import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { UserService } from "./user.service";
import { PermissionService } from "../database/permission.service";
import { environment } from "src/environments/environment";
import { AuthErrors } from "src/app/shared/enums/auth-errors";
import { IUserInfo } from "src/app/shared/interfaces/user-info";

@Injectable()
export class AuthService {
  private tokenRefreshedSource: Subject<void> = new Subject<void>();
  private uiAuthenticatedSource = new BehaviorSubject(false);
  public refreshTokenInProgress = false;
  public tokenRefreshed: Observable<void> = this.tokenRefreshedSource.asObservable();
  public uiAuthenticated: Observable<boolean> = this.uiAuthenticatedSource.asObservable();
  public permissions: number[] = [];
  private userAuthenticated = false;

  constructor(
    private msalService: MsalService,
    private router: Router,
    private userService: UserService,
    private permissionService: PermissionService
  ) {

  }

  isUserAuthenticated(): boolean {
    this.userAuthenticated = this.msalService.instance.getAllAccounts().length > 0;
    return this.userAuthenticated;
  }

  setUiAuthenticated(value: boolean): void {
    this.userAuthenticated = value;
    this.uiAuthenticatedSource.next(value);
  }

  canImpersonate(role: string): boolean {
    return role == 'Systems Developer' || role == 'Digital Improvement Manager';
  }

  login(): void {
    this.router.navigate(['redirect']);
    this.msalService.loginRedirect({ scopes: ["c2918d13-f931-455d-bcb6-25abdda93b80/.default"] });
  }

  postLoginGetUser() {
    this.userService.getMyInfo()
      .subscribe(response => {
        if (response['active'] == true && response['leaveDate'] === null) {
          console.log("database used", response['mode'])
          const user: IUserInfo = {
            staffId: response['staffid'],
            userName: `${response['forename']} ${response['surname']}`,
            firstName: response['forename'],
            jobRole: response['jobrole'],
            email: response['email'],
            department: response['department'],
            canImpersonate: this.canImpersonate(response['jobrole']),
            impersonatingRole: '',
            isImpersonating: false,
            visionAdmin: response['toolbarSetupStaffconfig'] > 1 ? true : false,
            defaultPage: response['defaultPage'],
            countUnreadNotifications: response['countUnreadNotifications'],
            mode: response['mode'],
            currentLocation: response['currentLocation'],
            microsoftId: response['microsoftId'],
            company: response['company'],
            permission: response['permissions']
          }
          this.userService.saveUserToLocalDbSubscription(user).then(() => {
            this.userService.updateNotificationsGlobally(user);
            this.updatePermissions(user.staffId, () => {
              this.setUiAuthenticated(true);
              this.router.navigate(['']);
            });
          });
        }
        else {
          this.router.navigate([`auth-error`], { queryParams: { error: AuthErrors.UserNotActive } });
        }
      });

  }

  postLogin(result): void {
    if (result !== null) {
      if (result.accessToken !== null) {
        const account = this.msalService.instance.getAllAccounts()[0]; //grab the user account
        this.msalService.instance.setActiveAccount(account);
        localStorage.setItem('access_token', result.accessToken);
      }
    }
    this.postLoginGetUser();
  }

  postLoginEdge(): void {
    this.postLoginGetUser();
  }

  logout(): void {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.logoutRedirectUri
    });
  }

  refreshTokenSilent() {
    console.log("Refreshing token!");
    const token = localStorage.getItem('access_token');
    if (token !== null) {
      if (this.refreshTokenInProgress) {
        return new Observable(observer => {
          this.tokenRefreshed.subscribe(() => {
            observer.next();
            observer.complete();
          })
        })
      }
      else {
        this.refreshTokenInProgress = true;
        try {
          const account = this.msalService.instance.getAllAccounts()[0]; //grab the user account
          this.msalService.instance.setActiveAccount(account);
          this.msalService.acquireTokenSilent({ scopes: ["c2918d13-f931-455d-bcb6-25abdda93b80/.default"] }).subscribe({
            next: (result) => {
              localStorage.setItem('access_token', result.accessToken);
              this.tokenRefreshedSource.next();
              this.refreshTokenInProgress = false;
            },
            error: (error) => {
              this.refreshTokenInProgress = false;
              this.router.navigate([`auth-error`], { queryParams: { error: AuthErrors.RefreshTokenError } });
            }
          });
        }
        catch (error) {
          console.log(error);
        }
      }
    }
  }

  getTokenFromLocalStorage(): string {
    const tokenObject = localStorage.getItem(Object.keys(localStorage).find(key => key.includes("idtoken")));
    if (tokenObject !== null || tokenObject !== undefined) {
      const token = JSON.parse(tokenObject);
      return token.secret;
    }
    return null;
  }

  addAuthHeader<T>(request: HttpRequest<T>): HttpRequest<T> {
    const token = localStorage.getItem('access_token');
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }
    return request;
  }

  updatePermissions(staffId, callback): void {
    this.userService.getPermission(staffId).subscribe(permission => {
      this.permissionService.clear();
      this.permissionService.add(permission, 1);
      this.permissions = permission;
      callback();
    });
  }
}
