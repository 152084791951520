<table mat-table [dataSource]="dataSource" matSort>

    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{processColumnTitle(column)}} </th>
      <td mat-cell *matCellDef="let element" class="data-list-padding">
        <span *ngIf="column=='info'" matTooltip="{{element[column]}}" >
          <mat-icon>info</mat-icon>
        </span>
        <span *ngIf="column=='fullName'">
          <a class="export-all" style="text-decoration: underline;"
            (click)="loadLearner(element['traineeId'], element['pot'])"> {{element[column]}} </a>
        </span>
        <span *ngIf="column!='fullName' && column!='info'">
          {{isPercentage(element[column], column) ? (element[column] * 100).toFixed(0) + '%': element[column] }}
        </span>
        <span *ngIf="column=='notes'">
          <mat-icon *ngIf="element['notes']" (click)="openDialog(element)" class="icon--click-hover edit-icon">edit</mat-icon>
          <mat-icon *ngIf="!element['notes']" (click)="openDialog(element)" class="icon--click-hover">add</mat-icon>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<mat-paginator  [pageSize]="10"  [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
<button mat-flat-button color="primary" (click)="export()" class="reportOptionButton">Export</button>
