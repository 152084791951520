import { NativeDateAdapter } from "@angular/material/core";
import { Injectable } from "@angular/core";


@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    if (displayFormat == 'input') {

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
    return date.toDateString();
  }
}

export const APP_DATE_FORMATS =
{
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Injectable({
  providedIn: 'root'
})
export class DateHelper {
  calculateDayDiff(startDate, endDate) {
    return Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())) / (1000 * 60 * 60 * 24));
  }
  isWeekDay(date: Date) {
    return date.getDay() != 6 && date.getDay() != 0;
  }
  timeIsBefore(time: string, controlTime: string) {
    return time < controlTime;
  }
  timeIsAfter(time: string, controlTime: string) {
    return time > controlTime;
  }
}
