<ng-sidebar-container class="sidebar-container">
  <ng-sidebar #ngSidebar class="side-bar" style="color: white; direction: rtl;" [(opened)]="sidebarOpened"
    [mode]="this.sidebarMode" [position]="'left'" [dock]="true" [dockedSize]="'35px'" [autoFocus]="false">

    <div style="min-width: 35px">
      <mat-icon *ngIf="!sidebarOpened" class="filter filter-button" (click)="toggleSidebar()">filter_list</mat-icon>
    </div>

    <div [ngStyle]="{'display': !this.sidebarOpened ? 'none' : 'inherit'}" class="filter-bar side-bar"
      style="width:320px; direction: ltr; padding-left: 8px">
      <div style="display:flex; gap: 5px; height: 60px;">
        <h2>
          Filters
        </h2>
        <div style="flex-grow: 1"></div>
        <button #loadFilters mat-button class="filterButtons" onClick="this.blur()"
          (click)="checkSaveFiltersAndStore()">
          Save
        </button>
        <button #loadFilters mat-button class="filterButtons" onClick="this.blur()" (click)="viewSavedFilters()">
          Load
        </button>
        <button mat-button class="filterButtons" (click)="clearSidebar()">
          Clear
        </button>
        <button mat-button class="filterButtons" (click)="toggleSidebar()" style="margin-right: 14px;">
          <img src="../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Hide Filters">
        </button>
      </div>
      <div style="display: flex; gap: 10px; justify-content: space-between; margin-right: 14px;">
      </div>

      <div style="display: inherit;" class="filter-bar-inner" style="padding-left: 10px; min-height: calc(100% - 60px)">
        <div class="option-section-header" style="margin: 20px 0 10px 0;">
          <div style="flex: 1;">
            <h4 style="margin: 0">Options</h4>
          </div>
          <div style="flex: 0.25; align-self: center;">
            <mat-icon id="Options-Filters" (click)="showHideFilters($event)"
              class="icon--click-hover report-filter-toggle">
              {{ showOptionsFilters ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </div>
        </div>
        <div *ngIf="this.errorOptionsShow" [hidden]="!showOptionsFilters" style="margin-right: 20px; margin-top: 10px">
          <p style="text-align: center;">{{this.errorOptionsText}}</p>
        </div>
        <div style="margin-right: 20px; margin-top: 10px">
          <mat-form-field class="programme-select" appearance="outline" style="width: 100%;">
            <mat-label>Programme</mat-label>
            <mat-select #programmeSelector (selectionChange)="setProgramme($event.value)" disableOptionCentering
              panelClass="dropdown-menu" [value]="selectedProgramme">
              <mat-option *ngFor="let col of this.programmeOptions" [value]="col.name">
                {{col.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="!this.loadingTermContractYear && !this.errorTermContractYearShow">
            <mat-form-field class="term-contract-year-select" appearance="outline" style="width: 100%;">
              <mat-label>Term Contract Year</mat-label>
              <mat-select #termContractYearSelector (selectionChange)="setContractYear($event.value)"
                disableOptionCentering panelClass="dropdown-menu" [value]="selectedContractYear">
                <mat-option *ngFor="let col of this.termContractYearOptions" [value]="col">
                  {{col}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="this.loadingTermContractYear && !this.errorTermContractYearShow"
          style="margin-right: 20px; margin-top: 10px" [hidden]="!showReportFilters">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="this.errorTermContractYearShow" [hidden]="!showOptionsFilters"
          style="margin-right: 20px; margin-top: 10px">
          <p style=" text-align: center;">{{this.errorTermContractYearText}}</p>
        </div>
        <br *ngIf="!showOptionsFilters">
        <div class="option-section-header" style="margin: 20px 0 10px 0;">
          <div style="flex: 1;">
            <h4 style="margin: 0">Classic</h4>
          </div>
          <div style="flex: 0.25; align-self: center;">
            <mat-icon id="Classic-Filters" (click)="showHideFilters($event)"
              class="icon--click-hover report-filter-toggle">
              {{ showClassicFilters ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </div>
        </div>
        <div *ngIf="this.errorClassicShow" [hidden]="!showClassicFilters" style="margin-right: 20px; margin-top: 10px">
          <p style="text-align: center;">{{this.errorClassicText}}</p>
        </div>

        <br *ngIf="!showClassicFilters">


        <div *ngIf="this.loadingFilters && !this.errorClassicShow" style="margin-right: 20px; margin-top: 10px"
          [hidden]="!showClassicFilters">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <app-shared-filters *ngIf="!this.loadingFilters && !this.errorClassicShow" [hidden]="!showClassicFilters"
          #sharedFilters [hasActiveLearnerFilter]="this.activeLearnerToggle" [assessorOptions]="assessorOptions"
          [centreOptions]="centreOptions" [contractOptions]="contractOptions"
          [subcontractorOptions]="subcontractorOptions" [programmeTypeOptions]="programmeTypeOptions"
          [schemeOptions]="schemeOptions" [sectorOptions]="sectorOptions" (setFilterEmitter)="setFilter($event)"
          (toggleOnlyActiveLearners)="toggleOnlyActiveLearners($event)">
        </app-shared-filters>

        <br *ngIf="!showClassicFilters">
        <span *ngIf="displayReportFilters">
          <div class="option-section-header" style="margin: 20px 0 10px 0;">
            <div style="flex: 1;">
              <h4 style="margin: 0;">Report</h4>
            </div>
            <div style="flex: 0.25; align-self: center;">
              <mat-icon id="Report-Filters" (click)="showHideFilters($event)"
                class="icon--click-hover report-filter-toggle">
                {{ showReportFilters ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </div>
          </div>
          <div *ngIf="this.loadingFilters && !this.errorCustomShow" style="margin-right: 20px; margin-top: 10px"
            [hidden]="!showReportFilters">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf="this.errorCustomShow" [hidden]="!showReportFilters" style="margin-right: 20px; margin-top: 10px">
            <p style=" text-align: center;">{{this.errorCustomText}}</p>
          </div>
          <div *ngIf="!this.loadingFilters && !this.errorCustomShow" [hidden]="!showReportFilters"
            [formGroup]="additionalFilterGroup">
            <ng-select *ngFor="let filter of additionalFilters" [items]="filter.Options" [closeOnSelect]="false"
              [multiple]="true" placeholder="{{this.sharedFunctions.unCamelCase(filter.Type)}}" class="filter-select"
              #select style="margin-right: 20px;" appearance="outline"
              (change)="setFilter({type: filter.Type, value: additionalFilterGroup.get(filter.Type).value})"
              formControlName="{{filter.Type}}" [clearOnBackspace]="false">
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice:0:2">
                  <span class="ng-value-label">{{item}}</span>
                  <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 2">
                  <span class="ng-value-label">{{items.length - 2}} more...</span>
                </div>
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item}}
              </ng-template>
            </ng-select>
          </div>
        </span>
      </div>
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>
    <div style="display: flex;">
      <div style="flex-grow: 1"></div>
      <button mat-button (click)="back()" class="close-button">Close</button>
    </div>
    <mat-divider></mat-divider>
    <div class="reportContainer">
      <div style="display: flex;">
        <h2 class="reportTitle"
          style="color: #262633 !important; font-size: 24px !important; margin: 0; align-self: center;">{{reportName}}
        </h2>
        <button class="favourite-button" [disabled]="this.waitingForToggle" (click)="toggleReportFavorite()">
          <mat-icon class='star-favourite-icon'>
            {{ this.isFavouriteReport.status ? 'star' : 'star_outline'}}
          </mat-icon>
        </button>
        <div style="flex: 1"></div>
        <mat-button-toggle-group class="report-type-buttons" aria-label="Display Option"
          (change)="changeGrouping($event)" [value]="this.groupingSelected">
          <mat-button-toggle *ngFor="let group of grouping" [value]="group.name"
            style="padding-right: 5px; color: #473B8C">
            <img [src]="group.icon" width="15px">
            <span>{{group.name}}</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div>
        <div class="reportTable">
          <table mat-table [dataSource]="this.tableDataSource" #tableSort="matSort" matSort
            matSortActive="{{this.matSortActive}}" matSortDirection="{{this.matSortDirection}}">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
              <th mat-sort-header mat-header-cell *matHeaderCellDef sticky [ngStyle]="headerCellStyle(column)"> {{
                this.tableHeaderName(column)}} </th>
              <td mat-cell *matCellDef="let cell" [ngStyle]="tableCellStyle(column , cell[column])">
                <span> {{ this.sharedFunctions.isDateFromColumnName(cell[column], column) ? (cell[column] |
                  date:this.dateFormat)
                  ?? '-' : cell[column]}}
                </span>
              </td>
              <td mat-footer-cell *matFooterCellDef [ngStyle]="tableCellStyle(column , getCount(column))">
                {{getCount(column)}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="displayedColumns.length" style="text-align: center;"
                [attr.colspan]="this.displayedColumns.length">
                {{
                showSpinner ? 'Loading...':
                errorText ? errorText :'No Results'}}
              </td>
            </tr>
            <tr [hidden]="showSpinner" mat-footer-row *matFooterRowDef="displayedColumns;  sticky: true"
              class="footer-row">
            </tr>
          </table>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
        <mat-paginator #tablePaginator [length]="totalData" [pageSizeOptions]="pageOptions" showFirstLastButtons="true">
        </mat-paginator>
      </div>
      <div>
        <div style="flex-grow: 1; margin-left: 20px; display:flex; gap: 15px">
          <button mat-flat-button color="primary" (click)="shareReport()">
            <!-- TODO - Import svg and edit inline -->
            <img src="assets/general/share.svg" width="15px" alt="share"> <span style="padding: 5px;">Share</span>
          </button>
          <button mat-stroked-button class="save-export-buttons" [matMenuTriggerFor]="saveMenu">
            Export As... <mat-icon class="export-arrow">expand_more</mat-icon>
          </button>
          <mat-menu #saveMenu="matMenu" yPosition="below">
            <button mat-menu-item (click)="export('csv')">.csv</button>
            <button mat-menu-item (click)="export('xlsx')">.xlsx</button>
            <button mat-menu-item (click)="export('pdf')">.pdf</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</ng-sidebar-container>