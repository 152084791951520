<mat-card class="learner-card" [style.cursor]="this.learner.traineeId !== undefined ? 'pointer' : 'wait'" (click)="this.learner.traineeId !== undefined ? this.routeToLearner() : ''">
  <div *ngIf="this.learner.traineeId !== undefined; else loading" style="height: 100%;">
    <div style="display: flex; flex-direction: column; height: 100%; gap: 3px;">
      <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 8px;">
        <h3 style="font-weight: 500; margin-bottom: 2px;" [ngStyle]="{'font-size' : learner.name?.length > 25 ? '16px' : '18px'}">
          {{ this.learner.name ?? '*No Name'}}
        </h3>
          <div style="width: 18px; height: 18px; border-radius: 4px; flex-shrink: 0; flex-grow: 0;"
            [ngStyle]="this.potStatusColour()"
            matTooltip="Pot Status: {{this.learner.potStatus}}"
            matTooltipPosition="above"
            matTooltipClass="card-tooltip"
          >
            <div style="padding: 1px; text-align: center; font-weight: 500; cursor: default">
              {{ this.learner.pot }}
            </div>
          </div>
      </div>
      <div>
        <img src="../../../assets/profiles/pie-chart-filled.svg" alt="Sector" class="learner-card-icon">
        <span>{{ this.learner.sector }}</span>
      </div>
      <div>
        <img src="../../../assets/images/icon-route-new.png" alt="Scheme" class="learner-card-icon">
        <span>{{ this.learner.scheme }}</span>
      </div>

      <div style="flex: 1"></div>

      <div>
        <img src="../../../assets/images/icon-assessor.png" alt="assessor" style="padding-right: 5px; width: 1em;">
        <span>{{ this.learner.assessorName }}</span>
      </div>
      <div>
          <img src="../../../assets/images/icon-date.png" alt="term dates" style="padding-right: 5px; width: 1em;">
          <span>{{ this.learner.startDate | date: this.dateFormat }}</span>
          <span *ngIf="learner.terminationDate !== null"> - {{ this.learner.terminationDate | date: this.dateFormat}}</span>
      </div>
    </div>
    <div class="more-info"
    [matTooltip]="this.cardTooltipText()"
    matTooltipClass="card-tooltip more-info-tooltip"
    matTooltipPosition="below"
    >...</div>
  </div>

  <ng-template #loading>
    <h3 style="font-weight: 500; margin-bottom: 2px; font-size: 18px">
      Loading...
    </h3>
  </ng-template>
</mat-card>
