
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-employer-dialog',
  templateUrl: './add-employer-dialog.component.html',
  styleUrls: ['./add-employer-dialog.component.scss']
})
export class AddEmployerDialogComponent implements OnInit {
  employerForm: UntypedFormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddEmployerDialogComponent>,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(): void {
    this.dialogRef.close();    
  }

  onOK(): void {
    console.log("ok")
    this.dialogRef.close(this.employerForm.value);
  }

  buildForm() {
    this.employerForm = this.fb.group({
      name: [null, Validators.required],
      address: null

    });
  }
}
