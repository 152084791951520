<div class="attendance-page-container">

  <div *ngIf="!this.loadedAttendance">
    <p style="text-align: center; margin-bottom: 0;">Loading Attendance...</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="this.loadedAttendance" style="display: flex;">
    <div style="flex: 1">
        <h1 class="title" style="margin: 0 0 15px 0; text-align: center;">Last 3 Weeks</h1>

        <div *ngIf="!this.latestWeeksGuages; else guages">
          No Data
        </div>
        <ng-template #guages>
          <div class="gauges">
              <ngx-gauge *ngFor="let gauge of this.latestWeeksGuages"
                [type]="'arch'"
                [size]="150"
                [cap]="'round'"
                [thick]="10"
                [value]="gauge.value.toFixed(0)"
                [thresholds]="this.thresholdConfig"
                [label]="gauge.lable ? (gauge.lable | date) : '-'"
                [append]="'%'"
              >
              </ngx-gauge>
          </div>
        </ng-template>
    </div>

    <mat-card style="padding: 30px">
      <h1 class="title" style="margin: 0 0 15px 0; text-align: center;">Reports</h1>
        <button mat-flat-button color="primary" (click)="this.openReport('signInHistory')" [disabled]="this.loadingReport">
          Sign In History
          <mat-progress-bar *ngIf="this.loadingReport" mode="indeterminate" class="button-progress-bar"></mat-progress-bar>
        </button>
    </mat-card>
  </div>

  <div *ngIf="this.loadedAttendance">
    <h1 class="title" style="margin: 0 0 10px 0">Weekly Summary</h1>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0" style="margin-bottom: 15px;" matSortDirection="desc" matSortActive="week">

      <!-- week Column -->
      <ng-container matColumnDef="week">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title" style="padding-left: 10px !important;">
          Week Starting
        </th>
        <td mat-cell *matCellDef="let element" (click)="loadingWeekDetail ? null : this.getAttendanceDetails(element)" class="clickable" style="padding-left: 30px !important">
            {{element.week | date: this.dateFormat}}
            <mat-spinner *ngIf="this.loadingWeekDetail === element.attendanceWeekId" [diameter]="17" style="margin-left: 5px; display: inline;"></mat-spinner>
        </td>
      </ng-container>

      <!-- Time Attended Column -->
      <ng-container matColumnDef="timeAttended">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by date planned" style="text-align: center;">
          Time Attended
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
           {{element.timeAttendedHours}}<span class="low-opacity">h</span> {{element.timeAttendedMinutes}}<span class="low-opacity">m</span>
        </td>
      </ng-container>

      <!-- Time Docked Column -->
      <ng-container matColumnDef="timeDocked">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by iqa type" style="text-align: center;">
          Time Docked
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{element.timeDockedHours}}<span class="low-opacity">h</span> {{element.timeDockedMinutes}}<span class="low-opacity">m</span>
        </td>
      </ng-container>

      <!-- Perentage Column -->
      <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef style="text-align: right !important; padding-right:20px !important;">
          Attendance %
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="this.attendanceColor(element.percentage)" style="text-align: right; padding-right: 30px !important">
          {{(element.percentage * 100).toFixed(0)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4" style="text-align: center;">
          {{ this.noData }}
        </td>
      </tr>


    </table>
  </div>

  <div *ngIf="this.loadedAttendance">
    <h3 class="title" style="margin: 0 0 10px 0">Holiday Summary</h3>

    <table mat-table [dataSource]="dataSourceHolidays" class="mat-elevation-z0">

        <div *ngFor="let col of displayedColumnsHolidays">
            <ng-container matColumnDef="{{col}}">
                <th mat-header-cell *matHeaderCellDef>
                {{this.sharedFunctionsSercice.unCamelCase(col)}}
                </th>
                <td mat-cell *matCellDef="let element"> {{ element[col] }} </td>
            </ng-container>
        </div>


        <tr mat-header-row *matHeaderRowDef="displayedColumnsHolidays"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsHolidays;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" style="text-align: center;">
            {{ this.noData }}
          </td>
        </tr>
    </table>
  </div>

</div>
