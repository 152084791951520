import { MatSnackBar } from '@angular/material/snack-bar';
import { IqaRecordFormService } from './iqa-record-form.service';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from 'underscore';
import { UserTableService } from 'src/app/core/database/user-table.service'
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LearnerService } from '../../learnerService.service';

@Component({
  selector: 'app-iqa-record-form',
  templateUrl: './iqa-record-form.component.html',
  styleUrls: ['./iqa-record-form.component.scss']
})
export class IqaRecordFormComponent implements OnInit {

  traineeId: string;
  iqaRecordId: number;
  pot: number;
  staffId: any;
  formOptions: any;
  selectedUnits: any[] = [];
  units: any[] = [];
  unitCount = 1;
  iqaForm: UntypedFormGroup;
  iqaCreditsMin: number;
  iqaCreditsCount = 0;
  displayQualificationNotSelectedWarning = false;
  claimable = false;
  iqaCreditsAtLevel: any;
  iqaCreditsMax_A: any;
  iqaCreditsMax_B: any;
  iqaCreditsMax_C: any;
  iqaCreditsMax_D: any;
  iqaCreditsMax_E: any;
  iqaCreditsMax_F: any;
  iqaCreditsMin_A: any;
  iqaCreditsMin_B: any;
  iqaCreditsMin_C: any;
  iqaCreditsMin_D: any;
  iqaCreditsMin_E: any;
  iqaCreditsMin_F: any;
  iqaCreditsCountA = 0;
  iqaCreditsCountB = 0;
  iqaCreditsCountC = 0;
  iqaCreditsCountD = 0;
  iqaCreditsCountE = 0;
  iqaCreditsCountF = 0;
  undo: boolean;

  claimError: string;
  hasRegDate = false;
  beenClaimed = false;
  existingClaim = false;
  claimDate = null;
  claimBy = null;
  certReceived = null;
  needOutcome = false;
  hasOutcome = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<IqaRecordFormComponent>,
    private dialog: MatDialog,
    private iqaRecordService: IqaRecordFormService,
    private userService: UserTableService,
    private fb: UntypedFormBuilder,
    private learnerService: LearnerService,
    private cdr: ChangeDetectorRef,
  ) { this.buildForm(); }

  ngOnInit(): void {
    this.traineeId = this.data.traineeId;
    this.pot = this.data.pot;
    this.formOptions = this.data.formOptions;
    this.getRegDate(this.data.laimRef);
    this.checkForExistingClaim(this.data.laimRef, this.data.iqaRecordId ?? null);
    this.checkForClaimAndCertReceived(this.data);

    // console.log('data', this.data);


    // This would come from a requested IQA
    if (this.data.laimRef && !this.data.iqaRecordId) {
      this.iqaForm.patchValue({
        title: this.data.title,
        laimRef: this.data.laimRef,
        iqaTypeId: this.data.iqaTypeId,
        awardingBody: this.data.awardingBody,
      });
      this.setNeededCredits(this.data.laimSpecification);
      this.getMyUnits(this.iqaForm.controls.laimRef.value, true);
    }

    // This would be a excisting IQA record
    if (this.data.iqaRecordId) {
      this.getStaffId();
      this.setNeededCredits(this.data.laimSpecification);
      const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
      this.selectedUnits = this.data.units.sort((a, b) => { return collator.compare(a.selectedUnit.unit, b.selectedUnit.unit) });
      this.iqaRecordId = this.data.iqaRecordId;

      this.iqaForm.patchValue({
        isExistingRecord: true,
        iqaRecordId: String(this.data.iqaRecordId),
        title: this.data.title,
        laimRef: this.data.laimRef,
        awardingBody: this.data.awardingBody,
        datePlanned: !this.data.datePlanned ? null : this.data.datePlanned,
        dateActual: !this.data.dateActual ? null : this.data.dateActual,
        sampleTypeId: this.data.sampleTypeId,
        iqaTypeId: this.data.iqaTypeId,
        iqaOutcomeId: this.data.iqaOutcomeId,
        iqaSiteId: this.data.iqaSiteId,
        iqaStaffId: this.data.iqaStaffId,
        assessorId: this.data.assessorId,
        secondLineAssessorId: this.data.secondLineAssessorId,
        counterSignIqaStaffId: this.data.counterSignIqaStaffId,
        claim: this.beenClaimed,
        comments: this.data.comments
      });

      this.getMyUnits(this.iqaForm.controls.laimRef.value, true);
    }


    // console.log('formOptions', this.formOptions);
  }

  getRegDate(laimRef) {
    if (!laimRef) {
      this.hasRegDate = false;
      return;
    }
    const matchingObjective = this.data.objectives.find(o => o.laimRef === laimRef);
    this.hasRegDate = matchingObjective?.regDate ? true : false;
  }

  checkForClaimAndCertReceived(record) {
    this.beenClaimed = record.dateSignedOff !== null ? true : false;
    if (this.beenClaimed) {
      this.claimDate = record.dateSignedOff;
      this.claimBy = record.staffNameSignedOff;
    }
    this.certReceived = record.certReceived;
  }

  checkForExistingClaim(recordLaimRef, recordId = null) {
    const claimedRecords = this.data.allRecords.filter(r => r.laimRef === recordLaimRef && r.dateSignedOff !== null && r.iqaRecordId !== recordId);
    if (claimedRecords.length > 0) {
      this.existingClaim = true;
    }
  }


  buildForm() {
    this.iqaForm = this.fb.group({
      isExistingRecord: false,
      iqaRecordId: '',
      title: [null, Validators.required],
      laimRef: [null, Validators.required],
      awardingBody: [null, Validators.required],
      datePlanned: [null, Validators.required],
      dateActual: null,
      sampleTypeId: [null, Validators.required],
      iqaTypeId: [null, Validators.required],
      iqaOutcomeId: null,
      iqaSiteId: null,
      iqaStaffId: null,
      counterSignIqaStaffId: null,
      assessorId: null,
      secondLineAssessorId: null,
      claim: { value: false, disabled: true },
      comments: null,
      selectedUnitsArray: this.fb.array([this.createUnitSelect()])
    });

    this.iqaForm.get('iqaTypeId').valueChanges.subscribe(value => {
      this.needOutcome = value === 6 || value === 3 ? true : false;
    });

    this.iqaForm.get('iqaOutcomeId').valueChanges.subscribe(value => {
      this.hasOutcome = value === null ? false : true;
      this.checkIfClaimable();
    });
  }





  checkQualSelected() {
    if (!this.iqaForm.get('title').value) {
      this.displayQualificationNotSelectedWarning = true;
    }
  }

  private createUnitSelect(unit = null):
    UntypedFormGroup {
    return new UntypedFormGroup({
      'selectedUnit': new UntypedFormControl(unit)
    })
  }

  getStaffId() {
    this.userService.get(1).then(
      value => {
        this.staffId = value.staffId;
      });
  }

  countCriteria(evt = null) {
    this.resetCriteriaCount();
    const array = this.iqaForm.get('selectedUnitsArray') as UntypedFormArray;
    const unitsCounted = [];
    array.controls.forEach(element => {
      for (const x in element.value) {
        if (element.value[x]) {
          const val = element.value[x].iqaCreditValue;
          const group = element.value[x].iqaCreditGroup;
          const unitId = element.value[x].unitId;
          if (!isNaN(val) && !unitsCounted.includes(unitId)) {
            this.iqaCreditsCount += val;
            switch (group) {
              case ('A'):
                this.iqaCreditsCountA += val;
                break;
              case ('B'):
                this.iqaCreditsCountB += val;
                break;
              case ('C'):
                this.iqaCreditsCountC += val;
                break;
              case ('D'):
                this.iqaCreditsCountD += val;
                break;
              case ('E'):
                this.iqaCreditsCountE += val;
                break;
              case ('F'):
                this.iqaCreditsCountF += val;
                break;
            }
            unitsCounted.push(unitId);
          }

          this.checkIfClaimable();
        }
      }
    });
  }

  checkIfClaimable() {
    const iqaOutcome = this.formOptions.iqaFormOptionOutcome.find(i => i.outcomeId === this.iqaForm.controls.iqaOutcomeId.value);
    if (this.iqaCreditsCount >= this.iqaCreditsMin && this.hasRegDate && !this.existingClaim && this.hasOutcome && iqaOutcome.claimable) {
      this.iqaForm.controls['claim'].enable()
      this.claimError = '';
    } else {
      this.iqaForm.controls['claim'].disable()
      if (this.iqaCreditsCount < this.iqaCreditsMin) {
        this.claimError = 'You must select at least ' + this.iqaCreditsMin + ' credits to claim';
      } else if (!this.hasRegDate) {
        this.claimError = 'Quilification must be registered before you can claim';
      } else if (this.existingClaim) {
        this.claimError = 'A claim has already been requested';
      } else if (!iqaOutcome.claimable) {
        this.claimError = 'This outcome is not claimable';
      } else if (!this.hasOutcome) {
        this.claimError = 'You must select an outcome before you can claim';
      }
    }
  }

  resetCriteriaCount() {
    this.iqaCreditsCount = 0;
    this.iqaCreditsCountA = 0;
    this.iqaCreditsCountB = 0;
    this.iqaCreditsCountC = 0;
    this.iqaCreditsCountD = 0;
    this.iqaCreditsCountE = 0;
    this.iqaCreditsCountF = 0;
  }

  newUnit() {
    const array = this.iqaForm.get('selectedUnitsArray') as UntypedFormArray
    array.push(this.createUnitSelect());
  }

  populatePreSelectedUnits() {
    const array = this.iqaForm.get('selectedUnitsArray') as UntypedFormArray;
    this.selectedUnits.forEach(item => {
      array.push(this.createUnitSelect(item['selectedUnit']));
    });
    // This just clears the first empty unit if others are preselected
    this.cancelUnit(0, false);
  }

  compareFn(c1, c2): boolean {
    return c1 && c2 ? c1.unitId === c2.unitId : c1 === c2;
  }

  cancelUnit(i: number, markAsDirty = true) {
    const unitArray = this.iqaForm.get('selectedUnitsArray') as UntypedFormArray
    if (unitArray.length > 1) {
      unitArray.removeAt(i);
    } else {
      unitArray.reset();
    }
    this.countCriteria();
    if (markAsDirty) {
      this.iqaForm.markAsDirty();
    }
  }

  close(): void {
    if (this.iqaForm.dirty) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: `You are about to close this IQA record without saving. Do you wish to continue?`,
        title: 'Are you sure?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warn',
      }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        result => {
          if (result) {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  changeQualification() {
    this.displayQualificationNotSelectedWarning = false;
    this.data.title = this.iqaForm.controls.title.value;
    const result = this.data.objectives.filter(o => o.title == this.iqaForm.controls.title.value);
    this.iqaForm.controls.laimRef.setValue(result[0].laimRef);
    this.iqaForm.controls.awardingBody.setValue(result[0].awardingBody);

    this.setNeededCredits(result[0].laimSpecification);

    const selectedUnitsArray = this.iqaForm.get('selectedUnitsArray') as UntypedFormArray;
    selectedUnitsArray.clear();
    selectedUnitsArray.push(this.createUnitSelect());
    this.resetCriteriaCount();
    this.getMyUnits(this.iqaForm.controls.laimRef.value);

    this.claimError = 'You must select at least ' + this.iqaCreditsMin + ' credits to claim';
    this.getRegDate(result[0].laimRef);
    this.checkForExistingClaim(result[0].laimRef);
    // const matchingLaimRefRecords = this.data.allRecords.filter(r => r.laimRef == result[0].laimRef && r.dateSignedOff !== null);
    // if(matchingLaimRefRecords.length > 0) {
    //   this.checkForClaimAndCertReceived(matchingLaimRefRecords[0]);
    //   this.iqaForm.controls['claim'].setValue(this.beenClaimed);
    // }
  }

  setNeededCredits(laimSpecification) {
    this.iqaCreditsMin = laimSpecification.iqaCreditsMin;
    this.iqaCreditsAtLevel = laimSpecification.iqaCreditsAtLevel;
    this.iqaCreditsMax_A = laimSpecification.iqaCreditsMax_A;
    this.iqaCreditsMax_B = laimSpecification.iqaCreditsMax_B;
    this.iqaCreditsMax_C = laimSpecification.iqaCreditsMax_C;
    this.iqaCreditsMax_D = laimSpecification.iqaCreditsMax_D;
    this.iqaCreditsMax_E = laimSpecification.iqaCreditsMax_E;
    this.iqaCreditsMax_F = laimSpecification.iqaCreditsMax_F;
    this.iqaCreditsMin_A = laimSpecification.iqaCreditsMin_A;
    this.iqaCreditsMin_B = laimSpecification.iqaCreditsMin_B;
    this.iqaCreditsMin_C = laimSpecification.iqaCreditsMin_C;
    this.iqaCreditsMin_D = laimSpecification.iqaCreditsMin_D;
    this.iqaCreditsMin_E = laimSpecification.iqaCreditsMin_E;
    this.iqaCreditsMin_F = laimSpecification.iqaCreditsMin_F;
  }

  getMyUnits(laimref, preSelected = false) {
    this.iqaRecordService.getUnits(laimref).subscribe({
      next: (units) => {
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        if (units.length > 0) {
          this.units = units.sort((a, b) => { return collator.compare(a.unit, b.unit) });
        }
      },
      error: (error) => {
        console.log('Error getting units:', error.error);
        this.snackBar.open('Error getting units, please reload and try again. If this error persists, please contact system support.', "Close");
      },
      complete: () => {
        if (preSelected) {
          this.populatePreSelectedUnits();
          this.countCriteria();
        }
      }
    });
  }

  unitCustomSearch(term: string, unit: any) {
    term = term.toLowerCase();
    return unit.title.toLocaleLowerCase().indexOf(term) > -1 ||
      unit.unit.toLocaleLowerCase().indexOf(term) > -1;
  }

  delete() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: `You are about to delete this IQA record for **${this.data.title}**. Do you wish to continue?`,
      title: 'Delete Record',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warn',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.deleteIqaRecord(this.data.iqaRecordId);
        }
      });
  }

  deleteIqaRecord(recordId: number) {
    const subscription = this.learnerService.deleteIqaRecord(recordId).subscribe({
      error: (error) => {
        if (error === 'You do not have permission to complete the requested action.') {
          this.snackBar.open(error, 'Close', {
            duration: 3000,
          });
          console.log(error);
        } else {
          console.log('Error deleting iqa record:', error.error);
          this.snackBar.open('Error deleting iqa record, please reload and try again. If this error persists, please contact support.', 'Close', {
            duration: 3000,
          });
        }
        subscription.unsubscribe();
      },
      complete: () => {
        this.snackBar.open('IQA record deleted', 'Close', {
          duration: 3000,
        });
        subscription.unsubscribe();
        this.dialogRef.close({ delete: true, iqaRecordId: this.data.iqaRecordId });
        this.cdr.detectChanges();
      },
    });
  }

  save() {
    if (!this.iqaForm.value.isExistingRecord && this.iqaForm.dirty) {
      if (!this.data.canCreate) {
        this.snackBar.open('You do not have permission to create new records.', 'Close', {
          duration: 3000,
        });
        return;
      }

      this.iqaRecordService.createNewIqaRecord(this.traineeId, this.pot, this.staffId, this.iqaForm.value)
        .subscribe({
          next: iqaRecordId => {
            // TODO: It would be better to return the updated record from the API rather than getting it again here.
            // This will need looking into though as GetIqaRecord is already a bit odd and needs looking into.
            // So keeping this for now as it works. RB - 08/11/23

            // get new record and update form
            this.iqaRecordService.getIqaRecord(iqaRecordId)
              .subscribe(iqaRecord => {
                this.dialogRef.close({ add: true, iqaRecord: iqaRecord[0] });
                this.snackBar.open("IQA record added", "Success", {
                  duration: 3000,
                });
              })
          },
          error: error => {
            if (error === 'You do not have permission to complete the requested action.') {
              this.snackBar.open(error, 'Close', {
                duration: 3000,
              });
              console.log(error);
            } else {
              console.log('Error creating new iqa record:', error.error);
              this.snackBar.open('Error creating new iqa record, please reload and try again. If this error persists, please contact system support.', 'Close', {});
            }
          }
        });
    }

    if (this.iqaForm.value.isExistingRecord && this.iqaForm.dirty) {
      if (!this.data.canUpdate) {
        this.snackBar.open('You do not have permission to edit records.', 'Close', {
          duration: 3000,
        });
        return;
      }

      this.iqaRecordService.updateIqaRecord(this.traineeId, this.pot, this.staffId, this.iqaForm.value)
        .subscribe({
          next: iqaRecordId => {
            // TODO: It would be better to return the updated record from the API rather than getting it again here.
            // This will need looking into though as GetIqaRecord is already a bit odd and needs looking into.
            // So keeping this for now as it works. RB - 08/11/23

            // get updated iqa record and update form
            this.iqaRecordService.getIqaRecord(iqaRecordId)
              .subscribe(iqaRecord => {
                this.dialogRef.close({ update: true, iqaRecordId: iqaRecordId, iqaRecord: iqaRecord[0] });
                this.snackBar.open("IQA record updated", "Success", {
                  duration: 3000,
                });

                this.cdr.detectChanges();
              });
          },
          error: error => {
            if (error === 'You do not have permission to complete the requested action.') {
              this.snackBar.open(error, 'Close', {
                duration: 3000,
              });
              console.log(error);
            } else {
              console.log('Error updating iqa record:', error.error);
              this.snackBar.open('Error updating iqa record, please reload and try again. If this error persists, please contact system support.', 'Close', {});
            }
          }
        });
    }
  }
}

