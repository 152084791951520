<h1 class="progressTitle title" style="margin-bottom: 5px;">Progress Summary</h1>

<a routerLink="/progress-summary-report/{{traineeId}}/{{pot}}" class="export-all" style="padding-left: 20px; " *ngIf="showViewReportLink">View report</a>

<highcharts-chart class="container"
   [Highcharts] = "highcharts" 
   [options] = "chartOptions" 
   style = "width: 100%; height: 400px; display: block;">
</highcharts-chart>


<!-- [view]="[container.offsetWidth * 0.95, container.offsetWidth * heightVar]" -->