<!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="left" [style.margin-left]="'125px'" -->
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="left" class="phone-book-container max-width">
  <mat-spinner *ngIf="phoneList.length == 0" style="font-size: 25px;"></mat-spinner>
  <div style="text-align: center; margin: 0px 30px 30px 0px;" *ngFor="let phoneRecord of phoneList.slice(0,itemNum)">
    <mat-card (click)="onSelect(phoneRecord)" class="search-card">
      <div class="mat-title">
        {{ phoneRecord.name }}<br>
      </div>
      <div class="mat-info2">
        <span *ngIf="phoneRecord.jobRole"> {{ phoneRecord.jobRole }}<br> </span>
      </div>
      <div class="mat-info">
        <span *ngIf="phoneRecord.company"> {{ phoneRecord.company }}<br> </span>
        <span *ngIf="phoneRecord.mobile"> {{ phoneRecord.mobile }}<br> </span>
      </div>
      <div class="mat-info" *ngIf="phoneRecord.currentLocation">
        Current Location: {{ phoneRecord.currentLocation }} <br>
      </div>
    </mat-card>
  </div>
</div>

<div *ngIf="staffListLoaded && phoneList.length > 0">
  <div style="margin-top: 10px; margin-left: 125px;" *ngFor="let phoneRecord of officePhoneList">
    {{ phoneRecord.title }} : {{ phoneRecord.phone }}
  </div>

  <div style="margin: 20px 0 0 125px;">
    <button mat-stroked-button>Visitor Log</button>
  </div>
  <div style="margin: 20px 0 0 125px;">
    <button mat-stroked-button>Print</button>
  </div>
  <div style="margin: 20px 0 0 125px;">
    <button mat-stroked-button (click)="onDownload()">Download</button>
  </div>
</div>
