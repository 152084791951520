import { AdminDashReportsService } from '../../../core/database/admin-dash-reports.service';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FavouriteTableService } from 'src/app/core/database/favourite-table.service';
import { WidgetDirective } from 'src/app/core/directives/widget.directive';
import { ContractWidgetItem } from '../../contract-widgets/contract-widget-item';
import { ContractWidgetTemplateComponent } from '../../contract-widgets/contract-widget-template-component';
import { ReportCardComponent } from '../../home-dashboards-shared/report-card-caseload-progress/report-card.component';
import { YourEmployersComponent } from '../../home-dashboards-shared/your-employers/your-employers.component';
import { YourLearnersComponent } from '../../home-dashboards-shared/your-learners/your-learners.component';
import { YourReportsComponent } from '../../home-dashboards-shared/your-reports/your-reports.component';
import { HomeDashboardService } from '../home-dashboard.service';
import { YourEventsComponent } from '../../home-dashboards-shared/your-events/your-events.component';
import { ContractWidgetService } from '../../contract-widgets/contract-widget.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataCardComponent } from '../../home-dashboards-shared/data-card/data-card.component';

@Component({
  selector: 'app-home-admin',
  templateUrl: './home-admin.component.html',
  styleUrls: ['./home-admin.component.scss']
})
export class HomeAdminComponent implements OnInit {

  @Input() staffId: number;
  @ViewChild(WidgetDirective, { static: true }) selectedWidgetHost!: WidgetDirective;

  //@Input() adminDashboardData;

  allWidgets: any[] = [];
  selectedWidgets: ContractWidgetItem[] = [];
  widgetsSelected: number[] = [];
  widgetTemplate: number[] = [];

  showHideWidgetOptions: boolean = false;

  isDefaultsLoaded: boolean = false;

  regRequests: any;
  awaitingCerts: any;
  toBeClaimed: any;
  withdrawnActivities: any;
  myFavouriteReports: any;
  currentDashboard = 'admin';

  cards: any[] = [
    { 'title': 'Registration Requests', 'stat': 0, 'data': null },
    { 'title': 'Awaiting Certificates', 'stat': 0, 'data': null },
    { 'title': 'To Be Claimed', 'stat': 0, 'data': null },
    { 'title': 'Withdrawn Activities', 'stat': 0, 'data': null }
  ]

  adminData: any;

  constructor(
    private adminDashReporService: AdminDashReportsService,
    private homeDashboardService: HomeDashboardService,
    private favouriteTableService: FavouriteTableService,
    private cdr: ChangeDetectorRef,
    private contractWidgetService: ContractWidgetService,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
  }

  // Widgets --------------

  refreshDisplayedWidgets() {
    let tempArray: ContractWidgetItem[] = [];

    this.widgetsSelected.forEach(element => {
      let selected = this.allWidgets.filter(a => {
        return a.widgetId == element
      });

      if (selected[0]) {
        tempArray.push(
          new ContractWidgetItem(
            ContractWidgetTemplateComponent,
            { 'widgetId': selected[0].widgetId, 'name': `${selected[0].widgetName}` }
          ));
      }
    });

    this.selectedWidgets = tempArray;
  }

  saveWidgetChanges() {

    if (this.isDefaultsLoaded) {
      this.contractWidgetService.removeStaffWidgetSelections(this.staffId).subscribe(
        response => {
          if (response) {
            this.snackBar.open("Default widgets have been restored", "Close", {
              duration: 4000,
            });
            this.isDefaultsLoaded = false;
          }
        }
      )
    } else {

      let hasChanged = true //false;

      // TODO: check if tempArray and this.selectedWidgets hold any different items

      /*
        tempArray.every(ta => {
          if (this.selectedWidgets.indexOf(ta) == -1) {
            hasChanged = true;
            return false;
          }
        });

        if (!hasChanged) {
          this.selectedWidgets.every(sw => {
            if (tempArray.indexOf(sw) == -1) {
              hasChanged = true;
              return false;
            }
          })
        }
      */

      if (hasChanged) {
        this.contractWidgetService.saveContractWidgetChanges(this.staffId, this.widgetsSelected).subscribe(
          response => {
            console.log(response);
          }
        );

        this.refreshDisplayedWidgets();
      }

      this.loadSelectedWidgetComponent();
    }
  }

  loadDefaultWidgets() {
    this.isDefaultsLoaded = true;
    this.widgetsSelected = this.widgetTemplate;
    this.refreshDisplayedWidgets();
    this.loadSelectedWidgetComponent();
  }

  loadSelectedWidgetComponent() {
    const viewContainerRef = this.selectedWidgetHost.viewContainerRef;
    viewContainerRef.clear();

    this.selectedWidgets.forEach(
      widgetItem => {

        let widgetId = widgetItem.data.widgetId;

        switch (widgetId) {

          case (1): {
            //console.log('adding widget 1: YourLearnersComponent')
            const componentRef = viewContainerRef.createComponent<YourLearnersComponent>(YourLearnersComponent);
            this.homeDashboardService.getYourLearners(this.staffId).subscribe(data => {
              componentRef.location.nativeElement['style']['width'] = '45%';
              componentRef.instance.setData(data);
              this.cdr.detectChanges();
            });
            break;
          };

          case (2): {
            //console.log('adding widget 2: YourEmployersComponent')
            const componentRef = viewContainerRef.createComponent<YourEmployersComponent>(YourEmployersComponent);
            this.homeDashboardService.getYourEmployers(this.staffId).subscribe(data => {
              if (data) {
                componentRef.location.nativeElement['style']['width'] = '45%';
                componentRef.instance.setCols(data);
                this.cdr.detectChanges();
              };
            });
            break;
          };

          case (3): {
            //console.log('adding widget 3: YourEventsComponent')
            const componentRef = viewContainerRef.createComponent<YourEventsComponent>(YourEventsComponent);
            componentRef.location.nativeElement['style']['width'] = '45%';
            componentRef.instance.staffId == this.staffId;
            componentRef.instance.currentDashboard = 'healthandsafety';
            this.cdr.detectChanges();
            break;
          };

          case (4): {
            //console.log('adding widget 4: YourReportsComponent')
            const componentRef = viewContainerRef.createComponent<YourReportsComponent>(YourReportsComponent);
            this.favouriteTableService
              .getAll()
              .then(response => {
                for (let x in response) {
                  let link = "/reports/" + response[x].title.toLowerCase().replace(" ", "-") + (response[x]['filterId'] != 0 ? '/' + response[x]['filterId'] : '')
                  response[x].link = link;
                };
                componentRef.instance.favourites = response;
                componentRef.location.nativeElement['style']['width'] = '22.5%';
                this.cdr.detectChanges();
              });
            break;
          };

          case (5): {
            //console.log('adding widget 5: ReportCardComponent')
            const componentRef = viewContainerRef.createComponent<ReportCardComponent>(ReportCardComponent);
            this.homeDashboardService.getCaseloadStats(this.staffId).subscribe(data => {
              if (data) {
                componentRef.location.nativeElement['style']['width'] = '45%';
                componentRef.instance.setData(data.caseload);
              };
              this.cdr.detectChanges();
            });
          };

          case (6): {
            //console.log('adding widget 6: Reg Requests')
            const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
            if (!this.adminData) {
              this.homeDashboardService.getDataForAdminDashboard().subscribe(data =>
                {this.adminData == data;
                let idx = this.cards.findIndex((x => x.title == 'Registration Requests'));
                this.cards[idx].stat = data['regRequests'].length;
                this.cards[idx].data = data['regRequests'];

                componentRef.instance.data = this.cards[0]['data'];
                componentRef.instance.title = this.cards[0]['title'];
                componentRef.instance.stat = this.cards[0]['stat'];
                this.cdr.detectChanges();
                });
            }
            else {
                let idx = this.cards.findIndex((x => x.title == 'Registration Requests'));
                this.cards[idx].stat = this.adminData['regRequests'].length;
                this.cards[idx].data = this.adminData['regRequests'];

                componentRef.instance.data = this.cards[0]['data'];
                componentRef.instance.title = this.cards[0]['title'];
                componentRef.instance.stat = this.cards[0]['stat'];
                this.cdr.detectChanges();
              };
              componentRef.location.nativeElement['style']['width'] = '22.5%';
              break;
            };

          case (7): {
            //console.log('adding widget 7: Awaiting Certificates')
            const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
            if (!this.adminData) {
              this.homeDashboardService.getDataForAdminDashboard().subscribe(data =>
                {this.adminData == data;
                let idx = this.cards.findIndex((x => x.title == 'Awaiting Certificates'));
                this.cards[idx].stat = data['awaitingCertificates'].length;
                this.cards[idx].data = data['awaitingCertificates'];

                componentRef.instance.data = this.cards[1]['data'];
                componentRef.instance.title = this.cards[1]['title'];
                componentRef.instance.stat = this.cards[1]['stat'];
                this.cdr.detectChanges();
                });
            }
            else {
                let idx = this.cards.findIndex((x => x.title == 'Awaiting Certificates'));
                this.cards[idx].stat = this.adminData['awaitingCertificates'].length;
                this.cards[idx].data = this.adminData['awaitingCertificates'];

                componentRef.instance.data = this.cards[1]['data'];
                componentRef.instance.title = this.cards[1]['title'];
                componentRef.instance.stat = this.cards[1]['stat'];
                this.cdr.detectChanges();
              };
              componentRef.location.nativeElement['style']['width'] = '22.5%';
              break;
            };

            case (8): {
              //console.log('adding widget 8: To be claimed')
              const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
              if (!this.adminData) {
                this.homeDashboardService.getDataForAdminDashboard().subscribe(data =>
                  {this.adminData == data;
                  let idx = this.cards.findIndex((x => x.title == 'To Be Claimed'));
                  this.cards[idx].stat = data['toBeClaimed'].length;
                  this.cards[idx].data = data['toBeClaimed'];

                  componentRef.instance.data = this.cards[2]['data'];
                  componentRef.instance.title = this.cards[2]['title'];
                  componentRef.instance.stat = this.cards[2]['stat'];
                  this.cdr.detectChanges();
                  });
              }
              else {
                  let idx = this.cards.findIndex((x => x.title == 'To Be Claimed'));
                  this.cards[idx].stat = this.adminData['toBeClaimed'].length;
                  this.cards[idx].data = this.adminData['toBeClaimed'];

                  componentRef.instance.data = this.cards[2]['data'];
                  componentRef.instance.title = this.cards[2]['title'];
                  componentRef.instance.stat = this.cards[2]['stat'];
                  this.cdr.detectChanges();
                };
                componentRef.location.nativeElement['style']['width'] = '22.5%';
                break;
              };

              case (9): {
                //console.log('adding widget 8: To be claimed')
                const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
                if (!this.adminData) {
                  this.homeDashboardService.getDataForAdminDashboard().subscribe(data =>
                    {this.adminData == data;
                    let idx = this.cards.findIndex((x => x.title == 'Withdrawn Activities'));
                    this.cards[idx].stat = data['withdrawnActivities'].length;
                    this.cards[idx].data = data['withdrawnActivities'];

                    componentRef.instance.data = this.cards[3]['data'];
                    componentRef.instance.title = this.cards[3]['title'];
                    componentRef.instance.stat = this.cards[3]['stat'];
                    this.cdr.detectChanges();
                    });
                }
                else {
                    let idx = this.cards.findIndex((x => x.title == 'Withdrawn Activities'));
                    this.cards[idx].stat = this.adminData['withdrawnActivities'].length;
                    this.cards[idx].data = this.adminData['withdrawnActivities'];

                    componentRef.instance.data = this.cards[3]['data'];
                    componentRef.instance.title = this.cards[3]['title'];
                    componentRef.instance.stat = this.cards[3]['stat'];
                    this.cdr.detectChanges();
                  };
                  componentRef.location.nativeElement['style']['width'] = '22.5%';
                  break;
                };
        }
        this.cdr.detectChanges();
      });
  }

  showWidgetOptions() {
    this.showHideWidgetOptions = !this.showHideWidgetOptions;
  }

  // -------------- Widgets

  // setData(data, resetLocal: boolean) {

  //   this.regRequests = data['regRequests']
  //   this.awaitingCerts = data['awaitingCertificates']
  //   this.toBeClaimed = data['toBeClaimed']
  //   this.withdrawnActivities = data['withdrawnActivities']

  //   let idx = this.cards.findIndex((x => x.title == 'Registration Requests'));
  //   this.cards[idx].stat = this.regRequests.length;
  //   this.cards[idx].data = this.regRequests;

  //   idx = this.cards.findIndex((x => x.title == 'Awaiting Certificates'));
  //   this.cards[idx].stat = this.awaitingCerts.length;
  //   this.cards[idx].data = this.awaitingCerts;

  //   idx = this.cards.findIndex((x => x.title == 'To Be Claimed'));
  //   this.cards[idx].stat = this.toBeClaimed.length;
  //   this.cards[idx].data = this.toBeClaimed;

  //   idx = this.cards.findIndex((x => x.title == 'Withdrawn Activities'));
  //   this.cards[idx].stat = this.withdrawnActivities.length;
  //   this.cards[idx].data = this.withdrawnActivities;

  //   if (resetLocal) {
  //     this.saveDataToLocalDb(data);
  //   }

  //   this.cdr.detectChanges();
  // }

  saveDataToLocalDb(dataToSave: any) {
    this.adminDashReporService.clear();
    dataToSave.lastSaved = new Date();
    // Test: let now = new Date(); dataToSave.lastSaved = now.setHours(now.getHours() - 2);

    this.adminDashReporService.add(dataToSave, 1);
  }

  setFavouritesData(data) {
    this.myFavouriteReports = data;
  }

}
