import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EmployerEventsService } from '../employer-events.service';
import { TimeRangeHelperService } from './../../../shared/services/time-range-helper.service'
import { combineLatest } from 'rxjs';
import { UserTableService } from 'src/app/core/database/user-table.service';

@Component({
  selector: 'app-add-employer-event',
  templateUrl: './add-employer-event.component.html',
  styleUrls: ['./add-employer-event.component.scss']
})
export class AddEmployerEventComponent implements OnInit {
  eventForm: UntypedFormGroup;
  eventTypeSelection;
  startTimeSelection: string[];
  endTimeSelection: string[] = [];
  endTimeSelectionTimeOnly: string[] = [];
  isDisplayNewAppointmentOptions: boolean = false;
  isDisplayAppraisalOptions: boolean = false;
  staff;
  eventTypeInitialForEdit;
  staffInitialForEdit;
  branchInitialForEdit;
  selectedAttendeesInitialForEdit;
  isLoaded: boolean = false;
  isEdit;
  extraCalendarinfo;
  isHealthAndSafety: boolean;
  canDelete: boolean = false;

  public Editor = ClassicEditor;

  editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic']
  }
  currentUser: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddEmployerEventComponent>,
    private fb: UntypedFormBuilder,
    private employerEventsService: EmployerEventsService,
    private timeRangeHelperService: TimeRangeHelperService,
    private userTableService: UserTableService
  ) { }

  ngOnInit(): void {
    const staff$ = this.employerEventsService.getStaff();
    const eventTypes$ = this.employerEventsService.getEventTypes();

    if (this.data.isDashboard) {
      const combined = combineLatest(staff$, eventTypes$);
      combined.subscribe(([staff$, eventTypes$]) => {
        this.eventTypeSelection = eventTypes$
        this.staff = staff$
        this.buildForm();
        this.isLoaded = true;
      })
    }
    else if (this.data.isEdit) {
      this.isHealthAndSafety = this.data.eventType == 'Vetting' || this.data.eventType == 'Monitor' || this.data.eventType == 'ELI';
      const extraCalendarinfo$ = this.employerEventsService.getEventCalendarInfo(this.data.eventId);
      const combined = combineLatest(staff$, eventTypes$, extraCalendarinfo$);
      combined.subscribe(([staff$, eventTypes$, extraCalendarinfo$]) => {
        this.eventTypeSelection = eventTypes$
        this.staff = staff$
        this.extraCalendarinfo = extraCalendarinfo$;
        this.buildForm();
        this.isLoaded = true;
        this.setValidators();
        this.userTableService.get(1)
        .then(response => {
          this.currentUser = response['staffId'];
          if (this.currentUser == this.data.staffId) {
            this.canDelete = true;
          } 
        });
      });
    }
    else {
      const combined = combineLatest(staff$, eventTypes$);
      combined.subscribe(([staff$, eventTypes$]) => {
        this.eventTypeSelection = eventTypes$
        this.staff = staff$
        this.buildForm();
        this.isLoaded = true;
      })
    }

    this.startTimeSelection = this.timeRangeHelperService.timeselection;
    // console.log('st', this.startTimeSelection)
  }


  eventSelected() {
    const eventType = this.eventForm.get('eventType').value
    console.log('checking', eventType);
    if (eventType) {
      this.isHealthAndSafety = false;
      const eventTypeValue = eventType.employereventtype
      if (eventTypeValue == "Appointment" || eventTypeValue == "Email") {
        this.isDisplayNewAppointmentOptions = true;
      }
      else if (eventTypeValue == "ELI" || eventTypeValue == "Monitor"  || eventTypeValue == "Vetting") {
        this.isHealthAndSafety = true;
        this.isDisplayNewAppointmentOptions = false;
        this.isDisplayAppraisalOptions = true
      }
      else {
        this.isDisplayNewAppointmentOptions = false;
        this.isDisplayAppraisalOptions = false
      }
      this.setValidators();
    }
  }

  setValidators() {
    const eventType = this.eventForm.get('eventType').value
    const eventTypeValue = eventType.employereventtype
    if (eventTypeValue == "Appointment" || eventTypeValue == "Email") {
      this.eventForm.get('eventDate').setValidators(Validators.required);
      this.eventForm.get('plannedEventDate').clearValidators();
      this.eventForm.get('by').clearValidators();
    }
    else if (eventTypeValue == "ELI" || eventTypeValue == "Monitor"  || eventTypeValue == "Vetting") {
      this.eventForm.get('eventDate').clearValidators();
      this.eventForm.get('plannedEventDate').setValidators(Validators.required);
      this.eventForm.get('by').setValidators(Validators.required);
    }
    else {
      this.eventForm.get('eventDate').setValidators(Validators.required);
      this.eventForm.get('plannedEventDate').clearValidators();
      this.eventForm.get('by').clearValidators();
    }
  }

  setEndTimeList(startTime: string) {
    var startIndex = this.startTimeSelection.indexOf(startTime) + 1;
    var endTimeSelectionTimes = this.startTimeSelection.slice(startIndex);
    var index = 0;
    endTimeSelectionTimes.forEach(endSelectiontime => {
      this.endTimeSelectionTimeOnly.push(endSelectiontime)
      this.endTimeSelection.push(endSelectiontime + ' (' + this.timeRangeHelperService.durations[index] + ')')
      index++;
    });
  }

  buildForm() {
    this.isEdit = this.data.isEdit;
    var startTime = ''
    var endTime = ''
    if (!this.isEdit) {
      startTime = this.timeRangeHelperService.getDeaultStartTime();
      this.setEndTimeList(startTime);
      endTime = this.endTimeSelection[0]
    }
    else{
      startTime = this.extraCalendarinfo.startTime;
      this.setEndTimeList(startTime);
      endTime = this.extraCalendarinfo.endTime;
    }

    if (this.data.eventType) {
      this.eventTypeInitialForEdit = this.eventTypeSelection.filter(({ employereventtype }) => employereventtype == this.data.eventType)[0];
    }

    if (this.data.staffId) {
      this.staffInitialForEdit = this.staff.filter(({ staffId }) => staffId == this.data.staffId)[0];
   //   console.log('staff initial', this.staffInitialForEdit)
    }

    if (this.data.forBranch) {
      this.branchInitialForEdit = this.data.associatedPlacements.filter(({ placementName }) => placementName == this.data.forBranch)[0];
    }

    if(this.extraCalendarinfo && this.extraCalendarinfo.attendees.length > 0){

      this.selectedAttendeesInitialForEdit = this.staff.filter(({ email }) =>   this.extraCalendarinfo.attendees.includes(email));
    }

  //  console.log('inital staff', this.selectedAttendeesInitialForEdit)
    this.eventForm = this.fb.group({
      subject: this.extraCalendarinfo ? this.extraCalendarinfo.subject : null,
      message: this.data.notes,
      location: this.extraCalendarinfo ? this.extraCalendarinfo.location : null,
      eventDate: this.data.eventDate,
      plannedEventDate: this.data.plannedEventDate,
      actualEventDate: this.data.actualEventDate,
      startTime: startTime,
      endTime: endTime,
      selectedStaff: [this.selectedAttendeesInitialForEdit],
      by: this.staffInitialForEdit,
      eventType: [this.eventTypeInitialForEdit, Validators.required],
      IamTheOrganiser: false,
      IsEmployerLevelEvent: this.data.isEmployerLevelSelected,
      forBranch: this.branchInitialForEdit // is employer selected and want to specify branch
    });

    if (this.data.eventType) {
      this.eventSelected();
    }
    this.onChanges();
  }

  onChanges(): void {
    this.eventForm.get('startTime').valueChanges.subscribe(val => {
      this.endTimeSelection = []
      this.endTimeSelectionTimeOnly = []
      this.setEndTimeList(val);

      var endTimeValueWithoutDuration = this.eventForm.get('endTime').value.split(" ")[0]
      var indexOfEndTime = this.endTimeSelectionTimeOnly.indexOf(endTimeValueWithoutDuration)
      if (indexOfEndTime == -1) {
        const endTime = this.endTimeSelection[0]
        this.eventForm.get('endTime').setValue(endTime);
      }
      else {
        var endTime = this.endTimeSelection[indexOfEndTime]
        this.eventForm.get('endTime').setValue(endTime);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOK(): void {
    console.log("ok")
    if (this.isDisplayNewAppointmentOptions && !this.eventForm.value.IamTheOrganiser && !this.eventForm.value.selectedStaff) {
      const snackBarMessage = "Please select at least one person to attend this."
      this.snackBar.open(snackBarMessage,
        'Close', { duration: 5000 });
    } else if (this.isHealthAndSafety && !this.eventForm.get('by').value) {
      const snackBarMessage = "Please select staff member."
      this.snackBar.open(snackBarMessage,
        'Close', { duration: 5000 });
    } else {
      console.log('ready to emit')
      var formSave = { 'eventId': this.data.eventId, 'isEdit': this.isEdit, 'formValue': this.eventForm.value }
      console.log('ready to emit', formSave)
      this.dialogRef.close(formSave);
    }

  }

  deleteEvent(): void {
    if(window.confirm("Are you sure you want to delete this event?")) {
      console.log('deleted', this.data.eventId);
      this.dialogRef.close(['delete', this.data.eventId]);
      this.snackBar.open("Event deleted", "Success", {
        duration: 3000,
      });
    }
  }



}
