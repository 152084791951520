import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';

@Component({
  selector: 'app-early-leavers-report',
  templateUrl: './early-leavers-report.html'
})
export class EarlyLeaversReportComponent {

  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  name = 'Early Leavers Report';
  reportVariant = ReportVariant.EarlyLeavers;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: [
        'traineeId',
        'status',
        'fullName',
        'schemeShort',
        'sector',
        'funded',
        'previousPot',
        'previous8WeekEvent',
        'reason',
        'bdSource',
        'daysSinceReview',
        'monthsOnProgram',
        'laptopReturned',
        'within8Weeks',
        'employerName',
        'timely',
        'ialpCompleted',
        'compliant',
        'eventDate'
      ]
    }
  ];
  defaultColumnOrder = [
    {
      type: 'default',
      columns: [
        'traineeId',
        'status',
        'fullName',
        'schemeShort',
        'sector',
        'funded',
        'previousPot',
        'previous8WeekEvent',
        'reason',
        'bdSource',
        'daysSinceReview',
        'monthsOnProgram',
        'laptopReturned',
        'within8Weeks',
        'employerName',
        'timely',
        'ialpCompleted',
        'compliant',
        'eventDate'
      ]
    }
  ];
  dateFilters = ['eventDate'];
}
