import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'learner-details-entry',
  templateUrl: './learner-details-entry.component.html',
  styleUrls: ['./learner-details-entry.component.css']
})
export class LearnerDetailsEntryComponent implements OnInit {
  @Input() labelName;
  @Input() labelValue;
  @Input() inline = false;
  @Input() copyToClipboard = false;
  constructor(private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  copyValue(value) {

    if(value){
      this.copyMessage(value)
      this.snackBar.open(`${value} copied to clipboard`, "Close", {
        duration: 4000,
      });
    }
    else{
      this.snackBar.open("Nothing to copy", "Close", {
        duration: 4000,
      });
    }
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
