import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-create-mass-learner-event-modal',
  templateUrl: './create-mass-learner-event-modal.component.html',
  styleUrls: ['./create-mass-learner-event-modal.component.scss']
})
export class CreateMassLearnerEventModal implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateMassLearnerEventModal>,
    public fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) { }

  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  eventForm: FormGroup;
  allLearners: any[];
  filteredLearners : any[];
  noOptions: boolean = false;

  onlyMyLearners: boolean = true;
  onlyActiveLearners: boolean = true;
  onlyFavoriteLearners: boolean = false;

  private _searchFilter: string = '';
  private debounceTimer: NodeJS.Timer;

  get searchFilter(): string {
    return this._searchFilter;
  }

  set searchFilter(value: string) {
    this._searchFilter = value;

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.filterData();
    }, 300);
  }

  ngOnInit(): void {
    this.allLearners = this.data.learners;
    this.buildForm();

    this.filterData();
  }

  buildForm() {
    this.eventForm = this.fb.group({
      learners: new UntypedFormControl([], Validators.required),
    });
  }

  public toggleOnlyMyLearners(value) {
    this.onlyMyLearners = value.checked;
    this.filterData();
  }

  public toggleOnlyActiveLearners(value) {
    this.onlyActiveLearners = value.checked;
    this.filterData();
  }

  public toggleOnlyFavoriteLearners(value) {
    this.onlyFavoriteLearners = value.checked;
    this.filterData();
  }

  filterData() {
    if(this.onlyMyLearners && this.onlyActiveLearners && this.onlyFavoriteLearners) {
      this.filteredLearners = this.allLearners.filter(x => x.myLearner && !x.beenTerminated && x.isFavourite);
    }
    else if(this.onlyMyLearners && this.onlyActiveLearners && !this.onlyFavoriteLearners) {
      this.filteredLearners = this.allLearners.filter(x => x.myLearner && !x.beenTerminated);
    }
    else if(this.onlyMyLearners && !this.onlyActiveLearners && !this.onlyFavoriteLearners) {
      this.filteredLearners = this.allLearners.filter(x => x.myLearner );
    }
    else if(this.onlyMyLearners && !this.onlyActiveLearners && this.onlyFavoriteLearners) {
      this.filteredLearners = this.allLearners.filter(x => x.myLearner && x.isFavourite );
    }
    else if(!this.onlyMyLearners && this.onlyActiveLearners && !this.onlyFavoriteLearners) {
      this.filteredLearners = this.allLearners.filter(x => !x.beenTerminated );
    }
    else if(!this.onlyMyLearners && this.onlyActiveLearners && this.onlyFavoriteLearners) {
      this.filteredLearners = this.allLearners.filter(x => !x.beenTerminated && x.isFavourite);
    }
    else if(!this.onlyMyLearners && !this.onlyActiveLearners && this.onlyFavoriteLearners) {
      this.filteredLearners = this.allLearners.filter(x => x.isFavourite );
    }
    else {
      this.filteredLearners = [...this.allLearners];
    }

    if(this.notNullOrEmpty(this.searchFilter)) {
      this.filteredLearners = this.filteredLearners.filter(x => x.fullName?.toLowerCase().includes(this.searchFilter.toLowerCase()));
    }

    this.noOptions = this.filteredLearners.length == 0;
    this.cdr.detectChanges();
  }

  notNullOrEmpty(value: string) {
    return value !== null && value.trim() !== '';
  }

  showOption(option) {
    if(!this.eventForm.get('learners').value.includes(option)) {
      return 'flex';
    }

    return 'none';
  }

  // When you open the dropdown it scrolls to the top, this makes sure the viewport matches
  dropdownOpened() {
    if(this.viewport) {
      this.viewport.scrollToIndex(0);
    }
  }

  submitForm() {
    if(this.eventForm.valid) {
      const data = this.eventForm.value.learners.map(x => {
        return {fullName:x.fullName, traineeId: x.traineeId, pot: x.pot }
      });
      this.dialogRef.close(data);
    }
  }

}
