import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../core/services/user.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { AuthErrors } from '../enums/auth-errors';


@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private snackBar: MatSnackBar,
    private userService: UserService,
    private authService: AuthService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 0:
            this.userService.connectionIssueNotification();
            this.snackBar.open("Please check internet connection", "Error", {
              duration: 3000,
            });
            break;
          case 401:
            return this.authService.refreshTokenSilent().pipe(
              switchMap(() => {
                request = this.authService.addAuthHeader(request);
                return next.handle(request);
              }),
              catchError(error => {
                if (error.status) {
                  return this.intercept(request, next);
                }
                else {
                  this.router.navigate([`auth-error`], { queryParams: { error: AuthErrors.RefreshTokenError } });
                }
              }));
          default:
            break;
        }
        let errorString = "";
        if (typeof err.error === 'string') {
          errorString = err.error;
        }
        if (errorString === "" && err.message != null) {
          errorString = err.message;
        }
        if (errorString === "" && err.statusText != null) {
          errorString = err.statusText;
        }
        return throwError(errorString);
      }))
  }
}
