import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-save-report-filters-dialog',
  templateUrl: './save-report-filters-dialog.component.html',
  styleUrls: ['./save-report-filters-dialog.component.scss']
})
export class SaveReportFiltersDialogComponent implements OnInit {
  isSaveFiltersForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SaveReportFiltersDialogComponent>,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOK(): void {
    if(!this.isSaveFiltersForm.valid) {
      let snackBarMessage = "";
      if(this.isSaveFiltersForm.controls.name.errors.required) {
        snackBarMessage = "Please choose a name for this filter."
      } else if(this.isSaveFiltersForm.controls.name.errors.maxlength) {
        snackBarMessage = "The name you have chosen is too long."
      }
      this.snackBar.open(snackBarMessage,
        'Close', { duration: 5000 });
    } else {
      this.dialogRef.close(this.isSaveFiltersForm.value);
    }
  }

  buildForm() {
    this.isSaveFiltersForm = this.fb.group({
      isSaveFilters: true,
      name: [null, [Validators.required, Validators.maxLength(70)]]
    });
  }
}
