import { Component } from "@angular/core";
import { SharedFunctionsService } from "src/app/core/services/shared-functions.service";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { ISummaryGroupBy } from "src/app/shared/interfaces/generic-interfaces";

@Component({
  selector: 'app-wellbeing-report',
  templateUrl: './wellbeing-report.component.html'
})

export class WellbeingReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }
  reportVariant = ReportVariant.Wellbeing;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'contract', 'subcontractor', 'programmeType', 'scheme', 'sector', 'centre', 'assessor', 'lastCompleteAverage', 'growth', 'distance']
    }
  ];
  dateFilters = ['startDate', 'expCompletionDate', 'terminationDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];
}
