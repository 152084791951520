import { ReportVariant } from "./../../shared/enums/report-variant";
import { DownloadService } from "./download.service";
import { ITableFilter } from "./../../shared/interfaces/generic-interfaces";
import { Injectable } from "@angular/core";
import moment from "moment";
import * as _ from "underscore";
import { reportParameters } from "src/app/shared/models/report-parameters";

@Injectable({
  providedIn: "root",
})
export class SharedFunctionsService {
  constructor(private downloadService: DownloadService) { }

  public unCamelCase(str: string) {
    return (
      str
        // insert a space between lowercase and number
        .replace(/([a-z])([0-9])/g, "$1 $2")
        // insert a space between uppercase and number
        .replace(/([0-9])([A-Z])/g, "$1 $2")
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  }

  public toCamelCase(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (leftTrim, index) =>
        index === 0 ? leftTrim.toLowerCase() : leftTrim.toUpperCase()
      )
      .replace(/\s+/g, "");
  }

  public genericOrdered(options: any[], fieldname = ""): any {
    const sorted = options.sort(function (a, b) {
      if (fieldname) {
        if (a[fieldname] && b[fieldname]) {
          const sortA = a[fieldname].isNaN ? a[fieldname].toUpperCase() : a[fieldname];
          const sortB = b[fieldname].isNaN ? b[fieldname].toUpperCase() : b[fieldname];
          if (sortA > sortB) {
            return 1;
          }
          if (sortA < sortB) {
            return -1;
          }
        }
      } else {
        const sortA = a.toUpperCase().isNaN ? a.toUpperCase() : a;
        const sortB = b.toUpperCase().isNaN ? b.toUpperCase() : b;
        if (sortA > sortB) {
          return 1;
        }
        if (sortA < sortB) {
          return -1;
        }
      }
      return 0;
    });
    return sorted;
  }

  public genericOrderedDate(
    options: any[],
    fieldname: string,
    isDescending: boolean,
    useMoment = false
  ): any {
    const sorted = options.sort(function (a, b) {
      if (a[fieldname] && b[fieldname]) {
        let sortA;
        let sortB;
        if (!useMoment) {
          sortA = a[fieldname];
          sortB = b[fieldname];
        } else {
          sortA = moment(a[fieldname], "DD-MM-YYYY").toDate();
          sortB = moment(b[fieldname], "DD-MM-YYYY").toDate();
        }
        if (sortA < sortB) {
          return isDescending ? 1 : -1;
        }
        if (sortA > sortB) {
          return isDescending ? -1 : 1;
        }
      }
      return 0;
    });
    return sorted;
  }

  public isDateTimeFromColumnName(value, column) {
    let isDateTime = false;
    if ([
      "startTime",
      "endTime",
      "workshopDate"
    ].includes(column)) {
      isDateTime = !isNaN(Date.parse(value));
    }
    return isDateTime;
  }
  public isDateFromColumnName(value, column) {
    let isADate = false;
    if (
      [
        "date",
        "dateSubmitted",
        "startDate",
        "terminationDate",
        "expectedEndDate",
        "lastReview",
        "nextReview",
        "datePlanned",
        "createdDate",
        "regDate",
        "certReceived",
        "activityExpectedCompletionDate",
        "activityStartDate",
        "activityTerminationDate",
        "expectedCompletionDate",
        "termDate",
        "objectiveStatusDate",
        "awarded",
        "ialpVersionCompDate",
        "twentyEightDays",
        "fifteenDays",
        "actualDate",
        "expectedStartDate",
        "actualIqaDate",
        "dateAllocatedForIqa",
        "dateActual",
        "dateSignedOff",
        "planWeekStartDate",
        "certAppliedFor",
        "nextPlannedVisit",
        "plannedVisitDate",
        "psmDate",
        "notificationSent",
        "deadline",
        "agreedStartDate",
        "approvedDate",
        "referralDate",
        "weekstartdate",
        "weekStartDate",
        "lastContact",
        "dateRequested",
        "expectedCompletionDate",
        "dateOfBirth",
        "week8And12",
        "firstAttended",
        "dob",
        'signInDate',
        'weekStart',
        'aonDate',
        'comDate',
        'dlDate',
        'eventDate',
        'reviewDate',
        'dateEntered',
        'returnDate',
        'newStartDate',
        'dateChanged',
        'oldDateChanged',
        'weekStarting',
        'termChanged',
        'suspendedDate',
        'returnDate',
        'nextEli',
        'lastEli',
        'nextMonitor',
        'lastMonitor',
        'nextVetting',
        'lastVetting',
        'lastSignInDate',
        'registrationDate',
        'dateDue',
        'previous8WeekEvent',
        'expCompletionDate',
        'firstContact',
        'ialpDate',
        'weekCommencing',
        'exitProcessDate',
        'dateDeadline',
        'dateLearnerSign',
        'dateAssessorSign',
        'terminationDate',
        'dateAssessment',
        'iqaDate',
        'confirmatoryTestDate',
        'qualificationClaimDate',
        'dateRecievedForMarking',
        'dateMarkingReturned',
        'dateAllocatedForMarking',
        'qualStartDate',
        'mostRecentReview',
        'mostRecentAssessmentPlan',
        'mostRecentSignedAssessmentPlan',
        'mostRecentCancellationDate'
      ].includes(column)
    ) {
      isADate = !isNaN(Date.parse(value));
    }
    return isADate;
  }

  public customFilterPredicate(data: any, filters: ITableFilter[]): boolean {
    for (let i = 0; i < filters.length; i++) {
      const fitsThisFilter = data[filters[i].column] == filters[i].value;
      if (!fitsThisFilter) {
        return false;
      }
    }
    return true;
  }

  public calcOptimumRows(rowAdjustment = 0) {
    let rows;
    console.log("window.innerHeight", window.innerHeight);
    if (window.innerHeight >= 1290) { rows = 16; }
    else if (window.innerHeight >= 1200) { rows = 15; }
    else if (window.innerHeight >= 1100) { rows = 13; }
    else if (window.innerHeight >= 980) { rows = 12; }
    else if (window.innerHeight >= 900) { rows = 10; }
    else if (window.innerHeight >= 780) { rows = 9; }
    else if (window.innerHeight >= 660) { rows = 7; }
    else { rows = 5; }

    rows = rows + rowAdjustment;

    return rows;
  }

  public groupBy(list, iteratee) {
    // Option 1
    return _.groupBy(list, iteratee);

    // Option 2 - keep here in case it is ever preferred
    return list.reduce(function (rv, x) {
      (rv[x[iteratee]] = rv[x[iteratee]] || []).push(x);
      return rv;
    }, {});
  }

  public genericMapFilterData(
    value: any,
    type: any,
    mapId: string,
    mapName: string,
    model: { options: any },
    dataSourceFiltered: any
  ) {
    // let tempDataSource = this.masterTableData.filter(a => valueArray.indexOf(a[type]) != -1)
    //  .map(obj => ({[mapId]: obj[mapId], [mapName]: obj[mapName]}));

    // TODO: keep the following line to maybe test more fully - it tightens up the filter
    // drop-downs when adding filters, but perhaps to the detriment of a level of usablitlity.
    const tempDataSource = dataSourceFiltered.map((obj) => ({
      [mapId]: obj[mapId],
      [mapName]: obj[mapName],
    }));

    if (tempDataSource) {
      const uniqueOptions = _.uniq(tempDataSource, (x) => x[mapId]);
      model.options = this.genericOrdered(uniqueOptions, mapName);
      model.options.unshift({ [mapId]: 0, [mapName]: `< All ${[mapName]}s >` });
    }
    return model;
  }

  public export(
    data: any[],
    displayedColumns: string[],
    name: any,
    fileType = "csv",
    useOriginalCasing = false,
    apendDate = true,
    includeHeader = true,
  ): string {
    const mappedData = [];

    data.forEach(function (v) {
      const j = {};
      displayedColumns.forEach((prop) => {
        j[prop] = v[prop];
      });

      for (const key in j) {
        if (Object.prototype.hasOwnProperty.call(j, key)) {
          j[key.charAt(0).toUpperCase() + key.substring(1)] = j[key];
          delete j[key];
        }
      }

      mappedData.push(j);
    });

    if (typeof name == "number") {
      name = ReportVariant[name];
    }
    if (!useOriginalCasing) {
      name = this.unCamelCase(name);
    }

    return this.downloadService.downloadFile(
      mappedData,
      name,
      fileType,
      apendDate,
      includeHeader
    );
  }

  public exportBlankTemplate(
    displayedColumns: string[],
    name: any,
    fileType = "csv",
    useOriginalCasing = false,
    apendDate = true
  ): string {
    const mappedData = [];
    const j = {};

    displayedColumns.forEach((prop) => {
      j[prop] = "";
    });

    mappedData.push(j);

    if (!useOriginalCasing) {
      name = this.unCamelCase(name);
    }

    return this.downloadService.downloadFile(
      mappedData,
      name,
      fileType,
      (apendDate == apendDate)
    );
  }

  public createParametersString(reportParameters: reportParameters): string {
    let parameters = "";
    parameters += reportParameters.programmeSelect
      ? `ProgrammeSelect=${reportParameters.programmeSelect}`
      : "";
    parameters += reportParameters.esw
      ? (parameters ? "&" : "") + `Esw=${reportParameters.esw}`
      : "";
    parameters += reportParameters.contractId
      ? (parameters ? "&" : "") + `ContractId=${reportParameters.contractId}`
      : "";
    parameters += reportParameters.providerId
      ? (parameters ? "&" : "") + `ProviderId=${reportParameters.providerId}`
      : "";
    parameters += reportParameters.centreId
      ? (parameters ? "&" : "") + `CentreId=${reportParameters.centreId}`
      : "";
    parameters += reportParameters.programmeId
      ? (parameters ? "&" : "") + `ProgrammeId=${reportParameters.programmeId}`
      : "";
    parameters += reportParameters.schemeId
      ? (parameters ? "&" : "") + `SchemeId=${reportParameters.schemeId}`
      : "";
    parameters += reportParameters.sectorId
      ? (parameters ? "&" : "") + `SectorId=${reportParameters.sectorId}`
      : "";
    parameters += reportParameters.assessorId
      ? (parameters ? "&" : "") + `AssessorId=${reportParameters.assessorId}`
      : "";
    parameters += reportParameters.yearOffset
      ? (parameters ? "&" : "") + `YearOffset=${reportParameters.yearOffset}`
      : "";
    parameters += reportParameters.groupBy
      ? (parameters ? "&" : "") + `GroupBy=${reportParameters.groupBy}`
      : "";
    return parameters;
  }


  public convertGenderString(gender: string): string {
    let formattedGender = '';
    gender = gender.toLowerCase();
    switch (gender) {
      case 'male':
        formattedGender = 'm';
        break;
      case 'female':
        formattedGender = 'f';
        break;
      case 'm':
        formattedGender = 'Male';
        break;
      case 'f':
        formattedGender = 'Female';
        break;
      default:
        console.log(`Unknown Gender: ${gender}`);
        break;
    }

    return formattedGender;
  }
}
