import { UserService } from 'src/app/core/services/user.service';
import { EmployerTableService } from './../core/database/employer-table-service';
import { EmployerSearchTableService } from './../core/database/employer-search-table.service';
import { EmployerSearchService } from './employer-search.service';
import { IUserInfo } from 'src/app/shared/interfaces/user-info';
import { UserTableService } from './../core/database/user-table.service';
import { Router } from '@angular/router';
import { ENTER } from '@angular/cdk/keycodes';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';
import { ISearchFilter } from '../shared/interfaces/search-filter';
import { IGenericTextValuePair } from '../shared/interfaces/generic-text-value-pair';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddEmployerDialogComponent } from './add-employer-dialog/add-employer-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedFunctionsService } from '../core/services/shared-functions.service';
import * as _ from 'underscore';
import { exit } from 'process';

export interface IEmployerSearchResult {
  employers: any[],
  branches: any[],
  combined: any[]
}

@Component({
  selector: 'employer-search',
  templateUrl: './employer-search.component.html',
  styleUrls: ['./employer-search.component.scss']
})
export class EmployerSearchComponent implements OnInit {

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler() {
    this.employerSearchTableService.clear();
    this.employerTableService.clear();
  }

  sideBarOpen;

  searchTypeString: string = 'Employer or Branch';
  description: string;
  searchResultsRaw: IEmployerSearchResult = { employers: [], branches: [], combined: [] };
  searchResultsFiltered: IEmployerSearchResult = { employers: [], branches: [], combined: [] };
  searchForm: UntypedFormGroup;
  searchTypes: any[];
  selectedSearchType: IGenericTextValuePair;
  refineByKeywordText: string;
  icon: string;
  width: any;
  checkWidth: any;
  userInfo: IUserInfo;

  // Filters
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  readonly separatorKeysCodes: number[] = [ENTER];
  filters: ISearchFilter[] = [];
  options: Observable<string[]>;
  autoCompleteOptions: string[] = [];
  rawAutoCompleteOptions: any;

  noResultsMessage: string;
  showSpinner: boolean;

  selectedEmployerBranchName: string
  selectedPostCode: string;
  selectedPostTown: string;
  selectedContactName: string;
  selectedAddressPart: string;

  employerBranchOptions: any[];
  contactOptions: any[];
  postCodeOptions: any[];
  postTownOptions: any[];

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private userTableService: UserTableService,
    private employerTableService: EmployerTableService,
    private employerSearchService: EmployerSearchService,
    private employerSearchTableService: EmployerSearchTableService,
    private userService: UserService,
    private sharedFunctions: SharedFunctionsService
    ) { }



  ngOnInit(): void {
    this.getCurrentUser();
    this.getEmployerSearchList();
    this.populateSearchTypeDropDownList();
    this.buildForm();
    this.selectedSearchType = { value: 'EmployerBranch', text: 'Employer or Branch' };
    this.refineByKeywordText = "Refine by keyword";
    this.icon = "filter_list";
    this.width = { 'width': "50%" };

    this.onChanges();

    this.getSearchResultsFromLocalDb();

    this.options = this.searchForm.get('searchText').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterData(value).slice(0, 10))
      );
  }

  resetFilterOptions() {
    this.employerBranchOptions = [];
    this.contactOptions = [];
    this.postTownOptions = [];
    this.postCodeOptions = [];
  }

  private filterData(value: string): string[] {
    if (value && value.length > 1 && this.autoCompleteOptions) {
      const filterValue = value.toLowerCase();
      return this.autoCompleteOptions.filter(
        option => option.toLowerCase().includes(filterValue)).slice(0, 10);
    }
    else {
      return this.autoCompleteOptions.slice(0, 10);
    }
  }

  onChanges(): void {
    this.searchForm.get('searchText').valueChanges.subscribe(val => {
      //console.log('search text', val);
      this.noResultsMessage = '';
    });
  }

  getCurrentUser() {
    this.userTableService.get(1)
      .then(staffResponse => {
        if (!staffResponse || staffResponse == undefined || staffResponse == null) {
          this.router.navigate(['']);
        } else {
          this.userInfo = staffResponse;
          this.userService.getNotifications(staffResponse.staffId).subscribe(
            notificationsResponse => {
              staffResponse.countUnreadNotifications = notificationsResponse['countUnreadNotifications'];
              this.userService.updateUserNotifications(staffResponse);
              this.cdr.detectChanges();
            })
        }
      });
  }

  buildForm() {
    this.searchForm = this.fb.group({
      searchText: null,
      searchDateOfBirth: null,
      searchFilter: null,
      searchType: null,
      employerBranchOptions: this.employerBranchOptions,
      contactOptions: this.contactOptions,
      postTownOptions: this.postTownOptions,
      postCodeOptions: this.postCodeOptions,
      addressPartFilter: null
    })
  }

  get filterStyle() {
    return this.width;
  }

  get filterStyleTickbox() {
    return this.checkWidth;
  }

  setLocalFilter(value: string, field: string) {
    if (value) {
      this.showSpinner = true;
      this.clearSelected(field);
      let newFilterEntry = {
        type: 'localFilter',
        value: field != 'addressPart' ? value[field] : value,
        field: field
      };

      this.filters.push(newFilterEntry);

      console.log('setLocalFilter', field, value);
      console.log('Employer or Branch: ', this.selectedSearchType);
      this.searchLocal();
    } else {
      this.clearSelected(field);
    }
  }

  setAddressPartFilter(event: any) {
    if(event.key == 'Enter') {
      console.log('setAddressPartFilter', this.selectedAddressPart);
      this.setLocalFilter(this.selectedAddressPart, 'addressPart');
    }
  }

  clearSelected(field: string) {
    if(field) {
      let filterToRemove = this.filters.filter(f => f.field == field)[0];
      if(filterToRemove) {
        this.removeFilter(filterToRemove);
      }
    }
  }

  clearAllLocalFilters() {
    this.filters = this.filters.filter(f => f.type != 'localFilter');

    this.searchForm.controls['addressPartFilter'].setValue(null);
    this.searchForm.controls['employerBranchOptions'].setValue(null);
    this.searchForm.controls['contactOptions'].setValue(null);
    this.searchForm.controls['postCodeOptions'].setValue(null);
    this.searchForm.controls['postTownOptions'].setValue(null);

    this.removeAllFilters();
  }

  setLocalFilterInFormControls() {
    const filters = this.filters.filter(f => f.type == 'localFilter');

    this.searchForm.controls['addressPartFilter'].setValue(_.findWhere(filters, {field: 'addressPart'})?.value);
    this.searchForm.controls['employerBranchOptions'].setValue(_.findWhere(filters, {field: 'employerBranch'})?.value);
    this.searchForm.controls['contactOptions'].setValue(_.findWhere(filters, {field: 'contact'})?.value);
    this.searchForm.controls['postCodeOptions'].setValue(_.findWhere(filters, {field: 'postCode'})?.value);
    this.searchForm.controls['postTownOptions'].setValue(_.findWhere(filters, {field: 'postTown'})?.value);
  }

  resetForm() {
    this.selectedSearchType = { value: 'EmployerBranch', text: 'Employer or Branch' };
    this.icon = "filter_list";
    this.width = { 'width': "50%" };

    this.searchForm.reset();
    this.buildForm();
    this.sideBarOpen = false;
    this.showSpinner = false;
    this.resetSearchResults();
    this.filters = [];
    this.noResultsMessage = '';
    this.employerSearchTableService.clear();
    this.employerTableService.clear();
    this.resetFilterOptions();

    this.options = this.searchForm.get('searchText').valueChanges
    .pipe(
      startWith(''),
      map(value => this.filterData(value).slice(0, 10))
    );

    return false;
  }

  onFocus(event) {
    //this.refineByKeywordText = "Why not try location or contact?"
    this.checkWidth = { 'width': '15%' };
    this.width = { 'width': "60%" };
    this.icon = 'search';
  }

  lostFocus(event) {
    //this.refineByKeywordText = "Refine by keyword";
    this.checkWidth = { 'width': '15%' };
    this.width = { 'width': "50%" };
    this.icon = 'filter_list'
  }

  populateSearchResultsFiltered() {
    if (this.searchResultsRaw != undefined && this.searchResultsRaw != null) {

        this.searchResultsFiltered.employers = this.searchResultsRaw.employers ? this.searchResultsRaw.employers.filter(
          element => {
            return element.selected == true;
          }
        ) : null;

        this.searchResultsFiltered.branches = this.searchResultsRaw.branches ? this.searchResultsRaw.branches.filter(
          element => {
            return element.selected == true;
          }
        ) : null;


        if (this.searchResultsFiltered.employers && this.searchResultsFiltered.branches) {
          let combined = this.searchResultsFiltered.employers.concat(this.searchResultsFiltered.branches);
          combined = this.sharedFunctions.genericOrdered(combined, 'employerName');
          this.searchResultsFiltered.combined = combined;
        } else if (this.searchResultsFiltered.employers) {
          let combined = this.sharedFunctions.genericOrdered(this.searchResultsFiltered.employers, 'employerName');
          this.searchResultsFiltered.combined = combined;
        } else if (this.searchResultsFiltered.branches) {
          let combined = this.sharedFunctions.genericOrdered(this.searchResultsFiltered.branches, 'employerName');
          this.searchResultsFiltered.combined = combined;
        }

        console.log('combined search results', this.searchResultsFiltered.combined);

    }
    this.showSpinner = false;
  }

  saveSearchResultsToLocalDb() {
    this.employerSearchTableService.clear();
    this.employerSearchTableService.add(this.searchResultsRaw, 0);
    this.employerSearchTableService.add(this.searchResultsFiltered, 1);
    this.employerSearchTableService.add(this.filters, 2);
  }

  getSearchResultsFromLocalDb() {
    this.showSpinner = true;
    this.employerSearchTableService.getAll().then(
      searchResults => {
        //console.log('from localDb', searchResults);
        if (searchResults.length) {
          this.searchResultsRaw = searchResults[0];
          this.searchResultsFiltered = searchResults[1];
          this.filters = searchResults[2];
          if(!this.employerBranchOptions || this.employerBranchOptions.length == 0) {
            this.organiseFilterOptions(this.searchResultsRaw.employers.concat(this.searchResultsRaw.branches));
          }
          this.restoreSelectedLocalFilters();
        }
        this.showSpinner = false;
        this.cdr.detectChanges();
      }
    )
  }

  restoreSelectedLocalFilters() {
    for(let i = 0; i < this.filters.length; i++) {
      if(this.filters[i].type === 'localFilter') {
        switch(this.filters[i].field) {
          case 'employerBranch':
            this.selectedEmployerBranchName = this.filters[i].value;
            break;
          case 'postTown':
            this.selectedPostTown = this.filters[i].value;
            break;
            case 'postCode':
            this.selectedPostCode = this.filters[i].value;
            break;
          case 'addressPart':
            this.selectedAddressPart = this.filters[i].value;
            break;
          case 'contact':
            this.selectedContactName = this.filters[i].value;
            break;
        }
      }
    }
  }

  addFilter(event: MatChipInputEvent = null, clickEvent: boolean = false): void {
    const input = clickEvent ? this.searchForm.controls['searchText'] : event.input;
    let value = clickEvent ? this.searchForm.controls['searchText'].value : event.value;

    // Add filter
    if ((value || '').trim()) {

      // Deal with legit (i.e. first) comma
      const commaPos = value.indexOf(',');

      if (commaPos != -1) {
        const leftPart = value.substring(0, commaPos).trim();
        const rightPart = value.substring(commaPos + 1, value.length).trim();
        value = `${rightPart} ${leftPart}`;
      }

      // deal with any superfluous commas
      value = value.replace(',', ' ').trim();

      const formValues = this.searchForm.value;
      formValues.searchText = value;

      const newFilterEntry = {
        type: this.isLocalSearch() ? 'localFilter' : 'externalSearch',
        value: value
      };

      this.filters.push(newFilterEntry);
      this.executeSearch();
    }

  }

  selectAllSearchResults(searchResults: any) {
    if (searchResults.employers) {
      for (let i = 0; i < searchResults.employers.length; i++) {
        searchResults.employers.forEach(item => {
          item.selected = true;
        });
      };
    };
    if (searchResults.branches) {
      for (let i = 0; i < searchResults.branches.length; i++) {
        searchResults.branches.forEach(item => {
          item.selected = true;
        });
      };
    };
  }

  removeFilter(filter: ISearchFilter): void {
    const index = this.filters.indexOf(filter);

    if (index >= 0) {
      this.filters.splice(index, 1);
      if (this.filters.length == 1) {
        this.filters[0].type = 'externalSearch';
        this.searchForm.value.searchFilter = this.filters[0].value;
        this.searchForm.value.searchText = this.filters[0].value;
      }
      this.searchLocal();
    }

    if (!this.hasExternalSearchFilter()) {
      this.resetForm();
    }
  }

  removeAllFilters(): void {
    this.showSpinner = true;
    if (!this.hasExternalSearchFilter()) {
      this.resetForm();
    }

    this.filters.splice(1, this.filters.length-1);
    this.filters[0].type = 'externalSearch';
    this.searchForm.value.searchFilter = this.filters[0].value;
    this.searchForm.value.searchText = this.filters[0].value;
    this.selectAllSearchResults(this.searchResultsRaw);
    this.populateSearchResultsFiltered();
    this.saveSearchResultsToLocalDb();
    this.showSpinner = false;
  }

  filtersOrdered(excludeExternal: boolean = true) {
    //console.log('filtersOrdered', this.filters.length);
    let sorted = this.filters.sort(function (a, b) {
      var nameA = a.type.toUpperCase();
      var nameB = b.type.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });

    if (excludeExternal) {
      sorted = sorted.filter(
        element => {
          return element.type != 'externalSearch';
        }
      )
    }

    return sorted;
  }

  searchTypeClass(filter: ISearchFilter) {
    let searchTypeClass: string;
    if (filter.type == 'externalSearch') {
      searchTypeClass = 'primary';
    } else {
      searchTypeClass = 'secondary';
    }
    //console.log('searchTypeClass', searchTypeClass);
    return searchTypeClass;
  }

  hasExternalSearchFilter() {
    return this.filters.filter(item => item.type == 'externalSearch').length > 0;
  }

  hasLocalSearchFilter() {
    return this.filters.filter(item => item.type == 'localFilter').length > 0;
  }

  isLocalSearch() {
    //console.log(this.filters);
    const hasResults =
      this.searchResultsRaw && ((this.searchResultsRaw.employers && this.searchResultsRaw.employers.length > 0) || (this.searchResultsRaw.branches && this.searchResultsRaw.branches.length > 0));
    this.sideBarOpen = hasResults;
    return hasResults;
  }

  filtersOfType(type: string) {
    // type='externalSearch': search database via API
    // type='localFilter': filter search results returned from API
    let filters: ISearchFilter[];
    filters = this.filters.filter(
      element => {
        return element.type == type;
      }
    );
    return filters;
  }

  externalFilterValue() {
    const filter = this.filtersOfType('externalSearch');
    return filter[0].value;
  }

  executeSearch() {
    this.noResultsMessage = '';
    const isLocal = this.isLocalSearch();
    console.log('executeSearch', isLocal);
    if (!isLocal) {
      this.searchExternal();
    }
  }

  onSelectedSearchType(event: MatSelectChange): void {
    this.selectedSearchType = {
      value: event.value,
      text: event.source.triggerValue
    };


    let type = event.value == 'Branch' ? "DT_V_VEMPLOYERS_BRANCHES" : event.value == 'Employer' ? "DT_V_VEMPLOYERS" : 'All';
    this.autoCompleteOptions = [];

    if (type == 'DT_V_VEMPLOYERS_BRANCHES') {
      this.searchTypeString = 'Branch'
    }
    else if (type == 'DT_V_VEMPLOYERS') {
      this.searchTypeString = 'Employer'
    }
    else {
      this.searchTypeString = 'Employer or Branch'
    }
    this.filterAutoCompleteOptions(this.rawAutoCompleteOptions, type);
  }

  filterAutoCompleteOptions(options, type) {
    for(let x in options) {
      if (type != 'All') {
        if (this.rawAutoCompleteOptions[x].source == type) {
          this.autoCompleteOptions.push(this.rawAutoCompleteOptions[x].name);
        }
      } else {
        this.autoCompleteOptions.push(this.rawAutoCompleteOptions[x].name);
      }
    }
  }

  resetSearchResults() {
    this.searchResultsRaw.employers = [];
    this.searchResultsRaw.branches = [];
    this.searchResultsFiltered.employers = [];
    this.searchResultsFiltered.branches = [];
    this.searchResultsFiltered.combined = [];
  }

  searchExternal() {
    if (!this.userInfo || this.userInfo == undefined || this.userInfo == null) {
      this.router.navigate(['']);
    }

    this.showSpinner = true;

    this.resetSearchResults();

    let searchText: string;
    const searchValues = this.searchForm.value;

    if (searchValues.searchFilter != null) {
      searchText = searchValues.searchFilter;
    } else {
      searchText = searchValues.searchText;
    }

    if(searchText) {
      this.employerSearchService.searchEmployer(searchText, this.selectedSearchType.value, this.userInfo.staffId)
        .subscribe(response => {
          //response can be null for branches or employers due to search type
          if (response.branches?.length > 0 || response.employers?.length > 0 ) {
            this.organiseSearchResult(response);
            this.sideBarOpen = true;
          } else {
            console.log(response);
            this.sideBarOpen = false;
            this.noResultsMessage = `${this.selectedSearchType.text} '${searchText}' does not exist`;
            this.showSpinner = false;
            this.resetSearchResults();
            this.filters = [];
          }
          this.cdr.detectChanges();
        });
    } else {
      this.showSpinner = false;
    }
  }

  searchLocal() {
    const filters = this.filtersOfType('localFilter');
    this.resetFilterOptions();
    // Setting it like this can cause issues, if search type isnt Employers and branch one of the arrays will be null.
    // this.selectedSearchType is not reliable to check as when you return to search results from elsewhere it gets reset to default
    // So for now just left a check to make sure its truthy before trying to use forEach on it.
    const searchTypes = ['employers', 'branches'];

    if(this.searchResultsRaw) {
      searchTypes.forEach(searchType => {
        // Need this to make sure array is not null due to search type selected.
        if(this.searchResultsRaw[searchType]) {
          this.searchResultsRaw[searchType].forEach(resultRaw => {

          if (filters.length > 0) {
            let perEmployerPass = 0;

            filters.forEach(filter => {
              let filterValue = filter['value'];
              let delegate: string | string[];
              let partialMatch = false;

              if (perEmployerPass == 0 || perEmployerPass > 0 && resultRaw.selected) {

                switch(filter['field']){
                  case 'employerBranch':
                    delegate = [resultRaw.employerName, resultRaw.branchName];
                    break;
                  case 'contact':
                    delegate = resultRaw.contactName;
                    break;
                  case 'postTown':
                    delegate = resultRaw.postTown;
                    break;
                  case 'postCode':
                    delegate = resultRaw.postCode;
                    break;
                  case 'addressPart':
                    delegate = [resultRaw.address1, resultRaw.address2, resultRaw.address3, resultRaw.postCode, resultRaw.postTown];
                    partialMatch = true;
                    break;
                  default:
                    console.log('Unknown filter field');
                    break;
                }

                resultRaw.selected = this.filterOnString(delegate, filterValue, partialMatch);
                if(resultRaw.selected && perEmployerPass === filters.length-1) {
                  this.addFilterOptions(resultRaw);
                }
              } else {
                resultRaw.selected = false;
              }
              perEmployerPass++;
            });

          } else {
            resultRaw.selected = true;
            this.addFilterOptions(resultRaw);
          }
          });
        }

      });
    }

    this.showSpinner = false;
    this.makeFilterOptionsUniqueAndOrdered();
    this.populateSearchResultsFiltered();
    this.saveSearchResultsToLocalDb();
  }

  addFilterOptions(employerOrBranch: any) {
    if(employerOrBranch.postTown) this.postTownOptions.push({ postTown: employerOrBranch.postTown.toLowerCase() });
    if(employerOrBranch.postCode) this.postCodeOptions.push({ postCode: employerOrBranch.postCode.toUpperCase() });
    if(employerOrBranch.contactName) this.contactOptions.push({ contact: employerOrBranch.contactName });
    if(employerOrBranch.employerName) this.employerBranchOptions.push({ employerBranch: employerOrBranch.employerName.toLowerCase() });
    if(employerOrBranch.branchName) this.employerBranchOptions.push({ employerBranch: employerOrBranch.branchName.toLowerCase() });
  }

  makeFilterOptionsUniqueAndOrdered() {
    this.postTownOptions = [...new Map(this.postTownOptions.map(item => [item['postTown'], item])).values()];
    this.postCodeOptions = [...new Map(this.postCodeOptions.map(item => [item['postCode'], item])).values()];
    this.contactOptions = [...new Map(this.contactOptions.map(item => [item['contact'], item])).values()];
    this.employerBranchOptions = [...new Map(this.employerBranchOptions.map(item => [item['employerBranch'], item])).values()];

    this.postTownOptions = this.sharedFunctions.genericOrdered(this.postTownOptions, 'postTown');
    this.postCodeOptions = this.sharedFunctions.genericOrdered(this.postCodeOptions, 'postCode');
    this.contactOptions = this.sharedFunctions.genericOrdered(this.contactOptions, 'contact');
    this.employerBranchOptions = this.sharedFunctions.genericOrdered(this.employerBranchOptions, 'employerBranch');
  }

  private organiseSearchResult(searchResult: any) {
    this.selectAllSearchResults(searchResult);
    this.searchResultsRaw = searchResult;

    if (this.searchResultsRaw.employers) {
      this.searchResultsRaw.employers = this.sharedFunctions.genericOrdered(this.searchResultsRaw.employers, 'employerName');
    }
    if (this.searchResultsRaw.branches) {
      this.searchResultsRaw.branches = this.sharedFunctions.genericOrdered(this.searchResultsRaw.branches, 'employerName');
    }

    console.log(this.searchResultsRaw, 'search results raw');
    this.populateSearchResultsFiltered();

    if (this.hasLocalSearchFilter()) {
      this.searchLocal();
    } else {
      this.organiseFilterOptions(this.searchResultsFiltered.combined);
    }

    this.saveSearchResultsToLocalDb();
  }

  private organiseFilterOptions(searchResult: any[]) {
    console.log('searchResult', searchResult);
    this.resetFilterOptions();
    searchResult.forEach(employer => {
      if(employer.selected)
      this.addFilterOptions(employer);
    });
    this.makeFilterOptionsUniqueAndOrdered();

    this.buildForm();
  }

  filterOnString(delegate:string | string[], filter: string, partialMatch: boolean): boolean {
    let isMatch = false;

    if (!delegate || !filter) { return isMatch };
    filter = filter.toLowerCase();

    if(typeof delegate === 'string') {
      delegate = delegate.toLowerCase();
      if(!partialMatch) {
        isMatch = delegate === filter;
      } else {
        isMatch = delegate.indexOf(filter) > -1; // Returns true if filter string is found within delegate string.
      }
    } else {
      delegate.every(valueString => {
        if (valueString) {
          const delegateString = valueString.toLowerCase();
          if (!partialMatch && delegateString === filter) {
            isMatch = true;
            return false; // Exits out of the every methord
          } else if (partialMatch && delegateString.indexOf(filter) > -1) { // Returns true if filter string is found within delegate string.
            isMatch = true;
            return false; // Exits out of the every methord
          } else {
            return true; // Keeps running the every methord
          }
        }
      });
    }

    return isMatch;
  }

  filterOnAddressParts(addressLine: string, filterString: string): boolean {

    if(!addressLine || !filterString) { return false };

    addressLine = addressLine.toLowerCase();
    filterString = filterString.toLowerCase();

    if (addressLine == filterString) { return true; }

    if(addressLine.indexOf(filterString) > -1) {
      console.log(`indexOf ${addressLine}`);
      return true;
    }

    let addressParts = addressLine.split(' ');

    addressParts.forEach(element => {
      if (filterString == element) {
        console.log(`adress part ${element}`);
        return true;
      }
    });

    return false;
  }

  onSelect(record: any) {
    this.employerTableService.count().then(
      result => {
        if (result >= 1) {
          this.employerTableService.clear()
        }

        let type = this.selectedSearchType.value.toLowerCase();
        if (type == 'employerbranch') {type = 'employer'};

        this.saveLocalEmployerAndNavigateToDashboard(record, type);
      }
    )
  }

  saveLocalEmployerAndNavigateToDashboard(record: any, type: string) {
    let id: number;
    switch (type) {
      case 'employer':
        id = record.vEmployerId;
        break;
      case 'branch':
        id = record.vBranchId;
        break;
    }

    let employerToSave: any = {};
    employerToSave.employerId = id;
    employerToSave.type = type;

    this.employerTableService.put(employerToSave).then((employerId) => {
      console.log(`${employerToSave.employerId} added to localDb. Id = ${id}`);
    });
    if(type === 'employer') {
      this.router.navigate([`employer-old/dashboard/${id}`]);
    } else {
      this.router.navigate([`employer-old/dashboard/branch/${id}`]);
    }

  }

  populateSearchTypeDropDownList() {
    this.searchTypes = [
      { value: 'EmployerBranch', title: 'Employer or Branch' },
      { value: 'Employer', title: 'Employer' },
      { value: 'Branch', title: 'Branch' }
    ]
  }

  getEmployerSearchList() {
    this.employerSearchService.getEmployerSearchList().subscribe(
      result => {
        this.rawAutoCompleteOptions = result;
        for (let x in result) {
          this.autoCompleteOptions.push(result[x].name);
        };
      }
    )
    //this.cdr.detectChanges();
  }



  addEmployer(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '50%'
    dialogConfig.minHeight = '95%';
    this.showSpinner = false;

    dialogConfig.data = { };
    const dialogRef = this.dialog.open(AddEmployerDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      //console.log(formData)
      this.employerSearchService.addEmployer(formData).subscribe(response => {
        this.autoCompleteOptions.push(response);
        const snackBarMessage = "Employer added: " + formData.name

        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
      });
    });
  }

}
