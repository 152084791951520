<h2 class="progressTitle">Health and Safety</h2>
<div style="margin-left: 20px; margin-bottom: 50px;">
  <form id="hsInfoForm" [formGroup]="hsInfoForm" style="margin-left: 1em;">
    <mat-form-field *ngFor="let ctrl of hsInfoForm.controls | keyvalue" style="margin-right: 1em;">
      <span>{{ctrl.key}} date:</span>
      <input matInput formControlName="{{ctrl.key}}" [readonly]="true">
    </mat-form-field>
  </form>
</div>
<h2 class="progressTitle">Employer Risk</h2>
<div style="margin-left: 20px; margin-bottom: 50px;">
  <form id="riskRatingForm" [formGroup]="riskRatingForm" style="margin-left: 1em;">
    <mat-select formControlName="riskRating">
      <mat-option *ngFor="let riskBanding of riskBandings" [value]="riskBanding.id">
        {{riskBanding.name}}
      </mat-option>
    </mat-select>
  </form>
</div>
