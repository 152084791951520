import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployerDashboardComponent } from './employer-dashboard.component';
import { EmployerSearchComponent } from '../employer-search/employer-search.component';
import { AuthGuard } from '../core/services/auth-guard.service';

const routes: Routes = [

  { path: 'dashboard/branch/:branchId', component: EmployerDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/branch/:branchId/:loadEvents', component: EmployerDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/:employerId/:loadEvents', component: EmployerDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/:employerId', component: EmployerDashboardComponent, canActivate: [AuthGuard] },
  { path: 'search', component: EmployerSearchComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class EmployerDashboardRoutingModule { }
