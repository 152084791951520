import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface NoteForm {
  notes: string;
  trModuleId: number;
}

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
})
export class NoteDialogComponent implements OnInit {
  commentsForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: NoteForm,
    private dialogRef: MatDialogRef<NoteDialogComponent>,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.commentsForm.dirty) {
      this.dialogRef.close(this.commentsForm.value);
    }
    else {
      this.dialogRef.close();
    }
  }

  buildForm() {
    this.commentsForm = this.fb.group({
      notes: this.data.notes,
      trModuleId: this.data.trModuleId
    });
  }
}
