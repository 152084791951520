import { SharedFunctionsService } from './../core/services/shared-functions.service';
import { UserTableService } from 'src/app/core/database/user-table.service';
import { ReportVariant } from '../shared/enums/report-variant';
import { FavouriteTableService } from './../core/database/favourite-table.service';
import { FavouriteLearnerTableService } from '../core/database/favourite-learner-table.service';
import { Router } from '@angular/router';
import { IUserInfo } from './../shared/interfaces/user-info';
import { UserInfo } from '../shared/models/my-info';
import { UserService } from '../core/services/user.service';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { HomeDashboardService } from './home-dashboards/home-dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContractWidgetService } from './contract-widgets/contract-widget.service';
import { ContractWidgetItem } from './contract-widgets/contract-widget-item';
import { WidgetDirective } from '../core/directives/widget.directive';
import { ContractWidgetTemplateComponent } from './contract-widgets/contract-widget-template-component';
import moment from 'moment';
import _ from 'underscore';
import { MilestoneTableService } from '../core/database/milestone-table-service';
import { ProgressTableService } from '../core/database/progress-table-service';
import { AuthService } from '../core/services/auth.service';
import { AuthErrors } from '../shared/enums/auth-errors';

@Component({
  selector: 'home',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  title = 'Vision';
  user: IUserInfo;
  userGraph: any;
  adminDashboardData: any[] = [];
  signedInMessage: string = '';

  @ViewChild(WidgetDirective, { static: true }) selectedWidgetHost!: WidgetDirective;
  @ViewChild('genericDashboard') genericDashboard;

  countUnread: number = 0;
  profileId: any;
  showDefaultPageSelection: boolean = false;
  selectedHomepage;
  currentUser;
  homepages = [{ 'name': 'Admin', 'type': 'admin' }, { 'name': 'Assessor', 'type': 'assessor' }, { 'name': 'Business Development', 'type': 'businessDevelopment' }, { 'name': 'Health and Safety', 'type': 'healthAndSafety' }, { 'name': 'Manager', 'type': 'manager' }, { 'name': 'Marketing', 'type': 'marketing' }, { 'name': 'SMT', 'type': 'smt' }]
  showHideWidgetOptions: boolean = false;

  allWidgets: any[] = [];
  selectedWidgets: ContractWidgetItem[] = [];
  widgetsSelected: number[] = [];
  widgetTemplate: number[] = [];

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private userTableService: UserTableService,
    private favouriteTableService: FavouriteTableService,
    private favouriteLearnerTableService: FavouriteLearnerTableService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private homeDashboardService: HomeDashboardService,
    private milestonesTableService: MilestoneTableService,
    private progressTableService: ProgressTableService,
    private sharedFunctions: SharedFunctionsService,
    private contractWidgetService: ContractWidgetService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    console.log("home")
    this.user = new UserInfo;
    this.getMyInfo();
    this.milestonesTableService.clear();
    this.progressTableService.clear();
  }

  getMyInfo() {
    this.userTableService.get(1)
      .then(thisUserResponse => {
        this.currentUser = thisUserResponse;
        if (thisUserResponse == null || thisUserResponse['staffId'] == null) {
          this.router.navigate([`auth-error`], { queryParams: { error: AuthErrors.LostUserDetails } });
        }
        this.user.staffId = thisUserResponse['staffId'];
        this.user.mode = thisUserResponse['mode'];
        if (this.user.mode == "Development" && document.location.hostname.search("vision.acttraining.org.uk") !== -1) {
          this.snackBar.open("Unexpected database being used. Please contact support.", "Error", {
            duration: 10000,
          });
        }
        this.user.userName = thisUserResponse['userName'];
        this.user.firstName = thisUserResponse['firstName'];
        this.user.jobRole = thisUserResponse['jobRole'];
        this.user.email = thisUserResponse['email'];
        this.user.department = thisUserResponse['department'];
        this.user.defaultPage = (thisUserResponse['defaultPage'] !== null ? thisUserResponse['defaultPage'] : this.defaultToAssorCheck(this.user.department)).toLowerCase();
        this.user.canImpersonate = this.canImpersonate(thisUserResponse['jobRole']);
        this.user.impersonatingRole = '';
        this.user.isImpersonating = thisUserResponse.isImpersonating;
        this.user.visionAdmin = thisUserResponse.visionAdmin;
        this.user.countUnreadNotifications = thisUserResponse['countUnreadNotifications'];
        this.user.currentLocation = thisUserResponse['currentLocation'];
        this.signedInMessage = this.user.currentLocation != 'Signed Out' ? ("You are signed in at " + this.user.currentLocation) : ("You are signed out");
        this.user.permission = thisUserResponse['permissions'];
        this.userService.getNotifications(this.user.staffId).subscribe(
          notificationsResponse => {
            this.countUnread = notificationsResponse['countUnreadNotifications'];
            thisUserResponse.countUnreadNotifications = this.countUnread;
            this.userService.updateUserNotifications(thisUserResponse);
            this.cdr.detectChanges();
          });

        this.getFavouriteReports();
        this.getFavouriteLearners(this.user.staffId);
        this.getStaffContractWidgets(this.user.staffId);
        this.cdr.detectChanges();
      }, error => {
        console.log("error", error)
      });
  }

  getStaffContractWidgets(staffId: number) {
    this.contractWidgetService.getStaffContractWidgets(staffId)
      .subscribe(response => {
        response.forEach(element => {
          this.allWidgets.push(element);

          if (element.isSelected == 1) {
            this.selectedWidgets.push(
              new ContractWidgetItem(
                ContractWidgetTemplateComponent,
                { 'widgetId': element.widgetId, 'name': `${element.widgetName}` }
              )
            );
            this.widgetsSelected.push(element.widgetId);
          }
        });

        this.contractWidgetService.getWidgetTemplate(staffId)
          .subscribe(response => {
            response.widgets.forEach(element => {
              this.widgetTemplate.push(element.widgetId);
            });

            if (this.selectedWidgets.length == 0) {
              this.widgetTemplate.forEach(id => {
                const widget = this.allWidgets.find(w => w.widgetId == id);
                this.selectedWidgets.push(
                  new ContractWidgetItem(
                    ContractWidgetTemplateComponent,
                    { 'widgetId': widget.widgetId, 'name': `${widget.widgetName}` }
                  )
                );
                this.widgetsSelected.push(id);
              })
            }

            this.loadDashboardData(this.user.defaultPage);
          });
      });
  }

  getFavouriteReports() {
    this.userService.getFavouriteReports(this.user.staffId)
      .subscribe(response => {
        //console.log('Getting favourite reports from API', response);
        const favourites = response['selectedReportIds'];
        const savedFilters = response['selectedReportFilters'];
        this.favouriteTableService.clear();

        favourites.forEach(favouriteReport => {
          this.favouriteTableService.add({
            type: 'report',
            id: favouriteReport.id,
            title: this.sharedFunctions.unCamelCase(ReportVariant[favouriteReport.reportId]),
            link: '',
            tags: '',
            filter: '',
            name: '',
            reportId: favouriteReport.reportId,
          }, `report:${favouriteReport.id}`);
        });

        savedFilters.forEach(savedFilter => {
          this.favouriteTableService.add({
            type: 'filter',
            id: savedFilter.filterId,
            title: this.sharedFunctions.unCamelCase(ReportVariant[savedFilter.reportId]),
            link: '',
            tags: '',
            filter: savedFilter.filter,
            name: savedFilter.name,
            filterId: savedFilter.filterId,
            reportId: savedFilter.reportId,
          }, `filter:${savedFilter.filterId}`);
        });
      });
  }

  getFavouriteLearners(staffId: number) {
    this.userService.getFavouriteLearnersFromApi(staffId).subscribe({
      next: response => {
        let data = response['data'];
        this.favouriteLearnerTableService.clear();
        data.forEach(favouriteLearner => {
          this.favouriteLearnerTableService.add(favouriteLearner, favouriteLearner.id);
        });
      },
      error: error => {
        console.log('Error getting favourite learners from API', error);
      }
    });
  }

  canImpersonate(role: string): boolean {
    return role == 'Systems Developer' || role == 'Digital Improvement Manager';
  }

  hoursDiff(date1, date2): number {
    let duration = moment.duration(date1.diff(date2));
    let hours = duration.asHours();
    return hours;
  }

  setDefaultPage() {
    this.homeDashboardService.setUserHomepage(this.user.staffId, this.selectedHomepage).subscribe(response => {
      this.currentUser.defaultPage = this.selectedHomepage
      this.userTableService.update(1, this.currentUser);
      this.showDefaultPageSelection = false;
      this.getMyInfo();
    })
  }

  loadDashboardData(dashboardType: string) {

    this.genericDashboard.allWidgets = this.allWidgets;
    this.genericDashboard.selectedWidgets = this.selectedWidgets;
    this.genericDashboard.widgetsSelected = this.widgetsSelected;
    this.genericDashboard.widgetTemplate = this.widgetTemplate;
    this.genericDashboard.currentDashboard = dashboardType;

    this.genericDashboard.setSelectableWidgets();
    this.genericDashboard.loadSelectedWidgetComponents();

  }

  defaultToAssorCheck(department: string): string {
    department = department.toLowerCase();
    let defaultPage = department.replace(/ /g, '');
    let assessorDefault = false;

    if (department.includes('apprenticeships')) {
      assessorDefault = true;
    } else if (department.includes('jgw')) {
      assessorDefault = true;
    } else if (department.includes('essential')) {
      assessorDefault = true;
    }

    if (assessorDefault) {
      defaultPage = 'assessor';
      // Just setting the default page on the front end dosnt really make the homepage useable in any way
      // Have to update the table with a new default page so widgets load in and are selectable.
      // not super happy with this solution but it works for now.
      this.homeDashboardService.setUserHomepage(this.user.staffId, defaultPage).subscribe(response => {
        this.currentUser.defaultPage = defaultPage;
        this.userTableService.update(1, this.currentUser);
        this.getMyInfo();
      });
    } else {
      this.showDefaultPageSelection = true;
    }
    return defaultPage;
  }

}
