<h2 class="progressTitle">Details</h2>
<div style="margin-left: 20px;" *ngIf="!isEditForm">
  <p>
    Provider: ACT <br>
  </p>
  <p>
    {{placementAddress.slice(0, -1)}} <br> <!-- drop the trailing comma -->
    {{placementBasics.phone}} <br>
    {{placementBasics.email}}
  </p>
  <p>
    Account Manager: {{placementBasics.accountManagerName}}
  </p>
</div>

<form id="basicsForm" [formGroup]="basicsForm" style="margin-left: 1em;" *ngIf="isEditForm">
  <mat-form-field *ngFor="let ctrl of basicsForm.controls | keyvalue" style="margin-right: 1em;" [hidden]="ctrl.key == 'AccountManager'">
    <!-- <span *ngIf="ctrl.key != 'AccountManager'"> -->
      {{ctrl.key}}
      <input matInput formControlName="{{ctrl.key}}" (change)="basicsUpdate(ctrl)"  [hidden]="ctrl.key == 'AccountManager'">
    <!-- </span> -->
  </mat-form-field>


  <ng-select class="staff-member-select" #select [items]="businessDevelopmentStaff" bindLabel="name" placeholder="Account Manager"
    formControlName="AccountManager"></ng-select>

</form>