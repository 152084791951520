import { Component, ViewChild } from '@angular/core';
import { LearnerService } from '../learnerService.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ObjectivesComponent } from './objectives/objectives.component';
import { AwardsComponent } from './awards/awards.component';
import { ActivitiesComponent } from './activities/activities.component';
import { LearnerPageComponent } from '../learner-page.component';

@Component({
  selector: 'learner-progress',
  templateUrl: './learner-progress.component.html',
  styleUrls: ['./learner-progress.component.scss']
})
export class LearnerProgressComponent implements LearnerPageComponent {

  @ViewChild(ObjectivesComponent) objectivesComp:ObjectivesComponent;
  @ViewChild(AwardsComponent) awardsComp:AwardsComponent;
  @ViewChild(ActivitiesComponent) activitiesComp:ActivitiesComponent;
  activities: any;
  awards: any;
  objectives: any;
  regRequests: any;


  traineeId: string;
  pot: number;
  staffId: number;

  loadedProgress: boolean = false;

  constructor(
    private learnerService: LearnerService,
    private snackbar: MatSnackBar
    ) { }

  getData() {
    const subscription = this.learnerService.GetLearnerProgress(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.activitiesComp.setData(response.activities);
        this.awardsComp.setData(response.awards);
        this.objectivesComp.setData({
          'objectives': response.objectives,
          'regRequests': response.regRequests,
          'traineeId': this.traineeId,
          'pot': this.pot,
          'canDeleteRegRequests': response.canDeleteRegRequests
        });
      },
      error: (error) => {
        console.log('Error loading AAO:', error.error);
        this.activitiesComp.setData();
        this.awardsComp.setData();
        this.objectivesComp.setData(null);
        this.snackbar.open('Error loading data please reload and try again. If this error persists, please contact support.', 'Close', {
          duration: 3000,
        });
        subscription.unsubscribe();
      },
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

}
