<div class=report-list-container>

  <div style="display: inline-flex">
    <h3 class="dashboard_headers">Favourite Reports</h3>
    <mat-icon class='star-favourite' style="margin-top: 15px; cursor: default; width:auto; height:auto">
        star
    </mat-icon>
  </div>
  <div *ngIf="this.favourites.reports.length > 0; else noReports" fxLayout="row wrap" class="favourite-container">
    <div *ngFor="let favourite of this.favourites.reports" class="fave-report-button-container">
      <button class="fave-report-button" routerLink={{favourite.link}}>
        {{favourite.title}}
      </button>
      <mat-icon class="remove-favourite-icon" (click)="removeFavourite(favourite)">close</mat-icon>
    </div>
  </div>

  <ng-template #noReports>
    <div *ngIf="reportsList.length !== 0" style="margin: 0 0 40px 20px; font-size: 14px; color: #262625;">
      You have not yet favourited any reports!
    </div>
  </ng-template>

  <h3 class="dashboard_headers">Saved Filters</h3>

  <div *ngIf="this.favourites.filters.length > 0; else noFilters" fxLayout="row wrap" class="favourite-container">
    <div *ngFor="let favourite of this.favourites.filters" class="fave-report-button-container">
      <button class="fave-report-button" routerLink={{favourite.link}} style="display: flex; flex-direction: column">
        <div *ngIf="favourite.name" style="font-size: 12px; line-height: 14px;">
            {{favourite.title}}
        </div>
        <div class="favourite-name">
          {{favourite.name}}
        </div>
        <div *ngIf="favourite.createdDate" style="color: #454545; font-size: 12px; line-height: 14px;">
            Created: {{favourite.createdDate | date : this.dateFormat}}
        </div>
      </button>
      <mat-icon class="remove-favourite-icon" (click)="removeFavourite(favourite)">close</mat-icon>
    </div>
  </div>

  <ng-template #noFilters>
    <div *ngIf="reportsList.length !== 0" style="margin: 0 0 40px 20px; font-size: 14px; color: #262625;">
      You have not yet saved any filters!
    </div>
  </ng-template>

  <h3 class=dashboard_headers>All Reports</h3>

  <mat-spinner *ngIf="reportsList.length == 0"></mat-spinner>

  <mat-form-field style="width:calc(60% - 140px); min-width: 250px;" appearance="outline">
      <mat-icon matPrefix (click)="applyFilter(txtVal.value)">search</mat-icon>
      <input #txtVal matInput placeholder="What are you looking for?" (keydown.enter)="applyFilter(txtVal.value)">
      <mat-icon matSuffix *ngIf="filterApplied" (click)=clear()>close</mat-icon>
  </mat-form-field>

  <div class="parent" fxLayout="row wrap"  *ngIf="!filterApplied" style="margin-bottom: 100px;">
      <div class="child" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100">
        <h3>A-F</h3>
          <div *ngFor='let report of reportsList_AF'>
              <a class="export-all" routerLink={{report.link}}>{{report.title}}</a>
          </div>
      </div>
      <div class="child" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100">
          <h3>G-M</h3>
          <div *ngFor='let report of reportsList_GM'>
              <a class="export-all" routerLink={{report.link}}>{{report.title}}</a>
          </div>
      </div>
      <div class="child" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100">
          <h3 class="child flex-child">N-Q</h3>
          <div *ngFor='let report of reportsList_NQ'>
              <a class="export-all" routerLink={{report.link}}>{{report.title}}</a>
          </div>
      </div>
      <div class="child" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100">
          <h3 class="child flex-child">R-Z</h3>
          <div *ngFor='let report of reportsList_RZ'>
              <a class="export-all" routerLink={{report.link}}>{{report.title}}</a>
          </div>
      </div>
  </div>

  <div class="parent flex-parent" *ngIf="filterApplied">
      <div class="child flex-child">
          <h3>Search Results</h3>
          <div class="search-results" fxLayout="row wrap" fxLayoutGap="25px grid">
          <div *ngFor='let report of filteredReports()' fxFlex="40"  fxFlex.sm="100"  fxFlex.xs="100">
              <button  mat-button class="search-result" routerLink={{report.link}}>{{report.title}}<span style="vertical-align: unset;" *ngIf="report.name">: {{report.name}}
              </span>
              </button>
          </div>
      </div>
      </div>
  </div>

</div>
