<mat-card class="employer-card" [style.cursor]="this.employer.employerId !== undefined ? 'pointer' : 'wait'"
  (click)="this.employer.employerId !== undefined ? this.routeToEmployer() : ''">
  <div *ngIf="this.employer.employerId !== undefined; else loading" style="height: 100%;">
    <div style="display: flex; flex-direction: column; height: 100%; gap: 3px;">
      <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 8px;">
        <h3 style="font-weight: 500; margin-bottom: 2px;"
          [ngStyle]="{'font-size' : employer.branchName?.length > 25 ? '16px' : '18px'}">
          {{ this.employer.branchName ?? '*No Name'}}
        </h3>
        <div style="width: 18px; height: 18px; border-radius: 4px; flex-shrink: 0; flex-grow: 0;"
          [ngStyle]="this.activeColour()" [matTooltip]="this.employer.active===1?'Active':'Inactive'"
          matTooltipPosition="above" matTooltipClass="card-tooltip">
        </div>
      </div>
      <div>
        <mat-icon matTooltip="Type" svgIcon="employer" alt="Employer" matTooltipPosition="left" class="employer-card-icon"></mat-icon>
        <span
          [ngStyle]="{'font-size' : employer.employerName?.length > 25 ? '12px' : '14px'}">{{this.stringHelper.notNullOrEmpty(this.employer.employerName)?
          this.employer.employerName.length>25?this.employer.employerName.substring(0,25)+"...":this.employer.employerName:"No
          Details"}}</span>
      </div>
      <div>
        <mat-icon matTooltip="Type" svgIcon="accountManager" alt="Account Manager" matTooltipPosition="left" class="employer-card-icon"></mat-icon>
        <span>{{this.stringHelper.notNullOrEmpty(this.employer.accountManagerName)?this.employer.accountManagerName:"No
          Details"}}</span>
      </div>
      <div *ngIf="this.stringHelper.notNullOrEmpty(this.employer.type)">
        <mat-icon matTooltip="Type" svgIcon="type" alt="Type" matTooltipPosition="left" class="employer-card-icon"></mat-icon>
        <span>{{this.employer.type}}</span>
      </div>
    </div>
    <div class="more-info" [matTooltip]="this.cardTooltipText()" matTooltipClass="card-tooltip more-info-tooltip"
      matTooltipPosition="below">...</div>
  </div>

  <ng-template #loading>
    <h3 style="font-weight: 500; margin-bottom: 2px; font-size: 18px">
      Loading...
    </h3>
  </ng-template>
</mat-card>
