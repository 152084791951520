import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadService } from 'src/app/core/services/upload.service';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';

export interface IFile {
  title: string
}

@Component({
  selector: 'file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})

export class FileListComponent implements OnInit {

  fileList: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private uploadService: UploadService,
  private cdf: ChangeDetectorRef,
  private dialogRef: MatDialogRef<FileListComponent>)
   { }

  showSpinner: boolean = false;

  ngOnInit(): void {
    if(this.data.isEmployer){
      this.getFileListForEmployer();
    }
  }

  getFileListForEmployer() {
    this.showSpinner = true;
    this.uploadService.getUploadedFilesListForEmployer(this.data).subscribe(
      data => {
        let file: any = data;
        this.fileList = [];
        Object.keys(file).forEach(
          item => {
            const splitFile: string[] = item.split('/');
            const fileName = splitFile[splitFile.length - 1];
            this.fileList.push(fileName);
          });
        this.showSpinner = false;
        this.cdf.detectChanges();
      });
  }

  downloadFileForEmployer(fileName: string) {

    if (fileName.indexOf('/') > 0) {
      const splitFile: string[] = fileName.split('/');
      fileName = splitFile[splitFile.length - 1];
    }

    this.uploadService.downloadUnitFileForEmployer(this.data.vEmployerId, fileName)
      .subscribe((res: any) => {
        var file = new Blob([res], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = fileName;
        link.click();
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.')
      });
  }

  close() {
    this.dialogRef.close();
  }
}



