import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { element } from 'protractor';

@Injectable()
export class SearchHistoryTableService {
  table: Dexie.Table<any, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('searchHistory');
  }

  getAll() {
    return this.table.toArray();
  }

  get(id) {
    return this.table.get(id);
  }

  async where(index, data) {
    const result = await this.table.where(index).equals(data).toArray();
    return result;
  }

  async add(data) {
    let current = this.table.toArray();
    if ((await current).indexOf(data) == -1) {
      let rows = await this.table.count();
      if (rows < 10) {
        rows++;
      } else {
        (await current).shift();
        this.table.clear();
        let row = 1;
        (await current).forEach(
          element => {
            this.table.add(element, row);
            row++;
          }
        )
      rows = row;
      }
      this.table.add(data, rows);
    }
    return this.table;
  }

  put(data) {
    return this.table.put(data);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  delete(id) {
    return this.table.delete(id);
  }

  clear() {
    return this.table.clear();
  }

  count() {
    return this.table.count();
  }

  last() {
    return this.table.orderBy('Key').last();
  }

  first() {
    return this.table.orderBy('Key').first();
  }
}
