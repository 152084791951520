import { SharedFunctionsService } from './../core/services/shared-functions.service';
import { UserService } from 'src/app/core/services/user.service';
import { IUserInfo } from './../shared/interfaces/user-info';
import { UserTableService } from './../core/database/user-table.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IPlacementBasics } from './../shared/interfaces/placement-basics';
import { PlacementTypeVariant } from './../shared/enums/placement-type-variant';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EmployerDashboardService } from '../employer-dashboard/employer-dashboard.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddBranchDialogComponent } from './add-branch-dialog/add-branch-dialog.component';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

export class PlacementBasics implements IPlacementBasics {
  placementId: 0;
  placementType: PlacementTypeVariant.None
}

@Component({
  selector: 'app-employer',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.scss'],
})
export class EmployerDashboardComponent implements OnInit {

  @ViewChild('employerBasics') employerBasics;
  @ViewChild('employerContacts') employerContacts;
  @ViewChild('employerHAndS') employerHAndS;
  @ViewChild('employerMaytasLinks') employerMaytasLinks;
  @ViewChild('employerEvents') employerEventsChild;
  @ViewChild('learnerList') learnerListChild;
  isInitialLoad: boolean = true;
  selectedTabIndex = 0;
  isEditEmployer: boolean = false;
  learners: any;
  hsInfo: any;
  contacts: any;
  maytasLinks: any;
  associatedPlacements: any;
  associatedTitleString: string;
  dataLoaded: boolean = false;
  updatedData: object = {};
  iconText: string = 'edit';
  placementType: PlacementTypeVariant;
  placementId: number;
  placementBasics: IPlacementBasics = new PlacementBasics();
  placementUpdates: IPlacementBasics;
  contactUpdates: any[]; //TODO: interface for contact?
  hsInfoUpdates: any; //TODO: interface?
  maytasLinkUpdates: any; //TODO: interface?
  maytasLinksToSend: any[]; //TODO: interface?
  hasMaytasLinks: boolean;
  maytasLinkOptions: any[];
  riskBandings: any[];
  currentRiskId: number;
  selectedBranch;
  userInfo: IUserInfo;
  previousURL: string;
  isLoadEvents: boolean;
  rowSpanLearners: number = 8;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private employerDashboardService: EmployerDashboardService,
    private userTableService: UserTableService,
    private snackBar: MatSnackBar,
    private location: Location,
    private userService: UserService,
    private sharedFunctions: SharedFunctionsService
    //private activatedRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        const navigation = this.router.getCurrentNavigation();
        this.previousURL = navigation.extras.state ? navigation.extras.state.originURL : 0;
      })
  };

  ngOnInit(): void {
    this.getCurrentUser();
    console.log("route", this.route.snapshot.params);
    console.log("route", this.route.snapshot.params.employerId);
    if(this.route.snapshot.params.employerId !== undefined) {
      this.placementType = PlacementTypeVariant.Employer;
      this.placementId = this.route.snapshot.params.employerId.toLowerCase();
    } else {
      this.placementType = PlacementTypeVariant.Branch;
      this.placementId = this.route.snapshot.params.branchId.toLowerCase();
    }

    console.log('placementType', this.placementType);
    // this.placementType = this.route.snapshot.params.type.toLowerCase() == 'employer' ? PlacementTypeVariant.Employer : PlacementTypeVariant.Branch;
    // this.placementId = this.route.snapshot.params.employerId.toLowerCase();

    this.getEmployerData();
  }

  pageSizeUpdated(numberOfRows){
    console.log("updating", numberOfRows)
    if(numberOfRows == 6){

      this.rowSpanLearners = 8;
    }
    else if(numberOfRows == 15){
      this.rowSpanLearners = 12;
    }
    else if(numberOfRows == 50){
      this.rowSpanLearners = 31;
    }
    this.cdr.detectChanges();
  }
  getCurrentUser() {
    this.userTableService.get(1)
      .then(staffResponse => {
        if (!staffResponse || staffResponse == undefined || staffResponse == null) {
          this.router.navigate(['']);
        } else {
          //console.log('learner-search getCurrentUser', result.staffId + ': ' + result.userName);
          this.userService.getNotifications(staffResponse.staffId).subscribe(
            notificationsResponse => {
              staffResponse.countUnreadNotifications = notificationsResponse['countUnreadNotifications'];
              this.userService.updateUserNotifications(staffResponse);
              this.cdr.detectChanges();
            })
        }
      });
  }

  toggleEdit(forceSet: boolean = false, isEdit: boolean = false) {
    if (forceSet) {
      this.isEditEmployer = isEdit;
    } else {
      this.isEditEmployer = !this.isEditEmployer
    }
    this.iconText = this.isEditEmployer ? 'save' : 'edit';

    if (!this.isEditEmployer && !forceSet) {
      this.commitChanges();
    }
  }

  getEmployerData() {
    this.employerDashboardService.getEmployerForDashboard(this.placementType, this.placementId)
      .subscribe(data => {
        this.placementBasics = new PlacementBasics;

        this.addToPlacementBasics(data.employerBasics || data.branchBasics);
        this.learners = data.learners;
        console.log("learners returning", this.learners)
        this.contacts = data.contacts;
        this.maytasLinks = data.maytasLinks;
        this.maytasLinkOptions = data.maytasLinkOptions;
        this.hsInfo = data.employerHealthandSafety;
        this.riskBandings = data.riskBandings;
        this.currentRiskId = data.riskBandingId
        this.associatedPlacements = this.sharedFunctions.genericOrdered(data.associatedPlacements, 'placementName');
      })
      .add(() => {
        this.setupSundries();
        this.cdr.detectChanges();
        console.log("about to set cols");
        this.delay(1000).then(response => {
         this.learnerListChild.setCols(this.learners);
        });
      });
  }

  addToPlacementBasics(placementBasics: any) {
    this.placementBasics.placementId = this.placementType == PlacementTypeVariant.Employer ? placementBasics.vEmployerId : placementBasics.vBranchId;
    this.placementBasics.name = placementBasics.employerName || placementBasics.branchName;
    this.placementBasics.address1 = placementBasics.address1;
    this.placementBasics.address2 = placementBasics.address2;
    this.placementBasics.address3 = placementBasics.address3;
    this.placementBasics.postTown = placementBasics.postTown;
    this.placementBasics.postCode = placementBasics.postCode;
    this.placementBasics.phone = placementBasics.phone;

    console.log("adding", placementBasics)
    this.placementBasics.accountManagerName = placementBasics.accountManagerName
    this.placementBasics.accountManagerId = placementBasics.accountManagerId
  }

  setupSundries() {
    this.associatedTitleString = this.placementType == PlacementTypeVariant.Employer ? 'Branches:' : 'Employer:';
    this.dataLoaded = true;

    this.hasMaytasLinks = this.maytasLinks && this.maytasLinks.length > 0;
    this.delay(1000).then(response => {
     this.navigateAndOpenEvents();
    });
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  navigateAndOpenEvents(){
    if(this.isInitialLoad && this.route.snapshot.params.loadEvents && this.route.snapshot.params.loadEvents == 'loadEvents'){
      this.isLoadEvents = true;
      this.selectedTabIndex = 1;
      this.employerEventsChild.addEventForm();
      this.cdr.detectChanges();
      this.isInitialLoad = false;
    }
    else if(this.isInitialLoad && this.route.snapshot.params.loadEvent && this.route.snapshot.params.loadEvent == 'loadEvent'){

      console.log("loading event")
      this.isLoadEvents = true; // todo change
      this.selectedTabIndex = 1;
      var eventId =  this.route.snapshot.params.eventId;
      console.log("loading event 1 ", eventId)
      this.employerEventsChild.openForEditById(eventId);
      this.cdr.detectChanges();
      this.isInitialLoad = false;
    }

  }

  loadAssociatedPlacement() {
    var placement = this.selectedBranch;
    this.placementType = parseInt(PlacementTypeVariant[placement.placementType], 10);
    this.placementId = placement.placementID;
    this.getEmployerData();
    this.selectedBranch = null;
    if(this.placementType == PlacementTypeVariant.Employer){
      window.history.pushState("abc", "Title", `/employer-old/dashboard/${this.placementId}`);
    } else {
      window.history.pushState("abc", "Title", `/employer-old/dashboard/branch/${this.placementId}`);
    }
  };

  loadEmployerOrBranch(placement) {

    this.placementType = this.placementType == 1 ? 2 : 1;
    this.placementId = placement.placementID;
    this.getEmployerData();

    if(this.placementType == PlacementTypeVariant.Employer){
      window.history.pushState("abc", "Title", `/employer-old/dashboard/${this.placementId}`);
    } else {
      window.history.pushState("abc", "Title", `/employer-old/dashboard/branch/${this.placementId}`);
    }
  }

  processFormData(event, component) {
    let operation: string;

    switch (component) {

      case ('details'): {
        operation = event.placementBasics.operation;
        let placementBasics = event.placementBasics.values;
        this.placementUpdates = {
          placementType: this.placementType,
          placementId: this.placementId,
          address1: placementBasics['Address 1'],
          address2: placementBasics['Address 2'],
          address3: placementBasics['Address 3'],
          postTown: placementBasics['Post Town'],
          postCode: placementBasics['Postcode'],
          phone: placementBasics['Phone'],
          businessManagementEmail: placementBasics['BusinessManagementEmail']
        };
        break;
      }
      case ('contacts'): {
        operation = event.contacts.operation;

        const contacts = event.contacts.values;
        this.contactUpdates = [];

        contacts.forEach(element => {
          let contact = {
            contactName: element['name'],
            contactTitle: element['title'],
            contactEmail: element['email'],
            contactPhone: element['phone'],
            contactType: element['type'],
          };

          if (this.placementType == PlacementTypeVariant.Employer) {
            contact['vEmployerId'] = this.placementId;
          }
          else {
            contact['vBranchId'] = this.placementId;
          }

          this.contactUpdates.push(contact);
        });


        break;
      }
      case ('hsInfo'): {
        operation = event.hsInfo.operation;
        this.hsInfoUpdates = {
          riskBandingId: event.hsInfo.values['riskBandingId']
        };

        //let hsInfo = event.hsInfo.values;
        // this.hsInfoUpdates = {
        //   monitorLast: hsInfo['Monitor Last'],
        //   monitorNext: hsInfo['Monitor Next'],
        //   appraisalLast: hsInfo['Appraisal Last'],
        //   appraisalNext: hsInfo['Appraisal Next'],
        //   monitorLastId: hsInfo['monitorLastId'],
        //   monitorNextId: hsInfo['monitorNextId'],
        //   appraisalLastId: hsInfo['appraisalLastId'],
        //   appraisalNextId: hsInfo['appraisalNextId'],
        //   eliNext: hsInfo['ELI Next'],
        // };

        if (this.placementType == PlacementTypeVariant.Employer) {
          this.hsInfoUpdates.vEmployerId = this.placementId;
        }
        else {
          this.hsInfoUpdates.vEmployerId = this.associatedPlacements[0].placementID;
          this.hsInfoUpdates.vBranchId = this.placementId;
        }

        break;
      }
      case ('maytasLinks'): {
        operation = event.maytasLinks.operation;

        let maytaskLinks: any[] = [];

        switch (operation) {

          case ('delete'): {
            event.maytasLinks.values.forEach(element => {
              let link = {
                employerId: element['employerId'],
                employerName: element['employerName'],
                vBranchId: element['branchId'], // TODO
                createdBy: element['createdWhen'],
                createdById: element['createdById']
              };

              maytaskLinks.push(link);
            });

            break;
          }

          case ('create'): {
            event.maytasLinks.values.forEach(element => {
              let link = {
                employerId: element.employer['employerId'],
                vBranchId: this.placementType == PlacementTypeVariant.Employer
                  ? element.branch['placementID']
                  : this.placementId,
                createdById: this.userInfo.staffId
              };

              maytaskLinks.push(link);
            });

            break;
          }
        }

        this.maytasLinkUpdates = { maytasLinks: maytaskLinks };

        if (this.placementType == PlacementTypeVariant.Employer) {
          this.maytasLinkUpdates.vEmployerId = this.placementId;
        }
        else {
          this.maytasLinkUpdates.vBranchId = this.placementId;
        }

        break;
      }
    }

    this.databaseOperations(component, operation)
  }

  commitChanges() {
    console.log('Commit changes');
    this.employerBasics.emitData();
    this.employerContacts.emitData();
    this.employerHAndS.emitData();
    this.employerMaytasLinks.emitData();
  }

  userMessage(success: boolean) {
    let message: string = success ? 'Your changes have been saved.' : 'There was a problem saving your changes. Please try again later.';
    this.snackBar.open(message, "Close", {
      duration: 4000,
    });
  }

  databaseOperations(component: string, operation: string) {
    switch (component) {
      case ('details'): {
        switch (operation) {
          case ('create'):
            // TODO
            break;
          case ('update'):
            console.log('update details');
            this.employerDashboardService.updatePlacementBasics(this.placementUpdates)
              .subscribe(response => {
                console.log(response);
                this.toggleEdit(true, false);
                this.setupSundries();
                this.userMessage(response);

                // TODO: could update here name of account amnager. 123
                this.employerBasics.updateAccountManagerAfterEdit();
                this.cdr.detectChanges();
                this.learnerListChild.setCols(this.learners);
              });
            break;
          case ('delete'):
            // TODO
            break;
        }
        break;
      }
      case ('contacts'): {
        switch (operation) {
          case ('update'):
            console.log('update contact');
            this.employerDashboardService.updatePlacementContact(this.contactUpdates)
              .subscribe(response => {
                console.log(response);
                this.toggleEdit(true, false);
                this.setupSundries();
                this.userMessage(response);
                this.cdr.detectChanges();
                this.learnerListChild.setCols(this.learners);
              })
            break;
        }
        break;
      }
      case ('hsInfo'): {
        switch (operation) {
          case ('update'):
            console.log('update hsInfo');
            this.employerDashboardService.updatePlacementHealthAndSafety(this.hsInfoUpdates)
              .subscribe(response => {
                console.log(response);
                this.toggleEdit(true, false);
                this.setupSundries();
                this.userMessage(response);
                this.cdr.detectChanges();
                this.learnerListChild.setCols(this.learners);
              })
            break;
        }
        break;
      }
      case ('maytasLinks'): {

        this.maytasLinksToSend = this.maytasLinkUpdates;

        switch (operation) {
          case ('delete'):
            console.log('delete maytas links');
            this.employerDashboardService.deleteMaytasLinks(this.maytasLinkUpdates)
              .subscribe(response => {
                console.log(response);
                this.toggleEdit(true, false);
                this.setupSundries();
                this.userMessage(response);
                this.cdr.detectChanges();
                this.learnerListChild.setCols(this.learners);
              })
            break;

          case ('create'):
            console.log('create maytas links');
            this.employerDashboardService.createMaytasLinks(this.maytasLinkUpdates)
              .subscribe(response => {
                this.toggleEdit(true, false);
                this.setupSundries();
                this.userMessage(response);
                this.cdr.detectChanges();
                this.learnerListChild.setCols(this.learners);
              })
        }
        break;
      }
    }
  }

  removeDeletedMaytasLinks(linksToDelete: any): any[] {
    let newLinks = this.maytasLinks.filter(item => {
      return linksToDelete.indexOf(item) == -1;
    });
    return newLinks;
  }


  addBranch(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '70%'
    dialogConfig.minHeight = '95%';

    dialogConfig.data = { };
    const dialogRef = this.dialog.open(AddBranchDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      console.log(formData)
      formData.employerId = this.placementId;
      this.employerDashboardService.addBranch(formData).subscribe(response => {
        console.log(response);
        const snackBarMessage = "Branch added: " + formData.name
        this.associatedPlacements.push(response);
        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
      })
    });
  }

  navBack() {
    if(this.previousURL) {
      this.router.navigateByUrl(this.previousURL);
    }
    else {
      this.location.back();
    }
  }

}
