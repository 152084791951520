import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve, Router, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, from, switchMap, map, catchError, of } from "rxjs";
import { UserTableService } from "src/app/core/database/user-table.service";
import { any } from "underscore";


@Injectable()
export class AttendanceTemplateResolverService implements Resolve<any> {

  constructor(
    private router: Router,
    private userTableService: UserTableService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    const tabName = route.params['tabName'];
    return from(this.userTableService.get(1)).pipe(
      switchMap(user => {
        const staffId = user.staffId;
        return of({
          tabName: tabName,
          staffId: staffId,
        });
      })
    );
  }

}
