<mat-card class="date-calculator">
  <div class="row">
    <div class="column">
      <h2 class="dashboard-titles">Learner Attendance Pay Export</h2>
    </div>
    <div class="column">
      <mat-spinner *ngIf="showSpinner" [diameter]="50" class="spinner"></mat-spinner>
    </div>
  </div>
  <div class="filter-select row">
    <div class="column">
      <mat-form-field appearance="outline">
        <mat-label>Contract</mat-label>
        <mat-select #filter [value]="'0'" (selectionChange)="selectFilter('contract', $event.value)">
          <mat-option *ngFor="let item of contracts" [value]="item.id">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Scheme</mat-label>
        <mat-select #filter [value]="'0'" (selectionChange)="selectFilter('scheme', $event.value)">
          <mat-option *ngFor="let item of schemes" [value]="item.id">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="column">
      <mat-form-field appearance="outline">
        <mat-label>Subcontractor</mat-label>
        <mat-select #filter [value]="'0'" (selectionChange)="selectFilter('subcontractor', $event.value)">
          <mat-option *ngFor="let item of subcontractors" [value]="item.id">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Sector</mat-label>
        <mat-select #filter [value]="'0'" (selectionChange)="selectFilter('sector', $event.value)">
          <mat-option *ngFor="let item of sectors" [value]="item.id">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="column">
      <mat-form-field appearance="outline">
        <mat-label>Centre</mat-label>
        <mat-select #filter [value]="'0'" (selectionChange)="selectFilter('centre', $event.value)">
          <mat-option *ngFor="let item of centres" [value]="item.id">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Assessor</mat-label>
        <mat-select #filter [value]="'0'" (selectionChange)="selectFilter('assessor', $event.value)">
          <mat-option *ngFor="let item of assessors" [value]="item.id">
            {{item.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="file-details-selector row">
    <div class="column file-type-select">
      <span class="subtitle">BACS Type</span>
      <mat-radio-group [(ngModel)]="selectedBacsType">
        <mat-radio-button class="row radio-button" value="standard">Standard</mat-radio-button>
        <mat-radio-button class="row radio-button" value="santander" [checked]="true">Santander</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="column date-select">
      <span class="subtitle" style="padding-left: 10px;">Week commencing</span>
      <div class="row">
        <div class="column">
          <button mat-button class="icon--click-hover date-button"
            [ngClass]="{'button-selected': selectedButton == 1, 'button-deselected': selectedButton != 1 && selectedButton != 0}"
            (click)="exportFiles(mondayWeekMinus1, 1)">{{mondayWeekMinus1 | date: this.dateFormat}}</button> <br>
          <button mat-button class="icon--click-hover date-button"
            [ngClass]="{'button-selected': selectedButton == 2, 'button-deselected': selectedButton != 2 && selectedButton != 0}"
            (click)="exportFiles(mondayWeekMinus2, 2)">{{mondayWeekMinus2 | date: this.dateFormat }}</button>
        </div>
        <div class="column">
          <button mat-button class="icon--click-hover date-button"
            [ngClass]="{'button-selected': selectedButton == 3, 'button-deselected': selectedButton != 3 && selectedButton != 0}"
            (click)="exportFiles(mondayWeekMinus3, 3)">{{mondayWeekMinus3 | date: this.dateFormat}}</button> <br>
          <button mat-button class="icon--click-hover date-button"
            [ngClass]="{'button-selected': selectedButton == 4, 'button-deselected': selectedButton != 4 && selectedButton != 0}"
            (click)="exportFiles(mondayWeekMinus4, 4)">{{mondayWeekMinus4 | date: this.dateFormat}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="column close-button">
      <button mat-button (click)="closeForm()" mat-flat-button color="primary" style="width: 6rem;">Close</button>
    </div>
    <div class="column">
      <div *ngIf="fileNames.length > 0 && !showSpinner" class="completed-text">
        <span class="subtitle file-saved-dialog" style="text-align: left;">Exported to your downloads folder:</span>
        <div *ngFor="let fileName of fileNames" class="file-location">{{fileName}}</div>
      </div>
    </div>
  </div>
</mat-card>
