import { PlacementTypeVariant } from './../../../shared/enums/placement-type-variant';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-employer-maytas-add-link',
  templateUrl: './employer-maytas-add-link.component.html',
  styleUrls: ['./employer-maytas-add-link.component.scss']
})
export class EmployerMaytasAddLinkComponent implements OnInit {

  selectedEmployer: any;
  selectedBranch: any;

  currentSelection: {}; // TODO interface?
  selectedLinks: any[] = [];
  placementType: PlacementTypeVariant;
  branches: any[];

  constructor(@Inject(MAT_DIALOG_DATA)
  public maytasLinkOptions: any,
  private dialogRef: MatDialogRef<EmployerMaytasAddLinkComponent>,
  private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.maytasLinkOptions.forEach(element => {
      element.employerName = this.titleCasePipe.transform(element.employerName);
    });
    this.placementType = this.maytasLinkOptions.placementType;
    this.branches = this.maytasLinkOptions.associatedPlacements;

    if(this.placementType == PlacementTypeVariant.Branch) {
      this.selectedBranch = {
        placementId: this.maytasLinkOptions.placementBasics.placementId,
        placementName: this.maytasLinkOptions.placementBasics.name,
        placementType: this.maytasLinkOptions.placementType
      }
    }
  }

  selectEmployer() {
    if(this.placementType == PlacementTypeVariant.Branch) {
      this.createLink();
      this.selectedEmployer = undefined;
    }else {
      this.selectedBranch = undefined;
    }
  }

  selectBranch() {
    this.createLink();
    this.selectedEmployer = undefined;
  }

  createLink() {
    this.currentSelection = { employer: this.selectedEmployer, branch: this.selectedBranch }
    this.selectedLinks.push(this.currentSelection);
    this.maytasLinkOptions = this.maytasLinkOptions.filter(o => o.employerId != this.selectedEmployer.employerId);
  }

  cancelOperation() {
    this.dialogRef.close();
  }
}
