import { LearnerSearchComponent } from '../searchV2/learner-search/learner-search.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/services/auth-guard.service';
import { LearnerTemplateComponent } from './learner-template/learner-template.component';
import { LearnerTemplateResolverService } from './learner-template/learner-template-resolver.service';

const routes: Routes = [
  { path: 'learner-search', component: LearnerSearchComponent, canActivate: [AuthGuard] },
  { path: ':tabName/:traineeId/:potId', component: LearnerTemplateComponent, resolve: { learner: LearnerTemplateResolverService }, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class LearnerRoutingModule { }
