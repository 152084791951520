import Dexie from 'dexie';
import { IAdminDashReports } from './../../shared/interfaces/admin-dash-reports';
import { IReportDetail } from '../../shared/interfaces/report-detail';
import { IUserInfo } from 'src/app/shared/interfaces/user-info';
import { ILearner } from 'src/app/shared/interfaces/learner';
import { IManager } from 'src/app/shared/interfaces/manager';
import { IAssessor } from 'src/app/shared/interfaces/assessor';
import { Injectable } from "@angular/core";
import { IHealthAndSafetyDashReports } from 'src/app/shared/interfaces/h-s-dash-reports';
import { IMilestone } from 'src/app/shared/interfaces/milestone';
import { IPotProgress } from 'src/app/shared/interfaces/potprogress';

@Injectable()
export class LocalDb extends Dexie {

    users: Dexie.Table<IUserInfo, string>;
    permission: Dexie.Table<number[], string>
    learners: Dexie.Table<ILearner, string>;
    employers: Dexie.Table<any, string>;
    managers: Dexie.Table<IManager, string>;
    assessors: Dexie.Table<IAssessor, string>;
    favourites: Dexie.Table<IReportDetail, number>;
    favouriteLearner: Dexie.Table<any, string>;
    learnerSearch: Dexie.Table<any, string>;
    search: Dexie.Table<any, string>;
    searchHistory: Dexie.Table<any, string>;
    employerSearch: Dexie.Table<any, string>;
    staffSearch: Dexie.Table<any, string>;
    adminDashReports: Dexie.Table<IAdminDashReports, string>;
    smtDashReports: Dexie.Table<IAdminDashReports, string>;
    healthAndSafetyDashReports: Dexie.Table<IHealthAndSafetyDashReports, string>;
    milestones: Dexie.Table<IMilestone, string>;
    progress: Dexie.Table<IPotProgress, string>;
    attendance: Dexie.Table<any, string>;

    constructor() {
        super('LocalDb');

        // ---------------------------
        // Use to reset database:
        // console.log('deleting LocalDb');
        // Dexie.delete('LocalDb')
        // ---------------------------

        this.version(1).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 1');
        });

        this.version(2).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 2');
        });

        this.version(3).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 3');
        });

        this.version(4).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 4');
        });

        this.version(5).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 5');
        });

        this.version(6).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 6');
        });

        this.version(7).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 7');
        });

        this.version(8).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            learnerEvents: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 8');
        });

        this.version(9).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 9');
        });

        this.version(10).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 10');
        });

        this.version(11).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId'
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 11');
        });

        this.version(12).stores({
            users: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 12');
        });

        this.version(13).stores({
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 13');
        });

        this.version(14).stores({
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 14');
        });

        this.version(15).stores({
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 15');
        });

        this.version(16).stores({
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            search: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 16');
        });

        this.version(17).stores({
            attendance: '',
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            search: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: ''
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 17');
        });
        this.version(18).stores({
            attendance: '',
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            search: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: '',
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 18');
        });
        this.version(19).stores({
            attendance: '',
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            search: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: '',
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 19');
        });
        this.version(20).stores({
            attendance: '',
            users: '',
            permission: '',
            learners: '++id, traineeId',
            employers: '++id, employerId, type',
            managers: '++id, userId',
            assessors: '++id, userId, assessorId',
            favourites: '',
            learnerSearch: '',
            employerSearch: '',
            search: '',
            staffSearch: '',
            adminDashReports: '',
            smtDashReports: '',
            healthAndSafetyDashReports: '',
            recentReportFiltersUnsaved: '++id',
            searchHistory: '',
            milestones: '++id, traineeId',
            progress: '++id, traineeId',
            favouriteLearner: '',
        }).upgrade(trans => {
            console.log('LocalDb upgrade() version 20');
        });
    }
}
