<div class="dashboard-home">

  <div *ngIf="!this.dashboardLoaded">
    <p style="text-align: center; margin-bottom: 0;">Loading Overview...</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="this.dashboardLoaded" style="display: flex; flex-direction: row; gap: 25px; padding-bottom: 25px;">

    <div style="display: flex; flex-direction: column; gap: 30px; flex: 1;">

      <div>

        <progress-summary *ngIf="showProgress; else noProgress"
          [potProgress]="this.potProgress"
          [pot]="this.pot"
          [traineeId]="this.traineeId"
          [showViewReportLink]="false"
          >
        </progress-summary>

        <ng-template #noProgress>
          <h2 class="title">Progress Summary</h2>
          <mat-card style="padding: 40px">
            {{ this.noData }}
          </mat-card>
        </ng-template>

      </div>

      <div>
        <div *ngIf="this.showWest; else noWest" style="display: flex; flex-direction: column;">
          <h2 class="title">
            WEST Summary
          </h2>

          <div>
              <div  *ngIf="this.westDetails['applicationOfNumbers']?.length > 0
                    || this.westDetails['aonTarget'] !== null"
                  style="float: left; margin-right: 23px;"
              >
                <mat-card class="west-card">
                  <h2>
                    Application Of Numbers
                  </h2>
                  <div class="value">
                    {{ this.westDetails['applicationOfNumbers']?.length > 0 ? this.westDetails['applicationOfNumbers'][0].level.toUpperCase() : '-' }}
                  </div>
                  <div class="target">
                    Target: {{ this.westDetails['aonTarget'] !== null ? this.westDetails['aonTarget'].toUpperCase() : 'N/A' }}
                  </div>
                </mat-card>
              </div>

              <div *ngIf="this.westDetails['communications']?.length > 0
                    || this.westDetails['comTarget'] !== null"
                  style="float: left; margin-right: 23px;"
              >
                <mat-card class="west-card">
                  <h2>
                    Communications
                  </h2>
                  <div class="value">
                    {{ this.westDetails['communications']?.length > 0 ? this.westDetails['communications'][0].level.toUpperCase() : '-' }}
                  </div>
                  <div class="target">
                    Target: {{ this.westDetails['comTarget'] !== null ? this.westDetails['comTarget'].toUpperCase() : 'N/A' }}
                  </div>
                </mat-card>
              </div>

            <div *ngIf="this.westDetails['digitalLiteracies'].length > 0
                  || this.westDetails['dlTarget'] !== null"
                  style="float: left; margin-right: 23px;"
            >
              <mat-card class="west-card">
                <h2>
                  Digital Literacy
                </h2>
                <div class="value">
                  {{ this.westDetails['digitalLiteracies']?.length > 0 ? this.westDetails['digitalLiteracies'][0].level.toUpperCase() : '-' }}
                </div>
                <div class="target">
                  Target: {{ this.westDetails['dlTarget'] !== null ? this.westDetails['dlTarget'].toUpperCase() : 'N/A' }}
                </div>
              </mat-card>
            </div>
          </div>

        </div>

        <ng-template #noWest>
          <h2 class="title">WEST Summary</h2>
          <mat-card style="padding: 40px">
            {{ this.noData }}
          </mat-card>
        </ng-template>
      </div>

      <div>
        <h2 class="title">
          Quizzes
        </h2>

        <div *ngIf="this.quizResultsSuccess; else quizError">
          <mat-card class="west-card">
            <h2>
              Digital Literacy Checker
            </h2>
            <div class="value">
              {{ this.quizResults['digitalLiteracyLevel'] ?? '-'}}
            </div>
          </mat-card>
        </div>

        <ng-template #quizError>
          <mat-card style="padding: 40px">
            {{ this.errorGettingData }}
          </mat-card>
        </ng-template>
      </div>

      <div style="display: flex; flex-direction: column;">
        <h1 class="title">
          People
        </h1>
        <learner-people
          [traineeId]="this.traineeId"
          [pot]="this.pot"
        >
        </learner-people>
      </div>
    </div>

    <div style="display: flex; flex-direction: column; gap: 25px; min-width: 400px;">
      <div *ngIf="this.oneFileId !== null" class="button-container">
        <div class="portfolio-button">
          <img style="margin: 8px 8px 0 0 !important;" src="../../../assets/images/Icon-onefile.png">
          <a href="https://live.onefile.co.uk/learner/portfolio.aspx?UserID={{this.oneFileId}}" target="_blank" style="text-decoration: none !important;">
            View OneFile Portfolio
          </a>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 20px; margin-top: 30px">
        <div>
          <mat-card class="review-details">
            <div>
              <div class="content">
                <h2>Last Review</h2>
                <div *ngIf="this.reviewDetails?.lastReviewDate !== this.reviewDetails?.startDate; else noReview">
                  <p>{{ this.reviewDetails?.lastReviewDate | date: dateFormat }}</p>
                  <p><span>Days Since:</span> {{ this.reviewDetails?.daysSinceReview }}</p>
                </div>
                <ng-template #noReview>
                  <p style="font-weight: 300; font-size: 25px; margin-bottom: 15px">No Review Yet</p>
                  <p><span>Days Since Start:</span> {{ this.reviewDetails?.daysSinceReview }}</p>
                </ng-template>

              </div>
            </div>
          </mat-card>
        </div>

        <milestones class="info-bar"
          [showMilestones]="this.showMilestones"
          [milestones]="this.milestones"
          [traineeId]="this.traineeId"
          [pot]="this.pot">
        </milestones>
      </div>
    </div>

  </div>
</div>

