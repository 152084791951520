import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { IMilestone } from 'src/app/shared/interfaces/milestone';

export interface MilestoneWithId extends IMilestone {
  id: number;
}

@Injectable()
export class MilestoneTableService {
  table: Dexie.Table<MilestoneWithId, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('milestones');
  }

  get(id) {
    return this.table.get(id);
  }

  getAll(id) {
    return this.table.toArray(id);
  }

  add(data) {
    return this.table.add(data);
  }

  put(data) {
    return this.table.put(data);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }

  clear() {
    return this.table.clear();
  }
}
