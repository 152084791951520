import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { MaterialsModule } from '../core/materials/materials.module';
import { NotificationsService } from './notifications.service';
import { FriendlyDatePipe } from '../core/pipes/friendly-date.pipe';



@NgModule({
  declarations: [ NotificationsComponent,FriendlyDatePipe],
  imports: [
    CommonModule,
    MaterialsModule,
    NotificationsRoutingModule
  ],
  providers: [NotificationsService]
})
export class NotificationsModule { }
