import { Injectable } from '@angular/core';

import { LearnerDashboardComponent } from './learner-dashboard/learner-dashboard.component';
import { LearnerIqaComponent } from './learner-iqa/learner-iqa.component';
import { LearnerDetailsComponent } from './learner-details/learner-details.component';
import { LearnerWestComponent } from './learner-west/learner-west.component';
import { LearnerProgressComponent } from './learner-progress/learner-progress.component';
import { LearnerAttachmentsComponent } from './learner-attachments/learner-attachments.component';
import { LearnerEventsComponent } from './learner-events/learner-events.component';
import { LearnerReviewsComponent } from './learner-reviews/learner-reviews.component';
import { LearnerAttendanceComponent } from './learner-attendance/attendance.component';
import { LearnerSignUpComponent } from './learner-sign-up/learner-sign-up.component';

@Injectable()
export class LearnerPageService {

  getLearnerPages() {
    return {
      'dashboard': {
          'component': LearnerDashboardComponent,
          'label': 'Overview',
          'tabName': 'dashboard',
          'order': 0
        },
      'profile': {
        'component': LearnerDetailsComponent,
        'label': 'Profile',
        'tabName': 'profile',
        'order': 1
      },
      'iqa': {
        'component': LearnerIqaComponent,
        'label': 'IQA Records',
        'tabName': 'iqa',
        'order': 2
      },
      'west': {
        'component': LearnerWestComponent,
        'label': 'WEST',
        'tabName': 'west',
        'order': 3

      },
      'reviews': {
        'component': LearnerReviewsComponent,
        'label': 'Reviews',
        'tabName': 'reviews',
        'order': 4
      },
      'progress': {
        'component': LearnerProgressComponent,
        'label': 'Activities, Awards & Objectives',
        'tabName': 'progress',
        'order': 5
      },
      'attachments': {
        'component': LearnerAttachmentsComponent,
        'label': 'Attachments',
        'tabName': 'attachments',
        'order': 6
      },
      'events': {
        'component': LearnerEventsComponent,
        'label': 'Events',
        'tabName': 'events',
        'order': 7
      },
      'attendance': {
        'component': LearnerAttendanceComponent,
        'label': 'Attendance',
        'tabName': 'attendance',
        'order': 8
      },
      'sign-up': {
        'component': LearnerSignUpComponent,
        'label': 'Sign up',
        'tabName': 'sign-up',
        'order': 9
      }
   };
  }
}
