<h1 mat-dialog-title>Permissions Update Request</h1>
<div mat-dialog-content>

  <form [formGroup]="permissionsForm" novalidate>

    <mat-dialog-content class="new-record-form">

      <mat-label class=reviewFormTitle></mat-label>

      <mat-label class=reviewFormTitle>Please provide details of the permission request:</mat-label>

      <ckeditor class="multi-line white" id="message" [editor]="Editor" [config]="editorConfig"
        formControlName="requestBody" data="" cdkFocusInitial></ckeditor>

    </mat-dialog-content>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="sendRequest()">Send</button>
</div>
