import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-assessor-history',
  templateUrl: './assessor-history.component.html',
  styleUrls: ['./assessor-history.component.scss']
})
export class AssessorHistoryComponent implements OnInit {

  assessorHistory: any;
  displayedColumns: string[] = ['assessor', 'startDate', 'endDate'];
  dateFormat = DATE_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<AssessorHistoryComponent>,
  ) {}

  ngOnInit(): void {
    this.assessorHistory = this.data.assessorHistory;
  }

}
