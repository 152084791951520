import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'learner-unique-identification',
  templateUrl: './learner-unique-identification.component.html',
  styleUrls: ['./learner-unique-identification.component.scss']
})
export class LearnerUniqueIdentificationComponent implements OnInit {

  @Output() learnerUniqueIdUpdate = new EventEmitter();
  @Input() learnerDetails;
  @Input() editPermissionLevel: number;
  formFieldStyle = 'none'; // standard or none
  uniqueIdForm: UntypedFormGroup;

  editMode = false;
  editOrSave = 'edit';

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.buildForm();
  }


  buildForm() {
    this.uniqueIdForm = this.fb.group({
      'provisionalWestPassword': new UntypedFormControl({ value: this.learnerDetails.learnerUniqueIdentification.provisionalWestPassword, disabled: true }),
      'provisionalWestUsername': new UntypedFormControl({ value: this.learnerDetails.learnerUniqueIdentification.provisionalWestUserName, disabled: true }),
    });
  }

  toggleEdit() {
    if(this.editMode && this.uniqueIdForm.dirty) {
      this.learnerUniqueIdUpdate.emit({ values: this.uniqueIdForm.value, operation: 'update' });
      this.uniqueIdForm.markAsUntouched();
      this.uniqueIdForm.markAsPristine();
    }

    this.editMode = !this.editMode;
    this.formFieldStyle = this.editMode ? 'standard' : 'none';
    this.editOrSave = this.editMode ? 'save' : 'edit';

    if (this.editMode) {
      this.uniqueIdForm.controls['provisionalWestPassword'].enable();
      this.uniqueIdForm.controls['provisionalWestUsername'].enable();
    } else {
      this.uniqueIdForm.controls['provisionalWestPassword'].disable();
      this.uniqueIdForm.controls['provisionalWestUsername'].disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  cancelEdit() {
    this.editMode = false;
    this.editOrSave = 'edit';
    this.formFieldStyle = 'none';
    this.buildForm();
  }


}
