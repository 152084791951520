<div>
  <div class="info-dialog-icon">
    <mat-icon *ngIf="data.showError || data.showWarning || data.showInfo" [style]="
        data.showError?'color:red;':
        data.showWarning?'color:orange;':
        data.showInfo? 'color:blue;':''">{{data.icon}}</mat-icon>
    <mat-spinner *ngIf="data.showLoading"></mat-spinner>
  </div>
  <div class="info-dialog-title">
    <h3 [style]="
        data.showError?'color:red;':
        data.showWarning?'color:orange;':
        data.showInfo? 'color:blue;':''">{{data.title}}</h3>
  </div>
  <div *ngIf="data.subTitle" class="info-dialog-subtitle">
    <p innerHtml="{{data.subTitle}}"></p>
  </div>

  <div *ngIf="data.showButtons && (data.showError || data.showInfo)" class="info-dialog-close-button">
    <button mat-raised-button color="primary" (click)="closeDialog('close')">Close</button>
  </div>
  <div *ngIf="data.showButtons && data.showWarning" class="info-dialog-accept-button">
    <button mat-raised-button color="primary" (click)="closeDialog('accept')">Accept</button>
    <button mat-raised-button (click)="closeDialog('close')">Close</button>
  </div>
</div>