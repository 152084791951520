import { LearnerPayService } from './../learner-pay.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { DATE_FORMAT } from 'src/app/app.constants';

export interface IDropDownItem {
  id: string;
  title: string;
}

@Component({
  selector: 'app-learner-pay-export',
  templateUrl: './learner-pay-export.component.html',
  styleUrls: ['./learner-pay-export.component.scss']
})

export class LearnerPayExportComponent implements OnInit {

  @ViewChild('filter') filterControl;

  public mondayWeek: Date = new Date();
  public mondayWeekMinus1: Date = new Date();
  public mondayWeekMinus2: Date = new Date();
  public mondayWeekMinus3: Date = new Date();
  public mondayWeekMinus4: Date = new Date();
  public fileNames: string[] = [];
  public showSpinner: boolean = false;
  public exportsDone = 0;
  public selectedButton: number;
  public contracts: IDropDownItem[] = [];
  public subcontractors: IDropDownItem[] = [];
  public centres: IDropDownItem[] = [];
  public schemes: IDropDownItem[] = [];
  public sectors: IDropDownItem[] = [];
  public assessors: IDropDownItem[] = [];
  public selectedContractId: string = '0';
  public selectedSubcontractorId: string = '0';
  public selectedCentreId: string = '0';
  public selectedSchemeId: string = '0';
  public selectedSectorId: string = '0';
  public selectedAssessorId: string = '0';
  public selectedBacsType: string = 'santander';
  private programmeTypeId: string = '16';
  public dateFormat = DATE_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LearnerPayExportComponent>,
    private learnerPayService: LearnerPayService
  ) { }

  ngOnInit(): void {
    this.getDateSelections();
    this.getFilters();
  }

  getFilters() {
    this.learnerPayService.getFilters(this.data.staffId, this.programmeTypeId, this.selectedContractId, this.selectedSubcontractorId, this.selectedCentreId, this.selectedSchemeId, this.selectedSectorId, this.selectedAssessorId)
      .subscribe(filters => {
        filters.result.contracts.forEach(x => {
          this.contracts.push(x);
        });
        filters.result.subcontractors.forEach(x => {
          this.subcontractors.push(x);
        });
        filters.result.centres.forEach(x => {
          this.centres.push(x);
        });
        filters.result.schemes.forEach(x => {
          this.schemes.push(x);
        });
        filters.result.sectors.forEach(x => {
          this.sectors.push(x);
        });
        filters.result.assessors.forEach(x => {
          this.assessors.push(x);
        });
        this.contracts.unshift({ id: '0', title: 'All Contracts' });
        this.subcontractors.unshift({ id: '0', title: 'All Subcontracts' });
        this.centres.unshift({ id: '0', title: 'All Centres' });
        this.schemes.unshift({ id: '0', title: 'All Schemes' });
        this.sectors.unshift({ id: '0', title: 'All Sectors' });
        this.assessors.unshift({ id: '0', title: 'All Assessors' });
      })
  }

  getDateSelections() {
    this.mondayWeek.setDate(this.mondayWeek.getDate());
    this.mondayWeek = this.getMonday(this.mondayWeek);

    this.mondayWeekMinus1.setDate(this.mondayWeekMinus1.getDate() - 7);
    this.mondayWeekMinus1 = this.getMonday(this.mondayWeekMinus1);

    this.mondayWeekMinus2.setDate(this.mondayWeekMinus2.getDate() - 14);
    this.mondayWeekMinus2 = this.getMonday(this.mondayWeekMinus2);

    this.mondayWeekMinus3.setDate(this.mondayWeekMinus3.getDate() - 21);
    this.mondayWeekMinus3 = this.getMonday(this.mondayWeekMinus3);

    this.mondayWeekMinus4.setDate(this.mondayWeekMinus4.getDate() - 28);
    this.mondayWeekMinus4 = this.getMonday(this.mondayWeekMinus4);
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  exportFiles(date: Date, button: number) {
    const start = moment(date).format('DD/MM/YYYY');
    this.showSpinner = true;
    this.selectedButton = button;
    this.fileNames = [];

    this.exportsDone = 0;

    // Standard
    this.learnerPayService.getTraineePayExport(1, this.data.staffId, this.selectedContractId, this.selectedSubcontractorId, this.selectedCentreId, this.selectedSchemeId, this.selectedSectorId, this.selectedAssessorId, start)
      .subscribe(
        response => {
        if (response) {
          const fileName = this.learnerPayService.exportTraineePayFile(1, response, start);
          if(fileName && fileName != 'export not done') { this.fileNames.push(fileName); }
          this.exportsDone++;
        }
        if (this.exportsDone >= 3) { this.showSpinner = false; }
      });

    if (this.selectedBacsType == 'santander') {
      // Santander BACS
      this.learnerPayService.getTraineePayExport(2, this.data.staffId, this.selectedContractId, this.selectedSubcontractorId, this.selectedCentreId, this.selectedSchemeId, this.selectedSectorId, this.selectedAssessorId, start)
        .subscribe(response => {
          if (response) {
            const fileName = this.learnerPayService.exportTraineePayFile(2, response, start);
            if(fileName && fileName != 'export not done') { this.fileNames.push(fileName); }
            this.exportsDone++;
          }
          if (this.exportsDone >= 3) { this.showSpinner = false; }
        });
    } else {
      // BACS
      this.learnerPayService.getTraineePayExport(3, this.data.staffId, this.selectedContractId, this.selectedSubcontractorId, this.selectedCentreId, this.selectedSchemeId, this.selectedSectorId, this.selectedAssessorId, start)
        .subscribe(response => {
          if (response) {
            const fileName = this.learnerPayService.exportTraineePayFile(3, response, start);
            if(fileName && fileName != 'export not done') { this.fileNames.push(fileName); }
            this.exportsDone++;
          }
          if (this.exportsDone >= 3) { this.showSpinner = false; }
        });
    }

    // Cheque
    this.learnerPayService.getTraineePayExport(4, this.data.staffId, this.selectedContractId, this.selectedSubcontractorId, this.selectedCentreId, this.selectedSchemeId, this.selectedSectorId, this.selectedAssessorId, start)
      .subscribe(response => {
        if (response) {
          const fileName = this.learnerPayService.exportTraineePayFile(4, response, start);
          if(fileName && fileName != 'export not done') { this.fileNames.push(fileName); }
          this.exportsDone++;
        }
        if (this.exportsDone >= 3) { this.showSpinner = false; }
      });


  }

  selectFilter(filterType: string, id: string) {
    switch (filterType) {
      case 'contract':
        this.selectedContractId = id;
        break;
      case 'subcontractor':
        this.selectedSubcontractorId = id;
        break;
      case 'centre':
        this.selectedCentreId = id;
        break;
      case 'scheme':
        this.selectedSchemeId = id;
        break;
      case 'sector':
        this.selectedSectorId = id;
        break;
      case 'assessor':
        this.selectedAssessorId = id;
        break;
    }
    //this.getFilters();
  }

  closeForm() {
    this.dialogRef.close();
  }
}
