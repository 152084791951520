import { ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-ialps-due',
  templateUrl: './ialps-due.component.html',
  styleUrls: ['./ialps-due.component.scss']
})
export class IalpsDueComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @ViewChild('sort', {static: true}) sort: MatSort;
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;

  dataSource: any;
  displayedColumns = ['fullName', 'programmeType', 'assessor','dateDue'];
  showTable = false;
  loadingData = true;
  onlyMyLearnerStatus = true;
  hidePrivateLearnerStatus = true;
  rawData: any;

  dateFormat = DATE_FORMAT;

  constructor(
    private cdr: ChangeDetectorRef,
    private sharedFunctions: SharedFunctionsService,
    private homeDashboardService: HomeDashboardService,
  ) { }

  ngOnInit(): void {
  }

  initialApiCall(staffId: number){
    // Could probably do with making this its own endpoint as it currently uses the report endpoint (GetOneFileIalpsReport)
    // Maybe do this when we go to the new Dashboard.
    this.homeDashboardService.getIalpsDue(staffId).subscribe(
      result => {
        this.rawData = result.reportData
        .filter(x => x.digitalIALPCompleted === "No" )
        .map(x => {
          x.dateDue = x.programmeTypeId === 16 ? x.fifteenDays : x.twentyEightDays;
          return x;
        });
        this.loadingData = false;
        this.dataSource = new MatTableDataSource(this.rawData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.filterData();
    });
  }

  toggleTable() {
    this.showTable = !this.showTable;
  }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }

  isDate(value, column) {
    return this.sharedFunctions.isDateFromColumnName(value, column);
  }

  toggleOnlyMyLearnerStatus(value: MatSlideToggleChange) {
    this.onlyMyLearnerStatus = value.checked;
    this.filterData();
    this.cdr.detectChanges();
  }

  toggleHidePrivateLearnerStatus(value: MatSlideToggleChange) {
    this.hidePrivateLearnerStatus = value.checked;
    this.filterData();
  }


  filterData() {
    if(this.onlyMyLearnerStatus && this.hidePrivateLearnerStatus)
    {
      this.dataSource.data = this.rawData.filter(x => x.yourLearner == "Yes" && x.programmeTypeId !== 9);
    }
    else if(this.onlyMyLearnerStatus && !this.hidePrivateLearnerStatus) {
      this.dataSource.data = this.rawData.filter(x => x.yourLearner == "Yes");
    }
    else if(!this.onlyMyLearnerStatus && this.hidePrivateLearnerStatus) {
      this.dataSource.data = this.rawData.filter(x => x.programmeTypeId !== 9);
    }
    else {
      this.dataSource.data = this.rawData;
    }
    this.dataSource.paginator.firstPage();
    this.cdr.detectChanges();
  }

  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch(column) {
      case 'dateDue':
        if(row['over15Or28Days'] === 'Yes') {
          style = { 'background-color': '#ffd1d1' };
        }
        break;
    }
    return style;
  }

}
