import { UserTableService } from './../database/user-table.service';
import { UserInfo } from './../../shared/models/my-info';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { FavouriteLearnerTableService } from '../database/favourite-learner-table.service';
import { Router } from '@angular/router';
import { IUserInfo } from 'src/app/shared/interfaces/user-info';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public impersonation: boolean = false;
  private messageSource: BehaviorSubject<number> = new BehaviorSubject(0);
  public message = this.messageSource.asObservable();

  private apiErrorSource: BehaviorSubject<number> = new BehaviorSubject(0);
  public apiErrorMessage = this.apiErrorSource.asObservable();
  private uiImpersonationSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public uiImpersonation: Observable<boolean> = this.uiImpersonationSource.asObservable();

  apiUrl: string;
  token: string;
  indexedDb: any;
  mockDataUrl: string

  constructor(
    private http: HttpClient,
    private userTableService: UserTableService,
    private favouriteLearnerTableService: FavouriteLearnerTableService,
    private router: Router
  ) {
    this.apiUrl = environment.API_URL;
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }


  getOptionsWithAccessTokenTwo(token) {
    //  const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', token);
    let options = { headers: headers };
    return options;
  }

  connectionIssueNotification() {
    console.log("about to notify")
    this.apiErrorSource.next(1);
  }

  resetInternetConnectionError() {
    this.apiErrorSource.next(0);
  }

  setPresence(profileId: string, status: string, activity: string): Observable<any> {
    let url = `${environment.API_URL}SetUserPresence`;
    let data = { 'email': profileId, 'status': status, 'activity': activity }
    console.log(url, 'url')
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, data, options);
  }

  scheduleMeeting(token) {
    const options = this.getOptionsWithAccessTokenTwo(token);
    //console.log(options, 'options')

    const start = { dateTime: "2021-06-22T12:00:36.101Z", timeZone: "UTC" }
    const end = { dateTime: "2021-06-22T13:14:36.101Z", timeZone: "UTC" }
    const body = { "contentType": "HTML", "content": "Lunch for one &#128512;" }
    const location = { displayName: "Home" }

    //const emailAddressSam = { address:  "sam.robson-davis@acttraining.org.uk", name: "Sam Robson-Davis" }
    //const emailAddressJoe = { address:  "josephpilgrim@acttraining.org.uk", name: "Joe Pilgrim" }
    //const attendeesJoe = [{ emailAddress: emailAddressJoe, type: "Required" }]
    //const attendeesTwo = [{ emailAddress: emailAddressSam, type: "Required" }, { emailAddress: emailAddressJoe, type: "Required" }]

    const emailAddressSteve = { address: "stephenbanbury@acttraining.org.uk", name: "Stephen Banbury" }
    const emailAddressRaven = { address: "ravenbennett@acttraining.org.uk", name: "Raven Bennett" }
    const attendeesRaven = [{ emailAddress: emailAddressRaven, type: "Required" }]
    const attendeesTwo = [{ emailAddress: emailAddressSteve, type: "Required" }, { emailAddress: emailAddressRaven, type: "Required" }]

    const postData = { subject: "Lunch virtual summit", start: start, end: end, body: body, location: location, attendees: attendeesRaven, allowNewTimeProposals: true, "transactionId": "7E163156-7862-4BEB-A1C6-829I781755A7" }


    console.log('post data', postData)
    return this.http.post("https://graph.microsoft.com/v1.0/me/events", postData, options);
  }

  checkMeetingAvailability(token) {
    const options = this.getOptionsWithAccessTokenTwo(token);
    //console.log(options, 'options')

    const start = { dateTime: "2021-06-21T11:14:36.101Z", timeZone: "UTC" }
    const end = { dateTime: "2021-06-24T12:14:36.101Z", timeZone: "UTC" }
    //const emailAddressSam = { address:  "sam.robson-davis@acttraining.org.uk", name: "Sam Robson-Davis" }
    //const emailAddressJoe = { address:  "josephpilgrim@acttraining.org.uk", name: "Joe Pilgrim" }
    const emailAddressSteve = { address: "stephenbanbury@acttraining.org.uk", name: "Stephen Banbury" }
    const emailAddressRaven = { address: "josephpilgrim@acttraining.org.uk", name: "Raven Bennett" }
    const emailAddressSamK = { address: "matthewburnett@acttraining.org.uk", name: "Sam Kirkby" }
    const type = { type: "Required" }
    const attendeesRaven = [{ emailAddress: emailAddressRaven, type: "Required" }]
    const attendeesSteve = [{ emailAddress: emailAddressSteve, type: "Required" }]
    const attendeesSamK = [{ emailAddress: emailAddressSamK, type: "Required" }]
    const attendeesTwo = [{ emailAddress: emailAddressSteve, type: "Required" }, { emailAddress: emailAddressSteve, type: "Required" }]
    const attendeesThree = [{ emailAddress: emailAddressRaven, type: "Required" }, { emailAddress: emailAddressRaven, type: "Required" }, { address: "samuelkirkby@acttraining.org.uk", name: "Sam K" }]
    const timeslots = [{ start: start, end: end }]
    const timeConstraint = { timeslots: timeslots }
    const locations = [{ displayName: "Meeting Room 1", locationEmailAddress: "ophmr1@acttraining.org.uk" }, { displayName: "Meeting Room 2", locationEmailAddress: "ophmr2@acttraining.org.uk" }, { displayName: "Meeting Room 3", locationEmailAddress: "ophmr3@acttraining.org.uk" }, { displayName: "Meeting Room 4", locationEmailAddress: "ophmr4@acttraining.org.uk" }, { displayName: "Meeting Room 5", locationEmailAddress: "ophmr5@acttraining.org.uk" }, { displayName: "Meeting Room 6", locationEmailAddress: "ophmr6@acttraining.org.uk" }, { displayName: "Meeting Room 7", locationEmailAddress: "ophmr7@acttraining.org.uk" }]
    const locationConstraint = { isRequired: "true", suggestLocation: "false", locations: locations }
    const postData = { meetingDuration: "PT1H", attendees: attendeesSamK, timeConstraint: timeConstraint, locationConstraint: locationConstraint }

    console.log('post data', postData)
    return this.http.post("https://graph.microsoft.com/v1.0/me/findMeetingTimes", postData, options);
  }

  getGraphInfo(token) {
    const options = this.getOptionsWithAccessTokenTwo(token);
    //console.log(options, 'options')
    return this.http.get("https://graph.microsoft.com/v1.0/me", options);
  }


  //https://graph.microsoft.com/v1.0/communications/getPresencesByUserId
  getPresenceInfoMultipleUsers(token, userIds = []): Observable<any> {
    const options = this.getOptionsWithAccessTokenTwo(token);

    //var data = {"ids": ["b0c317ff-a90a-4e3c-afd5-33153d709a04", "0765a049-80c6-46a3-a00a-c38849200c83"] }

    var data = { "ids": userIds }
    // working
    return this.http.post("https://graph.microsoft.com/v1.0/communications/getPresencesByUserId", data, options);
  }

  getPresenceInfo(microsoftId, token) {
    // can get all user IDs with: https://graph.microsoft.com/v1.0/users


    const options = this.getOptionsWithAccessTokenTwo(token);
    console.log(options, 'options')
    // 0765a049-80c6-46a3-a00a-c38849200c83 - is id samrobson-davis
    // below working
    //return this.http.get("https://graph.microsoft.com/beta/users/0765a049-80c6-46a3-a00a-c38849200c83/presence", options);

    // joe working
    return this.http.get("https://graph.microsoft.com/beta/users/" + microsoftId + "/presence", options);
    // below unknown - with or without .
    //return this.http.get("https://graph.microsoft.com/beta/users/josephpilgrim@acttraining.org.uk/presence", options);

    // also unknown with my own email "PresenceUnknown"
    // return this.http.get("https://graph.microsoft.com/beta/users/samrobson-davis@acttraining.org.uk/presence", options);

  }


  getMyInfo() {
    const options = this.getOptionsWithAccessToken();

    var url = `${this.apiUrl}myinfo`
    return this.http.get(url, options);
  }

  public impersonateUser(user: UserInfo) {
    this.router.navigate(['redirect']).then(() => {
      this.impersonation = true;
      this.userTableService.get(1).then(value => {
        this.userTableService.clear();
        this.userTableService.add(user, 1);
        this.userTableService.add(value, 2);
        this.router.navigate(['']).then(() => {
          this.uiImpersonationSource.next(true);
        });
      });
    });
  }

  public cancelImpersonation() {
    this.router.navigate(['redirect']).then(() => {
      this.impersonation = false;
      this.userTableService.get(2).then(value => {
        this.userTableService.clear();
        this.userTableService.add(value, 1);
        this.router.navigate(['']).then(() => {
          this.uiImpersonationSource.next(true);
        });
      });
    });
  }

  public saveUserToLocalDbSubscription(user: UserInfo) {
    this.userTableService.clear();
    return this.userTableService
      .add(user, 1);
  }

  public updateNotificationsGlobally(user: UserInfo) {
    this.messageSource.next(user.countUnreadNotifications);
  }

  public saveUserToLocalDb(user: UserInfo) {
    this.userTableService.clear();
    this.userTableService
      .add(user, 1)
      .then(id => {
        this.messageSource.next(user.countUnreadNotifications);
      })
  }

  public updateUserNotifications(user: IUserInfo) {
    this.userTableService.get(1).then(
      userResponse => {
        if (userResponse) {
          this.userTableService.update(1, { countUnreadNotifications: user.countUnreadNotifications }).then(
            updated => {
              this.messageSource.next(user.countUnreadNotifications);
            }
          );
        }
      });
  }

  public resetUserNotifications() {
    this.userTableService.get(1).then(
      userResponse => {
        if (userResponse) {
          this.userTableService.update(1, { countUnreadNotifications: 0 }).then(
            updated => {
              this.messageSource.next(0);
            }
          );
        }
      });
  }

  public getPermission(staffId: string) {
    let url = `${environment.API_URL}Permission`;
    if (staffId) {
      url += "/" + staffId;
    }
    const options = this.getOptionsWithAccessToken();
    return this.http.get<any>(url, options)
  }

  public shareFavouriteReportWithFilter(data: any) {
    const url = `${environment.API_URL}V2/Reporting/ShareReport`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, data, options);
  }

  public postFavouriteReportWithFilter(data: any) {

    const url = `${environment.API_URL}PostFavouriteReportWithFilter`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, data, options);
  }

  public addReportToFavourites(data: any) {
    const url = `${environment.API_URL}PostFavouriteReportWithoutFilter`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, data, options);
  }

  public removeFavourite(data: any) {
    const url = `${environment.API_URL}RemoveFavourite`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, data, options);
  }

  public getFavouriteReports(staffId: number) {
    const url = `${environment.API_URL}GetFavouriteReports?staffId=${staffId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options)
  }

  public getNotifications(staffId: number) {
    const url = `${environment.API_URL}GetNotifications/${staffId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options)
  }


  public getFavouriteLearnersFromApi(staffId: number) {
    const url = `${environment.API_URL}GetFavouriteLearners?staffId=${staffId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public getFavouriteLearnersFromLocalDb() {
    return this.favouriteLearnerTableService.getAll();
  }

  public addFavouriteLearner(staffId: number, traineeId: string): Observable<any> {
    return this.addFavouriteLearnerRequest(staffId, traineeId).pipe(
      map((response: Observable<any>) => {
        const data = response['data'][0];
        this.favouriteLearnerTableService.add(data, data.id);
        return data.id;
      })
    );
  }

  private addFavouriteLearnerRequest(staffId: number, traineeId: string) {
    const url = `${environment.API_URL}AddFavouriteLearners`;
    const postData = { staffId: staffId, traineeId: traineeId };
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, postData, options);
  }

  public removeFavouriteLearner(staffId: number, id: number): Observable<any> {
    return this.removeFavouriteLearnerRequest(staffId, id).pipe(
      map(() => {
        this.favouriteLearnerTableService.remove(id);
      })
    );
  }

  private removeFavouriteLearnerRequest(staffId: number, id: number) {
    const url = `${environment.API_URL}RemoveFavouriteLearners/${staffId}/${id}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.delete(url, options);
  }

}
