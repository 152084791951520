import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core";
import { Sidebar } from "ng-sidebar";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { IAdditionalFilter } from "../classic-report/classic-report.component";
import { SharedFunctionsService } from "src/app/core/services/shared-functions.service";
import { StringHelper } from "src/app/shared/string-helper";
import { DATE_FORMAT } from "src/app/app.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { UserTableService } from "src/app/core/database/user-table.service";
import { LorReportService } from "./lor-report.service";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { catchError, map, merge, of, startWith, switchMap } from "rxjs";
import { ITableFilter } from "src/app/shared/interfaces/generic-interfaces";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { SaveReportFiltersDialogComponentV2 } from "../save-report-filters-dialog/save-report-filters-dialog.component";
import { UserService } from "src/app/core/services/user.service";
import { FavouriteTableService } from "src/app/core/database/favourite-table.service";
import { ReportStoredFilterHelperService } from "src/app/reporting/report-stored-filter-helper.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ShowSavedReportFiltersDialogComponentV2 } from "../show-saved-report-filters-dialog/show-saved-report-filters-dialog.component";
import { ShareReportFiltersDialogComponentV2 } from "../share-report-filters-dialog/share-report-filters-dialog.component";
import { IUserInfo } from "src/app/shared/interfaces/user-info";
import { element } from "protractor";
import { T } from "@angular/cdk/keycodes";

interface IHighlighting {
  name: string;
  columns: string[];
  lowerThreshold: number;
  upperThreshold?: number;
  hex: string;
}
interface IGrouping {
  name: string;
  icon: string;
  highlighting: IHighlighting[];
}
interface ProgrammeOption {
  name: string;
  columns: string[];
  columnAliases?: { columns: string[], alias: string }[];
  columnGrouping?: { columnDef: string, title: string, colspan: number, style: string }
  grouping: IGrouping[];
}
interface ColumnHeader {
  columnDef: string;
  title: string;
  cols?: string[];
  headerStyle: string;
  headerHex?: string;
  bodyHex?: string;
}

@Component({
  selector: 'lor-report',
  templateUrl: './lor-report.component.html',
  styleUrls: ['../classic-report/classic-report.component.scss', './lor-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LorReportComponent<T> implements OnInit {
  @ViewChild('tablePaginator', { static: true }) tablePaginator: MatPaginator;
  @ViewChild('tableSort', { static: true }) tableSort: MatSort;
  @ViewChild("ngSidebar") ngSidebar: Sidebar;
  @ViewChild("sharedFilters") sharedFiltersChild;

  @Input() columnHeader: ColumnHeader[] = [];
  @Input() programmeOptions: ProgrammeOption[];
  @Input() reportName: string;
  @Input() reportVariant: ReportVariant;

  activeLearnerToggle = false; // needed to reuse app-shared-filters
  additionalFilterGroup = new UntypedFormGroup({});
  additionalFilters: IAdditionalFilter[] = [];
  allFilterOptionsRaw: ReportFilters;
  assessorOptions: AssessorFilters[];
  centreOptions: CentreFilters[];
  contractOptions: ContractFilters[];
  overallTotalData: T = {} as T;
  dateFormat = DATE_FORMAT;
  displayedColumns = [];
  displayReportFilters = true;
  errorClassicShow = false;
  errorClassicText = '';
  errorCustomShow = false;
  errorCustomText = '';
  errorOptionsShow = false;
  errorOptionsText = '';
  errorTermContractYearShow = false;
  errorTermContractYearText = "";
  errorText = '';
  filter: ITableFilter[] = []
  filterString = "";
  getDataEmitter = new EventEmitter<boolean>();
  grouping: IGrouping[] = [];
  groupingSelected: string;
  headerColumnGroups: string[] = [];
  isFavouriteReport: { status: boolean, data: { Id: number; key: string } } = { status: false, data: { Id: null, key: null } };
  loadedFilterString: string;
  loadingFilters = true;
  loadingTermContractYear = true;
  matSortActive = "";
  matSortDirection = "";
  pageOptions: number[];
  programmeTypeOptions: ProgrammeTypeFilters[];
  reportSavedFilter: { filters: string, name: string };
  savedFilterToApply: string;
  savedReportFilterName;
  schemeOptions: SchemeFilters[];
  sectorOptions: SectorFilters[];
  selectedContractYear: string;
  selectedProgramme: string;
  showClassicFilters = true;
  showOptionsFilters = true;
  showReportFilters = true;
  showSpinner = true;
  sidebarMode = 'push';
  sidebarOpened = true;
  storedFilters = [];
  subcontractorOptions: SubcontractorFilters[];
  tableDataSource: MatTableDataSource<T>;
  termContractYearOptions: string[];
  totalData = 0;
  userInfo: IUserInfo;
  waitingForToggle = false;

  constructor(
    private cdr: ChangeDetectorRef,
    public sharedFunctions: SharedFunctionsService,
    public stringHelper: StringHelper,
    private router: Router,
    private userTableService: UserTableService,
    private lorReportService: LorReportService,
    private dialog: MatDialog,
    private userService: UserService,
    private favouriteTableService: FavouriteTableService,
    private reportStoredFilterHelperService: ReportStoredFilterHelperService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,

  ) {
    this.pageOptions = [this.sharedFunctions.calcOptimumRows(), 25, 50, 100]
  }

  async ngOnInit() {
    this.columnHeader.forEach(column => {
      this.headerColumnGroups.push(column.columnDef);
    });
    this.userInfo = await this.userTableService.getUserInfo(); // grab the user details.
    this.grouping = this.programmeOptions[0].grouping;
    this.groupingSelected = this.programmeOptions[0].grouping[0].name;
    this.selectedProgramme = this.programmeOptions[0].name;
    this.displayedColumns = this.programmeOptions[0].columns;
    this.tableDataSource = new MatTableDataSource([]);
    this.tableDataSource.paginator = this.tablePaginator;
    this.tableDataSource.sort = this.tableSort;
    this.getAllSavedFiltersFromLocalDb();
    this.setTableColumns();
    this.setupAllFilterOptions();
    this.setupTermContractYearOptions();
    this.setupTableData();
    this.getFilterFromUrl();
  }

  getFilterFromUrl() {
    this.route.data.forEach(
      result => {
        if (result.reportFilter == null) return;
        else {
          this.reportSavedFilter = result.reportFilter;
          if (this.reportSavedFilter) {
            this.savedFilterToApply = this.reportSavedFilter.filters;
            this.savedReportFilterName = this.reportSavedFilter.name;
            this.loadSourceFilter();
            this.snackBar.open("Filter loaded: " + this.reportSavedFilter.name,
              'Close', { duration: 5000 });
          }
        }
      }
    );
  }
  loadSavedFilters() {
    if (this.savedFilterToApply !== undefined) {
      const filter = this.savedFilterToApply.split(";");
      this.sharedFiltersChild.loadSavedFilters(this.allFilterOptionsRaw, filter);
      this.loadAdditionalFilters(filter);
    }
  }
  loadSourceFilter() {
    if (this.savedFilterToApply !== undefined) {
      const filter = this.savedFilterToApply.split(";");
      filter.forEach(filterForColumns => {
        if (filterForColumns.includes("source::")) {
          const filterForColumnsSplit = filterForColumns.split("::");
          const filterForColumnsValue = filterForColumnsSplit[1];
          this.setProgramme(filterForColumnsValue);
        }
      });
    }
  }
  loadTermContractYearFilter() {
    if (this.savedFilterToApply !== undefined) {
      const filter = this.savedFilterToApply.split(";");
      filter.forEach(filterForColumns => {
        if (filterForColumns.includes("termContractYear::")) {
          const filterForColumnsSplit = filterForColumns.split("::");
          const filterForColumnsValue = filterForColumnsSplit[1];
          this.setContractYear(filterForColumnsValue);
        }
      });
    }
  }

  loadAdditionalFilters(filters) {
    filters.forEach(filter => {
      const filterSplit = filter.split("::");
      const filterType = filterSplit[0];
      const filterMatch = this.additionalFilterGroup.get(filterType)
      if (filterMatch) {
        filterMatch.setValue(filterSplit[1].split(","));
        this.setFilter({ type: filterType, value: filterSplit[1].split(",") })
      }
    });
  }

  /*
    Get Data!
  */
  setupAllFilterOptions() {
    this.loadingFilters = true;
    this.lorReportService
      .getReportFilter(this.reportVariant, this.userInfo.staffId)
      .subscribe(
        {
          next: (data: ReportFilters) => {
            this.allFilterOptionsRaw = data;
            this.assessorOptions = data.assessorFilters;
            this.centreOptions = data.centreFilters;
            this.contractOptions = data.contractFilters;
            this.programmeTypeOptions = data.programmeFilters;
            this.schemeOptions = data.schemeFilters;
            this.sectorOptions = data.sectorFilters;
            this.subcontractorOptions = data.subcontractorFilters;
            if (data.genericFilters.length > 0) {
              data.genericFilters.forEach(filter => {
                this.additionalFilters.push({ Type: filter.type, Options: [...filter.options] });
                this.additionalFilterGroup.addControl(filter.type, new UntypedFormControl(null));
              });
            }
            else {
              this.displayReportFilters = false;
            }
            this.loadingFilters = false;
            this.cdr.detectChanges();
            this.loadSavedFilters();
          },
          error: () => {
            this.loadingFilters = false;
            this.errorOptionsShow = true;
            this.errorClassicShow = true;
            this.errorCustomShow = true;
            this.cdr.detectChanges();
          }
        });
  }

  setupTermContractYearOptions() {
    this.loadingTermContractYear = true;
    this.lorReportService
      .getTermContractYearFilter(this.reportVariant, this.selectedProgramme)
      .subscribe({
        next: (data: string[]) => {
          this.termContractYearOptions = data;
          this.setContractYear(this.termContractYearOptions[0]);
          this.loadingTermContractYear = false;
          this.cdr.detectChanges();
          this.loadTermContractYearFilter();
        }
      });
  }

  setupTableData() {
    merge(this.tableSort.sortChange,
      this.tablePaginator.page,
      this.getDataEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.showSpinner = true;
          return this.lorReportService.getReportData(
            this.groupingSelected,
            this.reportVariant,
            this.userInfo.staffId,
            this.tablePaginator.pageIndex + 1,
            this.tablePaginator.pageSize,
            this.filterString,
            this.tableSort.active,
            this.tableSort.direction)
            .pipe(
              catchError((val) => {
                this.showSpinner = false;
                this.errorText = val;
                this.cdr.detectChanges();
                return of(null);
              })
            );
        }),
        map((data: PaginationResults<T>) => {
          if (data == null) return [];
          this.totalData = data.totalRecords;
          return data.data
        })
      )
      .subscribe((data: any) => {
        this.tableDataSource = new MatTableDataSource(data.data);
        this.overallTotalData = data.total;
        this.showSpinner = false;
        this.cdr.detectChanges()
      });
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  showHideFilters(filterType: Event) {
    const clickedChevron = filterType.target as Element;
    switch (clickedChevron.id) {
      case "Options-Filters":
        this.showOptionsFilters = !this.showOptionsFilters;
        break;
      case "Classic-Filters":
        this.showClassicFilters = !this.showClassicFilters;
        break;
      case "Report-Filters":
        this.showReportFilters = !this.showReportFilters;
        break;
      default:
        console.error("Unknown chevron clicked");
        break;
    }
    this.ngSidebar.triggerRerender();
  }

  setFilter(newFilter) {
    this.tablePaginator.pageIndex = 0;
    this.filterString = "";
    if (
      (typeof newFilter.value === 'object' && newFilter.value.indexOf(0) === -1 && newFilter.value.length !== 0) ||
      (typeof newFilter.value === 'string' && newFilter.value !== '') ||
      (typeof newFilter.value === 'boolean' && newFilter.value !== null)
    ) {
      // remove current filter if it exists.
      const filterToRemove = this.filter.findIndex((obj) => obj.column === newFilter.type);
      if (filterToRemove !== -1) {
        this.filter.splice(filterToRemove, 1);
      }
      const filterToAdd: ITableFilter = { column: newFilter.type, value: newFilter.value };
      this.filter.push(filterToAdd);
    }
    else {
      const filterToRemove = this.filter.findIndex((obj) => obj.column === newFilter.type);
      if (filterToRemove !== -1) {
        this.filter.splice(filterToRemove, 1);
      }
    }
    this.filter.forEach(element => {
      this.filterString += `${element.column}::${element.value};`;
    });
    //remove last & from the string.
    this.filterString = this.filterString.slice(0, -1);
    this.getDataEmitter.emit(true);
  }

  setProgramme(event) {
    this.tablePaginator.pageIndex = 0;
    this.tableDataSource = new MatTableDataSource([]);
    this.showSpinner = true;
    this.selectedProgramme = event;
    this.grouping = this.programmeOptions.find(i => i.name === this.selectedProgramme).grouping
    this.groupingSelected = this.grouping[0].name;
    this.setTableColumns();
    this.setupTermContractYearOptions();
    this.cdr.detectChanges();
  }

  setContractYear(event) {
    this.tablePaginator.pageIndex = 0;
    this.selectedContractYear = event;
    this.setFilter({ type: 'source', value: this.selectedProgramme });
    this.setFilter({ type: 'termContractYear', value: this.selectedContractYear });
  }

  checkSaveFiltersAndStore() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '300px';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(SaveReportFiltersDialogComponentV2, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      this.storeFavourite(formData);
    });
  }

  storeFavourite(rawData) {
    const reportTitle = this.sharedFunctions.unCamelCase(ReportVariant[this.reportVariant]);
    if (rawData) {
      if (rawData.isSaveFilters) {
        const fullFilter = this.getFullFilterAsString();
        const saveData = { "filter": fullFilter, "reportId": this.reportVariant, "staffId": this.userInfo.staffId, "name": rawData.name }
        this.userService.postFavouriteReportWithFilter(saveData).subscribe(filterId => {
          this.favouriteTableService.add({
            id: filterId,
            title: reportTitle,
            link: '',
            tags: '',
            filter: fullFilter,
            name: rawData.name,
            filterId: filterId,
            reportId: this.reportVariant
          }, filterId)
            .then(() => {
              this.storedFilters.push({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: rawData.name, filterId: filterId, reportId: this.reportVariant });
              this.snackBar.open('Saved: ' + rawData.name, 'Close', { duration: 2000 });
              this.cdr.detectChanges();
            })
        })
      }
      else {
        const data = { "reportId": this.reportVariant, "staffId": this.userInfo.staffId };
        this.userService.addReportToFavourites(data).subscribe(id => {
          if (id) {
            this.favouriteTableService.add({
              type: 'report',
              id: id,
              title: reportTitle,
              link: '',
              tags: '',
              filter: '',
              name: '',
              reportId: this.reportVariant
            }, `report:${id}`)
              .then(() => {
                this.waitingForToggle = false;
                this.isFavouriteReport.status = true;
                this.isFavouriteReport.data.Id = id;
                this.isFavouriteReport.data.key = `report:${id}`;
                this.snackBar.open(`${reportTitle} added to favourites`, 'Close', { duration: 2000 });
                this.cdr.detectChanges();
              })
          }
        })
      }
    }
  }

  getFullFilterAsString(): string {
    const programmeSelected = `source::${this.selectedProgramme};`;
    const contractYearSelected = `termContractYear::${this.selectedContractYear};`;
    const sharedFilters = this.sharedFiltersChild.getFiltersAsString();
    const additionalFilters = this.reportStoredFilterHelperService.getAdditionalFilters(this.additionalFilters, this.additionalFilterGroup);
    const fullFilter = programmeSelected + contractYearSelected + sharedFilters + additionalFilters;
    return fullFilter;
  }

  getAllSavedFiltersFromLocalDb() {
    this.favouriteTableService
      .getAll()
      .then(response => {
        response.forEach(item => {
          if (item.type === 'filter' && item.reportId === this.reportVariant) {
            this.storedFilters.push(item);
          } else if (item.type === 'report' && item.reportId === this.reportVariant) {
            this.isFavouriteReport.status = true;
            this.isFavouriteReport.data.Id = item.id;
            this.isFavouriteReport.data.key = `report:${item.id}`;
          }
        });
      });
  }

  viewSavedFilters() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '300px';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      storedFilters: this.storedFilters
    };
    const dialogRef = this.dialog.open(ShowSavedReportFiltersDialogComponentV2, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        this.showSpinner = true;
        this.clearSidebar();
        this.savedFilterToApply = formData.filter.filter;
        this.savedReportFilterName = formData.filter.name;
        this.loadSourceFilter();
        this.loadSavedFilters();
        this.showSpinner = false;
        this.cdr.detectChanges();
        const snackBarMessage = "Filter loaded: " + formData.filter.name
        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
      }
    });
  }

  clearSidebar() {
    this.sharedFiltersChild.reset();
    this.filterString = `source::${this.selectedProgramme};termContractYear::${this.selectedContractYear};`;
    this.getDataEmitter.emit(true);
  }

  back() {
    this.router.navigate(['/reports/index']);
  }

  toggleReportFavorite() {
    this.waitingForToggle = true;
    this.isFavouriteReport.status = !this.isFavouriteReport.status;
    if (this.isFavouriteReport.status) {
      this.storeFavourite({ isSaveFilters: false });
    }
    else {
      const data = { "type": 'report', "Id": this.isFavouriteReport.data.Id, "staffId": this.userInfo.staffId };
      this.userService.removeFavourite(data).subscribe(response => {
        if (response) {
          this.favouriteTableService.remove(this.isFavouriteReport.data.key);
          this.waitingForToggle = false;
          this.snackBar.open("Removed as a Favourite",
            'OK', { duration: 2000 });
        }
      });
    }
  }

  changeGrouping(event) {
    this.tablePaginator.pageIndex = 0;
    this.tableDataSource = new MatTableDataSource([]);
    this.groupingSelected = event.value;
    this.setTableColumns();
    this.getDataEmitter.emit(true);
  }

  headerCellStyle(column) {
    let style = {};
    this.columnHeader.forEach(header => {
      if (header.cols.includes(column) && header.headerHex !== undefined) {
        style = {
          'background-color': header.headerHex,
          'text-align': 'center',
          'color': 'white'
        }
      }
    });
    return style;
  }

  tableCellStyle(column, cell) {
    let style = {};
    const grouping = this.grouping.find(i => i.name == this.groupingSelected);
    this.columnHeader.forEach(header => {
      if (header.cols.includes(column) && header.bodyHex !== undefined) {
        style = {
          'background-color': header.bodyHex
        }
      }
    });
    if (grouping.highlighting === undefined) return style;
    grouping.highlighting.forEach(highlight => {
      if (highlight.columns.includes(column)) {
        if (highlight.upperThreshold !== undefined) {
          if (cell >= highlight.lowerThreshold && cell <= highlight.upperThreshold) {
            style = {
              'background-color': highlight.hex
            }
          }
        }
        else {
          if (cell >= highlight.lowerThreshold) {
            style = {
              'background-color': highlight.hex
            }
          }
        }
      }
    });
    return style;
  }


  tableHeaderName(column) {
    const selectedProgramme = this.programmeOptions.find(group => group.name === this.selectedProgramme);
    if (selectedProgramme.columnAliases !== undefined) {
      const alias = selectedProgramme.columnAliases.find(alias => alias.columns.find(i => i.toLowerCase() === column.toLowerCase()));
      if (alias !== undefined) {
        return alias.alias;
      }
      else {
        return this.sharedFunctions.unCamelCase(column);
      }
    }
    else {
      return this.sharedFunctions.unCamelCase(column);
    }
  }

  shareReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '500px';
    const dialogRef = this.dialog.open(ShareReportFiltersDialogComponentV2, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      const fullFilter = this.getFullFilterAsString();
      const reportNameForUrl = this.route.snapshot.url[0].path;
      const data = { "filter": fullFilter, "reportId": this.reportVariant, "staffId": this.userInfo.staffId, "name": formData.name, "staffToShareThisWith": formData.selectedStaff, "isSaveFilters": formData.isSaveFilters, "reportNameForUrl": reportNameForUrl }
      this.userService.shareFavouriteReportWithFilter(data).subscribe(filterId => {
        const snackBarMessage = "Filter shared: " + formData.name

        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
        let reportTitle = this.sharedFunctions.unCamelCase(ReportVariant[this.reportVariant]);
        if (data.isSaveFilters) {
          this.favouriteTableService
            .add({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: formData.name, filterId: filterId, reportId: this.reportVariant }, filterId)
            .then(() => {
              reportTitle = this.sharedFunctions.unCamelCase(ReportVariant[this.reportVariant]);
              this.cdr.detectChanges();
            })
        }
      });
    });
  }

  export(exportType: string) {
    this.lorReportService.postReportExport(this.reportVariant, this.filterString, `${this.tableSort.active}::${this.tableSort.direction}`, this.groupingSelected, this.displayedColumns.join(";"), exportType).subscribe({
      next: (data: { body: string }) => {
        this.snackBar.open(data.body, 'Close');
      },
      error: (error) => {
        this.snackBar.open(error, "Close");
      }
    });
  }

  //Not implemented but needed to reuse app-shared-filters
  toggleOnlyActiveLearners(event) {
    console.log(event); //This is only here to stop the linter from crying.
    console.error("toggleOnlyActiveLearners not implemented");
  }

  getCount(column: string) {
    if (column === "scheme") return "Overall Total";
    return this.overallTotalData[column] !== null ? this.overallTotalData[column] : "";
  }
  private setTableColumns() {
    this.displayedColumns = [this.groupingSelected.toLowerCase(), ...this.programmeOptions.find(group => group.name === this.selectedProgramme).columns];
    this.matSortActive = this.displayedColumns[0];
    this.matSortDirection = "asc";
    this.cdr.detectChanges();
  }

}
