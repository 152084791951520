<div class="home-generic-container">

  <div class="widget-container" fxLayout="row wrap">
    <ng-template selectedWidgetHost></ng-template>
  </div>

  <div id="outer-widget-selector-div">
    <div id="inner-widget-selector-div">
      <div id="title-container">
        <div id="logo">
          <img id="widget-img" src="assets/dashboard/widget.svg" width="40px" alt="widget icon">
        </div>
        <div id="options-container">
          <h4 id="widget-title">Customise Your Widget Selection</h4>
          <span class="icon--click-hover" id="view-button" *ngIf="!showHideWidgetOptions" (click)="showWidgetOptions()">Click to view options</span>
          <span class="icon--click-hover" id="view-button" *ngIf="showHideWidgetOptions" (click)="showWidgetOptions()">Click to hide options</span>
        </div>
      </div>

      <div id="widget-selector-div" *ngIf="showHideWidgetOptions">
          <ng-select
            class="widget-selector"
            [items]="selectableWidgets"
            [multiple]="true"
            bindLabel="widgetName"
            [selectableGroup]="true"
            [selectableGroupAsModel]="true"
            [closeOnSelect]="false"
            bindValue="widgetId"
            [(ngModel)]="widgetsSelected">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.widgetName}}
            </ng-template>
          </ng-select>

          <div class="widget-buttons">
            <div class="default-widget-selection-button favourites-button">
              <button mat-flat-button color="secondary" (click)="loadDefaultWidgets()">Load Defaults</button>
            </div>

            <div class="save-widget-selection-button favourites-button">
              <button mat-flat-button color="primary" (click)="saveWidgetChanges()">Save</button>
            </div>
          </div>

      </div>

    </div>
  </div>

</div>
