<ng-sidebar-container class="sidebar-container">
  <div ng-sidebar-content>
    <div style="display: flex;">
      <div style="flex-grow: 1"></div>
      <button mat-button (click)="back()" class="close-button">Close</button>
    </div>
    <mat-divider></mat-divider>
    <div class="reportContainer">
      <div style="display: flex;">
        <h2 class="reportTitle"
          style="color: #262633 !important; font-size: 24px !important; margin: 0; align-self: center;">{{reportName}}
        </h2>
        <button class="favourite-button" [disabled]="this.waitingForToggle" (click)="toggleReportFavorite()">
          <mat-icon class='star-favourite-icon'>
            {{ this.isFavouriteReport.status ? 'star' : 'star_outline'}}
          </mat-icon>
        </button>
        <div style="flex: 1"></div>
        <mat-button-toggle-group class="report-type-buttons" aria-label="Display Option"
          (change)="changeGroupType($event)" [value]="this.selectedDrillDownGroup">
          <mat-button-toggle *ngFor="let group of this.drillDownGroups" [value]="group">
            <img src="{{group.image}}" width="15px" alt="{{group.name}} view">
            <span>{{group.name}}</span>
          </mat-button-toggle>

        </mat-button-toggle-group>
      </div>
      <div style="display: flex; font-style: italic; font-size: 12px; padding-bottom: 0.5%; padding-top: 0.5%;">
        <span *ngIf="this.drillDownBreadCrumb.length === 1">
          Select a row in the table to drill down further.
        </span>
        <span
          *ngIf="this.drillDownBreadCrumb.length !== this.selectedDrillDownGroup.levels.length && this.drillDownBreadCrumb.length!==1">
          Select a row in the table to drill down further or select a link below to return to a higher level.
        </span>
        <span *ngIf="this.drillDownBreadCrumb.length === this.selectedDrillDownGroup.levels.length">
          Select a link below to return to a higher level.
        </span>
      </div>
      <h3 class="subheading subheading-flex">
        <span *ngFor="let crumb of this.drillDownBreadCrumb; let i = index">
          <span>
            <img src="../../../assets/arrows/right-arrow.svg" class="breadcrumb-chevron" [hidden]="i===0" />
          </span>
          <a *ngIf="this.drillDownBreadCrumb.length>0 && crumb.id!==0" [routerLink]="[]"
            (click)="selectLevel(crumb.levelId)">{{crumb.name}}</a>
          <span *ngIf="this.drillDownBreadCrumb.length>0 && crumb.id===0">{{crumb.name}}</span>
        </span>
      </h3>
      <div class="reportTable">
        <table mat-table [dataSource]="this.tableDataSource" #tableSort="matSort" matSort
          matSortActive="{{this.matSortActive}}" matSortDirection="{{this.matSortDirection}}">
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-sort-header mat-header-cell *matHeaderCellDef sticky> {{ stringHelper.unCamelCase(column)}} </th>
            <td mat-cell *matCellDef="let cell" (click)="drillDown(cell[selectedLevel.idColumn])"
              [ngStyle]="this.tableCellStyle(column , cell[column], cell)"
              [style.cursor]="this.drillDownBreadCrumb.length !== this.selectedDrillDownGroup.levels.length ?'pointer':'default'">
              <span *ngIf="column != 'fullName'">
                {{this.columnFormatter(column, cell[column])}}
              </span>
              <span *ngIf="column == 'fullName'">
                <a routerLink="/learner/dashboard/{{cell['traineeId']}}/{{cell['pot']}}">{{stringHelper.formatForEmpty(cell[column])}}
                </a>
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="displayedColumns.length" style="text-align: center;"
              [attr.colspan]="this.displayedColumns.length">
              {{
              showSpinner ? 'Loading...':
              errorText ? errorText :'No Results'}}
            </td>
          </tr>
        </table>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
      <mat-paginator #tablePaginator [length]="totalData" [pageSizeOptions]="pageOptions" showFirstLastButtons="true">
      </mat-paginator>
    </div>
    <div>
      <div style="flex-grow: 1; margin-left: 20px; display:flex; gap: 15px">
        <button mat-flat-button color="primary" (click)="shareReport()">
          <!-- TODO - Import svg and edit inline -->
          <img src="assets/general/share.svg" width="15px" alt="share"> <span style="padding: 5px;">Share</span>
        </button>
        <button mat-stroked-button class="save-export-buttons" [matMenuTriggerFor]="saveMenu">
          Export As... <mat-icon class="export-arrow">expand_more</mat-icon>
        </button>
        <mat-menu #saveMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="export('csv')">.csv</button>
          <button mat-menu-item (click)="export('xlsx')">.xlsx</button>
          <button mat-menu-item (click)="export('pdf')">.pdf</button>
        </mat-menu>
      </div>
    </div>
  </div>

  <ng-sidebar #ngSidebar class="side-bar" style="color: white; direction: rtl;" [(opened)]="sidebarOpened"
    [mode]="this.sidebarMode" [position]="'left'" [dock]="true" [dockedSize]="'35px'" [autoFocus]="false">
    <div style="min-width: 35px">
      <mat-icon *ngIf="!sidebarOpened" class="filter filter-button" (click)="toggleSidebar()">filter_list</mat-icon>
    </div>
    <div [ngStyle]="{'display': !this.sidebarOpened ? 'none' : 'inherit'}" class="filter-bar side-bar"
      style="width:320px; direction: ltr; padding-left: 8px">

      <div style="display:flex; gap: 5px; height: 60px;">
        <h2>
          Filters
        </h2>
        <div style="flex-grow: 1"></div>
        <button #loadFilters mat-button class="filterButtons" onClick="this.blur()"
          (click)="checkSaveFiltersAndStore()">
          Save
        </button>
        <button #loadFilters mat-button class="filterButtons" onClick="this.blur()" (click)="viewSavedFilters()">
          Load
        </button>
        <button mat-button class="filterButtons" (click)="clearSidebar()">
          Clear
        </button>
        <button mat-button class="filterButtons" (click)="toggleSidebar()" style="margin-right: 14px;">
          <img src="../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Hide Filters">
        </button>
      </div>
      <div style="display: flex; gap: 10px; justify-content: space-between; margin-right: 14px;">
      </div>

      <div style="display: inherit;" class="filter-bar-inner" style="padding-left: 10px; min-height: calc(100% - 60px)">
        <!-- Main Options -->
        <div class="option-section-header">
          <div style="flex: 1;">
            <h4 style="margin: 15px 0 10px 0;">Options</h4>
          </div>
          <div style="flex: 0.25; align-self: center;">
            <mat-icon id="Options-Filters" (click)="showHideFilters($event)"
              class="icon--click-hover report-filter-toggle">
              {{ showOptions ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </div>
        </div>
        <div *ngIf="showOptions" style="margin-right: 20px; margin-top: 10px">
          <div *ngIf="this.errorOptionsShow">
            <p style="text-align: center;">{{this.errorOptionsText}}</p>
          </div>
          <mat-progress-bar mode="indeterminate"
            *ngIf="this.loadingFilters && !this.errorOptionsShow"></mat-progress-bar>
          <div *ngIf="!loadingFilters && !errorOptionsShow" id="date-filter">
            <mat-form-field class="option-select" style="margin-bottom: 5px; width: 100%;" appearance="outline">
              <mat-select class="date-filter-type-select" placeholder="Change Date Filter Type"
                [(ngModel)]="dateFilterTypeSelected" disableOptionCentering panelClass="dropdown-menu"
                [formControl]="dateTypeChange">
                <mat-option *ngFor="let dateFilter of dateFilterTypes" [value]="dateFilter">
                  {{stringHelper.unCamelCase(dateFilter)}}
                </mat-option>
              </mat-select>
              <mat-label>Date Type</mat-label>
            </mat-form-field>
            <mat-form-field id="date-range-field" class="option-select" style="width: 100%;" appearance="outline"
              [floatLabel]="'always'">
              <mat-label>Date Range</mat-label>
              <input style="min-height: 30px; cursor: pointer;" matInput type="text" #dateRange ngxDaterangepickerMd
                placeholder="Select Date Range" [(ngModel)]="selectedDateRange" (datesUpdated)="dateRangeChange($event)"
                [ranges]="ranges" [showClearButton]="true" [readonly]="true"
                [locale]="{applyLabel: 'Apply',clearLabel: 'Clear',format: 'DD/MM/YYYY'}" startKey="startDate"
                endKey="endDate" autocomplete="off" startKey="startDate" [alwaysShowCalendars]=true endKey="endDate"
                [showDropdowns]="true">
            </mat-form-field>
          </div>
          <div *ngIf="!loadingFilters && !errorOptionsShow">
            <div *ngIf="this.multipleDefaultDisplayColumns" style="margin-bottom: 10px;">
              <mat-radio-group style="display: flex; justify-content: center; gap: 10px;"
                (change)="this.setDefaultColumn($event)">
                <mat-radio-button *ngFor="let defaultDisplay of this.defaultDisplayColumns"
                  [value]="defaultDisplay.type"
                  [checked]="this.selectedDefaultDisplayColumns.type === defaultDisplay.type">
                  {{ defaultDisplay.type }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-form-field class="option-select" appearance="outline" style="width: 100%;">
              <mat-label>Display Columns</mat-label>
              <mat-select #showColumnSelector [formControl]="this.columnFormControl" multiple
                (selectionChange)="selectColumns($event.value)" disableOptionCentering
                panelClass="dropdown-menu-columns">
                <mat-option *ngFor="let col of allColumns" [value]="col" class="mat-accent">
                  {{stringHelper.unCamelCase(col)}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <br *ngIf="!showOptions">
        <!-- Classic Filters -->
        <div *ngIf="hasClassicFilters">
          <div class="option-section-header" style="margin: 20px 0 10px 0;">
            <div style="flex: 1;">
              <h4 style="margin: 0">Classic</h4>
            </div>
            <div style="flex: 0.25; align-self: center;">
              <mat-icon id="Classic-Filters" (click)="showHideFilters($event)"
                class="icon--click-hover report-filter-toggle">
                {{ showClassicFilters ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </div>
          </div>
          <div *ngIf="this.errorClassicShow" [hidden]="!showClassicFilters"
            style="margin-right: 20px; margin-top: 10px">
            <p style="text-align: center;">{{this.errorClassicText}}</p>
          </div>
          <div *ngIf="this.loadingFilters && !this.errorClassicShow" style="margin-right: 20px; margin-top: 10px"
            [hidden]="!showClassicFilters">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
          <app-shared-filters *ngIf="!this.loadingFilters && !this.errorClassicShow" [hidden]="!showClassicFilters"
            #sharedFilters [hasActiveLearnerFilter]="this.activeLearnerToggle" [assessorOptions]="assessorOptions"
            [centreOptions]="centreOptions" [contractOptions]="contractOptions"
            [subcontractorOptions]="subcontractorOptions" [programmeTypeOptions]="programmeTypeOptions"
            [schemeOptions]="schemeOptions" [sectorOptions]="sectorOptions" (setFilterEmitter)="setFilter($event)"
            (toggleOnlyActiveLearners)="toggleOnlyActiveLearners($event)">
          </app-shared-filters>
          <br *ngIf="!showClassicFilters">
        </div>
        <!-- Report Filters -->
        <div class="option-section-header" style="margin: 20px 0 10px 0;">
          <div style="flex: 1;">
            <h4 style="margin: 0;">Report</h4>
          </div>
          <div style="flex: 0.25; align-self: center;">
            <mat-icon id="Report-Filters" (click)="showHideFilters($event)"
              class="icon--click-hover report-filter-toggle">
              {{ showReportFilters ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </div>
        </div>
        <div *ngIf="this.loadingCustomFilters && !this.errorCustomShow" style="margin-right: 20px; margin-top: 10px"
          [hidden]="!showReportFilters">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="this.errorCustomShow" [hidden]="!showReportFilters" style="margin-right: 20px; margin-top: 10px">
          <p style=" text-align: center;">{{this.errorCustomText}}</p>
        </div>
        <div *ngIf="!this.loadingCustomFilters && !this.errorCustomShow" [hidden]="!showReportFilters"
          [formGroup]="additionalFilterGroup">
          <ng-select *ngFor="let filter of additionalFilters" [items]="filter.Options" [closeOnSelect]="false"
            [multiple]="true" placeholder="{{stringHelper.unCamelCase(filter.Type)}}" class="filter-select" #select
            style="margin-right: 20px;" appearance="outline"
            (change)="setFilter({type: filter.Type, value: additionalFilterGroup.get(filter.Type).value})"
            formControlName="{{filter.Type}}" [clearOnBackspace]="false">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice:0:2">
                <span class="ng-value-label">{{item}}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 2">
                <span class="ng-value-label">{{items.length - 2}} more...</span>
              </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item}}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </ng-sidebar>
</ng-sidebar-container>