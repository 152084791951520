<h2 class="progressTitle">Attachments</h2>
<div class=attachContainer>

    <div class="upload-div">
        <button mat-flat-button color="primary" class="button" (click)="dndFiles()">Upload Attachment</button>
    </div>

    <div class="upload-div">
      <button mat-flat-button color="primary" class="button" (click)="viewAttachments()">View Attachments</button>
    </div>
  </div>
