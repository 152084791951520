import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'current-employment',
  templateUrl: './current-employment.component.html',
  styleUrls: ['./current-employment.component.css']
})
export class CurrentEmploymentComponent implements OnInit {

  @Output() currentEmploymentUpdate = new EventEmitter();
  @Input() currentEmployment: any;
  @Input() contactInformation: any;
  @Input() editPermissionLevel: number;

  show: boolean = false;
  currentEmploymentForm: UntypedFormGroup;
  editMode: boolean = false;
  formFieldStyle: string = 'none';
  longContract: boolean = null;
  wifiAccess: boolean = null;
  formattedStartDate: string;
  formattedEndDate: string;

  dateFormat = DATE_FORMAT;

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
    )
    { }

  ngOnInit(): void {
    this.formattedStartDate = this.currentEmployment.startDate ? this.datePipe.transform(this.currentEmployment.startDate, this.dateFormat) : '-';
    this.formattedEndDate = this.currentEmployment.endDate ? this.datePipe.transform(this.currentEmployment.endDate, this.dateFormat) : '-';
    this.buildForm();
  }

  display(value) {
    this.show = value;
    this.changeDetectorRef.detectChanges();
  }

  buildForm() {
    this.longContract = this.currentEmployment.contractType === null ? null : this.currentEmployment.contractType === 'No' ? false : true;
    this.wifiAccess = this.currentEmployment.wiFiAccess === null ? null : this.currentEmployment.wiFiAccess === 'No' ? false : true;
    this.currentEmploymentForm = this.fb.group({
      'manager': new UntypedFormControl({value: this.currentEmployment.manager.fullName, disabled: true}),
      'managerphone':  new UntypedFormControl({value: this.currentEmployment.manager.phone, disabled: true}),
      'manageremail': new UntypedFormControl({value: this.currentEmployment.manager.email, disabled: true}),
      'wifiaccess' : new UntypedFormControl({value: this.wifiAccess, disabled: true}),
      'jobtitle' : new UntypedFormControl({value: this.currentEmployment.jobTitle, disabled: true}),
      'longcontract' : new UntypedFormControl({value: this.longContract, disabled: true}),
      'lineManager' : new UntypedFormControl({value:this.contactInformation.lineManager2, disabled: true}),
      'lineManagerMobile' : new UntypedFormControl({value: this.contactInformation.lineManagerMobile2, disabled: true}),
      'lineManagerEmail' : new UntypedFormControl({value: this.contactInformation.lineManagerEmail2, disabled: true}),
    });
    this.changeDetectorRef.detectChanges();
  }

  changeEditMode(editMode) {
    this.editMode = editMode;
    this.formFieldStyle = this.editMode ? 'standard' : 'none';
    if( this.editMode ) {
      this.currentEmploymentForm.enable()
    } else {
      this.currentEmploymentForm.disable()
    };

    this.changeDetectorRef.detectChanges();
  }

  copyEmail(email){
    if(email){
      this.copyMessage(email)
      this.snackBar.open(`${email} copied to clipboard`, "Close", {
        duration: 4000,
      });
    }
    else{
      this.snackBar.open("Nothing to copy", "Close", {
        duration: 4000,
      });
    }
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  saveData() {
    if (this.editMode && this.currentEmploymentForm.dirty) {
      let returnData = { values: this.currentEmploymentForm.value, operation: 'update' };

      // This really should be updated in the API to not use 'Yes' and 'No'
      returnData.values.longcontract = returnData.values.longcontract === null ? null : returnData.values.longcontract === false ? 'No' : 'Yes';
      returnData.values.wifiaccess = returnData.values.wifiaccess === null ? null : returnData.values.wifiaccess === false ? 'No' : 'Yes';
      this.currentEmploymentUpdate.emit({ 'currentEmploymentDetails': returnData });
      this.currentEmploymentForm.markAsUntouched();
      this.currentEmploymentForm.markAsPristine();
    }
  }

  cancelEdit() {
    this.editMode = false;
    this.formFieldStyle = 'none';
    this.buildForm();
  }
}
