import { Injectable } from "@angular/core";
import { AbsenceToAuthoriseDetailsComponent } from "./attendance-authorised absence/attendance-authorised.component";
import { AttendanceAnalysisComponent } from "./attendance-analysis/attendance-analysis.component";
import { AttendanceApprovalComponent } from "./attendance-approval/attendance-approval.component";

@Injectable()
export class AttendancePageService {
  getAttendanceTabs() {
    return {
      'authorisedAbsence': {
        'component': AbsenceToAuthoriseDetailsComponent,
        'label': 'Authorised Absence',
        'tabName': 'authorisedAbsence',
        'order': 0
      },
      'approval': {
        'component': AttendanceApprovalComponent,
        'label': 'Approval',
        'tabName': 'approval',
        'order': 1
      },
      'analysis': {
        'component': AttendanceAnalysisComponent,
        'label': 'Analysis',
        'tabName': 'analysis',
        'order': 2
      },
    }
  }
}
