<ng-select
  class="filter-select"
  [multiple]="this.multipleSelect"
  appearance="outline"
  [items]="this.items"
  bindLabel="name"
  [placeholder]="this.placeholder"
  [clearOnBackspace]="false"
  [closeOnSelect]="false"
  virtualScroll="true"
  [(ngModel)]="this.model"
  [ngModelOptions]="{standalone: true}"
  (open)="ngSelectOpenOrClose(itemRef)"
  (close)="ngSelectOpenOrClose(itemRef)"
  dropdownPosition="auto"
  [loading]="this.isLoading"
  [disabled]="this.isLoading"
  [notFoundText]="this.error ? this.dataError : 'No items found'"
  #itemRef
>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:3">
      <span class="ng-value-label">
        {{item.name}}
      </span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > this.displayNumber">
      <span class="ng-value-label" matTooltip="{{ generateMoreTooltipText() }}">
        {{items.length - this.displayNumber}} more
      </span>
    </div>
  </ng-template>
</ng-select>
