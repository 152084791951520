import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'learner-glance-details',
  templateUrl: './learner-glance-details.component.html',
  styleUrls: ['./learner-glance-details.component.scss']
})
export class LearnerGlanceDetailsComponent implements OnInit {

  @Output() glanceLearnerDetailsUpdate = new EventEmitter();
  @Input() learnerInfo: any;
  @Input() editPermissionLevel: number;

  defaultIcon = '../../../assets/images/icon-active_learner.png';
  defaultAlt = 'Error';

  yesIconURL = '../../../assets/images/icon-yes.png';
  noIconURL = '../../../assets/images/icon-no.png';
  yesAlt = 'Yes';
  noAlt = 'No';

  previousLearner = this.newGlanceItem('previousActLearner');
  //previousWork = this.newGlanceItem('previousActwork');
  previousWest = this.newGlanceItem('previousWestTest');
  otherTraining = this.newGlanceItem('otherTraining');
  additionalSupport = this.newGlanceItem('additionalSupport');
  englishFirstLanguage = this.newGlanceItem('englishFirstLanguage');
  welshSpeaking = this.newGlanceItem('welshSpeaking');
  westInWelsh = this.newGlanceItem('westInWelsh');
  laptopLoaned = this.newGlanceItem('laptopLoaned');

  editableGlanceDetals = [
    this.previousLearner,
    this.previousWest,
    this.otherTraining,
    this.englishFirstLanguage,
    this.westInWelsh,
    this.laptopLoaned
  ];

  updateData: object;


  editMode = false;
  editOrSave = 'edit';

  canEditSignup: boolean;

  ngOnInit(): void {
    this.setAllGlanceItems();
    this.createUpdateDataDefaults();
    this.canEditSignup = this.learnerInfo.canEditSignup;
  }


  createUpdateDataDefaults(){
    this.updateData = {
        previousActLearner: this.learnerInfo.learnerRelationship.previousActLearner === '-1' ? 'Yes' : 'No',
        previousWestTest: this.learnerInfo.learnerRelationship.previousWestTest === '-1' ? 'Yes' : 'No',
        otherTraining: this.learnerInfo.learnerRelationship.otherTraining === '-1' ? 'Yes' : 'No',
        englishFirstLanguage: this.learnerInfo.learnerLanguagePreferences.englishFirstLanguage,
        westInWelsh: this.learnerInfo.learnerLanguagePreferences.westInWelsh,
        laptopLoaned: this.learnerInfo.learnerEquipment.laptopLoaned ? 'Yes' : 'No',
    };
  }

  newGlanceItem(name:string) {
    return {
      icon: this.defaultIcon,
      alt: this.defaultAlt,
      name: name,
      _state: false,
      _savedState: false,
      get state() {
        return this._state;
      },
      set state(value: boolean) {
        this._state = value;
        this._savedState = value;
      },
      get savedState() {
        return this._savedState;
      },
      change(event) {
        this._state = event.checked;
      },
      hasBeenEdited() {
        return this._state !== this._savedState;
      },
      reset() {
        this._state = this.savedState;
      }
    }
  }

  setAllGlanceItems() {
    this.setGlanceItem(this.previousLearner, this.learnerInfo.learnerRelationship.previousActLearner === '-1');
    //this.setGlanceItem(this.previousWork, this.learnerInfo.learnerRelationship.previousActWork === '-1');
    this.setGlanceItem(this.previousWest, this.learnerInfo.learnerRelationship.previousWestTest === '-1');
    this.setGlanceItem(this.otherTraining, this.learnerInfo.learnerRelationship.otherTraining === '-1');
    this.setGlanceItem(this.additionalSupport,
      this.learnerInfo.additionalSupportRequirements.additionalLearningNeeds !== 'Learner does not have a LD and/or disability (LLDD) /not required' &&
      this.learnerInfo.additionalSupportRequirements.additionalLearningNeeds !== null);
    this.setGlanceItem(this.englishFirstLanguage, this.learnerInfo.learnerLanguagePreferences.englishFirstLanguage?.toLowerCase() === 'yes');
    this.setGlanceItem(this.welshSpeaking, this.learnerInfo.learnerLanguagePreferences.welshSpeaking?.toLowerCase() === 'yes');
    this.setGlanceItem(this.westInWelsh, this.learnerInfo.learnerLanguagePreferences.westInWelsh?.toLowerCase() === 'yes');
    this.setGlanceItem(this.laptopLoaned, this.learnerInfo.learnerEquipment.laptopLoaned);
  }


  setGlanceItem(item, conditinal) {
    if(conditinal) {
      item.icon = this.yesIconURL;
      item.alt = this.yesAlt;
      item.state = true;
    } else {
      item.icon = this.noIconURL;
      item.alt = this.noAlt;
      item.state = false;
    }
  }

  toggleEdit() {
    this.editMode = !this.editMode;
    if(this.editMode) {
      this.editOrSave = 'save';
    } else {
      let changesMade = false;
      this.editableGlanceDetals.forEach(glanceDetail => {
        if(glanceDetail.hasBeenEdited()) {
          changesMade = true;
          this.setGlanceItem(glanceDetail, glanceDetail.state);
          this.updateData[glanceDetail.name] = glanceDetail.state ? 'Yes' : 'No';
        }
      });
      // Currently just using the same api call as the mainLearnerDetails for now
      if(changesMade) {
        this.glanceLearnerDetailsUpdate.emit(this.updateData);
      }
      this.editOrSave = 'edit';
    }
  }

  cancelEdit() {
    this.editMode = false;
    this.editOrSave = 'edit';
    this.editableGlanceDetals.forEach(detail => {
        detail.reset();
    });
  }



}
