import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-request-registration',
  templateUrl: './request-registration.component.html',
  styleUrls: ['./request-registration.component.scss']
})
export class RequestRegistrationComponent implements OnInit {
  staffId: number;

  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
  private fb: UntypedFormBuilder,
  private dialogRef: MatDialogRef<RequestRegistrationComponent>) { }

  requestRegistrationForm: UntypedFormGroup;
  objectives: object[];

  ngOnInit(): void {
    this.objectives = this.data.objectives.filter((objective) => objective.status !== 'Withdrawn');
    this.buildForm();
  }

  buildForm() {
    this.requestRegistrationForm = this.fb.group({
      objective: new UntypedFormControl(),
    });
  }

  close(){
    this.dialogRef.close();
  }

}
