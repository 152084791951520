import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from "@azure/msal-browser";
import { StaffAdminComponent } from './home/staff-admin/staff-admin.component';
import { AuthGuard } from './core/services/auth-guard.service';
import { RedirectComponent } from './redirect/redirect.component';
import { LoginComponent } from './login/login.component';
import { LogOutComponent } from './log-out/log-out.component';
import { ForceLogOutComponent } from './log-out/force-log-out.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BdHomepageComponent } from './bd-homepage/bd-homepage.component';



export const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'login', component: LoginComponent },
  { path: 'log-out', component: LogOutComponent },
  { path: 'force-log-out', component: ForceLogOutComponent },
  { path: 'auth-error', component: AuthErrorComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'learner', loadChildren: () => import('./learner/learner.module').then(m => m.LearnerModule) },
  { path: 'employer-old', loadChildren: () => import('./employer-dashboard/employer-dashboard.module').then(m => m.EmployerDashboardModule) },
  { path: 'employer', loadChildren: () => import('./employerV2/employer.module').then(m => m.EmployerModule) },
  { path: 'staff', loadChildren: () => import('./staff/staff-dashboard/staff-dashboard.module').then(m => m.StaffDashboardModule) },
  { path: 'reports', loadChildren: () => import('./report-list/report-list.module').then(m => m.ReportListModule) },
  { path: 'staff-admin', component: StaffAdminComponent, canActivate: [AuthGuard] },
  { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'attendance', loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule) },
  { path: 'bd-homepage', component: BdHomepageComponent },
  { path: '**', component: PageNotFoundComponent }

];

const isIframe = window !== window.parent && !window.opener

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        preloadingStrategy: PreloadAllModules,
        useHash: false,
        relativeLinkResolution: 'corrected',
        initialNavigation:
          !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
            ? "enabledNonBlocking"
            : "disabled",
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
