import { SharedFunctionsService } from './../../../core/services/shared-functions.service';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import moment from 'moment';
import { AdminDashReportsService } from 'src/app/core/database/admin-dash-reports.service';
import { DownloadService } from 'src/app/core/services/download.service';
import * as _ from 'underscore';
import { DataListDialogComponent } from './data-list-dialog/data-list-dialog.component';
import { DataListService } from './data-list.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss']
})
export class DataListComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<any>,
    private snackBar: MatSnackBar,
    private router: Router,
    private downloadService: DownloadService,
    private dialog: MatDialog,
    private dataListService: DataListService,
    private adminDashReportService: AdminDashReportsService,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedFunctions: SharedFunctionsService
    ) { }

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();
  tooltipColumns: string[] = ['centre', 'sector'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dialogRef.updateSize('80%', '80%');
    this.setCols();
    this.createTooltipText();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.data;
    console.log('data to use', this.data);
    let dateCols = ['dob', 'requested', 'dateSignedOff', 'certAppliedFor']
    this.dataSource.sortingDataAccessor = (item, property) => {
        if (dateCols.includes(property)) {
          let newDate = moment(item[property], "DD/MM/YYYY").toDate();
          return newDate;
        }
        else {
          return item[property];
        }
      };
  }

  setCols() {
    let cols = Object.keys(this.data[0])
    for (let col in cols) {
      this.displayedColumns.push(cols[col]);
    };
  }

  createTooltipText() {
    let hasColumns: string[] = _.intersection(this.displayedColumns, this.tooltipColumns);

    if (hasColumns.length > 0) {
      if(this.displayedColumns.indexOf('info') == -1){
        this.displayedColumns.push('info');
      }

      for (let i = 0; i < this.data.length; i++) {
        let textArray: string[] = [];
        let text: string = '';

        hasColumns.forEach(col => {
          const title = col[0].toUpperCase() + col.substring(1);
          textArray.push(title + ': ' + this.data[i][col]);
        });

        textArray.forEach(t => {
          text += t + ' | ';
        });

        text = text.substring(0, text.length - 3);
        this.data[i].info = text;
      }

      for (let i = 0; i < this.displayedColumns.length; i++) {
        if (this.tooltipColumns.indexOf(this.displayedColumns[i]) != -1) {
          this.displayedColumns.splice(i, 1);
        }
      }
    }
  }

  processColumnTitle(title: string) {
    if(title == 'info') return '';
    return this.sharedFunctions.unCamelCase(title);
  }

  loadLearner(traineeId, pot) {
    this.snackBar.open("Loading learner details", "Close", {
      duration: 4000,
    });
    const link =  `/learner/dashboard/${traineeId}/${pot}`;
    this.router.navigate([link]);
    this.dialogRef.close();
  }

  isPercentage(value, column) {
    let isNum: boolean = false;
    if (['frameworkOverallActual', 'frameworkOverallTarget', 'mqProgress', 'mqTarget', 'aonProgress', 'aonProgress','comProgress', 'dlProgress'].includes(column)) {
      isNum = typeof(value) === 'number';
    }
    return isNum;
  }

  export() : boolean {
    var mappedData = []
    var dis = this.displayedColumns
    this.data.forEach(function (v) {
      var j = {};
      dis.forEach(prop => {
         j[prop] = v[prop]
      });
      for(var key in j) {
        if (j.hasOwnProperty(key)) {
          j[key.charAt(0).toUpperCase() + key.substring(1)] = j[key];
          delete j[key];
        }
      };
      mappedData.push(j);
    });

    let today = new Date().toLocaleDateString('en-gb');
    this.downloadService.downloadFile(mappedData, `export-${today}`);
    return false;
  }

  openDialog(data = {}) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '500px';
    if (data) {
      console.log(data, 'data to send to dialog');
      dialogConfig.data = {comment: data['notes'], trModuleId: data['trModuleId']};
    }
    const dialogRef = this.dialog.open(DataListDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        this.dataListService.addNotes(formData.trModuleId, formData.comment)
          .subscribe(a=> {
            if (a == 200) {
              this.adminDashReportService.getAll().then(a=> {
                let data = a[0];
                let updated = 0;
                const tablesToUpdate = ['regRequests', 'awaitingCertificates', 'toBeClaimed', 'withdrawnActivities']
                for (let t in tablesToUpdate) {
                  let tableToUpdate = tablesToUpdate[t]
                  let objIndex = data[tableToUpdate].findIndex((obj => obj.trModuleId == formData.trModuleId));
                  if (objIndex != -1) {
                    // update localdb
                    data[tableToUpdate][objIndex].notes = formData.comment;
                    // update mat table source
                    let tabIndex = this.dataSource.data.findIndex((obj => obj['trModuleId'] == formData.trModuleId));
                    this.dataSource.data[tabIndex]['notes'] = formData.comment;
                    updated = 1;
                  };
                }
                if (updated == 1) {
                  this.adminDashReportService.clear();
                  this.adminDashReportService.add(data, 1);
                  this.changeDetectorRef.detectChanges();
                }
              });
            }
          })
      }
    });
  }

}
