<div class="form-container">

  <form novalidate autocomplete="off" (ngSubmit)="executeSearch()" [formGroup]="searchForm" class="search-container">

    <div *ngIf="sideBarOpen" class="sidebar">
      <div>
        <h2>Customise Results &nbsp;&nbsp;<img src="../../assets/images/icon-filter.png"
            style="background-color: #483B8D;"></h2>
      </div>

      <mat-toolbar>

        <mat-toolbar-row style="height: 32px;">
          <button type="button" mat-button (click)="clearAllLocalFilters()">Clear all filters</button>
        </mat-toolbar-row>


        <mat-toolbar-row style="height: 48px;">
          <p>Address Part</p>
        </mat-toolbar-row>

        <mat-toolbar-row style="height: 16px;">
          <mat-form-field appearance="fill" class="no-underline filter-input" style="width: 200px !important;"
            [ngStyle]="filterStyle">
            <mat-label class="filter-input-label">Try part of an address</mat-label>
            <input matInput formControlName="addressPartFilter" class="filter-input-text"
              [(ngModel)]="selectedAddressPart"
              (keydown)="setAddressPartFilter($event)">
            <mat-icon matSuffix class="ui-icon icon--click-hover" (click)="setLocalFilter(selectedAddressPart, 'addressPart')">search</mat-icon>
          </mat-form-field>
        </mat-toolbar-row>

        <mat-toolbar-row style="height: 32px;">
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="employerBranchOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedEmployerBranchName, 'employerBranch')" class="filter-select" [items]="employerBranchOptions"
            bindLabel="employerBranch" placeholder="Employer or branch name" [(ngModel)]="selectedEmployerBranchName"
            (clear)="clearSelected('employerBranch')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="contactOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedContactName, 'contact')" class="filter-select" [items]="contactOptions"
            bindLabel="contact" placeholder="Contact name" [(ngModel)]="selectedContactName"
            (clear)="clearSelected('contact')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="postCodeOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedPostCode, 'postCode')" class="filter-select" [items]="postCodeOptions"
            bindLabel="postCode" placeholder="Postcode" [(ngModel)]="selectedPostCode"
            (clear)="clearSelected('postCode')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="postTownOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedPostTown, 'postTown')" class="filter-select" [items]="postTownOptions"
            bindLabel="postTown" placeholder="Post town" [(ngModel)]="selectedPostTown"
            (clear)="clearSelected('postTown')">
          </ng-select>
        </mat-toolbar-row>

      </mat-toolbar>

      <mat-spinner *ngIf="showSpinner"></mat-spinner>
    </div> <!-- sidebar -->


    <div style="float: left; width: 100%; padding-top: 60px;">

      <div class="controls-container" style="padding-left: 0px;">

        <div *ngIf="!sideBarOpen" class="titlebox">

          <div style="float: left;">
            <h1>Employer Search</h1>
            <h3>Who can we help you find today?</h3>
          </div>

          <div style="float: right; padding-top: 22px">
            <button mat-flat-button color="primary" class="create-button" (click)="addEmployer()">Add a New Employer</button>
          </div>

          <div style="padding-top: 32px; clear: both;">

            <mat-form-field *ngIf="isLocalSearch() == false" style="width: calc(8% + 140px);" appearance="fill"
              class="no-underline search-type-select">
              <mat-select style="padding-left: 10px;" formControlName="searchType" [ngModel]="selectedSearchType.value"
                (selectionChange)="onSelectedSearchType($event)">
                <mat-option #searchTypeText *ngFor="let searchType of searchTypes" [value]="searchType.value">
                  {{searchType.title}}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix>arrow_drop_down</mat-icon>
            </mat-form-field>

            <mat-form-field *ngIf="isLocalSearch() == false"
              style="width:calc(80% - 420px); min-width: 250px; flex: 0.75;" appearance="fill"
              class="no-underline search-type-external">
              <input matInput placeholder="{{searchTypeString}} Search" value="" formControlName="searchText"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addFilter($event)" [matAutocomplete]="auto"
                (focus)="onFocus($event)" (focusout)="lostFocus($event)" style="width: 100%;">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of options | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
              <mat-icon matSuffix (click)="addFilter(null, true)" class="icon--click-hover">search</mat-icon>
            </mat-form-field>

            <div style="width: 100%; display: flex;">

              <mat-chip-list #chipList aria-label="Filter selection" class=chiplist>
                <mat-chip matChipRemove style=" border-radius: 5px; background-color: #E0E0E0;"
                  *ngFor="let filter of filtersOrdered()" [class.search-primary]="searchTypeClass(filter) == 'primary'"
                  [class.search-secondary]="searchTypeClass(filter) == 'secondary'" [selectable]="selectable"
                  [removable]="removable" (removed)="removeFilter(filter)">
                  {{filter.value}}
                  <mat-icon matChipRemove *ngIf="removable" class="filterChipRemove">close</mat-icon>
                </mat-chip>
              </mat-chip-list>

              <mat-spinner *ngIf="showSpinner"></mat-spinner>

            </div>

            <div>
              <mat-label class="no-results-message" *ngIf="noResultsMessage != ''">
                {{noResultsMessage}}
              </mat-label>
            </div>

          </div>

        </div>
      </div>

      <div class="results">
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="20px" fxLayoutAlign="left" class="max-width">

          <div *ngIf="isLocalSearch() == true" class="name-header" style="width: 100%;">
            {{externalFilterValue()}}
            <a href="#" (click)="resetForm()" id="clear-button">Clear</a>
          </div>

          <div style="text-align: center; margin: 0px 30px 30px 0px;" *ngFor="let element of searchResultsFiltered.combined">

            <mat-card (click)="onSelect(element)" class="search-card">

              <div class="mat-title employer-name">
                {{element.employerName}}
             </div>
              <div class="mat-subtitle" *ngIf="element.branchName != null">
                {{element.branchName}}
             </div>
              <div class="mat-subtitle" *ngIf="element.branchName == null">
                {{element.postTown}}
             </div>
              <div class="spacer"></div>
              <div class="mat-info">
                <img src="../../assets/images/icon-assessor.png" style="padding-right: 7px;">
                {{element.contactName}}
             </div>
              <div class="mat-info">
                <img src="../../assets/images/icon-date.png" style="padding-right: 7px;"> {{element.phone}}
             </div>
          </mat-card>

          </div>
        </div>

      </div>

    </div>

  </form>

</div>
