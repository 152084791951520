<mat-card class="learners-card">
    <div class="learners-container">
        <button *ngIf="dataSource" mat-flat-button color="primary" class="learners-button" routerLink="/learner/learner-search">Search all Learners</button>
        <h2 class="dashboard-titles" style="padding-left:0px;">Your Learners</h2>
        <div style="display: flex; flex-direction: row; justify-content: space-around">
          <mat-slide-toggle style="align-self: center; padding-bottom: 1em;" [checked]="this.hidePendingStarts" (change)="toggleHidePendingStarts($event)">Hide Pending Starts</mat-slide-toggle>
          <div style="align-self: center; width: 60%">
            <mat-form-field style="width: 100%" appearance="outline">
                <mat-icon matPrefix >search</mat-icon>
                <input #txtVal matInput placeholder="Start typing to jump to a learner" (keyup)="applyFilter(txtVal.value)" autocomplete="off">
            </mat-form-field>
          </div>
        </div>

        <table class="widget-your-employers-table" [hidden]="!dataSource" mat-table [dataSource]="dataSource" matSort>
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{unCamelCase(column)}} </th>
                <ng-container *ngIf="column=='name'; else otherData">
                    <td mat-cell *matCellDef="let element" > <a routerLink="/learner/dashboard/{{element['traineeId']}}/{{element['pot']}}"> {{element[column]}} </a> </td>
                </ng-container>
                <ng-template #otherData>
                    <td mat-cell *matCellDef="let element"
                    [style]="column === 'signedIntoCenter'
                              ? element[column] === 'Yes' ? 'background-color: #a8d694' : 'background-color: #d69494'
                              : ''"
                    >
                      {{ element[column] }}
                    </td>
                </ng-template>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator  [pageSize]="6"  [pageSizeOptions]="[6, 15, 50]" showFirstLastButtons></mat-paginator>
    </div>


</mat-card>
