import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-most-recent-sign-in-report',
  templateUrl: './most-recent-sign-in-report.component.html'
})
export class MostRecentSignInReportComponentV2 {

  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.MostRecentSignIn;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'lastSignInDate', 'signInCentre', 'timeIn', 'timeOut', 'status']
    }
  ];
  dateFilters = ['lastSignInDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'signInCentre', title: 'Sign In Centre', columns: ['signInCentre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'status', title: 'Status', columns: ['signInCentre', 'status', 'count'],
      pieChartDrillDown: 'status', pieChartShareValue: 'count'
    },
  ];
}
