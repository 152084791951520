import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyDate'
})
export class FriendlyDatePipe  implements PipeTransform {

  constructor(private dp: DatePipe) {
  }

  transform(item: any) {
  
      var value = item.createddateepoc
    console.log(value)
    var _value = Number(value);

    var today = new Date()
    var todayInt = today.setHours(0,0,0,0) / 1000

    console.log(todayInt)

    var dif = Math.floor( ( (todayInt - _value) ) );
    console.log('dif', dif)
    if ( dif == 0 ){
      return "Today";
    }
    else if(dif == 86400){
      return "Yesterday";
    }
    else{
      var date = new Date(item.createddate)
      let formattedByDatePipe = this.dp.transform(date, 'd MMMM, y');
      return formattedByDatePipe;
    }
   
 }

}
