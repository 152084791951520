import { SharedFunctionsService } from './../../../core/services/shared-functions.service';
import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { RequestRegistrationComponent } from './request-registration/request-registration.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LearnerService } from '../../learnerService.service';
import { ChangeDetectorRef } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DATE_FORMAT, NO_DATA } from 'src/app/app.constants';

export interface genericResponse {
  data: any;
  success: boolean;
  error: string;
};

export interface inputData {
  objectives: genericResponse;
  regRequests: genericResponse;
  traineeId: string;
  pot: number;
  canDeleteRegRequests: genericResponse;
};

@Component({
  selector: 'objectives',
  templateUrl: './objectives.component.html',
  styleUrls: ['./objectives.component.scss']
})
export class ObjectivesComponent {

  displayedColumns: string[] = ['laimRef', 'title', 'assessorName', 'regDate', 'regNumber', 'percentComplete', 'status', 'certAppliedFor', 'certReceived'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  objectives: any;
  regRequests: any;
  canDeleteRegRequests: Boolean;

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;

  @Input() traineeId: string;
  @Input() pot: number;

  loadedObjectives: boolean = false;

  data: { 'objectives': any; 'regRequests': any; 'traineeId': string; 'pot': number;};

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private learnerService: LearnerService,
    public sharedFunctions: SharedFunctionsService,
    private cdf: ChangeDetectorRef,
  ) { }

  setData(data: inputData) {
    this.objectives = data.objectives;
    this.regRequests = data.regRequests;
    this.traineeId = data.traineeId;
    this.canDeleteRegRequests = data.canDeleteRegRequests.success ? data.canDeleteRegRequests.data : false;
    this.pot = data.pot;

    if(this.objectives.success) {

      if(this.regRequests.success) {
        this.setRegRequests();
      } else {
        console.log('Error loading regRequests:', this.regRequests.error);
      }

      this.dataSource.data = this.objectives.data;

    } else {
      console.log('Error loading objectives:', this.objectives.error);
    }

    this.loadedObjectives = true;
    this.cdf.detectChanges();
  }

  setRegRequests() {
    this.objectives.data?.forEach(objective => {
      if(objective['regDate'] === null) {
        const regRequest: any = this.regRequests.data?.find(r => r.trModuleId === objective.trModuleId);
        if(regRequest !== undefined) {
          objective.regRequestedBy = regRequest.staffFullName;
          objective.regRequestedDate = regRequest.dateRequested;
          objective.requestId = regRequest.requestId;
        } else {
          objective.regRequestedBy = null;
          objective.regRequestedDate = null;
          objective.requestId = null;
        }
      }
    });
  }

  requestRegistration() {
    const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      objectives: this.objectives.data.filter(a => a.regNumber == null && a.status !== 'APL'),
    }
    const dialogRef: MatDialogRef<RequestRegistrationComponent, any> = this.dialog.open(RequestRegistrationComponent, dialogConfig);

    dialogRef.afterClosed().subscribe( formData => {
      if (formData) {
        const trModuleId: number = formData.value.objective;
        const request = {
          'traineeId': this.traineeId,
          'pot': this.pot,
          'trModuleId': trModuleId,
        };
        this.createNewRegRequest(request);
      }
    });
  }

  createNewRegRequest(request: any) {
    const subscription = this.learnerService.createNewRegRequest(request).subscribe({
      next: (response) => {
        this.regRequests.data.push(response);
        this.setRegRequests();
        this.snackBar.open("Registration requested!", "Close", {
          duration: 3000,
        });
      },
      error: (error) => {
        console.log('Error creating new registration request:', error.error);
        if(error.error.error === 'A Request already exists') {
          this.snackBar.open("Error: A registration request already exists for this objective.", "Close", {
            duration: 3000,
          });
        } else {
          this.snackBar.open("Error: Failed to create new registration request please try again. If this error persists, please contact support.", "Close", {
            duration: 3000,
          });
        }
        subscription.unsubscribe();
      },
      complete: () => {
        this.cdf.detectChanges();
        subscription.unsubscribe();
      }
    });
  }

  deleteRegRequest(regRequestId: number) {
    if(!this.canDeleteRegRequests) {
      this.snackBar.open("Error: You do not have permission to delete this registration request.", "Close", {
        duration: 3000,
      });
      return;
    }

    const regTitle: any = this.objectives.data.filter(a => a.requestId == regRequestId)[0].title;
    const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
    dialogConfig.data = {
      message: `You are about to **remove** the registration request for **${regTitle}**. Do you wish to continue?`,
      title: 'Are you sure?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warn',
    }
    const dialogRef: MatDialogRef<ConfirmDialogComponent, any> = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result =>
      {
        if(result) {
          const subscription = this.learnerService.deleteRegRequest(regRequestId).subscribe({
            next: (response) => {
              this.regRequests.data = this.regRequests.data.filter(r => r.requestId !== regRequestId);
              this.setRegRequests();
            },
            error: (error) => {
              console.log('Error deleting registration request:', error.error);
              this.snackBar.open("Error: Failed to delete registration request please try again. If this error persists, please contact support.", "Close", {
                duration: 3000,
              });
              subscription.unsubscribe();
            },
            complete: () => {
              this.snackBar.open("Registration request remove!", "Close", {
                duration: 3000,
              });
              this.cdf.detectChanges();
              subscription.unsubscribe();
            }
          });
        }
    });
  }
}
