import { ChangeDetectorRef, Component } from '@angular/core';
import { LearnerService } from '../learnerService.service';
import { LearnerPageComponent } from '../learner-page.component';
import { DATE_FORMAT, NO_DATA, ERROR_GETTING_DATA } from 'src/app/app.constants';

@Component({
  selector: 'learner-dashboard',
  templateUrl: './learner-dashboard.component.html',
  styleUrls: ['./learner-dashboard.component.scss']
})
export class LearnerDashboardComponent implements LearnerPageComponent {

  westDetails: any[];
  reviewDetails: any;
  quizResults: any[];

  dashboardLoaded = false;
  potProgress: any;
  milestones: any;
  oneFileId: string;
  showWest = false;
  showProgress = false;
  showMilestones = false;

  quizResultsSuccess = false;

  traineeId: string;
  pot: number;
  staffId: number;

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;
  errorGettingData = ERROR_GETTING_DATA;


  constructor(
    private learnerService: LearnerService,
    private cdr: ChangeDetectorRef,
    ) {
  }


  public getData()
  {
    this.getDashboardData();
  }

  getDashboardData() {
    this.dashboardLoaded = false;
    const dashboardSubscription = this.learnerService.getLearnerDashboard(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.oneFileId = response.oneFileId.data;
        this.westDetails = response.westDetails.data;
        this.potProgress = response.potProgress.data;
        this.milestones = response.milestones.data;
        this.reviewDetails = response.reviewDetails.data;
        this.quizResults = response.quizResults.data;

        this.showProgress = response.potProgress.success && response.potProgress.data.length > 0;
        this.showWest = response.westDetails.success && ((this.westDetails['applicationOfNumbers']?.length > 0 || this.westDetails['aonTarget'] !== null)
          || (this.westDetails['communications']?.length > 0 || this.westDetails['comTarget'] !== null)
          || (this.westDetails['digitalLiteracies']?.length > 0 || this.westDetails['dlTarget'] !== null));
        this.showMilestones = response.milestones.success;
        this.quizResultsSuccess = response.quizResults.success;
      },
      error: (err) => {
        console.log('Error loading dashboard:', err.error);
        this.dashboardLoaded = true;
        this.cdr.detectChanges();
        dashboardSubscription.unsubscribe();
      },
      complete: () => {
        this.dashboardLoaded = true;
        this.cdr.detectChanges();
        dashboardSubscription.unsubscribe();
      }
    });



  }

}
