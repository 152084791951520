import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { UploadService } from 'src/app/core/services/upload.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-learning-aim-units-form',
  templateUrl: './learning-aim-units-form.component.html',
  styleUrls: ['./learning-aim-units-form.component.scss']
})
export class LearningAimUnitsFormComponent implements OnInit {

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  larUnitsForm: UntypedFormGroup;
  files: any[] =[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LearningAimUnitsFormComponent>,
    private sharedFunctions: SharedFunctionsService,
    private uploadService: UploadService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  /**
    * on file drop handler
    */
   onFileDropped($event) {
    //console.log('onFileDropped', $event)
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }


  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.files = [];

    this.files.push(files[0]);

    this.uploadService.importLearningAimUnitFile(files[0], this.data.laimRef).subscribe(
      importedUnits => {
        console.log('importedUnits', importedUnits);
        let message: string;
        if (importedUnits.length) {
          this.data.units = importedUnits;
          message = 'Your file has been imported';
        } else {
          message = 'A problem has occurred and the import was aborted. Please check your file and try again. Note: should be a well structured .csv file.';
        }
        this.snackBar.open(message, 'Close', {
          duration: 5000,
        });
      }
    );
  }

  /*
  // Not used - only use this if need API to import the CSV file, i.e. not via file picker/dnd directive
  importUnitsViaApi() {
    this.homeDashboardService.importLearningAimUnitsFromCsv(this.data.laimRef).subscribe(
      response => {
        this.snackBar.open('Your file has been imported', "Close", {
          duration: 5000,
        });
      });
  }

  // Not used - only use this if need API to import the CSV file, i.e. not via file picker/dnd directive
  exportUnitsViaApi() {
    this.homeDashboardService.exportLearningAimUnitsAsCsv(this.data.laimRef).subscribe(
      response => {
        this.snackBar.open(`Your file has been saved as ${response}`, "Close", {
          duration: 5000,
        });
      });
  }
  */

  exportUnits(asTemplate: boolean) {
    if(!asTemplate && this.data.units.length == 0) return false;

    let keys: string[] = [];

    if (this.data.units.length) {
      for (const [key, value] of Object.entries(this.data.units[0])) { keys.push(key); }
    } else {
      keys = ['LaimRef', 'Unit', 'Title', 'IqaCreditLevel', 'IqaCreditValue', 'IqaCreditGroup', 'Abunit']
    }

    if(!asTemplate) {
      const fileName = this.sharedFunctions.export(this.data.units, keys, `LaimRefUnits_${this.data.laimRef}`, 'csv', true, false);
      this.snackBar.open(`Your units file - ${fileName} - has been exported to your downloads folder`, "Close", {
        duration: 5000,
      });
    }else{
      const fileName = this.sharedFunctions.exportBlankTemplate(keys, 'LaimRefUnits_Template', 'csv', true, true);
      this.snackBar.open(`Your template units file - ${fileName} - has been exported to your downloads folder`, "Close", {
        duration: 5000,
      });
    }
  }

  closeForm() {
    this.dialogRef.close();
  }
}
