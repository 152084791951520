import { NgModule } from "@angular/core";
import { MaterialsModule } from "../core/materials/materials.module";
import { AttendancePageService } from "./attendance-page.service";
import { AttendanceTabsComponent } from "./attendance-template/attendance-tabs/attendance-tabs.component";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { RouterModule } from "@angular/router";
import { AttendanceRoutingModule } from "./attendance-routing.module";
import { AttendanceTemplateComponent } from "./attendance-template/attendance-template.component";
import { AttendanceDynamicDirective } from "./attendance-dynamic.directive";
import { AttendanceTemplateResolverService } from "./attendance-template/attendance-template-resolver.service";
import { AbsenceToAuthoriseDetailsComponent } from "./attendance-authorised absence/attendance-authorised.component";
import { AttendanceAuthorisedService } from "./attendance-authorised absence/attendance-authorised.service";
import { AuthoriseDialogComponent } from "./attendance-authorised absence/authorise-dialog/authorise-dialog.component";
import { AttendanceAnalysisComponent } from "./attendance-analysis/attendance-analysis.component";
import { AttendanceApprovalComponent } from "./attendance-approval/attendance-approval.component";
import { TimeEditDialogComponent } from "./attendance-approval/time-edit-dialog/time-edit-dialog.component";

@NgModule({
  declarations: [
    AttendanceDynamicDirective,
    AttendanceTabsComponent,
    AttendanceTemplateComponent,
    AbsenceToAuthoriseDetailsComponent,
    AuthoriseDialogComponent,
    AttendanceAnalysisComponent,
    AttendanceApprovalComponent,
    TimeEditDialogComponent
  ],
  imports: [
    MaterialsModule,
    NgxDaterangepickerMd,
    FormsModule,
    NgSelectModule,
    RouterModule,
    AttendanceRoutingModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd
  ],
  providers: [
    AttendancePageService,
    AttendanceTemplateResolverService,
    AttendanceAuthorisedService
  ]
})

export class AttendanceModule { }
