<div class="widget-container" fxLayout="row wrap" fxLayoutGap="25px grid">
  <ng-template selectedWidgetHost></ng-template>
</div>

<div class="home-container">

  <div class="hello-container">
    <div style="flex: 1">
      <h3 class="progressTitle hello-text" style="margin-block-end: 0px;">Hello {{user.firstName}}, </h3>
      <p class="hello-text hello-notifications">You have <a routerLink='/notifications'>{{countUnread}}
          notifications</a></p>
    </div>
    <div style="flex: 1;">

    </div>
  </div>

  <div class="page-selector" *ngIf="showDefaultPageSelection">
    <ng-select [items]="homepages" bindLabel="name" bindValue="type" placeholder="Choose default page"
      name="selectedHomepage" [(ngModel)]="selectedHomepage">
    </ng-select>
    <button mat-button [disabled]="!selectedHomepage" color="primary" (click)="setDefaultPage()">Set default</button>
  </div>

  <div>
    <app-home-generic #genericDashboard [staffId]="user.staffId" [department]="user.department"
      [defaultPage]="user.defaultPage">
    </app-home-generic>
  </div>

</div>