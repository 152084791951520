import { Component } from '@angular/core';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-occupancy-report',
  templateUrl: './occupancy-report.component.html'
})
export class OccupancyReportComponentV2 {

  reportVariant = ReportVariant.Occupancy;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'potStatus', 'subcontractor', 'scheme', 'sector', 'assessor', 'centre', 'email', 'mobile',]
    }
  ];
  dateFilters = ['startDate', 'expectedEndDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];

  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch (column) {
      case 'targetProgressPercent':
        if (value >= 85 && row['actualProgress'] > row['targetProgress']) {
          style = { 'background-color': '#da9ef0' };
        } else if (value >= 85) {
          style = { 'background-color': '#adffcb' };
        } else if (value !== null) {
          style = { 'background-color': '#f5a4a4' };
        }
        break;
    }
    return style;
  }

}
