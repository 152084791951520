import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReviewRecordFormComponent } from './review-record-form/review-record-form.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LearnerService } from '../learnerService.service';
import { LearnerPageComponent } from '../learner-page.component';
import { DATE_FORMAT, NO_DATA } from 'src/app/app.constants';

@Component({
  selector: 'learner-reviews',
  templateUrl: './learner-reviews.component.html',
  styleUrls: ['./learner-reviews.component.scss']
})
export class LearnerReviewsComponent implements LearnerPageComponent {

  traineeId: string;
  pot: number;
  staffId: number;

  loadedReviewsData: boolean = false;

  displayedColumns: string[] = ['title', 'actualDate', 'assessor', 'status'];
  dataSource = new MatTableDataSource([]);
  classGreen: string[] = ['Successful', 'Successful (No EC)', 'Successful (EC)', 'Completed Review'];
  classRed: string[] = ['Actions Required', 'Missed/Missing', 'Not able to see', 'Suspended', 'Signature Missing', 'Unsuccessful', 'Returned'];

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private learnerService: LearnerService
  ) { }

  getData() {
    const subscription = this.learnerService.GetLearnerReviews(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.dataSource.data = response.data;
      },
      error: (error) => {
        this.loadedReviewsData = true;
        this.cdr.detectChanges();
        console.log('Error loading reviews:', error.error);
        this.snackBar.open('Error loading reviews', 'Close', {
          duration: 3000,
        });
        subscription.unsubscribe();
      },
      complete: () => {
        this.loadedReviewsData = true;
        this.cdr.detectChanges();
        subscription.unsubscribe();
      }
    });
  }

  getOutcomeClass(outcome: string): string {
    return this.classGreen.indexOf(outcome) > -1
    ? 'outcome-stamp-pass'
    : this.classRed.indexOf(outcome) > -1
      ? 'outcome-stamp-fail'
      : 'outcome-stamp-draft';
  }

  openDialog(selectedRecord?) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    if (selectedRecord != null) {
      let record = this.dataSource.data.find(rec => rec.id == selectedRecord.id);

      if (record != null) {
        dialogConfig.data = {
          Id: record.id,
          Outcome: selectedRecord.outcome,
          OutcomeClass: this.getOutcomeClass(selectedRecord.outcome),
          Date: record.actualDate,
          ReviewType: record.visitType,
          Notes: record.notes,
          FullDetails: record.digitalReviewDetails
        }
      }

      this.dialog.open(ReviewRecordFormComponent, dialogConfig);

    }

    return false;
  }

  async getReviews(tid = this.traineeId) {
    var data = { 'traineeId': tid };
    //this.showSpinner = true;
    this.learnerService.importReviewsForTraineeFromOnefile(data).subscribe(response => {
      //this.showSpinner = false;
      this.cdr.detectChanges();
      this.snackBar.open("Sync complete", "Success", {
        duration: 3000,
      });
    }, error => {
      this.snackBar.open("Unable to Sync", "Error", {
        duration: 5000,
      });
      //this.showSpinner = false;
      this.cdr.detectChanges();
    })


  }
}
