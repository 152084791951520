<div class="notifications-container">
    <h1>
        Notifications
    </h1>
    <div *ngIf="notifications?.length == 0">
        Nothing new
    </div>
    <!-- {{notifications?[0] | json}} -->
    <div *ngFor="let notificationSet of notifications">
        <mat-list>
            <div mat-subheader>{{notificationSet[0] | friendlyDate }}</div>

            <mat-list-item *ngFor="let notificationItem of notificationSet"
                [ngSwitch]="notificationItem.vnotificationtype">

                <!-- <div class="green-circle"></div> -->
                <mat-icon mat-list-icon class="green-circle" *ngIf="!notificationItem.hasbeenread"></mat-icon>
                <mat-icon mat-list-icon class="grey-circle" *ngIf="notificationItem.hasbeenread"></mat-icon>

                <div mat-line *ngSwitchCase="'ReportShared'" class="notification-text">
                    <a [href]="notificationItem.vreportlink">{{notificationItem.vreportname }}</a> <span> has been
                        shared with you.</span>
                </div>

                <div mat-line *ngSwitchCase="'ReportExported'" class="notification-text">
                    <span>The report {{notificationItem.vreportname }} is now available to download from </span><a
                        [href]="notificationItem.vreportlink">here.</a><span> The link will remain active for 12
                        hours.</span>
                </div>

                <div mat-line *ngSwitchCase="'NewLearner'" class="notification-text">
                    <a routerLink="/learner/dashboard/{{notificationItem.vlearnerid}}/{{notificationItem.vlearnerpot}}">{{notificationItem.vlearnername
                        }}</a> <span> has been assigned to your caseload.</span>
                </div>

                <div mat-line *ngSwitchCase="'ReviewActions'" class="notification-text">
                    <span><a [href]="notificationItem.vreportlink">{{notificationItem.vlearnername}}'s</a> recent review
                        has actions that may need addressing.</span>
                </div>

                <div mat-line *ngSwitchCase="'OverEED'" class="notification-text">
                    <span><a [href]="notificationItem.vreportlink">{{notificationItem.vlearnername }}</a> has gone past
                        their expected end date.</span>
                </div>

                <div mat-line *ngSwitchCase="'NotRegistered'" class="notification-text">
                    <span><a [href]="notificationItem.vreportlink">{{notificationItem.vlearnername}}</a> is about to
                        start an unregistered qualification ({{notificationItem.vreportname}}).</span>
                </div>

                <div mat-line *ngSwitchCase="'IQAReferred'" class="notification-text">
                    <span><a [href]="notificationItem.vreportlink">{{notificationItem.vlearnername}}</a> has an
                        outstanding IQA referral ({{notificationItem.vreportname}}). </span>
                </div>

                <div mat-line *ngSwitchCase="'LateFinalCheckIQA'" class="notification-text">
                    <span><a [href]="notificationItem.vreportlink">{{notificationItem.vlearnername}}</a> is overdue an
                        IQA Final Check ({{notificationItem.vreportname}}). </span>
                </div>

                <div mat-line *ngSwitchCase="'OutstandingHealthAndSafety'" class="notification-text">
                    <span>{{notificationItem.vlearnername}}'s <a [href]="notificationItem.vreportlink">employer</a> is
                        due a health and safety check. </span>
                </div>

                <div mat-line>By {{notificationItem.vbystaffname}} @ {{notificationItem.createddate | date:'shortTime'}}
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <!-- <div mat-subheader>Notes</div>
        <mat-list-item *ngFor="let note of notes">
          <mat-icon mat-list-icon>note</mat-icon>
          <div mat-line>{{note.name}}</div>
          <div mat-line> {{note.updated | date}} </div>
        </mat-list-item>
      -->
        </mat-list>
    </div>
</div>