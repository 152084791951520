<!-- Learner -->
<div *ngIf="this.eventDisplayType === this.eventDisplayTypeEnum.learner" class="event" [ngClass]="{'highlight' : this.highlight }">
  <span style="font-weight: 450;">
    {{event.eventType ? event.eventType : '[no type]'}} -
    <span style="font-weight: 400;">
      {{ event.eventDate | date: this.dateFormat }}
      <span *ngIf="event.eventDate == null">[no date]</span>
    </span>
  </span>

  <div class="event-by body-text">
    By {{ event.staffName }} - Created on {{ event.created | date: this.dateTimeFormat }}
  </div>
  <div class="event-comment body-text" [style]="textExpand ? 'white-space: pre-line' : ''">
    {{ event.comments?.length > 0 ? textExpand ? event.comments : (event.comments | truncate:120) : '-'  }}
  </div>
</div>


<!-- Employer -->
<div *ngIf="this.eventDisplayType === this.eventDisplayTypeEnum.employer" class="event" [ngClass]="{'highlight' : this.highlight }">
  <header style="font-weight: 450; display: flex; flex-direction: row; gap: 5px">
    <div>
      {{event.type ? event.type.name : '[no type]'}}
    </div>
    <div>-</div>
    <div style="font-weight: 400;">
      <span *ngIf="event.actualDate !== null; else plannedDate">{{ event.actualDate | date: this.dateFormat }}</span>
      <ng-template #plannedDate>
        <span styel="font-style: italic;">Planned</span> {{ event.plannedDate | date: this.dateFormat }}
        <span *ngIf="event.plannedDate === null">[no date]</span>
      </ng-template>
    </div>
    <div *ngIf="!event.isBranch" class="employer-badge" matTooltip="Root Employer Event">R</div>
  </header>

  <div class="event-by body-text">
    {{ event.assignee.name }} - Updated <span matTooltip="By {{event.editedBy }}">{{ event.editedDate | date: this.dateFormat }}</span>
  </div>
  <div class="event-comment body-text" [style]="textExpand ? 'white-space: pre-line' : ''">
    {{ event.notes?.length > 0 ? textExpand ? event.notes : (event.notes | truncate:120) : '-'  }}
  </div>
</div>
