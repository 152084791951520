<section class="events-page-container">
  <h2 class="events-title">Events</h2>

  <mat-card>
    <mat-card-content>

      <!-- Tabs -->
      <div class="tab-container">
        <div *ngFor="let tab of tabs" (click)="changeTab(tab)" [class]="activeTab === tab ? 'active' : ''">
          {{ tab }}
        </div>
      </div>

      <div class="events-container">
        <!-- Filters -->
        <div class="filter-container">
          <h3 class="filter-title">
            Filters
          </h3>
          <ng-select-override
            [items]="this.filterOptions.staff"
            placeholder="Staff"
            [multipleSelect]="true"
            [isLoading]="this.loadingFilterOptions"
            [error]="this.filterError"
            (onChange)="this.filterChange($event, 'staff')"
          >
          </ng-select-override>
          <ng-select-override
            [items]="this.filterOptions.types"
            placeholder="Event Type"
            [multipleSelect]="true"
            [isLoading]="this.loadingFilterOptions"
            [error]="this.filterError"
            (onChange)="this.filterChange($event, 'type')"
          >
          </ng-select-override>
        </div>

        <!-- Table -->
        <div style="flex: 1; display: flex; flex-direction: column; justify-content: space-between;">
          <table mat-table [dataSource]="this.eventsDataSource">
            <ng-container [matColumnDef]="column" *ngFor="let column of eventColumns[this.activeTab]">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="column === 'employerName' ? 'left' : 'inherit'">
                {{
                  column === 'employerName' ? 'Employer'
                  : column === 'plannedDate' ? 'Planned'
                  : column === 'actualDate' ? 'Actual'
                  : this.sharedService.uppercaseWords(column | unCamelCase)
                }}
              </th>

              <td mat-cell *matCellDef="let element" (click)="openEventForm(element.id)">
                {{
                  this.sharedService.isNullOrEmpty(element[column])
                  ? '-'
                  : this.dateColumns.includes(column)
                  ? (element[column] | date:this.dateFormat)
                  : this.sharedService.uppercaseWords(element[column])
                  + (column === 'employerName' && !this.sharedService.isNullOrEmpty(element.branchName) ? ' - ' + this.sharedService.uppercaseWords(element.branchName) : '')
                }}<span *ngIf="column === 'plannedDate' && element.startTime !== null">{{ addTime(element) }}</span>
              </td>

            </ng-container>
            <tr mat-header-row *matHeaderRowDef="eventColumns[this.activeTab]"></tr>
            <tr mat-row *matRowDef="let row; columns: eventColumns[this.activeTab];"></tr>
            <tr class="mat-row" *matNoDataRow>
              <ng-container>
                <td class="mat-cell" colspan="5" style="text-align: center;">
                  {{ this.eventError ? this.dataError : this.loading || !this.runSetUp ? 'Loading...' : 'No Events' }}
                </td>
              </ng-container>
            </tr>

          </table>
          <div>
            <div style="height: 4px; background-color: white;">
              <mat-progress-bar *ngIf="this.loading" mode="indeterminate"></mat-progress-bar>
            </div>
            <mat-paginator
              [pageSize]="this.pageSize"
              [length]="this.totalEvents"
              showFirstLastButtons
              (page)="handlePageEvent($event)"
              aria-label="Select page"
              [disabled]="this.loading"
            >
            </mat-paginator>
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</section>
