import { isArray } from 'underscore';
import { Component, Input, OnInit, ViewChild, NgZone, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AddEmployerEventComponent } from './add-employer-event/add-employer-event.component';
import moment from 'moment';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EmployerEventsService } from './employer-events.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlacementTypeVariant } from 'src/app/shared/enums/placement-type-variant';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-employer-events',
  templateUrl: './employer-events.component.html',
  styleUrls: ['./employer-events.component.scss']
})
export class EmployerEventsComponent implements OnInit {
  @Input() placementType;
  @Input() placementId;
  @Input() placementName;
  @Input() associatedPlacements;
  eventsFormOptions: UntypedFormGroup;
  options: any;
  events: any;
  branches: any[];
  basics: any;
  uniqueEventTypes = [];
  uniqueStaff = [];
  filteredEvents = [];
  startDate: Date;
  endDate: Date;
  @ViewChild("dateRange") dateRangePicker;
  dateRangeSelected: { startDate: moment.Moment, endDate: moment.Moment };
  staffId: number;

  dateFormat = DATE_FORMAT;

  constructor(private fb: UntypedFormBuilder, private ngZone: NgZone,
    private cdr: ChangeDetectorRef, private snackBar: MatSnackBar, private route: ActivatedRoute, private dialog: MatDialog, private employerEventsService: EmployerEventsService) { }

  ngOnInit(): void {
    this.buildForm();
    this.getEmployerEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.firstChange && changes.placementType && changes.placementId) {
      this.getEmployerEvents(PlacementTypeVariant[changes.placementType.currentValue], changes.placementId.currentValue)
    }
  }

  getEmployerEvents(type = PlacementTypeVariant[this.placementType], id = this.placementId) {
    this.employerEventsService.getEmployerEvents(type, id)
      .subscribe(response => {
        this.ngZone.run(() => {
          this.events = response;
          //console.log(response);
          this.filteredEvents = response;

          this.uniqueEventTypes = Array.from(new Set(this.events.map(ev => ev.eventType))).sort();
          this.uniqueEventTypes.unshift("All Event Types");

          this.uniqueStaff = Array.from(new Set(this.events.map(ev => ev.staff))).sort();
          this.uniqueStaff.unshift("All Staff");

          this.branches = Array.from(new Set(this.events.map(ev => ev.branch != null ? ev.branch : this.placementName))).sort();
          this.branches.unshift("All Branches");
          this.cdr.detectChanges();
        });
      })
    // get event id.
    // this.route.data.forEach(
    //   data => {
    //     console.log('events: learner', data.learner);
    //     this.events = data.learner.events.sort((a,b) => moment(b.eventDate).unix() - moment(a.eventDate).unix());
    //     this.basics = data.learner.learnerBasics;
    // })
  }


  openForEditById(eventIdToLoad){
    var eventToLoad = this.events.filter(({ eventId }) => eventId == eventIdToLoad)[0];
    this.openForEdit(eventToLoad);
  }

  openForEdit(event) {
    console.log('event to load', event);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '70%';
    dialogConfig.minHeight = '95%';

    dialogConfig.data = {
      isEmployerLevel: this.placementType == 1,
      isEmployerLevelSelected: this.placementType == 1 && !event.branch,
      forBranch: this.placementType == 1 ? event.branch : null,
      placementName: this.placementName,
      associatedPlacements: this.associatedPlacements,
      eventType: event.eventType,
      notes: event.notes,
      eventDate: event.eventDate,
      plannedEventDate: event.plannedEventDate,
      actualEventDate: event.actualEventDate,
      staffId: event.staffId,
      isEdit: true,
      eventId: event.eventId
    };

    //forBranch

    const dialogRef = this.dialog.open(AddEmployerEventComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData && formData[0] != 'delete') {
        var postData = formData.formValue;
        postData.eventDate = postData.eventDate == null ? null : moment(postData.eventDate).format('YYYY-MM-DD');
        postData.plannedEventDate = postData.plannedEventDate == null ? null : moment(postData.plannedEventDate).format('YYYY-MM-DD');
        postData.actualEventDate = postData.actualEventDate == null ? null : moment(postData.actualEventDate).format('YYYY-MM-DD');
        this.employerEventsService.UpdateEmployerEvent(formData.eventId, this.placementId, this.placementType, postData)
          .subscribe(response => {
            if (response == true) {
              this.getEmployerEvents();
            }
          });
      }
      if (formData && formData[0] == 'delete') {
        this.employerEventsService.deleteEmployerEvent(Number(formData[1])).subscribe(response => {
          if (response.active == 0) {
          this.getEmployerEvents();
          }
        });
      }
    });
  }

  addEventForm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '70%'
    dialogConfig.minHeight = '95%';

    dialogConfig.data = {
      isEmployerLevel: this.placementType == 1,
      isEmployerLevelSelected: this.placementType == 1,
      placementName: this.placementName,
      associatedPlacements: this.associatedPlacements,
      isEdit: false
    }

    const dialogRef = this.dialog.open(AddEmployerEventComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData && !formData.isEdit) {
        var postData = formData.formValue;
        postData.eventDate = postData.eventDate == null ? null : moment(postData.eventDate).format('YYYY-MM-DD');
        postData.plannedEventDate = postData.plannedEventDate == null ? null : moment(postData.plannedEventDate).format('YYYY-MM-DD');
        postData.actualEventDate = postData.actualEventDate == null ? null : moment(postData.actualEventDate).format('YYYY-MM-DD');
        this.employerEventsService.createNewEmployerEvent(this.placementId, this.placementType, postData)
          .subscribe(response => {
            const snackBarMessage = "Employer event added."
            this.snackBar.open(snackBarMessage, 'Close', { duration: 5000 });
            if (Array.isArray(response)) {
              this.ngZone.run(() => {
                response.forEach(element => {
                  this.updateLocalEvents(element);
                  this.cdr.detectChanges();
                });
              });
            }
            else {
              this.ngZone.run(() => {
                this.updateLocalEvents(response);
                this.cdr.detectChanges();
              });
            }
          })
      }
    });
  }

  updateLocalEvents(element: any) {
    this.events.unshift(element);
    //this.filteredEvents.unshift(element);
    if (this.uniqueEventTypes.indexOf(element.eventType) == -1) {
      this.uniqueEventTypes.unshift(element.eventType);
    }
    if (this.uniqueStaff.indexOf(element.staff) == -1) {
      this.uniqueStaff.unshift(element.staff);
    }
    if (this.branches.indexOf(element.branch) == -1) {
      this.branches.unshift(element.branch);
    }
  }

  resetForm() {
    this.buildForm();
    this.filteredEvents = this.events;
    this.clear();
  }

  clear() {
    console.log("reset this", this.dateRangePicker);

    this.dateRangePicker.startDate = moment().startOf('day');
    this.dateRangePicker.endDate = moment().endOf('day');
    this.dateRangePicker.nativeElement.form[0].value = '';

  }

  buildForm() {
    this.eventsFormOptions = this.fb.group({
      eventTypeSelect: null,
      eventStaffSelect: null,
      branches: null
    });
  }

  dateRangeChange(event: any) {
    let startDate: moment.Moment = event.startDate;
    let endDate: moment.Moment = event.endDate;
    console.log(event)

    if (startDate && endDate) {
      this.startDate = startDate.toDate();
      this.endDate = endDate.toDate();
      console.log(this.startDate);
      console.log(this.endDate);
      this.filterEventsByDate(this.startDate, this.endDate)
    }
  }

  // branchesChanged(){
  //   this.filteredEvents = this.events;
  //   const branches = this.eventsFormOptions.value.branches;
  //   console.log(branches)
  //   if(branches.length > 0){
  //     this.filteredEvents = this.filteredEvents.filter(({ branch }) => branches.includes(branch));
  //   }

  // }

  eventTypeCleared() {
    this.eventsFormOptions.get("eventTypeSelect").setValue("All Event Types");
    this.getFilteredEvents("type");
  }

  staffSelectCleared() {
    this.eventsFormOptions.get("eventStaffSelect").setValue("All Staff");
    this.getFilteredEvents("staff");
  }

  getFilteredEvents(ctrl: string) {
    this.filteredEvents = this.events;
    const valType = this.eventsFormOptions.controls.eventTypeSelect.value;
    const valStaff = this.eventsFormOptions.controls.eventStaffSelect.value;
    var valBranches = this.eventsFormOptions.value.branches;

    if (valBranches != null && valBranches.includes(this.placementName)) {
      valBranches.push(null)
    }

    if (valType == "All Event Types" || valStaff == "All Staff" || (valBranches != null && valBranches.includes("All Branches"))) {
      this.filteredEvents = this.events
    }
    // if any specifies all, then get all

    // if filtering on type, filter on type. start with the lot as it's the first one.
    if (ctrl == 'type' && valType != "All Event Types") {
      this.filteredEvents = this.events.filter(({ eventType }) => eventType.includes(valType));
    }
    // else if filtering on branches, filter on branches.
    else if (ctrl == 'branches' && valBranches.length > 0 && !valBranches.includes("All Branches")) {
      this.filteredEvents = this.filteredEvents.filter(({ branch }) => valBranches.includes(branch));
    }
    // esle if filtering on staff, filter on staff
    else if (ctrl == 'staff' && valStaff && valStaff != "All Staff") {
      this.filteredEvents = this.filteredEvents.filter(({ staff }) => staff.includes(valStaff));
    }


    // check if there is also a selected staff
    if (ctrl != 'staff' && valStaff && valStaff != "All Staff") {

      this.filteredEvents = this.filteredEvents.filter(({ staff }) => staff.includes(valStaff));
    }

    // check if there is also a selected type
    if (ctrl != 'type' && valType && valType != "All Event Types") {
      this.filteredEvents = this.filteredEvents.filter(({ eventType }) => eventType.includes(valType));
    }

    if (ctrl != 'branches' && valBranches && !valBranches.includes("All Branches")) {
      this.filteredEvents = this.filteredEvents.filter(({ branch }) => valBranches.includes(branch));
    }

    // check if there is also a selected date
    if (this.startDate && this.endDate) { this.filterEventsByDate(this.startDate, this.endDate) }
    return this.filteredEvents;
  }

  // to test
  filterEventsByDate(sdt, edt) {
    if (this.eventsFormOptions.controls.eventStaffSelect.value || this.eventsFormOptions.controls.eventTypeSelect.value) {
      this.filteredEvents = this.filteredEvents.filter(
        m => moment(m.eventDate).toDate() >= sdt && moment(m.eventDate).toDate() <= edt
      )
    } else {
      this.filteredEvents = this.events.filter(
        m => moment(m.eventDate).toDate() >= sdt && moment(m.eventDate).toDate() <= edt
      )
    }
    return this.filteredEvents
  }
}
