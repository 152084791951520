import { ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expiration-notifications',
  templateUrl: './expiration-notifications.component.html',
  styleUrls: ['./expiration-notifications.component.scss']
})
export class ExpirationNotificationsComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  constructor(private router: Router) { }

  dataSource: any;
  displayedColumns: string[] = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
  }

  setCols(data) {
    if (data && data.length > 0) {
    this.dataSource = new MatTableDataSource(data);
    let cols = Object.keys(data[0]);
    for(let col in cols) {
      if (cols[col] != 'vemployerid')
      this.displayedColumns.push(cols[col]);
      };
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

unCamelCase (str: string){
  return str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // uppercase the first character
      .replace(/^./, function(str){ return str.toUpperCase(); })
  }


  loadEmployer(emp) {
    let empId = this.dataSource._data._value.filter(a=> a.name == emp);
    this.router.navigate([`/employer-old/dashboard/${empId[0].vemployerid}`]);

  }

}
