<div style="display: flex; flex-direction: row; gap: 10px; align-items: baseline;">
  <div style="display: flex">
    <button class="icon-button" (click)="this.navigateBack()" style="width: 35px; height: auto; margin-left: 20px;">
      <mat-icon class="back-icon icons">
        chevron_left
      </mat-icon>
    </button>

    <div class="summary-bar-title" [style.font-size]="'30px'">
      {{learnerName}}
    </div>

    <button class="icon-button" [disabled]="this.waitingForFavoriteToggle" (click)="toggleFavorite()">
      <mat-icon class="{{this.isFavorite? 'bookmark-icon-solid icons':'bookmark-icon-outline icons'}}"
        matTooltip="{{this.isFavorite? 'Remove Bookmark': 'Add Bookmark'}}">
        {{ this.isFavorite ? 'bookmark' : 'bookmark_outline'}}
      </mat-icon>
    </button>

    <div *ngIf="welsh" class="cym" [style.margin-left]="'5px'" title="This learner speaks Welsh">
      <img src="assets/profiles/cymraeg.svg" width="20px" alt="welsh learner" />
    </div>
  </div>

  <div class="learner-info-container">
    <div class="learner-info hide-small-screen">
      <img src="../../assets/profiles/pie-chart-filled.svg" style="padding-right: 5px; width: 1em" alt="Sector" />
      <span>{{sector}}</span>
    </div>
    <div class="learner-info hide-small-screen">
      <img src="../../assets/images/icon-route-new.png" style="padding-right: 5px; width: 1em" alt="Route" />
      <span *ngIf="scheme">{{scheme}}</span>
      <span *ngIf="!scheme">{{programme}}</span>
      <span *ngIf="level">&nbsp;Level {{level}}</span>
    </div>

    <div *ngIf="potStatus?.length > 0" class="learner-info ">
      <img src="../../assets/images/icon-status.png" style="padding-right: 5px; width: 1em" alt="Pot Status" />
      <span>{{potStatus}}</span>
    </div>

  </div>
</div>
