<div *ngIf="hasActiveLearnerFilter" style="margin: 0 0 15px 5px;">
  <mat-checkbox style="margin-right: 25px;" [(ngModel)]="onlyActiveLearners" labelPosition="before"
    (change)="toggleOnlyActiveSearch()">Show Only Active
    Learners</mat-checkbox>
</div>

<ng-select [multiple]="multiSelectIsEnabled" appearance="outline" style="margin-right: 20px;"
  (change)="setFilter(selectedContract, 'contractId')" class="filter-select" #select [items]="contractOptions"
  bindLabel="contract" placeholder="Contract" [(ngModel)]="selectedContract" (clear)="contractSelectCleared()"
  [clearOnBackspace]="false" [closeOnSelect]="false">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2">
      <span class="ng-value-label">{{item.contract}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index.contractId}}" type="checkbox" [checked]="item$.selected" /> {{item.contract}}
  </ng-template>
</ng-select>

<ng-select [multiple]="multiSelectIsEnabled" appearance="outline" style="margin-right: 20px;"
  (change)="setFilter(selectedSubcontractor, 'subcontractorId')" class="filter-select" #select
  [items]="subcontractorOptions" bindLabel="subcontractor" placeholder="Subcontractor"
  [(ngModel)]="selectedSubcontractor" (clear)="subcontractorSelectCleared()" [clearOnBackspace]="false"
  [closeOnSelect]="false">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2">
      <span class="ng-value-label">{{item.subcontractor}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index.subContractorId}}" type="checkbox" [checked]="item$.selected" /> {{item.subcontractor}}
  </ng-template>
</ng-select>

<ng-select [multiple]="multiSelectIsEnabled" appearance="outline" style="margin-right: 20px;"
  (change)="setFilter(selectedProgrammeType, 'programmeTypeId')" class="filter-select" #select
  [items]="programmeTypeOptions" bindLabel="programmeType" placeholder="Programme Type"
  [(ngModel)]="selectedProgrammeType" (clear)="programmeTypeSelectCleared()" [clearOnBackspace]="false"
  [closeOnSelect]="false">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2">
      <span class="ng-value-label">{{item.programmeType}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index.programmeTypeId}}" type="checkbox" [checked]="item$.selected" /> {{item.programmeType}}
  </ng-template>
</ng-select>

<ng-select [multiple]="multiSelectIsEnabled" appearance="outline" style="margin-right: 20px;"
  (change)="setFilter(selectedScheme, 'schemeId')" class="filter-select" #select [items]="schemeOptions"
  bindLabel="scheme" placeholder="Scheme" [(ngModel)]="selectedScheme" (clear)="schemeSelectCleared()"
  [clearOnBackspace]="false" [closeOnSelect]="false">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2">
      <span class="ng-value-label">{{item.scheme}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index.schemeId}}" type="checkbox" [checked]="item$.selected" /> {{item.scheme}}
  </ng-template>
</ng-select>

<ng-select [multiple]="multiSelectIsEnabled" appearance="outline" style="margin-right: 20px;"
  (change)="setFilter(selectedSector, 'sectorId')" class="filter-select" #select [items]="sectorOptions"
  bindLabel="sector" placeholder="Sector" [(ngModel)]="selectedSector" (clear)="sectorSelectCleared()"
  [clearOnBackspace]="false" [closeOnSelect]="false">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2">
      <span class="ng-value-label">{{item.sector}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index.sectorId}}" type="checkbox" [checked]="item$.selected" /> {{item.sector}}
  </ng-template>
</ng-select>

<ng-select [multiple]="multiSelectIsEnabled" appearance="outline" style="margin-right: 20px;"
  (change)="setFilter(selectedCentre, 'centreId')" class="filter-select" #select [items]="centreOptions"
  bindLabel="centre" placeholder="Centre" [(ngModel)]="selectedCentre" (clear)="centreSelectCleared()"
  [clearOnBackspace]="false" [closeOnSelect]="false">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2">
      <span class="ng-value-label">{{item.centre}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index.centreId}}" type="checkbox" [checked]="item$.selected" /> {{item.centre}}
  </ng-template>
</ng-select>

<ng-select [multiple]="multiSelectIsEnabled" appearance="outline" style="margin-right: 20px;"
  (change)="setFilter(selectedAssessor, 'assessorId')" class="filter-select" #select [items]="assessorOptions"
  bindLabel="assessor" placeholder="Assessor" [(ngModel)]="selectedAssessor" (clear)="assessorSelectCleared()"
  [clearOnBackspace]="false" [closeOnSelect]="false">
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngFor="let item of items | slice:0:2">
      <span class="ng-value-label">{{item.assessor}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 2">
      <span class="ng-value-label">{{items.length - 2}} more...</span>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <input id="item-{{index.assessorId}}" type="checkbox" [checked]="item$.selected" /> {{item.assessor}}
  </ng-template>
</ng-select>