export const environment = {
  production: true,
  API_URL: 'https://api-cloud-vision.acttraining.org.uk/api/',
  statusPage: 'https://status.acttraining.org.uk/',
  tenantId: '6da945da-348b-4a3b-8ab2-f42a0e375185',
  clientId: '83363622-71e2-4e17-b1ae-58343ca5bdea',
  redirectUri: 'https://cloud-vision.acttraining.org.uk/redirect',
  logoutRedirectUri: `https://cloud-vision.acttraining.org.uk/log-out`,
  reportMaxPageSize: 999999,
  websiteURL: 'https://cloud-vision.acttraining.org.uk',
  dsn: 'https://cadd341fecba41de997b44ccd0825ad5@o4505562443612160.ingest.sentry.io/4505566556717056',
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1
};
