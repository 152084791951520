import { SendMessageService } from './send-message.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-send-message-form',
  templateUrl: './send-message-form.component.html',
  styleUrls: ['./send-message-form.component.scss']
})
export class SendMessageFormComponent implements OnInit {
  public Editor = ClassicEditor;

  editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic']
  }

  sendMessageForm: UntypedFormGroup;

  title: string;
  extras: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SendMessageFormComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private sendMessageService: SendMessageService
  ) { this.buildForm(); }

  ngOnInit(): void {
    this.title = 'Send message';
    if (this.data.recipientName) this.title += ` to ${this.data.recipientName}`;
    if (this.data.extras) {this.extras = this.data.extras}
  }

  buildForm() {
    this.sendMessageForm = this.fb.group({
      subject: '',
      message: '',
    });
  }

  onCancel(): void {
    // TODO: Find a way to allow user to keep editing the form if they wish, without using the ugly 'confirm' dialogue.
    if (this.sendMessageForm.dirty) {
      this.snackBar.open("Closing this form will lose your unsaved message", "Close", {
        duration: 4000,
      });
    }
    // if (this.sendMessageForm.dirty) {
    //   let answer = confirm("Are you sure you want to close without sending your message?")
    //   if (answer == true) {
    //     this.dialogRef.close();
    //   }
    // }
    this.dialogRef.close();
  }

  sendMessage() {
    console.log('Send message');

    if (this.data.recipientEmail && this.data.senderEmail) {
      let data = {
        recipientName: this.data.recipientName,
        recipientEmail: this.data.recipientEmail,
        senderName: this.data.senderName,
        senderEmail: this.data.senderEmail,
        subject: this.sendMessageForm.value.subject,
        body: this.sendMessageForm.value.message + this.extras
      };

      let snackBarMessage = !this.data.customMessage ?  `An email has been sent to
      ${this.data.recipientName} (${this.data.recipientEmail})
      from ${this.data.senderEmail}` : this.data.customMessage

      this.sendMessageService.sendEmail(data).subscribe(
        response => {
          if (response == 'OK') {
            this.snackBar.open(snackBarMessage,
              'Close', { duration: 5000 });
          }
        }
      )
    } else {
      this.snackBar.open(`A valid email address does not exist for ${this.data.recipientName}.
      No email has been sent.`, 'Close', { duration: 6000 });
    }

    this.dialogRef.close();
  }

}
