import { Injectable } from '@angular/core';
import moment from 'moment';
import { ReportType } from './basic-report/basic-report.component';

class DateToLoad {
  startDate: any;
  endDate: any;
  dateTypeFilter: string;
  dateRangeSelected: string;
}

class TypeAndColumns {
  isShowSummery: boolean;
  selectedColumns: string[]
  groupByColumn: string;
}

class BasicTypeAndColumns {
  reportType: ReportType;
  defaultDisplayColumnsType: string;
  selectedColumns: string[]
  groupByColumn: string;
  isShowSummery: boolean; // This is for backward compatibility only
}

@Injectable({
  providedIn: 'root'
})
export class ReportStoredFilterHelperService {

  constructor() { }

  getDateFilterAsString(startDate, endDate, dateRangeSelected, dateFilterTypeSelected): string {
    var dateFilter = '';

    if (startDate || endDate) {
      if (!dateRangeSelected) {
        dateFilter = dateFilterTypeSelected + "::" + moment(startDate).format("YYYY,MM,DD") + "-" + moment(endDate).format("YYYY,MM,DD") + ";";
      }
      else {
        dateFilter = dateFilterTypeSelected + "::" + dateRangeSelected + ";";
      }
    }
    return dateFilter;
  }

  getBasicDateFilterAsString(startDate, endDate, dateFilterTypeSelected): string {
    var dateFilter = '';
    dateFilter = `dateTypeSelected::${dateFilterTypeSelected};`;
    if (startDate || endDate) {
        dateFilter += `dateSelected::${moment(startDate).format("YYYY,MM,DD")}-${moment(endDate).format("YYYY,MM,DD")};`;
    }
    return dateFilter;
  }


  getAdditionalFilters(additionalFilters, additionalFilterGroup): string {
    var additionalFiltersString = "";
    additionalFilters.forEach(additionalFilter => {
      var formValue = additionalFilterGroup.get(additionalFilter.Type).value;
      if (formValue && formValue != "") {
        additionalFiltersString += additionalFilter.Type + "::" + formValue + ";";
      }
    });
    return additionalFiltersString;
  }

  getBasicReportTypeAndColumns(reportType: ReportType, selectedColumns, groupByColumn, selectedDefaultDisplayColumnsType: string): string {
    var typeAndColumnFilter = "";
    typeAndColumnFilter += `reportType::${reportType};`;
    typeAndColumnFilter += `defaultDisplayColumnsType::${selectedDefaultDisplayColumnsType};`;
    if(reportType !== ReportType.table)
    typeAndColumnFilter += this.getGroupedColoumns(groupByColumn);
    else {
      typeAndColumnFilter += this.getSelectedColoumns(selectedColumns);
    }
    return typeAndColumnFilter;
  }

  // getReportTypeAndColumns(showSummary, selectedColumns, groupByColumn): string {
  //   var typeAndColumnFilter = "";
  //   if (showSummary) {
  //     typeAndColumnFilter += "type::summary;"
  //     typeAndColumnFilter += this.getGroupedColoumns(groupByColumn);
  //   }
  //   else {
  //     typeAndColumnFilter += "type::standard;"
  //     typeAndColumnFilter += this.getSelectedColoumns(selectedColumns);
  //   }
  //   return typeAndColumnFilter;
  // }

  private getSelectedColoumns(selectedColumns) {
    var selectedColumns = selectedColumns.value;
    return "selectedColumns::" + selectedColumns + ";"
  }

  private getGroupedColoumns(groupByColumn) {
    return "groupByColumn::" + groupByColumn + ";"
  }


  getDateToLoad(filters, ranges): DateToLoad{
    const dateToLoad : DateToLoad = new DateToLoad();
    filters.forEach(filter => {
      var filterSplit = filter.split("::");

      const filterType = filterSplit[0];
      var dateTypes = ['dateSubmitted', 'startDate', 'terminationDate', 'expectedEndDate', 'lastReview',
                      'nextReview', 'datePlanned', 'dateSubmitted', 'createdDate', 'certReceived',
                      'ialpVersionCompDate', 'twentyEightDays', 'regDate', 'psmDate', 'noficiationSent'];
      if(dateTypes.includes(filterType)){
        const datesArray = filterSplit[1].split("-");
        dateToLoad.dateTypeFilter = filterType;
        dateToLoad.startDate = moment(datesArray[0]).startOf('day');
        dateToLoad.endDate = moment(datesArray[1]).endOf('day');
      } else {
        // This is for the new saved date data structure, so no longer have to use and update the hardcoded array above
        if(filterType === 'dateTypeSelected') {
          dateToLoad.dateTypeFilter = filterSplit[1];
        } else if(filterType === 'dateSelected') {
          const datesArray = filterSplit[1].split("-");
          dateToLoad.startDate = moment(datesArray[0]).startOf('day');
          dateToLoad.endDate = moment(datesArray[1]).endOf('day');
        }
      }

    });
    return dateToLoad;
  }



  loadBasicTypeAndColumnFilters(filters): BasicTypeAndColumns {
    var typeAndColumns = new BasicTypeAndColumns();
      const filterSplit = filters[0].split("::");
      const reportKey = filterSplit[0];

      // for backwards compatability
      if(reportKey === "type") {
        filterSplit[1] = "standard" ? ReportType.table : ReportType.summary;
        typeAndColumns.defaultDisplayColumnsType = 'default';
      }

      typeAndColumns.reportType = filterSplit[1];

      if (typeAndColumns.reportType === "table") {
        filters.forEach(filterForColumns => {
          const filterForColumnsSplit = filterForColumns.split("::");
          const filterForColumnsType = filterForColumnsSplit[0];

          if (filterForColumnsType === "selectedColumns") {
            typeAndColumns.selectedColumns = filterForColumnsSplit[1].split(",");
          }

          if (filterForColumnsType === "defaultDisplayColumnsType") {
            typeAndColumns.defaultDisplayColumnsType = filterForColumnsSplit[1];
          }
        });
        typeAndColumns.isShowSummery = false; // For Backwards compatability
      }
      else {
        filters.forEach(filterForColumns => {
          const filterForColumnsSplit = filterForColumns.split("::");
          const filterForColumnsType = filterForColumnsSplit[0];

          if (filterForColumnsType === "groupByColumn") {
            typeAndColumns.groupByColumn = filterForColumnsSplit[1]
          }
        });
        typeAndColumns.isShowSummery = true; // For Backwards compatability
      }
    return typeAndColumns;
  }

  // loadTypeAndColumnFilters(filters): TypeAndColumns {
  //   let typeAndColumns = new TypeAndColumns();
  //   filters.forEach(filter => {
  //     const filterSplit = filter.split("::");
  //     const filterType = filterSplit[0];

  //     if (filterType == "type") {
  //       if (filterSplit[1] == "standard") {
  //         typeAndColumns.isShowSummery = false;

  //         filters.forEach(filterForColumns => {
  //           const filterForColumnsSplit = filterForColumns.split("::");
  //           const filterForColumnsType = filterForColumnsSplit[0];

  //           if (filterForColumnsType == "selectedColumns") {
  //             typeAndColumns.selectedColumns = filterForColumnsSplit[1].split(",");
  //           }
  //         });
  //       } else {
  //         typeAndColumns.isShowSummery = true;

  //         filters.forEach(filterForColumns => {
  //           const filterForColumnsSplit = filterForColumns.split("::");
  //           const filterForColumnsType = filterForColumnsSplit[0];

  //           if (filterForColumnsType == "groupByColumn") {
  //             console.log( filterForColumnsSplit[1])
  //             typeAndColumns.groupByColumn = filterForColumnsSplit[1]
  //           }
  //         });
  //       }
  //     }
  //   });
  //   return typeAndColumns;
  // }


}
