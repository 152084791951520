<div class="form-container">

  <form novalidate autocomplete="off" (ngSubmit)="executeSearch()" [formGroup]="searchForm" class="search-container">

    <div *ngIf="sideBarOpen" class="sidebar">


      <mat-toolbar style="height: calc(100% - 28px); overflow-y: auto;">

        <mat-toolbar-row style="height: 50px">
          <h2>Customise Results &nbsp;&nbsp;<img src="../../assets/images/icon-filter.png"
              style="background-color: #483B8D;"></h2>
        </mat-toolbar-row>

        <mat-toolbar-row style="height: 30px;">
          <button type="button" mat-button (click)="clearAllLocalFilters()">Clear all filters</button>
        </mat-toolbar-row>

        <mat-toolbar-row style="height: 48px;">
          <button type="button" mat-flat-button class="search-button"
            [ngClass]="{'search-button-primary': isActiveLearnersOnly, 'search-button-secondary': !isActiveLearnersOnly}"
            (click)="activeOnlySearch()">
            Active Learner &nbsp;&nbsp;&nbsp;<img src="../../assets/images/icon-active_learner.png">
          </button>
          <!-- <mat-icon matSuffix class="active-learner-select">close</mat-icon> -->
        </mat-toolbar-row>

        <mat-toolbar-row style="height: 48px;">
          <p>Unique Identifier</p>
        </mat-toolbar-row>

        <mat-toolbar-row style="height: 16px; margin-bottom: 32px;">
          <mat-form-field appearance="fill" class="no-underline filter-input" style="width: 200px !important;"
            [ngStyle]="filterStyle">
            <mat-label class="filter-input-label">Try NI, LN02, ULN, TraineeID</mat-label>
            <input matInput formControlName="uniqueIdentifierFilter" class="filter-input-text"
              [(ngModel)]="selectedUniqueIdentifier"
              (focusout)="setLocalFilter(selectedUniqueIdentifier, 'uniqueIdentifier')"
              (keydown)="setUniqueIdentifierFilter($event)">
            <mat-icon matSuffix (click)="addFilter(null, true)" class="ui-icon icon--click-hover">search</mat-icon>
          </mat-form-field>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="learnerNameOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedLearnerName, 'fullName')" class="filter-select"
            [items]="learnerNameOptions" bindLabel="fullName" placeholder="Learner name"
            [(ngModel)]="selectedLearnerName" (clear)="clearSelected('fullName')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="assessorOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedAssessor, 'assessor')" class="filter-select" [items]="assessorOptions"
            bindLabel="assessor" placeholder="Assessor" [(ngModel)]="selectedAssessor"
            (clear)="clearSelected('assessor')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="genderOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedGender, 'gender')" class="filter-select" [items]="genderOptions"
            bindLabel="gender" placeholder="Gender" [(ngModel)]="selectedGender" (clear)="clearSelected('gender')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="postCodeOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedPostCode, 'postCode')" class="filter-select" [items]="postCodeOptions"
            bindLabel="postCode" placeholder="Postcode" [(ngModel)]="selectedPostCode"
            (clear)="clearSelected('postCode')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="postTownOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedPostTown, 'postTown')" class="filter-select" [items]="postTownOptions"
            bindLabel="postTown" placeholder="Post town" [(ngModel)]="selectedPostTown"
            (clear)="clearSelected('postTown')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="sectorOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedSector, 'sector')" class="filter-select" [items]="sectorOptions"
            bindLabel="sector" placeholder="Sector" [(ngModel)]="selectedSector" (clear)="clearSelected('sector')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="departmentOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedDepartment, 'department')" class="filter-select"
            [items]="departmentOptions" bindLabel="department" placeholder="Department" [(ngModel)]="selectedDepartment"
            (clear)="clearSelected('department')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="employerBranchPlacementOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedEmployerBranchPlacement, 'employerBranchPlacement')" class="filter-select"
            [items]="employerBranchPlacementOptions" bindLabel="employerBranchPlacement"
            placeholder="Employer Branch Placement" [(ngModel)]="selectedEmployerBranchPlacement"
            (clear)="clearSelected('employerBranchPlacement')">
          </ng-select>
        </mat-toolbar-row>

        <mat-toolbar-row>
          <ng-select formControlName="schemeOptions" appearance="outline" style="margin-right: 20px;"
            (change)="setLocalFilter(selectedScheme, 'scheme')" class="filter-select" [items]="schemeOptions"
            bindLabel="scheme" placeholder="Scheme" [(ngModel)]="selectedScheme" (clear)="clearSelected('scheme')">
          </ng-select>
        </mat-toolbar-row>

      </mat-toolbar>

    </div> <!-- sidebar -->


    <div style="float: left; width: 100%; padding-top: 60px;">
      <div class="controls-container">

        <div *ngIf="!sideBarOpen" class="titlebox">

          <h1>Learner Search</h1>
          <h3>Who can we help you find today?</h3>

          <div style="padding-top: 32px;">
            <mat-form-field *ngIf="isLocalSearch() == false" style="width: calc(8% + 140px);" appearance="fill"
              class="no-underline search-type-select">
              <mat-select style="padding-left: 10px;" formControlName="searchType" [ngModel]="selectedSearchType.value"
                (selectionChange)="onSelectedSearchType($event)">
                <mat-option style="color: #262633;" #searchTypeText *ngFor="let searchType of searchTypes"
                  [value]="searchType.value">
                  {{searchType.title}}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix>arrow_drop_down</mat-icon>
            </mat-form-field>

            <mat-form-field *ngIf="isLocalSearch() == false && selectedSearchType.value != 'DOB'"
              style="width:calc(80% - 140px); min-width: 250px; flex: 0.75;" appearance="fill"
              class="no-underline search-type-external">
              <input matInput placeholder="Search" value="" formControlName="searchText"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addFilter($event)" [matAutocomplete]="auto"
                (focus)="onFocus($event)" (focusout)="lostFocus($event)">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.term">
                  {{option.term}}
                </mat-option>
              </mat-autocomplete>
              <mat-icon matSuffix (click)="addFilter(null, true)" class="icon--click-hover">search</mat-icon>
            </mat-form-field>

            <mat-form-field *ngIf="isLocalSearch() == false && selectedSearchType.value == 'DOB'" style="width:calc(80% - 640px); min-width: 250px;
                      flex: 0.75;" class="no-underline dob-search" appearance="fill">
              <input matInput readonly [matDatepicker]="picker" formControlName="searchDateOfBirth"
                style="cursor: default;" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="addFilter($event)">
              <mat-datepicker-toggle matSuffix [for]="picker" style="float: left;"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-icon matSuffix (click)="addFilter(null, true)" class="icon--click-hover" style="margin-top: 8px">
                search
              </mat-icon>
            </mat-form-field>

            <div style="width: 100%; display: flex;">
              <div>
                <mat-checkbox *ngIf="!sideBarOpen" formControlName="activeLearnersOnly" style="margin-left: 20px" class="active-only"> Only
                  show active learners </mat-checkbox>
              </div>
              <!-- <div>
                <mat-checkbox *ngIf="userInfo.defaultPage=='systems'" formControlName="useRandP" style="margin-left: 20px" class="active-only">
                  Use 'Roles And Permissions' in search </mat-checkbox>
              </div> -->

              <mat-chip-list #chipList aria-label="Filter selection" class="chiplist">
                <mat-chip matChipRemove style=" border-radius: 5px; background-color: #E0E0E0;"
                  *ngFor="let filter of filtersOrdered()" [class.search-primary]="searchTypeClass(filter) == 'primary'"
                  [class.search-secondary]="searchTypeClass(filter) == 'secondary'" [selectable]="selectable"
                  [removable]="removable" (removed)="removeFilter(filter)">
                  {{filter.value}}
                  <mat-icon matChipRemove *ngIf="removable" class="filterChipRemove">close</mat-icon>
                </mat-chip>
              </mat-chip-list>

              <mat-spinner *ngIf="showSpinner"></mat-spinner>
            </div>

            <div>
              <mat-label class="no-results-message" *ngIf="noResultsMessage != ''">
                {{noResultsMessage}}
              </mat-label>
            </div>
          </div>
        </div>
      </div>

      <div class="results">
        <div fxLayout="row wrap" fxLayoutWrap fxLayoutGap="20px" fxLayoutAlign="left" class="max-width">

          <div *ngIf="isLocalSearch() == true" class="name-header" style="width: 100%;">
            {{externalFilterValue()}}
            <a href="#" id="clear-button" (click)="resetForm()">Clear</a>
          </div>

          <div style="text-align: center; margin: 0px 30px 30px 0px;" *ngFor="let learner of searchResultsFiltered">

            <mat-card (click)="onSelect(learner)" class="search-card custom-tooltip" [matTooltip]="learner.toolTipText"
              matTooltipClass="custom-tooltip" matTooltipPosition="below">

              <mat-toolbar>
                <mat-spinner class="search-spinner" *ngIf="loadingLearner && selectedTraineeId == learner.learnerBasics.traineeId" [diameter]="30"></mat-spinner>

                <mat-toolbar-row class="mat-title" style="margin-bottom: 0; white-space: normal; height: auto;"
                  [ngStyle]="{'font-size' : (learner.learnerBasics.firstName + learner.learnerBasics.lastName).length > 25 ? '16px' : '18px'}"
                >
                  {{learner.learnerBasics.firstName}} {{learner.learnerBasics.lastName}}
                </mat-toolbar-row>

                <mat-toolbar-row class="mat-subtitle">
                  {{learner.sector.sector}}
                </mat-toolbar-row>
                <mat-toolbar-row class="spacer"></mat-toolbar-row>
                <mat-toolbar-row class="mat-info" *ngIf="learner.scheme.scheme == null" style="color: red;">
                  <img src="../../assets/images/icon-assessor.png" style="padding-right: 7px;"> *Scheme missing ({{learner.potDetails.pot}})
                </mat-toolbar-row>
                <mat-toolbar-row class="mat-info" *ngIf="learner.scheme.scheme != null" >
                  <img src="../../assets/images/icon-assessor.png" style="padding-right: 7px;"> {{learner.scheme.scheme}} ({{learner.potDetails.pot}})
                </mat-toolbar-row>
                <mat-toolbar-row class="mat-info">
                  <img src="../../assets/images/icon-assessor.png" style="padding-right: 7px;">
                  {{learner.assessor.assessor}}
                </mat-toolbar-row>
                <mat-toolbar-row class="mat-info">
                  <img src="../../assets/images/icon-date.png" style="padding-right: 7px;">
                  {{learner.potDetails.startDate | date: this.dateFormat}}
                </mat-toolbar-row>

              </mat-toolbar>

            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>
