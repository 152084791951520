
<h1 mat-dialog-title>Discussion Form</h1>
<div mat-dialog-content>

  <form [formGroup]="discussionForm" novalidate>

    <mat-dialog-content class="new-record-form">
      <mat-divider class="iqaformDivider"></mat-divider>

      <ckeditor class="multi-line white" [editor]="Editor" [config]="editorConfig"
        formControlName="discussion"></ckeditor>

    </mat-dialog-content>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Cancel</button>
  <button mat-button cdkFocusInitial (click)="saveAndClose()">Confirm</button>
</div>
