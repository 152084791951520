import { UserService } from '../../core/services/user.service';
import { StaffSearchTableService } from '../../core/database/staff-search-table.service';
import { ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { UserTableService } from '../../core/database/user-table.service';
import { ISearchFilter } from '../../shared/interfaces/search-filter';
import { IGenericTextValuePair } from '../../shared/interfaces/generic-text-value-pair';
import { IUserInfo } from '../../shared/interfaces/user-info';
import { PhoneBookService } from '../phone-book/phone-book.service';
import { IPhoneListRecord } from '../../shared/interfaces/phone-list-record';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PhoneRecordFormComponent } from '../phone-record-form/phone-record-form.component';
import { StaffSearchService } from './staff-search.service';

@Component({
  selector: 'staff-search',
  templateUrl: './staff-search.component.html',
  styleUrls: ['./staff-search.component.scss']
})
export class StaffSearchComponent implements OnInit {
  dataLoaded: boolean = false;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.staffSearchTableService.clear();
  }

  phoneList: IPhoneListRecord[];
  description: string;
  searchResultsFiltered: any[] = [1, 2, 3, 4];
  searchForm: UntypedFormGroup;
  searchTypes: any[];
  selectedSearchType: IGenericTextValuePair;
  refineByKeywordText: string;
  icon: string;
  width: any;
  checkWidth: any;
  userInfo: IUserInfo;
  resetOption: boolean = false;

  // Filters
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  readonly separatorKeysCodes: number[] = [ENTER];
  filters: ISearchFilter[] = [];

  noResultsMessage: string;
  showSpinner: boolean = true;

  displayedColumns: string[] = ['name', 'jobRole', 'company', 'mobile', 'currentLocation', 'status'];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;
  graphToken;

  constructor(
    private fb: UntypedFormBuilder,
    private phoneBookService: PhoneBookService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private userTableService: UserTableService,
    private staffSearchTableService: StaffSearchTableService,
    private userService: UserService,
    private _liveAnnouncer: LiveAnnouncer,
    private staffSearchService: StaffSearchService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.showSpinner = true;
    this.getCurrentUser();

    this.populateSearchTypeDropDownList();
    this.buildForm();
    this.selectedSearchType = { value: 'StaffName', text: 'Staff Name' };
    this.refineByKeywordText = "Search by Name, Team, Job Title or Location?";
    this.icon = "search";
    this.getPhonebook();

  }

  // This is not working?
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    this.pageChange(this.pageEvent);
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  // As we are only updating per page, im going to make it so that you cant sort by current location or status
  // Is there a reason we are not getting the microsoft status for all results at the start?
  public pageChange(event?: PageEvent) {
    var itemsToSkip = 0;
    var maxIndex = 25;

    if (event) {
      itemsToSkip = event.pageIndex * event.pageSize;
      maxIndex = itemsToSkip + event.pageSize;
    }


    var index = 0;
    var microsoftIds = []

    var dataToUpdate = this.dataSource.data

    //Im not sure what this is meant to be doing?
    if (this.sort && this.sort.active) {
      dataToUpdate = this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    }


    dataToUpdate.forEach(result => {
      if (index >= itemsToSkip && index <= maxIndex) {
        var microsoftId = result.microsoftID
        if (microsoftId != null) {
          microsoftIds.push(microsoftId);
        }
        result.status = "-"
      }
      index++;

    })

    index = 0;
    if (microsoftIds.length > 0) {
      this.userService.getPresenceInfoMultipleUsers(this.graphToken, microsoftIds).subscribe(response => {
        dataToUpdate.forEach(result => {
          if (index >= itemsToSkip && index <= maxIndex) {
            var statusMatch = response.value.filter(({ id }) => id == result.microsoftID)
            if (statusMatch.length != 0) {
              var status = statusMatch[0].availability;
              var statusWithSpaces = status.replace(/([A-Z])/g, ' $1').trim()
              result.status = statusWithSpaces
              console.log(result.currentLocation, result.status);
              result.currentLocation = result.currentLocation === 'Signed Out' && result.status !== 'Offline' ? 'Working From Home' : result.currentLocation;
            }
          }
          index++;

        })
        this.cdr.detectChanges()
      })

    }

    return event;
  }

  onChanges(): void {
    this.searchForm.get('searchText').valueChanges.subscribe(val => {
      this.noResultsMessage = '';
    });
  }

  getPhonebook() {
    this.phoneList = [];
    this.phoneBookService.GetPhoneList().subscribe(
      data => {
        data.forEach(element => {
          element.selected = false;
          this.phoneList.push(element);
        });
        this.phoneList = this.phoneListOrdered();
        this.dataSource.data = this.phoneList;
        this.pageChange(null);

        this.getSearchResultsFromLocalDb();

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.showSpinner = false;
        this.dataLoaded = true;
        console.log(this.dataLoaded);
        this.cdr.detectChanges();
      });
  }

  getCurrentUser() {
    this.userTableService.get(1)
      .then(staffResponse => {
        if (!staffResponse || staffResponse == undefined || staffResponse == null) {
          this.router.navigate(['']);
        } else {
          this.userInfo = staffResponse;
          this.userService.getNotifications(staffResponse.staffId).subscribe(
            notificationsResponse => {
              staffResponse.countUnreadNotifications = notificationsResponse['countUnreadNotifications'];
              this.userService.updateUserNotifications(staffResponse);
              this.cdr.detectChanges();
            })
        }
      });
  }

  buildForm() {
    this.searchForm = this.fb.group({
      searchText: null,
      searchFilter: null,
      searchType: null,
    })
  }

  get filterStyle() {
    return this.width;
  }

  get filterStyleTickbox() {
    return this.checkWidth;
  }

  resetForm() {
    this.showSpinner = false;
    this.phoneList = [];
    this.dataSource.data = this.phoneList;
    this.pageChange(null);
    this.searchResultsFiltered = [];
    this.filters = [];
    this.searchForm.reset();
    this.selectedSearchType = { value: 'StaffName', text: 'Staff Name' };
    this.buildForm();
    this.noResultsMessage = '';
    window.location.reload();
    this.staffSearchTableService.clear();
    return false;
  }

  saveSearchResultsToLocalDb() {
    this.staffSearchTableService.clear();
    if (this.filters.length > 0) {
      this.staffSearchTableService.add(this.searchResultsFiltered, 0);
      this.staffSearchTableService.add(this.filters, 1);
    }
  }

  getSearchResultsFromLocalDb() {
    this.staffSearchTableService.getAll().then(
      searchResults => {
        if (searchResults.length) {
          this.searchResultsFiltered = searchResults[0];
          this.filters = searchResults[1];
          if (this.filters.length > 0) {
            this.searchLocal();
            //this.populateSearchResultsFiltered();
          }

          this.onChanges();
        }
        this.cdr.detectChanges();
      }
    )
  }

  onFocus(event) {
    this.refineByKeywordText = "Search by Name, Team, Job Title or Location?"
    this.icon = 'search';
  }

  lostFocus(event) {
    this.refineByKeywordText = "Search by Name, Team, Job Title or Location?";
    this.icon = 'search'
  }

  populateSearchResultsFiltered() {
    if (this.phoneList != undefined && this.phoneList != null) {
      if (this.hasLocalSearchFilter()) {
        this.searchResultsFiltered = this.phoneList.filter(
          element => {
            return element.selected == true;
          }
        )
      } else {
        this.searchResultsFiltered = this.phoneList;
      }
    }
    this.dataSource.data = this.searchResultsFiltered;
    this.pageChange(null);
    this.showSpinner = false;
  }

  addFilter(event: MatChipInputEvent): void {
    const input = event.input;
    let value = event.value;

    // Add filter
    if ((value || '').trim()) {

      // Deal with legit (i.e. first) comma
      const commaPos = value.indexOf(',');

      if (commaPos != -1) {
        const leftPart = value.substring(0, commaPos).trim();
        const rightPart = value.substring(commaPos + 1, value.length).trim();
        value = `${rightPart} ${leftPart}`;
      }

      // deal with any superfluous commas
      value = value.replace(',', ' ').trim();

      const formValues = this.searchForm.value;
      formValues.searchText = value;

      const newFilterEntry = {
        type: 'localFilter',
        value: value
      };

      this.filters.push(newFilterEntry);
      this.staffSearch();
    }

    // Reset input value
    if (input) {
      input.value = '';
    }
  }

  removeFilter(filter: ISearchFilter): void {
    const index = this.filters.indexOf(filter);
    if (index >= 0) {
      this.filters.splice(index, 1);
      this.phoneList.forEach(pl => pl.selected = true);
      this.staffSearch();
      this.populateSearchResultsFiltered();
      this.saveSearchResultsToLocalDb();
    }
  }

  phoneListOrdered(): IPhoneListRecord[] {
    let sorted = this.phoneList.sort(function (a, b) {
      const sortA = a.name.split(' ')[1].toUpperCase();
      const sortB = b.name.split(' ')[1].toUpperCase();
      if (sortA > sortB) {
        return 1;
      }
      if (sortA < sortB) {
        return -1;
      }
      return 0;
    });
    return sorted;
  }

  hasLocalSearchFilter() {
    return this.filters.filter(item => item.type == 'localFilter').length > 0;
  }

  staffSearch() {
    this.noResultsMessage = '';
    this.showSpinner = true;
    this.searchLocal();
  }

  onSelectedSearchType(event: MatSelectChange): void {
    this.selectedSearchType = {
      value: event.value,
      text: event.source.triggerValue
    };
  }

  searchLocal() {
    let phoneList: IPhoneListRecord[];

    if (this.filters.length > 1) {
      phoneList = this.phoneList.filter(element => element.selected);
    } else {
      phoneList = this.phoneList;
    }

    this.filters.forEach(filter => {

      phoneList.forEach(result => {
        if (this.filters.length > 0) {

          let isMatch = false;

          // staff name
          isMatch = this.filterOnName(result.name, filter['value']);
          result.selected = isMatch;

          // company
          if (!isMatch) {
            isMatch = this.filterGeneric(result.company, filter['value']);
            result.selected = isMatch;
          }

          // jobRole
          if (!isMatch) {
            isMatch = this.filterGeneric(result.jobRole, filter['value']);
            result.selected = isMatch;
          }

          // Mobile
          if (!isMatch) {
            isMatch = this.filterGeneric(result.mobile, filter['value']);
            result.selected = isMatch;
          }

          // currentLocation
          if (!isMatch) {
            isMatch = this.filterGeneric(result.currentLocation, filter['value']);
            result.selected = isMatch;
          }
        } else {
          result.selected = true;
        }
      })
      phoneList = phoneList.filter(element => element.selected);
    });

    this.populateSearchResultsFiltered();
    this.saveSearchResultsToLocalDb();
  }

  filterOnName(name: string, filterString: string): boolean {
    let isMatch = false;

    const names = name.split(' ');
    const firstName = names[0];
    const lastName = names.length > 1 ? names[1] : '';


    if (`${firstName} ${lastName}` == filterString) { return true; }

    let filterParts = filterString.split(' ');

    if (firstName && firstName.substr(0, filterParts[0].length).toLowerCase() == filterParts[0].toLowerCase()) {
      isMatch = true;
    }

    if (filterParts.length == 1) {
      if (lastName && lastName.substr(0, filterParts[0].length).toLowerCase() == filterParts[0].toLowerCase()) {
        isMatch = true;
      }
    } else {
      if (lastName && lastName.substr(0, filterParts[1].length).toLowerCase() != filterParts[1].toLowerCase()) {
        isMatch = false;
      }
    }

    return isMatch;
  }

  filterGeneric(name: string, filterString: string): boolean {
    let isMatch = false;

    if (name) {
      if (name == filterString) { return true; }
      const names = name.split(' ');
      let filterParts = filterString.split(' ');

      filterParts.forEach(filterPart => {
        names.forEach(namePart => {
          if (namePart.substr(0, filterPart.length).toLowerCase() == filterPart.toLowerCase()) {
            isMatch = true;
          }
        })
      })
    }

    return isMatch;
  }

  filterOnJobRole(jobRole: string, filterString: string): boolean {
    let isMatch = false;


    return isMatch;
  }

  filterOnCurrentLocation(location: string, filterString: string): boolean {
    let isMatch = false;


    return isMatch;
  }

  populateSearchTypeDropDownList() {
    this.searchTypes = [
      { value: 'StaffName', title: 'Staff Name' },
      { value: 'Company', title: 'Company/Office' },
      { value: 'JobTitle', title: 'Job Title' },
      { value: 'Location', title: 'Current Location' }
    ]
  }

  onSelect(staffPhoneRecord: any) {
    console.log('selected', staffPhoneRecord)
    this.onOpenDialogue(staffPhoneRecord);
  }

  onOpenDialogue(staffPhoneRecord: IPhoneListRecord) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    console.log('about to open dialog')
    if (staffPhoneRecord != null) {
      var staffId = staffPhoneRecord.staffId.toString();
      this.staffSearchService.getStaffPermissions(staffId).subscribe(response => {
        console.log(response)
        dialogConfig.data = {
          staffData: staffPhoneRecord,
          traineePermissions: response,
        },
          dialogConfig.width = '1800px';
        const dialogRef = this.dialog.open(PhoneRecordFormComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(updated => {
          if (updated) {
            this.resetForm();
          }
        });
      })
    }
  }

}
