import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'main-learner-details',
  templateUrl: './main-learner-details.component.html',
  styleUrls: ['./main-learner-details.component.css']
})
export class MainLearnerDetailsComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private sharedFunctions: SharedFunctionsService
    ) { }

  @Output() mainLearnerDetailsUpdate = new EventEmitter();
  @Input() learnerDetails: any;
  @Input() editPermissionLevel: number;
  @Input() show: boolean = false;
  learnerDetailsForm: UntypedFormGroup;
  formFieldStyle: string = 'none';
  editMode: boolean = false;

  useOnlyMobile2: boolean = false;
  useOnlyEmail2: boolean = false;
  age: number;
  formattedGender: string;
  formattedDOB: string;
  birthday: boolean;

  dateFormat = DATE_FORMAT;

  ngOnInit(): void {
    console.log('learner details', this.learnerDetails);
    this.buildForm();
    this.age =  new Date(Date.now() - new Date(this.learnerDetails.learnerBasics.dob).getTime()).getFullYear() - 1970;
    if(!this.learnerDetails.learnerContactInformation.mobile1 && this.learnerDetails.learnerContactInformation.mobile2){
      this.useOnlyMobile2 = true;
    }
    if(!this.learnerDetails.learnerContactInformation.email1 && this.learnerDetails.learnerContactInformation.email2){
      this.useOnlyEmail2 = true;
    }
    this.birthday = new Date(this.learnerDetails.learnerBasics.dob).getMonth() === new Date(Date.now()).getMonth()
      && new Date(this.learnerDetails.learnerBasics.dob).getDate() === new Date(Date.now()).getDate();
  }

  // Emergency contact details are only in a single string in emergencyContact (Name : Number, Name : Number)
  // It is also only on learners from the DT_V_POTSIGNUP_DETAILS_TSHIPS table
  // employerPhone dosnt seem to always match the other employer number to get from current employment
  // mobile2 and email2 seem to sometimes be the employer or someone else not the learner - comes from DT_V_POTCONTACTINFO
  buildForm() {
    this.formattedGender = this.learnerDetails.learnerBasics.genderId === null? null : this.sharedFunctions.convertGenderString(this.learnerDetails.learnerBasics.genderId);
    this.formattedDOB = this.datePipe.transform(this.learnerDetails.learnerBasics.dob, this.dateFormat);
    this.learnerDetailsForm = this.fb.group({
      'firstName': new UntypedFormControl({ value: this.learnerDetails.learnerBasics.firstName, disabled: true }),
      'lastName': new UntypedFormControl({ value: this.learnerDetails.learnerBasics.lastName, disabled: true }),
      'dob': new UntypedFormControl({ value: this.formattedDOB, disabled: true }),
      'gender': new UntypedFormControl({value: this.formattedGender, disabled: true }),
      'previousActLearner': new UntypedFormControl({ value: this.learnerDetails.learnerRelationship.previousActLearner == -1 ? 'Yes' : 'No', disabled: true }),
      //'previousActWork': new FormControl({ value: this.learnerDetails.learnerRelationship.previousActWork == -1 ? 'Yes' : 'No', disabled: true }),
      'previousWestTest': new UntypedFormControl({ value: this.learnerDetails.learnerRelationship.previousWestTest == -1 ? 'Yes' : 'No', disabled: true }),
      'otherTraining': new UntypedFormControl({ value: this.learnerDetails.learnerRelationship.previousWestTest == -1 ? 'Yes' : 'No', disabled: true }),
      'emergencyContact': new UntypedFormControl({ value: this.learnerDetails.learnerContactInformation.emergencyContact, disabled: true }),
      'preferredLanguageOfLearning': new UntypedFormControl({ value: this.learnerDetails.learnerLanguagePreferences.preferredLanguageOfLearning ? this.learnerDetails.learnerLanguagePreferences.preferredLanguageOfLearning : '', disabled: true }),
      'phone': new UntypedFormControl({ value: this.learnerDetails.learnerContactInformation.phone, disabled: true }),
      'employerPhone': new UntypedFormControl({ value: this.learnerDetails.learnerContactInformation.employerPhone, disabled: true }),
      'mobile1': new UntypedFormControl({ value: this.learnerDetails.learnerContactInformation.mobile1, disabled: true }),
      'mobile2': new UntypedFormControl({ value: this.learnerDetails.learnerContactInformation.mobile2, disabled: true }),
      'email1': new UntypedFormControl({ value: this.learnerDetails.learnerContactInformation.email1, disabled: true }),
      'email2': new UntypedFormControl({ value: this.learnerDetails.learnerContactInformation.email2, disabled: true }),
      'additionalInfo': new UntypedFormControl({ value: this.learnerDetails.learnerBasics.additionalInfo, disabled: true })
    });
  }

  display(value) {
    this.show = value;
    this.changeDetectorRef.detectChanges();
  }

  saveData() {
    if (this.editMode && this.learnerDetailsForm.dirty) {
      let returnData = { values: this.learnerDetailsForm.value, operation: 'update' };
      returnData['traineeId'] = this.learnerDetails.learnerBasics.traineeId;
      returnData['pot'] = this.learnerDetails.learnerUniqueIdentification.pot;
      returnData['programme'] = this.learnerDetails.learnerBasics.programme;
      console.log('sending', returnData);
      this.mainLearnerDetailsUpdate.emit({ 'mainLearnerDetails': returnData });
      this.learnerDetailsForm.markAsUntouched();
      this.learnerDetailsForm.markAsPristine();
    }
  }

  changeEditMode(editMode) {
    this.editMode = editMode;
    this.formFieldStyle = this.editMode ? 'standard' : 'none';

    if (this.editMode) {
      this.learnerDetailsForm.enable();
    } else {
      this.learnerDetailsForm.disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  cancelEdit() {
    this.editMode = false;
    this.formFieldStyle = 'none';
    this.buildForm();
  }

  copyEmail(email){
    if(email){
      this.copyMessage(email)
      this.snackBar.open(`${email} copied to clipboard`, "Close", {
        duration: 4000,
      });
    }
    else{
      this.snackBar.open("Nothing to copy", "Close", {
        duration: 4000,
      });
    }
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
