import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { SearchVirtualScrollComponent } from '../shared/search-virtual-scroll/search-virtual-scroll.component';
import { SearchType } from '../search.service';

@Component({
  selector: 'app-learner-search',
  templateUrl: './learner-search.component.html',
})
export class LearnerSearchComponent {

  @ViewChild('virtualScroll', { static: false }) SearchVirtualScrollComponent: SearchVirtualScrollComponent;

  constructor(
    private fb: FormBuilder,
    private cdf: ChangeDetectorRef,
  ) {

  }

  public categories = [
    { label: 'Name', value: 'name' },
    { label: 'ULI', value: 'uli' },
    { label: 'Trainee ID', value: 'traineeId' },
  ]

  public filterOptions: {
    subcontractors: { name: string, id: number }[],
    programmeTypes: [name: string, id: number][],
    centres: [name: string, id: number][],
    assessors: { name: string, id: number }[],
    sectors: [name: string, id: number][],
    schemes: [name: string, id: number][]
  } = {
      subcontractors: [],
      programmeTypes: [],
      centres: [],
      assessors: [],
      sectors: [],
      schemes: []
    };

  searchForm = this.fb.group({
    term: [''],
    category: ['name'],
  });

  filterForm = this.fb.group({
    subcontractors: [[] as { name: string, id: number }[]],
    programmeTypes: [[] as { name: string, id: number }[]],
    centres: [[] as { name: string, id: number }[]],
    assessors: [[] as { name: string, id: number }[]],
    sectors: [[] as { name: string, id: number }[]],
    schemes: [[] as { name: string, id: number }[]],
    activeOnly: [true],
  });

  public type = SearchType.learner;

  public isLoadingSearch = false;
  public applyFiltersButton = false;
  public totalCount: number = null;
  public gettingMoreResults = false;
  public sideNavOpen = true;

  public loadingSearchChange(value: boolean) {
    this.isLoadingSearch = value;
    this.cdf.detectChanges();
  }

  public applyFiltersButtonChange(value: boolean) {
    this.applyFiltersButton = value;
    this.cdf.detectChanges();
  }

  public totalCountChange(value: number) {
    this.totalCount = value;
    this.cdf.detectChanges();
  }

  public gettingMoreResultsChange(value: boolean) {
    this.gettingMoreResults = value;
    this.cdf.detectChanges();
  }

  public onSubmit(data) {
    this.SearchVirtualScrollComponent.onSubmit(data);
  }

  public onSidenavOpenedChange(value: boolean) {
    this.sideNavOpen = value;
    this.SearchVirtualScrollComponent.onSidenavOpenedChange(value);
    this.cdf.detectChanges();
  }

}
