<!-- <button mat-flat-button color="primary" class="button" (click)="addEvent()">Add Event</button> -->


<div class="events-container" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3%" fxLayoutAlign="center">


  <div fxFlex="100" class="events-form">
    <h2 class="eventsTitle">Events</h2>

    <form [formGroup]="eventsFormOptions" novalidate style="width: 100%;">
      <div class="wrap" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
        fxLayoutAlign="start end" fxLayoutAlign.md="start start" fxLayoutAlign.sm="start start"
        fxLayoutAlign.xs="start start">
        <ng-select *ngIf="placementType == 1" flex="25" #select class="branch-select" placeholder="Branches"
          [items]="branches" [multiple]="true" formControlName="branches" (change)="getFilteredEvents('branches')">
        </ng-select>

        <ng-select flex="15" class="event-type-select" (change)="getFilteredEvents('type')" #select
          [items]="uniqueEventTypes" placeholder="Event Type" formControlName="eventTypeSelect"
          (clear)="eventTypeCleared()"></ng-select>

        <ng-select flex="15" class="staff-member-select" (change)="getFilteredEvents('staff')" #select
          [items]="uniqueStaff" placeholder="By" formControlName="eventStaffSelect" (clear)="staffSelectCleared()">
        </ng-select>

        <input flex="20" type="text" #dateRange ngxDaterangepickerMd placeholder="Date period" class="date-range-picker"
          autocomplete="off" startKey="start" endKey="end" (datesUpdated)="dateRangeChange($event)">

        <button flex="5" class="config-button" mat-button color="primary" style="margin-left: 10px;"
          (click)="resetForm()">Reset</button>
        <button flex="5" class="config-button" mat-button color="accent" style="margin-left: 10px;"
          (click)="addEventForm()">Create</button>
      </div>
    </form>

    <div *ngFor="let event of filteredEvents" class="employer-events" style="max-width: 40%; padding-bottom: 20px;">
      <button (click)="openForEdit(event)" mat-flat-button class="employer-event-button"
        style="font-weight: 450;">{{event.branch}} {{event.eventType}}</button>
        <mat-divider></mat-divider>
      <div>
        <div [outerHTML]="event.notes"></div>
      </div>
      <div *ngIf="event.eventType == 'Vetting' || event.eventType == 'Monitor' || event.eventType == 'ELI'">
        <span *ngIf="event.staff != 'Unknown User'">By {{event.staff}}</span>
        <span *ngIf="event.plannedEventDate != null"> Planned for {{event.plannedEventDate | date: this.dateFormat }}</span>
        <span *ngIf="event.actualEventDate != null"> | Actual date: {{event.actualEventDate | date: this.dateFormat }}</span>
      </div>
      <div *ngIf="event.eventType != 'Vetting' && event.eventType != 'Monitor' && event.eventType != 'ELI'">
        <span *ngIf="event.staff != 'Unknown User'">By {{event.staff}}</span>
        <span *ngIf="event.actualEventDate == null"> Planned for {{event.plannedEventDate | date: this.dateFormat }}</span>
        <span *ngIf="event.actualEventDate != null"> on {{event.eventDate | date: this.dateFormat }}</span>
      </div>
    </div>
  </div>




</div>
