<mat-card class="learners-card">
    <button *ngIf="dataSource" mat-flat-button color="primary" class="learners-button" routerLink="/employer/search">Search all Employers</button>
    <h2 class="progressTitle" style="padding-left:0px;">Expiration Notifications</h2>

    <mat-spinner *ngIf="!dataSource" style="font-size: 25px;"></mat-spinner>

    <table [hidden]="!dataSource" mat-table [dataSource]="dataSource" matSort>
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{unCamelCase(column)}} </th>
            <span *ngIf="column=='name'">
                <td mat-cell *matCellDef="let element"> <a class="export-all" style="text-decoration: underline; font-size: 1em;" (click)="loadEmployer(element[column])"> {{element[column]}} </a> </td>
            </span>
            <span *ngIf="column != 'name'">
                <td mat-cell *matCellDef="let element" > {{column.indexOf('expiry') != -1 ? (element[column] | date) : element[column] }} </td>
            </span>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator  [pageSize]="8"  [pageSizeOptions]="[8, 15, 50]" showFirstLastButtons></mat-paginator>
</mat-card>
