import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";

@Component({
  selector: "app-lor-progression-success-report",
  templateUrl: "./lor-progression-success-report.component.html"
})
export class LorProgressionReportComponent {

  reportVariant = ReportVariant.LorProgressionSuccess;
  reportName = "LOR Progression Success Report";
  highlighting1 = [
    {
      name: "Excellent",
      columns: ["total_percent"],
      lowerThreshold: 80,
      hex: "#02c21c",
    },
    {
      name: "Good",
      columns: ["total_percent"],
      lowerThreshold: 70,
      upperThreshold: 79,
      hex: "#9afc9f",
    },
    {
      name: "Poor",
      columns: ["total_percent"],
      lowerThreshold: 60,
      upperThreshold: 69,
      hex: "#f7700f",
    },
    {
      name: "Very Poor",
      columns: ["total_percent"],
      lowerThreshold: 0,
      upperThreshold: 59,
      hex: "#c92020",
    }
  ];
  highlighting2 = [
    {
      name: "Excellent",
      columns: ["total_percent"],
      lowerThreshold: 70,
      hex: "#02c21c",
    },
    {
      name: "Good",
      columns: ["total_percent"],
      lowerThreshold: 60,
      upperThreshold: 69,
      hex: "#9afc9f",
    },
    {
      name: "Poor",
      columns: ["total_percent"],
      lowerThreshold: 50,
      upperThreshold: 59,
      hex: "#f7700f",
    },
    {
      name: "Very Poor",
      columns: ["total_percent"],
      lowerThreshold: 0,
      upperThreshold: 50,
      hex: "#c92020",
    }
  ];
  columnHeader = [
    {
      columnDef: "header-row-blank",
      title: "",
      cols: ['', 'scheme'], //needs to be blank to allow for grouping
      headerStyle: "background: #F9F6F2; border: 0;"
    },
    {
      columnDef: "header-row-pl",
      title: "Positive Learners",
      cols: ['pl_e', 'pl_hl', 'pl_se', 'pl_vw', 'pl_tl'],
      headerStyle: "background: #1fab69;",
      headerHex: "#1fab69",
      bodyHex: "#b3ffdb"
    },
    {
      columnDef: "header-row-nl",
      title: "Negative Learners",
      cols: ['nl_e', 'nl_fl', 'nl_np', 'nl_tl'],
      headerStyle: "background: #fa3232;",
      headerHex: "#fa3232",
      bodyHex: "#fac3c3"
    },
    {
      columnDef: "header-row-xl",
      title: "Excluded Leavers",
      cols: ['xl_e', 'xl_fl', 'xl_np', 'xl_tl'],
      headerStyle: "background: #c56dfc;",
      headerHex: "#c56dfc",
      bodyHex: "#eed4ff"
    },
    {
      columnDef: "header-row-tl",
      title: "Total",
      cols: ['total_tl', 'total_percent'],
      headerStyle: "background: gray;",
      headerHex: "gray",
      bodyHex: ""
    }
  ];
  tableColumns = ['scheme', 'pl_e', 'pl_hl', 'pl_se', 'pl_vw', 'pl_tl', 'nl_e', 'nl_fl', 'nl_np', 'nl_tl', 'xl_e', 'xl_fl', 'xl_np', 'xl_tl', 'total_tl', 'total_percent'];
  tableColumnAliases = [
    {
      columns: ['pl_e', 'nl_e', 'xl_e'],
      alias: "Employed"
    },
    {
      columns: ['pl_hl'],
      alias: "Higher Learning"
    },
    {
      columns: ['pl_se'],
      alias: "Self Employed"
    },
    {
      columns: ['pl_vw'],
      alias: "Voluntary Work"
    },

    {
      columns: ['nl_fl', 'xl_fl'],
      alias: "Further Learning"
    },
    {
      columns: ['nl_np', 'xl_np'],
      alias: "No Progress"
    },
    {
      columns: ['pl_tl', 'nl_tl', 'xl_tl', 'total_tl'],
      alias: "Total Leavers"
    },
    {
      columns: ['total_percent'],
      alias: "Total % (not inc. Excluded Leavers)"
    }
  ];
  programmeOptions = [
    {
      name: "JGW",
      columns: this.tableColumns,
      columnAliases: this.tableColumnAliases,
      columnGrouping: [{
        columnDef: "header-row-pl",
        title: "Positive Learners",
        colspan: 5,
        style: "background: #1fab69;"
      }],
      grouping: [
        {
          name: "Provider",
          icon: "assets/reports/summary-view.svg",
          highlighting: this.highlighting1
        },
        {
          name: "Sector",
          icon: "assets/reports/graph-view.svg",
          highlighting: this.highlighting1
        },
        {
          name: "Assessor",
          icon: "assets/reports/graph-view.svg",
          highlighting: this.highlighting1
        }
      ]
    },
    {
      name: "Traineeships",
      columns: this.tableColumns,
      columnAliases: this.tableColumnAliases,
      grouping: [
        {
          name: "Provider",
          icon: "assets/reports/summary-view.svg",
          highlighting: this.highlighting1
        },
        {
          name: "Centre",
          icon: "assets/reports/summary-view.svg",
          highlighting: this.highlighting1
        },
        {
          name: "Sector",
          icon: "assets/reports/graph-view.svg",
          highlighting: this.highlighting1
        },
        {
          name: "Assessor",
          icon: "assets/reports/graph-view.svg",
          highlighting: this.highlighting1
        }
      ]
    },
    {
      name: "Work Ready",
      columns: this.tableColumns,
      columnAliases: this.tableColumnAliases,
      grouping: [
        {
          name: "Provider",
          icon: "assets/reports/summary-view.svg",
          highlighting: this.highlighting2
        },
        {
          name: "Sector",
          icon: "assets/reports/graph-view.svg",
          highlighting: this.highlighting2
        },
        {
          name: "Assessor",
          icon: "assets/reports/graph-view.svg",
          highlighting: this.highlighting2
        }
      ]
    },
    {
      name: "Other",
      columns: this.tableColumns,
      columnAliases: this.tableColumnAliases,
      grouping: [
        {
          name: "Provider",
          icon: "assets/reports/summary-view.svg"
        },
        {
          name: "Sector",
          icon: "assets/reports/graph-view.svg"
        },
        {
          name: "Assessor",
          icon: "assets/reports/graph-view.svg"
        }
      ]
    }
  ];
}
