import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { ReportsService } from '../reports.service';
import { ShareReportFiltersDialogComponent } from '../share-report-filters-dialog/share-report-filters-dialog.component';

@Component({
  selector: 'app-recent-filter-dialog',
  templateUrl: './recent-filter-dialog.component.html',
  styleUrls: ['./recent-filter-dialog.component.scss']
})
export class RecentFilterDialogComponent implements OnInit {
  shareFiltersForm: UntypedFormGroup;
  staff;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<RecentFilterDialogComponent>,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.staff = this.reportsService.getStaff();
    this.buildForm();
    console.log(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getStoredTimeAsString(epoch){
   return  moment.unix(epoch).format('dddd, MMMM Do, YYYY h:mm:ss A')
  }

  loadRecentFilter(recent){
    var data = {'type': "recent", 'filter': recent};
    this.dialogRef.close(data);
  }

  loadStoredFilter(stored){
    var data = {'type': "stored", 'filter': stored};
    this.dialogRef.close(data);
  }

  onOK(): void {
    if (!this.shareFiltersForm.value.name) {
      const snackBarMessage = "Please choose a name for this filter."
      this.snackBar.open(snackBarMessage,
        'Close', { duration: 5000 });
    }
    else {
      this.dialogRef.close(this.shareFiltersForm.value);
    }
  }

  buildForm() {
    this.shareFiltersForm = this.fb.group({
      isSaveFilters: [true, Validators.required],
      name: null,
      selectedStaff: null
    });
  }
}
