<div>
    <div style="display: flex; justify-content: center;">
        <p>
            {{errorMessage}}
        </p>
    </div>
    <div style="display: flex;justify-content: center;">
        <p>
            {{countMessage}}
        </p>
    </div>
</div>