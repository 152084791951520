import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-discussion-form',
  templateUrl: './discussion-form.component.html',
  styleUrls: ['./discussion-form.component.scss']
})
export class DiscussionFormComponent implements OnInit {

  Editor = ClassicEditor;
  discussionForm: UntypedFormGroup;
  editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic'],
    height: '500px'
  }
  discussionData: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DiscussionFormComponent>,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    console.log(this.data.discussion);
    this.discussionData = this.data['discussion'];
    this.buildForm(); 
  }

  buildForm() {
    this.discussionForm = this.fb.group({
      discussion: this.discussionData
    });
  }

  saveAndClose(){
    const formData = this.discussionForm.dirty ? this.discussionForm.value : null;
    // console.log(formData.discussion);
    this.dialogRef.close(formData.discussion);
  }

  close() {
    this.dialogRef.close();
  }
  
}
