<div *ngIf="show"  [style.margin-left]="'25px'">
    <div *ngFor="let p of plr">
        <div class="body-text-title">
            <p class="body-text" style="margin-bottom: 15px;"> {{ p.qualificationTitle }}  {{ p.level}} (Grade: {{ p.grade }})</p>
        </div>
    </div>
</div>



