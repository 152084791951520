<mat-card class="summary-card">
    <h2 class="progressTitle summary-title">{{title}}</h2>

    <div fxLayout="row wrap" fxLayoutGap="25px grid">

        <div class="data-card">
            <a class="export-all" style="text-decoration: underline; font-size: 1em;" (click)="openDialog('size')"> Number of learners </a>
            <mat-spinner *ngIf="!size && size != 0" style="font-size: 25px;"></mat-spinner>
            <p class="data-card-data" *ngIf="size" >{{size}}</p>
            <p class="percentage">{{sizePercentage}}</p>
        </div>

        <div class="data-card">
            <a class="export-all" style="text-decoration: underline; font-size: 1em;" (click)="openDialog('onTrack')"> Learners on track </a>
            <mat-spinner *ngIf="!onTrack && onTrack != 0" style="font-size: 25px;"></mat-spinner>
            <p class="data-card-data" *ngIf="onTrack" >{{onTrack}}</p>
            <p class="percentage">{{onTrackPercentage}}</p>
        </div>

        <div class="data-card">
            <a class="export-all" style="text-decoration: underline; font-size: 1em;" (click)="openDialog('overEED')"> Learners Over EED </a>
            <mat-spinner *ngIf="!overEED && overEED != 0" style="font-size: 25px;"></mat-spinner>
            <p class="data-card-data" *ngIf="overEED" >{{overEED}}</p>
            <p class="percentage">{{overEEDPercentage}}</p>
        </div>

    </div>
</mat-card>

