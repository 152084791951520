import { Injectable } from "@angular/core";
import { ApiHelper } from "src/app/shared/api-helper";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { StringHelper } from "src/app/shared/string-helper";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DrillDownReportService extends ApiHelper {

  getReportDate(
    reportId: number,
    staffId: number,
    level: number,
    pageNumber: number,
    pageSize: number,
    filter: string = null,
    sortColumn: string = null,
    sortColumnDirection: string = null,
    groupByColumn: string = null
  ) {
    const report = ReportVariant[reportId];
    let url = `${environment.API_URL}V2/ReportingData/${report}/${level}?staffId=${staffId}&groupByString=${groupByColumn}&pageNumber=${pageNumber}&perPage=${pageSize}`;
    if (this.stringHelper.notNullOrEmpty(filter)) {
      url += `&filterString=${filter}`;
    }
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    return this.getWithAccessToken(url);
  }

  getCommonFilterData(reportId: number, staffId: number) {
    const report = ReportVariant[reportId];
    const url = `${environment.API_URL}V2/ReportingFilter/${report}/${staffId}`;
    return this.getWithAccessToken(url);
  }

  getCustomFilterData(reportId: number, staffId: number, level: number, type: string) {
    const report = ReportVariant[reportId];
    const url = `${environment.API_URL}V2/ReportingFilter/${report}/${staffId}/${level}/${type}`;
    return this.getWithAccessToken(url);
  }

  postReportExport(
    reportId: number,
    filterString: string,
    sortString: string,
    displayedColumns: string,
    reportFormat: string,
    groupByColumn: string,
    drillDownLevel: number
  ) {
    const url = `${environment.API_URL}V2/Reporting/QueueExport`;
    const body = {
      reportId: reportId,
      filterString: filterString,
      sortString: sortString,
      reportFormat: reportFormat,
      displayedColumns: displayedColumns,
      groupByString: groupByColumn,
      drillDownLevel: drillDownLevel
    };
    return this.postWithAccessToken(url, body);
  }
}
