import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";

@Component({
  selector: "app-lor-framework-success-report",
  templateUrl: "./lor-framework-success-report.component.html"
})
export class LorFrameworkReportComponent {
  reportVariant = ReportVariant.LorFrameworkSuccess;
  reportName = "LOR Framework Success Report";
  highlighting = [
    {
      name: "Excellent",
      columns: ["prov", "act"],
      lowerThreshold: 90,
      hex: "#02c21c",
    },
    {
      name: "Good",
      columns: ["prov", "act"],
      lowerThreshold: 80,
      upperThreshold: 89,
      hex: "#9afc9f",
    },
    {
      name: "Poor",
      columns: ["prov", "act"],
      lowerThreshold: 75,
      upperThreshold: 79,
      hex: "#f7700f",
    },
    {
      name: "Very Poor",
      columns: ["prov", "act"],
      lowerThreshold: 0,
      upperThreshold: 74,
      hex: "#c92020",
    }
  ];
  groupingOptions = [
    {
      name: "Provider",
      icon: "assets/reports/summary-view.svg",
      highlighting: this.highlighting
    },
    {
      name: "Sector",
      icon: "assets/reports/graph-view.svg",
      highlighting: this.highlighting
    },
    {
      name: "Assessor",
      icon: "assets/reports/graph-view.svg",
      highlighting: this.highlighting
    }
  ]
  programmeOptions = [
    { name: 'Apprenticeships', columns: ['scheme', 'aa', 'pa', 'el', 'xl', 'tl', 'prov', 'act'], grouping: this.groupingOptions }
  ]
}
