import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class StringHelper {
  /**
   * @description
   * Function that checks if a string is null or empty.
   * @remarks
   * This check also takes into account if the value is just whitespace.
   * @param value - The string to check.
   * @returns boolean - True if the string is not null or empty, false otherwise.
   */
  notNullOrEmpty(value: string) {
    return value !== null && value.trim() !== '';
  }
  /**
   * @description
   * Formats a string to display '-' if the string is null or empty.
   * @remarks
   * This check also takes into account if the value is just whitespace.
   * @param value - The string to check.
   * @returns value if the string is not null or empty, '-' otherwise.
   */
  formatForEmpty(value): string {
    let formattedValue = '-';
    if (this.notNullOrEmpty(value)) {
      formattedValue = value;
    }
    return formattedValue;
  }

  /**
   * @description
   * Function that converts camel case strings to a more readable format.
   * @param str - the camel case string to convert.
   * @returns string - The converted string.
   */
  unCamelCase(str: string): string {
    return (
      str
        // insert a space between lowercase and number
        .replace(/([a-z])([0-9])/g, "$1 $2")
        // insert a space between uppercase and number
        .replace(/([0-9])([A-Z])/g, "$1 $2")
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  }
}
