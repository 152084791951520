import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-data-list-dialog',
  templateUrl: './data-list-dialog.component.html',
  styleUrls: ['./data-list-dialog.component.scss']
})
export class DataListDialogComponent implements OnInit {
  commentsForm: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private snackBar: MatSnackBar,
  private dialogRef: MatDialogRef<DataListDialogComponent>,
  private fb: UntypedFormBuilder
) { }

ngOnInit(): void {
  this.buildForm();
}

onNoClick(): void {
  this.dialogRef.close();
}

onOK(): void {
  if (this.commentsForm.dirty) {
    this.dialogRef.close(this.commentsForm.value);
  }
  else {
    this.dialogRef.close();
  }
}

buildForm() {
  console.log(this.data, 'notes?')
    this.commentsForm = this.fb.group({
      comment: this.data.comment,
      trModuleId: this.data.trModuleId
    });
  }
}
