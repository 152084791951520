import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface IPostData {
  placementId: string,
  placementType: string,
  staff: any[],
  by: any,
  eventTypeId: number,
  eventDate: Date,
  plannedDate: Date,
  actualDate: Date,
  location: string,
  message: string,
  subject: string,
  startTime: string,
  endTime: string,
  IamTheOrganiser: boolean,
  IsEmployerLevelEvent: boolean,
  forBranchId: string,
  eventId: string
}

@Injectable({
  providedIn: 'root'
})
export class EmployerEventsService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public getStaff(): Observable<any> {
    const url = `${environment.API_URL}GetStaffForEvent`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public getEventTypes(): Observable<any> {
    const url = `${environment.API_URL}GetEmployerEventTypes`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public getEventCalendarInfo(id: string): Observable<any> {
    const url = `${environment.API_URL}getEventCalendarInfo?id=${id}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }


  public getEmployerEvents(type: string, employerBranchId: number): Observable<any> {
    const url = `${environment.API_URL}GetEmployerEvents?employerBranchId=${employerBranchId}&type=${type}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }



  public createNewEmployerEvent(placementId: string, placementType: string,  values: any): Observable<any> {
    console.log('type', placementType)

    const url = `${environment.API_URL}CreateNewEmployerEventRecord`;
    const options = this.getOptionsWithAccessToken();
    console.log('for branch', values.forBranch)
    let postData: IPostData = {
      placementId: placementId,
      placementType: placementType,
      staff: values.selectedStaff ?? [],
      by: values.by,
      eventTypeId: values.eventType.employereventtypeid,
      eventDate: values.eventDate,
      plannedDate: values.plannedEventDate,
      actualDate: values.actualEventDate,
      location: values.location,
      subject: values.subject,
      message: values.message,
      startTime: values.startTime,
      endTime: values.endTime,
      IamTheOrganiser: values.IamTheOrganiser,
      IsEmployerLevelEvent: values.IsEmployerLevelEvent,
      forBranchId: values.forBranch ? values.forBranch.placementID : 0,
      eventId: "-"
    };
    //console.log(postData);
    return this.http.post<any>(url, postData, options);
  }

  public UpdateEmployerEvent(eventId: string, placementId: string, placementType: string,  values: any): Observable<any> {
    console.log('type', placementType)

    const url = `${environment.API_URL}UpdateEmployerEventRecord`;
    const options = this.getOptionsWithAccessToken();
    console.log('for branch', values.forBranch)
    let postData: IPostData = {
      placementId: placementId,
      placementType: placementType,
      staff: values.selectedStaff ?? [],
      by: values.by,
      eventTypeId: values.eventType.employereventtypeid,
      eventDate: values.eventDate,
      plannedDate: values.plannedEventDate,
      actualDate: values.actualEventDate,
      location: values.location,
      subject: values.subject,
      message: values.message,
      startTime: values.startTime,
      endTime: values.endTime,
      IamTheOrganiser: values.IamTheOrganiser,
      IsEmployerLevelEvent: values.IsEmployerLevelEvent,
      forBranchId: values.forBranch ? values.forBranch.placementID : 0,
      eventId: eventId
    };
    //console.log(postData);
    return this.http.post<any>(url, postData, options);
  }

  public deleteEmployerEvent(eventId: number): Observable<any> {
    const url = `${environment.API_URL}DeleteEmployerEventRecord?eventId=${eventId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, null, options);
  }

}
