import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { WidgetDirective } from 'src/app/core/directives/widget.directive';
import { ContractWidgetItem } from '../../contract-widgets/contract-widget-item';
import { HomeDashboardService } from '../home-dashboard.service';
import { FavouriteTableService } from 'src/app/core/database/favourite-table.service';
import { ContractWidgetTemplateComponent } from '../../contract-widgets/contract-widget-template-component';
import { YourLearnersComponent } from '../../home-dashboards-shared/your-learners/your-learners.component';
import { YourEmployersComponent } from '../../home-dashboards-shared/your-employers/your-employers.component';
import { YourReportsComponent } from '../../home-dashboards-shared/your-reports/your-reports.component';
import { ReportCardComponent } from '../../home-dashboards-shared/report-card-caseload-progress/report-card.component';
import { YourEventsComponent } from '../../home-dashboards-shared/your-events/your-events.component';
import { ContractWidgetService } from '../../contract-widgets/contract-widget.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home-marketing',
  templateUrl: './home-marketing.component.html',
  styleUrls: ['./home-marketing.component.scss']
})
export class HomeMarketingComponent implements OnInit {

  @Input() staffId: number;

  @ViewChild(WidgetDirective, { static: true }) selectedWidgetHost!: WidgetDirective;

  // @ViewChild('yourEventsWidget') yourEventsWidget;
  // @ViewChild('yourEmployersWidget') yourEmployersWidget;

  allWidgets: any[] = [];
  selectedWidgets: ContractWidgetItem[] = [];
  widgetsSelected: number[] = [];
  widgetTemplate: number[] = [];

  showHideWidgetOptions: boolean = false;

  isDefaultsLoaded: boolean = false;

  currentDashboard = 'marketing';
  myFavouriteReports: any;

  constructor(
    private homeDashboardService: HomeDashboardService,
    private favouriteTableService: FavouriteTableService,
    private cdr: ChangeDetectorRef,
    private contractWidgetService: ContractWidgetService,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
  }

  // Widgets --------------

  refreshDisplayedWidgets() {
    let tempArray: ContractWidgetItem[] = [];

    this.widgetsSelected.forEach(element => {
      let selected = this.allWidgets.filter(a => {
        return a.widgetId == element
      });

      if (selected[0]) {
        tempArray.push(
          new ContractWidgetItem(
            ContractWidgetTemplateComponent,
            { 'widgetId': selected[0].widgetId, 'name': `${selected[0].widgetName}` }
          ));
      }
    });

    this.selectedWidgets = tempArray;
  }

  saveWidgetChanges() {

    if (this.isDefaultsLoaded) {
      this.contractWidgetService.removeStaffWidgetSelections(this.staffId).subscribe(
        response => {
          if (response) {
            this.snackBar.open("Default widgets have been restored", "Close", {
              duration: 4000,
            });
            this.isDefaultsLoaded = false;
          }
        }
      )
    } else {

      let hasChanged = true //false;

      // TODO: check if tempArray and this.selectedWidgets hold any different items

      /*
        tempArray.every(ta => {
          if (this.selectedWidgets.indexOf(ta) == -1) {
            hasChanged = true;
            return false;
          }
        });

        if (!hasChanged) {
          this.selectedWidgets.every(sw => {
            if (tempArray.indexOf(sw) == -1) {
              hasChanged = true;
              return false;
            }
          })
        }
      */

      if (hasChanged) {
        this.contractWidgetService.saveContractWidgetChanges(this.staffId, this.widgetsSelected).subscribe(
          response => {
            console.log(response);
          }
        );

        this.refreshDisplayedWidgets();
      }

      this.loadSelectedWidgetComponent();
    }
  }

  loadDefaultWidgets() {
    this.isDefaultsLoaded = true;
    this.widgetsSelected = this.widgetTemplate;
    this.refreshDisplayedWidgets();
    this.loadSelectedWidgetComponent();
  }

  loadSelectedWidgetComponent() {
    const viewContainerRef = this.selectedWidgetHost.viewContainerRef;
    viewContainerRef.clear();

    this.selectedWidgets.forEach(
      widgetItem => {

        let widgetId = widgetItem.data.widgetId;

        switch (widgetId) {

          case (1): {
            //console.log('adding widget 1: YourLearnersComponent')
            const componentRef = viewContainerRef.createComponent<YourLearnersComponent>(YourLearnersComponent);
            this.homeDashboardService.getYourLearners(this.staffId).subscribe(data => {
              componentRef.location.nativeElement['style']['width'] = '45%';
              componentRef.instance.setData(data);
              this.cdr.detectChanges();
            });
            break;
          };

          case (2): {
            //console.log('adding widget 2: YourEmployersComponent')
            const componentRef = viewContainerRef.createComponent<YourEmployersComponent>(YourEmployersComponent);
            this.homeDashboardService.getYourEmployers(this.staffId).subscribe(data => {
              if (data) {
                componentRef.location.nativeElement['style']['width'] = '45%';
                componentRef.instance.setCols(data);
                this.cdr.detectChanges();
              };
            });
            break;
          };

          case (3): {
            //console.log('adding widget 3: YourEventsComponent')
            const componentRef = viewContainerRef.createComponent<YourEventsComponent>(YourEventsComponent);
            componentRef.location.nativeElement['style']['width'] = '45%';
            componentRef.instance.currentDashboard = 'healthandsafety';
            componentRef.instance.staffId == this.staffId;
            this.cdr.detectChanges();
            break;
          };

          case (4): {
            //console.log('adding widget 4: YourReportsComponent')
            const componentRef = viewContainerRef.createComponent<YourReportsComponent>(YourReportsComponent);
            this.favouriteTableService
              .getAll()
              .then(response => {
                for (let x in response) {
                  let link = "/reports/" + response[x].title.toLowerCase().replace(" ", "-") + (response[x]['filterId'] != 0 ? '/' + response[x]['filterId'] : '')
                  response[x].link = link;
                };
                componentRef.instance.favourites = response;
                componentRef.location.nativeElement['style']['width'] = '22.5%';
                this.cdr.detectChanges();
              });
            break;
          };

          case (5): {
            //console.log('adding widget 5: ReportCardComponent')
            const componentRef = viewContainerRef.createComponent<ReportCardComponent>(ReportCardComponent);
            this.homeDashboardService.getCaseloadStats(this.staffId).subscribe(data => {
              if (data) {
                componentRef.location.nativeElement['style']['width'] = '45%';
                componentRef.instance.setData(data.caseload);
              };
              this.cdr.detectChanges();
            });
          };
        }
      });
  }

  showWidgetOptions() {
    this.showHideWidgetOptions = !this.showHideWidgetOptions;
  }

  // -------------- Widgets

  // hideMissedEvents(){
  //   this.yourEventsWidget.hideMissedEvents();
  // }

  // setYourEmployers(data) {
  //   this.yourEmployersWidget.setCols(data);
  // }

  setYourFavourites(data) {
    this.myFavouriteReports = data;
    console.log('faves', this.myFavouriteReports);
    this.cdr.detectChanges();
  }


}
