import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { select } from "underscore";

@Component({
    selector: 'app-trainee-objective-list-report',
    templateUrl: './trainee-objective-list-report.component.html',
})
export class TraineeObjectiveListReportComponent {

    reportName = "Trainee Objective List Report";
    reportVariant = ReportVariant.TraineeObjectiveList;
    defaultDisplayColumns = [
        {
            type: 'default',
            columns: [
                'fullname',
                'contract',
                'programmeType',
                'scheme',
                'sector',
                'titleShort',
                'objectiveStatus',
                'assessor',
                'percentComplete',
                'expCompletionDate'
            ]
        }
    ];
    dateFilters = [
        'startDate',
        'expCompletionDate',
        'terminationDate',
        'regDate',
        'certAppliedFor',
        'certReceived',
        'qualStartDate',
        'objectiveStatusDate'
    ];
    otherColumns = ['scheme', 'count'];

}
