import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import Dexie from 'dexie';
import { LocalDb } from '../core/database/database.service';
import { ISearchData } from 'src/app/shared/interfaces/search-data';
import { ApiHelper } from '../shared/api-helper';

export enum SearchType {
  learner = 'learner',
  employer = 'employer'
}

@Injectable({
  providedIn: 'root'
})
export class SearchTableService {
  searchTable: Dexie.Table<any, number>;
  constructor(private localDb: LocalDb) {
    this.searchTable = this.localDb.table('search');
  }
  public getStoredParams(type: string): Dexie.Promise<any> {
    switch (type) {
      case SearchType.learner:
        return this.searchTable.get(0);
      case SearchType.employer:
        return this.searchTable.get(1);
      default:
        throw new Error("Invalid search type");
    }
  }

  public setStoredParams(type: string, params: ISearchData) {
    // Could be worth checking for any null values as this could cause issues - espechially for the filter dropdowns
    switch (type) {
      case SearchType.learner:
        this.searchTable.put(JSON.stringify(params), 0);
        break;
      case SearchType.employer:
        this.searchTable.put(JSON.stringify(params), 1);
        break;
      default:
        throw new Error("Invalid search type");
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SearchService extends ApiHelper {
  public search(search: ISearchData): Observable<any> {
    let url = "";

    switch (search.type) {
      case SearchType.learner:
        url = `${environment.API_URL}v2/Search/Learner`;
        break;
      case SearchType.employer:
        url = `${environment.API_URL}v2/Search/Employer`;
        break;
      default:
        throw new Error("Invalid search type");
    }

    const filters = {};
    for (const prop in search.filters) {
      if (prop !== "activeOnly") {
        filters[prop] = search.filters[prop].map(x => x.id);
      } else {
        filters[prop] = search.filters[prop];
      }
    }

    const params = new HttpParams()
      .set("term", search.term)
      .set("category", search.category)
      .set("filters", JSON.stringify(filters))
      .set("staffId", search.staffId.toString())
      .set("paginationSkip", search.paginationSkip.toString())
      .set("paginationTake", search.paginationTake.toString());

    return this.getWithAccessToken(url, params);
  }

  public filterOptions(type: string, staffId: number): Observable<any> {
    let url = "";
    switch (type) {
      case SearchType.learner:
        url = `${environment.API_URL}v2/Search/Learner/FilterOptions`;
        break;
      case SearchType.employer:
        url = `${environment.API_URL}v2/Search/Employer/FilterOptions`;
        break;
      default:
        throw new Error("Invalid search type");
    }
    const params = new HttpParams()
      .set("staffId", staffId);

    return this.getWithAccessToken(url, params);
  }
}
