import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-date-calculator',
  templateUrl: './date-calculator.component.html',
  styleUrls: ['./date-calculator.component.scss']
})
export class DateCalculatorComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  dateCalculatorForm: UntypedFormGroup;
  plus30: Date;
  plus61: Date;
  dateDiff: number;

  dateFormat = DATE_FORMAT;

  constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.dateCalculatorForm = this.fb.group({
      reviewDate: new UntypedFormControl(),
      dateOne: new UntypedFormControl(),
      dateTwo: new UntypedFormControl(),
    });
  }

  calcDates() {
    console.log('calcing')
    this.plus30 = new Date(this.dateCalculatorForm.controls['reviewDate'].value.getTime() + (1000 * 60 * 60 * 24)*30 );
    this.plus61 = new Date(this.dateCalculatorForm.controls['reviewDate'].value.getTime() + (1000 * 60 * 60 * 24)*61 );
    this.cdr.detectChanges();
  }

  calcDateDifference() {
    let dateOne =  this.dateCalculatorForm.controls['dateOne'].value;
    let dateTwo = this.dateCalculatorForm.controls['dateTwo'].value;

    if (dateOne && dateTwo) {
      this.dateDiff = Math.round((dateTwo - dateOne) / 86400000) //  milliseconds;
    }
  }

}
