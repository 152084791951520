import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, FormControl, FormArray, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-submit-for-iqa',
  templateUrl: './submit-for-iqa.component.html',
  styleUrls: ['./submit-for-iqa.component.scss']
})
export class SubmitForIqaComponent implements OnInit {

  submitForIQAForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SubmitForIqaComponent>,
    private fb: UntypedFormBuilder
  ){}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.submitForIQAForm = this.fb.group({
      portfolioType: ["e-Portfolio", Validators.required] ,
      iqaType: [null, Validators.required],
      priority: ["Non-Priority", Validators.required],
      objective: [null, Validators.required]
    });
  }

  close(){
    this.dialogRef.close();
  }

}
