import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BasicReportService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  getBasicReport(reportName: string, staffId: number): Observable<any> {
    const url = `${environment.API_URL}Get${reportName}?PageNumber=${1}&PageSize=${environment.reportMaxPageSize}&staffId=${staffId}&isOnlyActiveLearners=${false}&filterString=${null}`;
    const options = this.getOptionsWithAccessToken();
    //console.log(`${reportName}: ${url}`);
    return this.http.get(url, options).pipe(catchError(error => {
      console.log('API Error: ', error);
      return throwError('An error occurred while fetching data.');
    }));
  }
}


