import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { INavBarPage } from '../../interfaces/nav-bar-page';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  @Input() currentPage: INavBarPage;
  @Input() pages: INavBarPage[];

  @Output() loadPageEvent = new EventEmitter<string>();

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!simpleChanges.currentPage.firstChange && simpleChanges.currentPage.currentValue !== simpleChanges.currentPage.previousValue) {
      this.currentPage = this.pages.find(page => page.name === this.currentPage.name);
    }
  }

  loadPage(name: string) {
    this.loadPageEvent.emit(name);
  }

}
