import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { LearnerService } from '../learnerService.service';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import moment from 'moment';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LearnerEventFormComponent } from 'src/app/learner/learner-events/learner-event-form/learner-event-form.component';
import { EventModalMode } from 'src/app/shared/enums/event-modal-mode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LearnerPageComponent } from '../learner-page.component';
import { ActivatedRoute } from '@angular/router';
import { DATE_FORMAT, DATE_TIME_FORMAT, NO_DATA } from 'src/app/app.constants';
import { SitePermissions } from 'src/app/shared/enums/permissions';
import { UserTableService } from 'src/app/core/database/user-table.service';

@Injectable()

@Component({
  selector: 'app-learner-events',
  templateUrl: './learner-events.component.html',
  styleUrls: ['./learner-events.component.scss']
})
export class LearnerEventsComponent implements LearnerPageComponent {

  traineeId: string;
  staffId: number;
  pot: number;
  loadedEvents = false;
  errorLoadingEvents: string = null;
  eventToHighlight: number = null;
  eventModalMode = EventModalMode;
  allEventTypes: any[] = null;
  textExpand = false;
  sortByCreatedDate = false;

  eventsFormOptions: UntypedFormGroup;
  events: any[] = null;
  drafts: any[] = null;
  uniqueEventTypes = [];
  uniqueStaff = [];
  filteredEvents = [];
  filteredDraftEvents = [];
  dateRangeSelected: { startDate: moment.Moment, endDate: moment.Moment };
  permissions: {
    canEdit: boolean,
    canDelete: boolean,
    canCreate: boolean,
    canAudit: boolean
  } = { canEdit: false, canDelete: false, canCreate: false, canAudit: false };

  isLoadingEvents = false;

  dateFormat = DATE_FORMAT;
  dateTimeFormat = DATE_TIME_FORMAT;
  noData = NO_DATA;

  @ViewChild("dateRange") dateRangePicker;


  constructor(
    private learnerService: LearnerService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private cdf: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private userTableService: UserTableService,
  ) {
    this.buildForm();
    this.userTableService.get(1)
      .then(user => {
        if (!user) {
          this.router.navigate(['']);
        }
        this.permissions = {
          canEdit: user.permission.includes(SitePermissions.EventEdit),
          canDelete: user.permission.includes(SitePermissions.EventDelete),
          canCreate: user.permission.includes(SitePermissions.EventCreate),
          canAudit: user.permission.includes(SitePermissions.EventAudit),
        };
        this.cdf.detectChanges();
      });
  }

  buildForm() {
    this.eventsFormOptions = this.fb.group({
      eventTypeSelect: null,
      eventStaffSelect: null,
    });
  }

  public getData() {
    this.getLearnerEvents();
  }

  getLearnerEvents() {
    this.errorLoadingEvents = null;
    const subscription = this.learnerService.getLearnerEvents(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.drafts = response.drafts;
        this.events = response.events;
        if (response.eventTypes.success) {
          this.allEventTypes = response.eventTypes.data;
        } else {
          console.log('Error getting event types', response.eventTypes.errorMessage);
        }
      },
      error: (err) => {
        console.log('Events Error:', err.error);
        this.errorLoadingEvents = "There was an error fetching events, please reload and try again. If this error persists, please contact support.";
        this.loadedEvents = true;
        subscription.unsubscribe();
      },
      complete: () => {
        this.setFilters();
        this.filteredEvents = this.events;
        this.filteredDraftEvents = this.drafts;
        this.loadedEvents = true;
        this.checkForParamsEvent();
        this.cdf.detectChanges();
        subscription.unsubscribe();
      }
    });
  }

  // TODO: Add some proper error handling not just console.log the issue
  private checkForParamsEvent() {
    // If route has an event then we need to open up the dialog modal with the correct event
    this.route.queryParams.subscribe(params => {
      if (params['eventId'] !== undefined) {
        const eventId = parseInt(params['eventId']);
        if (isNaN(eventId)) {
          console.log('Invalid eventId:', params['eventId']);
          return;
        }
        const requestedEvent = this.events.find(element => element.eventId === eventId);
        const requestedDraftEvent = this.drafts.find(element => element.eventId === eventId);
        const event = requestedEvent === undefined ? requestedDraftEvent : requestedEvent;
        if (event === undefined) {
          console.log('No event found for eventId:', eventId);
          return;
        }
        this.openEventModal(EventModalMode.view, event);
      }
    });
  }

  setFilters() {
    this.uniqueEventTypes = Array.from(new Set(this.events?.map(ev => ev.eventType))).sort();
    this.uniqueStaff = Array.from(new Set(this.events?.map(ev => ev.staffName))).sort();
  }

  resetAllValues() {
    this.eventsFormOptions.controls.eventTypeSelect.reset();
    this.eventsFormOptions.controls.eventStaffSelect.reset();
    this.dateRangeSelected = null;
    this.dateRangePicker.nativeElement.value = '';
    this.filterEvents();
  }

  eventTypeCleared() {
    this.eventsFormOptions.controls.eventTypeSelect.reset();
    this.filterEvents();
  }

  staffSelectCleared() {
    this.eventsFormOptions.controls.eventStaffSelect.reset();
    this.filterEvents();
  }

  filterEvents() {
    this.filteredEvents = [...this.events ?? []];
    //currently not filtering draft events... but we could
    this.filteredDraftEvents = [...this.drafts ?? []];

    if (!(this.events?.length > 0)) {
      this.filteredEvents = [];
      return;
    }

    const eventType = this.eventsFormOptions.controls.eventTypeSelect.value;
    const staff = this.eventsFormOptions.controls.eventStaffSelect.value;
    const startDate = this.dateRangeSelected?.startDate?.toJSON();
    const endDate = this.dateRangeSelected?.endDate?.toJSON();

    if (eventType) {
      this.filteredEvents = this.filteredEvents.filter(e => e.eventType.includes(eventType));
    }
    if (staff) {
      this.filteredEvents = this.filteredEvents.filter(e => e.staffName.includes(staff));
    }
    if (startDate && endDate) {
      this.filteredEvents = this.filteredEvents.filter(e =>
        e.eventDate >= startDate
        && e.eventDate <= endDate
      );
    }
  }

  updateEventValues(values: any, event: any) {
    event.comments = values.comments;
    event.eventType = values.eventType;
    event.eventDate = values.eventDate;
    event.signedOffDate = values.signedOffDate;
  }

  moveEventToNewArray(event: any) {
    if (event.isDraft) {
      this.events = this.events.filter(e => e.eventId !== event.eventId);
      this.drafts.push(event);
    } else {
      this.drafts = this.drafts.filter(e => e.eventId !== event.eventId);
      this.events.push(event);
    }
  }

  sortEventsbyDate() {
    this.events.sort((a, b) => +new Date(b.eventDate) - +new Date(a.eventDate));
    this.drafts.sort((a, b) => +new Date(b.eventDate) - +new Date(a.eventDate));
  }

  newModal(mode: EventModalMode): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      allEventTypes: this.allEventTypes.filter(e => !e.archived),
      mode: mode,
    };

    return dialogConfig;
  }

  openEventModal(mode: EventModalMode, event: any = null) {
    const dialogConfig = this.newModal(mode);

    if (event !== null) {
      if (this.allEventTypes === null) {
        const eventType = { eventType: event.eventType, eventTypeId: event.eventTypeId, auditEvent: event.auditEvent };
        dialogConfig.data.allEventTypes = [eventType];
        dialogConfig.data.eventType = eventType;
      } else {
        dialogConfig.data.eventType = this.allEventTypes.find(e => e.eventTypeId === event.eventTypeId);
        if (!dialogConfig.data.allEventTypes.includes(dialogConfig.data.eventType)) {
          dialogConfig.data.allEventTypes.push(dialogConfig.data.eventType);
        }
      }
      dialogConfig.data.eventId = event.eventId;
      dialogConfig.data.eventDate = event.eventDate;
      dialogConfig.data.staffName = event.staffName;
      dialogConfig.data.comments = event.comments;
      dialogConfig.data.signedOffDate = event.signedOffDate;
      dialogConfig.data.isDraft = event.isDraft;
      dialogConfig.data.createdByUser = event.createdByUser;
      dialogConfig.data.created = event.created;
    }
    dialogConfig.data.permissions = this.permissions;

    const dialogRef = this.dialog.open(LearnerEventFormComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData === null) {
        return;
      }

      if (formData.delete) {
        this.deleteLearnerEvent(formData.eventId, formData.isDraft);
        return;
      }

      if (formData.value.eventId) {
        this.updateLearnerEvent(formData);
      } else {
        this.postLearnerEvent(formData);
      }

    });
  }

  postLearnerEvent(formData: any) {
    const eventData = {
      traineeId: this.traineeId,
      pot: this.pot,
      eventTypeId: formData.value.eventType.eventTypeId,
      eventDate: formData.value.eventDate,
      signedOffDate: formData.value.signedOffDate ?? null,
      Comments: formData.value.comments,
      isDraft: formData.value.isDraft ?? false
    };

    this.loadedEvents = false;
    this.eventToHighlight = null;
    this.cdf.detectChanges();

    const subscription = this.learnerService.PostLearnerEvent(eventData).subscribe({
      next: (response) => {
        const newEvent = response;
        this.eventToHighlight = newEvent.eventId;
        if (formData.value.isDraft) {
          this.drafts.push(newEvent);
        } else {
          this.events.push(newEvent);
        }
        this.sortEventsbyDate();
        this.setFilters();
        this.filterEvents();
      },
      error: (err) => {
        if (err === 'You do not have permission to complete the requested action.') {
          this.snackBar.open(err, 'Close', {
            duration: 3000,
          });
          console.log(err);
        } else {
          console.log('Post Event Error:', err.error);
          this.snackBar.open("There was an error adding the event. Please try again. If this error persists, please contact support. ", 'Close');
        }
        this.loadedEvents = true;
        this.cdf.detectChanges();
        subscription.unsubscribe();
      },
      complete: () => {
        this.loadedEvents = true;
        this.snackBar.open("Event Added.", 'Close', { duration: 3000 });
        this.cdf.detectChanges();
        console.log('Post Event Complete');
        subscription.unsubscribe();
      }
    });
  }

  updateLearnerEvent(formData: any) {
    const eventData = {
      eventTypeId: formData.value.eventType.eventTypeId,
      eventDate: formData.value.eventDate,
      signedOffDate: formData.value.signedOffDate ?? null,
      Comments: formData.value.comments,
      isDraft: formData.value.isDraft ?? true
    };

    this.loadedEvents = false;
    this.eventToHighlight = null;
    this.cdf.detectChanges();

    const subscription = this.learnerService.UpdateLearnerEvent(formData.value.eventId, eventData).subscribe({
      next: (response) => {
        this.eventToHighlight = response.eventId;
        const draftIndex = this.drafts.findIndex(e => e.eventId === response.eventId);
        const eventIndex = this.events.findIndex(e => e.eventId === response.eventId);

        if ((response.isDraft && draftIndex === -1 && eventIndex !== -1) || (response.isDraft === false && eventIndex === -1 && draftIndex !== -1)) {
          this.moveEventToNewArray(response);
        }
        else if (response.isDraft && draftIndex !== -1) {
          this.updateEventValues(response, this.drafts[draftIndex]);
        }
        else if (response.isDraft === false && eventIndex !== -1) {
          this.updateEventValues(response, this.events[eventIndex]);
        } else {
          // Failed to find event in either array. Reload page to get fresh data
          location.reload();
        }

        this.sortEventsbyDate();
        this.setFilters();
        this.filterEvents();
      },
      error: (err) => {
        if (err === 'You do not have permission to complete the requested action.') {
          this.snackBar.open(err, 'Close', {
            duration: 3000,
          });
          console.log(err);
        } else {
          console.log('Update Event Error:', err.error);
          this.snackBar.open("There was an error updating the event. Please try again. If this error persists, please contact support. ", 'Close');
        }
        this.loadedEvents = true;
        this.cdf.detectChanges();
        subscription.unsubscribe();
      },
      complete: () => {
        this.loadedEvents = true;
        this.snackBar.open("Event Updated.", 'Close', { duration: 3000 });
        this.cdf.detectChanges();
        subscription.unsubscribe();
      }
    });
  }

  deleteLearnerEvent(eventId: number, isDraft: boolean) {

    this.loadedEvents = false;
    this.cdf.detectChanges();

    const subscription = this.learnerService.DeleteLearnerEvent(eventId).subscribe({
      next: () => {
        if (isDraft) {
          this.drafts = this.drafts.filter(e => e.eventId !== eventId);
        } else {
          this.events = this.events.filter(e => e.eventId !== eventId);
        }

        this.setFilters();
        this.filterEvents();
      },
      error: (err) => {
        if (err === 'You do not have permission to complete the requested action.') {
          this.snackBar.open(err, 'Close', {
            duration: 3000,
          });
          console.log(err);
        } else {
          console.log('Delete Event Error:', err.error);
          this.snackBar.open("There was an error deleting the event. Please try again. If this error persists, please contact support. ", 'Close');
        }
        this.loadedEvents = true;
        this.cdf.detectChanges();
        subscription.unsubscribe();
      },
      complete: () => {
        this.loadedEvents = true;
        this.snackBar.open("Event Deleted.", 'Close', { duration: 3000 });
        this.cdf.detectChanges();
        subscription.unsubscribe();
      }
    });
  }

  toggleTextExpand() {
    this.textExpand = !this.textExpand;
  }

}
