import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-report-card-review-compliance',
  templateUrl: './report-card-review-compliance.component.html',
  styleUrls: ['./report-card-review-compliance.component.scss']
})
export class ReportCardReviewComplianceComponent {

@HostBinding('class') class = 'active-widget';
constructor(private changeDetectorRef:ChangeDetectorRef) { }

categories: string[] = [];
dataSource: [{}] = [{}];
highcharts = Highcharts;
chartOptions = {
   chart: {
      type: "spline",
      height: 500
   },
   title: {
      text: "Review Compliance over the last 12 months"
   },
   xAxis:{
      categories: this.categories
   },
   yAxis: {
      title:{
         text:"Compliance"
      }
   },
   tooltip: {
      valueSuffix:"%"
   },
   series: this.dataSource
};
  updateFlag: boolean = false;

setData(data){
    this.dataSource = data;
    let dataArray = [];
    let categoryArray = []
    for (let x in this.dataSource) {
      let month = this.dataSource[x]['month']
      dataArray.push(this.dataSource[x]['percentage'])
      categoryArray.push(month);
    }
    let obj = {'name': 'Compliance', 'data': dataArray}

    this.categories = categoryArray.sort((n1,n2) => n1 - n2).map(String);
    this.chartOptions.xAxis.categories = this.categories;

    this.chartOptions.series = [obj];
    this.updateFlag = true;
    this.changeDetectorRef.detectChanges();
  };

}

