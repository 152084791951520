import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportListService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    const options = { headers: headers };
    return options;
  }

  getReportList(staffId: number) {
    return this.http.get(`${environment.API_URL}GetReportList/${staffId}`, this.getOptionsWithAccessToken());
  }

  getReportFilter(reportId) {
    const data = { 'reportId': reportId }
    return this.http.post(`${environment.API_URL}GetReportFilter`, data, this.getOptionsWithAccessToken());
  }

}
