<div>
  <header>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div class="form-title">
        <h1 mat-dialog-title *ngIf="this.mode === eventModalMode.view; else modify">
          {{ this.event.type.name }}
          <span *ngIf="!this.event.isBranch" class="root-badge" matTooltip="Root Employer Event">
            Root
          </span>
        </h1>

        <ng-template #modify>
          <h1 mat-dialog-title>{{ (this.mode === eventModalMode.create ? 'Create ' : 'Edit ') + 'Event' }}</h1>
        </ng-template>
      </div>

      <div style="display: flex; flex-direction: row; gap: 10px">
        <mat-icon *ngIf="this.permissions.canDelete && this.mode !== this.eventModalMode.create" (click)="this.delete()" class="icon delete">
          delete_forever
        </mat-icon>
        <mat-icon *ngIf="this.permissions.canEdit && this.mode === this.eventModalMode.view" (click)="this.changeEventMode(this.eventModalMode.edit)"
          class="icon edit">
          edit
        </mat-icon>
      </div>
    </div>
    <mat-divider style="margin: 5px 0; width: 100%"></mat-divider>
  </header>

  <mat-dialog-content style="padding: 0 55px">

    <!-- View -->
    <div *ngIf="this.mode === this.eventModalMode.view; else showEventForm" class="view-content">
      <div class="view-key-details">
        <div class="view-key-detail">
          <mat-icon class="key-icon">person</mat-icon>
          <div>
            {{this.event.assignee.name}}
          </div>
        </div>
        <div class="view-key-detail">
          <mat-icon class="key-icon">access_time_filled</mat-icon>
          <div>
            {{
              this.event.actualDate !== null ? (this.event.actualDate | date: this.dateFormat)
              : (this.event.plannedDate | date: this.dateFormat)
            }}
          </div>
          <div *ngIf="this.event.startTime !== null">
           {{ this.event.startTime}} - {{this.event.endTime}}
          </div>
          <div *ngIf="this.event.actualDate === null">
            Planned
          </div>
        </div>
      </div>

      <div class="view-notes">
        <p>
          {{ this.event.notes !== null ? this.event.notes : 'No notes'}}
        </p>
      </div>
    </div>

    <!-- Modify -->
    <ng-template #showEventForm>
      <form [formGroup]="eventsForm" novalidate class="form-content">


        <div class="form-row gap" style="align-items: flex-start;">
          <!-- Type -->
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-label>Event Type</mat-label>
            <mat-select required focused="false"
              formControlName="type"
              panelClass="matSelectPanelBelow"
              [disableOptionCentering]="true"
              (selectionChange)="this.onTypeChange()"
            >
            <div style="padding: 10px 0">
              <mat-option *ngIf="this.eventOptions?.types === null; else showTypes" disabled style="color: #ff5722;">
                Error loading event types. Please reload page, if this persists contact support.
              </mat-option>

              <!-- List all filtered events -->
              <ng-template #showTypes>
                <div style="display: flex;">
                  <input #searchInput type="text" class="type-search" [(ngModel)]="this.typeSearchFilter" [ngModelOptions]="{standalone: true}" placeholder="Search..."/>
                </div>
                <mat-divider style="margin-bottom: 8px;"></mat-divider>

                <div *ngIf="this.filteredEventTypes?.length > 0; else noTypes" style="overflow: auto; max-height: 185px">
                  <mat-option *ngFor="let event of this.filteredEventTypes" [value]="event">
                    {{ event.name }}
                  </mat-option>
                </div>

                <ng-template #noTypes>
                  <mat-option disabled>No event types found.</mat-option>
                </ng-template>

              </ng-template>
            </div>
            </mat-select>
            <mat-error *ngIf="this.eventsForm.controls.type.errors">
              A type is required
            </mat-error>
          </mat-form-field>

          <!-- Root Event -->
          <div class="toggle-field">
            <mat-slide-toggle formControlName="rootEvent">
              Root Event
            </mat-slide-toggle>
          </div>
        </div>

        <!-- Assignee -->
        <mat-form-field style="width: 100%;" appearance="outline">
          <mat-label>Assignee</mat-label>
          <mat-select required focused="false"
            formControlName="assignee"
            panelClass="matSelectPanelBelow"
            [disableOptionCentering]="true"
          >
          <div style="padding: 10px 0">
            <mat-option *ngIf="this.eventOptions?.staff === null; else showStaff" disabled style="color: #ff5722;">
              Error loading event types. Please reload page, if this persists contact support.
            </mat-option>

            <!-- List all filtered events -->
            <ng-template #showStaff>
              <div style="display: flex;">
                <input #searchInput type="text" class="type-search" [(ngModel)]="this.staffSearchFilter" [ngModelOptions]="{standalone: true}" placeholder="Search..."/>
              </div>
              <mat-divider style="margin-bottom: 8px;"></mat-divider>

              <div *ngIf="this.filteredEventStaff?.length > 0; else noStaff" style="overflow: auto; max-height: 185px">
                <mat-option *ngFor="let staff of this.filteredEventStaff" [value]="staff">
                  {{ staff.name }}
                </mat-option>
              </div>

              <ng-template #noStaff>
                <mat-option disabled>No staff found.</mat-option>
              </ng-template>

            </ng-template>
          </div>
          </mat-select>
          <mat-error *ngIf="this.eventsForm.controls.assignee.errors">
            A assignee is required
          </mat-error>
        </mat-form-field>

        <!-- Dates -->
        <div class="form-row space-between">
          <!-- Date Planned -->
        <mat-form-field *ngIf="this.isPlannedEvent()" appearance="outline">
            <mat-label>Date Planned*</mat-label>
            <input matInput readonly style="cursor: default;"
              [matDatepicker]="pickerPlanned"
              formControlName="plannedDate"
            >
            <mat-datepicker-toggle matSuffix [for]="pickerPlanned"></mat-datepicker-toggle>
            <mat-datepicker #pickerPlanned></mat-datepicker>
            <mat-error *ngIf="this.eventsForm.controls.plannedDate.errors">A date is required</mat-error>
          </mat-form-field>

          <!-- Date Actual -->
          <mat-form-field appearance="outline">
            <mat-label>Date{{this.isPlannedEvent() ? ' Actual' : ''}}*</mat-label>
            <input matInput readonly style="cursor: default;"
              [matDatepicker]="pickerActual"
              formControlName="actualDate"
            >
            <mat-datepicker-toggle matSuffix [for]="pickerActual"></mat-datepicker-toggle>
            <mat-datepicker #pickerActual></mat-datepicker>
            <mat-error *ngIf="this.eventsForm.controls.actualDate.errors">A date is required</mat-error>
          </mat-form-field>
        </div>

        <!-- Outlook Event -->
        <div *ngIf="this.isPlannedEvent()" class="toggle-field" [style.paddingBottom.px]="this.eventsForm.controls.outlookEvent.value ? '10' : '16'" style="margin-bottom: 25px;">
          <div style="display: flex; flex-direction: column; gap: 20px">

            <mat-slide-toggle formControlName="outlookEvent" (change)="this.onOutlookEventChange()">
              Outlook Event
            </mat-slide-toggle>

            <!-- Times -->
            <div *ngIf="this.eventsForm.controls.outlookEvent.value" class="form-row" style="justify-content: space-around;">
              <mat-form-field appearance="outline">
                <mat-label>Start time</mat-label>
                <mat-select focused="false"
                  formControlName="startTime"
                  panelClass="matSelectPanelBelow"
                  [disableOptionCentering]="true"
                  (selectionChange)="this.setEndTimes($event.value)"
                >
                  <mat-option *ngFor="let time of this.startTimes" [value]="time">
                    {{ time }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>End time</mat-label>
                <mat-select focused="false"
                  formControlName="endTime"
                  panelClass="matSelectPanelBelow"
                  [disableOptionCentering]="true"
                  [disabled]="this.eventsForm.controls.startTime.value === null"
                >
                  <mat-option *ngFor="let time of this.endTimes" [value]="time.split(' ')[0]">
                    {{ time }}
                  </mat-option>
                </mat-select>
                <div *ngIf="this.eventsForm.hasError('bothControlsRequired')"
                  style="position: absolute; top: 55px; font-size: 12px; left: 0px; color: #e06767;"
                >
                  An end time is required.
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Notes -->
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes" cdkAutosizeMinRows="10" style="min-height: 150px; resize: none;"></textarea>
        </mat-form-field>

      </form>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <mat-divider style="margin-bottom: 8px; width: 100%"></mat-divider>

    <div *ngIf="this.mode === this.eventModalMode.view; else modifyActions" style="display: flex; justify-content: space-between; width: 100%; align-items: flex-end">
      <div class="updated-info">
        Updated: <span style="font-style: italic;">{{ event.editedDate | date: this.dateFormat }}</span> &nbsp;by <span style="font-style: italic;">{{ event.editedBy }}</span>
      </div>
      <button mat-flat-button color="primary" (click)="close()">Close</button>
    </div>

    <ng-template #modifyActions>
      <div style="display: flex; justify-content: end; width: 100%;">
        <button mat-button (click)="close()">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="!this.eventsForm.valid || this.saving|| !this.eventsForm.dirty" type="submit" (click)="this.onSubmit()">Save</button>
      </div>
    </ng-template>
    <div style="height: 4px; width: 100%;">
      <mat-progress-bar *ngIf="this.saving" mode="indeterminate" [color]="'primary'"></mat-progress-bar>
    </div>
  </mat-dialog-actions>
</div>
