import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-your-event-info-dialog',
  templateUrl: './your-event-info-dialog.component.html',
  styleUrls: ['./your-event-info-dialog.component.scss']
})
export class YourEventInfoDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<YourEventInfoDialogComponent>) { }

  dateFormat = DATE_FORMAT;

  ngOnInit(): void {
  }


  goToEdit(){
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
