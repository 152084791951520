import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { concat } from 'rxjs';
import { UserTableService } from '../../../app/core/database/user-table.service';
import { PermissionsRequestFormComponent } from '../permissions-request-form/permissions-request-form.component';
import { PhoneRecordFormService } from './phone-record-form.service';
// PhoneRecordFormComponentimport { IPhoneListRecord } from 'src/app/shared/interfaces/phone-list-record';

@Component({
  selector: 'app-phone-record-form',
  templateUrl: './phone-record-form.component.html',
  styleUrls: ['./phone-record-form.component.scss']
})
export class PhoneRecordFormComponent implements OnInit {
  canSignOthersIn: boolean;
  buttonText: string;

  constructor(@Inject(MAT_DIALOG_DATA)
    public data: any, private dialog: MatDialog,
    private userTableService: UserTableService,
    private snackBar: MatSnackBar,
    private phoneRecordFormService: PhoneRecordFormService,
    private dialogRef: MatDialogRef<PhoneRecordFormComponent>) { }

  ngOnInit(): void {
    //console.log(this.data);
    this.checkRequestingUserPermissions();
    this.updateButtonText();
  }

  editPermission(permission){
  }

  requestPermission(staffName){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          'staffName': staffName    
        }
      this.dialog.open(PermissionsRequestFormComponent, dialogConfig);
  }

  updateButtonText() {
    if (this.data.staffData.currentLocation == 'Signed Out') {
      this.buttonText = "Sign in to OPH";
    } else {
      this.buttonText = "Sign Out";
    }
  }

  checkRequestingUserPermissions() {
    this.userTableService.get(1)
    .then(result => {
      this.canSignOthersIn = result.department == 'Reception' ? true : false;
    })
  }

  signInOrOut(){
    console.log('about to run', this.data.staffData.staffId);
    this.phoneRecordFormService.signInOrOut(this.data.staffData.staffId).subscribe((response) => {
      console.log('response', response);
      let snackBarMessage = "Staff member successfully updated" ;
      this.snackBar.open(snackBarMessage,
        'Success', { duration: 2000 });
      this.dialogRef.close(1);  
    });
  }

}
