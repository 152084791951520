import { Component, Input, Output, EventEmitter } from '@angular/core';
import {Location} from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-learner-key-info',
  templateUrl: './learner-key-info.component.html',
  styleUrls: ['./learner-key-info.component.scss']
})
export class LearnerKeyInfoComponent {
  @Input() level: string;
  @Input() programme: string;
  @Input() sector: string;
  @Input() scheme: string;
  @Input() learnerName: string;
  @Input() welsh = false;
  @Input() potStatus: string;
  @Input() isFavorite = false;
  @Input() waitingForFavoriteToggle = false;
  @Input() backUrl: string;
  @Input() backParams: any;

  @Output() toggleFavoriteEvent = new EventEmitter<boolean>();


  constructor(
    private location: Location,
    private router: Router
  ) { }

  toggleFavorite() {
    this.toggleFavoriteEvent.emit(!this.isFavorite);
  }

  navigateBack() {
    if(typeof this.backUrl === 'undefined') {
      this.location.back();
      return;
    }

    const navigationExtras : NavigationExtras = {
      queryParams: this.backParams
    };

    this.router.navigate([this.backUrl], navigationExtras);
  }

}
