import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SendMessageService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken(params: HttpParams) {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);

    let options = { headers: headers, params: params };
    return options;
  }

  sendEmail(messageData: any) {
    const url = `${environment.API_URL}SendHtmlEmail`;

    // TODO: remove this line - for testing only
    // let x = messageData.recipientEmail.toString();
    // if (! x.includes('teams.ms')) {
    //   messageData.recipientEmail = messageData.senderEmail;
    // }

    const params = new HttpParams()
      .set('recipientEmail', messageData.recipientEmail)
      .set('senderEmail', messageData.senderEmail)
      .set('subject', messageData.subject)
      .set('body', messageData.body);

    const options = this.getOptionsWithAccessToken(params);

    return this.http.get(url, options);
  }
}
