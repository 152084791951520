import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DataListComponent } from '../data-list/data-list.component';

@Component({
  selector: 'app-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss']
})
export class DataCardComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @Input() title: string;
  @Input() stat: number;
  @Input() data: any;

  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  detectChanges() {
    this.cdr.detectChanges();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.data;
    const dialogRef = this.dialog.open(DataListComponent, dialogConfig);
  }
}
