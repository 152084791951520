import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit, Input, ChangeDetectorRef, ViewChild, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { SaveReportFiltersDialogComponentV2 } from "../save-report-filters-dialog/save-report-filters-dialog.component";
import { StringHelper } from "src/app/shared/string-helper";
import { UserService } from "src/app/core/services/user.service";
import { FavouriteTableService } from "src/app/core/database/favourite-table.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReportStoredFilterHelperService } from "src/app/reporting/report-stored-filter-helper.service";
import { IAdditionalFilter, IDefaultColumns, ReportType } from "../classic-report/classic-report.component";
import { FormControl, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ISummaryGroupBy, ITableFilter } from "src/app/shared/interfaces/generic-interfaces";
import { ShowSavedReportFiltersDialogComponentV2 } from "../show-saved-report-filters-dialog/show-saved-report-filters-dialog.component";
import { startWith, pairwise, merge, map, switchMap, catchError, of } from "rxjs";
import moment from "moment";
import { Sidebar } from "ng-sidebar";
import { UserTableService } from "src/app/core/database/user-table.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DrillDownReportService } from "./drill-down-report.service";
import { MatTableDataSource } from "@angular/material/table";
import { SharedFunctionsService } from "src/app/core/services/shared-functions.service";
import { DATE_FORMAT_MOMENT, DATE_TIME_FORMAT_MOMENT } from "src/app/app.constants";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ShareReportFiltersDialogComponentV2 } from "../share-report-filters-dialog/share-report-filters-dialog.component";


interface DrillDownGroup {
  name: string;
  image: string;
  dateFilterTypes: [];
  levels: DrillDownLevel[];
}

interface DrillDownLevel {
  level: number;
  name: string;
  idColumn: string;
  hiddenColumns?: string[];
  defaultColumns: IDefaultColumns[];
  defaultColumnOrder?: IDefaultColumns[];
}

@Component({
  selector: 'drill-down-report',
  templateUrl: './drill-down-report.component.html',
  styleUrls: ['./drill-down-report.component.scss', '../classic-report/classic-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrillDownReportComponent implements OnInit, AfterViewInit {
  @ViewChild('dateRange') dateRangePicker;
  @ViewChild("ngSidebar") ngSidebar: Sidebar;
  @ViewChild('tablePaginator', { static: true }) tablePaginator: MatPaginator;
  @ViewChild('tableSort', { static: true }) tableSort: MatSort;
  @ViewChild("sharedFilters") sharedFiltersChild;

  //Emitters
  refreshData = new EventEmitter<void>()
  setFilterEmitter = new EventEmitter<string>();
  groupingChangeEmitter = new EventEmitter<void>();
  levelChangeEmitter = new EventEmitter<void>();

  //Inputs
  @Input() report: ReportVariant;
  @Input() reportName: string;
  @Input() drillDownGroups: DrillDownGroup[];
  @Input() hasClassicFilters = true;
  @Input() activeLearnerToggle = false;
  @Input() matSortActive = '';
  @Input() matSortDirection = '';
  @Input() tableCellStyle: (column: string, value: any, row: any) => object = () => { return {} };

  //General Vars
  staffId: number;
  showSpinner = false;
  defaultDisplayColumns: IDefaultColumns[];
  defaultColumnOrder: IDefaultColumns[] = new Array<IDefaultColumns>();
  selectedDrillDownGroup: DrillDownGroup;
  selectedLevel: DrillDownLevel;
  selectedColumnOrder: IDefaultColumns;
  lastColumnSelection: string[];
  selectAllColumns = false;
  allColumns: string[] = [];
  displayedColumns: string[];
  ranges: object = {}
  dateFilterTypes: string[] = [];
  multipleDefaultDisplayColumns = false;
  //Sidebar Vars
  sidebarMode: string = window.innerWidth <= 1200 ? 'over' : 'push';
  sidebarOpened = true;
  waitingForToggle = false;
  isFavouriteReport: { status: boolean, data: { Id: number; key: string } } = { status: false, data: { Id: null, key: null } };
  dateTypeChange = new FormControl();

  //Filter Vars
  storedFilters = [];
  columnFormControl: UntypedFormControl;
  groupByColumn: ISummaryGroupBy = { value: "", columns: [], pieChartDrillDown: "", pieChartShareValue: "", title: "" };
  selectedDefaultDisplayColumns: IDefaultColumns;
  selectedDateRange: { startDate: moment.Moment, endDate: moment.Moment } = null;
  dateFilterTypeSelected: string;
  additionalFilters: IAdditionalFilter[] = [];
  allFilterOptionsRaw: { [key: string]: any[] } = {};
  additionalFilterGroup = new UntypedFormGroup({});
  filter: ITableFilter[] = [];
  savedFilterToApply;
  savedReportFilterName: string;
  showReportFilters = true;
  errorCustomShow = false;
  errorCustomText = '';
  loadingFilters = false;
  loadingCustomFilters = false;
  showOptions = true;
  showClassicFilters = true;
  errorOptionsShow = false;
  errorOptionsText = '';
  errorClassicShow = false;
  errorClassicText = '';
  assessorOptions = [];
  centreOptions = [];
  contractOptions = [];
  programmeTypeOptions = [];
  schemeOptions = [];
  sectorOptions = [];
  subcontractorOptions = [];
  tableDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  dateFormat = DATE_FORMAT_MOMENT;
  dateTimeFormat = DATE_TIME_FORMAT_MOMENT;
  errorText = '';
  totalData: number;
  pageOptions = [this.sharedFunctions.calcOptimumRows(), 25, 50, 100];
  sortString: string = null;
  filterString = "";
  drillDownFilter = "";
  drillDownBreadCrumb: { levelId: number; name: string, id: number, columnId: string }[] = [];
  reportSavedFilter;

  constructor(
    public stringHelper: StringHelper,
    private dialog: MatDialog,
    private userService: UserService,
    private userTableService: UserTableService,
    private favouriteTableService: FavouriteTableService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private reportStoredFilterHelperService: ReportStoredFilterHelperService,
    private router: Router,
    private drillDownReportService: DrillDownReportService,
    private sharedFunctions: SharedFunctionsService,
    private route: ActivatedRoute
  ) {
    this.userTableService.get(1).then(user => {
      if (!user || user === undefined || user === null) {
        this.router.navigate[''];
      }
      else {
        this.staffId = user.staffId;
        this.setupTableData();
      }
    });

  }

  ngOnInit(): void {
    this.selectedDrillDownGroup = this.drillDownGroups[0];
    this.selectedLevel = this.selectedDrillDownGroup.levels[0];
    this.drillDownBreadCrumb.push({ levelId: this.selectedLevel.level, name: this.selectedLevel.name, id: 0, columnId: this.selectedLevel.idColumn });
    this.setColumns();
    this.sidebarMode = 'push';
  }

  ngAfterViewInit(): void {
    this.userTableService.get(1).then(user => {
      if (!user || user === undefined || user === null) {
        this.router.navigate[''];
      }
      else {
        this.staffId = user.staffId;
        this.getAllSavedFiltersFromLocalDb();
        this.setupCommonFilters();
        this.setupCustomFilters();
        this.setupDateTypeChange();
        this.setupDatePicker();
        this.cdr.detectChanges();
      }
    });
  }

  setColumns() {
    this.defaultDisplayColumns = this.selectedDrillDownGroup.levels[this.selectedLevel.level].defaultColumns;
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns[0];
    this.defaultColumnOrder = this.selectedDrillDownGroup.levels[this.selectedLevel.level].defaultColumnOrder ?? this.selectedDrillDownGroup.levels[this.selectedLevel.level].defaultColumns;
    this.selectedColumnOrder = this.defaultColumnOrder[0];
    this.dateFilterTypes = this.selectedDrillDownGroup.dateFilterTypes;
    this.displayedColumns = this.orderColumns([...this.selectedDefaultDisplayColumns.columns]);
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.columnFormControl = new UntypedFormControl(this.displayedColumns);
    this.dateFilterTypeSelected = this.dateFilterTypes[0];
    this.showSpinner = true;
    this.tableDataSource.paginator = this.tablePaginator;
    this.sortString = `${this.displayedColumns[0]}::asc`;
    this.tableDataSource.sort = this.tableSort;
    if (this.defaultDisplayColumns.length > 1) {
      this.multipleDefaultDisplayColumns = true;
    }
  }

  //#region Data
  getFilterFromUrl() {
    console.dir(this.route.data);
    this.route.data.forEach(
      result => {
        this.reportSavedFilter = result.reportFilter;
        if (this.reportSavedFilter) {
          this.savedFilterToApply = this.reportSavedFilter.filters;
          this.savedReportFilterName = this.reportSavedFilter.name;
          this.loadSavedFilters();
          this.snackBar.open("Filter applied: " + this.reportSavedFilter.name,
            'Close', { duration: 5000 });
        }
      }
    );
    this.cdr.detectChanges();
  }

  columnFormatter(column, value): string {
    if (column === 'level')
      if (this.sharedFunctions.isDateFromColumnName(value, column)) {
        return moment(value).format(this.dateFormat);
      }
    if (this.sharedFunctions.isDateTimeFromColumnName(value, column)) {
      return moment(value).format(this.dateTimeFormat);
    }
    if (typeof (value) === 'number')
      return value.toString();
    return this.stringHelper.formatForEmpty(value);
  }

  setupCommonFilters() {
    this.loadingFilters = true;
    merge(this.groupingChangeEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.drillDownReportService
            .getCommonFilterData(this.report, this.staffId)
            .pipe(
              catchError((error) => {
                this.errorOptionsShow = true;
                this.errorOptionsText = error;
                this.cdr.detectChanges();
                return of(null);
              })
            );
        }),
        map((data) => {
          return data;
        })
      )
      .subscribe((data: any/*change this to the filter value*/) => {
        this.allFilterOptionsRaw = data;
        this.centreOptions = data.centreFilters;
        this.contractOptions = data.contractFilters;
        this.programmeTypeOptions = data.programmeTypeFilters;
        this.schemeOptions = data.schemeFilters;
        this.sectorOptions = data.sectorFilters;
        this.subcontractorOptions = data.subcontractorFilters;
        this.assessorOptions = data.assessorFilters;
        this.loadingFilters = false;
        this.cdr.detectChanges();
        this.getFilterFromUrl();
      });
  }

  setupCustomFilters() {
    this.loadingCustomFilters = true;
    merge(this.groupingChangeEmitter, this.levelChangeEmitter)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.drillDownReportService
            .getCustomFilterData(this.report, this.staffId, this.selectedLevel.level, this.selectedDrillDownGroup.name)
            .pipe(
              catchError((error) => {
                this.errorOptionsShow = true;
                this.errorOptionsText = error;
                this.cdr.detectChanges();
                return of(null);
              })
            );
        }),
        map((data) => {
          return data;
        })
      )
      .subscribe((data: any/*change this to the filter value*/) => {
        this.additionalFilters = [];
        Object.keys(this.additionalFilterGroup.controls).forEach(key => {
          this.additionalFilterGroup.removeControl(key);
        });
        data?.forEach(filter => {
          this.additionalFilters.push({
            Type: filter.type,
            Options: [...filter.options]
          });
          this.additionalFilterGroup.addControl(filter.type, new UntypedFormControl(null));
        });
        this.loadingCustomFilters = false;
        this.additionalFilterGroup.updateValueAndValidity();
        this.cdr.detectChanges();
      });
  }

  setupTableData() {
    merge(
      this.refreshData,
      this.setFilterEmitter,
      this.levelChangeEmitter,
      this.groupingChangeEmitter,
      this.tableSort.sortChange,
      this.tablePaginator.page).pipe(
        startWith({}),
        switchMap((event) => {
          if (typeof event === 'string') {
            this.filterString = event;
          }
          this.drillDownBreadCrumb.forEach((element) => {
            if (element.id !== 0) {
              this.drillDownFilter += `${element.columnId}::${element.id};`;
            }
          });
          this.showSpinner = true;
          return this.drillDownReportService
            .getReportDate(
              this.report,
              this.staffId,
              this.selectedLevel.level,
              this.tablePaginator.pageIndex + 1,
              this.tablePaginator.pageSize,
              this.stringHelper.notNullOrEmpty(this.filterString) ? this.filterString + ";" + this.drillDownFilter : this.drillDownFilter,
              this.tableSort.active,
              this.tableSort.direction,
              this.selectedDrillDownGroup.name
            ).pipe(
              catchError((val) => {
                this.showSpinner = false;
                this.errorText = val;
                this.cdr.detectChanges();
                return of(
                  {
                    totalRecords: 0,
                    data: []
                  }
                );
              }));
        }),
        map((data: any) => {
          this.totalData = data.totalRecords;
          return data.data;
        })
      ).subscribe((data) => {
        if (data.length !== 0) {
          this.allColumns = Object.keys(data[0]).sort().filter(column => this.selectedLevel.hiddenColumns.indexOf(column) === -1);
          this.allColumns.unshift('Select All');
        }
        this.tableDataSource = new MatTableDataSource(data);
        this.showSpinner = false;
        this.cdr.detectChanges();
      });
  }

  drillDown(id) {
    if (this.selectedLevel.level === this.selectedDrillDownGroup.levels.length - 1)
      return;
    this.selectedLevel = this.selectedDrillDownGroup.levels[this.selectedLevel.level + 1];
    this.drillDownBreadCrumb.push({ levelId: this.selectedLevel.level, name: this.selectedLevel.name, id: 0, columnId: this.selectedLevel.idColumn });
    this.drillDownBreadCrumb = this.drillDownBreadCrumb.map((element) => {
      if (element.levelId !== this.selectedLevel.level && element.id === 0) {
        return { ...element, id: id }
      }
      return element;
    });
    this.setColumns();
    this.drillDownFilter = "";
    this.totalData = 0;
    this.tableDataSource.paginator.firstPage();
    this.matSortActive = "";
    this.matSortDirection = "";
    this.tableDataSource = new MatTableDataSource([]);
    this.levelChangeEmitter.emit();
  }
  selectLevel(id) {
    this.selectedLevel = this.selectedDrillDownGroup.levels[id];
    this.drillDownBreadCrumb = this.drillDownBreadCrumb.filter((element) => {
      if (element.levelId <= this.selectedLevel.level) {
        return { ...element }
      }
    });
    this.drillDownBreadCrumb = this.drillDownBreadCrumb.map((element) => {
      if (element.levelId === this.selectedLevel.level) {
        return { ...element, id: 0 }
      }
      return element;
    });
    console.dir(this.drillDownBreadCrumb);
    this.setColumns();
    this.drillDownFilter = "";
    this.totalData = 0;
    this.tableDataSource.paginator.firstPage();
    this.tableDataSource = new MatTableDataSource([]);
    this.levelChangeEmitter.emit();
  }
  //#endregion
  //#region
  back() {
    this.router.navigate(['reports/index']);
  }

  changeGroupType($event) {
    this.drillDownBreadCrumb = [];
    this.selectedDrillDownGroup = this.drillDownGroups.find(x => x.name === $event.value.name);
    this.selectedLevel = this.selectedDrillDownGroup.levels[0];
    this.drillDownBreadCrumb.push({ levelId: this.selectedLevel.level, name: this.selectedLevel.name, id: 0, columnId: this.selectedLevel.idColumn });
    this.drillDownFilter = "";
    this.setColumns();
    this.totalData = 0;
    this.tableDataSource.paginator.firstPage();
    this.tableDataSource = new MatTableDataSource([]);
    this.groupingChangeEmitter.emit();
  }

  getAllSavedFiltersFromLocalDb() {
    this.favouriteTableService
      .getAll()
      .then(response => {
        response.forEach(item => {
          if (item.type === 'filter' && item.reportId === this.report) {
            this.storedFilters.push(item);
          } else if (item.type === 'report' && item.reportId === this.report) {
            this.isFavouriteReport.status = true;
            this.isFavouriteReport.data.Id = item.id;
            this.isFavouriteReport.data.key = `report:${item.id}`;
          }
        });
      });
  }

  toggleReportFavorite() {
    this.waitingForToggle = true;
    this.isFavouriteReport.status = !this.isFavouriteReport.status;
    if (this.isFavouriteReport.status) {
      this.storeFavourite({ isSaveFilters: false });
    }
    else {
      const data = { "type": 'report', "Id": this.isFavouriteReport.data.Id, "staffId": this.staffId };
      this.userService.removeFavourite(data).subscribe(response => {
        if (response) {
          this.favouriteTableService.remove(this.isFavouriteReport.data.key);
          this.waitingForToggle = false;
          this.snackBar.open("Removed as a Favourite",
            'OK', { duration: 2000 });
        }
      });
    }
  }
  //#endregion

  //#region Setup
  setupDatePicker() {
    const dayOfYear = moment.utc().dayOfYear();
    const firstContractDay = moment();
    const lastContractDay = moment();
    const previousfirstContractDay = moment()
    const previouslastContractDay = moment()
    if (dayOfYear < 213) {
      firstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 }) // moment months start counting at 0!
      lastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 2, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year() - 1, 'month': 6, 'date': 31 })
    } else {
      firstContractDay.set({ 'year': moment().year(), 'month': 7, 'date': 1 })
      lastContractDay.set({ 'year': moment().year() + 1, 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
    }

    this.ranges = {
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'This Contract Year': [firstContractDay, lastContractDay],
      'Last Contract Year': [previousfirstContractDay, previouslastContractDay]
    }
  }

  setupDateTypeChange() {
    this.dateTypeChange.valueChanges.pipe(
      startWith(this.dateTypeChange.value),
      pairwise()
    ).subscribe(
      ([old, value]) => {
        this.resetDatePicker();
        this.setFilter({ type: old, value: '' });
      }
    );
  }
  //#endregion

  //#region Sidebar
  setDefaultColumn($event) {
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns.find(x => x.type === $event.value);
    this.selectedColumnOrder = this.defaultColumnOrder.find(x => x.type === $event.value);
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.displayedColumns = this.orderColumns(this.lastColumnSelection);
    this.columnFormControl.setValue(this.displayedColumns);
    this.resetSelectAll();
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  resetDatePicker() {
    this.selectedDateRange = null;
  }
  resetSelectAll() {
    if (this.selectAllColumns) {
      this.allColumns[this.allColumns.indexOf('Undo Select All')] = 'Select All';
      this.selectAllColumns = false;
    }
  }
  resetAllFilters() {
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns[0];
    this.selectedColumnOrder = this.defaultColumnOrder[0];
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.columnFormControl.setValue([...this.selectedDefaultDisplayColumns.columns]);
    this.resetSelectAll();
    this.dateRangePicker.nativeElement.value = '';
    this.dateFilterTypeSelected = "";
  }

  clearSidebar() {
    this.sharedFiltersChild.reset();
    this.filter = [];
    this.resetDatePicker();
    this.resetAllFilters();
    this.additionalFilterGroup.reset();
  }

  checkSaveFiltersAndStore() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '300px';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(SaveReportFiltersDialogComponentV2, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      this.storeFavourite(formData);
    });
  }

  storeFavourite(rawData) {
    const reportTitle = this.stringHelper.unCamelCase(ReportVariant[this.report]);
    if (rawData) {
      if (rawData.isSaveFilters) {
        const fullFilter = this.getFullFilterAsString();
        const saveData = { "filter": fullFilter, "reportId": this.report, "staffId": this.staffId, "name": rawData.name }
        this.userService.postFavouriteReportWithFilter(saveData).subscribe(filterId => {
          this.favouriteTableService.add({
            id: filterId,
            title: reportTitle,
            link: '',
            tags: '',
            filter: fullFilter,
            name: rawData.name,
            filterId: filterId,
            reportId: this.report
          }, filterId)
            .then(() => {
              this.storedFilters.push({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: rawData.name, filterId: filterId, reportId: this.report });
              this.snackBar.open('Saved: ' + rawData.name, 'Close', { duration: 2000 });
              this.cdr.detectChanges();
            })
        })
      }
      else {
        const data = { "reportId": this.report, "staffId": this.staffId };
        this.userService.addReportToFavourites(data).subscribe(id => {
          if (id) {
            this.favouriteTableService.add({
              type: 'report',
              id: id,
              title: reportTitle,
              link: '',
              tags: '',
              filter: '',
              name: '',
              reportId: this.report
            }, `report:${id}`)
              .then(() => {
                this.waitingForToggle = false;
                this.isFavouriteReport.status = true;
                this.isFavouriteReport.data.Id = id;
                this.isFavouriteReport.data.key = `report:${id}`;
                this.snackBar.open(`${reportTitle} added to favourites`, 'Close', { duration: 2000 });
                this.cdr.detectChanges();
              })
          }
        })
      }
    }
  }

  viewSavedFilters() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '300px';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      storedFilters: this.storedFilters
    };

    const dialogRef = this.dialog.open(ShowSavedReportFiltersDialogComponentV2, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        this.showSpinner = true;
        this.clearSidebar();
        this.savedFilterToApply = formData.filter.filter;
        this.savedReportFilterName = formData.filter.name;
        this.loadSavedFilters();
        this.showSpinner = false;
        this.cdr.detectChanges();

        const snackBarMessage = "Filter loaded: " + formData.filter.name
        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
      }
    });
  }

  loadSavedFilters() {
    const filters = this.savedFilterToApply.split(";");
    console.dir(this.sharedFiltersChild);
    this.sharedFiltersChild.loadSavedFilters(this.allFilterOptionsRaw, filters);
    this.loadDateFilters(filters);
    this.loadAdditionalFilters(filters);
    this.loadTypeAndColumnFilters(filters);
    this.savedFilterToApply = null;
    //this.cdr.detectChanges();
  }

  loadDateFilters(filters) {
    const dateToLoad = this.reportStoredFilterHelperService.getDateToLoad(filters, this.ranges);
    // If loading old saved filters this could be undefined if not in the hardcoded array - For newer ones this value will always be set.
    this.dateFilterTypeSelected = dateToLoad.dateTypeFilter ?? this.dateFilterTypeSelected;
    if (dateToLoad.startDate) {
      this.selectedDateRange = { "startDate": dateToLoad.startDate, "endDate": dateToLoad.endDate };
    }
    this.dateRangeChange(this.selectedDateRange);
  }

  loadAdditionalFilters(filters) {
    filters.forEach(filter => {
      const filterSplit = filter.split("::");
      const filterType = filterSplit[0];
      const filterMatch = this.additionalFilterGroup.get(filterType)
      if (filterMatch) {
        filterMatch.setValue(filterSplit[1].split(","));
        this.setFilter({ type: filterType, value: filterSplit[1].split(",") })
      }
    });
  }

  loadTypeAndColumnFilters(filters) {
    const typeAndColumns = this.reportStoredFilterHelperService.loadBasicTypeAndColumnFilters(filters);
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns.find(x => x.type === typeAndColumns.defaultDisplayColumnsType);
    this.selectedColumnOrder = this.defaultColumnOrder.find(x => x.type === typeAndColumns.defaultDisplayColumnsType);
    this.columnFormControl.setValue(typeAndColumns.selectedColumns);
    this.selectColumns(typeAndColumns.selectedColumns);
  }

  dateRangeChange(event) {
    const dateRange = {
      type: this.dateFilterTypeSelected,
      value: ''
    }
    if (event.startDate !== null && event.endDate !== null) {
      dateRange.value = event.startDate.format("YYYY,MM,DD") + '-' + event.endDate.format("YYYY,MM,DD")
    }
    this.setFilter(dateRange);
    this.cdr.detectChanges();
  }

  setFilter(newFilter) {
    let filterString = ''
    if (
      (typeof newFilter.value === 'object' && newFilter.value.indexOf(0) === -1 && newFilter.value.length !== 0) ||
      (typeof newFilter.value === 'string' && newFilter.value !== '') ||
      (typeof newFilter.value === 'boolean' && newFilter.value !== null)
    ) {
      // remove current filter if it exists.
      const filterToRemove = this.filter.findIndex((obj) => obj.column === newFilter.type);
      if (filterToRemove !== -1) {
        this.filter.splice(filterToRemove, 1);
      }
      const filterToAdd: ITableFilter = { column: newFilter.type, value: newFilter.value };
      this.filter.push(filterToAdd);
    }
    else {
      const filterToRemove = this.filter.findIndex((obj) => obj.column === newFilter.type);
      if (filterToRemove !== -1) {
        this.filter.splice(filterToRemove, 1);
      }
    }
    this.filter.forEach(element => {
      filterString += `${element.column}::${element.value};`;
    });
    //remove last & from the string.
    filterString = filterString.slice(0, -1);
    filterString = filterString.replace(/&/g, '%26'); // replace & with %26. This will be decoded on the server.
    this.setFilterEmitter.emit(filterString);
  }
  selectColumns(selection: any[]) {
    if (selection.indexOf('Select All') !== -1 && !this.selectAllColumns) {
      this.displayedColumns = this.orderColumns(this.allColumns);
      this.allColumns[this.allColumns.indexOf('Select All')] = 'Undo Select All';
      this.columnFormControl.setValue(this.displayedColumns);
      this.selectAllColumns = true;
    } else if (selection.indexOf('Undo Select All') !== -1 && this.selectAllColumns) {
      this.displayedColumns = [...this.lastColumnSelection];
      this.columnFormControl.setValue(this.displayedColumns);
      this.resetSelectAll();
    } else {
      this.displayedColumns = this.orderColumns(selection);
      this.lastColumnSelection = this.displayedColumns;
      this.resetSelectAll();
    }
  }
  orderColumns(newSelection: string[]) {
    let columnsToDisplay = [...this.selectedColumnOrder.columns];
    const selection = [...newSelection];

    columnsToDisplay = columnsToDisplay.filter(element => selection.indexOf(element) !== -1);

    const restOfSelection = selection.filter(item => this.selectedColumnOrder.columns.indexOf(item) === -1);
    restOfSelection.forEach(element => {
      if (element !== 'Select All') {
        columnsToDisplay.push(element);
      }
    });

    return columnsToDisplay;
  }

  showHideFilters(filterType: Event) {
    const clickedChevron = filterType.target as Element;
    switch (clickedChevron.id) {
      case "Options-Filters":
        this.showOptions = !this.showOptions;
        break;
      case "Classic-Filters":
        this.showClassicFilters = !this.showClassicFilters;
        break;
      case "Report-Filters":
        this.showReportFilters = !this.showReportFilters;
        break;
      default:
        console.error("Unknown chevron clicked");
        break;
    }
    this.ngSidebar.triggerRerender();
  }
  toggleOnlyActiveLearners(onlyActiveLearners: boolean) {
    this.setFilter({ type: 'activeLearners', value: onlyActiveLearners });
  }
  //#endregion
  //#region Filter Functions
  getFullFilterAsString(): string {
    // Check dateFilterTypeSelected is always set
    const reportTypeAndSelectColumns = this.reportStoredFilterHelperService.getBasicReportTypeAndColumns(ReportType.table, this.columnFormControl, this.groupByColumn, this.selectedDefaultDisplayColumns.type);
    const dateFilter = this.reportStoredFilterHelperService.getBasicDateFilterAsString(this.selectedDateRange.startDate, this.selectedDateRange.endDate, this.dateFilterTypeSelected);
    const sharedFilters = this.sharedFiltersChild.getFiltersAsString();
    const additionalFilters = this.reportStoredFilterHelperService.getAdditionalFilters(this.additionalFilters, this.additionalFilterGroup);
    const fullFilter = reportTypeAndSelectColumns + dateFilter + sharedFilters + additionalFilters;
    return fullFilter;
  }
  //#endregion
  shareReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '500px';
    const dialogRef = this.dialog.open(ShareReportFiltersDialogComponentV2, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      const fullFilter = this.getFullFilterAsString();
      const reportNameForUrl = this.route.snapshot.url[0].path;
      const data = { "filter": fullFilter, "reportId": this.report, "staffId": this.staffId, "name": formData.name, "staffToShareThisWith": formData.selectedStaff, "isSaveFilters": formData.isSaveFilters, "reportNameForUrl": reportNameForUrl }
      this.userService.shareFavouriteReportWithFilter(data).subscribe(filterId => {
        const snackBarMessage = "Filter shared: " + formData.name

        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
        let reportTitle = this.stringHelper.unCamelCase(ReportVariant[this.report]);
        if (data.isSaveFilters) {
          this.favouriteTableService
            .add({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: formData.name, filterId: filterId, reportId: this.report }, filterId)
            .then(id => {
              reportTitle = this.stringHelper.unCamelCase(ReportVariant[this.report]);
              this.cdr.detectChanges();
            })
        }
      });
    });
  }
  export(exportType: string) {
    this.drillDownReportService.postReportExport(this.report, this.stringHelper.notNullOrEmpty(this.filterString) ? this.filterString + ";" + this.drillDownFilter : this.drillDownFilter, this.sortString, this.displayedColumns.join(";"), exportType, this.selectedDrillDownGroup.name, this.selectedLevel.level).subscribe({
      next: (data: any) => {
        this.snackBar.open(data, 'Close');
      },
      error: (error: any) => {
        console.dir(error);
        this.snackBar.open(error, "Close");
      }
    });
  }
}
