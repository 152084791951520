import { ChangeDetectorRef, EventEmitter, OnChanges } from '@angular/core';
import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'underscore';

@Component({
  selector: 'app-employer-contacts',
  templateUrl: './employer-contacts.component.html',
  styleUrls: ['./employer-contacts.component.scss']
})

export class EmployerContactsComponent implements OnInit, OnChanges {

  @Input() isEditForm: boolean;
  @Input() contacts: any;
  @Output() contactsUpdate = new EventEmitter();

  numStrt: number;
  numEnd: number;
  numCols: number;
  contactForm: any;
  contactsMaster: any;
  isInitialised: boolean;
  contactTypes: string[] = ["Main", "Business Development", "Marketing", "Traineeships"]

  constructor(private fb: UntypedFormBuilder, private snackBar: MatSnackBar, private changeDetetorRef: ChangeDetectorRef) {
    this.contactForm = new UntypedFormGroup({
      contactsArray: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.isInitialised = true;
    this.contactsMaster = _.clone(this.contacts);
    this.numStrt = 0;
    this.numEnd = 2;
    this.numCols = this.numEnd;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isEditForm) {
      let x = changes.isEditForm.currentValue;
      this.toggleEdit(x);
    }
    if (!this.isInitialised) {
      this.buildForm();
    }
  }

  buildForm() {
    const contactTypes = [];
    const controlArray = this.contactForm.get('contactsArray') as UntypedFormArray;
    console.log(this.contacts, 'contacts')
    if (this.contacts) {
      Object.keys(this.contacts).forEach(i => {
        controlArray.push(
          this.fb.group({
            name: new UntypedFormControl({ value: this.contacts[i].contactName, disabled: true }),
            type: new UntypedFormControl({ value: this.contacts[i].contactType, disabled: true }),
            title: new UntypedFormControl({ value: this.contacts[i].contactPosition, disabled: true }),
            phone: new UntypedFormControl({ value: this.contacts[i].contactPhone, disabled: true }),
            email: new UntypedFormControl({ value: this.contacts[i].contactEmail, disabled: true })
          })
        );
      })
    }
  }

  copyPhone(index) {
    console.log("ready to copy ", index)
    var valueToCopy = this.contactForm.get('contactsArray').get(String(index)).get("phone").value
    console.log("ready to copy ", valueToCopy)
    if (valueToCopy) {
      this.copyMessage(valueToCopy)
      this.snackBar.open("Phone number copied to clipboard", "Close", {
        duration: 4000,
      });
    }
    else {
      this.snackBar.open("Nothing to copy", "Close", {
        duration: 4000,
      });
    }
  }

  copyEmail(index) {
    console.log("ready to copy ", index)
    var valueToCopy = this.contactForm.get('contactsArray').get(String(index)).get("email").value
    console.log("ready to copy ", valueToCopy)
    if (valueToCopy) {
      this.copyMessage(valueToCopy)
      this.snackBar.open("Email copied to clipboard", "Close", {
        duration: 4000,
      });
    }
    else {
      this.snackBar.open("Nothing to copy", "Close", {
        duration: 4000,
      });
    }
  }


  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  loadNextContacts() {
    this.numStrt = this.numStrt + 2;
    this.numEnd = this.numEnd + 2;
    if (this.numStrt >= this.contacts.length) {
      this.numStrt = 0;
      this.numEnd = this.numCols;
    }
    console.log(this.numStrt, this.numEnd, this.contacts.length)
    return false;
  }

  addNew() {
    this.contacts.push({ 'contactName': null, 'contactType': null, 'contactPosition': null, 'contactPhone': null, 'contactEmail': null, 'vBranchId': null, 'vEmployerId': null });

    const controlArray = this.contactForm.get('contactsArray') as UntypedFormArray;
    controlArray.push(
      this.fb.group({
        name: new UntypedFormControl(null),
        type: new UntypedFormControl({ value: null, disabled: false }),
        title: new UntypedFormControl({ value: null, disabled: false }),
        phone: new UntypedFormControl({ value: null, disabled: false }),
        email: new UntypedFormControl({ value: null, disabled: false })
      })
    );
    this.numEnd = this.contacts.length;
    this.numStrt = this.numEnd - 1;
    this.changeDetetorRef.detectChanges();
    console.log(this.contacts, controlArray.value, this.contactForm);
  }

  availableContactTypes(selection) {
    let usedTypes = [];
    for (let type in this.contactTypes) {
      if (this.contacts.some(e => e.contactType === this.contactTypes[type])) {
        usedTypes.push(this.contactTypes[type])
      }
    }
    let availableTypes = this.contactTypes.filter(item => !usedTypes.includes(item));
    if (selection) {
      availableTypes.push(selection);
    }
    return availableTypes;
  };

  toggleEdit(isEdit) {
    <UntypedFormArray>this.contactForm.get('contactsArray')
      .controls
      .forEach(control => {
        if (isEdit) {
          control.enable();
        }
        else {
          control.disable();
        }
      });
  }

  emitData() {
    console.log('emit data from employer-contacts');

    // This is not working - needs work

    if (this.contactForm.dirty) {
      const returnData = { values: this.contactForm.get('contactsArray').value, operation: 'update' };
      console.log('sending: ', returnData);
      this.contactsUpdate.emit({ 'contacts': returnData });

      // _.each(this.contactForm.controls.contactsArray, c => {
      //   c.pristine = true;
      //   c.touched = false;
      // });

      this.contactForm.markAsUntouched();
      this.contactForm.pristine = true;
    }
    else {
      console.log('no changes to emit');
    }

  }

}
