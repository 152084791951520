import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DATE_FORMAT, ERROR_SAVING_DATA } from 'src/app/app.constants';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EventModalMode } from 'src/app/shared/enums/event-modal-mode';
import { EmployerEvent, EmployerEventOptions } from 'src/app/shared/interfaces/employer-event';
import { FilterOption } from '../../employer-overview/employer-overview-interfaces';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FromValidators } from 'src/app/shared/form-validators';
import { EmployerService } from '../../employer.service';

@Component({
  selector: 'app-employer-events-form',
  templateUrl: './employer-events-form.component.html',
  styleUrls: ['./employer-events-form.component.scss']
})
export class EmployerEventsFormComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
      {
        mode: EventModalMode,
        event: EmployerEvent | null,
        eventOptions: EmployerEventOptions,
        staffId: number,
        employerId: number,
        permissions: { canEdit: boolean, canDelete: boolean },
        lockRootEvent: boolean,
      },
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>,
    private matDialog: MatDialog,
    private sharedService: SharedService,
    private matSnackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private formValidators: FromValidators,
    private employerService: EmployerService,
  ) { }

  staffId: number;
  employerId: number;
  saveError = ERROR_SAVING_DATA;
  dateFormat = DATE_FORMAT;
  eventModalMode = EventModalMode;

  plannedEventIds = [1, 2, 3, 4, 5, 6, 7, 12, 13, 20, 26, 27];

  private _staffSearchFilter = '';
  private staffDebounceTimer: NodeJS.Timer;

  get staffSearchFilter(): string {
    return this._staffSearchFilter;
  }

  set staffSearchFilter(value: string) {
    this._staffSearchFilter = value;

    if (this.staffDebounceTimer) {
      clearTimeout(this.staffDebounceTimer);
    }

    this.staffDebounceTimer = setTimeout(() => {
      this.filterEventStaff();
    }, 300);
  }

  private _typeSearchFilter = '';
  private typeDebounceTimer: NodeJS.Timer;

  get typeSearchFilter(): string {
    return this._typeSearchFilter;
  }

  set typeSearchFilter(value: string) {
    this._typeSearchFilter = value;

    if (this.typeDebounceTimer) {
      clearTimeout(this.typeDebounceTimer);
    }

    this.typeDebounceTimer = setTimeout(() => {
      this.filterEventTypes();
    }, 300);
  }

  event: EmployerEvent = null;
  mode: EventModalMode = EventModalMode.view;
  eventsForm: UntypedFormGroup;
  saving = false;
  eventOptions: EmployerEventOptions;
  filteredEventTypes: FilterOption[] = [];
  filteredEventStaff: FilterOption[] = [];

  timeIntervals = 15;
  startTimes = [];
  endTimes = [];


  permissions: {
    canEdit: boolean,
    canDelete: boolean,
  };

  refreshEvents = false;

  ngOnInit(): void {
    this.makeTimeIntervals();
    this.permissions = this.data.permissions;
    this.mode = this.data.mode;
    this.event = this.data.event;
    this.eventOptions = this.data.eventOptions;
    this.staffId = this.data.staffId;
    this.employerId = this.data.employerId;
    this.filteredEventTypes = [...this.eventOptions.types];
    this.filteredEventStaff = [...this.eventOptions.staff];
    this.buildForm();
    if (this.event?.startTime !== undefined) {
      this.setEndTimes(this.event.startTime);
    }
    this.cdr.detectChanges();
  }

  makeTimeIntervals() {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += this.timeIntervals) {
        const time = `${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`;
        this.startTimes.push(time);
      }
    }
    this.endTimes = [...this.startTimes];
  }

  // Endt times should be greater than start selected
  setEndTimes(startTime: string) {
    this.eventsForm.controls.endTime.setValue(null);
    this.endTimes = this.startTimes.filter(x => x > startTime).map(x => x + ' (' + this.timeDifference(startTime, x) + ')');
  }

  timeDifference(startTime: string, endTime: string): string {
    const start = startTime.split(':');
    const end = endTime.split(':');
    const hours = parseInt(end[0]) - parseInt(start[0]);
    const mins = Math.abs(parseInt(end[1]) - parseInt(start[1]));
    if (hours === 0) {
      return `${mins} mins`;
    }
    if (mins === 0) {
      return `${hours} hours`;
    }
    return `${hours} hours ${mins} mins`;
  }

  buildForm() {
    this.eventsForm = this.fb.group({
      type: [this.filteredEventTypes.find(t => t.id === this.event?.type.id), Validators.required],
      assignee: [this.filteredEventStaff.find(s => s.id === this.event?.assignee.id), Validators.required],
      notes: [this.event?.notes],
      plannedDate: [this.event?.plannedDate],
      actualDate: [this.event?.actualDate],
      startTime: [this.event?.startTime],
      endTime: [this.event?.endTime],
      rootEvent: [{value: this.event?.isBranch === undefined ? false : !this.event?.isBranch, disabled: this.data.lockRootEvent}],
      outlookEvent: [this.event?.outlookEvent === undefined ? false : this.event?.outlookEvent.active],
    },
      {
        validators: [
          this.formValidators.atLeastOneValidator(['plannedDate', 'actualDate']),
          this.formValidators.bothControlsRequiredValidator('startTime', 'endTime'),
        ]
      });
  }

  changeEventMode(mode: EventModalMode) {
    this.mode = mode;
    this.cdr.detectChanges();
  }

  filterEventTypes() {
    if (this.eventOptions?.types.length > 0) {

      this.filteredEventTypes = [...this.eventOptions.types];

      if (!this.sharedService.isNullOrEmpty(this.typeSearchFilter)) {
        this.filteredEventTypes = this.eventOptions.types.filter(x => x.name?.toLowerCase().includes(this.typeSearchFilter.toLowerCase()));
      }
    }
  }

  filterEventStaff() {
    if (this.eventOptions?.staff.length > 0) {

      this.filteredEventStaff = [...this.eventOptions.staff];

      if (!this.sharedService.isNullOrEmpty(this.staffSearchFilter)) {
        this.filteredEventStaff = this.eventOptions.staff.filter(x => x.name?.toLowerCase().includes(this.staffSearchFilter.toLowerCase()));
      }
    }
  }

  onTypeChange() {
    if (!this.isPlannedEvent()) {
      this.eventsForm.controls.startTime.setValue(null);
      this.eventsForm.controls.endTime.setValue(null);
      this.eventsForm.controls.outlookEvent.setValue(false);
      this.eventsForm.controls.plannedDate.setValue(null);
    }
  }

  onOutlookEventChange() {
    if (!this.eventsForm.value.outlookEvent) {
      this.eventsForm.controls.startTime.setValue(null);
      this.eventsForm.controls.endTime.setValue(null);
    }
  }

  isPlannedEvent() {
    return this.plannedEventIds.includes(this.eventsForm.value.type?.id);
  }

  private getModifedEvent(): EmployerEvent {
    const event: EmployerEvent = {
      id: this.event?.id,
      branchId: this.event?.branchId,
      type: this.eventsForm.value.type,
      assignee: this.eventsForm.value.assignee,
      notes: this.eventsForm.value.notes,
      plannedDate: this.eventsForm.value.plannedDate,
      actualDate: this.eventsForm.value.actualDate,
      startTime: this.eventsForm.value.startTime,
      endTime: this.eventsForm.value.endTime,
      isBranch: !this.eventsForm.value.rootEvent,
      outlookEvent: { active: this.eventsForm.value.outlookEvent, id: this.event?.outlookEvent?.id },
      typeGroup: this.event?.typeGroup,
      editedBy: this.event?.editedBy,
      editedDate: this.event?.editedDate,
    };
    return event;
  }

  onSubmit() {
    if (this.saving) {
      return;
    }

    this.saving = true;

    if (this.mode === EventModalMode.edit) {
      this.updateEvent();
      return;
    }

    this.createEvent();

  }

  createEvent() {

    const subscription = this.employerService.createEvent(this.employerId, this.getModifedEvent(), this.staffId).subscribe({
      next: (createdEvent: EmployerEvent) => {
        this.event = createdEvent;
        this.matSnackBar.open("Event Created.", '', {
          duration: 2000,
        });
      },
      error: (error) => {
        const errorMessage = error === 'You do not have permission to complete the requested action.' ? 'You do not have permission to create an event.' : this.saveError;
        this.matSnackBar.open(errorMessage, '', {
          duration: 4000,
        });
        console.error('Error creating event', error);
        this.saving = false;
      },
      complete: () => {
        subscription.unsubscribe();
        this.eventsForm.markAsPristine();
        this.refreshEvents = true;
        this.saving = false;
        this.changeEventMode(EventModalMode.view);
      }
    });
  }

  updateEvent() {
    const subscription = this.employerService.updateEvent(this.getModifedEvent(), this.staffId, this.employerId).subscribe({
      next: (updatedEvent: EmployerEvent) => {
        this.event = updatedEvent;
        this.matSnackBar.open("Event Updated.", '', {
          duration: 2000,
        });
      },
      error: (error) => {
        const errorMessage = error === 'You do not have permission to complete the requested action.' ? 'You do not have permission to edit this event.' : this.saveError;
        this.matSnackBar.open(errorMessage, '', {
          duration: 4000,
        });
        console.error('Error updating event', error);
        this.saving = false;
      },
      complete: () => {
        subscription.unsubscribe();
        this.eventsForm.markAsPristine();
        this.refreshEvents = true;
        this.saving = false;
        this.changeEventMode(EventModalMode.view);
      }
    });
  }

  close() {

    if (!this.eventsForm.dirty) {
      this.dialogRef.close({ refresh: this.refreshEvents });
      return;
    }

    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      message: `You are about to close without saving, **any changes made to this event will be lost**. Do you wish to continue?`,
      title: 'Are you sure?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warn',
    };

    this.matDialog.open(ConfirmDialogComponent, confirmDialogConfig)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.dialogRef.close({ refresh: this.refreshEvents });
        }
      }
      );
  }

  delete() {
    if (!this.permissions.canDelete || this.saving) {
      return;
    }

    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      message: `You are about to **permanently delete ${this.event.type.name} event**. Do you wish to continue?`,
      title: 'Are you sure?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warn',
    };

    this.matDialog.open(ConfirmDialogComponent, confirmDialogConfig)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.saving = true;

          const subscription = this.employerService.deleteEvent(this.event.id, this.staffId).subscribe({
            next: () => {
              this.matSnackBar.open("Event Deleted.", '', {
                duration: 2000,
              });
            },
            error: (error) => {
              const errorMessage = error === 'You do not have permission to complete the requested action.' ? 'You do not have permission to delete this event.' : 'Error deleting event. Please try again.';
              this.matSnackBar.open(errorMessage, '', {
                duration: 4000,
              });
              console.error('Error deleting event', error);
              this.saving = false;
            },
            complete: () => {
              subscription.unsubscribe();
              this.refreshEvents = true;
              this.saving = false;
              this.dialogRef.close({ refresh: this.refreshEvents });
            }
          });
        }
      });
  }

}
