import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'staff-dashboard',
  templateUrl: './staff-dashboard.component.html',
  styleUrls: ['./staff-dashboard.component.scss']
})
export class StaffDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
