<h1 class="progressTitle title">
  Awards
</h1>

<div class="reportTable">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.sharedFunctions.unCamelCase(column)}} </th>
      <td mat-cell *matCellDef="let element">
        {{element[column] === null ? '-'
        : dateCols.indexOf(column) === -1 ? element[column] : element[column] | date: this.dateFormat }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td *ngIf="this.loadedAwards" class="mat-cell" colspan="8" style="text-align: center;">
        {{
          !this.awards.success
          ? 'There was an issue getting Awards. Please reload or contant support if it contunues.'
          : this.noData
        }}
      </td>
    </tr>

  </table>
  <mat-progress-bar *ngIf="!this.loadedAwards" mode="indeterminate"></mat-progress-bar>
</div>
