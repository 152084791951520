import { AdminDashReportsService } from '../../../core/database/admin-dash-reports.service';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FavouriteTableService } from 'src/app/core/database/favourite-table.service';
import { WidgetDirective } from 'src/app/core/directives/widget.directive';
import { ContractWidgetItem } from '../../contract-widgets/contract-widget-item';
import { ContractWidgetTemplateComponent } from '../../contract-widgets/contract-widget-template-component';
import { ReportCardComponent } from '../../home-dashboards-shared/report-card-caseload-progress/report-card.component';
import { YourEmployersComponent } from '../../home-dashboards-shared/your-employers/your-employers.component';
import { YourLearnersComponent } from '../../home-dashboards-shared/your-learners/your-learners.component';
import { YourReportsComponent } from '../../home-dashboards-shared/your-reports/your-reports.component';
import { HomeDashboardService } from '../home-dashboard.service';
import { YourEventsComponent } from '../../home-dashboards-shared/your-events/your-events.component';
import { ContractWidgetService } from '../../contract-widgets/contract-widget.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataCardComponent } from '../../home-dashboards-shared/data-card/data-card.component';
import { DateCalculatorComponent } from '../../home-dashboards-shared/date-calculator/date-calculator.component';
import { ExpirationNotificationsComponent } from '../home-health-and-safety/expiration-notifications/expiration-notifications.component';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { ReportCardReviewComplianceComponent } from '../../home-dashboards-shared/report-card-review-compliance/report-card-review-compliance.component';
import { YourAssessorsComponent } from '../../home-dashboards-shared/your-assessors/your-assessors.component';
import { LearningAimEditorComponent } from '../../home-dashboards-shared/learning-aim-editor/learning-aim-editor.component';
import { LearnerPayComponent } from '../../home-dashboards-shared/learner-pay/learner-pay.component';
import { AttendanceApprovalComponent } from '../../home-dashboards-shared/attendance-approval/attendance-approval.component';
import { AuthorisedAbsenceComponent } from '../../home-dashboards-shared/authorised-absence/authorised-absence.component';
import { AttendanceAnalysisComponent } from '../../home-dashboards-shared/attendance-analysis/attendance-analysis.component';
import { ContractManagementComponent } from '../../home-dashboards-shared/contract-management/contract-management.component';
import { DeliveryFundingPredictionsComponent } from '../../home-dashboards-shared/delivery-funding-predictions/delivery-funding-predictions.component';
import { UserTableService } from 'src/app/core/database/user-table.service';
import { ReviewsDueComponent } from '../../home-dashboards-shared/reviews-due/reviews-due.component';
import { IalpsDueComponent } from '../../home-dashboards-shared/ialps-due/ialps-due.component';
import { FavouriteLearnersComponent } from '../../home-dashboards-shared/favourite-learners/favourite-learners.component';
import { VastDataExportComponent } from '../../home-dashboards-shared/vast-data-export/vast-data-expoer.component';

@Component({
  selector: 'app-home-generic',
  templateUrl: './home-generic.component.html',
  styleUrls: ['./home-generic.component.scss']
})
export class HomeGenericComponent implements OnInit {

  @Input() staffId: number;
  @Input() department: string;
  @Input() defaultPage: string;
  @ViewChild(WidgetDirective, { static: true }) selectedWidgetHost!: WidgetDirective;

  allWidgets: any[] = [];
  selectableWidgets: any[] = [];
  selectedWidgets: ContractWidgetItem[] = [];
  widgetsSelected: number[] = [];
  widgetTemplate: number[] = [];

  showHideWidgetOptions: boolean = false;

  isDefaultsLoaded: boolean = false;

  regRequests: any;
  awaitingCerts: any;
  toBeClaimed: any;
  withdrawnActivities: any;
  myFavouriteReports: any;
  currentDashboard: string;

  cards: any[] = [
    { 'title': 'Registration Requests', 'stat': 0, 'data': null },
    { 'title': 'Awaiting Certificates', 'stat': 0, 'data': null },
    { 'title': 'To Be Claimed', 'stat': 0, 'data': null },
    { 'title': 'Withdrawn Activities', 'stat': 0, 'data': null }
  ]

  adminData: any;

  constructor(
    private adminDashReporService: AdminDashReportsService,
    private homeDashboardService: HomeDashboardService,
    private favouriteTableService: FavouriteTableService,
    private cdr: ChangeDetectorRef,
    private contractWidgetService: ContractWidgetService,
    private snackBar: MatSnackBar,
    private sharedFunctionsService: SharedFunctionsService,
    private userService: UserTableService
  ) { }

  ngOnInit(): void {
  }

  refreshDisplayedWidgets() {
    let tempArray: ContractWidgetItem[] = [];

    this.widgetsSelected.forEach(element => {
      let selected = this.allWidgets.filter(a => {
        return a.widgetId == element
      });

      if (selected[0]) {
        tempArray.push(
          new ContractWidgetItem(
            ContractWidgetTemplateComponent,
            { 'widgetId': selected[0].widgetId, 'name': `${selected[0].widgetName}` }
          ));
      }
    });

    this.selectedWidgets = this.sharedFunctionsService.genericOrdered(tempArray, 'widgetName');
  }

  setSelectableWidgets() {
    let tempArray: any[] = [];

    this.widgetTemplate.forEach(element => {
      let selected = this.allWidgets.filter(a => {
        return a.widgetId == element
      });

      if (selected[0]) {
        tempArray.push(selected[0]);
      }

    });

    this.selectableWidgets = this.sharedFunctionsService.genericOrdered(tempArray, 'widgetName');
  }

  saveWidgetChanges() {

    if (this.isDefaultsLoaded) {
      this.contractWidgetService.removeStaffWidgetSelections(this.staffId).subscribe(
        response => {
          if (response) {
            this.snackBar.open("Default widgets have been restored", "Close", {
              duration: 4000,
            });
            this.isDefaultsLoaded = false;
          }
        }
      )
    } else {

      let hasChanged = true //false;

      // TODO: check if tempArray and this.selectedWidgets hold any different items

      /*
        tempArray.every(ta => {
          if (this.selectedWidgets.indexOf(ta) == -1) {
            hasChanged = true;
            return false;
          }
        });

        if (!hasChanged) {
          this.selectedWidgets.every(sw => {
            if (tempArray.indexOf(sw) == -1) {
              hasChanged = true;
              return false;
            }
          })
        }
      */

      if (hasChanged) {
        this.contractWidgetService.saveContractWidgetChanges(this.staffId, this.widgetsSelected).subscribe(
          response => {
            console.log(response);
          }
        );

        this.refreshDisplayedWidgets();
      }

      this.loadSelectedWidgetComponents();
    }
  }

  loadDefaultWidgets() {
    this.isDefaultsLoaded = true;
    this.widgetsSelected = this.widgetTemplate;
    this.refreshDisplayedWidgets();
    this.loadSelectedWidgetComponents();
    this.saveWidgetChanges();
  }

  loadSelectedWidgetComponents() {
    const viewContainerRef = this.selectedWidgetHost.viewContainerRef;
    viewContainerRef.clear();
    this.selectedWidgets.forEach(
      widgetItem => {
        let widgetId = widgetItem.data.widgetId;
        this.setupWidget(widgetId, viewContainerRef);
        this.cdr.detectChanges();
      });
  }

  setupWidget(widgetId: number, viewContainerRef: ViewContainerRef) {
    switch (widgetId) {

      case (1): {
        //console.log('adding widget 1: YourLearnersComponent')
        const componentRef = viewContainerRef.createComponent<YourLearnersComponent>(YourLearnersComponent);
        this.homeDashboardService.getYourLearners(this.staffId).subscribe(data => {
          componentRef.location.nativeElement['style']['width'] = '45%';
          componentRef.instance.setData(data);

        });
        break;
      };

      case (2): {
        //console.log('adding widget 2: YourEmployersComponent')
        const componentRef = viewContainerRef.createComponent<YourEmployersComponent>(YourEmployersComponent);
        this.homeDashboardService.getYourEmployers(this.staffId).subscribe(data => {
          if (data) {
            componentRef.location.nativeElement['style']['width'] = '45%';
            componentRef.instance.setCols(data);
            this.cdr.detectChanges();
          }
        });
        break;
      };

      case (3): {
        //console.log('adding widget 3: YourEventsComponent')
        const componentRef = viewContainerRef.createComponent<YourEventsComponent>(YourEventsComponent);
        componentRef.location.nativeElement['style']['width'] = '45%';
        componentRef.instance.staffId = this.staffId;
        componentRef.instance.currentDashboard = this.currentDashboard;
        this.cdr.detectChanges();
        break;
      };

      case (4): {
        //console.log('adding widget 4: YourReportsComponent')
        const componentRef = viewContainerRef.createComponent<YourReportsComponent>(YourReportsComponent);
        this.favouriteTableService
          .getAll()
          .then(response => {
            for (let x in response) {
              let link = "/reports/" + response[x].title.toLowerCase().replace(/ /g, "-") + (response[x]['type'] === 'filter' ? '/' + response[x]['filterId'] : '')
              response[x].link = link;
            };
            componentRef.instance.favourites = response;
            componentRef.location.nativeElement['style']['width'] = '22.5%';
            componentRef.location.nativeElement['style']['overflow-y'] = 'auto';
            this.cdr.detectChanges();
          });
        break;
      };

      case (5): {
        //console.log('adding widget 5: ReportCardComponent')
        const componentRef = viewContainerRef.createComponent<ReportCardComponent>(ReportCardComponent)
        const method =
          this.defaultPage == "assessor" ? "getCaseloadStats" :
            this.defaultPage == "manager" ? "getCaseloadStatsManager" :
              this.defaultPage == "smt" ? 'getSubcontractorStats' :
                null;

        const param1 =
          ["manager", "assessor"].includes(this.defaultPage) ? this.staffId :
            ["smt"].includes(this.defaultPage) ? 1 :  //TODO get subcontractorid
              null;

        const param2 = ["manager", "smt"].includes(this.defaultPage) ? this.department : null;

        if (method) {
          this.homeDashboardService[`${method}`](param1, param2).subscribe(data => {
            if (data) {
              componentRef.instance.setData(data.caseload);
            };
            this.cdr.detectChanges();
          });
        }
        break;
      };

      case (6): {
        //console.log('adding widget 6: Reg Requests')
        const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
        if (!this.adminData) {
          this.homeDashboardService.getDataForAdminDashboard().subscribe(data => {
            this.adminData == data;
            this.updateCards(data, 'Registration Requests', componentRef);
          });
        }
        else {
          this.updateCards(this.adminData, 'Registration Requests', componentRef);
        };
        componentRef.location.nativeElement['style']['width'] = '22.5%';
        this.cdr.detectChanges();
        break;
      };

      case (7): {
        //console.log('adding widget 7: Awaiting Certificates')
        const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
        if (!this.adminData) {
          this.homeDashboardService.getDataForAdminDashboard().subscribe(data => {
            this.adminData == data;
            this.updateCards(data, 'Awaiting Certificates', componentRef);
            this.cdr.detectChanges();
          });
        }
        else {
          this.updateCards(this.adminData, 'Awaiting Certificates', componentRef);
          this.cdr.detectChanges();
        };
        componentRef.location.nativeElement['style']['width'] = '22.5%';
        break;
      };

      case (8): {
        //console.log('adding widget 8: To be claimed')
        const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
        if (!this.adminData) {
          this.homeDashboardService.getDataForAdminDashboard().subscribe(data => {
            this.adminData == data;
            this.updateCards(data, 'To Be Claimed', componentRef);
            this.cdr.detectChanges();
          });
        }
        else {
          this.updateCards(this.adminData, 'To Be Claimed', componentRef);
          this.cdr.detectChanges();
        };
        componentRef.location.nativeElement['style']['width'] = '22.5%';
        break;
      };

      case (9): {
        //console.log('adding widget 8: Withdrawn Activities')
        const componentRef = viewContainerRef.createComponent<DataCardComponent>(DataCardComponent);
        if (!this.adminData) {
          this.homeDashboardService.getDataForAdminDashboard().subscribe(data => {
            this.adminData == data;
            this.updateCards(data, 'Withdrawn Activities', componentRef);
            this.cdr.detectChanges();
          });
        }
        else {
          this.updateCards(this.adminData, 'Withdrawn Activities', componentRef);
          this.cdr.detectChanges();
        };
        componentRef.location.nativeElement['style']['width'] = '22.5%';
        break;
      };

      //console.log('adding widget 10: Review Compliance')
      case (10): {
        const componentRef = viewContainerRef.createComponent<ReportCardReviewComplianceComponent>(ReportCardReviewComplianceComponent);
        this.homeDashboardService.getReviewCompliance().subscribe(data => {
          componentRef.instance.setData(data);
          // componentRef.location.nativeElement['style']['width'] = '22.5%';
          this.cdr.detectChanges();
        });

        break;
      };

      //console.log('adding widget 11: Expiration Notifications')
      case (11): {
        const componentRef = viewContainerRef.createComponent<ExpirationNotificationsComponent>(ExpirationNotificationsComponent);
        this.homeDashboardService.getEmployerExpirations('act').subscribe(data => {
          let combined = [...new Set([...data['appraisalExpirations'], ...data['eliExpirations'], ...data['monitorExpirations']])];
          componentRef.instance.setCols(combined);
        });

        break;
      };


      case (12): {
        //console.log('adding widget 12: Attendance Approval')
        const componentRef = viewContainerRef.createComponent<AttendanceApprovalComponent>(AttendanceApprovalComponent);
        this.homeDashboardService.getAttendanceToApprove(this.staffId).subscribe(data => {
          if (data.length > 0) {
            componentRef.location.nativeElement['style']['width'] = '100%';
            componentRef.instance.staffId = this.staffId;
            componentRef.instance.setData(data);
            componentRef.instance.paginateAndSort();
            componentRef.instance.attendanceApproval.subscribe(val => this.loadSelectedWidgetComponents());
            this.cdr.detectChanges();
          }
        })
        break;
      };

      case (13): {
        //console.log('adding widget 13: Authorised Absence')
        const componentRef = viewContainerRef.createComponent<AuthorisedAbsenceComponent>(AuthorisedAbsenceComponent);
        this.homeDashboardService.getAbsenceToAuthorise(this.staffId).subscribe(data => {
          if (data.length > 0) {
            //console.log('authorised absence data', data)
            componentRef.location.nativeElement['style']['width'] = '100%';
            componentRef.instance.staffId = this.staffId;
            componentRef.instance.setData(data);
            componentRef.instance.paginateAndSort();
            componentRef.instance.attendanceUpdate.subscribe(val => this.loadSelectedWidgetComponents());
            this.cdr.detectChanges();
          }

        })
        break;
      };

      case (15): {
        //console.log('adding widget 15: Contract Management')
        const componentRef = viewContainerRef.createComponent<ContractManagementComponent>(ContractManagementComponent);
        this.homeDashboardService.getContractManagement(this.staffId).subscribe(data => {
          componentRef.instance.setData(data);
          this.cdr.detectChanges();
        });
        break;
      };

      case (16): {
        //console.log('adding widget 16: Learning Aim Reference Editor')
        const componentRef = viewContainerRef.createComponent<LearningAimEditorComponent>(LearningAimEditorComponent);
        componentRef.location.nativeElement['style']['width'] = '30%';
        componentRef.instance.getLearningAimReferences();
        break;

      };

      case (17): {
        //console.log('adding widget 17: Date Calculator')
        const componentRef = viewContainerRef.createComponent<DateCalculatorComponent>(DateCalculatorComponent);
        componentRef.location.nativeElement['style']['width'] = '22.5%';
        console.log('NATIVE ELEMENT', componentRef.location.nativeElement);
        this.cdr.detectChanges();
        break;
      };

      case (18): {
        //console.log('adding widget 18: Learner Pay Export')
        const componentRef = viewContainerRef.createComponent<LearnerPayComponent>(LearnerPayComponent);
        componentRef.location.nativeElement['style']['width'] = '30%';
        componentRef.instance.staffId = this.staffId;
        this.cdr.detectChanges();
        // });
        break;
      };

      case (19): {
        //console.log('adding widget 19: Your Assessors')
        const componentRef = viewContainerRef.createComponent<YourAssessorsComponent>(YourAssessorsComponent);
        this.homeDashboardService.getYourAssessors(this.staffId, this.department).subscribe(data => {
          componentRef.instance.setCols(data);
          this.cdr.detectChanges();
        });
        break;
      };

      case (20): {
        //console.log('adding widget 20: Attendance Analysis')
        const componentRef = viewContainerRef.createComponent<AttendanceAnalysisComponent>(AttendanceAnalysisComponent);
        componentRef.location.nativeElement['style']['min-width'] = '100%';
        componentRef.instance.staffId = this.staffId;
        componentRef.instance.initialApiCall();
        break;
      };

      case (21): {
        const componentRef = viewContainerRef.createComponent<DeliveryFundingPredictionsComponent>(DeliveryFundingPredictionsComponent);
        componentRef.location.nativeElement['style']['min-width'] = '100%';
        componentRef.instance.staffId = this.staffId;
        componentRef.instance.initialApiCall();
        break;
      };

      case (22): {
        const componentRef = viewContainerRef.createComponent<ReviewsDueComponent>(ReviewsDueComponent);
        componentRef.location.nativeElement['style']['width'] = '45%';
        componentRef.instance.initialApiCall(this.staffId);
        this.cdr.detectChanges();
        break;
      };

      case (23): {
        const componentRef = viewContainerRef.createComponent<IalpsDueComponent>(IalpsDueComponent);
        componentRef.location.nativeElement['style']['width'] = '45%';
        componentRef.instance.initialApiCall(this.staffId);
        this.cdr.detectChanges();
        break;
      };

      case (24): {
        const componentRef = viewContainerRef.createComponent<FavouriteLearnersComponent>(FavouriteLearnersComponent);
        componentRef.location.nativeElement['style']['width'] = '25%';
        componentRef.instance.initialApiCall(this.staffId);
        this.cdr.detectChanges();
        break;
      };

      case (25): {
        const componentRef = viewContainerRef.createComponent<VastDataExportComponent>(VastDataExportComponent);
        componentRef.location.nativeElement['style']['width'] = '30%';
        this.cdr.detectChanges();
        break;
      }

    }
  }

  updateCards(data, title, component) {
    let dataTitle = this.sharedFunctionsService.toCamelCase(title.replace('Registration', 'Reg'));
    let idx = this.cards.findIndex((x => x.title == title));
    //console.log(data, dataTitle);

    this.cards[idx].stat = data[`${dataTitle}`].length;
    this.cards[idx].data = data[`${dataTitle}`];

    component.instance.data = this.cards[idx]['data'];
    component.instance.title = this.cards[idx]['title'];
    component.instance.stat = this.cards[idx]['stat'];
  }

  showWidgetOptions() {
    this.showHideWidgetOptions = !this.showHideWidgetOptions;
    this.cdr.detectChanges();
    window.scrollTo(0, document.body.scrollHeight);
  }

  // -------------- Widgets

  // setData(data, resetLocal: boolean) {

  //   this.regRequests = data['regRequests']
  //   this.awaitingCerts = data['awaitingCertificates']
  //   this.toBeClaimed = data['toBeClaimed']
  //   this.withdrawnActivities = data['withdrawnActivities']

  //   let idx = this.cards.findIndex((x => x.title == 'Registration Requests'));
  //   this.cards[idx].stat = this.regRequests.length;
  //   this.cards[idx].data = this.regRequests;

  //   idx = this.cards.findIndex((x => x.title == 'Awaiting Certificates'));
  //   this.cards[idx].stat = this.awaitingCerts.length;
  //   this.cards[idx].data = this.awaitingCerts;

  //   idx = this.cards.findIndex((x => x.title == 'To Be Claimed'));
  //   this.cards[idx].stat = this.toBeClaimed.length;
  //   this.cards[idx].data = this.toBeClaimed;

  //   idx = this.cards.findIndex((x => x.title == 'Withdrawn Activities'));
  //   this.cards[idx].stat = this.withdrawnActivities.length;
  //   this.cards[idx].data = this.withdrawnActivities;

  //   if (resetLocal) {
  //     this.saveDataToLocalDb(data);
  //   }

  //   this.cdr.detectChanges();
  // }

  saveDataToLocalDb(dataToSave: any) {
    this.adminDashReporService.clear();
    dataToSave.lastSaved = new Date();
    this.adminDashReporService.add(dataToSave, 1);
  }

  setFavouritesData(data) {
    this.myFavouriteReports = data;
  }
}
