import { Component, Input } from '@angular/core';

@Component({
  selector: 'view-only-field',
  templateUrl: './view-only-field.component.html',
  styleUrls: ['./view-only-field.component.scss']
})
export class ViewOnlyFieldComponent {

  @Input() label

}
