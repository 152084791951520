<mat-dialog-content>
  <div style="display: flex; margin-bottom: 10px; align-items: center;">
    <div style="flex-grow: 1"></div>
    <h3 style="margin: 0">{{weekStartDate | date: 'MMMM dd, y'}} - {{weekEndDate | date: 'MMMM dd, y'}}</h3>
    <div style="flex-grow: 0.87"></div>
    <button mat-button (click)="close()">
      Close
    </button>
  </div>

  <mat-tab-group [disableRipple]="true" style="border: 1px solid rgb(220, 220, 220); border-radius: 6px;">
    <!-- Timesheet -->
    <mat-tab label="Timesheet">
      <div class="table-container" *ngIf="dataSourceDetails">

        <table mat-table [dataSource]="dataSourceDetails" matSort class="mat-elevation-z0" [formGroup]="timesheetForm">

          <div *ngFor="let col of displayedColumnsDetails">
            <ng-container matColumnDef="{{col}}">
              <th mat-header-cell *matHeaderCellDef
                [style.padding]="'0 5px'"
                [style.display]="col.split(' ')[0] === 'Day' || col.split(' ')[1] === 'Hours'? 'table-cell' : 'none'"
                [style.text-align]="'center'"
                [style.color]="'#000'"
                [style.font-size]="'0.9rem'"
              >
                <span *ngIf="col.split(' ')[0] === 'Day' || col.split(' ')[1] === 'Hours'">
                  {{ makeHeaderNameFriendly(col.split(' ')[0]) }}
                </span>
              </th>

              <td mat-cell *matCellDef="let element"
                [style.text-align]="'center'"
                [style.display]="col.split(' ')[0] === 'Day' || col.split(' ')[1] === 'Hours'? 'table-cell' : 'none'"
                [style.font-size]="col.split(' ')[0] === 'Day' ? '1rem' : '0.9rem'"
                [style.width]="col.split(' ')[0] === 'WB' ? '160px' : '100px'"
                [style.background]="col.split(' ')[0] === 'DT' && col.split(' ')[1] === 'Hours'
                  && calculateDaylySum(element, 'DT Hours') + (calculateDaylySum(element, col.split(' ')[0] + ' Minutes') * 0.01) > 10 ? '#ffe6e6' : ''"
              >
                <div *ngIf="col.split(' ')[0] !== 'Day' && col.split(' ')[0] !== 'DT' && col.split(' ')[1] === 'Hours'">
                  <ng-container *ngIf="this.editTimesheet; else notEditable">
                    <ng-container *ngIf="this.isColumnEditable(col); else notEditable">
                      <input class="timesheet-input" type="number" step="1"
                        [style.color]="this.timesheetForm.controls[element['Day'].substring(0,3) + ' ' + col.split(' ')[0] + ' Hours'].errors !== null ? '#dd3f3f' : 'black'"
                        formControlName="{{element['Day'].substring(0,3) + ' ' + col.split(' ')[0] + ' Hours'}}"/><span class="timesheet-h">h</span>
                      <input class="timesheet-input" type="number" step="1"
                        [style.color]="this.timesheetForm.controls[element['Day'].substring(0,3) + ' ' + col.split(' ')[0] + ' Minutes'].errors !== null ? '#dd3f3f' : 'black'"
                        formControlName="{{element['Day'].substring(0,3) + ' ' + col.split(' ')[0] + ' Minutes'}}"/><span class="timesheet-m">m</span>
                    </ng-container>
                  </ng-container>

                  <ng-template #notEditable>
                    {{calculateHoursAndMinutes(element, col).hours}}<span class="timesheet-h">h</span>
                    {{calculateHoursAndMinutes(element, col).minutes}}<span class="timesheet-m">m</span>
                  </ng-template>
                </div>

                <div [class]="'totals'" *ngIf="col.split(' ')[0] === 'DT' && col.split(' ')[1] === 'Hours'">
                  {{calculateDaylySum(element, col)}}<span class="timesheet-h">h</span>
                  {{calculateDaylySum(element, col.split(' ')[0] + ' Minutes')}}<span class="timesheet-m">m</span>
                </div>

                <div *ngIf="col.split(' ')[0] === 'Day'">
                  {{element[col]}}
                </div>
              </td>

              <td mat-footer-cell *matFooterCellDef
                [style.text-align]="'center'"
                [style.display]="col.split(' ')[0] === 'Day' || col.split(' ')[1] === 'Hours'? 'table-cell' : 'none'"
              >
                <div class="totals" *ngIf="col.split(' ')[0] === 'Day' || col.split(' ')[1] === 'Hours'">
                  <ng-container *ngIf="col === 'Day'; else notDayColumn">
                    Weekly Totals:
                  </ng-container>

                  <ng-template #notDayColumn>
                    <ng-container *ngIf="col.split(' ')[0] !== 'DT'; else allSummary">
                      {{calculateWeeklySum(col)}}<span class="timesheet-h">h</span>
                      {{calculateWeeklySum(col.split(' ')[0] + ' Minutes')}}<span class="timesheet-m">m</span>
                    </ng-container>
                  </ng-template>

                  <ng-template #allSummary>
                    {{calculateAllSum(col)}}
                    <span class="timesheet-h">h</span>
                    {{calculateAllSum(col.split(' ')[0] + ' Minutes')}}
                    <span class="timesheet-m">m</span>
                  </ng-template>
                </div>
              </td>

            </ng-container>
          </div>


          <tr mat-header-row *matHeaderRowDef="displayedColumnsDetails"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDetails;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumnsDetails"></tr>

        </table>
      </div>
      <div style="display: flex; gap: 10px; justify-content: flex-end; margin-bottom: 20px" >
        <button class="mat-flat-button {{ this.editTimesheet ? '' : 'mat-primary'}}"
          (click)="this.editTimesheetToggle()"
        >
          {{ this.editTimesheet ? 'Cancel' : 'Edit' }}
        </button>
        <button *ngIf="this.editTimesheet" class="mat-flat-button mat-primary"
        (click)="this.saveTimesheetChanges()" [disabled]="this.savingTimesheet || this.timesheetForm.pristine"
        >
          {{ this.savingTimesheet ? 'Saving' : 'Save'}}
        </button>
      </div>
    </mat-tab>

    <!-- Finance Tab -->
    <mat-tab label="Finance Summary">
      <div class="table-container" *ngIf="dataSourceDetails">
          <div class="finance-grid-container">
            <ng-container [formGroup]="this.financeForm" style="display: contents">

              <!-- Allowance -->
              <div class="finance-header" style="grid-column: 1 / span 5; grid-row: 1 / span 1; border-right: 1px solid #DDD">Pay</div>
              <div class="finance-sub-header" style="grid-column: 1 / span 1; grid-row: 2 / span 1">Allowance</div>
              <div class="finance-value" style="grid-column: 1 / span 1; grid-row: 3 / span 1">£{{(this.data[0].allowance).toFixed(2)}}</div>
              <div class="finance-sub-header" style="grid-column: 2 / span 1; grid-row: 2 / span 1">Attendance</div>
              <div class="finance-value" style="grid-column: 2 / span 1; grid-row: 3 / span 1">{{this.math.round(this.data[0].attendance * 100)}}%</div>
              <div class="finance-sub-header" style="grid-column: 3 / span 1; grid-row: 2 / span 1">Accrued</div>
              <div class="finance-value" style="grid-column: 3 / span 1; grid-row: 3 / span 1">£{{(this.data[0].allowance * this.data[0].attendance).toFixed(2)}}</div>
              <div class="finance-sub-header" style="grid-column: 4 / span 1; grid-row: 2 / span 1">+</div>
              <div class="finance-value" style="grid-column: 4 / span 1; grid-row: 3 / span 1">
                £<span *ngIf="!this.editFinance">{{this.data[0].additionAllowance.toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance" [controlName]="'allowanceAddition'">
                </app-finance-currancy-input>
              </div>
              <div class="finance-sub-header" style="grid-column: 5 / span 1; grid-row: 2 / span 1">-</div>
              <div class="finance-value" style="grid-column: 5 / span 1; grid-row: 3 / span 1">
                £<span *ngIf="!this.editFinance">{{this.math.abs(this.data[0].deductionAllowance).toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance" [controlName]="'allowanceDeduction'">
                </app-finance-currancy-input>
              </div>

              <!-- Weekly Notes -->
              <div class="finance-header" style="grid-column: 6 / span 1; grid-row: 1 / span 1; border-right: 1px solid #DDD"> Week Notes </div>
              <div class="finance-textbox" style="grid-column: 6 / span 1; grid-row: 2 / span 5;" [style.padding]="this.editFinance ? '0' : '10px'">
                <span *ngIf="!this.editFinance">{{this.data[0].notes}}</span>
                <textarea *ngIf="this.editFinance" class="finance-textbox-input" formControlName="weeklyNote"></textarea>
              </div>

              <!-- Accrued Summary -->
              <div class="finance-header" style="grid-column: 7 / span 2; grid-row: 1 / span 1;">Accrued Summary</div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 2 / span 1">Pay</div>
              <div class="finance-value" style="grid-column: 8 / span 1; grid-row: 2 / span 1; border-bottom: 1px solid #DDD;">
                £{{this.attendanceAccuredTotal.toFixed(2)}}
              </div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 3 / span 1">Travel</div>
              <div class="finance-value" style="grid-column: 8 / span 1; grid-row: 3 / span 1; border-bottom: 1px solid #DDD;">
                £{{this.travelAccuredTotal.toFixed(2)}}
              </div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 4 / span 1">Meal</div>
              <div class="finance-value" style="grid-column: 8 / span 1; grid-row: 4 / span 1; border-bottom: 1px solid #DDD;">
                £{{this.mealAccuredTotal.toFixed(2)}}
              </div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 6 / span 1; border-bottom: 0px;">Total</div>
              <div class="finance-value" style="grid-column: 8 / span 1; grid-row: 6 / span 1">
                £{{this.accuredTotal.toFixed(2)}}
              </div>

              <!-- Travel -->
              <div class="finance-header" style="grid-column: 1 / span 5; grid-row: 5 / span 1; border-top: 1px solid #DDD;">Travel</div>
              <div class="finance-sub-header" style="grid-column: 1 / span 1; grid-row: 6 / span 1">Type</div>
              <div class="finance-value" style="grid-column: 1 / span 1; grid-row: 7 / span 1">
                <span *ngIf="!this.editFinance">{{this.travelTypeReadable(this.data[0].travelType)}}</span>
                <ng-select *ngIf="this.editFinance" class="finance-input finance-dropdown"
                  formControlName="travelType"
                  [clearable]="false"
                  [searchable]="false"
                  [clearOnBackspace]="false"
                >
                  <ng-option value="D">{{'Daily'}}</ng-option>
                  <ng-option value="W">{{'Weekly'}}</ng-option>
                  <ng-option value="M">{{'Mixed'}}</ng-option>
                  <ng-option value="P">{{'Petrol'}}</ng-option>
                </ng-select>
              </div>
              <div class="finance-sub-header" style="grid-column: 2 / span 1; grid-row: 6 / span 1">Total Cost</div>
              <div class="finance-value" style="grid-column: 2 / span 1; grid-row: 7 / span 1">
                £<span *ngIf="!this.editFinance">{{this.data[0].travel.toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance" [controlName]="'travelCost'">
                </app-finance-currancy-input>
              </div>
              <div class="finance-sub-header" style="grid-column: 3 / span 1; grid-row: 6 / span 1">Accrued</div>
              <div class="finance-value" style="grid-column: 3 / span 1; grid-row: 7 / span 1">£{{this.data[0].payTravel.toFixed(2)}}</div>
              <div class="finance-sub-header" style="grid-column: 4 / span 1; grid-row: 6 / span 1">+</div>
              <div class="finance-value" style="grid-column: 4 / span 1; grid-row: 7 / span 1">
                £<span *ngIf="!this.editFinance" >{{this.data[0].additionTravel.toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance" [controlName]="'travelAddition'">
                </app-finance-currancy-input>
              </div>
              <div class="finance-sub-header" style="grid-column: 5 / span 1; grid-row: 6 / span 1">-</div>
              <div class="finance-value" style="grid-column: 5 / span 1; grid-row: 7 / span 1">
                £<span *ngIf="!this.editFinance">{{this.math.abs(this.data[0].deductionTravel).toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance" [controlName]="'travelDeduction'">
                </app-finance-currancy-input>
              </div>

              <!-- Permanent Note -->
              <div class="finance-header" style="grid-column: 6 / span 1; grid-row: 7 / span 1; border-right: 1px solid #DDD; border-left: 1px solid #DDD;">Permanent Note</div>
              <div class="finance-textbox" style="grid-column: 6 / span 1; grid-row: 8 / span 5;" [style.padding]="this.editFinance ? '0' : '10px'">
                <span *ngIf="!this.editFinance">{{this.data[0].permanentNote}}</span>
                <textarea *ngIf="this.editFinance" class="finance-textbox-input" formControlName="permanentNote"></textarea>

              </div>

              <!-- Meal -->
              <div class="finance-header" style="grid-column: 1 / span 5; grid-row: 9 / span 1; border-top: 1px solid #DDD;">Meal Allowance</div>
              <div class="finance-sub-header" style="grid-column: 1 / span 1; grid-row: 10 / span 1">Daily Allowance</div>
              <div class="finance-value" style="grid-column: 1 / span 1; grid-row: 11 / span 1">£{{this.data[0].mealValue.toFixed(2)}}</div>
              <div class="finance-sub-header" style="grid-column: 2 / span 1; grid-row: 10 / span 1">Days</div>
              <div class="finance-value" style="grid-column: 2 / span 1; grid-row: 11 / span 1">{{this.data[0].mealDays}}</div>
              <div class="finance-sub-header" style="grid-column: 3 / span 1; grid-row: 10 / span 1">Accrued</div>
              <div class="finance-value" style="grid-column: 3 / span 1; grid-row: 11 / span 1">£{{(this.data[0].mealDays * this.data[0].mealValue).toFixed(2)}}</div>
              <div class="finance-sub-header" style="grid-column: 4 / span 1; grid-row: 10 / span 1">+</div>
              <div class="finance-value" style="grid-column: 4 / span 1; grid-row: 11 / span 1">
                £<span *ngIf="!this.editFinance">{{this.data[0].additionMeal.toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance" [controlName]="'mealAddition'">
                </app-finance-currancy-input>
              </div>
              <div class="finance-sub-header" style="grid-column: 5 / span 1; grid-row: 10 / span 1">-</div>
              <div class="finance-value" style="grid-column: 5 / span 1; grid-row: 11 / span 1">
                £<span *ngIf="!this.editFinance">{{this.math.abs(this.data[0].deductionMeal).toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance" [controlName]="'mealDeduction'">
                </app-finance-currancy-input>
              </div>

              <!-- Finance Received/Paid -->
              <div class="finance-header" style="grid-column: 7 / span 2; grid-row: 7 / span 1; border-top: 1px solid #DDD;">Finance Received/Paid</div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 8 / span 1">Pay</div>
              <div class="finance-value {{this.data[0].paidAllowance.toFixed(2) === this.attendanceAccuredTotal.toFixed(2) ? 'paidMatch' : 'paidMismatch'}}" style="grid-column: 8 / span 1; grid-row: 8 / span 1; border-bottom: 1px solid #DDD;">
                £<span *ngIf="(this.data[0].canEditFinancePaid && !this.editFinance) || !this.data[0].canEditFinancePaid">{{this.data[0].paidAllowance.toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance && this.data[0].canEditFinancePaid" [controlName]="'paidAllowance'">
                </app-finance-currancy-input>
              </div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 9 / span 1">Travel</div>
              <div class="finance-value {{this.data[0].paidTravel.toFixed(2) === this.travelAccuredTotal.toFixed(2) ? 'paidMatch' : 'paidMismatch'}}" style="grid-column: 8 / span 1; grid-row: 9 / span 1; border-bottom: 1px solid #DDD;">
                £<span *ngIf="(this.data[0].canEditFinancePaid && !this.editFinance) || !this.data[0].canEditFinancePaid">{{this.data[0].paidTravel.toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance && this.data[0].canEditFinancePaid" [controlName]="'paidTravel'">
                </app-finance-currancy-input>
              </div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 10 / span 1">Meal</div>
              <div class="finance-value {{this.data[0].paidMeal.toFixed(2) === this.mealAccuredTotal.toFixed(2) ? 'paidMatch' : 'paidMismatch'}}" style="grid-column: 8 / span 1; grid-row: 10 / span 1; border-bottom: 1px solid #DDD;">
                £<span *ngIf="(this.data[0].canEditFinancePaid && !this.editFinance) || !this.data[0].canEditFinancePaid">{{this.data[0].paidMeal.toFixed(2)}}</span>
                <app-finance-currancy-input [isActive]="this.editFinance && this.data[0].canEditFinancePaid" [controlName]="'paidMeal'">
                </app-finance-currancy-input>
              </div>
              <div class="finance-sub-header" style="grid-column: 7 / span 1; grid-row: 12 / span 1; border-bottom: 0px;">Total</div>
              <div class="finance-value {{this.totalPaid.toFixed(2) === this.accuredTotal.toFixed(2) ? 'paidMatch' : 'paidMismatch'}}" style="grid-column: 8 / span 1; grid-row: 12 / span 1">£{{this.totalPaid.toFixed(2)}}</div>
            </ng-container>
          </div>
      </div>

      <div style="display: flex; gap: 10px; justify-content: flex-end; margin-bottom: 20px" >
        <button class="mat-flat-button {{ this.editFinance ? '' : 'mat-primary'}}"
          (click)="this.editFinanceToggle()"
        >
          {{ this.editFinance ? 'Cancel' : 'Edit' }}
        </button>
        <button *ngIf="this.editFinance" class="mat-flat-button mat-primary"
        (click)="this.saveFinanceChanges()" [disabled]="this.savingFinance || this.financeForm.pristine"
        >
          {{ this.savingFinance ? 'Saving' : 'Save'}}
        </button>
      </div>

    </mat-tab>
  </mat-tab-group>

</mat-dialog-content>

