<div class="dashboard-nav-container">

  <div class="hide-small-screen">
    <div style="display: flex; flex-direction: row; gap: 8px">
      <div *ngFor="let page of this.learnerPages" >
        <button mat-stroked-button (click)="loadPage(page.tabName)" [ngClass]="this.currentTab == page.tabName ? 'dashboard-nav-button active' : 'dashboard-nav-button'" >{{page.label}}</button>
      </div>
    </div>
  </div>

  <div class="show-small-screen">
    <div style="line-height: 1.45; cursor: pointer; width: 180px; padding: 6px 0; border-bottom: solid 1px gray;">
      <mat-select [(value)]="selectedTab" (selectionChange)="loadPage(selectedTab.tabName)">
        <mat-option *ngFor="let page of this.learnerPages" [value]="page">
          {{page.label}}
        </mat-option>
      </mat-select>
    </div>
  </div>

</div>
