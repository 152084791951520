import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-trainee-essential-skills-report',
  templateUrl: './trainee-essential-skills-report.component.html'
})
export class TraineeEssentialSkillsReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.TraineeEssentialSkills;
  defaultDisplayColumns = [
    {
      type: 'AON',
      columns: ['fullName', 'scheme', 'sector', 'assessor', 'potStatus', 'aonDate', 'aonOverall', 'usingData', 'wholeNumbers', 'fractionsDecimalsPercentages', 'shapeSpacePosition', 'proportionRatioScale', 'formulasEquations', 'statisticsProbability', 'measuresMoneyTime'],
    },
    {
      type: 'COM',
      columns: ['fullName', 'scheme', 'sector', 'assessor', 'potStatus', 'comDate', 'comOverall', 'speakingListening', 'readUnderstandWords', 'readUnderstandText', 'readTextToFindInformation', 'writeRelevantAppropriateText', 'spellCorrectly', 'punctuateCorrectly', 'useCorrectGrammar'],
    },
    {
      type: 'DL',
      columns: ['fullName', 'scheme', 'sector', 'assessor', 'potStatus', 'dlDate', 'dlOverall', 'digitalResponsibility', 'digitalProductivity', 'digitalInformationLiteracy', 'digitalCollaboration', 'digitalCreativity', 'digitalLearning'],
    }
  ];
  dateFilters = ['startDate', 'expectedCompletionDate', 'terminationDate', 'aonDate', 'comDate', 'dlDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];
}
