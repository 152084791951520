import { SharedFunctionsService } from './../../core/services/shared-functions.service';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-employer-learner-list',
  templateUrl: './employer-learner-list.component.html',
  styleUrls: ['./employer-learner-list.component.scss']
})
export class EmployerLearnerListComponent implements OnInit {
  dataSource: any;
  hasLearners: boolean = true;
  displayedColumns: string[] = [];
  currentPageSize: number = 6;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() learners: any;
  @Output() pageSizeUpdated = new EventEmitter();

  dateFormat = DATE_FORMAT;

  constructor(private router: Router, private sharedFunctions: SharedFunctionsService) { }

  ngOnInit(): void {
    //  this.setCols(this.learners)
  }

  ngViewAfterInit() {
    console.log("here is the paginator", this.paginator)
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  pageUpdated(event) {
    console.log("doing something", event)
    if (event.pageSize != this.currentPageSize) {
      this.pageSizeUpdated.emit(event.pageSize);
    }
    this.currentPageSize = event.pageSize;
  }

  setCols(data) {
    if (data && data.length > 0) {
      console.log("we have some data", data)
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      let cols = Object.keys(data[0]);
      cols = cols.slice(2, 5);
      console.log("cols", cols)
      for (let col in cols) {
        console.log("adding", cols[col])
        this.displayedColumns.push(cols[col]);
      };
      this.displayedColumns.push('startdate')
    }
    else{
      this.hasLearners = false;
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // TO Add back in below?
  redirect(learner) {
    let traineeId = learner.traineeId;
    this.router.navigateByUrl(`learner/dashboard/${traineeId}`, { state: { originURL: this.router.url } });
  }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }
}
