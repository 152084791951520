import { Injectable } from "@angular/core";
import { ApiHelper } from "src/app/shared/api-helper";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AttendanceAnalysisService extends ApiHelper {
  BaseUrl = `${environment.API_URL}V2/Attendance/Analysis`;
  getSubContractor(staffId: number, pageNumber: number, pageSize: number, filterString: string, sortColumn: string = null, sortColumnDirection: string = null) {
    let url = `${this.BaseUrl}?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}&filterString=${filterString}`;
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    return this.getWithAccessToken(url);
  }
  getCentre(staffId: number, pageNumber: number, pageSize: number, filterString: string, sortColumn: string = null, sortColumnDirection: string = null) {
    let url = `${this.BaseUrl}/Level1?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}&filterString=${filterString}`;
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    return this.getWithAccessToken(url);
  }
  getLearners(staffId: number, pageNumber: number, pageSize: number, filterString: string, sortColumn: string = null, sortColumnDirection: string = null) {
    let url = `${this.BaseUrl}/Level2?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}&filterString=${filterString}`;
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    return this.getWithAccessToken(url);
  }
}
