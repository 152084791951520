import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import _ from 'underscore';
import { DiscussionFormComponent } from './discussion-form/discussion-form.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'jobs-growth-wales-plus',
  templateUrl: './jobs-growth-wales-plus.component.html',
  styleUrls: ['./jobs-growth-wales-plus.component.css']
})
export class JobsGrowthWalesPlus implements OnInit {

  @Input() learnerDetails: any;
  @Input() jgwInfo: any;
  @Input() centreOptions: any;
  @Input() sectorOptions: any;
  @Input() editPermissionLevel: number;

  @Output() jgwUpdate = new EventEmitter();

  show: boolean = false;
  jgwForm: UntypedFormGroup;
  editMode: boolean = false;
  formFieldStyle: string = 'none';
  dataSource = new MatTableDataSource([]);
  daysOfWeek: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  psmLocationName: string;
  centreName: string;
  sectorName: string;
  totalHours: number = 0;
  notificationSent: any;

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.dataSource.data = this.jgwInfo;
    this.notificationSent = this.datePipe.transform(this.jgwInfo.notificationSent, 'd MMM, y, h:mm:ss a');
    this.psmLocationName = this.jgwInfo.psmLocation ? this.centreOptions.find(element =>
      element.centreId === this.jgwInfo.psmLocation
    ).centre : null;
    this.buildForm();
    this.updateTotalHours();
  }

  buildForm() {
    this.centreName = this.jgwInfo.centre ? this.jgwInfo.centre : this.learnerDetails.centre;
    this.sectorName = this.jgwInfo.sector ? this.jgwInfo.sector : this.learnerDetails.sector;
    this.jgwForm = this.fb.group({
      'PSMDate': new UntypedFormControl({value: this.jgwInfo.psmDate, disabled: true}),
      'PSMTime':  new UntypedFormControl({value: this.jgwInfo.psmTime, disabled: true}),
      'PSMLocation':  new UntypedFormControl({value: this.jgwInfo.psmLocation, disabled: true}),
      'ExpectedStartDate': new UntypedFormControl({value: this.jgwInfo.expStartDate, disabled: true}),
      'AgreedStartDate' : new UntypedFormControl({value: this.jgwInfo.agreedStartDate, disabled: true}),
      'Discussion' : new UntypedFormControl({value: this.jgwInfo.discussion, disabled: true}),
      'TTMonday' : new UntypedFormControl({value: this.jgwInfo.ttMon, disabled: true}),
      'TTMondayHours' : new UntypedFormControl({value: this.jgwInfo.ttMonHours, disabled: true}),
      'TTTuesday' : new UntypedFormControl({value: this.jgwInfo.ttTue, disabled: true}),
      'TTTuesdayHours' : new UntypedFormControl({value: this.jgwInfo.ttTueHours, disabled: true}),
      'TTWednesday' : new UntypedFormControl({value: this.jgwInfo.ttWed, disabled: true}),
      'TTWednesdayHours' : new UntypedFormControl({value: this.jgwInfo.ttWedHours, disabled: true}),
      'TTThursday' : new UntypedFormControl({value: this.jgwInfo.ttThu, disabled: true}),
      'TTThursdayHours' : new UntypedFormControl({value: this.jgwInfo.ttThuHours, disabled: true}),
      'TTFriday' : new UntypedFormControl({value: this.jgwInfo.ttFri, disabled: true}),
      'TTFridayHours' : new UntypedFormControl({value: this.jgwInfo.ttFriHours, disabled: true}),
      'Centre' : new UntypedFormControl({value: this.centreName, disabled: true}),
      'Sector' : new UntypedFormControl({value: this.sectorName, disabled: true})
    });
    this.changeDetectorRef.detectChanges();
  }

  display(value) {
    this.show = value;
    this.changeDetectorRef.detectChanges();
  }

  discussionHtmlToText(html) {
    let textToShow;
    if (html) {
      // new line for closing p tags
      textToShow = html.replace('</p>','\n');
      // remove all other html tags
      textToShow = textToShow.replace(/<[^>]*>?/gm, '');
      // replace double enter with new lines
      textToShow = textToShow.replace(/&nbsp;/g, '\n\n');
    }
    else {
      textToShow = html;
    }
    return textToShow;
  }

  saveData() {
    if (this.editMode && this.jgwForm.dirty) {
      let returnData = { values: this.jgwForm.value, operation: 'update' };

      // Stops you from sending dates that are not valied to the api and replaces it with the original date
      if(this.jgwForm.get('ExpectedStartDate').hasError('matDatepickerParse')) {
        returnData.values.ExpectedStartDate = this.jgwInfo.expStartDate;
      }
      if(this.jgwForm.get('AgreedStartDate').hasError('matDatepickerParse')) {
        returnData.values.AgreedStartDate = this.jgwInfo.agreedStartDate;
      }
      if(this.jgwForm.get('PSMDate').hasError('matDatepickerParse')) {
        returnData.values.PSMDate = this.jgwInfo.psmDate;
      }


      this.jgwUpdate.emit({ 'jgwDetails': returnData });
      this.jgwForm.markAsUntouched();
      this.jgwForm.markAsPristine();
      this.jgwForm.controls['PSMTime'].disable();
    }
  }

  changeEditMode(editMode) {
    this.editMode = editMode;
    this.formFieldStyle = this.editMode ? 'standard' : 'none';

    if (this.editMode) {
      this.jgwForm.enable();
    } else {
      this.jgwForm.disable();
    }

    this.changeDetectorRef.detectChanges();
  }

  cancelEdit() {
    this.editMode = false;
    this.formFieldStyle = 'none';
    this.buildForm();
  }

  updateTotalHours(){
    this.totalHours = 0;
    for (let x in this.jgwForm.value) {
      if (x.toString().includes('Hours') && this.jgwForm.value[x]) {
        this.totalHours += parseFloat(this.jgwForm.value[x]);
      }
    }
  }

  dayToggled(event: any) {
    let ctrl = 'TT' + event.source.id + 'Hours';
    this.jgwForm.controls[ctrl].setValue(0);
    this.updateTotalHours();
  }

  getColours() {
    if (this.learnerDetails.scheme == 'Engagement') {
      if (this.totalHours == 0 || this.totalHours >= 31) {return 'red';}
      else if (this.totalHours >= 21 && this.totalHours < 31) {return 'amber';}
      else if (this.totalHours == 31) {return 'green';}
    }
    else if (this.learnerDetails.scheme == 'Advancement') {
      if (this.totalHours <= 15 || this.totalHours >= 41) {return 'red';}
      else if (this.totalHours >= 16 && this.totalHours < 30) {return 'amber';}
      else if (this.totalHours >= 30 && this.totalHours < 41) {return 'green';}
    }
    else {
      return 'black';
    }
  }

  openDiscussion() {
    if (this.editMode) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        discussion: this.jgwInfo.discussion
      };
      const dialogRef = this.dialog.open(DiscussionFormComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(formData => {
        if (formData) {
          this.jgwInfo.discussion = formData;
          this.jgwForm.controls.Discussion.setValue(formData);
          this.jgwForm.markAsDirty();
        }
      });
    }
  }

}
