import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  transform(yOrN: string) : string {
    if( yOrN == "Y"){
      return "Yes"
    }
    else if( yOrN == "N"){
      return "No"
    }
    else return yOrN;
  }

}
