<form id="larForm" [formGroup]="larForm">

    <div class="row">
        <h2 class="dashboard-titles" style="padding-left:0px;">Learning Aim Reference Editor</h2>
    </div>

    <div class="row">
        <div class="column-left">

            <mat-label>Learning Aim Reference</mat-label> <br>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="learningAimReference">
            </mat-form-field>
            <br>
            <mat-label>Title</mat-label> <br>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="title">
            </mat-form-field>
            <br>
            <mat-label>Title Short</mat-label> <br>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="titleShort">
            </mat-form-field>
            <br>
            <mat-label>Awarding Body</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.awardingBody"
                formControlName="awardingBody" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>Level</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.level"
                formControlName="level" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>Type</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.type"
                formControlName="type" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>Route</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.route"
                formControlName="route" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>LA 47</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.la47"
                formControlName="la47" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
        </div>

        <div class="column-right">
            <mat-label>Vocational</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.vocational"
                formControlName="vocational" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>ESW</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.esw" 
                formControlName="esw" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>LIT</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.lit" 
                formControlName="lit" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>NUM</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.num" 
                formControlName="num" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>ICT</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.ict" 
                formControlName="ict" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>OTH_KS</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.othKs"
                formControlName="othKs" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>MQ</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.mq" 
                formControlName="mq" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>TC</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.tc" 
                formControlName="tc" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
            <br>
            <mat-label>OTH</mat-label> <br>
            <ng-select appearance="outline" class="lar-input-field" [items]="selectableValues.oth" 
                formControlName="oth" bindLabel="value" notFoundText="No results found">
                <ng-template ng-option-tmp let-item="item">
                    {{item.label}}
                </ng-template>
            </ng-select>
        </div>
    </div>

    <div class="row-credit">        
        <div class="column-credit">
            <mat-label class="label-credit">Credits Min</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsMin">
            </mat-form-field>
            <br>
            <mat-label class="label-credit">Credits At Level</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsAtLevel">
            </mat-form-field>      
        </div>   
        <div class="column-credit">
            <mat-label class="label-credit">A Min</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsAMin">
            </mat-form-field>
            <br>
            <mat-label class="label-credit">A Max</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsAMax">
            </mat-form-field>
        </div>   
        <div class="column-credit">
            <mat-label class="label-credit">B Min</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsBMin">
            </mat-form-field>
            <br>
            <mat-label class="label-credit">B Max</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsBMax">
            </mat-form-field>
        </div>   
        <div class="column-credit">
            <mat-label class="label-credit">C Min</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsCMin">
            </mat-form-field>
            <br>
            <mat-label class="label-credit">C Max</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsCMax">
            </mat-form-field>
        </div>   
        <div class="column-credit">
            <mat-label class="label-credit">D Min</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsDMin">
            </mat-form-field>
            <br>
            <mat-label class="label-credit">D Max</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsDMax">
            </mat-form-field>
        </div>   
        <div class="column-credit">
            <mat-label class="label-credit">E Min</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsEMin">
            </mat-form-field>
            <br>
            <mat-label class="label-credit">E Max</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsEMax">
            </mat-form-field>
        </div>   
        <div class="column-credit">
            <mat-label class="label-credit">F Min</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsFMin">
            </mat-form-field>
            <br>
            <mat-label class="label-credit">F Max</mat-label>
            <mat-form-field appearance="outline" class="lar-input-field">
                <input matInput formControlName="creditsFMax">
            </mat-form-field>
        </div>   
    </div>

    <div class="row-footer">
        <div class="column-left">
            <button mat-button (click)="saveChanges()" mat-flat-button
                [ngClass]="{'button-enabled': isEdit && !larForm.pristine || !isEdit && larForm.valid, 'button-disabled': isEdit && larForm.pristine || !isEdit && !larForm.valid}">
                Save
            </button>
            <button *ngIf="isEdit" mat-button (click)="getUnitsAndOpenUnitsForm()" mat-flat-button color="secondary"
                class="close-button">View Units
            </button>
        </div>

        <div class="column-right">
            <button mat-button (click)="closeForm()" mat-flat-button color="secondary"
                class="close-button">Close</button>
        </div>
    </div>
</form>