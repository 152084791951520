import { Component, OnInit, Input, Output, ContentChild } from '@angular/core';

@Component({
  selector: 'learner-collapsible-section',
  templateUrl: './learner-collapsible-section.component.html',
  styleUrls: ['./learner-collapsible-section.component.scss']
})
export class LearnerCollapsibleSectionComponent implements OnInit {

  @Input() editPermissionLevel: number;
  @Input() name: string;
  @ContentChild('contentComponent') contentComponent;

  show: boolean = false;
  iconType: string = 'add_circle_outline';
  editMode: boolean = false;
  formFieldStyle: string = 'none';
  editIconStyle: string = 'edit';

  constructor() { }

  ngOnInit(): void {
  }

  showHide() {
    this.show = !this.show;
    this.iconType = this.show ? 'remove_circle_outline' : "add_circle_outline";
    if(this.contentComponent){
      this.contentComponent.display(this.show);
    }
  }

  toggleEdit() {
    if(this.editMode && this.contentComponent){
      this.contentComponent.saveData();
    }
    this.editMode = !this.editMode;
    this.editIconStyle = this.editMode ? 'save' : 'edit';

    if(this.contentComponent){
      this.contentComponent.changeEditMode(this.editMode);
    }
  }

  cancelEdit() {
    this.editMode = false;
    this.editIconStyle = 'edit';
    if(this.contentComponent){
      this.contentComponent.cancelEdit();
    }
  }

}
