import { SharedFunctionsService } from './../../../core/services/shared-functions.service';
import { Component } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DATE_FORMAT, NO_DATA } from 'src/app/app.constants';

@Component({
  selector: 'awards',
   templateUrl: './awards.component.html',
   styleUrls: ['./awards.component.scss']
})
export class AwardsComponent {

  displayedColumns: string[] = ['id', 'learningAimRef', 'title', 'dateEntered', 'dateResult', 'funding', 'type', 'result'];
  dateCols: string[] = ['dateEntered', 'dateResult'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  awards: any;
  loadedAwards: boolean = false;

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;

  constructor(
    public sharedFunctions: SharedFunctionsService,
    private cdf: ChangeDetectorRef,
    ){ }

  setData(data={'data': [], 'success': false, 'error': null}) {
    this.awards = data;
    if(this.awards.success) {
      this.dataSource.data = this.awards.data;
    } else {
      console.log('Error loading awards:', this.awards.error);
    }

    this.loadedAwards = true;
    this.cdf.detectChanges();
  }
}
