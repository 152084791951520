export interface IGenericSummaryTableData {
  column1: string;
  column2: string;
  column3: string;
  column4?: string;
  column5?: string;
  column6?: string;
  column7?: string;
  column8?: string;
  column9?: string;
  column10?: string;
}

export interface IPaginationMetaData {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export interface ITableFilter {
  column: string;
  value: any;
}

export interface IGroupBy {
  value: string;
  title: string;
}

export interface IChartData {
  rawDate: any;
  name: string;
  value: number;
}

export interface ISummaryGroupBy {
  value: string,
  title: string,
  columns: string[],
  pieChartDrillDown?: string,
  pieChartShareValue?: string,
}

export interface IDefaultDisplayColumns {
  type: string, columns: string[]
}
