<mat-card class="attendance-approval-card">
  <h2 class="dashboard-titles" style="padding-left:0px;">Attendance {{showApproved ? 'Approved For ' +
    showApprovedLearnerName : 'Approval' }}</h2>

  <mat-form-field style="width: 100%" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <input #txtVal matInput placeholder="Start typing to jump to a learner" (keyup)="applyFilter(txtVal.value)"
      autocomplete="off">
    <mat-slide-toggle [checked]="onlyMyLearnerStatus" (change)="toggleOnlyMyLearnerStatus($event)" matSuffix>Only My
      Learners</mat-slide-toggle>
  </mat-form-field>

  <mat-spinner *ngIf="this.showSpinner" style="position: absolute;
                        left: 15%;
                        top: 20%;">
  </mat-spinner>

  <div style="max-height: 65%; overflow-y: auto;">
    <table mat-table [dataSource]="dataSource" matSort class="attendance-approval-table">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="padding: 10px !important;">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Assessor Column -->
      <ng-container matColumnDef="assessor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tutor</th>
        <td mat-cell *matCellDef="let element"> {{element.assessor}} </td>
      </ng-container>

      <!-- Centre Column -->
      <ng-container matColumnDef="centre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Centre</th>
        <td mat-cell *matCellDef="let element"> {{element.centre}} </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!showApproved; else linkToTimetable">
            {{ isDate(element.date, "date") ? (element.date | date: this.dateFormat) : element.date }}
          </span>
          <ng-template #linkToTimetable>
            <a [href]="this.timeTable + '?date=' + element.date" target="_blank" class="clickable">
              {{ isDate(element.date, "date") ? (element.date | date: this.dateFormat) : element.date }}
            </a>
          </ng-template>
        </td>
      </ng-container>

      <!-- Time In Column -->
      <ng-container matColumnDef="timein">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time In </th>
        <td mat-cell *matCellDef="let element" class="{{
                  (element.timeinnew ? asTime(element.timeinnew) : asTime(element.timein)) < asTime(element.centreStartTime)
                  || (element.timeinnew ? asTime(element.timeinnew) : asTime(element.timein)) > asTime(element.centreEndTime)
                  ? 'red-text' : ''
                }}"> {{element.timeinnew ? asTime(element.timeinnew) : asTime(element.timein)}} </td>
      </ng-container>

      <!-- Time Out Column -->
      <ng-container matColumnDef="timeout">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time Out </th>
        <td mat-cell *matCellDef="let element" class="{{
                  (element.timeoutnew ? element.timeoutnew : element.timeout) === '17:00:00'
                  || (element.timeoutnew ? asTime(element.timeoutnew) : asTime(element.timeout)) > asTime(element.centreEndTime)
                  || (element.timeoutnew ? asTime(element.timeoutnew) : asTime(element.timeout)) < asTime(element.centreStartTime)
                  ? (element.timeoutnew ? element.timeoutnew : element.timeout) === '17:00:00' ? 'orange-text' : 'red-text'
                  : ''
                }}"> {{ element.timeoutnew ? asTime(element.timeoutnew) : asTime(element.timeout) }} </td>
      </ng-container>

      <!-- Type Approved Hours -->
      <ng-container matColumnDef="approvedHours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Hours already approved on this date for the learner" matTooltipPosition="above">
          Approved Hours
        </th>
        <td mat-cell *matCellDef="let element">
          <span (click)="element.approvedHours !== 0 ? showApprovedTimesForDay(element) : ''"
            class="{{ element.approvedHours !== 0 ? 'clickable' : '' }}">
            {{element.approvedHours}}
          </span>
        </td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let element"> <mat-icon (click)="edit(element)"
            style="width:auto;height:auto;">edit</mat-icon> </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">No Results Found</td>
      </tr>

    </table>
    <div style="display: flex; align-items: center; padding-left: 10px;">
      <div *ngIf="showApproved; else approveReject" style="display: flex; align-items: center;">
        <mat-icon *ngIf="showApproved" (click)="resetToAwaitingApproval()"
          style="margin-right: 20px;">keyboard_backspace</mat-icon>
      </div>
      <ng-template #approveReject>
        <button class="mat-raised-button mat-primary submit-button" mat-button (click)="approveOrReject(1)"
          [disabled]="this.showSpinner">Approve Selected</button>
        <button class="mat-raised-button mat-primary submit-button" style="margin-left: 10px;" mat-button
          (click)="approveOrReject(0)" [disabled]="this.showSpinner">Reject Selected</button>
      </ng-template>
      <div style="flex-grow: 1;"></div>
      <mat-paginator [pageSize]="4" [pageSizeOptions]="[4, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <!-- <div *ngIf="showLoading" style="margin-top: 25px; float: left">
        <h4>Processing attendance...please wait.</h4>
        <h4>Screen will refresh when complete.</h4>
    </div> -->


</mat-card>
