import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeDashboardService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken(params: HttpParams = null) {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options;
    if (params) {
      options = { headers: headers, params: params };
    } else {
      options = { headers: headers };
    }
    return options;
  }

  public setUserHomepage(staffId: number, homepage: string): Observable<any> {
    const url = `${environment.API_URL}SetDefaultHomePage`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    console.log("staff id", staffId)
    const postData = { homepage: homepage, staffId: staffId };
    return this.http.post(url, postData, options);
  }


  public getDataForAdminDashboard(): Observable<any> {
    const url = `${environment.API_URL}GetDataForAdminDashboard`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getYourLearners(staffId): Observable<any> {
    // For testing only
    //staffId = 624
    const url = `${environment.API_URL}GetYourLearners?staffId=${staffId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getCaseloadStats(staffId): Observable<any> {
    // For testing only
    //staffId = 624
    const url = `${environment.API_URL}GetCaseloadStats?staffId=${staffId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }


  public getCaseloadStatsManager(staffId, department = ""): Observable<any> {
    // For testing only
    // let staffIdTemp = 31
    const url = `${environment.API_URL}GetCaseloadStatsManager/${staffId}/${department}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getYourAssessors(staffId, department = ""): Observable<any> {
    // For testing only
    // let staffIdTemp = 31
    const url = `${environment.API_URL}GetYourAssessors/${staffId}/${department}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getSubcontractorStats(subcontractorId = 1): Observable<any> {
    const url = `${environment.API_URL}GetSubcontractorStats?subcontractorId=${subcontractorId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getYourEmployers(staffId): Observable<any> {
    // For testing only
    // let staffIdTemp = 983
    const url = `${environment.API_URL}GetYourEmployers?staffId=${staffId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getReviewCompliance(subcontractorId = 1): Observable<any> {
    const url = `${environment.API_URL}GetReviewCompliance?subcontractorId=${subcontractorId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);

    return this.http.get(url, options);
  }

  public getEmployerExpirations(createdByCompany = 'act'): Observable<any> {
    const url = `${environment.API_URL}GetEmployerExpirations?createdByCompany=${createdByCompany}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  // public addSelectedWidget(widgetId: number, staffId: number): Observable<any> {
  //   const url = `${environment.API_URL}addSelectedWidget?staffId=${staffId}&&widgetId=${widgetId}`;
  //   const params = new HttpParams();
  //   const options = this.getOptionsWithAccessToken(params);
  //   let postData = { staffId: staffId, widgetId: widgetId };
  //   return this.http.post(url, postData, options);
  // }

  public getLearningAimReferenceDictionary(): Observable<any> {
    const url = `${environment.API_URL}GetLearningAimReferenceDictionary`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getLaimRefDetails(selectedLaimRef: string): Observable<any> {
    const url = `${environment.API_URL}GetLearningAimRefDetails?laimRef=${selectedLaimRef}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public CreateLearningAimReference(values: any): Observable<any> {
    values.laimRef = values.learningAimReference;
    console.log('CreateLearningAimReference', values);
    const url = `${environment.API_URL}CreateLearningAimReference`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, values, options);
  }

  public updateLearningAimReference(values: any): Observable<any> {
    values.laimRef = values.learningAimReference;
    console.log('UpdateLearningAimReference', values);
    const url = `${environment.API_URL}UpdateLearningAimReference`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.patch<any>(url, values, options);
  }

  /*
  public importLearningAimUnitsFromCsv(laimRef: string): Observable<any> {
    const url = `${environment.API_URL}ImportLearningAimUnitsFromCsv?laimRef=${laimRef}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }


  //Not used - only use this if need API to import the CSV file, i.e. not via file picker/dnd directive
  public exportLearningAimUnitsAsCsv(laimRef: string): Observable<any> {
    const url = `${environment.API_URL}ExportLearningAimUnitsAsCsv?laimRef=${laimRef}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, null, options);
  }
  */
  public getLearningAimUnits(laimRef: string): Observable<any> {
    const url = `${environment.API_URL}GetLearningAimUnits?laimRef=${laimRef}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getLearningAimFormValues(): Observable<any> {
    const url = `${environment.API_URL}GetLearningAimFormValues`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getAttendanceToApprove(staffId): Observable<any> {
    const url = `${environment.API_URL}GetAttendanceToApprove?staffId=${staffId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public approveAttendance(values: any, staffId: number, decision: number): Observable<any> {
    const postData = { attendanceApprovals: values, staffId: staffId, decision: decision };
    console.log('approving attendance', postData);
    const url = `${environment.API_URL}ApproveAttendance`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.patch<any>(url, postData, options);
  }


  public authoriseAbsence(values: any): Observable<any> {
    console.log('authorise absence', values);
    const url = `${environment.API_URL}AuthoriseAbsence`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.patch<any>(url, values, options);
  }

  public getAbsenceToAuthorise(staffId): Observable<any> {
    const url = `${environment.API_URL}getAbsenceToAuthorise?staffId=${staffId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getContractManagement(staffId): Observable<any> {
    const url = `${environment.API_URL}getContractManagementSummary?staffId=${staffId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public createAuthorisedAbsences(values: any, staffId: number): Observable<any> {
    console.log('CreateAuthoisedAbsences', values);
    const data = { authorisedAbsences: values, staffId: staffId };
    const url = `${environment.API_URL}CreateAuthorisedAbsences`;
    const options = this.getOptionsWithAccessToken();
    console.log('CreateAuthorisedAbsences', data);
    return this.http.post<any>(url, data, options);
  }

  public GetDeliveryFundingPredictions(staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetDeliveryFundingPredictions?staffId=${staffId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getAttendanceForWidget(staffId, start = null, end = null, scheme = null): Observable<any> {
    let url = `${environment.API_URL}GetAttendanceForWidget?staffId=${staffId}`;
    if (start && end && !scheme) {
      url = `${environment.API_URL}GetAttendanceForWidget?staffId=${staffId}&&start=${start}&&end=${end}`;
    } else if (start && end && scheme) {
      url = `${environment.API_URL}GetAttendanceForWidget?staffId=${staffId}&&start=${start}&&end=${end}&&scheme=${scheme}`;
    }
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public GetRemittanceReport(subcontractorId: number, contractYearId: number): Observable<any> {
    const url = `${environment.API_URL}GetRemittanceReport?subcontractorId=${subcontractorId}&&contractYearId=${contractYearId}`;
    const params = new HttpParams()
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getReviewsDue(staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetReviewsRequiredReport?PageNumber=${1}&PageSize=${environment.reportMaxPageSize}&isOnlyActiveLearners=${true}&filterString=${null}&staffId=${staffId}`;
    const params = new HttpParams();
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getIalpsDue(staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetOneFileIalpsReport?PageNumber=${1}&PageSize=${environment.reportMaxPageSize}&isOnlyActiveLearners=${true}&filterString=${null}&staffId=${staffId}`;
    const params = new HttpParams();
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public getVastDataExport(): Observable<any> {
    const url = `${environment.API_URL}v2/vastdataexport/reportlist`;
    const params = new HttpParams();
    const options = this.getOptionsWithAccessToken(params);
    return this.http.get(url, options);
  }

  public postVastDataExport(report: number, format: string, filterString: string): Observable<any> {
    const payload = {
      reportId: report,
      reportFormat: format,
      filterString: filterString
    }

    const url = `${environment.API_URL}v2/vastdataexport/queueexport`;
    const params = new HttpParams();
    const options = this.getOptionsWithAccessToken(params);
    return this.http.post(url, payload, options);
  }


  //#region "Your Events"

  public getEvents(staffId: number, currentDashboard: string): Observable<any> {
    const url = `${environment.API_URL}GetStaffEvents?staffId=${staffId}&&currentDashboard=${currentDashboard}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public getEventModalData(staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetEventModalData/${staffId}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public getEmployers(): Observable<any> {
    const url = `${environment.API_URL}GetEmployerSearchList`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  //#endregion

}
