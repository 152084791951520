<div>
  <h1 class="progressTitle title">
    Objectives
  </h1>
  <div class="reportTable">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">

        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span *ngIf="column=='certAppliedFor'" class="col-certificate">Certificate Applied For</span>
          <span *ngIf="column=='certReceived'" class="col-certificate">Certificate Received</span>
          <span *ngIf="column!='certAppliedFor' && column!='certReceived'">{{ this.sharedFunctions.unCamelCase(column)}}</span>
        </th>

        <td mat-cell *matCellDef="let element"
          [ngClass]="column=='certReceived' ? 'col-certificate' : column=='certAppliedFor' ? 'col-certificate' : 'col-other'">

          <span *ngIf="column == 'certAppliedFor' || column == 'certReceived'">
            <img *ngIf="element[column] == 'Y'" src="../../../../assets/images/icon-yes.png">
            <img *ngIf="element[column] == 'N'" src="../../../../assets/images/icon-no.png">
          </span>

          <span *ngIf="column == 'percentComplete' ">
            {{element[column]/ 100 | percent }}
          </span>

          <span *ngIf="column === 'regDate' ">

            <div *ngIf="element[column] === null && element['regRequestedDate']; else regDate"
              style="display: flex; flex-direction: row; gap: 8px; align-items: center;"
            >
              <span style="cursor: default;"
                [matTooltip]="'By ' + element['regRequestedBy'] + ' on ' + (element['regRequestedDate'] | date: this.dateFormat)"
              >
                Requested
              </span>

              <mat-icon *ngIf="this.canDeleteRegRequests"
                style="color: rgb(145, 58, 58); cursor: pointer; font-size: 20px; width: 20px; height: 20px;"
                (click)="this.deleteRegRequest(element['requestId'])"
                [matTooltip]="'Delete'"
              >
                delete_forever
              </mat-icon>
            </div>

            <ng-template #regDate>
              {{element[column] === null ? '-' : element[column] | date: this.dateFormat}}
            </ng-template>
          </span>

          <span *ngIf="column != 'certAppliedFor' && column != 'certReceived' && column != 'percentComplete' && column != 'regDate' ">
            {{element[column] ?? '-'}}
          </span>

        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="this.loadedObjectives" class="mat-cell" colspan="9" style="text-align: center;">
          {{
            !this.objectives.success
            ? 'There was an issue getting objectives. Please reload or contant support if it contunues.'
            : this.noData
          }}
        </td>
      </tr>

    </table>
    <mat-progress-bar *ngIf="!this.loadedObjectives" mode="indeterminate"></mat-progress-bar>
  </div>
  <div style="margin-top: 30px; float: right;">
    <button mat-flat-button color="primary" class="create-button" (click)="requestRegistration()">
      New RegistrationRequest
    </button>
  </div>
</div>
