<h1 mat-dialog-title>Load Filter</h1>
<div mat-dialog-content>
    <div class="recent-filters" *ngIf="data.recentFilters != null && data.recentFilters.length != 0">
        <h2>Recent filters</h2>
        <div class="recent-filter" *ngFor="let recent of data.recentFilters">
            <div class="clear"></div>
            <span class="text-entry">
                <span *ngIf="data.recentFilterLoadedId == recent.id">*</span>
                Last viewed: {{getStoredTimeAsString(recent.epochTimeStored)}}</span> <button mat-stroked-button
                color="primary" class="load-button" (click)="loadRecentFilter(recent)">Load</button>
        </div>
    </div>

    <!-- {{data.storedFilters | json}} -->
    <h2>Stored reports</h2>
    <div class="recent-filter" *ngFor="let stored of data.storedFilters">
        <div class="clear"></div>
        <span class="text-entry">
            <span *ngIf="data.recentFilterLoadedId == stored.id">*</span>
            {{stored.name}}</span> <button mat-stroked-button color="primary" class="load-button"
            (click)="loadStoredFilter(stored)">Load</button>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" (click)="onNoClick()">Cancel</button>

</div>