import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';
import moment from 'moment';

@Component({
  selector: 'app-apprenticeship-provisional-achievers-report',
  templateUrl: './apprenticeship-provisional-achievers-report.component.html'
})
export class ApprenticeshipProvisionalAchieversReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.ApprenticeshipProvisionalAchievers;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'subcontractor', 'assessor', 'scheme', 'sector', 'startDate', 'terminationDate', 'frameworkCompleted']
    }
  ];
  dateFilters = ['startDate', 'expectedEndDate', 'terminationDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'scheme', title: 'Framework Completed By Scheme', columns: ['scheme', 'frameworkCompleted', 'count'],
      pieChartDrillDown: 'frameworkCompleted', pieChartShareValue: 'count'
    },
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];

  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch (column) {
      case 'exitProcessDate': {
        const weeks = moment().diff(moment(value), 'weeks');
        if (weeks >= 8) {
          style = { 'background-color': '#f5a4a4' };
        }
        break;
      }
    }
    return style;
  }
}
