<mat-toolbar *ngIf="isImpersonating">
  <span class="user-info">Logged in as {{ user.userName }} : {{ user.jobRole }}</span>
</mat-toolbar>
<mat-sidenav-container class="container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0" autosize="true">

  <div class="vision-sidenav">
    <div class="vision-sidenav-buttons-container" style="height: 100%;" *ngIf="isLoggedIn">

      <div style="margin: 0 0; margin-top: 10px;">
        <mat-icon svgIcon="Logo" style="width: 35px; height: 35px;"></mat-icon>
      </div>

      <div class="vision-sidenav-buttons" routerLink="." (click)="activatedNavLink()">
        <mat-icon matTooltip="Dashboard" class="menu-icon" [ngClass]="{'menu-icon-selected' : activeURL == '/'}"
          svgIcon="Dashboard"></mat-icon>
      </div>

      <div *ngIf="this.permissions.includes(this.SitePermissions.BdHomepageView)" class="vision-sidenav-buttons" routerLink="/bd-homepage/" (click)="activatedNavLink()">
        <mat-icon matTooltip="BD Homepage" class="menu-icon" style="color: white; width: 30px;" [ngClass]="{'menu-icon-selected' : activeURL == '/bd-homepage'}"
          svgIcon="bd-homepage"></mat-icon>
      </div>

      <div class="vision-sidenav-buttons" routerLink="/learner/learner-search" (click)="activatedNavLink()">
        <mat-icon matTooltip="Learner" class="menu-icon"
          [ngClass]="{'menu-icon-selected' : activeURL.includes('/learner/')}" svgIcon="Learner"></mat-icon>
      </div>

      <div class="vision-sidenav-buttons" routerLink="employer/search" (click)="activatedNavLink()">
        <mat-icon matTooltip="Employer" class="menu-icon"
          [ngClass]="{'menu-icon-selected' : activeURL.includes('/employer')}" svgIcon="EmployerMenu"></mat-icon>
      </div>

      <div class="vision-sidenav-buttons" routerLink="/reports/index" (click)="activatedNavLink()">
        <mat-icon matTooltip="Reports" class="menu-icon"
          [ngClass]="{'menu-icon-selected' : activeURL.includes('/reports/')}" svgIcon="Reports"></mat-icon>
      </div>

      <div class="vision-sidenav-buttons" routerLink="/attendance/authorisedAbsence" (click)="activatedNavLink()">
        <mat-icon matTooltip="Attendance" class="menu-icon"
          [ngClass]="{'menu-icon-selected' : activeURL.includes('/attendance/')}" svgIcon="Register"></mat-icon>
      </div>

      <div class="vision-sidenav-buttons" routerLink="/staff/staff-dashboard" (click)="activatedNavLink()">
        <mat-icon matTooltip="Staff" class="menu-icon"
          [ngClass]="{'menu-icon-selected' : activeURL == '/staff/staff-dashboard'}" svgIcon="Staff"></mat-icon>
      </div>

      <div style="flex: 1"></div>

      <div class="vision-sidenav-buttons" style="display: flex; align-items: end; padding-bottom: 20px;">
        <button mat-icon-button [matMenuTriggerFor]="userOptions" aria-label="User Initials Dropdown menu">
          <div class="menu-user-icon">
            {{initials}}
            <div *ngIf="initials" [ngClass]="countUnread > 0 ? 'circle-red status-circle' : 'status-no-display'"></div>
          </div>
        </button>
        <mat-menu #userOptions="matMenu">
          <button mat-menu-item routerLink="/notifications" (click)="activatedNavLink()">
            <mat-icon class="matMenu-button">notifications</mat-icon>
            <span>Notifications</span>
          </button>
          <button mat-menu-item (click)="navigateToStatusPage()">
            <mat-icon class="matMenu-button">check_circle_outline</mat-icon>
            <span>Vision Status</span>
          </button>
          <button *ngIf="this.permissions.includes(this.SitePermissions.Admin)" mat-menu-item routerLink="/admin"
            (click)="activatedNavLink()">
            <mat-icon class="matMenu-button">settings</mat-icon>
            <span>Admin</span>
          </button>
          <button *ngIf="isImpersonating" mat-menu-item (click)="cancelImpersonation()">
            <mat-icon class="matMenu-button">face</mat-icon>
            <span>Stop Impersonation</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon class="matMenu-button">logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>


  <div class="page-container">
    <main>
      <router-outlet (activate)="onActivate($event)" style="margin-top: 80px;"></router-outlet>
    </main>
    <div class="centre-page" *ngIf="pageRefreshButton">
      <button mat-button color="warn" (click)="refreshPage()" cdkFocusInitial>Refresh page</button>
    </div>
    <div class="bottom-right">
      <span class="version">v{{version}}</span>
      <span *ngIf="isLoggedIn">
        <frill-widget>Widget</frill-widget>
      </span>
    </div>
  </div>
</mat-sidenav-container>
