


<div class="file-drop-container" appDnd (fileDropped)="onFileDropped($event)">
  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
  <img src="assets/dnd/ic-upload-file.svg" alt="">
  <h3>Drag and drop file here</h3>
  <h3>or</h3>
  <label for="fileDropRef">Browse for file</label>
</div>


<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <img src="assets/dnd/ic-file.svg" width="45px" alt="file">
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-progress [progress]="file?.progress"></app-progress>
    </div>
    <img src="assets/dnd/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
    <button mat-button [mat-dialog-close]="true" *ngIf="uploadDone">
      <!-- <img src="assets/images/dnd/check-mark-3-24.ico" width="24px" alt="upload done"> -->
      <mat-icon class="milestone-icon color_green">check_circle_outline</mat-icon>  
    </button>
  </div>
</div>

<div style="float:right">
    <button mat-button (click)="close()" class="export-all" [style.width]="'auto'">Close</button>  <br>
</div>
