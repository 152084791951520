import { Injectable } from "@angular/core";
import { ValidatorFn, FormGroup, ValidationErrors, AbstractControl, FormControl } from "@angular/forms";
import { StringHelper } from "./string-helper";
import { DateHelper } from "./date-helper";

export class FormControlWarn extends FormControl { warnings: any; }

@Injectable({
  providedIn: 'root'
})
export class FromValidators {

  constructor(
    private stringHelper: StringHelper,
    private dateHelper: DateHelper
  ) { }

  /**
   * @description
   * Validator that checks that at least one of the controls in the keys has a value.
   * @remarks
   * This validator is meant to be used with the `FormControl` class.
   * @param keys - The keys of the controls to check. e.g ['assessorIdIn', 'centreIdIn']
   * @returns `ValidationErrors | null`
   */
  atLeastOneValidator(keys: string[]): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const { controls } = group;
      return keys.some(key => controls[key] &&  typeof(controls[key].value) === 'string' ? this.stringHelper.notNullOrEmpty(controls[key].value) : controls[key].value !== null)
        ? null
        : { emptyForm: true };
    };
  }

/**
 *
 * @@description
 * Validator that checks that if one control has a value the other also has a value.
 * @remarks
 * This validator is meant to be used with the `FormGroup` class.
 * @param keys
 * @returns  `ValidationErrors | null`
 */
  bothControlsRequiredValidator(controlName1: string, controlName2: string) {
    return (group: FormGroup): ValidationErrors | null => {
      const control1 = group.get(controlName1);
      const control2 = group.get(controlName2);

      // If one control is set but the other isn't, return the validation error
      if ((control1.value !== null && control2.value === null) || (control2.value !== null && control1.value === null)) {
        return { bothControlsRequired: true };
      }
      return null;
    };
  }

  /**
   * @description
   * Validator that requires the control's value to be surrounded in a single quote.
   * @remarks
   * This validator is meant to be used with the `FormControl` class. It can also check for multiple values separated by a comma.
   * @returns `ValidationErrors | null`
   */
  quoteCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.stringHelper.notNullOrEmpty(control.value) && !/^'[^']*'(, *'[^']*')*$/.test(control.value) ? { incorrect: true } : null;
    };
  }

  /**
   * @description
   * Validator that checks to see if a value is on a weekend.
   * @remarks
   * This validator is meant to be used with the `FormControl` class.
   * If the range is a single day over a weekend it will return an error.
   * If the range is the entire weekend it will return an error.
   * If the range is multiple days and spans a weekend it will warn the user.
   * @returns `ValidationErrors | null`
   */
  isOverWeekend(): ValidatorFn {
    return (control: FormControlWarn): ValidationErrors | null => {
      control.warnings = null;
      if (control.value !== null && control.value.startDate !== null && control.value.endDate !== null) {
        const startDate = new Date(control.value.startDate);
        const endDate = new Date(control.value.endDate);
        const dayDifference = this.dateHelper.calculateDayDiff(startDate, endDate);
        //Check a single day. Throw error if it falls on a weekend.
        if (dayDifference == 0) {
          const startDay = startDate.getDay();
          const endDay = endDate.getDay();
          if (startDay == 0 || startDay == 6 || endDay == 0 || endDay == 6) {
            return { onlyWeekend: true };
          }
        }
        else {
          //check for saturday and sunday selection. Throw error if it spans a weekend only.
          if (dayDifference == 1) {
            if (startDate.getDay() == 6 && endDate.getDay() == 0) {
              return { onlyWeekend: true };
            }
          }
          else {
            //check for multiple days. Just warn user as they may just want to ignore the weekend.
            for (let i = 0; i <= dayDifference; i++) {
              const date = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i)).getDay();
              if (date == 0 || date == 6) {
                control.warnings = { overWeekend: true };
              }
            }
          }
        }
        return null;
      }
    }
  }
  /**
   * @description
   * Validator that checks to see if the date range exceeds the max days.
   * @param maxDays
   * @returns
   */
  exceedsMaxDays(maxDays: number): ValidatorFn {
    return (control: FormControlWarn): ValidationErrors | null => {
      control.warnings = null;
      if (control.value !== null && control.value.startDate !== null && control.value.endDate !== null) {
        const startDate = new Date(control.value.startDate);
        const endDate = new Date(control.value.endDate);
        const dayDifference = this.dateHelper.calculateDayDiff(startDate, endDate);
        if (dayDifference > maxDays) {
          return { exceedsMaxDays: true };
        }
      }
    }
  }
  /**
   * @description
   * This is a generic time check. It checks if the time is before or after a given time.
   * @param timeToCompare
   */
  timeIsBefore(timeToCompare: string): ValidatorFn {
    return (control: FormControlWarn): ValidationErrors | null => {
      control.warnings = null;
      if (this.stringHelper.notNullOrEmpty(control.value) && this.stringHelper.notNullOrEmpty(timeToCompare)) {
        if (this.stringHelper.notNullOrEmpty(control.value)) {
          if (this.dateHelper.timeIsBefore(control.value, timeToCompare)) {
            return { timeIsBefore: true };
          }
        }
      }
    }
  }
  /**
   * @description
   * This is a generic time check. It checks if the time is before or after a given time.
   * @param timeToCompare
   */
  timeIsAfter(timeToCompare: string): ValidatorFn {
    return (control: FormControlWarn): ValidationErrors | null => {
      control.warnings = null;
      if (this.stringHelper.notNullOrEmpty(control.value) && this.stringHelper.notNullOrEmpty(timeToCompare)) {
        if (this.stringHelper.notNullOrEmpty(control.value)) {
          if (this.dateHelper.timeIsAfter(control.value, timeToCompare)) {
            return { timeIsAfter: true };
          }
        }
      }
    }
  }
  /**
   * @description
   * This is a specific time check. It checks if the time is after the learner sign out time.
   * @param timeToCompare
   */
  timeIsAfterOtherInput(timeToCompare: string): ValidatorFn {
    return (control: FormControlWarn): ValidationErrors | null => {
      control.warnings = null;
      if (this.stringHelper.notNullOrEmpty(control.value) && this.stringHelper.notNullOrEmpty(timeToCompare)) {
        console.dir(`TIME IS AFTER OTHER INPUT: ${control.value} ${timeToCompare} RESULT: ${this.dateHelper.timeIsAfter(control.value, timeToCompare)}`)
        if (this.dateHelper.timeIsAfter(control.value, timeToCompare)) {
          return { timeIsAfterOtherInput: true };
        }
      }
    }
  }
  /**
  * @description
  * This is a specific time check. It checks if the time is before the learner sign out time.
  * @param timeToCompare
  */
  timeIsBeforeOtherInput(timeToCompare: string): ValidatorFn {
    return (control: FormControlWarn): ValidationErrors | null => {
      control.warnings = null;
      if (this.stringHelper.notNullOrEmpty(control.value) && this.stringHelper.notNullOrEmpty(timeToCompare)) {
        console.dir(`TIME IS BEFORE OTHER INPUT: ${control.value} ${timeToCompare} RESULT:${this.dateHelper.timeIsBefore(control.value, timeToCompare)}`)
        if (this.dateHelper.timeIsBefore(control.value, timeToCompare)) {
          return { timeIsBeforeOtherInput: true };
        }
      }
    }
  }
}
