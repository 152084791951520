<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>

  <form [formGroup]="sendMessageForm" novalidate>

    <mat-dialog-content class="new-record-form">
      <mat-divider class="iqaformDivider"></mat-divider>

      <mat-label class=reviewFormTitle>Subject</mat-label>
      <mat-form-field class="iqa-new-record" appearance="outline">
        <input cdkFocusInitial matInput formControlName="subject">
      </mat-form-field>

      <mat-label class=reviewFormTitle>Message</mat-label>

      <ckeditor class="multi-line white" id="message" [editor]="Editor" [config]="editorConfig"
        formControlName="message" data=""></ckeditor>

    </mat-dialog-content>

    <span>
      email will be sent from <b>{{data.senderEmail}}</b>
    </span>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="sendMessage()" cdkFocusInitial>Send</button>
</div>
