<mat-icon *ngIf="isEditForm" class="icon-add" (click)="addNew()">add_circle</mat-icon>
<h2 class="progressTitle text-vertical-align">Contacts
  
</h2>

<form id="myForm" [formGroup]="contactForm" style="margin-left: 1em;" >
  <div  style="margin: 10px 10px 10px 0;"
  [formArrayName]="'contactsArray'">
    <div class="contact-tile" *ngFor="let con of contacts?.slice(numStrt, numEnd); index as i" >
      <div [formGroupName]="numStrt + i">
        <h4 style="margin-bottom: 0px; margin-top:10px">{{numStrt + i + 1}}</h4>
        <mat-form-field class="contacts-form-field">
          <mat-label>Name </mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field class="contacts-form-field">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let conType of availableContactTypes(con.contactType)" [value]="conType">
              {{conType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="contacts-form-field">
          <mat-label>Phone </mat-label>
          <input matInput formControlName="phone">
          <mat-icon matSuffix (click)="copyPhone(numStrt + i)">content_copy</mat-icon>
        </mat-form-field>
        <mat-form-field class="contacts-form-field">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email">
          <mat-icon matSuffix (click)="copyEmail(numStrt + i)">content_copy</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<mat-icon class="icon-next" (click)="loadNextContacts()" *ngIf="!(numStrt == 0 && contacts.length <= 2)">chevron_right</mat-icon>

