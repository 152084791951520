import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';


@Component({
  selector: 'app-review-list-report',
  templateUrl: './review-list-report.component.html'
})
export class ReviewListReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.ReviewList;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'subcontractor', 'programmeType', 'sector', 'assessor', 'centre', 'daysToReview', 'lastReview', 'nextReview', 'plannedVisitDate']
    }
  ];
  dateFilters = ['startDate', 'expectedEndDate', 'lastReview', 'nextReview', 'plannedVisitDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];

  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch (column) {
      case 'daysToReview': {
        if (value < 0) {
          style = { 'background-color': '#f5a4a4' };
        }
        else if ((row['programmeType'] === "JGW+" && value >= 0 && value < 7) || (row['programmeType'] !== "JGW+" && value >= 0 && value < 14)) {
          style = { 'background-color': '#fcb549' };
        }
        else {
          style = { 'background-color': '#adffcb' };
        }
        return style;
      }
    }
  }
}
