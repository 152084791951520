import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { select } from "underscore";

@Component({
  selector: 'app-recent-visits--report',
  templateUrl: './recent-visits-report.component.html',
})
export class RecentVisitsReportComponent {

  reportName = "Recent Visits Report";
  reportVariant = ReportVariant.RecentVisits;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: [
        'fullname',
        'sector',
        'programmeType',
        'potStatus',
        'mostRecentReview',
        'mostRecentAssessmentPlan',
        'mostRecentSignedAssessmentPlan',
        'assessor',
        'startDate',
        'expCompletionDate'
      ]
    }
  ];
  dateFilters = [
    'startDate',
    'expCompletionDate',
    'mostRecentReview',
    'mostRecentAssessmentPlan',
    'mostRecentSignedAssessmentPlan'
  ];
  otherColumns = ['scheme', 'count'];

}
