import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialsModule } from './../core/materials/materials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LearnerSearchComponent } from './learner-search/learner-search.component';
import { LearnerCardComponent } from './shared/cards/learner-card/learner-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchLayoutComponent } from './shared/search-layout/search-layout.component';
import { SearchVirtualScrollComponent } from './shared/search-virtual-scroll/search-virtual-scroll.component';
import { EmployerCardComponent } from './shared/cards/employer-card/employer-card.component';
import { EmployerSearchComponent } from './employer-search/employer-search.component';



@NgModule({
    declarations: [
        LearnerSearchComponent,
        LearnerCardComponent,
        EmployerSearchComponent,
        EmployerCardComponent,
        SearchLayoutComponent,
        SearchVirtualScrollComponent
    ],
    imports: [
        CommonModule,
        MaterialsModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        NgSelectModule,
    ],
    providers: [
    ],
})
export class SearchModule { }
