import { ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'attendance-analysis',
  templateUrl: './attendance-analysis.component.html',
  styleUrls: ['./attendance-analysis.component.scss']
})
export class AttendanceAnalysisComponent implements OnInit {
  @HostBinding('class') class = 'active-widget';
  groupedBySubcontractor: any;
  groupedByCentre: any;
  learnerAttendance: any;
  drilled: boolean = false;
  dataType: number = 1;
  staffId: number;

  selectedScheme;
  schemeOptions;

  startDate: Date;
  endDate: Date;
  dateRangeSelected: { start: moment.Moment, end: moment.Moment };
  dateRangeSelectedString: { start: string, end: string };
  dataFiltered: boolean = false;
  ranges: any;
  masterData: any;

  showSpinner: boolean = false;
  SubcontractorAvg: boolean = false;

  widgetId: number;
  dataSource: any;
  displayedColumns: string[] = [
    'subcontractor',
    'cbPercentage',
    'wbPercentage',
    'virtualPercentage',
    'learnPercentage',
    'aaPercentage',
    'holPercentage',
    'uaPercentage'
  ];

  dateFormat = DATE_FORMAT;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('datePicker') datePicker;

  constructor(
    private cdr: ChangeDetectorRef,
    private sharedFunctions: SharedFunctionsService,
    private homeDashboardService: HomeDashboardService
  ) { }


  export(fileExtension: string) {
    //run through the properties
    const showSubcontractorAverage = this.SubcontractorAvg && this.dataType === 2 ? true : false;
    var data = this.dataSource.filteredData.map(a => { return { ...a } });
    if (this.dataType !== 3) {
      var averageData = [];
      if (showSubcontractorAverage) {
        averageData = this.dataSource.filteredData.map(a => { return { ...a } });
      } else {
        averageData = this.groupedBySubcontractor.map(a => { return { ...a } });
      }
      var average = averageData.reduce((previous, current, index, array) => {
        var currentCopy = { ...current };
        Object.keys(current).forEach(key => {
          if (key === 'centre' || key === 'subcontractor' || key === 'fullname') {
            if (showSubcontractorAverage) {
              currentCopy[key] = 'subcontractor Average';
            } else {
              currentCopy[key] = 'Overall Average';
            }
          }
          else {
            currentCopy[key] += previous[key]
            if (index === array.length - 1) currentCopy[key] /= array.length
          }
        })
        return currentCopy;
      });
      data.push(average);
    }
    this.sharedFunctions.export(data, this.displayedColumns, 'Attendance Analysis', fileExtension);
  }

  ngOnInit(): void {
    this.dataType = 1;
    this.setRanges();
  }

  paginatorAndSort() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setCols(data) {
    if (data) {
      this.masterData = data;
      this.groupedBySubcontractor =
        data['attendanceAnalysisGroupedBySubcontractor'];
      this.groupedByCentre = data['attendanceAnalysisGroupedByCentre'];
      this.learnerAttendance = data['attendanceAnalysis'];
      this.dataSource = new MatTableDataSource(this.groupedBySubcontractor);
      this.resetDisplayedColumns();
      this.dataType = 1;
      this.drilled = false;
      this.paginatorAndSort();
      // let cols = Object.keys(this.groupedBySubcontractor[0]);
      // for (let col in cols) {
      //   this.displayedColumns.push(cols[col]);
      // };
    }
  }

  resetDisplayedColumns() {
    this.displayedColumns = [
      'subcontractor',
      'cbPercentage',
      'wbPercentage',
      'virtualPercentage',
      'learnPercentage',
      'aaPercentage',
      'holPercentage',
      'uaPercentage'
    ];
  }

  drilldown(type, val) {
    if (type == 'subcontractor') {
      let newData = this.groupedByCentre.filter(
        centre => centre.subcontractor == val
      );
      this.dataSource = new MatTableDataSource(newData);
      this.displayedColumns = [
        'centre',
        'cbPercentage',
        'wbPercentage',
        'virtualPercentage',
        'learnPercentage',
        'aaPercentage',
        'holPercentage',
        'uaPercentage'
      ];
      this.dataType = 2;
      this.paginatorAndSort();
      this.cdr.detectChanges();
    }
    if (type == 'centre') {
      let newData = this.learnerAttendance.filter(
        learner => learner.centre == val
      );
      this.dataSource = new MatTableDataSource(newData);
      this.displayedColumns = [
        'fullName',
        'weekStartDate',
        'cbHours',
        'wbHours',
        'virtualHours',
        'learnHours',
        'aaHours',
        'hHours',
        'uaHours',
        'totalHours',
        'fundedHours',
        'scheme'
      ];
      this.paginatorAndSort();
      this.dataType = 3;
      this.cdr.detectChanges();
    }
    this.drilled = true;
  }

  resetVars() {
    this.dataType = 1;
    this.drilled = false;
    this.dateRangeSelected = null;
    this.dateRangeSelectedString = null;
  }

  reset() {
    this.dataSource = new MatTableDataSource();
    //this.cdr.detectChanges()

    this.groupedBySubcontractor = null;
    this.homeDashboardService
      .getAttendanceForWidget(this.staffId)
      .subscribe(data => {
        if (data) {
          this.setCols(data);
          this.setSchemeOptions(data);
          this.displayedColumns = [
            'subcontractor',
            'cbPercentage',
            'wbPercentage',
            'virtualPercentage',
            'learnPercentage',
            'aaPercentage',
            'holPercentage',
            'uaPercentage'
          ];
          this.paginatorAndSort();
          this.cdr.detectChanges();
        }
      });

    this.resetVars();
  }

  // (datesUpdated)="dateRangeChange($event)"

  unCamelCase(str: string) {
    return (
      str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  }

  isDate(value, column): boolean {
    return this.sharedFunctions.isDateFromColumnName(value, column);
  }

  initialApiCall() {
    this.setRanges();
    let start: string = this.ranges['Last 14 Days'][0].format('YYYY/MM/DD');
    let end: string = this.ranges['Last 14 Days'][1].format('YYYY/MM/DD');
    const scheme = null;
    this.dateRangeSelected = { start: moment(start), end: moment(end) };
    this.dateRangeSelectedString = { start: start, end: end };
    this.homeDashboardService
      .getAttendanceForWidget(this.staffId, start, end, scheme)
      .subscribe(data => {
        if (data) {
          this.setCols(data);
          this.setSchemeOptions(data);
          this.paginatorAndSort();
          this.cdr.detectChanges();
          this.dataFiltered = true;
        }
      });
  }

  setSchemeOptions(data: Array<any>) {
    this.schemeOptions = [...new Set(data['attendanceAnalysis'].map(a => a.scheme))];
  }

  schemeChange(scheme: any) {
    this.showSpinner = true;
    this.dataFiltered = true;
    this.dataSource = new MatTableDataSource();
    let start = this.dateRangeSelectedString.start;
    let end = this.dateRangeSelectedString.end;
    this.homeDashboardService
      .getAttendanceForWidget(this.staffId, start, end, scheme)
      .subscribe(data => {
        if (data) {
          this.setCols(data);
          this.paginatorAndSort();
          this.cdr.detectChanges();
        }
        this.showSpinner = false;
      });
  }

  schemeSelectCleared() {
    this.selectedScheme = [];
  }

  dateRangeChange(event: any) {
    this.showSpinner = true;
    let startDate: moment.Moment = event.start;
    let endDate: moment.Moment = event.end;

    if (event.start && event.end) {
      this.dataFiltered = true;
      this.dataSource = new MatTableDataSource();
      switch (this.dataType) {
        case 1:
        case 2:
          {
            let start = startDate.format('YYYY/MM/DD');
            let end = endDate.format('YYYY/MM/DD');
            this.dateRangeSelectedString = { start: start, end: end };
            this.homeDashboardService
              .getAttendanceForWidget(this.staffId, start, end)
              .subscribe(data => {
                if (data) {
                  this.setCols(data);
                  this.setSchemeOptions(data);
                  this.paginatorAndSort();
                }
                this.showSpinner = false;
              });
          }
          break;
        case 3: {
          this.startDate = startDate.toDate();
          this.endDate = endDate.toDate();
          this.dataSource.data = this.dataSource.data.filter(
            e =>
              new Date(e.weekstartdate) >= this.startDate &&
              new Date(e.weekstartdate) <= this.endDate
          );
          this.showSpinner = false;
        }
      }
    } else {
      this.showSpinner = false;
    }
    this.cdr.detectChanges();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  indicatorColour(value: number): string {
    let colour = '#fff';
    if (value < 75) {
      colour = '#c92020';
    } else if (value >= 75 && value <= 79) {
      colour = '#f7700f';
    } else if (value >= 80 && value <= 89) {
      colour = '#9afc9f';
    } else if (value >= 90) {
      colour = '#02c21c';
    }
    return colour;
  }

  setRanges() {
    let monthOfYear = new Date().getMonth();
    let firstContractDay = moment();
    let lastContractDay = moment();
    let previousfirstContractDay = moment();
    let previouslastContractDay = moment();
    if (monthOfYear < 4) {
      firstContractDay.set({ year: moment().year() - 1, month: 3, date: 1 }); // moment months start counting at 0!
      lastContractDay.set({ year: moment().year(), month: 2, date: 31 });
      previousfirstContractDay.set({ year: moment().year() - 2, month: 3, date: 1 });
      previouslastContractDay.set({ year: moment().year() - 1, month: 2, date: 31 });
    } else {
      firstContractDay.set({ year: moment().year(), month: 3, date: 1 });
      lastContractDay.set({ year: moment().year() + 1, month: 2, date: 31 });
      previousfirstContractDay.set({ year: moment().year() - 1, month: 3, date: 1 });
      previouslastContractDay.set({ year: moment().year(), month: 2, date: 31 });
    }

    this.ranges = {
      'Last 14 Days': [moment().subtract(14, 'days'), moment()],
      'Last 30 Days': [moment().subtract(30, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'This Contract Year': [firstContractDay, lastContractDay],
      'Last Contract Year': [previousfirstContractDay, previouslastContractDay]
    };
  }

  getAverageForColumn(column: string): string {
    const showSubcontractorAverage = this.SubcontractorAvg && this.dataType === 2 ? true : false;
    if (this.dataSource) {
      let data: [];
      if (showSubcontractorAverage) {
        data = this.dataSource.filteredData;
      } else {
        data = this.groupedBySubcontractor;
      }

      if (data.length > 0) {
        if (column !== 'subcontractor' && column !== 'centre') {
          return (data.reduce((a, b) => a + b[column], 0) / data.length).toFixed(2) + '%';
        }
      } else if (data.length === 0) {
        return '';
      }
    }
    if (showSubcontractorAverage) {
      return 'subcontractor Average';
    } else {
      return 'Overall Average';
    }
  }


  hideFooter(): boolean {
    return !this.dataFiltered || this.dataType === 3 || this.showSpinner;
  }
}
