<h1 class="progressTitle title">
  Activities
</h1>

<div class="reportTable">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.sharedFunctions.unCamelCase(column)}} </th>
      <td mat-cell *matCellDef="let element">
        <span [ngClass]="{'status-text': column=='status',
          'outcome-stamp-pass':  column=='status' && element[column]=='Continuing',
          'outcome-stamp-draft': column=='status' && element[column]=='Successful',
          'outcome-stamp-fail': column=='status' && element[column]!='Continuing' && element[column]!='Successful'}"
        >
          {{element[column] === null ? '-'
            : dateCols.indexOf(column) === -1 ? element[column] : element[column] | date: this.dateFormat}}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td *ngIf="this.loadedActivities" class="mat-cell" colspan="7" style="text-align: center;">
        {{
          !this.activities.success
          ? 'There was an issue getting Activities. Please reload or contant support if it contunues.'
          : this.noData
        }}
      </td>
    </tr>

  </table>
  <mat-progress-bar *ngIf="!this.loadedActivities" mode="indeterminate"></mat-progress-bar>
</div>
