import { DndDirective } from './core/directives/dnd.directive';
import { FileListComponent } from './core/file-list/file-list.component';
import { FileUploadComponent } from './core/file-upload/file-upload.component';
import { ProgressComponent } from './core/file-upload/progress/progress.component';

import { NgxFileDropModule } from 'ngx-file-drop';
import { DatabaseModule } from './core/database/database.module';
import { LearnerSearchModule } from './learner-search/learner-search.module';
import { SearchModule } from './searchV2/search.module';
import { LogOutModule } from './log-out/log-out.module';
import { ReportingModule } from './reporting/reporting.module';
import { ReportingModuleV2 } from './reportingV2/reporting.module';

import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

import { NativeDateModule } from '@angular/material/core';
import { MaterialsModule } from './core/materials/materials.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { NgxGraphModule } from '@swimlane/ngx-graph';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LayoutModule } from '@angular/cdk/layout';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from './shared/date-helper';

import { DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from "@angular/material/dialog";

import { TitleCasePipe } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { PublicClientApplication } from "@azure/msal-browser";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { SendMessageFormComponent } from './core/common-forms/send-message-form/send-message-form.component';
import { FrillWidgetComponent } from './core/frill-widget/frill-widget.component';
import { AuthService } from './core/services/auth.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { EmployerDashboardModule } from './employer-dashboard/employer-dashboard.module';
import { HomeModule } from './home/home.module';
import { StaffAdminComponent } from './home/staff-admin/staff-admin.component';
import { FinanceCurrancyInputComponent } from './learner/learner-attendance/learner-attendance-details/finance-currancy-input/finance-currancy-input.component';
import { LearnerAttendanceDetailsComponent } from './learner/learner-attendance/learner-attendance-details/learner-attendance-details.component';
import { DiscussionFormComponent } from './learner/learner-details/jobs-growth-wales-plus/discussion-form/discussion-form.component';
import { RequestRegistrationComponent } from './learner/learner-progress/objectives/request-registration/request-registration.component';
import { LearnerModule } from './learner/learner.module';
import { NotificationsModule } from './notifications/notifications.module';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportListModule } from './report-list/report-list.module';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { ErrorInterceptorService } from './shared/services/error-interceptor.service';
import { PermissionsRequestFormComponent } from './staff/permissions-request-form/permissions-request-form.component';
import { StaffDashboardModule } from './staff/staff-dashboard/staff-dashboard.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { AttendanceModule } from './attendance/attendance.module';
import { NoteDialogComponent } from './shared/components/note-dialog/note-dialog.component';
import { BdHomepageModule } from './bd-homepage/bd-homepage.module';


const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
  exports: [
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    LogOutModule
  ],
  declarations: [
    AppComponent,
    // for file upload ---
    DndDirective,
    ProgressComponent,
    FileUploadComponent,
    FileListComponent,
    // -------------------
    ReportListComponent,
    SendMessageFormComponent,
    PermissionsRequestFormComponent,
    StaffAdminComponent,
    RequestRegistrationComponent,
    FrillWidgetComponent,
    LearnerAttendanceDetailsComponent,
    DiscussionFormComponent,
    FinanceCurrancyInputComponent,
    ConfirmDialogComponent,
    RedirectComponent,
    PageNotFoundComponent,
    NoteDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialsModule,
    BrowserAnimationsModule,
    LayoutModule,
    NativeDateModule,
    NgxGraphModule,
    FlexLayoutModule,
    ReportingModule,
    ReportingModuleV2,
    LearnerSearchModule,
    SearchModule,
    LearnerModule,
    StaffDashboardModule,
    EmployerDashboardModule,
    DatabaseModule,
    NotificationsModule,
    MatDialogModule,
    NgxDaterangepickerMd.forRoot(),
    NgxFileDropModule,
    ReportListModule,
    CKEditorModule,
    NgSelectModule,
    MatAutocompleteModule,
    HomeModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId, // Application (client) ID from the app registration
          authority: `https://login.microsoftonline.com/${environment.tenantId}`,
          redirectUri: environment.redirectUri, // This is your redirect URI
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        }
      }),
      null,
      null
    ),
    AttendanceModule,
    BdHomepageModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: "en-GB" },
    DeviceDetectorService,
    TitleCasePipe,
    GoogleAnalyticsService,
    AuthService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { console.log('linter is whining') },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
