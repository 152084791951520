import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import VersionInfo from 'src/assets/version.json';
import { environment } from '../environments/environment';
import { UserTableService } from './core/database/user-table.service';
import { AuthService } from './core/services/auth.service';
import { UserService } from './core/services/user.service';
import { NotificationsService } from './notifications/notifications.service';
import { SitePermissions } from './shared/enums/permissions';

export const APP_DATE_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    monthYearLabel: { year: 'numeric' }
  }
};

//This is used for google analytics.
declare let gtag: any; // eslint-disable no-alert, no-console

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  activeURL = '/';
  countUnread: any;
  currentPresence: any;
  currentUser: string;
  initials: string;
  isDisplayRightSidenav = false;
  isIframe = false;
  isLoggedIn = false;
  menuIcons: string[] = ['Dashboard', 'Staff', 'Learner', 'EmployerMenu', 'Reports', 'Logo', 'Register'];
  mobileQuery: MediaQueryList;
  pageRefreshButton = false;
  private _mobileQueryListener: () => void;
  profileId: any;
  profileLoaded = false;
  public version: string = VersionInfo.version;
  token: any;
  userGraph: any;
  isImpersonating = false;
  user: any;
  permissions: number[] = [];
  SitePermissions = SitePermissions;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private msalBroadcastService: MsalBroadcastService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private userTableService: UserTableService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 100px)'); //Checks to see if the screen is mobile or not.
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.displayRightSidenavOnlyOnCertainPages(router); //This is used to display the right sidenav only on certain pages.
    this.handleGoogleAnalytics(); //This is used to handle the google analytics when on production.
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.pageRefreshButton = false;

    this.userTableService.get(1).then(user => {
      const token = localStorage.getItem('access_token');
      if (typeof user === 'undefined' && token === null && !(this.router.url.includes("login") || this.router.url.includes("log-out") || this.router.url.includes("redirect") || this.router.url.includes("auth-error"))) {
        this.router.navigate(['login']);
      }
      if (typeof user !== 'undefined' && token !== null && !(this.router.url.includes("login") || this.router.url.includes("log-out") || this.router.url.includes("redirect") || this.router.url.includes("auth-error"))) {
        this.authService.setUiAuthenticated(true);
      }
    });

    this.msalBroadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        if (result.eventType === EventType.LOGIN_SUCCESS) {
          this.authService.postLogin(result.payload);
        }
      });

    this.activatedNavLink();

    this.authService.uiAuthenticated.subscribe(
      (authenticated) => {
        this.isLoggedIn = authenticated;
        this.updateUi();
      }
    );

    this.userService.uiImpersonation.subscribe(
      () => {
        this.updateUi();
      }
    );

    this.userService.apiErrorMessage.subscribe(
      apiError => {
        if (apiError == 1) {
          console.error("There is an error communicating with the API!");
          this.pageRefreshButton = true;
          console.log(this.pageRefreshButton);
        }
        else if (apiError == 0) {
          this.pageRefreshButton = false;
        }
      });

    if (this.route.snapshot) {
      console.log("snap", this.router.url, this.route.snapshot.url[0])
    }

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  updateUi(): void {
    this.registerMenuIcons();
    this.userTableService.get(1).then(user => {
      if (typeof user !== 'undefined') {
        this.initials = user.userName.match(/\b(\w)/g).join('');
        this.isImpersonating = user.isImpersonating;
        this.isImpersonating && this.authService.isUserAuthenticated() ? this.authService.updatePermissions(user.staffId, this.refreshPermissions) : this.authService.updatePermissions(null, this.refreshPermissions);
        this.user = user;
      }
    });
  }
  //#region Navigation bar
  activatedNavLink() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.activeURL = e.url;
      }
    });
  }

  registerMenuIcons() {
    if (this.isLoggedIn) {
      this.menuIcons.forEach(icon => {
        try {
          const url = this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.websiteURL}/assets/menu/${icon}.svg`);
          this.matIconRegistry.addSvgIcon(icon, url);
        }
        catch (error) {
          console.error(`Error grabbing ${icon} icon from ${environment.websiteURL}/assets/menu/${icon}.svg`)
        }
      })
      this.matIconRegistry.addSvgIcon(
        'bd-homepage',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.websiteURL}/assets/menu/briefcase-outline.svg`)
      );
    }
  }

  displayRightSidenavOnlyOnCertainPages(router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url.indexOf('/learner/dashboard/') != -1) {
          this.isDisplayRightSidenav = true
        }
        else {
          this.isDisplayRightSidenav = false
        }
      }
    });
  }
  //#endregion

  //#region Google Analytics
  handleGoogleAnalytics() {
    if (document.location.hostname.search("vision.acttraining.org.uk") !== -1) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-HCY04XXHTG',
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
      )
    }
  }
  //#endregion

  //#region Page Functions
  refreshPage() { //OnClick for the popup when the api is down.
    window.location.reload();
  }

  onActivate(event) { // Used in route....not sure what it does!
    window.scroll(0, 0);
  }

  navigateToStatusPage() {
    window.open(environment.statusPage, "_blank");
  }

  cancelImpersonation() {
    this.userService.cancelImpersonation();
  }

  logout() {
    this.authService.logout();
  }

  refreshPermissions = () => {
    this.permissions = this.authService.permissions;
    this.userTableService.update(1, { permission: this.permissions });
    this.changeDetectorRef.detectChanges();
  }
  //#endregion
}
