
<div class="select-container">
  <ng-select
    [items]="maytasLinkOptions"
    bindLabel="employerName"
    placeholder="Employer"
    [(ngModel)]="selectedEmployer"
    (change) = "selectEmployer()">
  </ng-select>
</div>

<div class="select-container" *ngIf="placementType == 1">
  <ng-select
    [items]="branches"
    bindLabel="placementName"
    placeholder="Branch"
    [(ngModel)]="selectedBranch"
    (change) = "selectBranch()">
  </ng-select>
</div>

<div style="height: 400px;">
  <ul>
    <li *ngFor="let link of selectedLinks">
      {{link.employer.employerName | titlecase}} : {{link.branch.placementName | titlecase}}
    </li>
  </ul>
</div>

<div style="float: left;">
  <button mat-flat-button color="warn" class="button" [mat-dialog-close]="selectedLinks" cdkFocusInitial>Create links</button>
</div>

<div style="float: right;">
  <button mat-flat-button color="primary" class="button" (click)="cancelOperation()">Cancel</button>
</div>
