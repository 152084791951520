import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-unregistered-learners-eight-and-twelve-weeks-report',
  templateUrl: './unregistered-learners-eight-and-twelve-weeks-report.component.html'
})
export class UnregisteredLearnersEightAndTwelveWeeksReportComponentV2 {

  reportVariant = ReportVariant.UnregisteredLearners8And12Weeks;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'scheme', 'sector', 'assessor', 'potStatus', 'startDate', 'week8And12']
    }
  ];
  dateFilters = ['startDate', 'expectedCompletionDate', 'terminationDate', 'week8And12'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];

  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch (column) {
      case 'week8And12': {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (new Date(value) < today) {
          style = { 'background-color': '#ffcccc' };
        }
        break;
      }
    }
    return style;
  }
}
