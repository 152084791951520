import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';

@Component({
  selector: 'app-esq-occupancy-report',
  templateUrl: './esq-occupancy-report.html'
})
export class EsqOccupancyReportComponent {

  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  name = 'ESQ Occupancy Report';
  reportVariant = ReportVariant.EsqOccupancy;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['traineeId', 'pot', 'fullName', 'schemeShort', 'sector', 'assessor', 'startDate', 'expectedCompletionDate', 'potStatus',
        'startContractYear', 'employerName', 'employerPostTown', 'mqStatus', 'mainQualActual', 'commStatus', 'commActual', 'aonStatus',
        'aonActual', 'ictStatus', 'ictActual', 'initialAon', 'initialComm', 'initialIct', 'currentAon', 'currentComm', 'currentIct', 'targetAon',
        'targetComm', 'targetIct']
    }
  ];
  defaultColumnOrder = [
    {
      type: 'default',
      columns: ['traineeId', 'pot', 'fullName', 'schemeShort', 'sector', 'assessor', 'startDate', 'expectedCompletionDate', 'potStatus',
        'startContractYear', 'employerName', 'employerPostTown', 'mqStatus', 'mainQualActual', 'commStatus', 'commActual', 'aonStatus',
        'aonActual', 'ictStatus', 'ictActual', 'initialAon', 'initialComm', 'initialIct', 'currentAon', 'currentComm', 'currentIct', 'targetAon',
        'targetComm', 'targetIct']
    }
  ];
  dateFilters = ['startDate', 'expectedCompletionDate'];
}
