import { Component } from '@angular/core';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { ReportVariant } from 'src/app/shared/enums/report-variant';
import { ISummaryGroupBy } from 'src/app/shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-health-and-safety-employer-report',
  templateUrl: './health-and-safety-employer-report.component.html'
})
export class HealthAndSafetyEmployerReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.HealthAndSafetyEmployer;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['name', 'streetAddress', 'cityOrTown', 'riskRating', 'activeLearners', 'nextEli', 'nextMonitor', 'nextVetting']
    }
  ];
  dateFilters = [];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'eliStatus', title: 'Eli Status', columns: ['eliStatus', 'riskRating', 'count'],
      pieChartDrillDown: 'riskRating', pieChartShareValue: 'count'
    },
    {
      value: 'monitorStatus', title: 'Monitor Status', columns: ['monitorStatus', 'riskRating', 'count'],
      pieChartDrillDown: 'riskRating', pieChartShareValue: 'count'
    },
    {
      value: 'vettingStatus', title: 'Vetting Status', columns: ['vettingStatus', 'riskRating', 'count'],
      pieChartDrillDown: 'riskRating', pieChartShareValue: 'count'
    },
  ];

  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch (column) {
      case 'nextEli':
        if (row['eliStatus'] === 'Outstanding' || row['eliStatus'] === 'Missing') {
          style = { 'background-color': '#ffb9b9' };
        } else if (row['eliStatus'] === 'Due') {
          style = { 'background-color': '#ffd5bc' };
        }
        break;
      case 'nextMonitor':
        if (row['monitorStatus'] === 'Outstanding' || row['monitorStatus'] === 'Missing') {
          style = { 'background-color': '#ffb9b9' };
        } else if (row['monitorStatus'] === 'Due') {
          style = { 'background-color': '#ffd5bc' };
        }
        break;
      case 'nextVetting':
        if (row['vettingStatus'] === 'Outstanding' || row['vettingStatus'] === 'Missing') {
          style = { 'background-color': '#ffb9b9' };
        } else if (row['vettingStatus'] === 'Due') {
          style = { 'background-color': '#ffd5bc' };
        }
        break;
    }
    return style;
  }
}
