import { I, U } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, Inject, OnInit, ɵɵsetComponentScope } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HomeDashboardService } from 'src/app/home/home-dashboards/home-dashboard.service';
import _ from 'underscore';
import { LearningAimUnitsFormComponent } from '../learning-aim-units-form/learning-aim-units-form.component';

export class FormSelectValues {
  awardingBody: string[];
  level: string[];
  type: string[];
  route: string[];
  la47: string[];
  vocational: string[];
  esw: string[];
  lit: string[];
  num: string[];
  ict: string[];
  othKs: string[];
  mq: string[];
  tc: string[];
  oth: string[];
}

export interface IFormData {
  learningAimReference?: string;
  title?: string;
  titleShort?: string;
  awardingBody?: string;
  level?: string;
  type?: string;
  route?: string;
  la47?: string;
  vocational?: string;
  esw?: string;
  lit?: string;
  num?: string;
  ict?: string;
  othKs?: string;
  mq?: string;
  tc?: string;
  oth?: string;
  creditsMin?: number;
  creditsAtLe?: number;
  creditsAMin?: number;
  creditsAMax?: number;
  creditsBMin?: number;
  creditsBMax?: number;
  creditsCMin?: number;
  creditsCMax?: number;
  creditsDMin?: number;
  creditsDMax?: number;
  creditsEMin?: number;
  creditsEMax?: number;
  creditsFMin?: number;
  creditsFMax?: number;
}

@Component({
  selector: 'app-learning-aim-editor-form',
  templateUrl: './learning-aim-editor-form.component.html',
  styleUrls: ['./learning-aim-editor-form.component.scss']
})
export class LearningAimEditorFormComponent implements OnInit {

  isEdit: boolean = false;
  units: any[] = [];
  larForm: UntypedFormGroup;
  selectableValues: FormSelectValues = new FormSelectValues();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<LearningAimEditorFormComponent>,
    private homeDashboardService: HomeDashboardService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.getFormValues();
  }

  buildForm() {
    this.larForm = this.fb.group({
      'learningAimReference': [null, Validators.required],
      'title': [null, Validators.required],
      'titleShort': [null, Validators.required],
      'awardingBody': [null, Validators.required],
      'level': [null, Validators.required],
      'type': [null, Validators.required],
      'route': [null, Validators.required],
      'la47': null,
      'vocational': null,
      'esw': null,
      'lit': null,
      'num': null,
      'ict': null,
      'othKs': null,
      'mq': null,
      'tc': null,
      'oth': null,
      'creditsMin': '0',
      'creditsAtLevel': '0',
      'creditsAMin': '0',
      'creditsAMax': '999',
      'creditsBMin': '0',
      'creditsBMax': '999',
      'creditsCMin': '0',
      'creditsCMax': '999',
      'creditsDMin': '0',
      'creditsDMax': '999',
      'creditsEMin': '0',
      'creditsEMax': '999',
      'creditsFMin': '0',
      'creditsFMax': '999'
    });
  }

  patchData() {
    console.log('form data', this.data);
    this.larForm.patchValue({
      'learningAimReference': this.data.laimRef,
      'title': this.data.title,
      'titleShort': this.data.titleShort,
      'awardingBody': this.data.awardingBody,
      'level': this.data.level,
      'type': this.data.type,
      'route': this.data.route,
      'la47': this.data.la47,
      'vocational': this.data.vocational,
      'esw': this.data.esw,
      'lit': this.data.lit,
      'num': this.data.num,
      'ict': this.data.ict,
      'othKs': this.data.othKs,
      'mq': this.data.mq,
      'tc': this.data.tc,
      'oth': this.data.oth,
      'creditsMin': this.data.creditsMin,
      'creditsAtLevel': this.data.creditsAtLevel,
      'creditsAMin': this.data.creditsAMin,
      'creditsAMax': this.data.creditsAMax,
      'creditsBMin': this.data.creditsBMin,
      'creditsBMax': this.data.creditsBMax,
      'creditsCMin': this.data.creditsCMin,
      'creditsCMax': this.data.creditsCMax,
      'creditsDMin': this.data.creditsDMin,
      'creditsDMax': this.data.creditsDMax,
      'creditsEMin': this.data.creditsEMin,
      'creditsEMax': this.data.creditsEMax,
      'creditsFMin': this.data.creditsFMin,
      'creditsFMax': this.data.creditsFMax,
    });
  }

  getUnitsAndOpenUnitsForm() {
    console.log('get units for laimRef', this.data.laimRef);
    this.homeDashboardService.getLearningAimUnits(this.data.laimRef)
      .subscribe(response => {
        console.log('units', response);
        this.units = response;
        this.openUnitsForm();
      });
  }

  openUnitsForm() {

    this.units.forEach(
      u => u.unit = u.unit == null ? '?' : u.unit
    )

    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '40%';
    dialogConfig.height = '90%';
    dialogConfig.data = {
      laimRef: this.data.laimRef,
      title: this.data.title,
      units: this.units
    };
    const dialogRef = this.dialog.open(LearningAimUnitsFormComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      if (formData) { }
    });
  }

  getFormValues() {
    this.homeDashboardService.getLearningAimFormValues()
      .subscribe(response => {
        this.selectableValues.awardingBody = this.convertToKeyValue(response.awardingBody);
        this.selectableValues.level = this.convertToKeyValue(response.level);
        this.selectableValues.type = this.convertToKeyValue(response.type);
        this.selectableValues.route = this.convertToKeyValue(response.route);
        this.selectableValues.la47 = this.convertToKeyValue(response.la47);
        this.selectableValues.vocational = this.convertToKeyValue(response.vocational);
        this.selectableValues.esw = this.convertToKeyValue(response.esw);
        this.selectableValues.lit = this.convertToKeyValue(response.lit);
        this.selectableValues.num = this.convertToKeyValue(response.num);
        this.selectableValues.ict = this.convertToKeyValue(response.ict);
        this.selectableValues.othKs = this.convertToKeyValue(response.othKs);
        this.selectableValues.mq = this.convertToKeyValue(response.mq);
        this.selectableValues.tc = this.convertToKeyValue(response.tc);
        this.selectableValues.oth = this.convertToKeyValue(response.oth);

        //console.log('form select values', this.selectableValues);

        if (this.data) {
          this.patchData();
          this.isEdit = true;
        }

        this.cdr.detectChanges();
      })
  }

  convertToKeyValue(data: []): any[] {
    let arr = [];
    _.each(data, element => {
      arr.push({ label: element, value: element })
    });
    return arr;
  }

  saveChanges() {
    if (this.larForm.pristine || this.larForm.invalid) { return false; }

    let returnData: IFormData = {};
    for (const [key, value] of Object.entries(this.larForm.controls)) {
      if (value.value?.value != undefined) {
        returnData[key] = value.value?.value;
      } else {
        returnData[key] = value.value;
      }
    }

    if (this.isEdit) {
      this.saveUpdates(returnData);
    } else {
      this.createNew(returnData);
    }

    this.larForm.reset(this.larForm.value);
  }

  saveUpdates(data: IFormData) {
    this.homeDashboardService.updateLearningAimReference(data).subscribe(
      response => {
        if (response) {
          this.snackBar.open("The Learning Aim Reference record has been updated.", "Close", {
            duration: 4000,
          });
        }
      });
  }

  createNew(data: IFormData) {
    this.homeDashboardService.CreateLearningAimReference(data).subscribe(
      response => {
        if (response) {
          this.snackBar.open("A new Learning Aim Reference record has been created.", "Close", {
            duration: 4000
          });
          this.data = data;
          this.isEdit = true;
        }
      })
  }

  closeForm() {
    this.dialogRef.close();
  }
}
