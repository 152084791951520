import { ChangeDetectorRef, Component, EventEmitter, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { AttendanceApproval, AttendanceAuthorisedService } from "../attendance-authorised absence/attendance-authorised.service";
import { MatTableDataSource } from "@angular/material/table";
import { UserTableService } from "src/app/core/database/user-table.service";
import { IUserInfo } from "src/app/shared/interfaces/user-info";
import { Router } from "@angular/router";
import { catchError, map, merge, of, startWith, switchMap } from "rxjs";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { SharedFunctionsService } from "src/app/core/services/shared-functions.service";
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from "@angular/material/snack-bar";
import { StringHelper } from "src/app/shared/string-helper";
import { AttendanceApprovalPayload, AttendanceApprovalService } from "./attendance-approval.service";
import { DATE_FORMAT } from 'src/app/app.constants';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { InfoDialogComponent } from "src/app/shared/components/info-dialog/info-dialog.component";
import { InfoDialog } from "src/app/shared/interfaces/info-dialog";
import { InfoDialogHelper } from "src/app/shared/components/info-dialog/info-dialog.helper";
import { TimeEditDialogComponent } from "./time-edit-dialog/time-edit-dialog.component";
import moment from 'moment';
import { AttendanceTableService } from "src/app/core/database/attendance-table.service";

@Component({
  selector: 'app-attendance-approval',
  templateUrl: './attendance-approval.component.html',
  styleUrls: ['./attendance-approval.component.scss']
})
export class AttendanceApprovalComponent {

  @ViewChild('tablePaginator', { static: true }) tablePaginator: MatPaginator;
  @ViewChild('tableSort', { static: true }) tableSort: MatSort;

  refreshTable: EventEmitter<void> = new EventEmitter<void>()

  savingDialogConfig: MatDialogConfig<InfoDialog>;
  tableDataSource: MatTableDataSource<any>; //TODO: Define type
  mainTableData: any[] = []; //TODO: Define type
  user: IUserInfo;
  filterString = '';
  onlyMyLearnerStatus = true;
  errorText = '';
  showSpinner = true;
  totalData: number;
  displayedColumns: string[] = ['select', 'name', 'assessor', 'centre', 'type', 'date', 'timeIn', 'timeOut', 'approvedHours', 'edit'];
  masterCheckbox = false;
  selection = new SelectionModel<any>(true, []);
  fullSelection = [];
  pageOptions: number[] = [this.sharedFunctions.calcOptimumRows(), 25, 50, 100];
  dateFormat = DATE_FORMAT;
  moment = moment; //doing this for the html

  constructor(
    private _snackBar: MatSnackBar,
    private attendanceApprovalService: AttendanceApprovalService,
    private cdr: ChangeDetectorRef,
    private userTableService: UserTableService,
    private router: Router,
    public sharedFunctions: SharedFunctionsService,
    public stringHelper: StringHelper,
    private dialog: MatDialog,
    private infoDialogHelper: InfoDialogHelper,
    private attendanceTableService: AttendanceTableService
  ) {
    this.tableDataSource = new MatTableDataSource<any>(this.mainTableData); //TODO: Define type
  }

  ngOnInit(): void {
    this.tableDataSource.paginator = this.tablePaginator;
    this.tableSort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
    this.tableDataSource.sort = this.tableSort;
    this.attendanceTableService.getApproval().then((data) => {
      if (data !== undefined && data !== null) {
        this.filterString = data.filterString;
        this.onlyMyLearnerStatus = data.onlyMyLearners;
      }
      this.userTableService.get(1).then((user) => {
        if (!user || user == undefined || user == null) {
          this.router.navigate(['home']);
        }
        else {
          this.user = user;
          this.setupTablePagination();
        }
      });
    });
  }

  setupTablePagination() {
    merge(this.tableSort.sortChange, this.tablePaginator.page, this.refreshTable).pipe(
      startWith({}),
      switchMap(() => {
        this.showSpinner = true;
        this.errorText = '';
        this.cdr.detectChanges();
        return this.attendanceApprovalService.getDetails(
          this.user.staffId,
          this.tablePaginator.pageIndex + 1,
          this.tablePaginator.pageSize,
          this.filterString,
          this.onlyMyLearnerStatus,
          this.tableSort.active,
          this.tableSort.direction)
          .pipe(catchError((val) => {
            this.showSpinner = false;
            this.errorText = val;
            this.cdr.detectChanges();
            return of(null);
          }))
      }),
      map((data: PaginationResults<any> /*TODO ADD TYPE*/) => {
        if (data == null) return [];
        this.totalData = data.totalRecords;
        return data.data;
      })
    ).subscribe((data) => {
      this.mainTableData = data;
      this.tableDataSource = new MatTableDataSource(this.mainTableData);
      this.showSpinner = false;
      this.selection.clear();
      this.checkBoxVisualControl(data);
      this.cdr.detectChanges();
    });
  }

  checkBoxVisualControl(data: AttendanceApproval[]) {
    this.fullSelection.forEach(item => {
      const found = data.find(x =>
        x.traineeId === item.traineeId &&
        x.pot === item.pot &&
        x.date === item.date &&
        x.type === item.type
      );
      if (found) {
        item.timeIn = found.timeIn;
        item.timeOut = found.timeOut;
        this.selection.select(found);
      }
    });
    this.masterCheckbox = this.isAllSelected();
  }

  applyFilter(value: string) {
    this.filterString = value.toLowerCase();
    this.tablePaginator.pageIndex = 0;
    this.attendanceTableService.addApproval(this.filterString, this.onlyMyLearnerStatus);
    this.refreshTable.emit();
  }

  toggleOnlyMyLearnerStatus(value: MatSlideToggleChange) {
    //this.fullSelection = [];
    this.tablePaginator.pageIndex = 0;
    this.onlyMyLearnerStatus = value.checked;
    this.attendanceTableService.addApproval(this.filterString, this.onlyMyLearnerStatus);
    this.refreshTable.emit();
  }

  loadLearner(traineeId, pot) {
    this._snackBar.open("Loading learner details", "Close", {
      duration: 4000,
    });
    const link = `/learner/dashboard/${traineeId}/${pot}`;
    window.open(link, '_blank').focus();
  }

  masterToggle($event) {
    this.masterCheckbox = $event.checked;
    if (this.isAllSelected()) {
      this.tableDataSource.filteredData.forEach(row => {
        this.selectedItem({ checked: false }, row);
      });
    } else {
      this.tableDataSource.filteredData.forEach(row => {
        const value = this.fullSelection.filter(item => (item.traineeId === row.traineeId) && (item.pot === row.pot) && (row.date === item.date) && (row.type === item.type));
        if (value.length === 0) {
          this.selectedItem({ checked: true }, row);
        }
      });
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.filteredData.length;
    return numSelected === numRows;
  }

  selectedItem($event, cell) {
    const traineeId = cell.traineeId;
    const pot = cell.pot;
    const type = cell.type;
    const date = cell.date;
    if ($event.checked) {
      this.selection.select(cell);
      this.fullSelection.push(cell);
    }
    else {
      const valueToRemove = this.fullSelection.filter(item => (item.traineeId === traineeId) && (item.pot === pot) && (date === item.date) && (type === item.type));
      const index = this.fullSelection.indexOf(valueToRemove[0]);
      if (index !== -1) {
        this.fullSelection.splice(index, 1);
      }
      this.selection.deselect(cell);
    }
    this.masterCheckbox = this.isAllSelected();
    this.cdr.detectChanges();
  }

  asTime(value) {
    if (value == null) return '';
    return value.substring(0, 5);
  }

  timeStyle(value, centreStart, centreEnd) {
    if (value === null) return '';
    if (value === '17:00:00') return 'orange-text';
    if (value < centreStart || value > centreEnd) {
      return 'red-text';
    }
    if (value === centreStart || value === centreEnd) {
      return 'orange-text';
    }
    return '';
  }

  openAttendance(traineeId, pot, date) {
    this._snackBar.open("Loading learner details", "Close", {
      duration: 4000,
    });
    const link = `/learner/attendance/${traineeId}/${pot}?date=${moment(date).startOf('isoWeek').format('YYYY-MM-DD')}`;
    window.open(link, '_blank').focus();
  }

  buildPayload() {
    const attendanceApproval: AttendanceApprovalPayload[] = [];
    this.fullSelection.forEach((item) => {
      attendanceApproval.push({
        signId: item.signId,
        timeIn: item.timeIn,
        commentsTimeIn: item.commentsTimeIn,
        timeOut: item.timeOut,
        commentsTimeOut: item.commentsTimeOut,
        staffId: this.user.staffId
      });
    });
    return attendanceApproval;
  }

  approve() {
    if (this.fullSelection.length > 1) {
      const confirmDialogConfig: MatDialogConfig<InfoDialog> = this.infoDialogHelper.confirmDialogConfig('Approve Attendance?', `You are about to approve multiple rows, are you sure you want to continue?`);
      const confirmDialog = this.dialog.open(InfoDialogComponent, confirmDialogConfig);
      confirmDialog.afterClosed().subscribe((data) => {
        if (data === 'accept') {
          this.savingDialogConfig = this.infoDialogHelper.savingDialogConfig('Approving Attendance', 'There are multiple approvals so it could take some time!');
          const savingDialog = this.dialog.open(InfoDialogComponent, this.savingDialogConfig);
          this.sendApprovalRequest(savingDialog);
        }
      });
    }
    else {
      this.savingDialogConfig = this.infoDialogHelper.savingDialogConfig('Approving Attendance', '');
      const savingDialog = this.dialog.open(InfoDialogComponent, this.savingDialogConfig);
      this.sendApprovalRequest(savingDialog);
    }
  }



  reject() {
    if (this.fullSelection.length > 1) {
      const confirmDialogConfig: MatDialogConfig<InfoDialog> = this.infoDialogHelper.confirmDialogConfig('Reject Attendance?', `You are about to reject multiple rows, are you sure you want to continue?`);
      const confirmDialog = this.dialog.open(InfoDialogComponent, confirmDialogConfig);
      confirmDialog.afterClosed().subscribe((data) => {
        if (data === 'accept') {
          this.savingDialogConfig = this.infoDialogHelper.savingDialogConfig('Rejecting Attendance', 'There are multiple rejections so it could take some time!');
          const savingDialog = this.dialog.open(InfoDialogComponent, this.savingDialogConfig);
          this.sendRejectionRequest(savingDialog);
        }
      });
    }
    else {
      this.savingDialogConfig = this.infoDialogHelper.savingDialogConfig('Approving Attendance', '');
      const savingDialog = this.dialog.open(InfoDialogComponent, this.savingDialogConfig);
      this.sendRejectionRequest(savingDialog);
    }
  }

  sendApprovalRequest(savingDialogRef: MatDialogRef<InfoDialogComponent, any>) {
    this.attendanceApprovalService.approveDetails(this.buildPayload()).subscribe({
      next: (data: any) => {
        savingDialogRef.close();
        let infoDialog: MatDialogConfig<InfoDialog> = null;
        if (data.rejected.length > 0 && data.approved.length > 0) {
          infoDialog = this.infoDialogHelper.informationDialogConfig('Approving Attendance', `The following rows were approved:<br/><br/>${data.approved.map(x => `${x}`).join('<br/>')}</br>The following rows had errors:<br/><br/>${data.rejected.map(x => `${x}`).join('<br/>')}<br/>`);
        }
        else if (data.rejected.length == 0 && data.approved.length > 0) {
          infoDialog = this.infoDialogHelper.informationDialogConfig('Approving Attendance', `The following rows were approved:<br/><br/>${data.approved.map(x => `${x}`).join('<br/>')}<br/>`);
        }
        else if (data.rejected.length > 0 && data.approved.length == 0) {
          infoDialog = this.infoDialogHelper.informationDialogConfig('Approving Attendance', `The following rows had errors:<br/><br/>${data.rejected.map(x => `${x}`).join('<br/>')}<br/>`);
        }
        const infoDialogRef = this.dialog.open(InfoDialogComponent, infoDialog);
        this.fullSelection = [];
        this.refreshTable.emit();
      },
      error: (error) => {
        savingDialogRef.close();
        const errorDialogConfig: MatDialogConfig<InfoDialog> = this.infoDialogHelper.errorDialogConfig('Approving Attendance', 'There was an error approving the attendance, please try again later.');
        const errorDialog = this.dialog.open(InfoDialogComponent, errorDialogConfig);
      }
    });
  }

  sendRejectionRequest(savingDialogRef: MatDialogRef<InfoDialogComponent, any>) {
    this.attendanceApprovalService.rejectDetails(this.buildPayload()).subscribe({
      next: (data: any) => {
        savingDialogRef.close();
        let infoDialog: MatDialogConfig<InfoDialog> = null;
        if (data.rejected.length > 0 && data.approved.length > 0) {
          infoDialog = this.infoDialogHelper.informationDialogConfig('Rejecting Attendance', `The following rows were rejected:<br/><br/>${data.approved.map(x => `${x}`).join('<br/>')}</br>The following rows had errors:<br/><br/>${data.rejected.map(x => `${x}`).join('<br/>')}<br/>`);
        }
        else if (data.rejected.length == 0 && data.approved.length > 0) {
          infoDialog = this.infoDialogHelper.informationDialogConfig('Rejecting Attendance', `The following rows were rejected:<br/><br/>${data.approved.map(x => `${x}`).join('<br/>')}</br>`);
        }
        else if (data.rejected.length > 0 && data.approved.length == 0) {
          infoDialog = this.infoDialogHelper.informationDialogConfig('Rejecting Attendance', `The following rows had errors:<br/><br/>${data.rejected.map(x => `${x}`).join('<br/>')}</br>`);
        }
        const infoDialogRef = this.dialog.open(InfoDialogComponent, infoDialog);
        this.fullSelection = [];
        this.refreshTable.emit();
      },
      error: (error) => {
        savingDialogRef.close();
        const errorDialogConfig: MatDialogConfig<InfoDialog> = this.infoDialogHelper.errorDialogConfig('Approving Attendance', 'There was an error approving the attendance, please try again later.');
        const errorDialog = this.dialog.open(InfoDialogComponent, errorDialogConfig);
      }
    });
  }

  editTime(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      timeData: {
        signId: row.signId,
        timeIn: row.timeIn,
        timeOut: row.timeOut,
        commentsTimeIn: row.commentsTimeIn,
        commentsTimeOut: row.commentsTimeOut
      },
      centreStartTime: row.centreStartTime,
      centreEndTime: row.centreEndTime,
      name: row.name,
      date: moment(row.date).format('DD/MM/YYYY')
    }
    const dialogRef = this.dialog.open(TimeEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'saved') {
        this.refreshTable.emit();
      }
    });
  }

}
