<mat-card class="events-card">
  <button mat-flat-button color="primary" class="event-button" style="margin-left: 8px;"
  (click)="createNewLearnerEvent()" [disabled]="!this.haveLearnerData || !this.permissions.canCreate">
    Create Learner Event
    <mat-progress-bar *ngIf="!this.haveLearnerData" mode="indeterminate" class="button-progress-bar"></mat-progress-bar>
  </button>

  <button mat-button mat-flat-button color="primary" class="event-button" (click)="addNewEvent()">
    Create Employer Event
  </button>

  <h2 *ngIf="isShowTodaysEvents || isShowMissedEvents || isShowUpcomingEvents" class="dashboard-titles"
    style="padding-left: 0px; margin-block-start: 0.83em;">Your Events</h2>

  <h2 *ngIf="!isShowTodaysEvents && !isShowMissedEvents && !isShowUpcomingEvents" class="dashboard-titles"
    style="padding-left: 0px; margin-block-start: 0.83em;">You have no events</h2>

  <div style="width:100%; margin-top: 50px; height: 75%;">
    <mat-tab-group style="height: 100%;">

      <mat-tab label="Today" *ngIf="isShowTodaysEvents">
        <mat-spinner *ngIf="eventsToday.length == 0"></mat-spinner>
        <mat-divider></mat-divider>

        <div *ngFor="let event of eventsToday" fxLayout="row"
          [ngClass]="event.isTodayAndInPast ? 'list-item past' : 'list-item'" class="list-item">
          <div fxFlex="20" class="date-display">

            <span *ngIf="event.startTime">
              {{event.eventDate | date: this.dateFormat}} {{event.startTime}} - {{event.endTime}}
            </span>
            <span *ngIf="!event.startTime">
              {{event.eventDate | date: this.dateFormat}}
            </span>

          </div>
          <div fxFlex="10"></div>
          <div fxFlex="50" *ngIf="event.isLearnerEvent">
            {{event.eventType}}: {{event.traineeName}}
          </div>
          <div fxFlex="50" *ngIf="event.isEmployerEvent && !event.hasExtraDetail">
            {{event.eventType}}: <a
              routerLink="/employer-old/dashboard/{{event.vemployerid}}">{{event.employerName}}</a>
            <span *ngIf="event.location">
              ({{event.location}})
            </span>
          </div>
          <div fxFlex="50" *ngIf="event.isEmployerEvent && event.hasExtraDetail">
            <button mat-button color="primary" (click)="openDialogForEventInfo(event)">
              {{event.eventType}}: <a
                routerLink="/employer-old/dashboard/{{event.vemployerid}}">{{event.employerName}}</a>
              <span *ngIf="event.location">
                ({{event.location}})
              </span>
            </button>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Missed" *ngIf="isShowMissedEvents">
        <mat-divider></mat-divider>

        <div *ngFor="let event of eventsMissed.slice(0,10)" fxLayout="row" class="list-item">
          <div fxFlex="20" fxFlex.sm="40" fxFlex.xs="40" class="date-display">

            <span *ngIf="event.startTime">
              {{event.eventDate | date: this.dateFormat}} {{event.startTime}} - {{event.endTime}}
            </span>
            <span *ngIf="!event.startTime">
              {{event.eventDate | date: this.dateFormat}}
            </span>
          </div>
          <div fxFlex="10"></div>
          <div fxFlex="50" *ngIf="event.isLearnerEvent">
            {{event.eventType}}: {{event.traineeName}}
          </div>
          <div fxFlex="50" *ngIf="event.isEmployerEvent">
            {{event.eventType}}: <a
              routerLink="/employer-old/dashboard/{{event.vemployerid}}">{{event.employerName}}</a>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Upcoming" *ngIf="isShowUpcomingEvents">
        <mat-divider></mat-divider>

        <div *ngFor="let event of eventsUpcoming" fxLayout="row" class="list-item">
          <div fxFlex="20" class="date-display">
            <span *ngIf="event.startTime">
              {{event.eventDate | date: this.dateFormat}} {{event.startTime}} - {{event.endTime}}
            </span>
            <span *ngIf="!event.startTime">
              {{event.eventDate | date: this.dateFormat}}
            </span>
          </div>
          <div fxFlex="10"></div>
          <div fxFlex="50" *ngIf="event.isLearnerEvent">
            {{event.eventType}}: {{event.traineeName}}
          </div>
          <div fxFlex="50" *ngIf="event.isEmployerEvent">
            {{event.eventType}}: <a
              routerLink="/employer-old/dashboard/{{event.vemployerid}}">{{event.employerName}}</a>
          </div>
        </div>


      </mat-tab>

    </mat-tab-group>
    <mat-divider></mat-divider>
  </div>
</mat-card>
