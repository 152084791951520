
import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { UserService } from 'src/app/core/services/user.service';
import { IFullUserInfo } from 'src/app/shared/interfaces/full-user-info';
import { PhoneBookService } from 'src/app/staff/phone-book/phone-book.service';
import { StaffSearchService } from 'src/app/staff/staff-search/staff-search.service';
import * as _ from 'underscore';
import { StaffAdminService } from './staff-admin.service';

@Component({
  selector: 'app-staff-admin',
  templateUrl: './staff-admin.component.html',
  styleUrls: ['./staff-admin.component.scss']
})

export class StaffAdminComponent implements OnInit {


  staffSelectForm: UntypedFormGroup;
  staffInfoForm: UntypedFormGroup;
  tableForm: UntypedFormGroup;
  dataSource: MatTableDataSource<any>;

  data: object[] = [{}];
  @ViewChild(MatTable) table: MatTable<any>;

  staffSelected: boolean = false;
  selectedStaff: IFullUserInfo;
  staffList: any;
  traineePermissions: any;
  uniqueStaff: any;
  uniqueStaffSimple: any;
  permissionsEdit: boolean = false;
  displayedColumns: string[] = ['columnDelete', 'iD', 'assessorid', 'centreid', 'contractid', 'programmetypeid', 'sectorid', 'subcontractorid', 'schemeid',];
  sectorsList: object[] = [];
  contractsList: object[] = [];
  centresList: object[] = [];
  subcontractorsList: object[] = [];
  programmeTypesList: object[] = [];
  assessorList: object[] = [];
  schemesList: object[] = [];

  selectedSectors: any[] = [];
  selectedAssessors: any[] = [];
  selectedCentres: any[] = [];
  selectedContracts: any[] = [];
  selectedProgrammes: any[] = [];
  selectedSubcontractors: any[] = [];
  selectedSchemes: any[] = [];
  staffId: number;
  controlsList: any;
  formDirty: boolean = false;
  generatedPass: string ='';

  constructor(private fb: UntypedFormBuilder,
    private pbs: PhoneBookService,
    private staffSearchService: StaffSearchService,
    private staffAdminService: StaffAdminService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private sharedFunctions: SharedFunctionsService,
    private clipboard: Clipboard
    ) { this.buildForm(), this.dataSource = new MatTableDataSource<any>() }

  ngOnInit(): void {
    var staffIdsForEachDept = [881, 751]
    var departments = [];
    this.pbs.GetPhoneList().subscribe(
      data => {
        this.staffList = data;
        // change this so it gets all names and concatenate with company in brackets
        this.uniqueStaff=[];
        for(var item of this.staffList){
           this.uniqueStaff.push({'name' : item.name + ' (' + item.company + ')', 'staffId' : item.staffId});
        }
        this.changeDetectorRef.detectChanges();


        this.uniqueStaffSimple = this.staffList.filter(({ department }) => {
          if (department) {
            if (departments.indexOf(department) == -1) {
              departments.push(department);
              return true;
            }
          }
        });
        this.uniqueStaffSimple.sort((a, b) => a.department.localeCompare(b.department));
        this.changeDetectorRef.detectChanges();
      });

    this.staffAdminService.GetPermissionsOptions().subscribe(
      data => {
        this.assessorList = data.assessorFilters;
        this.centresList = data.centreFilters;
        this.contractsList = data.contractFilters;
        this.programmeTypesList = data.programmeFilters;
        this.sectorsList = data.sectorFilters;
        this.subcontractorsList = data.subcontractorFilters;
        this.schemesList = data.schemeFilters;
      }
    )
  }

  buildForm() {
    this.staffSelectForm = this.fb.group({
      'staffSelect': '',
      'staffSelectSimple': ''
    });

    this.staffInfoForm = this.fb.group({
    });

    this.tableForm = this.fb.group({
      'sectorSelect': new UntypedFormControl()
    })
  }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }

  loadStaffSimple() {
    let dept = this.staffSelectForm.get('staffSelectSimple').value.department;
    let staff = _.clone(this.staffList.filter(a=> a.department == dept));
    var random_index = Math.round(Math.random() * staff.length);
    this.staffId = staff[random_index]['staffId'];
   this.loadStaff()
  }

  loadStaffByMember(){
    let x = this.staffSelectForm.get('staffSelect').value;
    this.staffId = this.staffList.filter(a => a.staffId == x)[0]['staffId'];
    this.loadStaff()
  }

  loadStaff() {

    // this.refreshPage();



    this.staffAdminService.GetStaffInfo(this.staffId).subscribe((result) => {
      this.selectedStaff = result[0];
      this.populateFields();
      this.staffSelected = true;
      this.changeDetectorRef.detectChanges();
    });

    if (this.data) {
      this.data = [{}];
    }
    this.staffSearchService.getStaffPermissions(this.staffId.toString()).subscribe(response => {
      console.log(response, 'staff info');
      this.traineePermissions = response;
      for (let nextPermission in this.traineePermissions) {
        let obj = {};
        obj['iD'] = this.traineePermissions[nextPermission].iD;
        for (let permissionType in this.traineePermissions[nextPermission]) {
          if ((this.traineePermissions[nextPermission][permissionType] !== null) && typeof (this.traineePermissions[nextPermission][permissionType]) == 'object') {
            for (let permission in this.traineePermissions[nextPermission][permissionType]) {
              for (let key in this.traineePermissions[nextPermission][permissionType][permission]) {
                if (key === Object.keys(this.traineePermissions[nextPermission][permissionType][permission])[0] && ((key.includes("assessorid") || key.includes("contractid") || key.includes("schemeid") || key.includes("subcontractorid") || key.includes("programmetypeid") || key.includes("sectorid") || key.includes("centreid")))) {
                  if (obj[key]) {
                    obj[key].push(this.traineePermissions[nextPermission][permissionType][permission][key])
                  }
                  else {
                    obj[key] = [this.traineePermissions[nextPermission][permissionType][permission][key]]
                  }
                }
              }
            }
          }
        }
        this.data.push(obj);
      }
      this.dataSource.data = this.data.slice(1);

      // Populate selectedContracts
      for (let item in this.dataSource.data) {
        this.selectedContracts.length++;
        let itemArray = [];
        if (this.dataSource.data[item]['contractid']) {
          for (let cid in this.dataSource.data[item]['contractid']) {
            let contract = this.contractsList.filter((obj) => {
              var flag = false;
              Object.values(obj).forEach((val) => {
                if (String(val) == this.dataSource.data[item]['contractid'][cid]) {
                  flag = true;
                  return;
                }
              });
              if (flag) return obj;
            });
            itemArray.push(contract[0]['contract'])
          }
          this.selectedContracts.splice(parseInt(item), 0, itemArray)
        }
      }

      // Populate selectedSectors
      for (let item in this.dataSource.data) {
        this.selectedSectors.length++;
        let itemArray = [];
        if (this.dataSource.data[item]['sectorid']) {
          for (let cid in this.dataSource.data[item]['sectorid']) {
            let sector = this.sectorsList.filter((obj) => {
              var flag = false;
              Object.values(obj).forEach((val) => {
                if (String(val) == this.dataSource.data[item]['sectorid'][cid]) {
                  flag = true;
                  return;
                }
              });
              if (flag) return obj;
            });
            itemArray.push(sector[0]['sector'])
          }
          this.selectedSectors.splice(parseInt(item), 0, itemArray)
        }
      }

      // Populate selectedSchemes
      for (let item in this.dataSource.data) {
        this.selectedSchemes.length++;
        let itemArray = [];
        if (this.dataSource.data[item]['schemeid']) {
          for (let cid in this.dataSource.data[item]['schemeid']) {
            let scheme = this.schemesList.filter((obj) => {
              var flag = false;
              Object.values(obj).forEach((val) => {
                if (String(val) == this.dataSource.data[item]['schemeid'][cid]) {
                  flag = true;
                  return;
                }
              });
              if (flag) return obj;
            });
            itemArray.push(scheme[0]['scheme'])
          }
          this.selectedSchemes.splice(parseInt(item), 0, itemArray)
        }
      }

      // Populate selectedSubcontractors
      for (let item in this.dataSource.data) {
        this.selectedSubcontractors.length++;
        let itemArray = [];
        if (this.dataSource.data[item]['subcontractorid']) {
          for (let cid in this.dataSource.data[item]['subcontractorid']) {
            let subcontractor = this.subcontractorsList.filter((obj) => {
              var flag = false;
              Object.values(obj).forEach((val) => {
                if (String(val) == this.dataSource.data[item]['subcontractorid'][cid]) {
                  flag = true;
                  return;
                }
              });
              if (flag) return obj;
            });
            itemArray.push(subcontractor[0]['subcontractor'])
          }
          this.selectedSubcontractors.splice(parseInt(item), 0, itemArray);
          console.log(typeof (this.selectedSubcontractors));
        }
      }

      // Populate selectedCentres
      for (let item in this.dataSource.data) {
        this.selectedCentres.length++;
        let itemArray = [];
        if (this.dataSource.data[item]['centreid']) {
          for (let cid in this.dataSource.data[item]['centreid']) {
            let centre = this.centresList.filter((obj) => {
              var flag = false;
              Object.values(obj).forEach((val) => {
                if (String(val) == this.dataSource.data[item]['centreid'][cid]) {
                  flag = true;
                  return;
                }
              });
              if (flag) return obj;
            });
            itemArray.push(centre[0]['centre'])
          }
          this.selectedCentres.splice(parseInt(item), 0, itemArray)
        }
      }

      // Populate selectedprogrammetypes
      for (let item in this.dataSource.data) {
        this.selectedProgrammes.length++;
        let itemArray = [];
        if (this.dataSource.data[item]['programmetypeid']) {
          for (let cid in this.dataSource.data[item]['programmetypeid']) {
            let programme = this.programmeTypesList.filter((obj) => {
              var flag = false;
              Object.values(obj).forEach((val) => {
                if (String(val) == this.dataSource.data[item]['programmetypeid'][cid]) {
                  flag = true;
                  return;
                }
              });
              if (flag) return obj;
            });
            itemArray.push(programme[0]['programmeType'])
          }
          this.selectedProgrammes.splice(parseInt(item), 0, itemArray)
        }
      }

      // Populate selectedAssessors
      for (let item in this.dataSource.data) {
        console.log(this.dataSource.data);
        this.selectedAssessors.length++;
        let itemArray = [];
        if (this.dataSource.data[item]['assessorid']) {
          for (let cid in this.dataSource.data[item]['assessorid']) {
            let assessor = this.assessorList.filter((obj) => {
              var flag = false;
              Object.values(obj).forEach((val) => {
                if (String(val) == this.dataSource.data[item]['assessorid'][cid]) {
                  flag = true;
                  return;
                }
              });
              if (flag) return obj;
            });
            itemArray.push(assessor[0]['assessor'])
          }
          this.selectedAssessors.splice(parseInt(item), 0, itemArray)
        }
      }

    });
  }

  populateFields() {
    for (let x in this.selectedStaff) {
      if (!this.staffSelected) {
        this.staffInfoForm.addControl(x.toString().split(' ').join(''), new UntypedFormControl(this.selectedStaff[x]))
      }
      else {
        this.staffInfoForm.get(x.toString()).patchValue(this.selectedStaff[x])
      }
    }
    const filteredControls = Object.keys(this.staffInfoForm.controls).filter(a => !(a.includes('access') || a.includes('toolbar') || a.includes('filter')));
    this.controlsList = _.pick(this.staffInfoForm.controls, filteredControls);
  }

  togglePermissionsEdit() {
    this.permissionsEdit = !this.permissionsEdit;
    if (!this.permissionsEdit && this.formDirty) {
      console.log('data to send', this.dataSource.data);
      this.staffAdminService.postNewPermissions(this.dataSource.data, this.staffId).subscribe(
        a => {
          console.log(a);
          window.location.reload();
        });
    }
  }

  addRow() {
    this.dataSource.data.push({});
    this.selectedContracts.push({});
    this.selectedProgrammes.push({});
    this.selectedSchemes.push({});
    this.selectedSectors.push({});
    this.selectedCentres.push({});
    this.selectedSubcontractors.push({});
    this.selectedAssessors.push({});
    this.table.renderRows();
  }

  onSelect(event, index, type) {
    console.log('change', event, index);

    this.formDirty = true;

    this.dataSource.data[index][type] = [];

    if (type == 'contractid') {
      for (let x in this.selectedContracts[index]) {
        console.log(x, this.selectedContracts[index])
        this.dataSource.data[index][type].push(this.selectedContracts[index][x]['contractId'])
      };
    }

    if (type == 'subcontractorid') {
      for (let x in this.selectedSubcontractors[index]) {
        console.log(x, this.selectedSubcontractors[index])
        this.dataSource.data[index][type].push(this.selectedSubcontractors[index][x]['subcontractorId'])
      };
    }

    if (type == 'programmetypeid') {
      for (let x in this.selectedProgrammes[index]) {
        console.log(x, this.selectedProgrammes[index])
        this.dataSource.data[index][type].push(this.selectedProgrammes[index][x]['programmeTypeId'])
      };
    }

    if (type == 'schemeid') {
      for (let x in this.selectedSchemes[index]) {
        console.log(x, this.selectedSchemes[index])
        this.dataSource.data[index][type].push(this.selectedSchemes[index][x]['schemeId'])
      };
    }

    if (type == 'centreid') {
      for (let x in this.selectedCentres[index]) {
        console.log(x, this.selectedCentres[index])
        this.dataSource.data[index][type].push(this.selectedCentres[index][x]['centreId'])
      };
    }

    if (type == 'sectorid') {
      for (let x in this.selectedSectors[index]) {
        console.log(x, this.selectedSectors[index])
        this.dataSource.data[index][type].push(this.selectedSectors[index][x]['sectorId'])
      };
    }

    if (type == 'assessorid') {
      for (let x in this.selectedAssessors[index]) {
        console.log(x, this.selectedAssessors[index])
        this.dataSource.data[index][type].push(this.selectedAssessors[index][x]['assessorId'])
      };
    }

    console.log('updated data', this.dataSource.data)
  }

  delete(element) {
    let index = this.dataSource.data.findIndex(i => i['iD'] === element['iD'])
    this.dataSource.data.splice(index, 1);
    this.selectedContracts.splice(index, 1);
    this.selectedProgrammes.splice(index, 1);
    this.selectedSchemes.splice(index, 1);
    this.selectedSectors.splice(index, 1);
    this.selectedSubcontractors.splice(index, 1);
    this.selectedCentres.splice(index, 1);
    this.selectedAssessors.splice(index, 1);
    this.table.renderRows();
  }

  refreshPage() {
    this.contractsList = [];
    this.subcontractorsList = [];
    this.programmeTypesList = [];
    this.schemesList = [];
    this.sectorsList = [];
    this.centresList = [];
    this.assessorList = [];
    this.selectedContracts = [];
    this.selectedSubcontractors = [];
    this.selectedProgrammes = [];
    this.selectedSchemes = [];
    this.selectedSectors = [];
    this.selectedCentres = [];
    this.selectedAssessors = [];
  }

  clearSelection(index, type) {
    delete this.dataSource.data[index][type.toLowerCase()];
  }

  saveForm() {
    if (this.staffInfoForm.dirty) {
      console.log('data to send', this.staffInfoForm.value);
      this.staffAdminService.PutNewDetails(this.staffInfoForm.value, this.staffId).subscribe(a => {
        console.log(a);
      });
    }
  }

  impersonateUser() {
    var user: any = {};
    user.staffId = this.selectedStaff['staffid'];
    user.userName = `${this.selectedStaff['forename']} ${this.selectedStaff['surname']}`;
    user.firstName = this.selectedStaff['forename'];
    user.jobRole = this.selectedStaff['jobrole'];
    user.email = this.selectedStaff['email'];
    user.department = this.selectedStaff['department'];
    user.canImpersonate = true;
    user.impersonatingRole = '';
    user.isImpersonating = false;
    user.visionAdmin = true;
    user.defaultPage = this.selectedStaff['v2homepage'];
    user.company = this.selectedStaff['company'];
    this.userService.saveUserToLocalDbSubscription(user);
    this.snackBar.open(`Impersonating ${user.userName}`,
      'Success', { duration: 1500 });
      this.router.navigate(['']);
  };

  newTraineesToOneFileTest(){
    this.staffAdminService.newTraineesToOneFileTest().subscribe(a=> console.log(a));
  }

  getWordsForPassword() {
    this.staffAdminService.GetRandomWords().subscribe(a=> {
      let words = a;
      words = words.filter(a=> a.length < 9);
      let password = [];

      for (let i = 0; i < 3; i++) {
        let random_index = Math.round(Math.random() * words.length);
        password.push(words[random_index]);
      }
      this.generatedPass = password.join().replace(/,/g, "-");
      this.generatedPass = this.generatedPass[0].toUpperCase() + this.generatedPass.substring(1);
      console.log(this.generatedPass);
      this.changeDetectorRef.detectChanges();
    }
    )
  }

  copy() {
    this.clipboard.copy(this.generatedPass);
    this.snackBar.open("Success","Copied to clipboard",{duration: 1500});
  }

}
