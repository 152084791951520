import { SendMessageService } from '../../core/common-forms/send-message-form/send-message.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UserService } from '../../../app/core/services/user.service';

@Component({
  selector: 'app-permissions-request-form',
  templateUrl: './permissions-request-form.component.html',
  styleUrls: ['./permissions-request-form.component.scss']
})
export class PermissionsRequestFormComponent implements OnInit {
  permissionsForm: UntypedFormGroup;
  public Editor = ClassicEditor;
  undo = false;
  userName: string;
  userEmail: string;

  constructor(@Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
    private sms: SendMessageService, private sb: MatSnackBar,
    private dialogRef: MatDialogRef<PermissionsRequestFormComponent>,
    private userService: UserService
    ) { this.buildForm(); }

  editorConfig = {
    toolbar: ['heading', '|', 'bold', 'italic']
  }

  ngOnInit(): void {
    this.getMyInfo();
  }

  buildForm() {
    this.permissionsForm = this.fb.group({
      requestBody: ''
    });
  }

  getMyInfo() {
    this.userService.getMyInfo()
      .subscribe(response => {
        console.log('we have a response 1')
        this.userName = `${response['forename']} ${response['surname']}`;
        this.userEmail = response['email'].toLowerCase();
      });
  }

  onCancel(): void {
    if (this.permissionsForm.dirty) {
      let msg = "Closing this form will lose your unsaved message!";
      this.undo = false;
      let ref = this.sb.open(msg, 'Return to message', {
        duration: 2500
      });
      setTimeout(() => {
        if (!this.undo) {
          this.dialogRef.close();
        }
      }, 2500);

      ref.onAction().subscribe(() => {
        console.log('undo');
        this.undo = true;
      });
    }
    else {
      this.dialogRef.close();
    }
  }

  sendRequest() {
    let sendData =
    {
      'recipientEmail': 'systemsupport@acttraining.org.uk',
      'senderEmail': `${this.userEmail}`,
      'subject': `New Permission Request for ${this.data.staffName} by ${this.userName}`,
      'body': this.permissionsForm.get('requestBody').value
    };
    if (this.userEmail && this.data.staffName && this.userName) {
    this.sms.sendEmail(sendData).subscribe(a => {
      this.dialogRef.close();
    })
    }
  }

}
