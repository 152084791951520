import { Component } from '@angular/core';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-iqa-profiles-report',
  templateUrl: './iqa-profiles-report.component.html'
})
export class IqaProfilesReportComponentV2 {
  reportVariant = ReportVariant.IqaProfiles;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'scheme', 'sector', 'assessor', 'potStatus', 'title', 'iqaType', 'datePlanned', 'percentComplete', 'reasonsForReferral']
    }
  ];
  dateFilters = ['startDate', 'expectedCompletionDate', 'terminationDate', 'dateActual', 'createdDate', 'datePlanned', 'dateSignedOff', 'dateSubmitted', 'planWeekStartDate', 'regDate', 'certAppliedFor', 'certReceived'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];
}
