import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeRangeHelperService {

  constructor() { }

  paddy(num) {
    var pad_char = '0';
    var pad = new Array(3).join(pad_char);
    return (pad + num).slice(-pad.length);
  }


  getDeaultStartTime(): string {
    var d = new Date();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var minuteString = '';
    var hourString = '';



    if(minute > 30){
      console.log('hours', hour)
      hour = hour + 1;
      minuteString = '00'
    }
    else {
      minuteString = '30'
    }
    console.log('hours', hour)
    if(hour < 10){
      hourString = this.paddy(hour);
    }
    else {
      hourString = hour + ''
    }

    var timeString = hourString + ':' + minuteString
    return timeString;

  }

  

timeselection =
[
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30'
];

durations = [
  '30 minutes',
  '1 hour',
  '1.5 hours',
  '2 hours',
  '2.5 hours',
  '3 hours',
  '3.5 hours',
  '4 hours',
  '4.5 hours',
  '5 hours',
  '5.5 hours',
  '6 hours',
  '6.5 hours',
  '7 hours',
  '7.5 hours',
  '8 hours',
  '8.5 hours',
  '9 hours',
  '9.5 hours',
  '10 hours',
  '10.5 hours',
  '11 hours',
  '11.5 hours',
  '12 hours',
  '12.5 hours',
  '13 hours',
  '13.5 hours',
  '14 hours',
  '14.5 hours',
  '15 hours',
  '15.5 hours',
  '16 hours',
  '16.5 hours',
  '17 hours',
  '17.5 hours',
  '18 hours',
  '18.5 hours',
  '19 hours',
  '19.5 hours',
  '20 hours',
  '20.5 hours',
  '21 hours',
  '21.5 hours',
  '22 hours',
  '22.5 hours',
  '23 hours',
  '23.5 hours'
]

}
