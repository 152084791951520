<form [formGroup]="requestRegistrationForm" novalidate (ngSubmit)="close()">

    <mat-dialog-content class="reg-request-form" style="display: flex; flex-direction: column;">
        <h2 mat-dialog-title class="IQAtitle" style="margin-bottom: 2px;">Request Registration</h2>

        <div style="display: flex; flex-direction: column; flex: 1">
          <ng-select appearance="outline" class="dropdown-select" style="width: 500px" #select placeholder="Select Qualification"
              formControlName="objective">
              <ng-option *ngFor="let obj of this.objectives" [value]="obj.trModuleId">
                  {{obj.title}}
              </ng-option>
          </ng-select>
          <div style="flex: 1;">&nbsp;</div>
          <div style="margin-top: 10px; margin-bottom: 10px; display: flex; gap: 15px;">
              <div style="flex: 1;">&nbsp;</div>
              <button mat-raised-button class="{{ this.requestRegistrationForm.value.objective === null ? '' : 'mat-primary'}}" [mat-dialog-close]="requestRegistrationForm" [disabled]="this.requestRegistrationForm.value.objective === null">Submit</button>
              <button mat-raised-button (click)="close()">Close</button >
          </div>
        </div>

    </mat-dialog-content>
</form>
