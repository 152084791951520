import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { SearchVirtualScrollComponent } from '../shared/search-virtual-scroll/search-virtual-scroll.component';
import { SearchType } from '../search.service';

@Component({
  selector: 'app-employer-search',
  templateUrl: './employer-search.component.html',
})
export class EmployerSearchComponent {

  @ViewChild('virtualScroll', { static: false }) SearchVirtualScrollComponent: SearchVirtualScrollComponent;

  constructor(
    private fb: FormBuilder,
    private cdf: ChangeDetectorRef,
  ) {

  }

  public categories = [
    { label: 'Branch', value: 'branch' },
    { label: 'Employer', value: 'employer' },
    { label: 'Address', value: 'address' },
  ]

  public filterOptions: {
    accountManagers: { name: string, id: number }[],
    accountTypes: [name: string, id: number][],

  } = {
      accountManagers: [],
      accountTypes: [],
    };

  searchForm = this.fb.group({
    term: [''],
    category: ['branch'],
  });

  filterForm = this.fb.group({
    accountManagers: [[] as { name: string, id: number }[]],
    accountTypes: [[] as { name: string, id: number }[]],
    activeOnly: [false],
  });

  public type = SearchType.employer;

  public isLoadingSearch = false;
  public applyFiltersButton = false;
  public totalCount: number = null;
  public gettingMoreResults = false;
  public sideNavOpen = true;

  public loadingSearchChange(value: boolean) {
    this.isLoadingSearch = value;
    this.cdf.detectChanges();
  }

  public applyFiltersButtonChange(value: boolean) {
    this.applyFiltersButton = value;
    this.cdf.detectChanges();
  }

  public totalCountChange(value: number) {
    this.totalCount = value;
    this.cdf.detectChanges();
  }

  public gettingMoreResultsChange(value: boolean) {
    this.gettingMoreResults = value;
    this.cdf.detectChanges();
  }

  public onSubmit(data) {
    this.SearchVirtualScrollComponent.onSubmit(data);
  }

  public onSidenavOpenedChange(value: boolean) {
    this.sideNavOpen = value;
    this.SearchVirtualScrollComponent.onSidenavOpenedChange(value);
    this.cdf.detectChanges();
  }

}
