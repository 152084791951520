import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';
import { PermissionService } from '../database/permission.service';
import { UserTableService } from '../database/user-table.service';
import { AuthErrors } from 'src/app/shared/enums/auth-errors';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private permissionService: PermissionService,
    private userTableService: UserTableService,
    private location: Location,
    private router: Router) {
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      return await this.permissionService.get(1).then((permission): any => {
        if (!(typeof permission === 'undefined' || permission === null)) {
          this.userService.resetInternetConnectionError();
          if (next.data === undefined || Object.keys(next.data).length === 0) {
            //Needs to be authorised but no restrictions on permissions.
            return true;
          }
          else {
            if (permission.includes(next.data.permission)) {
              //Needs to be authorised and has permissions.
              return true;
            }
            else {
              //Needs to be authorised but doesn't have permissions.
              this.location.back();
              return false;
            }
          }
        }
        else {
          //this.authService.logout();
          //debugger;
          if (localStorage.getItem('access_token') != null) {
            this.router.navigate([`auth-error`], { queryParams: { error: AuthErrors.PermissionsMissing } });
          }
          return false;
        }
      });
    }
    catch (error) {
      //this.authService.logout();
      //debugger;
      if (localStorage.getItem('access_token') != null) {
        this.router.navigate([`auth-error`], { queryParams: { error: AuthErrors.PermissionsMissing } });
      }
      return false;
    }
  }
}
