import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmployerLearnerFilter } from '../shared/interfaces/employerLearnerFilter';
import { EmployerContact } from '../shared/interfaces/employer-contacts';
import { Sort } from '@angular/material/sort';
import { EmployerEventFilter } from '../shared/interfaces/employer-event-filter';
import { EmployerEvent } from '../shared/interfaces/employer-event';
import { ApiHelper } from '../shared/api-helper';
import { EventEmitter } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class EmployerService extends ApiHelper {

  private refreshAttachmentsSource = new BehaviorSubject<boolean>(false);

  fetchRefreshAttachments() { return this.refreshAttachmentsSource.asObservable() }
  setRefreshAttachments() { this.refreshAttachmentsSource.next(true) }

  getTemplateInfo(employerId: number, staffId: number): Observable<any> {
    return this.getWithAccessToken(`${environment.API_URL}v2/Employer/TemplateInfo/${employerId}/${staffId}`);
  }

  getOverviewData(employerId: number, staffId: number): Observable<any> {
    return this.getWithAccessToken(`${environment.API_URL}v2/Employer/Overview/${employerId}/${staffId}`);
  }

  getHealthAndSafetyData(employerId: number, staffId: number): Observable<any> {
    return this.getWithAccessToken(`${environment.API_URL}v2/Employer/HealthAndSafety/${employerId}/${staffId}`);
  }

  getHealthAndSafetyAttachments(employerId: number, staffId: number): Observable<any> {
    return this.getWithAccessToken(`${environment.API_URL}v2/Employer/HealthAndSafety/Attachments/${employerId}/${staffId}`);
  }

  getHealthAndSafetyAttachment(employerId: number, staffId: number, fileName: string): Observable<any> {
    return this.getFileWithAccessToken(`${environment.API_URL}v2/Employer/HealthAndSafety/Attachment/${employerId}/${staffId}/${fileName}`);
  }

  postHealthAndSafetyAttachment(employerId: number, staffId: number, files: FileList): Observable<any> {
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    formData.append('employerId', employerId.toString());

    const url = `${environment.API_URL}v2/Employer/HealthAndSafety/Attachment/${employerId}/${staffId}`;
    return this.postFileWithAccessToken(url, formData);
  }


  getLearners(employerId: number, staffId: number, filters: EmployerLearnerFilter, pageNumber: number, pageSize: number, sort: Sort): Observable<any> {

    const filterParam = {
      nameFilter: filters.nameFilter,
      sectorIds: filters.sectorIds.map(x => x.id),
      activeOnly: filters.activeOnly,
    };

    const pagination = {
      skip: pageNumber * pageSize,
      take: pageSize,
      sortActive: sort.active,
      sortDirection: sort.direction,
    };

    const url = `${environment.API_URL}v2/Employer/Learners/${employerId}/${staffId}`;
    return this.getWithAccessToken(url,
      new HttpParams()
        .set("filters", JSON.stringify(filterParam))
        .set("pagination", JSON.stringify(pagination))
    );
  }

  updateContact(contact: EmployerContact, staffId: number) {
    return this.putWithAccessToken(`${environment.API_URL}v2/Employer/Contact/${contact.id}`, { contact, staffId });
  }

  updateHealthAndSafetyRiskRating(employerId: number, riskRatingId: number, staffId: number) {
    return this.postWithAccessToken(`${environment.API_URL}v2/Employer/HealthAndSafety/RiskRating/${employerId}/${staffId}`, riskRatingId);
  }

  createContact(employerId: number, contact: EmployerContact, staffId: number) {
    return this.postWithAccessToken(`${environment.API_URL}v2/Employer/Contact/`, { contact, staffId, employerId });
  }

  deleteContact(contactId: number, staffId: number) {
    const params = new HttpParams().set('staffId', staffId.toString());
    return this.deleteWithAccessToken(`${environment.API_URL}v2/Employer/Contact/${contactId}`, params);
  }

  getEvent(eventId: number, staffId: number) {
    const params = new HttpParams()
    .set("staffId", JSON.stringify(staffId));

    return this.getWithAccessToken(`${environment.API_URL}v2/Employer/Event/${eventId}`, params);
  }

  getEvents(employerId: number, staffId: number, filters: EmployerEventFilter, pageNumber: number, pageSize: number): Observable<any> {

    const pagination = {
      skip: pageNumber * pageSize,
      take: pageSize
    }

    const params = new HttpParams()
      .set("filters", JSON.stringify(filters))
      .set("pagination", JSON.stringify(pagination));

    const url = `${environment.API_URL}v2/Employer/Events/${employerId}/${staffId}`;
    return this.getWithAccessToken(url, params);
  }

  getEventFilterOptions(employerId: number) {
    return this.getWithAccessToken(`${environment.API_URL}v2/Employer/Events/FilterOptions/${employerId}`);
  }

  deleteEvent(eventId: number, staffId: number) {
    return this.deleteWithAccessToken(`${environment.API_URL}v2/Employer/Event/${eventId}`,
      new HttpParams()
        .set('staffId', staffId.toString())
    );
  }

  updateEvent(event: EmployerEvent, staffId: number, employerId: number) {
    return this.putWithAccessToken(`${environment.API_URL}v2/Employer/Event/${event.id}`, { event, staffId, employerId });
  }

  createEvent(employerId: number, event: EmployerEvent, staffId: number) {
    return this.postWithAccessToken(`${environment.API_URL}v2/Employer/Event/`, { event, staffId, employerId });
  }
}
