import { PlacementTypeVariant } from './../shared/enums/placement-type-variant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployerSearchService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public searchEmployer(searchText: string, searchType: string, staffId: number): Observable<any> {
    if(!searchText || !searchType || !staffId) return null;
    const url = `${environment.API_URL}searchEmployer?searchString=${searchText}&searchType=${searchType}&staffId=${staffId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public getEmployerSearchList(): Observable<any> {
    const url = `${environment.API_URL}GetEmployerSearchList`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);

  }

  public addEmployer(data: any): Observable<any> {
    const url = `${environment.API_URL}AddEmployer`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, data, options);
  }
}
