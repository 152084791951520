import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth-guard.service';
import { GCHDiscrepancyReportComponent } from '../reporting/gch-discrepancies-report/gch-discrepancies-report.component';
import { LorActivityReportComponent } from '../reportingV2/lor-activity-success-report/lor-activity-success-report.component';
import { LorFrameworkReportComponent } from '../reportingV2/lor-framework-success-report/lor-framework-success-report.component';
import { LorPredictedFrameworkReportComponent } from '../reportingV2/lor-predicted-framework-success-report/lor-predicted-framework-success-report.component';
import { LorProgressionReportComponent } from '../reportingV2/lor-progression-success-report/lor-progression-success-report.component';
import { ReportListComponent } from './report-list.component';
import { ReportsResolversService } from './reports-resolvers.service';
import { ReviewAlnReportComponent } from '../reportingV2/review-aln-report/review-aln-report.component';
import { GuidedContactHoursReportComponentV2 } from '../reportingV2/guided-contact-hours-report/guided-contact-hours-report.component';
import { DigitalApplicationsReportComponentV2 } from '../reportingV2/digital-applications-report/digital-applications-report.component';
import { IqaProfilesReportComponentV2 } from '../reportingV2/iqa-profiles-report/iqa-profiles-report.component';
import { JGWLearnerAwaitingValidationComponentV2 } from '../reportingV2/jgw-learner-awaiting-validation-report/jgw-learner-awaiting-validation-report.component';
import { TraineeVisionEventsReportComponentV2 } from '../reportingV2/trainee-vision-events-report/trainee-vision-events-report.component';
import { ReviewAccuracyReportComponentV2 } from '../reportingV2/review-accuracy-report/review-accuracy-report.component';
import { OccupancyReportComponentV2 } from '../reportingV2/occupancy-report/occupancy-report.component';
import { RegistrationSummaryReportComponentV2 } from '../reportingV2/registration-summary-report/registration-summary-report.component';
import { ReviewsRequiredReportComponentV2 } from '../reportingV2/reviews-required-report/reviews-required-report.component';
import { JGWLearnerAgreedStartComponentV2 } from '../reportingV2/jgw-learner-agreed-start-report/jgw-learner-agreed-start-report.component';
import { JGWLearnerNotificationsComponentV2 } from '../reportingV2/jgw-learner-notifications-report/jgw-learner-notifications-report.component';
import { JGWLearnerReferralsComponentV2 } from '../reportingV2/jgw-learner-referrals-report/jgw-learner-referrals-report.component';
import { TermsReportComponentV2 } from '../reportingV2/terms-report/terms-report.component';
import { WellbeingReportComponentV2 } from '../reportingV2/wellbeing-report/wellbeing-report.component';
import { TraineeStatusChangeReportComponentV2 } from '../reportingV2/trainee-status-change-report/trainee-status-change-report.component';
import { ReviewAnalysisReportComponentV2 } from '../reportingV2/review-analysis-report/review-analysis-report.component';
import { ActivitiesNoAwardsReportComponentV2 } from '../reportingV2/activities-no-awards-report/activities-no-awards-report.component';
import { AttendanceSummaryReportComponentV2 } from '../reportingV2/attendance-summary-report/attendance-summary-report.component';
import { SignInHistoryComponentV2 } from '../reportingV2/sign-in-history/sign-in-history.component';
import { ActivitiesReportComponentV2 } from '../reportingV2/activities-report/activities-report.component';
import { ApprenticeshipProvisionalAchieversReportComponentV2 } from '../reportingV2/apprenticeship-provisional-achievers-report/apprenticeship-provisional-achievers-report.component';
import { AttendanceSignedInReportComponentV2 } from '../reportingV2/attendance-signed-in-report/attendance-signed-in-report.component';
import { AttendanceSignedOutReportComponentV2 } from '../reportingV2/attendance-signed-out-report/attendance-signed-out-report.component';
import { AwaitingIqaReportComponentV2 } from '../reportingV2/awaiting-iqa-report/awaiting-iqa-report.component';
import { AwardsNoActivitiesReportComponentV2 } from '../reportingV2/awards-no-activities-report/awards-no-activities-report.component';
import { CertificatesClaimedAndReceivedReportComponentV2 } from '../reportingV2/certificates-claimed-and-received-report/certificates-claimed-and-received-report.component';
import { CertificatesToBeClaimedReportComponentV2 } from '../reportingV2/certificates-to-be-claimed-report/certificates-to-be-claimed-report.component';
import { HealthAndSafetyEmployerReportComponentV2 } from '../reportingV2/health-and-safety-employer-report/health-and-safety-employer-report.component';
import { MostRecentSignInReportComponentV2 } from '../reportingV2/most-recent-sign-in-report/most-recent-sign-in-report.component';
import { OnefileIalpsReportComponentV2 } from '../reportingV2/onefile-ialps-report/onefile-ialps-report.component';
import { OnefileReviewsReportComponentV2 } from '../reportingV2/onefile-reviews-report/onefile-reviews-report.component';
import { OutstandingRegistrationRequestsReportComponentV2 } from '../reportingV2/outstanding-registration-requests-report/outstanding-registration-requests-report.component';
import { OutstandingReturnedCertificatesReportComponentV2 } from '../reportingV2/outstanding-returned-certificates-report/outstanding-returned-certificates-report.component';
import { PlannedVisitsReportComponentV2 } from '../reportingV2/planned-visits-report/planned-visits-report.component';
import { ProfiledAchieversReportComponentV2 } from '../reportingV2/profiled-achievers-report/profiled-achievers-report.component';
import { ReviewListReportComponentV2 } from '../reportingV2/review-list-report/review-list-report.component';
import { StartsReportComponentV2 } from '../reportingV2/starts-report/starts-report.component';
import { SuspensionsReportComponentV2 } from '../reportingV2/suspensions-report/suspensions-report.component';
import { TraineeEssentialSkillsReportComponentV2 } from '../reportingV2/trainee-essential-skills-report/trainee-essential-skills-report.component';
import { UnregisteredLearnersEightAndTwelveWeeksReportComponentV2 } from '../reportingV2/unregistered-learners-eight-and-twelve-weeks-report/unregistered-learners-eight-and-twelve-weeks-report.component';
import { LearnerProgressReportComponent } from '../reportingV2/learner-progress-report/learner-progress-report.component';
import { EsqOccupancyReportComponent } from '../reportingV2/esq-occupancy-report/esq-occupancy-report.component';
import { EarlyLeaversReportComponent } from '../reportingV2/early-leavers-report/early-leavers-report.component';
import { EightWeekTermReportComponent } from '../reportingV2/eight-week-term-report/eight-week-term-report.component';
import { OnefileAssessmentReportComponent } from '../reportingV2/onefile-assessment-report/onefile-assessment-report';
import { OnefileAssessmentPlanReportComponent } from '../reportingV2/onefile-assessment-plan-report/onefile-assessment-plan-report';
import { OnefileAssessmentPendingReportComponent } from '../reportingV2/onefile-assessment-pending-report/onefile-assessment-pending-report';
import { EsqMarkingAndIqaReportComponent } from '../reportingV2/esq-marking-and-iqa-report/esq-marking-and-iqa-report.component';
import { WorkshopAvailableReportComponent } from '../reportingV2/workshop-available-report/workshop-availablereport.component';
import { WorkshopBookingReportComponent } from '../reportingV2/workshop-booking-report/workshop-booking-report.component';
import { TraineeObjectiveListReportComponent } from '../reportingV2/trainee-objective-list-report/trainee-objective-list-report.component';
import { UnopenedActivitiesReportComponent } from '../reportingV2/unopened-activities-report/unopened-activities-report.component';
import { RecentVisitsReportComponent } from '../reportingV2/recent-visits-report/recent-visits-report.component';
import { CancelledAppointmentsReportComponent } from '../reportingV2/cancelled-appointments-report/cancelled-appointments-report.component';
import { SuspensionSubmissionReportComponent } from '../reportingV2/suspension-submission-report/suspension-submission-report.component';
import { RetrospectiveOccupancyComponent } from '../reportingV2/retrospective-occupancy/retrospective-occupancy.component';


const routes: Routes = [
  { path: 'activities', component: ActivitiesReportComponentV2, canActivate: [AuthGuard] },
  { path: 'activities/:reportFilterId', component: ActivitiesReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'apprenticeship-provisional-achievers', component: ApprenticeshipProvisionalAchieversReportComponentV2, canActivate: [AuthGuard] },
  { path: 'apprenticeship-provisional-achievers/:reportFilterId', component: ApprenticeshipProvisionalAchieversReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'attendance-signed-in', component: AttendanceSignedInReportComponentV2, canActivate: [AuthGuard] },
  { path: 'attendance-signed-in/:reportFilterId', component: AttendanceSignedInReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'attendance-signed-out', component: AttendanceSignedOutReportComponentV2, canActivate: [AuthGuard] },
  { path: 'attendance-signed-out/:reportFilterId', component: AttendanceSignedOutReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'attendance-summary', component: AttendanceSummaryReportComponentV2, canActivate: [AuthGuard] },
  { path: 'attendance-summary/:reportFilterId', component: AttendanceSummaryReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'awaiting-iqa', component: AwaitingIqaReportComponentV2, canActivate: [AuthGuard] },
  { path: 'awaiting-iqa/:reportFilterId', component: AwaitingIqaReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'awards-missing-completed-activity', component: AwardsNoActivitiesReportComponentV2, canActivate: [AuthGuard] },
  { path: 'awards-missing-completed-activity/:reportFilterId', component: AwardsNoActivitiesReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'certificates-claimed-and-received', component: CertificatesClaimedAndReceivedReportComponentV2, canActivate: [AuthGuard] },
  { path: 'certificates-claimed-and-received/:reportFilterId', component: CertificatesClaimedAndReceivedReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'certificates-to-be-claimed', component: CertificatesToBeClaimedReportComponentV2, canActivate: [AuthGuard] },
  { path: 'certificates-to-be-claimed/:reportFilterId', component: CertificatesToBeClaimedReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'completed-activities-missing-awards', component: ActivitiesNoAwardsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'completed-activities-missing-awards/:reportFilterId', component: ActivitiesNoAwardsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'digital-applications', component: DigitalApplicationsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'digital-applications/:reportFilterId', component: DigitalApplicationsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'gch-discrepancies', component: GCHDiscrepancyReportComponent, canActivate: [AuthGuard] },
  { path: 'gch-discrepancies', component: GCHDiscrepancyReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'guided-contact-hours', component: GuidedContactHoursReportComponentV2, canActivate: [AuthGuard] },
  { path: 'guided-contact-hours/:reportFilterId', component: GuidedContactHoursReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'health-and-safety-employer', component: HealthAndSafetyEmployerReportComponentV2, canActivate: [AuthGuard] },
  { path: 'health-and-safety-employer/:reportFilterId', component: HealthAndSafetyEmployerReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'index', component: ReportListComponent, canActivate: [AuthGuard] },
  { path: 'iqa-profiles', component: IqaProfilesReportComponentV2, canActivate: [AuthGuard] },
  { path: 'iqa-profiles/:reportFilterId', component: IqaProfilesReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'jgw-learner-agreed-starts', component: JGWLearnerAgreedStartComponentV2, canActivate: [AuthGuard] },
  { path: 'jgw-learner-agreed-starts/:reportFilterId', component: JGWLearnerAgreedStartComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'jgw-learner-awaiting-validations', component: JGWLearnerAwaitingValidationComponentV2, canActivate: [AuthGuard] },
  { path: 'jgw-learner-awaiting-validations/:reportFilterId', component: JGWLearnerAwaitingValidationComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'jgw-learner-notifications-sent', component: JGWLearnerNotificationsComponentV2, canActivate: [AuthGuard] },
  { path: 'jgw-learner-notifications-sent/:reportFilterId', component: JGWLearnerNotificationsComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'jgw-learner-referrals', component: JGWLearnerReferralsComponentV2, canActivate: [AuthGuard] },
  { path: 'jgw-learner-referrals/:reportFilterId', component: JGWLearnerReferralsComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-activity-success', component: LorActivityReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-activity-success/:reportFilterId', component: LorActivityReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-framework-success', component: LorFrameworkReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-framework-success/:reportFilterId', component: LorFrameworkReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-predicted-framework-success', component: LorPredictedFrameworkReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-predicted-framework-success/:reportFilterId', component: LorPredictedFrameworkReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-progression-success', component: LorProgressionReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'lor-progression-success/:reportFilterId', component: LorProgressionReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'most-recent-sign-in', component: MostRecentSignInReportComponentV2, canActivate: [AuthGuard] },
  { path: 'most-recent-sign-in/:reportFilterId', component: MostRecentSignInReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'occupancy', component: OccupancyReportComponentV2, canActivate: [AuthGuard] },
  { path: 'occupancy/:reportFilterId', component: OccupancyReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-ialps', component: OnefileIalpsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'onefile-ialps/:reportFilterId', component: OnefileIalpsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-reviews', component: OnefileReviewsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'onefile-reviews/:reportFilterId', component: OnefileReviewsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'outstanding-registration-requests', component: OutstandingRegistrationRequestsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'outstanding-registration-requests/:reportFilterId', component: OutstandingRegistrationRequestsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'outstanding-returned-certificates', component: OutstandingReturnedCertificatesReportComponentV2, canActivate: [AuthGuard] },
  { path: 'outstanding-returned-certificates/:reportFilterId', component: OutstandingReturnedCertificatesReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'planned-visits', component: PlannedVisitsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'planned-visits/:reportFilterId', component: PlannedVisitsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'profiled-achievers', component: ProfiledAchieversReportComponentV2, canActivate: [AuthGuard] },
  { path: 'profiled-achievers/:reportFilterId', component: ProfiledAchieversReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'registration-summary', component: RegistrationSummaryReportComponentV2, canActivate: [AuthGuard] },
  { path: 'registration-summary/:reportFilterId', component: RegistrationSummaryReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'review-accuracy', component: ReviewAccuracyReportComponentV2, canActivate: [AuthGuard] },
  { path: 'review-accuracy/:reportFilterId', component: ReviewAccuracyReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'review-analysis', component: ReviewAnalysisReportComponentV2, canActivate: [AuthGuard] },
  { path: 'review-analysis/:reportFilterId', component: ReviewAnalysisReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'review-list', component: ReviewListReportComponentV2, canActivate: [AuthGuard] },
  { path: 'review-list/:reportFilterId', component: ReviewListReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'reviews-required', component: ReviewsRequiredReportComponentV2, canActivate: [AuthGuard] },
  { path: 'reviews-required/:reportFilterId', component: ReviewsRequiredReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'sign-in-history', component: SignInHistoryComponentV2, canActivate: [AuthGuard] },
  { path: 'sign-in-history', component: SignInHistoryComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'starts', component: StartsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'starts/:reportFilterId', component: StartsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'suspensions', component: SuspensionsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'suspensions/:reportFilterId', component: SuspensionsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'terms', component: TermsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'terms/:reportFilterId', component: TermsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'trainee-essential-skills', component: TraineeEssentialSkillsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'trainee-essential-skills/:reportFilterId', component: TraineeEssentialSkillsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'trainee-status-change', component: TraineeStatusChangeReportComponentV2, canActivate: [AuthGuard] },
  { path: 'trainee-status-change/:reportFilterId', component: TraineeStatusChangeReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'trainee-vision-events', component: TraineeVisionEventsReportComponentV2, canActivate: [AuthGuard] },
  { path: 'trainee-vision-events/:reportFilterId', component: TraineeVisionEventsReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'unregistered-learners-8-and-12-weeks', component: UnregisteredLearnersEightAndTwelveWeeksReportComponentV2, canActivate: [AuthGuard] },
  { path: 'unregistered-learners-8-and-12-weeks/:reportFilterId', component: UnregisteredLearnersEightAndTwelveWeeksReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'review-aln', component: ReviewAlnReportComponent, canActivate: [AuthGuard] },
  { path: 'review-aln/:reportFilterId', component: ReviewAlnReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'wellbeing', component: WellbeingReportComponentV2, canActivate: [AuthGuard] },
  { path: 'wellbeing/:reportFilterId', component: WellbeingReportComponentV2, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'learner-progress', component: LearnerProgressReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'learner-progress/:reportFilterId', component: LearnerProgressReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'esq-occupancy', component: EsqOccupancyReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'esq-occupancy/:reportFilterId', component: EsqOccupancyReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'early-leavers', component: EarlyLeaversReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'early-leavers/:reportFilterId', component: EarlyLeaversReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'eight-week-term', component: EightWeekTermReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'eight-week-term/:reportFilterId', component: EightWeekTermReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-assessment', component: OnefileAssessmentReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-assessment/:reportFilterId', component: OnefileAssessmentReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-assessment-plan', component: OnefileAssessmentPlanReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-assessment-plan/:reportFilterId', component: OnefileAssessmentPlanReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-assessment-pending', component: OnefileAssessmentPendingReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'onefile-assessment-pending/:reportFilterId', component: OnefileAssessmentPendingReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'esq-marking-and-iqa', component: EsqMarkingAndIqaReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'esq-marking-and-iqa/:reportFilterId', component: EsqMarkingAndIqaReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'available-workshops', component: WorkshopAvailableReportComponent, canActivate: [AuthGuard] },
  { path: 'available-workshops/:reportFilterId', component: WorkshopAvailableReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'workshop-bookings', component: WorkshopBookingReportComponent, canActivate: [AuthGuard] },
  { path: 'workshop-bookings/:reportFilterId', component: WorkshopBookingReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'trainee-objective-list', component: TraineeObjectiveListReportComponent, canActivate: [AuthGuard] },
  { path: 'trainee-objective-list/:reportFilterId', component: TraineeObjectiveListReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'unopened-activities', component: UnopenedActivitiesReportComponent, canActivate: [AuthGuard] },
  { path: 'unopened-activities/:reportFilterId', component: UnopenedActivitiesReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'recent-visits', component: RecentVisitsReportComponent, canActivate: [AuthGuard] },
  { path: 'recent-visits/:reportFilterId', component: RecentVisitsReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'cancelled-appointments', component: CancelledAppointmentsReportComponent, canActivate: [AuthGuard] },
  { path: 'cancelled-appointments/:reportFilterId', component: CancelledAppointmentsReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'suspension-submissions', component: SuspensionSubmissionReportComponent, canActivate: [AuthGuard] },
  { path: 'suspension-submissions/:reportFilterId', component: SuspensionSubmissionReportComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
  { path: 'retrospective-occupancy', component: RetrospectiveOccupancyComponent, canActivate: [AuthGuard] },
  { path: 'retrospective-occupancy/:reportFilterId', component: RetrospectiveOccupancyComponent, resolve: { reportFilter: ReportsResolversService }, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class ReportListRoutingModule { }
