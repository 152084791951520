import { Component, Input, OnInit } from '@angular/core';
import { UserTableService } from '../database/user-table.service';
import { UserService } from '../services/user.service';
import { FrillServiceService } from './frill-service.service';

@Component({
  selector: 'frill-widget',
  templateUrl: './frill-widget.component.html',
  styleUrls: ['./frill-widget.component.scss']
})
export class FrillWidgetComponent implements OnInit {

  widget?: FrillWidget;
  config?: FrillConfig;
  currentUser: any; // import("e:/Smart Owl/src/app/core/database/user-table.service").UserInfoWithId;

  constructor(private frillService: FrillServiceService, private userTableService: UserTableService) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.userTableService.get(1)
      .then(result => {
          this.currentUser = result;
          console.log('currentUser', this.currentUser);
          this.frillService.getToken(result.staffId, result.email, result.userName).subscribe(token=> {
            this.config = {
              key: 'a498f6f4-c4a7-4700-bdc6-8ebd0525ba33',
              callbacks: {
                onReady: (frillWidget) => {
                  this.widget = frillWidget;
                },
              },
              ssoToken: token
            };
            window.Frill_Config = window.Frill_Config || [];
            window.Frill_Config.push(this.config);

            if ('Frill' in window) {
              this.widget = window.Frill.widget(this.config);
            }
          })
        })
  }

  ngOnDestroy() {
    this.widget?.destroy();
    window.Frill_Config = window.Frill_Config.filter((c) => c !== this.config);
  }
}
