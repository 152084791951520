import { Component, ChangeDetectorRef } from '@angular/core';
import { LearnerService } from '../learnerService.service';
import { MatTableDataSource } from '@angular/material/table';
import { LearnerPageComponent } from '../learner-page.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort, SortDirection } from '@angular/material/sort';
import { DATE_TIME_FORMAT, NO_DATA } from 'src/app/app.constants';


@Component({
  selector: 'learner-attachments',
  templateUrl: './learner-attachments.component.html',
  styleUrls: ['./learner-attachments.component.scss']
})
export class LearnerAttachmentsComponent implements LearnerPageComponent {

  traineeId: string;
  staffId: number;
  pot: number;

  loadingFile: string = null;

  learner: any;

  fileList: any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ['name', 'uploadBy', 'date'];
  filesToHighlight: string[] = [];
  loadingText: string = 'Fetching attachments...';
  loadingAttachmentsError: boolean = false;

  loadedAttachments: boolean = false;
  defaultActiveColumn: string = 'name';
  defaultSortDirection: SortDirection = "asc";

  dateTimeFormat = DATE_TIME_FORMAT;
  noData = NO_DATA;

  constructor(
    private learnerService: LearnerService,
    private cdr : ChangeDetectorRef,
    private snackBar: MatSnackBar,
    ) { }

  getData() {
    this.getAttachmentList();
  }

  getAttachmentList(highlightAddedFiles = null){
    this.loadedAttachments = false;
    this.loadingAttachmentsError = false;
    this.learnerService.GetLearnerAttachmentsList(this.traineeId).subscribe({
      next: (response) => {
        this.fileList = response.data;
        //console.log('fileList', this.fileList);
        //this.fileList.sort((a, b) => { return Number(new Date(b.modifiedASW)) - Number(new Date(a.modifiedASW)) });
        this.filesToHighlight = highlightAddedFiles === null ? [] : highlightAddedFiles;
        this.dataSource = new MatTableDataSource(this.fileList);
        this.fileListSortChange({active: this.defaultActiveColumn, direction: this.defaultSortDirection});

      },
      error: (err) => {
        console.log('Error loading file list: ', err.error);
        this.loadingAttachmentsError = true;
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadingText = 'Refreshing attachments list...';
        this.loadedAttachments = true;
        this.cdr.detectChanges();
      }
    });
  }

  getFileForLearner(fileName: string) {
    if(this.loadingFile !== null) {
      return;
    }

    this.loadingFile = fileName;

    this.learnerService.GetLearnerAttachment(this.traineeId, fileName)
      .subscribe({
        next: (response) => {
          const type = this.getFileType(fileName);
          if(type != '') {
            var file = new Blob([response], { type: type });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          } else {
            var file = new Blob([response], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(file);
            link.download = fileName.replace("~~","+");
            link.click();
          }
        },
        error: (err) => {
          this.loadingFile = null;
          this.snackBar.open(
            'Error: File could not be downloaded, please try again. If this problem persists please contact systems support.', 'Close',
          {});
          console.log('error', err.error);
          this.cdr.detectChanges();
        },
        complete: () => {
          this.loadingFile = null;
          this.cdr.detectChanges();
        }
      });
  }

  getFileType(fileName: string) : string {
    const splitFile: string[] = fileName.split('.');
    const fileType: string = splitFile[splitFile.length - 1];
    let blobType: string = '';
    switch (fileType) {
      case 'pdf':
        blobType = 'application/pdf';
        break;
      case 'jpg':
      case 'jpeg':
      case 'jfif':
      case 'pjpeg':
      case 'pjp':
        blobType = 'image/jpeg';
        break;
      case 'png':
        blobType = 'image/png';
        break;
      case 'svg':
        blobType = 'image/svg+xml';
        break;
      case 'gif':
        blobType = 'image/gif';
        break;
      case 'html':
        blobType = 'text/html';
        break;
    }
    return blobType;
  }

  fileListSortChange(sortState: Sort) {
    const data: any[] = this.dataSource.data;
    data.sort((a : any, b : any) => {
      const isAsc = sortState.direction === 'asc';
      switch(sortState.active) {
        case 'name':
          return compare(a.fileName, b.fileName, isAsc);
        case 'date':
          return compare(a.modifiedASW, b.modifiedASW, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource.data = data;
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  const collator: Intl.Collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
  if(isAsc) {
    return collator.compare(a.toString(), b.toString());
  }
  return collator.compare(b.toString(), a.toString());
}
