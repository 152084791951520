import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';
import { LearningAimEditorFormComponent } from './learning-aim-editor-form/learning-aim-editor-form.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface ILaimRef {
  laimRef: string,
  title: string,
  label: string
}

@Component({
  selector: 'app-learning-aim-editor',
  templateUrl: './learning-aim-editor.component.html',
  styleUrls: ['./learning-aim-editor.component.scss']
})
export class LearningAimEditorComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  selectedLaimRef: string = '';
  laimRefs: ILaimRef[] = [];

  constructor(
    private dialog: MatDialog,
    private homeDashboardService: HomeDashboardService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  selectRef() {
    if (this.selectedLaimRef) {
      this.openEditorForm();
    }
  }

  newRef() {
    this.selectedLaimRef = '';
    this.openEditorForm();
  }

  openEditorForm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '50%';
    dialogConfig.height = '95%';

    if (!this.selectedLaimRef) {
      const dialogRef = this.dialog.open(LearningAimEditorFormComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(formData => {
        if (formData) { console.log('formData', formData); }
        this.getLearningAimReferences();
      });

    } else {

      this.homeDashboardService.getLaimRefDetails(this.selectedLaimRef).subscribe(
        response => {
          dialogConfig.data = response;
          const dialogRef = this.dialog.open(LearningAimEditorFormComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(formData => {
            if (formData) { console.log('formData', formData); }
            this.getLearningAimReferences();
          });
        });
    }
  }

  getLearningAimReferences() {
    this.homeDashboardService.getLearningAimReferenceDictionary().subscribe(data => {
      this.laimRefs = [];
      var keys = Object.keys(data.laimRefDictionary)
      for (var i = 0; i < keys.length; i++) {
        this.laimRefs = [...this.laimRefs, { laimRef: keys[i], title: data.laimRefDictionary[keys[i]], label: `${keys[i]}: ${data.laimRefDictionary[keys[i]]}` }];
      }
      this.cdr.detectChanges();
    });
  }

}
