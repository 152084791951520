
    <div class="row">
        <h2 class="dashboard-titles" style="padding-left:0px;">{{ data.title }} ({{ data.laimRef }})</h2>
    </div>  

    <div *ngIf="data.units.length">
        <div class="row" style="padding-bottom: 0;">
            Units
        </div>
        <hr>
        <div class="row" *ngFor="let unit of data.units">
            <span>{{ unit.unit }} | {{ unit.title }}</span>
        </div>
        <hr>
    </div>


    <div class="row" style="padding-top: 20px;">    
        <div class="column-left">
            <button mat-button (click)="exportUnits(false)" mat-flat-button [ngClass]="{'button-enabled': this.data.units.length > 0, 'button-disabled': this.data.units.length == 0}" >Export units as CSV</button>
            <button mat-button (click)="exportUnits(true)" mat-flat-button color="secondary" class="close-button" style="margin-left: 20px;">Export empty units template</button>
        </div>
    </div>

    <div class="row" style="padding-top: 20px;">    
        <div class="column-right">

            <h2 class="dashboard-titles">Import Units from CSV file</h2>
            
            <div class="file-drop-container" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                <div class="file-drop-information">
                  <img style="float: left;" src="../../../../assets/images/icon-documents.png">
                  <div>
                    <h4>Drag file here to upload</h4>
                    <label class="link" for="fileDropRef">or click to browse your files</label>
                  </div>
                </div>
              </div>
              
        </div>
    </div>

    <div class="footer-row" style="padding-top: 20px;">    
        <div class="column-right">
            <button mat-button (click)="closeForm()" mat-flat-button color="secondary" class="close-button" style="float: right;">Close</button>
        </div>
    </div>
