import { LearnerService } from "../../learnerService.service";
import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core";
import { MatIconRegistry } from '@angular/material/icon';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

export interface uploadResponse {
  files: [{name: string, savedToMaytas: boolean, uploadedToAWS: boolean, errorMessage: string}],
  errorMessage: string,
  loggedToDb: boolean,
  success: boolean
}

@Component({
  selector: "learner-file-upload",
  templateUrl: "./learner-file-upload.component.html",
  styleUrls: ["./learner-file-upload.component.scss"],
})

export class LearnerFileUploadComponent implements OnChanges {

  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  @ViewChild("fileDropRef", { static: false }) fileDrop: ElementRef;

  @Input() traineeId: string;
  @Input() loadedFileList: boolean;
  @Output() filesUploaded: EventEmitter<any[]> = new EventEmitter();

  filesToUpload: FileList;
  failedUploads: string[] = [];
  readyToUpload: boolean = true;

  constructor(
    private learnerService: LearnerService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    this.registerIcon();
  }

  registerIcon() {
    this.matIconRegistry.addSvgIcon(
      'Upload',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.websiteURL}/assets/dnd/Upload.svg`)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.loadedFileList !== undefined) {
      if(changes.loadedFileList.currentValue !== changes.loadedFileList.previousValue) {
        this.readyToUpload = this.loadedFileList;
        if(this.readyToUpload) {
          this.filesToUpload = null;
          this.fileInput.nativeElement.value = "";
          this.fileDrop.nativeElement.value = "";
        }
        this.cdr.detectChanges();
      }
    }
  }


  /**
   * handle file from browsing
   */
  fileBrowseHandler(filesToUpload: FileList) {
      this.prepareFilesList(filesToUpload);
  }

  uploadLearnerFiles(files: FileList) {
    this.filesToUpload = files;
    this.learnerService.PostLearnerAttachments(files, this.traineeId).subscribe({
      next: (response: uploadResponse) => {
        for(let file of this.filesToUpload) {
          const responseFile = response.files.find(x => x.name === file.name);
          if(responseFile !== undefined) {
            if(responseFile.uploadedToAWS) {
              file['uploadDone'] = true;
            } else {
              file['error'] = true;
              this.failedUploads.push(file.name);
            }
          }
        }
        this.cdr.detectChanges();
      },
      error: (error) => {
        for(let file of this.filesToUpload) {
          this.failedUploads.push(file.name);
        }
        this.filesToUpload = null;
        this.fileInput.nativeElement.value = "";
        this.fileDrop.nativeElement.value = "";
        this.readyToUpload = true;
        this.cdr.detectChanges();
        console.log('error', error.Message);
      },
      complete: () => {
        this.uploadComplete();
      }
    });
  }

  async uploadComplete() {
    const filesAdded: string[] = Object.values(this.filesToUpload).filter(x => x['uploadDone']).map(x => x.name);
    this.filesUploaded.emit(filesAdded);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: FileList) {
   // Accept all file types for now
      this.failedUploads = [];
      this.readyToUpload = false;
      this.uploadLearnerFiles(files);
  }

  FileTypeAccepted(fileType: string) : boolean {
    let isValid = false;
    switch (fileType) {
      case "application/pdf":
      case "image/jpeg":
      case "image/png":
      case "image/gif":
      case "image/svg+xml":
      case "text/html":
        isValid = true;
        break;
    }
    return isValid;
  }


  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}
