import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { IPhoneListRecord } from '../../shared/interfaces/phone-list-record';
import { IOfficePhone } from '../../shared/interfaces/office-phone';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PhoneBookService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) { this.token = localStorage.getItem('access_token')}

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public GetPhoneList(): Observable<any> {
    const url = `${environment.API_URL}GetPhoneList`;
    const options = this.getOptionsWithAccessToken();

    console.log('Call GetPhoneList from API');

    return this.http.get(url, options);
  }

  public getOfficePhoneNumbers(): Observable<IOfficePhone> {

    let phoneList: IOfficePhone[] =
    [
      { id: 1, title: 'Head Office', phone: '02920 464272' },
      { id: 2, title: 'iTeach', phone: '02920 474069' },
      { id: 3, title: 'Bridgend', phone: '01656 768888' },
      { id: 4, title: 'Hadfield Road', phone: '02920 729018' },
      { id: 5, title: 'Barry', phone: '01446 735433' },
      { id: 6, title: 'Aberdare', phone: '01685 879024' },
      { id: 7, title: 'Caerphilly Schools', phone: '02920 867853' },
      { id: 8, title: 'Ebbw Vale', phone: '01495 708022' },
      { id: 9, title: 'Portal', phone: '02920 474060' },
      { id: 10, title: 'Caerphilly Skills Centre', phone: '02922 408023' }
    ];

    phoneList.sort((a, b) => a.id - b.id);

    return from(phoneList);
  }
}
