<article style="padding: 3rem; height: calc(100vh - (120px + 6rem)); overflow: auto;">

  <form [formGroup]="learnerSignUpForm" (ngSubmit)="onSubmit()">

    <div style="margin: 0 auto; max-width: 64rem;">

      <div style="background: white; border-radius: 6px;">

        <ng-template #noSignUpData>
          <div *ngIf="!this.loadingData; else loading" style="padding: 4rem; text-align: center;">
            {{ this.noData }}
          </div>
        </ng-template>

        <ng-template #loading>
          <div style="padding: 4rem; text-align: center;">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </ng-template>

        <div *ngIf="!this.loadingData && this.signUpData !== null; else noSignUpData" style="display: flex; flex-direction: column; gap: 2rem; padding: 4rem;">

          <section class="section">
            <h3 class="side-column">BD Details</h3>

            <div class="column">
              <div *ngIf="this.canEdit; else bdDetailsView">
                <div class="row">
                  <ng-select
                  class="dropwdown-select width-sm"
                  appearance="outline"
                  placeholder="BDI"
                  [items]="this.bidOptions"
                  formControlName="Bdi"
                  [clearOnBackspace]="false"
                  [clearable]="false"
                  dropdownPosition="below"
                  [searchable]="true"
                  [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                  >
                  </ng-select>

                  <ng-select
                  class="dropwdown-select width-sm"
                  appearance="outline"
                  placeholder="Bd Source"
                  [items]="this.BdSourceOptions"
                  formControlName="BdSource"
                  [clearOnBackspace]="false"
                  [clearable]="false"
                  dropdownPosition="below"
                  [searchable]="false"
                  [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                  >
                  </ng-select>
                </div>

                <mat-form-field appearance="outline" floatLabel="always" class="width-m">
                  <mat-label>Enrolment Location</mat-label>
                  <input matInput formControlName="EnrolmentLocation">
                </mat-form-field>
              </div>

              <ng-template #bdDetailsView>
                <div class="row">
                  <view-only-field class="width-sm" [label]="'BDI'">{{this.learnerSignUpForm.value.Bdi}}</view-only-field>

                  <view-only-field class="width-sm" [label]="'Bd Source'">{{this.learnerSignUpForm.value.BdSource}}</view-only-field>
                </div>

                <view-only-field class="width-sm" [label]="'Enrolment Location'">{{this.learnerSignUpForm.value.EnrolmentLocation}}</view-only-field>
              </ng-template>
            </div>
          </section>

          <mat-divider class="divider"></mat-divider>

          <!-- General -->
          <section class="section">
            <h3 class="side-column">General</h3>

            <div class="column">
              <div *ngIf="this.canEdit; else generalView">
                <div class="row">
                  <ng-select
                  class="dropwdown-select width-sm"
                  appearance="outline"
                  placeholder="First Language"
                  [items]="this.languageOptions"
                  formControlName="FirstLanguage"
                  [clearOnBackspace]="false"
                  [clearable]="false"
                  dropdownPosition="below"
                  [searchable]="true"
                  [virtualScroll]="true"
                  [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                  [disabled]="!this.canEdit"
                  >
                  </ng-select>

                  <ng-select
                  class="dropwdown-select width-sm"
                  appearance="outline"
                  placeholder="Preferred Learning Language"
                  [items]="this.languageOptions"
                  formControlName="PreferredLearningLanguage"
                  [clearOnBackspace]="false"
                  [clearable]="false"
                  dropdownPosition="below"
                  [searchable]="true"
                  [virtualScroll]="true"
                  [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                  [disabled]="!this.canEdit"
                  >
                  </ng-select>
                </div>

                <div class="row">
                  <mat-form-field appearance="outline" class="date-picker" (click)="agreedStartDate.open()" floatLabel="always">
                    <mat-label>Agreed Start Date</mat-label>
                    <input matInput [matDatepicker]="agreedStartDate" formControlName="AgreedStartDate" readonly [disabled]="!this.canEdit">
                    <mat-datepicker-toggle matSuffix  [for]="agreedStartDate"></mat-datepicker-toggle>
                    <mat-datepicker #agreedStartDate startView="year">
                      <mat-date-range-picker-actions>
                        <button type="button" mat-stroked-button (click)="this.clear('AgreedStartDate'); agreedStartDate.close();">
                          Clear
                        </button>
                        <button type="button" mat-raised-button color="primary" matDateRangePickerApply>
                          Apply
                        </button>
                      </mat-date-range-picker-actions>
                    </mat-datepicker>
                  </mat-form-field>

                  <ng-select
                  class="dropwdown-select width-xs"
                  appearance="outline"
                  placeholder="Level"
                  [items]="this.levelOptions"
                  formControlName="Level"
                  [clearOnBackspace]="false"
                  [clearable]="false"
                  dropdownPosition="below"
                  [searchable]="false"
                  bindValue="value"
                  bindLabel="label"
                  [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                  >
                  </ng-select>
                </div>

                <div class="toggle-container">
                  <mat-slide-toggle formControlName="PreviousActLearner">
                    Previous Act Learner
                  </mat-slide-toggle>
                </div>

                <div class="toggle-container">
                  <mat-slide-toggle formControlName="AnyOtherTraining">
                    Any Other Training
                  </mat-slide-toggle>
                </div>
              </div>

              <ng-template #generalView>
                <div class="row">
                  <view-only-field class="width-sm" [label]="'First Language'">{{this.learnerSignUpForm.value.FirstLanguage}}</view-only-field>

                  <view-only-field class="width-sm" [label]="'Preferred Learning Language'">{{this.learnerSignUpForm.value.PreferredLearningLanguage}}</view-only-field>
                </div>

                <div class="row">
                  <view-only-field class="width-sm" [label]="'Agreed Start Date'">{{this.learnerSignUpForm.value.AgreedStartDate | date: this.dateFormat}}</view-only-field>

                  <view-only-field class="width-xs" [label]="'Level'">{{this.getLevelView()}}</view-only-field>
                </div>

                <view-only-field [label]="'Previous Act Learner'">{{this.learnerSignUpForm.value.PreviousActLearner ? 'Yes' : 'No'}}</view-only-field>

                <view-only-field [label]="'Any Other Training'">{{this.learnerSignUpForm.value.AnyOtherTraining ? 'Yes' : 'No'}}</view-only-field>
              </ng-template>

            </div>
          </section>

          <mat-divider class="divider"></mat-divider>

          <!-- Employer  -->
          <section class="section">
            <div class="side-column">
              <h3>Employment</h3>
              <div class="extra-info">
                {{this.signUpData.employer ?? 'No Employer'}}
              </div>
            </div>
            <div class="column">
              <div *ngIf="this.canEdit; else employerView">
                <mat-form-field appearance="outline" floatLabel="always" class="width-l">
                  <mat-label>Place Of Work</mat-label>
                  <input matInput formControlName="PlaceOfWork">
                </mat-form-field>

                <mat-form-field appearance="outline" floatLabel="always" class="width-l">
                  <mat-label>Job Tile</mat-label>
                  <input matInput formControlName="JobTile">
                </mat-form-field>

                <mat-form-field appearance="outline" floatLabel="always" class="width-m">
                  <mat-label>Manager Name</mat-label>
                  <input matInput formControlName="ManagerName">
                </mat-form-field>

                <div class="row">
                  <mat-form-field appearance="outline" floatLabel="always" class="width-xs">
                    <mat-label>Manager Phone</mat-label>
                    <input matInput formControlName="ManagerPhone">
                  </mat-form-field>

                  <mat-form-field appearance="outline" floatLabel="always" class="width-m">
                    <mat-label>Manager Email</mat-label>
                    <input matInput formControlName="ManagerEmail">
                  </mat-form-field>
                </div>


                <mat-form-field appearance="outline" class="date-picker" (click)="probationCompleteDate.open()" floatLabel="always">
                  <mat-label>Probation Complete Date</mat-label>
                  <input matInput [matDatepicker]="probationCompleteDate" formControlName="ProbationCompleteDate" readonly>
                  <mat-datepicker-toggle matSuffix  [for]="probationCompleteDate"></mat-datepicker-toggle>
                  <mat-datepicker #probationCompleteDate startView="year">
                    <mat-date-range-picker-actions>
                      <button type="button" mat-stroked-button (click)="this.clear('ProbationCompleteDate'); probationCompleteDate.close();">
                        Clear
                      </button>
                      <button type="button" mat-raised-button color="primary" matDateRangePickerApply>
                        Apply
                      </button>
                    </mat-date-range-picker-actions>
                  </mat-datepicker>
                </mat-form-field>

                <div class="toggle-container">
                  <mat-slide-toggle formControlName="LongTermContract">
                    Long Term Contract
                  </mat-slide-toggle>
                </div>

                <div class="toggle-container">
                  <mat-slide-toggle formControlName="ExtendedTimeOff">
                    Extended Time Off Work
                  </mat-slide-toggle>
                </div>

                <div class="toggle-container">
                  <mat-slide-toggle formControlName="WiFiAtWork">
                    WiFi At Work
                  </mat-slide-toggle>
                </div>

                <div class="toggle-container">
                  <mat-slide-toggle formControlName="LearningAgreement">
                    Learning Agreement Or SME
                  </mat-slide-toggle>
                </div>
              </div>

              <ng-template #employerView>
                <view-only-field class="width-l" [label]="'Place Of Work'">{{this.learnerSignUpForm.value.PlaceOfWork}}</view-only-field>

                <view-only-field class="width-l" [label]="'Job Tile'">{{this.learnerSignUpForm.value.JobTile}}</view-only-field>

                <view-only-field class="width-m" [label]="'Manager Name'">{{this.learnerSignUpForm.value.ManagerName}}</view-only-field>

                <div class="row">
                  <view-only-field class="width-xs" [label]="'Manager Phone'">{{this.learnerSignUpForm.value.ManagerPhone}}</view-only-field>

                  <view-only-field class="width-m" [label]="'Manager Email'">{{this.learnerSignUpForm.value.ManagerEmail}}</view-only-field>
                </div>

                <view-only-field [label]="'Probation Complete Date'">{{this.learnerSignUpForm.value.ProbationCompleteDate | date: this.dateFormat}}</view-only-field>

                <view-only-field [label]="'Long Term Contract'">{{this.learnerSignUpForm.value.LongTermContract ? 'Yes' : 'No'}}</view-only-field>

                <view-only-field [label]="'Extended Time Off Work'">{{this.learnerSignUpForm.value.ExtendedTimeOff ? 'Yes' : 'No'}}</view-only-field>

                <view-only-field [label]="'WiFi At Work'">{{this.learnerSignUpForm.value.WiFiAtWork ? 'Yes' : 'No'}}</view-only-field>

                <view-only-field [label]="'Learning Agreement Or SME'">{{this.learnerSignUpForm.value.LearningAgreement ? 'Yes' : 'No'}}</view-only-field>
              </ng-template>

            </div>
          </section>

          <mat-divider class="divider"></mat-divider>

          <!-- Incentives  -->
          <section class="section">
            <div class="side-column">
              <h3>Incentives</h3>
              <div class="extra-info">
                Age: {{this.signUpData.age}}
              </div>
              <div class="extra-info">
                Hours: {{this.signUpData.hoursPw}}
              </div>
            </div>
            <div class="column">
              <div *ngIf="this.canEdit; else incentivesView">
                <div class="toggle-container">
                  <mat-slide-toggle formControlName="DisabledIncentive">
                    Identified as disabled on or before start
                  </mat-slide-toggle>
                </div>

                <ng-select
                class="dropwdown-select width-m"
                appearance="outline"
                placeholder="New Apprentice and Redundancy"
                [items]="this.incentiveOptions"
                formControlName="Incentive"
                [clearOnBackspace]="false"
                [clearable]="false"
                dropdownPosition="below"
                [searchable]="false"
                [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                groupBy="group"
                bindValue="value"
                bindLabel="label"
                >
                </ng-select>
              </div>

              <ng-template #incentivesView>
                <view-only-field [label]="'Disabled Incentive'">{{this.learnerSignUpForm.value.DisabledIncentive ? 'Yes' : 'No'}}</view-only-field>

                <view-only-field class="width-m" [label]="'New Apprentice and Redundancy'">{{this.getIncentiveView()}}</view-only-field>
              </ng-template>

            </div>
          </section>

          <mat-divider class="divider"></mat-divider>

          <!-- West -->
          <section class="section">
            <h3 class="side-column">WEST</h3>
            <div class="column">
              <div *ngIf="this.canEdit; else westView">
                <div class="toggle-container">
                  <mat-slide-toggle formControlName="PreviousWest">
                    Previous West
                  </mat-slide-toggle>
                </div>

                <ng-select
                class="dropwdown-select width-xs"
                appearance="outline"
                placeholder="Wests in Welsh"
                [items]="this.westsInWelshOptions"
                formControlName="WelshWests"
                [clearOnBackspace]="false"
                [clearable]="false"
                [searchable]="false"
                dropdownPosition="below"
                [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                bindValue="value"
                bindLabel="label"
                >
                </ng-select>

                <div class="row">
                  <mat-form-field appearance="outline" floatLabel="always" class="width-sm">
                    <mat-label>Provisional West Username</mat-label>
                    <input matInput formControlName="ProvisionalWestUsername">
                  </mat-form-field>

                  <mat-form-field appearance="outline" floatLabel="always" class="width-sm">
                    <mat-label>Provisional West Password</mat-label>
                    <input matInput formControlName="ProvisionalWestPassword">
                  </mat-form-field>
                </div>
              </div>

              <ng-template #westView>
                <view-only-field [label]="'Previous West'">{{this.learnerSignUpForm.value.PreviousWest ? 'Yes' : 'No'}}</view-only-field>

                <view-only-field class="width-xs" [label]="'Wests in Welsh'">{{this.sharedService.uppercaseFirstLetter(this.learnerSignUpForm.value.WelshWests.toLowerCase())}}</view-only-field>

                <div class="row">
                  <view-only-field class="width-sm" [label]="'Provisional West Username'">{{this.learnerSignUpForm.value.ProvisionalWestUsername}}</view-only-field>

                  <view-only-field class="width-sm" [label]="'Provisional West Password'">{{this.learnerSignUpForm.value.ProvisionalWestPassword}}</view-only-field>
                </div>
              </ng-template>

            </div>
          </section>

          <mat-divider class="divider"></mat-divider>

          <!-- Additional Info -->
          <section class="section">
            <h3 class="side-column">Other</h3>

            <div class="column">
              <div *ngIf="this.canEdit; else additionalInfoView">
                <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
                  <mat-label>Additional Info</mat-label>
                  <textarea matInput formControlName="AdditionalInfo" style="resize: none; min-height: 10rem;"></textarea>
                </mat-form-field>
              </div>

              <ng-template #additionalInfoView>
                <div>
                  <div style="color: rgba(0, 0, 0, 0.8); font-weight: 400; font-size: 13px; margin-top: 2px; margin-bottom: 5px">
                    Additional Info
                  </div>
                  <div style="white-space: break-spaces;">{{this.sharedService.isNullOrEmpty(this.learnerSignUpForm.value.AdditionalInfo) ? '-' : this.learnerSignUpForm.value.AdditionalInfo?.trim()}}</div>
                </div>
              </ng-template>

            </div>

          </section>

          <mat-divider class="divider"></mat-divider>
          <div style="align-self: enf; display: flex; flex-direction: row; gap: 1rem; align-items: self-end;">
            <div *ngIf="this.signUpData.updateTime !== null" class="extra-info">Updated: {{ this.signUpData.updateTime |  date: this.dateTimeFormat}}</div>
            <div style="flex:1"></div>
            <button *ngIf="this.canEdit" type="button" (click)="this.resetForm()" mat-raised-button style="width: 10rem;"[disabled]="!learnerSignUpForm.valid || !learnerSignUpForm.dirty || this.saving">Cancel</button>
            <button *ngIf="this.canEdit" type="submit" mat-raised-button color="primary" style="width: 10rem;" [disabled]="!learnerSignUpForm.valid || !learnerSignUpForm.dirty || this.saving">Save</button>
          </div>

          <mat-progress-bar *ngIf="this.saving" mode="indeterminate" [color]="'primary'" style="margin-top: 1rem;"></mat-progress-bar>

        </div>
      </div>

    </div>

  </form>
</article>
