import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { BdHomepageService } from '../bd-homepage.service';
import { EmployerEvent, EmployerEventOptions } from 'src/app/shared/interfaces/employer-event';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DATE_FORMAT, ERROR_GETTING_DATA } from 'src/app/app.constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmployerEventsFormComponent } from 'src/app/employerV2/employer-events/employer-events-form/employer-events-form.component';
import { EventModalMode } from 'src/app/shared/enums/event-modal-mode';
import { EmployerService } from 'src/app/employerV2/employer.service';

@Component({
  selector: 'bd-events',
  templateUrl: './bd-events.component.html',
  styleUrls: ['./bd-events.component.scss']
})
export class BdEventsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private bdHomepageService: BdHomepageService,
    private cdr: ChangeDetectorRef,
    public sharedService: SharedService,
    private dialog: MatDialog,
    private employerService: EmployerService
  ) { }

  staffId: number;
  permissions: { canEditEvent: boolean, canDeleteEvent: boolean } =
    {
      canEditEvent: false,
      canDeleteEvent: false,
    };

  loading = false;
  runSetUp = false;
  loadingFilterOptions = true;
  filterError = false;
  eventError = false;

  openEvent = false;

  filterOptions: EmployerEventOptions = {
    staff: [],
    types: [],
  }
  eventsDataSource = new MatTableDataSource<any>();

  filterStaff = [];
  filterType = [];

  tabs = [
    'Today',
    'Recent',
    'Upcoming',
    'Missed'
  ]

  activeTab = 'Today';

  //eventColumns = ['employerName', 'branchName', 'assignee', 'type', 'plannedDate', 'actualDate', 'startTime', 'endTime'];
  eventColumns = {
    'Today': ['employerName', 'assignee', 'type', 'plannedDate'],
    'Recent': ['employerName', 'assignee', 'type', 'plannedDate', 'actualDate'],
    'Upcoming': ['employerName', 'assignee', 'type', 'plannedDate'],
    'Missed': ['employerName', 'assignee', 'type', 'plannedDate']
  };

  dateColumns = ['plannedDate', 'actualDate'];
  dateFormat = DATE_FORMAT;
  dataError = ERROR_GETTING_DATA;

  totalEvents = 0;
  pageSize = 12;
  pageIndex = 0;

  ngOnInit(): void {
    this.getFilterOptions();
  }

  setUp(staffId: number, permissions: { canEditEvent: boolean, canDeleteEvent: boolean }) {
    this.staffId = staffId;
    this.permissions = permissions;

    this.getEvents(false);
    this.runSetUp = true;
  }

  getFilterOptions() {
    this.loadingFilterOptions = true;
    this.filterError = false;

    this.bdHomepageService.getEventFilterOptions().subscribe({
      next: (data: EmployerEventOptions) => {
        this.filterOptions = data;
      },
      error: (error) => {
        console.error(error);
        this.filterError = true;
        this.loadingFilterOptions = false;
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadingFilterOptions = false;
        this.cdr.detectChanges();
      }
    });
  }

  changeTab(tab: string) {
    if(this.loading)
    {
      return;
    }

    this.activeTab = tab;
    this.getEvents();
  }

  handlePageEvent($event: any) {
    this.pageIndex = $event.pageIndex;

    this.getEvents(false);
  }

  filterChange($event: any, modelName: string) {
    switch (modelName) {
      case 'staff':
        this.filterStaff = $event.map(x => x.id);
        break;
      case 'type':
        this.filterType = $event.map(x => x.id);
        break;
    }

    this.getEvents();
  }

  getEvents(resetPage = true) {
    if(this.loading)
    {
      return;
    }

    if(this.staffId === undefined) {
      console.error('Staff ID is required');
      return;
    }

    if (resetPage) {
      this.pageIndex = 0;
      this.paginator.pageIndex = 0;
    }

    this.loading = true;
    this.eventError = false;

    const filters = {
      tab: this.activeTab,
      staff: this.filterStaff,
      type: this.filterType
    }

    this.bdHomepageService.getEvents(this.staffId, filters, this.pageIndex, this.pageSize).subscribe({
      next: (data) => {
        this.eventsDataSource.data = data.list;
        this.totalEvents = data.total;
        console.log(data);
      },
      error: (error) => {
        console.error(error);
        this.eventError = true;
        this.loading = false;
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loading = false;
        this.cdr.detectChanges();
      }
    });
  }

  addTime(element: any) {
    let time = `, ${element.startTime}`;
    if(element.endTime) {
      time += ` - ${element.endTime}`;
    }
    return time;
  }


  openEventForm(eventId: number) {
    if(this.openEvent) {
      return;
    }

    this.openEvent = true;

    const subscription = this.employerService.getEvent(eventId, this.staffId).subscribe({
      next: (data: EmployerEvent) => {
        const event: EmployerEvent = data;
        console.log('Event: ', event);

        const eventDialogConfig = new MatDialogConfig();
        eventDialogConfig.width = '650px';
        eventDialogConfig.autoFocus = false;
        eventDialogConfig.disableClose = true;

        eventDialogConfig.data = {
          mode: EventModalMode.view,
          event: event,
          staffId: this.staffId,
          employerId: event?.branchId,
          eventOptions: this.filterOptions,
          permissions: {
            canEdit: this.permissions.canEditEvent,
            canDelete: this.permissions.canDeleteEvent,
          },
          lockRootEvent: true,
        };

        this.dialog.open(EmployerEventsFormComponent, eventDialogConfig)
          .afterClosed()
          .subscribe(event => {
            if (event?.refresh === true) {
              this.getEvents();
            }
            this.openEvent = false;
          });
      },
      error: (error) => {
        console.error(error);
        subscription.unsubscribe();
        this.openEvent = false;
      },
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

}
