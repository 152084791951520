import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnyAaaaRecord } from 'dns';


@Injectable({
  providedIn: 'root'
})
export class LearnerService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);

    const params = new HttpParams();
    const options = { headers: headers, params: params };
    return options;
  }

  public getLearnerTemplateInfo(traineeId: string, pot: number, staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerTemplateInfo?traineeId=${traineeId}&pot=${pot}&staffId=${staffId}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }
  public GetLearnerPeopleCards(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerPeopleCards?traineeId=${traineeId}&pot=${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  //#region Overview

  public getLearnerDashboard(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerDashboard?traineeId=${traineeId}&pot=${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region Details

  public getLearnerDetails(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerDetails?traineeId=${traineeId}&pot=${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public updateLearnerSignupDetails(values: any): Observable<any> {
    const url = `${environment.API_URL}UpdateSignUpDetails`;
    return this.http.put<any>(url, values, this.getOptionsWithAccessToken());
  }

  public updateWestLogin(values: any): Observable<any> {
    const url = `${environment.API_URL}UpdateWestLogin`;
    return this.http.put<any>(url, values, this.getOptionsWithAccessToken());
  }

  public updateGlanceDetails(values: any): Observable<any> {
    const url = `${environment.API_URL}UpdateGlanceDetails`;
    return this.http.put<any>(url, values, this.getOptionsWithAccessToken());
  }

  public updateLearnerProgrammeSignupDetails(values: any): Observable<any> {
    const url = `${environment.API_URL}UpdateSignUpProgrammeDetails`;
    return this.http.put<any>(url, values, this.getOptionsWithAccessToken());
  }

  public updateLearnerEmployerSignupDetails(values: any): Observable<any> {
    const url = `${environment.API_URL}UpdateSignUpEmployerDetails`;
    return this.http.put<any>(url, values, this.getOptionsWithAccessToken());
  }

  public updateJGWDetails(values: any): Observable<any> {
    const url = `${environment.API_URL}UpdateJGWDetails`;
    return this.http.put<any>(url, values, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region IQA

  public GetLearnerIqa(traineeId: string, pot: number, staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerIqa?traineeId=${traineeId}&pot=${pot}&staffId=${staffId}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public deleteIqaRecord(iqaRecordId: number): Observable<any> {
    const url = `${environment.API_URL}DeleteIqaRecord/${iqaRecordId}`;
    return this.http.delete<any>(url, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region WEST

  public GetLearnerWestDetails(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerWestDetails?traineeId=${traineeId}&pot=${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region Reviews

  public GetLearnerReviews(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerReviews?traineeId=${traineeId}&pot=${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public importReviewsForTraineeFromOnefile(data: any): Observable<any> {
    const url = `${environment.API_URL}importReviewsForTraineeFromOnefile`;
    return this.http.post(url, data, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region Activities, Awards and Objectives

  public GetLearnerProgress(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerProgress?traineeId=${traineeId}&pot=${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public createNewRegRequest(data: { traineeId: string, pot: number, trModuleId: number }): Observable<any> {
    const url = `${environment.API_URL}CreateNewRegRequest`;
    return this.http.post<any>(url, data, this.getOptionsWithAccessToken());
  }

  public deleteRegRequest(regRequestId: number): Observable<any> {
    const url = `${environment.API_URL}DeleteRegRequest/${regRequestId}`;
    return this.http.delete<any>(url, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region Attatchments

  public GetLearnerAttachmentsList(traineeId: string): Observable<any> {
    const url = `${environment.API_URL}GetLearnerAttachmentsList/${traineeId}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public GetLearnerAttachment(traineeId: string, fileName: string): Observable<any> {
    const url = `${environment.API_URL}GetLearnerAttachment/${traineeId}/${encodeURIComponent(fileName)}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, { responseType: 'blob', headers: options.headers, params: options.params });
  }

  public PostLearnerAttachments(files: FileList, traineeId: string) {
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    formData.append('traineeId', traineeId);

    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);

    const url = `${environment.API_URL}PostLearnerAttachments`;
    return this.http.post(url, formData, { headers });
  }

  //#endregion

  //#region Events

  public getLearnerEvents(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerEvents?traineeId=${traineeId}&pot=${pot}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public PostLearnerEvent(event: { traineeId: string, pot: number, eventTypeId: number, eventDate: Date, signedOffDate: Date, Comments: string, isDraft: boolean }): Observable<any> {
    const url = `${environment.API_URL}PostLearnerEvent`;
    return this.http.post(url, event, this.getOptionsWithAccessToken());
  }

  public PostMassLearnerEvent(event: { postEventLearners: { traineeId: string, pot: number }[], eventTypeId: number, eventDate: Date, signedOffDate: Date, Comments: string, isDraft: boolean }): Observable<any> {
    const url = `${environment.API_URL}PostMassLearnerEvent`;
    return this.http.post(url, event, this.getOptionsWithAccessToken());
  }

  public UpdateLearnerEvent(eventId: number, eventData: { eventTypeId: number, eventDate: Date, signedOffDate: Date, Comments: string, isDraft: boolean }): Observable<any> {
    const url = `${environment.API_URL}UpdateLearnerEvent/${eventId}`;
    return this.http.put(url, eventData, this.getOptionsWithAccessToken());
  }

  public DeleteLearnerEvent(eventId: number): Observable<any> {
    const url = `${environment.API_URL}DeleteLearnerEvent/${eventId}`;
    return this.http.delete(url, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region Attendance

  public getLearnerAttendance(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerAttendance?traineeId=${traineeId}&pot=${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public getLearnerAttendanceWeekDetails(weekId: number): Observable<any> {
    const url = `${environment.API_URL}GetLearnerAttendanceWeekDetails/${weekId}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public updateWeeklyAttendanceFinanceDetails(values): Observable<any> {
    const url = `${environment.API_URL}UpdateWeeklyAttendanceFinanceDetails`;
    return this.http.post<any>(url, values, this.getOptionsWithAccessToken());
  }

  public updateAttendanceWeeklyTimesheet(values): Observable<any> {
    const url = `${environment.API_URL}UpdateAttendanceWeeklyTimesheet`;
    return this.http.post<any>(url, values, this.getOptionsWithAccessToken());
  }

  public getAttendanceReport(traineeId: string, pot: number, reportName: string): Observable<any> {
    const url = `${environment.API_URL}getAttendanceReport/${reportName}/${traineeId}/${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  //#endregion

  //#region Sign Up tab

  public getSignUpTabOptions(): Observable<any> {
    const url = `${environment.API_URL}SignUpDetailsTab/Options`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public getSignUpDetailsTab(traineeId: string, pot: number): Observable<any> {
    const url = `${environment.API_URL}SignUpDetailsTab/${traineeId}/${pot}`;
    return this.http.get(url, this.getOptionsWithAccessToken());
  }

  public updateSignUpDetailsTab(signUp: any): Observable<any> {
    const url = `${environment.API_URL}SignUpDetailsTab/Update`;
    return this.http.put(url, signUp, this.getOptionsWithAccessToken());
  }

  //#endregion

}
