import { PlacementTypeVariant } from './../shared/enums/placement-type-variant';
import { IPlacementBasics } from '../shared/interfaces/placement-basics';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployerDashboardService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public getBusinessDevelopmentStaff(): Observable<any> {
    const url = `${environment.API_URL}GetBusinessDevelopmentStaff`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);

  }


  public getEmployerForDashboard(type: PlacementTypeVariant, placementId: number): Observable<any> {
    const url = `${environment.API_URL}GetEmployer?placementId=${placementId}&type=${type.toString()}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);

  }

  public updatePlacementBasics(placement: IPlacementBasics): Observable<any> {
    const url = `${environment.API_URL}UpdatePlacementBasics`;
    const options = this.getOptionsWithAccessToken();
    console.log('updatePlacementBasics', placement);
    return this.http.post<any>(url, placement, options);
  }

  public updatePlacementContact(contacts: any[]): Observable<any> {
    const url = `${environment.API_URL}UpdatePlacementContact`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, contacts, options);
  }

  public updatePlacementHealthAndSafety(hsInfo: any): Observable<any> {
    const url = `${environment.API_URL}UpdatePlacementHealthAndSafety`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, hsInfo, options);
  }

  public deleteMaytasLinks(maytasLinks: any): Observable<any> {
    const url = `${environment.API_URL}DeletePlacementMaytasLinks`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, maytasLinks, options);
  }

  public createMaytasLinks(maytasLinks: any): Observable<any> {
    const url = `${environment.API_URL}CreatePlacementMaytasLinks`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, maytasLinks, options);
  }

  public addBranch(data: any): Observable<any> {
    const url = `${environment.API_URL}AddBranch`;
    const options = this.getOptionsWithAccessToken();
    return this.http.post<any>(url, data, options);
  }
}
