import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { UserTableService } from '../core/database/user-table.service';
import { PermissionService } from '../core/database/permission.service';
import { AuthErrors } from '../shared/enums/auth-errors';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html'
})
export class AuthErrorComponent implements OnInit {

  errorMessage: string = "";
  count = 10;
  countMessage: string = `You will be taken to the log out screen in ${this.count} seconds.`
  refreshInterval;
  constructor(
    public authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private userTableService: UserTableService,
    private permissionService: PermissionService,
  ) { }

  ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const errorNumber = urlParams.get('error');
    if (typeof errorNumber !== 'undefined' || errorNumber !== null) {
      switch (parseInt(errorNumber)) {
        case AuthErrors.RefreshTokenError:
          this.errorMessage = "There was an error while attempting to grab a refresh token. You will need to log in again to rectify the issue."
          break;
        case AuthErrors.LostUserDetails:
          this.errorMessage = "Your details have been lost. You will need to log in again to rectify the issue."
          break;
        case AuthErrors.PermissionsMissing:
          this.errorMessage = "Your permissions have been lost. You will need to log in again to rectify the issue."
          break;
        case AuthErrors.FailedRedirect:
          this.errorMessage = "There was an error while logging you in. Please log in again. If the issue persists, please contact support."
          break;
        default:
        case AuthErrors.ForceLogout:
          this.errorMessage = "You have been forcefully logged out. Please log in again to continue."
          break;
        case AuthErrors.UserNotActive:
          this.errorMessage = "Your Vision account is not active. Please contact support."
          break;
      }
      this.refreshInterval = setInterval(() => { this.countDown() }, 1000);
    }
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }
  countDown() {
    this.count--;
    this.countMessage = `You will be logged out in ${this.count} seconds.`;
    if (this.count == 0) {
      console.log("Remove Token");
      localStorage.removeItem('access_token');
      console.log("Clear user table");
      this.userTableService.clear();
      console.log("Clear permission table");
      this.permissionService.clear();
      console.log("Set auth to false");
      this.authService.setUiAuthenticated(false);
      this.authService.logout();
    }
    this.changeDetectorRef.detectChanges();
  }



}
