import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { select } from "underscore";

@Component({
  selector: 'app-unopened-activities-report',
  templateUrl: './unopened-activities-report.component.html',
})
export class UnopenedActivitiesReportComponent {

  reportName = "Unopened Activities Report";
  reportVariant = ReportVariant.UnopenedActivities;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: [
        'traineeId',
        'pot',
        'fullname',
        'schemeShort',
        'sector',
        'assessor',
        'startDate',
        'expCompletionDate',
        'potStatus',
        'laimRef',
        'titleShort',
        'awardingBody',
        'percentComplete',
        'objectiveStatus',
        'activityStatus',
        'regDate',
        'regNumber',
        'certAppliedFor',
        'certReceived',
        'qualStartDate',
        'objectiveStatusDate'
      ]
    }
  ];
  dateFilters = [
    'startDate',
    'expCompletionDate',
    'objectiveStatusDate'
  ];
  otherColumns = ['scheme', 'count'];

}
