import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialsModule } from './../core/materials/materials.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LearnerSearchService } from './learner-search.service';
import { LearnerSearchComponent } from './learner-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { SidebarComponent } from './sidebar/sidebar.component';
import { ItemsComponent } from './items/items.component';
import { ContentItemsComponent } from './content-items/content-items.component';
import { SidebarItemsComponent } from './sidebar-items/sidebar-items.component';
import { ContentComponent } from './content/content.component';
import { BreakpointService } from '../core/services/breakpoint.service';


@NgModule({
  declarations: [
    LearnerSearchComponent,
    SidebarComponent,
    ItemsComponent,
    ContentItemsComponent,
    SidebarItemsComponent,
    ContentComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgSelectModule,
    MatToolbarModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [
    LearnerSearchService,
    BreakpointService
  ],
})
export class LearnerSearchModule { }
