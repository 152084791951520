<article class="main-article" *ngIf="show" fxLayout="row wrap" fxLayoutGap="100px" style="padding-left: 25px;"
  [formGroup]="currentProgrammeForm">

  <section class="section-container" fxLayout="row wrap" fxLayoutGap="100px">

    <div class="bm-15" fxLayout="column" fxLayoutGap="15px">

      <!-- Programme -->
      <learner-details-entry [labelName]="'Programme'"
        [labelValue]="currentProgramme.programme ? currentProgramme.programme : '-'" [inline]="true">
      </learner-details-entry>

      <!-- Route -->
      <learner-details-entry [labelName]="'Route'" [labelValue]="currentProgramme.route ? currentProgramme.route : '-'"
        [inline]="true">
      </learner-details-entry>

      <!-- Provider -->
      <learner-details-entry [labelName]="'Provider'"
        [labelValue]="currentProgramme.provider ? currentProgramme.provider : '-'" [inline]="true">
      </learner-details-entry>

      <!-- Centre -->
      <learner-details-entry [labelName]="'Centre'"
        [labelValue]="currentProgramme.centre ? currentProgramme.centre : '-'" [inline]="true">
      </learner-details-entry>

      <!-- Scheme -->
      <learner-details-entry *ngIf="currentProgramme.scheme" [labelName]="'Scheme'"
        [labelValue]="currentProgramme.scheme ? currentProgramme.scheme : '-'" [inline]="true">
      </learner-details-entry>

    </div>

    <div fxLayout="column" fxLayoutGap="15px">

      <!-- Level -->
      <learner-details-entry [labelName]="'Level'" [labelValue]="currentProgramme.level ? currentProgramme.level : '-'"
        [inline]="true">
      </learner-details-entry>

      <!-- Pot Status -->
      <learner-details-entry [labelName]="'Pot Status'" [labelValue]="currentProgramme.potStatus" [inline]="true">
      </learner-details-entry>

      <!-- Start Date -->
      <learner-details-entry [labelName]="'Start date'"
        [labelValue]="currentProgramme.startDate | date: this.dateFormat" [inline]="true">
      </learner-details-entry>

      <!-- Termination Date -->
      <learner-details-entry *ngIf="currentProgramme.terminationDate" [labelName]="'Termination Date'"
        [labelValue]="currentProgramme.terminationDate | date: this.dateFormat" [inline]="true">
      </learner-details-entry>

    </div>

  </section>

  <section class="section-container" fxLayout="column" fxLayoutGap="15px" [style.min-width.px]="'255'">
    <h3 class="dashboard_headers">Enrolment</h3>

    <!-- Business Development Advisor -->
    <section>
      <div *ngIf="editMode && editPermissionLevel === 1; else bda">
        <!-- <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Business Development Advisor:
        </mat-label>
        <br>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <input matInput formControlName="businessDevelopmentAdvisor" placeholder="-">
        </mat-form-field> -->

        <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
          Business Development Advisor:
        </mat-label>
        <br>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <!-- setting a placeholder will make this value be a empty string and could cause issues. -->
          <mat-select formControlName="businessDevelopmentAdvisor">
            <mat-option *ngFor="let bd of this.currentProgramme.bdStaff" [value]="bd.name">{{ bd.name }}</mat-option>
          </mat-select>
        </mat-form-field>


      </div>
      <ng-template #bda>
        <learner-details-entry [labelName]="'Business Development Advisor'"
          [labelValue]="this.currentProgramme.businessDevelopmentAdvisor ?? '-' ">
        </learner-details-entry>
      </ng-template>
    </section>

    <!-- Business Development Source -->
    <section>
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Business Development Source:
        </mat-label>
        <br>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <input matInput formControlName="businessDevelopmentSource" placeholder="-">
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry [labelName]="'Business Development Source'"
          [labelValue]="this.currentProgramme.businessDevelopmentSource">
        </learner-details-entry>
      </div>
    </section>

    <!-- Agreed Start Date -->
    <section>
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Agreed Start Date:
        </mat-label>
        <br>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <input matInput readonly id="date-input" [matDatepicker]="picker" (click)="picker.open()"
            formControlName="agreedStartDate">
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="currentProgrammeForm.get('agreedStartDate').hasError('matDatepickerParse')">
            <span>Please enter a valid date</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry [labelName]="'Agreed Start Date'" [labelValue]="this.formattedAgreedStartDate">
        </learner-details-entry>
      </div>
    </section>

    <!-- Enrolment adress - currently will always be null from the API as is marked TODO -->
    <!-- <learner-details-address-entry [type]="'Enrolment location'"
        *ngIf="currentProgramme.enrolmentLocation?.addressLine1"
        [addressLineOne]="currentProgramme.enrolmentLocation.addressLine1"
        [addressLineTwo]="currentProgramme.enrolmentLocation.addressLine2"
        [addressLineThree]="currentProgramme.enrolmentLocation.postTown"
        [addressLineFour]="currentProgramme.enrolmentLocation.postCode">
      </learner-details-address-entry> -->

    <!-- Learning Agreement -->
    <section>
      <label style="font-weight: 500; vertical-align: middle;"
        [style.color]="editMode && editPermissionLevel === 1 ? 'darkred' : '#000000'">
        No Learning Agreement or SME
      </label>
      <mat-checkbox *ngIf="editPermissionLevel === 1 && editMode; else nlaImage" style="margin-left: 5px;"
        [style.color]="editMode ? 'darkred' : '#000000'" formControlName="nla">
      </mat-checkbox>
      <ng-template #nlaImage>
        <img style="margin-left: 5px; vertical-align: middle;"
          [src]="nla ? '../../../assets/images/icon-yes.png' : '../../../assets/images/icon-no.png'"
          [alt]="nla ? 'yes' : 'no'" />
      </ng-template>
    </section>

  </section>

  <section class="section-container" fxLayout="column" [fxLayoutGap]="editMode ? '15px' : '15.5px'"
    [style.min-width.px]="'300'">

    <h3 class="dashboard_headers">Employer Incentives</h3>

    <!-- New Apprentices -->
    <div *ngIf="editMode && editPermissionLevel === 1; else empInc">
      <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
        [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
        New Apprentices:
      </mat-label>
      <br>
      <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
        <!-- setting a placeholder will make this value be a empty string and could cause issues. -->
        <mat-select formControlName="empInc" placeholder="N/A">
          <mat-option value=0>N/A</mat-option>
          <mat-option value=1>£3000: Aged 16-24, 30+ hours p/w</mat-option>
          <mat-option value=2>£1500: Aged 16-24, 16-29 hours p/w</mat-option>
          <mat-option value=3>£2000: Aged 25+, 30+ hours p/w</mat-option>
          <mat-option value=4>£1000: Aged 25+, 16-29 hours p/w</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-template #empInc>
      <div style="height: 40px;">
        <learner-details-entry [labelName]="'New Apprentices'" [labelValue]="this.formattedEmpInc">
        </learner-details-entry>
      </div>
    </ng-template>

    <!-- Redundancies -->
    <div *ngIf="editMode && editPermissionLevel === 1; else empIncDis">
      <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
        [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
        Redundancies:
      </mat-label>
      <br>
      <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
        <!-- setting a placeholder will make this value be a empty string and could cause issues. -->
        <mat-select formControlName="empIncDis" placeholder="N/A">
          <mat-option value=0>N/A</mat-option>
          <mat-option value=5>£2600: Aged 16+, 30+ hours p/w</mat-option>
          <mat-option value=6>£1300: Aged 16+, 16-29 hours p/w</mat-option>
          <mat-option value=7>£1500: Identified as disabled during recruitment process</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-template #empIncDis>
      <div style="height: 40px;">
        <learner-details-entry [labelName]="'Redundancies'" [labelValue]="this.formattedEmpIncDis">
        </learner-details-entry>
      </div>
    </ng-template>
  </section>

</article>