import { HealthAndSafetyDashReportsService } from './h-s-dash-reports.service';
import { SmtDashReportsService } from './smt-dash-reports.service';
import { AdminDashReportsService } from './admin-dash-reports.service';
import { EmployerTableService } from './employer-table-service';
import { StaffSearchTableService } from './staff-search-table.service';
import { EmployerSearchTableService } from './employer-search-table.service';
import { LearnerSearchTableService } from './learner-search-table.service';
import { FavouriteTableService } from './favourite-table.service';
import { FavouriteLearnerTableService } from './favourite-learner-table.service';
import { LearnerTableService } from './learner-table.service';
import { UserTableService } from './user-table.service';
import { LocalDb } from './database.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SearchHistoryTableService } from './search-history.service';
import { RecentReportFiltersUnsavedTableService } from './recent-report-filters-unsaved-table.service';
import { MilestoneTableService } from './milestone-table-service';
import { ProgressTableService } from './progress-table-service';
import { PermissionService } from './permission.service';
import { AttendanceTableService } from './attendance-table.service';


// See: https://angular.io/docs/ts/latest/cookbook/ngmodule-faq.html#!#q-is-it-loaded
// and: https://angular.io/docs/ts/latest/guide/ngmodule.html#!#core-module for an explanation on what the CoreModule is
// and what its constructor does

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    LocalDb,
    UserTableService,
    LearnerTableService,
    EmployerTableService,
    FavouriteTableService,
    FavouriteLearnerTableService,
    LearnerSearchTableService,
    EmployerSearchTableService,
    StaffSearchTableService,
    AdminDashReportsService,
    SmtDashReportsService,
    HealthAndSafetyDashReportsService,
    SearchHistoryTableService,
    RecentReportFiltersUnsavedTableService,
    MilestoneTableService,
    ProgressTableService,
    PermissionService,
    AttendanceTableService
  ],
  bootstrap: [],
  exports: []
})
export class DatabaseModule {
  constructor(@Optional() @SkipSelf() parentModule: DatabaseModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
