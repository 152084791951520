<mat-card class="date-calculator">
    <div style="float: left; width: 100%;">
        <h2 class="dashboard-titles" style="padding-left:0px;">Review Date Calculator</h2>

        <form [formGroup]="dateCalculatorForm" novalidate>

        <mat-form-field class="review-date">
            <mat-label>Review Date </mat-label>
            <input matInput formControlName="reviewDate" [matDatepicker]="pickerEvent" (dateChange)="calcDates()">
                <mat-datepicker-toggle matSuffix [for]="pickerEvent"></mat-datepicker-toggle>
                <mat-datepicker #pickerEvent></mat-datepicker>
        </mat-form-field>

        <div class="days-later">
            <span *ngIf="plus30" class="date date-title">30 days later:</span>
            <span *ngIf="plus30" class="date">{{plus30 | date: this.dateFormat}}</span> <br>
            <span *ngIf="plus61" class="date date-title">61 days later:</span>
            <span *ngIf="plus30" class="date">{{plus61 | date: this.dateFormat}}</span>
        </div>

        <h3 class="dashboard_headers">Difference between two dates:</h3>

        <div class="date-diff-container">
            <mat-form-field class="date-diff">
                <mat-label>Date 1</mat-label>
                <input matInput formControlName="dateOne" [matDatepicker]="dateOne" (dateChange)="calcDateDifference()">
                    <mat-datepicker-toggle matSuffix [for]="dateOne"></mat-datepicker-toggle>
                    <mat-datepicker #dateOne></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="date-diff">
                <mat-label>Date 2 </mat-label>
                <input matInput formControlName="dateTwo" [matDatepicker]="dateTwo" (dateChange)="calcDateDifference()">
                    <mat-datepicker-toggle matSuffix [for]="dateTwo"></mat-datepicker-toggle>
                    <mat-datepicker #dateTwo></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="days-later">
            <span *ngIf="dateDiff" class="date">{{dateDiff}} days</span>
        </div>

        </form>
    </div>

</mat-card>
