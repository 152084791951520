import { Component } from '@angular/core';
import { SharedFunctionsService } from './../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-applications-report',
  templateUrl: './applications-report.component.html',
  styleUrls: ['./applications-report.component.scss']
})
export class ApplicationsReportComponent {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = null; //ReportVariant.Applications;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'subcontractor', 'programmeType', 'scheme', 'sector', 'assessor', 'centre', 'expectedStartDate', 'startDate']
    }
  ];
  dateFilters = ['startDate', 'expectedStartDate', 'expectedCompletionDate', 'terminationDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];

  getSummaryData = (tempTableData: any[], groupByColumn: ISummaryGroupBy): any => {
    let row: any;
    const summaryDataSource: any[] = [];
    const groupedLevel1 = this.sharedFunctions.groupBy(tempTableData, groupByColumn.value);
    for (const group in groupedLevel1) {
      const details1 = groupedLevel1[group];

      const groupCol = group;

      const groupedLevel2 = this.sharedFunctions.groupBy(details1, 'scheme');
      for (const group in groupedLevel2) {
        const details2 = groupedLevel2[group];
        row = { [groupByColumn.value]: groupCol, scheme: group, count: details2.length };
        summaryDataSource.push(row);
      }
    }
    return summaryDataSource;
  }

}
