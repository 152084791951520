<div class="attachments-container">

  <div class="upload-container">

      <learner-file-upload [traineeId]="traineeId" [loadedFileList]="this.loadedAttachments" (filesUploaded)="getAttachmentList($event)">
      </learner-file-upload>

  </div>

  <div class="filelist-container">
    <div *ngIf="!this.loadedAttachments">
      <ng-container *ngIf="!this.loadingAttachmentsError; else loadingError">
        <p style="text-align: center;">{{loadingText}}</p>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-container>
    </div>
    <ng-template #loadingError>
      <p style="text-align: center;">There was an error fetching attachments, please reload and try again. If this error persists, please contact support.</p>
      <mat-progress-bar mode="determinate" value="100" color="warn"></mat-progress-bar>
    </ng-template>

    <table *ngIf="this.loadedAttachments && !this.loadingAttachmentsError" class="attendance-approval-table"
      mat-table
      [dataSource]="this.dataSource"
      matSort
      [matSortActive]="this.defaultActiveColumn"
      [matSortDirection]="this.defaultSortDirection"
      (matSortChange)="fileListSortChange($event)"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">
          <a class="file-link" (click)="this.loadingFile ? null : getFileForLearner(element.fileName)">{{element.fileName}}</a>
          <mat-spinner *ngIf="this.loadingFile === element.fileName" [diameter]="17" style="margin-left: 5px; display: inline;"></mat-spinner>
        </td>
      </ng-container>

      <ng-container matColumnDef="uploadBy">
        <th mat-header-cell *matHeaderCellDef> Uploaded By </th>
        <td mat-cell *matCellDef="let element"> {{element.staffName ? element.staffName : "Unknown"}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Modified </th>
        <td mat-cell *matCellDef="let element"> {{element.modifiedASW | date: this.dateTimeFormat}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="this.displayedColumns"></tr>
      <tr mat-row [ngClass]="{'highlight': this.filesToHighlight.includes(row.fileName)}" *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3" style="text-align: center;">
          {{ this.noData }}
        </td>
      </tr>
    </table>
  </div>

</div>
