import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-your-learners',
  templateUrl: './your-learners.component.html',
  styleUrls: ['./your-learners.component.scss']
})
export class YourLearnersComponent implements OnInit {


  @HostBinding('class') class = 'active-widget';
  constructor() { }

  widgetId: number;
  dataSource: any;
  displayedColumns: string[] = ['name', 'employer', 'status', 'signedIntoCenter'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  rawData: any;
  hidePendingStarts: boolean = true;

  ngOnInit(): void {
  }

  paginatorAndSort() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setData(data) {
    if (data && data.length > 0) {
      this.rawData = data;
      this.dataSource = new MatTableDataSource(this.rawData);
      this.paginatorAndSort();
      this.filterData();
    }
  }

  unCamelCase(str: string) {
    return str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // uppercase the first character
      .replace(/^./, function (str) { return str.toUpperCase(); })
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  toggleHidePendingStarts(event: MatSlideToggleChange) {
    this.hidePendingStarts = event.checked;
    this.filterData();
  }

  filterData() {
    if (this.hidePendingStarts) {
      this.dataSource.data = this.rawData.filter(item => item.status !== 'Pending Start');
    } else {
      this.dataSource.data = this.rawData;
    }
  }

}
