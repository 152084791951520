import { PhoneRecordFormComponent } from '../phone-record-form/phone-record-form.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffDashboardComponent } from './staff-dashboard.component';
import { StaffDashboardRoutingModule } from './staff-dashboard-routing.module';
import { StaffSearchComponent } from '../staff-search/staff-search.component';
import { PhoneBookComponent } from '../phone-book/phone-book.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialsModule } from '../../core/materials/materials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StaffSearchService } from '../staff-search/staff-search.service';

@NgModule({
    declarations: [
        StaffDashboardComponent,
        StaffSearchComponent,
        PhoneBookComponent,
        PhoneRecordFormComponent
    ],
    imports: [
        CommonModule,
        StaffDashboardRoutingModule,
        FlexLayoutModule,
        MaterialsModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        StaffSearchService
    ]
})
export class StaffDashboardModule { }
