
<div class="file-drop-container" appDnd (fileDropped)="fileBrowseHandler($event)">
  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)"
    [disabled]="!this.readyToUpload"
  />
  <div class="file-drop-information">
    <img style="float: left;" src="../../../../assets/images/icon-documents.png">
    <div style="text-align: center;">
      <h1 class="title">Upload an attachment</h1>
      <h2>Drag file here to upload</h2>
      <!-- <h2 style="margin-top: 0px;">Accepts: pdf, jpeg, png, gif, svg, html</h2> -->
      <div>
        <button mat-flat-button color="primary" (click)="fileInput.click()" [disabled]="!this.readyToUpload">
          <mat-icon svgIcon="Upload" class="button-icon"></mat-icon>
          Upload
        </button>
        <input type="file" #fileInput (change)="fileBrowseHandler($event.target.files)" hidden/>
      </div>
    </div>
  </div>
</div>

<div style="margin-bottom: 10px;">
  <div *ngIf="this.filesToUpload?.length > 0" class="files-list">
    <div *ngFor="let file of filesToUpload; let i = index">

      <div *ngIf="!file?.error">
        <div class="single-file">
          <img src="assets/dnd/ic-file.svg" width="45px" alt="file">
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
            <div>
              <mat-progress-bar *ngIf="!file?.uploadDone" mode="indeterminate"></mat-progress-bar>
              <mat-progress-bar *ngIf="file?.uploadDone" mode="determinate" value="100"></mat-progress-bar>
            </div>
          </div>

          <mat-icon *ngIf="file?.uploadDone else uploading" class="color_green icon-display">cloud_done</mat-icon>
          <ng-template #uploading>
            <mat-icon class="icon-display">backup</mat-icon>
          </ng-template>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="this.failedUploads?.length > 0" class="files-list">
    <div *ngFor="let file of failedUploads; let i = index">

      <div class="single-file">
        <img src="assets/dnd/ic-file.svg" width="45px" alt="file">
        <div class="info">
          <h4 class="name">
            {{ file }}
          </h4>
          <p class="error">
            There was an error uploading this file. If this error persists, please contact support.
          </p>
          <div>
            <mat-progress-bar mode="determinate" value="100" color="warn"></mat-progress-bar>
          </div>
        </div>
        <mat-icon class="color_red icon-display">cloud_off</mat-icon>
      </div>

    <div>
  </div>
</div>

