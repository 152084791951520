<label *ngIf="labelName !== undefined" for="name">
  {{labelName}}:
</label>
  <!-- labelValue.replace("null", "") -->
  <div *ngIf="labelValue !== null && labelValue != undefined && labelValue != 'null' && labelValue != null"
    [style.display]="inline ? 'inline' : 'default' ">
      <span *ngIf="!copyToClipboard">
        {{labelValue.toString().replace("null", "")}}
      </span>
      <a *ngIf="copyToClipboard" (click)="copyValue(labelValue)" >
        {{labelValue.toString().replace("null", "")}}
      </a>
  </div>
