import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }


  /**
   * Get the current route url and params to be used as a back route in the navigation extras state
   * @returns NavigationExtras
   * @example
   * State: {back: { url: string, params: any}}
   */
  public getBackRouteState(): NavigationExtras {
    const queryParamsMap = this.route.snapshot.queryParamMap;
    const queryParams = {};
    for (const key of queryParamsMap.keys) {
      queryParams[key] = queryParamsMap.get(key);
    }

    const navigationExtras: NavigationExtras = {
      state: { back: { url: this.router.url.split('?')[0], params: queryParams } }
    };

    console.dir(navigationExtras);
    return navigationExtras;
  }


  /**
   * Uppercase the first letter of a string
   * @param str
   * @returns string
   */
  public uppercaseFirstLetter(str: string): string {
    if (this.isNullOrEmpty(str)) {
      return str;
    }
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Uppercase first letter of each word in a string
   * @param str
   * @returns
   */
  public uppercaseWords(str: string): string {
    str = str.toLowerCase();
    return str.replace(/\b\w/g, l => l.toUpperCase());
  }

  /**
   * Checks if a string is null, undefined or empty
   * @param str
   * @returns
   */
  public isNullOrEmpty(str: string): boolean {
    return str === null || str === undefined || str.trim() === '';
  }

}
