import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportListRoutingModule } from './report-list-routing.module';
import { ReportingModule } from '../reporting/reporting.module';
import { MaterialsModule } from './../core/materials/materials.module';
import { ReportsResolversService } from './reports-resolvers.service';
import { ReportingModuleV2 } from '../reportingV2/reporting.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReportListRoutingModule,
    ReportingModule,
    ReportingModuleV2,
    MaterialsModule,
  ],
  providers: [
    ReportsResolversService
  ]
})
export class ReportListModule { }
