import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { DATE_FORMAT, DATE_TIME_FORMAT_MOMENT } from 'src/app/app.constants';
import { EventModalMode } from 'src/app/shared/enums/event-modal-mode';
import moment from 'moment';

@Component({
  selector: 'app-learner-event-form',
  templateUrl: './learner-event-form.component.html',
  styleUrls: ['./learner-event-form.component.scss']
})
export class LearnerEventFormComponent implements OnInit {


  eventHeader = 'Create Event';
  eventDate: Date = null;
  signedOffDate: Date = null;
  eventSubHeader: string = null;

  eventMode = EventModalMode;
  mode: EventModalMode = EventModalMode.create;

  eventsForm: UntypedFormGroup;

  auditEvent = false;

  filteredEvents: any[];

  private _typeSearchFilter = '';
  private debounceTimer: NodeJS.Timer;

  get typeSearchFilter(): string {
    return this._typeSearchFilter;
  }

  set typeSearchFilter(value: string) {
    this._typeSearchFilter = value;

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.filterEventTypes();
    }, 300);
  }

  dateFormat = DATE_FORMAT;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<LearnerEventFormComponent>,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    console.dir(this.data);
    this.buildForm();
    this.auditEventCheck(this.data.eventType);
    this.changeEventMode(this.data.mode);
  }

  changeEventMode(mode: EventModalMode) {
    this.mode = mode;

    switch (this.mode) {
      case this.eventMode.view:
        this.eventHeader = this.data.eventType.eventType;
        this.eventDate = this.data.eventDate;
        this.signedOffDate = this.data.signedOffDate;
        this.eventSubHeader = `By ${this.data.staffName} - Created on ${moment(this.data.created).format(DATE_TIME_FORMAT_MOMENT)}`;
        break;
      case this.eventMode.edit:
        this.eventHeader = 'Edit Event'
        this.eventDate = null;
        this.signedOffDate = null;
        this.eventSubHeader = `By ${this.data.staffName} - Created on ${moment(this.data.created).format(DATE_TIME_FORMAT_MOMENT)}`;
        break;
      case this.eventMode.create:
        this.eventHeader = 'Create Event'
        this.eventDate = null;
        this.signedOffDate = null;
        this.eventSubHeader = null;
        break;
      default:
        console.log('Invalid mode');
        break;
    }
  }

  close() {
    if (this.eventsForm.dirty) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: `You are about to close this event without saving. Do you wish to continue?`,
        title: 'Are you sure?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warn',
      }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        result => {
          if (result) {
            this.dialogRef.close(null);
          }
        });
    } else {
      this.dialogRef.close(null);
    }
  }

  delete() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: `You are about to delete this ${this.data.eventType.eventType} event. Do you wish to continue?`,
      title: 'Delete Event',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warn',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.dialogRef.close({ delete: true, eventId: this.data.eventId, isDraft: this.data.isDraft });
        }
      });
  }

  buildForm() {
    this.eventsForm = this.fb.group({
      eventType: [this.data.eventType, Validators.required],
      eventDate: [this.data.eventDate, Validators.required],
      signedOffDate: [this.data.signedOffDate],
      comments: this.data.comments,
      isDraft: this.data.isDraft ?? false,
      eventId: this.data.eventId ?? null,
    });
    if (this.data.allEventTypes) {
      this.data.allEventTypes.sort((a, b) => a.eventType.trim().localeCompare(b.eventType.trim()));
      this.filterEventTypes();
    }
  }

  filterEventTypes() {
    if (this.data.allEventTypes) {

      this.filteredEvents = this.data.permissions.canAudit ? [...this.data.allEventTypes] : this.data.allEventTypes.filter(e => e.auditEvent !== 1);

      if (this.notNullOrEmpty(this.typeSearchFilter)) {
        this.filteredEvents = this.filteredEvents.filter(x => x.eventType?.toLowerCase().includes(this.typeSearchFilter.toLowerCase()));
      }
    }
  }

  notNullOrEmpty(value: string) {
    return value !== null && value.trim() !== '';
  }

  auditEventCheck(eventType) {
    this.auditEvent = eventType?.auditEvent === 1 ? true : false;
    // this is because the validation function dose not clear any errors when the auditEvent value is changed
    // so disabling the field will let the form be valid when it is not required
    if (!this.auditEvent) {
      this.eventsForm.get('signedOffDate').disable();
    } else {
      this.eventsForm.get('signedOffDate').reset();
      this.eventsForm.controls.signedOffDate.patchValue(this.data.signedOffDate);
      this.eventsForm.get('signedOffDate').enable();
    }
  }

  canSave(): boolean {
    return this.eventsForm.valid && this.eventsForm.dirty && (this.mode === this.eventMode.create || this.mode === this.eventMode.edit);
  }

  editPermissionCheck(): boolean {
    if (this.data.eventType?.auditEvent === 1 && !this.data.permissions.canAudit) {
      return false;
    }

    return (this.data.permissions.canEdit || this.data.createdByUser)
  }

  deletePermissionCheck(): boolean {
    if (this.data.eventType?.auditEvent === 1 && !this.data.permissions.canAudit) {
      return false;
    }

    return (this.data.permissions.canDelete || this.data.createdByUser)
  }

}
