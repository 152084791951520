import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportVariant } from 'src/app/shared/enums/report-variant';

@Injectable({
  providedIn: 'root'
})
export class ClassicReportService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    const options = { headers: headers };
    return options;
  }

  getReportData(reportId: number, staffId: number, pageNumber: number, pageSize: number, filter: string = null, sortColumn: string = null, sortColumnDirection: string = null): Observable<any> {
    const report = ReportVariant[reportId];
    let url = `${environment.API_URL}V2/ReportingData/${report}?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}`;
    if (filter !== null) {
      url += `&filterString=${filter}`;
    }
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe(catchError(error => {
      return throwError('An error occurred while fetching data.');
    }));
  }

  getReportSummary(reportId: number, staffId: number, pageNumber: number, pageSize: number, filter: string = null, sortColumn: string = null, sortColumnDirection: string = null, groupByColumn: string = null): Observable<any> {
    const report = ReportVariant[reportId];
    let url = `${environment.API_URL}V2/ReportingSummary/${report}?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}`;
    if (filter !== null) {
      url += `&filterString=${filter}`;
    }
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    if ((groupByColumn !== null)) {
      url += `&groupByString=${groupByColumn}`;
    }
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe(catchError(error => {
      return throwError('An error occurred while fetching data.');
    }));
  }

  getReportFilter(reportId: number, staffId: number): Observable<any> {
    const report = ReportVariant[reportId];
    const url = `${environment.API_URL}V2/ReportingFilter/${report}/${staffId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe(catchError(error => {
      return throwError('An error occurred while fetching data.');
    }));
  }

  getReportChartData(reportId: number, staffId: number, filter: string, groupByColumn: string) {
    const report = ReportVariant[reportId];
    let url = `${environment.API_URL}V2/ReportingChart/${report}?staffId=${staffId}`;
    if (filter !== null) {
      url += `&filterString=${filter}`;
    }
    if ((groupByColumn !== null)) {
      url += `&groupByString=${groupByColumn}`;
    }
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe(catchError(error => {
      return throwError('An error occurred while fetching data.');
    }));
  }

  getReportRefreshDetails(reportId: number) {
    const url = `${environment.API_URL}V2/Reporting/${reportId}/RefreshedOn`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe(catchError(error => {
      return throwError('An error occurred while fetching data.');
    }));
  }

  postReportExport(reportId: number, filterString: string, sortString: string, displayedColumns: string, reportFormat: string): Observable<any> {
    const url = `${environment.API_URL}V2/Reporting/QueueExport`;
    const options = this.getOptionsWithAccessToken();
    options['observe'] = 'response';
    const body = {
      reportId: reportId,
      filterString: filterString,
      sortString: sortString,
      reportFormat: reportFormat,
      displayedColumns: displayedColumns
    };
    return this.http.post<any>(url, body, options);
  }

}


