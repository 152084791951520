import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { LearnerService } from '../../learnerService.service';
import { ConfirmDialogComponent } from  'src/app/shared/components/confirm-dialog/confirm-dialog.component';



@Component({
  selector: 'app-learner-attendance-details',
  templateUrl: './learner-attendance-details.component.html',
  styleUrls: ['./learner-attendance-details.component.scss']
})
export class LearnerAttendanceDetailsComponent implements OnInit {

  dataSourceDetails = new MatTableDataSource([]);
  displayedColumnsDetails: string[] = ['Day', 'CB Hours', 'CB Minutes', 'Virtual Hours', 'Virtual Minutes', 'WB Hours', 'WB Minutes', 'AA Hours', 'AA Minutes', 'H Hours', 'H Minutes', 'UA Hours', 'UA Minutes', 'DT Hours', 'DT Minutes'];
  detailsDataFormatted: any[] =  [
    {'Day': 'Monday'},
    {'Day': 'Tuesday'},
    {'Day': 'Wednesday'},
    {'Day': 'Thursday'},
    {'Day': 'Friday'},
    {'Day': 'Saturday'},
    {'Day': 'Sunday'}
  ];

  travelTypes: [
    {name: 'Daily', id: 'D'},
    {name: 'Weekly', id: 'W'},
    {name: 'Mixed', id:'M'},
    {name: 'Petrol', id: 'P'}
  ]

  weekStartDate: any;
  weekEndDate : any;
  timesheetForm: UntypedFormGroup;
  financeForm: UntypedFormGroup;
  editTimesheet = false;
  editFinance = false;
  savedTimesheetData: any;
  savedFinanceData: any;
  savingTimesheet = false;
  savingFinance = false;
  math = Math;

  attendanceAccuredTotal: number;
  travelAccuredTotal: number;
  mealAccuredTotal: number;
  accuredTotal: number;
  totalPaid: number;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LearnerAttendanceDetailsComponent>,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private learnerService: LearnerService,
    private dialog: MatDialog,
  ){ }

  ngOnInit(): void {
    this.weekStartDate = this.data[0]['weekstartdate'];
    this.weekEndDate = moment(this.weekStartDate).add(6, 'days');

    for (const col in this.data[0]) {
      const colName = this.columnNameFix(col);
      if (col.includes('Mon')) {
        this.detailsDataFormatted[0][colName] = this.data[0][col];
      } else if (col.includes('Tue')) {
        this.detailsDataFormatted[1][colName] = this.data[0][col];
      } else if (col.includes('Wed')) {
        this.detailsDataFormatted[2][colName] = this.data[0][col];
      } else if (col.includes('Thu')) {
        this.detailsDataFormatted[3][colName] = this.data[0][col];
      } else if (col.includes('Fri')) {
        this.detailsDataFormatted[4][colName] = this.data[0][col];
      } else if (col.includes('Sat')) {
        this.detailsDataFormatted[5][colName] = this.data[0][col];
      } else if (col.includes('Sun')) {
        this.detailsDataFormatted[6][colName] = this.data[0][col];
      }
    }
    this.dataSourceDetails.data = this.detailsDataFormatted;

    this.setFinancialTotals();
    this.buildForms();
  }

  setFinancialTotals() {
    this.attendanceAccuredTotal = (this.data[0].allowance * this.data[0].attendance) + this.data[0].deductionAllowance + this.data[0].additionAllowance;
    this.travelAccuredTotal = this.data[0].payTravel +  this.data[0].deductionTravel + this.data[0].additionTravel;
    this.mealAccuredTotal = (this.data[0].mealDays * this.data[0].mealValue) + this.data[0].additionMeal + this.data[0].deductionMeal;
    this.accuredTotal = this.attendanceAccuredTotal + this.travelAccuredTotal + this.mealAccuredTotal;

    this.totalPaid = this.data[0].paidAllowance + this.data[0].paidTravel + this.data[0].paidMeal;
  }

  calculateWeeklySum(col) {
    let result = this.dataSourceDetails.data.map(day =>
      day[col]).reduce((prev, next) =>
        (prev = prev ? prev : 0) + (next ? next : 0));

    // Below checks if it is an hour column and adds minutes over multiples of 60
    if (col.includes('Hours')) {
      const relatedCol = col.replace("Hours", "Minutes");
      const minutesToAdd = this.dataSourceDetails.data.map(day =>
        day[relatedCol]).reduce((prev, next) =>
          (prev = prev ? prev : 0) + (next ? next : 0));
      const hours = Math.floor(minutesToAdd/60);
      result += hours >= 1 ? hours : 0;

    // Below checks if it is a minute column and subtracts full multiples of 60
    } else {
      const hours = Math.floor(result/60);
      result = hours >= 1 ? result - (hours * 60) : result;
    }
    return result;
  }

  calculateDaylySum(row : object, collumn: string) : number {
    const hours = [];
    const minutes = [];
    for(const [key, value] of Object.entries(row)) {
      if(key !== 'Day' && key.split(' ')[0] !== 'Virtual') {
        if(key.split(' ')[1] === 'Hours' && value !== null) {
          hours.push(value);
        } else if(value !== null){
          minutes.push(value);
        }
      }
    }

    const totalMinutes = minutes.reduce((a, b) => a + b, 0);

    if(collumn.split(' ')[1] === 'Hours') {
      hours.push(Math.floor(totalMinutes / 60));
      return hours.reduce((a, b) => a + b, 0);
    }

    return totalMinutes % 60;
  }

  calculateAllSum(col): number {
    const dayHourSums = [];
    const dayMinuteSums = [];
    this.dataSourceDetails.data.forEach(day => dayHourSums.push(this.calculateDaylySum(day, 'Week Hours')));
    this.dataSourceDetails.data.forEach(day => dayMinuteSums.push(this.calculateDaylySum(day, 'Week Minutes')));

    const totalMinutes = dayMinuteSums.reduce((a, b) => a + b, 0);

    if(col.split(' ')[1] === 'Hours') {
      dayHourSums.push(Math.floor(totalMinutes / 60));
      return dayHourSums.reduce((a, b) => a + b, 0);
    }

    return totalMinutes % 60;
  }

  buildForms() {
    this.buildTimesheetForm();
    this.buildFinanceForm();

    this.changeDetectorRef.detectChanges();
  }

  travelTypeReadable(type) {
    switch(type) {
      case 'D':
        return 'Daily';
      case 'W':
        return 'Weekly';
      case 'M':
        return 'Mixed';
      case 'P':
        return 'Petrol';
      default:
        return 'Unknown';
    }
  }

  isTravelType(): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      switch(control.value) {
        case 'D':
        case 'W':
        case 'M':
        case 'P':
          return null;
        default:
          return {isTravelType: {value: control.value}};

      }
    };
  }

  buildFinanceForm() {
    this.financeForm = this.fb.group({
      allowanceAddition: new UntypedFormControl(this.data[0].additionAllowance.toFixed(2), [Validators.min(0)]),
      allowanceDeduction: new UntypedFormControl(Math.abs(this.data[0].deductionAllowance).toFixed(2), [Validators.min(0)]),
      paidAllowance: new UntypedFormControl(this.data[0].paidAllowance.toFixed(2), [Validators.min(0)]),
      travelType: new UntypedFormControl(this.data[0].travelType, [this.isTravelType]),
      travelCost: new UntypedFormControl(this.data[0].travel.toFixed(2), [Validators.min(0)]),
      travelAddition: new UntypedFormControl(this.data[0].additionTravel.toFixed(2), [Validators.min(0)]),
      travelDeduction: new UntypedFormControl(Math.abs(this.data[0].deductionTravel).toFixed(2), [Validators.min(0)]),
      paidTravel: new UntypedFormControl(this.data[0].paidTravel.toFixed(2), [Validators.min(0)]),
      mealAddition: new UntypedFormControl(this.data[0].additionMeal.toFixed(2), [Validators.min(0)]),
      mealDeduction: new UntypedFormControl(Math.abs(this.data[0].deductionMeal).toFixed(2), [Validators.min(0)]),
      paidMeal: new UntypedFormControl(this.data[0].paidMeal.toFixed(2), [Validators.min(0)]),
      weeklyNote: new UntypedFormControl(this.data[0].notes),
      permanentNote: new UntypedFormControl(this.data[0].permanentNote),
    });
  }

  resetFinanceForm() {
    this.financeForm.reset({
      allowanceAddition: this.data[0].additionAllowance.toFixed(2),
      allowanceDeduction: Math.abs(this.data[0].deductionAllowance).toFixed(2),
      paidAllowance: this.data[0].paidAllowance.toFixed(2),
      travelType: this.data[0].travelType,
      travelCost: this.data[0].travel.toFixed(2),
      travelAddition: this.data[0].additionTravel.toFixed(2),
      travelDeduction: Math.abs(this.data[0].deductionTravel).toFixed(2),
      paidTravel: this.data[0].paidTravel.toFixed(2),
      mealAddition: this.data[0].additionMeal.toFixed(2),
      mealDeduction: Math.abs(this.data[0].deductionMeal).toFixed(2),
      paidMeal: this.data[0].paidMeal.toFixed(2),
      weeklyNote: this.data[0].notes,
      permanentNote: this.data[0].permanentNote,
    });
  }

  buildTimesheetForm() {
    this.timesheetForm = this.fb.group({});
    this.detailsDataFormatted.forEach((day) => {
      for(const key of Object.keys(day)) {
        if(this.isColumnEditable(key.split(' ')[0])) {
          const controlName = day['Day'].substring(0,3) + ' ' + key;
          if(key.split(' ')[1] === 'Hours' || key.split(' ')[1] === 'Minutes') {
            this.timesheetForm.addControl(controlName, new UntypedFormControl(
              key.split(' ')[1] === 'Hours' ? this.calculateHoursAndMinutes(day, key).hours : this.calculateHoursAndMinutes(day, key).minutes,
              key.split(' ')[1] === 'Hours' ? [Validators.max(10), Validators.min(0)] : [Validators.max(59), Validators.min(0)],
            ));
          }
        }
      }
    });
  }

  columnNameFix(currentName) {
    const oldName = currentName.substring(0, currentName.length - 3);
    const newName =
      oldName == 'aaH' ? 'AA Hours' :
      oldName == 'aaM' ? 'AA Minutes' :
      oldName == 'auH' ? 'Virtual Hours' :
      oldName == 'auM' ? 'Virtual Minutes' :
      oldName == 'auH' ? 'AU Hours' :
      oldName == 'cbH' ? 'CB Hours' :
      oldName == 'cbM' ? 'CB Minutes' :
      oldName == 'hh' ? 'H Hours' :
      oldName == 'hm' ? 'H Minutes' :
      oldName == 'uaH' ? 'UA Hours' :
      oldName == 'uaM' ? 'UA Minutes' :
      oldName == 'wbH' ? 'WB Hours' :
      oldName == 'wbM' ? 'WB Minutes' : null
    return newName;
  }

  close(): void {
    if(this.savingFinance || this.savingTimesheet) {
      this.snackBar.open('Please wait for the save to complete', 'Close', {
        duration: 3000
      });
      return;
    } else if (this.timesheetForm.dirty || this.financeForm.dirty) {
      this.closeWithWarning(this.timesheetForm.dirty , this.financeForm.dirty);
      return;
    }

    this.closeDialog();
  }

  closeWithWarning(timesheet, finance) {
    const unSavedTabs = timesheet && finance ? 'Timesheet and Finance sections' : timesheet ? 'Timesheet section' : 'Finance section';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: `You are about to close without saving, **changes made to the ${unSavedTabs} will be lost**. Do you wish to continue?`,
      title: 'Are you sure?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      type: 'warn',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result =>
      {
        if(result) {
          this.closeDialog();
        }
    });
  }

  closeDialog() {
    const returnData = {};
    if(this.savedTimesheetData !== undefined) {
      returnData['timesheetData'] = this.savedTimesheetData;
    }
    if(this.savedFinanceData !== undefined) {
      returnData['financeData'] = this.savedFinanceData;
    }
    this.dialogRef.close(returnData);
  }

  calculateHoursAndMinutes(row, column) : {hours: number, minutes: number} {
    let hours = row[column.split(' ')[0] + ' Hours'];
    let minutes = row[column.split(' ')[0] + ' Minutes'];

    if(hours === null || hours === undefined) {
      hours = 0;
    }
    if(minutes === null || minutes === undefined) {
      minutes = 0;
    }

    const totalHours = hours + Math.floor(minutes / 60);
    const totalMinutes = minutes % 60;
    return { hours: totalHours, minutes: totalMinutes};
  }

  isColumnEditable(column) : boolean {
    switch(column.split(' ')[0]) {
      case 'UA':
      case 'WB':
        return true;
      default:
        return false;
    }
  }

  makeHeaderNameFriendly(header): string {

    switch(header) {
      case 'AA':
        return 'Authorised Absence';
      case 'CB':
        return 'Centre';
      case 'H':
        return 'Holiday';
      case 'UA':
        return 'Unauthorised Absence';
      case 'WB':
        return 'Work';
      case 'DT':
        return 'Daily Total';
      default:
        return header;
    }
  }

  editTimesheetToggle() {
    this.editTimesheet = !this.editTimesheet;
    if(!this.editTimesheet) {
      // Should reall reset the values but rebuilding it is easier and doesnt break anything
      this.buildTimesheetForm();
    }
  }

  editFinanceToggle() {
    this.editFinance = !this.editFinance;
    if(!this.editFinance) {
      this.resetFinanceForm();
    }
  }

  saveFinanceChanges() {
    if(this.savingFinance) {
      return;
    }

    this.savingFinance = true;

    if(!this.financeForm.valid) {
      this.savingFinance = false;
      this.snackBar.open('Error: Unable to save due to invalid values. Make sure values are not less than 0' , '', {
        duration: 4000
      });
      return;
    }

    // format data
    const formData = this.financeForm.value;
    formData['allowanceDeduction'] = Number(-formData['allowanceDeduction']);
    formData['travelDeduction'] = Number(-formData['travelDeduction']);
    formData['mealDeduction'] = Number(-formData['mealDeduction']);
    formData['allowanceAddition'] = Number(formData['allowanceAddition']);
    formData['travelAddition'] = Number(formData['travelAddition']);
    formData['mealAddition'] = Number(formData['mealAddition']);
    formData['travelCost'] = Number(formData['travelCost']);
    formData['paidAllowance'] = Number(formData['paidAllowance']);
    formData['paidTravel'] = Number(formData['paidTravel']);
    formData['paidMeal'] = Number(formData['paidMeal']);
    formData['AttendanceWeekId'] = this.data[0].attendanceweekid;
    formData['TraineeId'] = this.data[0].traineeid;
    formData['Pot'] = this.data[0].pot;

    let PermanentNoteFailedToUpdate = false;

    this.learnerService.updateWeeklyAttendanceFinanceDetails(formData).subscribe({
      next: (response) => {
        this.data[0].payTravel = response.updatedAccruedTravel;
        this.savedFinanceData = {
          payTravel:  response.updatedAccruedTravel,
        };

        if(!response.permanentNoteFailedToUpdate) {
          this.data[0].permanentNote = formData['permanentNote'];
          this.savedFinanceData.permanentNote = formData['permanentNote'];
        }
        else {
          PermanentNoteFailedToUpdate = true;
        }
      },
      error: (error) => {
        this.savingFinance = false;
        this.snackBar.open('Error: ' + error.error , '', {
          duration: 4000
        });
      },
      complete: () => {
        this.data[0].additionAllowance = formData['allowanceAddition'];
        this.data[0].deductionAllowance = formData['allowanceDeduction'];
        this.data[0].additionTravel = formData['travelAddition'];
        this.data[0].deductionTravel = formData['travelDeduction'];
        this.data[0].additionMeal = formData['mealAddition'];
        this.data[0].deductionMeal = formData['mealDeduction'];
        this.data[0].travel = formData['travelCost'];
        this.data[0].travelType = formData['travelType'];
        this.data[0].paidAllowance = formData['paidAllowance'];
        this.data[0].paidTravel = formData['paidTravel'];
        this.data[0].paidMeal = formData['paidMeal'];
        this.data[0].notes = formData['weeklyNote'];


        this.setFinancialTotals();
        this.editFinanceToggle();
        this.savingFinance = false;
        if(PermanentNoteFailedToUpdate) {
          this.snackBar.open('Finance changes saved but failed to update permanent note. Please contact system support.' , '', {
            duration: 4000
          });
        }
        else {
          this.snackBar.open('Finance changes saved successfully.' , '', {
            duration: 4000
          });
        }
      }
    });
  }

  saveTimesheetChanges() {
    if(this.savingTimesheet) {
      return;
    }

    this.savingTimesheet = true;

    if(!this.timesheetForm.valid) {
      this.savingTimesheet = false;
      this.snackBar.open('Error: Unable to save due to invalid times. Hours must be between 0 & 12, Mins must be between 0 & 59.' , '', {
        duration: 4000
      });
      return;
    }

    const formData = {};

    // repmove spaces and handle nulls when creating formdata
    Object.keys(this.timesheetForm.value).forEach((key) => {
      const newKey = key.replace(/ /g,'');
      this.timesheetForm.controls[key].setValue(this.timesheetForm.value[key] === null || this.timesheetForm.value[key] === undefined ? 0 : this.timesheetForm.value[key])
      formData[newKey] = this.timesheetForm.value[key];
    });

    // add weekid to formdata
    formData['AttendanceWeekId'] = this.data[0].attendanceweekid;

    // Save the form to the database
    this.learnerService.updateAttendanceWeeklyTimesheet(formData).subscribe( (response) => {
      if(response.status !== "Success") {
        this.savingTimesheet = false;
        this.snackBar.open('Error: ' + response.status , '', {
          duration: 4000
        });
        return;
      }

      this.data[0].attendance = response.updatedAttendance;
      this.data[0].mealDays = response.updatedMealDays;
      this.setFinancialTotals();

      // Update the table
      this.detailsDataFormatted.forEach((day) => {
        for(const key of Object.keys(day)) {
          if(this.isColumnEditable(key.split(' ')[0])) {
            const controlName = day['Day'].substring(0,3) + ' ' + key;
            if(key.split(' ')[1] === 'Hours' || key.split(' ')[1] === 'Minutes') {
              day[key] = this.timesheetForm.value[controlName];
            }
          }
        }
      });

      const updatedTimes = this.calculateAttendedAndDockedTimes(this.detailsDataFormatted);
      this.savedTimesheetData = {
        'attendanceWeekId': formData['AttendanceWeekId'],
        'timeAttended': updatedTimes.timeAttended,
        'timeDocked': updatedTimes.timeDocked,
        'attendance': this.data[0].attendance,
        'mealDays': this.data[0].mealDays,
        'details': {},
      };

      this.dataSourceDetails.data.forEach( day => {
        for(const[key, value] of Object.entries(day)) {
          if(this.isColumnEditable(key)) {
            const keyname = key.split(' ')[0].toLowerCase() + key.split(' ')[1].substring(0,1) + day['Day'].substring(0,3);
            this.savedTimesheetData.details[keyname] = value;
          }
        }
      });

      this.dataSourceDetails.data = this.detailsDataFormatted;
      this.changeDetectorRef.detectChanges();
      this.editTimesheetToggle();
      this.savingTimesheet = false;
      this.snackBar.open('Timesheet updated successfully', '', {
        duration: 2000
      });
    });

  }


  calculateAttendedAndDockedTimes(data) : {timeAttended: {hours: number, minutes: number}, timeDocked: {hours: number, minutes: number} } {
    const attendedHours = [], attendedMinutes = [], dockedHours = [], dockedMinutes = [];

    data.forEach( day => {
      for(const [key, value] of Object.entries(day)) {
        if(key !== 'Day' && key.split(' ')[0] !== 'UA') {
          if(key.split(' ')[1] === 'Hours') {
            attendedHours.push(value);
          } else if(key.split(' ')[1] === 'Minutes') {
            attendedMinutes.push(value);
          }
        } else if(key.split(' ')[0] === 'UA') {
          if(key.split(' ')[1] === 'Hours') {
            dockedHours.push(value);
          } else if(key.split(' ')[1] === 'Minutes') {
            dockedMinutes.push(value);
          }
        }
      }
    });

    const timeAttendedHours = attendedHours.reduce((a, b) => a + b, 0);
    const timeAttendedMinutes = attendedMinutes.reduce((a, b) => a + b, 0);
    const timeDockedHours = dockedHours.reduce((a, b) => a + b, 0);
    const timeDockedMinutes = dockedMinutes.reduce((a, b) => a + b, 0);
    const timeAttended = { 'hours': timeAttendedHours + Math.floor(timeAttendedMinutes / 60), 'minutes': timeAttendedMinutes % 60 };
    const timeDocked = { 'hours': timeDockedHours + Math.floor(timeDockedMinutes / 60), 'minutes': timeDockedMinutes % 60 };

    return { timeAttended: timeAttended, timeDocked: timeDocked };
  }

  setDynamicWidth(value) {
    let length = 50;
    if(Math.abs(value) < 10 || value === null || value === undefined) {
      length = 31;
    } else if(Math.abs(value) < 100) {
      length = 40;
    }

    if(value < 0) {
      length = length + 5;
    }

    return `${length}px`;
  }


}
