import { Component, Inject, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-learner-west-details',
  templateUrl: './learner-west-details.component.html',
  styleUrls: ['./learner-west-details.component.scss']
})
export class LearnerWestDetailsComponent implements OnInit {

  aonDisplayedColumns: string[] = ['date', 'level', 'formulasEquations', 'fractionsDecimalsPercentages', 'measuresMoneyTime', 'proportionRationScale', 'shapeSpacePosition', 'statisticsProbablity', 'usingData', 'wholeNumbers'];
  comDisplayedColumns: string[] = ['date', 'level', 'speakingListening', 'readAndUnderstandWords', 'readAndUnderstandText', 'findInformation', 'writeRelevantText', 'spellCorrectly', 'punctuateCorrectly', 'useCorrectGrammar'];
  dlDisplayedColumns: string[] = ['date', 'level', 'digitalResponsibility', 'digitalProductivity', 'informationLiteracy', 'digitalCollaboration', 'digitalCreativity', 'digitalLearning'];

  displayedColumns: string[];
  title: string;
  dateFormat = DATE_FORMAT;

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private sharedFunctions: SharedFunctionsService,
    private dialogRef: MatDialogRef<LearnerWestDetailsComponent>,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    if(this.data.title === 'Application of Numbers') {
      this.displayedColumns = this.aonDisplayedColumns;
    } else if(this.data.title === 'Communication') {
      this.displayedColumns = this.comDisplayedColumns;
    } else if(this.data.title === 'Digital Literacy') {
      this.displayedColumns = this.dlDisplayedColumns;
    }
    for(let i = 0; i < this.data.westDetails.length; i++) {
      this.data.westDetails[i].index = i;
    }
    this.dataSource = new MatTableDataSource<any>(this.data.westDetails);
    console.log('Data source', this.dataSource);
  }

  compairLevel(index: number, level: string, column: string): string {
    if(index < this.data.westDetails.length - 1) {
      let value = this.levelValue(level);
      let previousValue = this.levelValue(this.data.westDetails[index+1][column]);
      let returnValue = '';
      if(value > previousValue) {
        returnValue = '<img class="arrow" src="../../../assets/arrows/green-up.svg" alt="level increased"/>';
      } else if (value < previousValue) {
        returnValue = '<img class="arrow" src="../../../assets/arrows/red-down.svg" alt="level decreassed"/>';
      }
      return returnValue;
    }
    return '';
  }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }

  isDate(value, column): boolean {
    return this.sharedFunctions.isDateFromColumnName(value, column);
  }

  close() {
    this.dialogRef.close();
  }

  levelValue(level: string): number {
    let value = 0;
    switch(level?.toLowerCase()){
      case 'e1':
        value = 1;
        break;
      case 'e2':
        value = 2;
        break;
      case 'e3':
        value = 3;
        break;
      case 'l1':
        value = 4;
        break;
      case 'l2':
        value = 5;
        break;
      case 'l3':
        value = 6;
        break;
    }
    return value;
  }



}
