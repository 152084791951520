<div class="reviews-container">

  <div class="reviews-table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">
          Title
        </th>
        <td mat-cell *matCellDef="let element">
          <a mat-button class="edit-reviews-button icon--click-hover" (click)="openDialog(element)">
            {{element.visitType}}
          </a>
        </td>
      </ng-container>

      <!-- Actual Date Column -->
      <ng-container matColumnDef="actualDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by actual date">
          Actual Date
        </th>
        <td mat-cell *matCellDef="let element"> {{element.actualDate | date: this.dateFormat}} </td>
      </ng-container>

      <!-- Assessor By Column -->
      <ng-container matColumnDef="assessor">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by assessor">
          Assessor
        </th>
        <td mat-cell *matCellDef="let element"> {{element.assessor}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
          Status
        </th>
        <!-- <td mat-cell *matCellDef="let element"><span> {{element.outcome}} </span></td> -->
        <td mat-cell *matCellDef="let element">
          <span class="status-text" [ngClass]="getOutcomeClass(element.outcome)">
            {{element.outcome}}
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <ng-container *ngIf="this.loadedReviewsData">
          <td class="mat-cell" colspan="4" style="text-align: center;">
            {{ this.noData }}
          </td>
        </ng-container>
      </tr>
    </table>
    <mat-progress-bar *ngIf="!this.loadedReviewsData" mode="indeterminate"></mat-progress-bar>

    <div class="button-container">
      <a mat-button class="submit-button" (click)="getReviews()"><img src="../../../assets/images/icon-sync.png" style="margin-right: 10px;"> Sync</a>
    </div>
  </div>

</div>
