import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LearnerPayService {

  constructor(private http: HttpClient, private sharedFunctions: SharedFunctionsService) { }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public getTraineePayExport(exportType: number, staffId: number, contractId: string, subcontratorId: string, centreId: string, schemeId:string, sectorId: string, assessorId: string, weekCommencing: string): Observable<any> {
    let apiMethod = exportType == 1 ? 'GetTraineePayExport' : exportType == 2 ? 'GetTraineePayExportSantanderBacs' : exportType == 3 ? 'GetTraineePayExportBacs' : exportType == 4 ? 'GetTraineePayExportCheque' : '';
    const url = `${environment.API_URL}${apiMethod}?staffId=${staffId}&contractId=${contractId}&subcontractorId=${subcontratorId}&centreId=${centreId}&schemeId=${schemeId}&sectorId=${sectorId}&assessorId=${assessorId}&start=${weekCommencing}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public exportTraineePayFile(exportType: number, data: any[], weekCommencing: string): string {
    if(!data || data.length == 0) return 'export not done';
    let keys: string[] = [];
    for (const [key, value] of Object.entries(data[0])) { keys.push(key); }
    let fileName = exportType == 1 ? 'TraineePay_' : exportType == 2 ? 'TraineePaySantanderBacs_' : exportType == 3 ? 'TraineePayBacs_' : exportType == 4 ? 'TraineePayCheque_' : '';
    const fileType = exportType == 2 ? 'txt' : 'csv';
    const includeHeader = exportType == 1 ? true : false;
    fileName = fileName + `_${weekCommencing.replace(/\//g,'')}`;
    return this.sharedFunctions.export(data, keys, fileName , fileType, true, false, includeHeader);
  }

  public getFilters(staffId: string, programmeTypeId: string, centreId: string, contractId: string, subcontratorId: string, schemeId:string, sectorId: string, assessorId: string): Observable<any> {
    const url = `${environment.API_URL}GetFiltersForTraineePayExport?staffId=${staffId}&programmeTypeId=${programmeTypeId}&centreId=${centreId}&contractId=${contractId}&subcontractorId=${subcontratorId}&schemeId=${schemeId}&sectorId=${sectorId}&assessorId=${assessorId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }
}
