import { IUserInfo } from './../../shared/interfaces/user-info';
import { PlacementBasics } from './../../employer-dashboard/employer-dashboard.component';
import { PlacementTypeVariant } from './../../shared/enums/placement-type-variant';
import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import * as _ from 'underscore';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EmployerMaytasAddLinkComponent } from './employer-maytas-add-link/employer-maytas-add-link.component';
import { now } from 'underscore';

@Component({
  selector: 'app-employer-maytas-info',
  templateUrl: './employer-maytas-info.component.html',
  styleUrls: ['./employer-maytas-info.component.scss']
})
export class EmployerMaytasInfoComponent implements OnInit {

  @Input() maytasLinks;
  @Input() isEditForm: boolean;
  @Input() showLinks: boolean;
  @Input() maytasLinkOptions;
  @Input() placementType: PlacementTypeVariant;
  @Input() placementBasics: any;
  @Input() associatedPlacements: any[];
  @Input() userInfo: IUserInfo;

  @Output() maytasLinkDelete = new EventEmitter();

  maytasLinksUpdated: any[];
  newMaytasLinks: any[];

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    //this.resetUpdatedMaytasLinks();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.maytasLinks) this.resetUpdatedMaytasLinks();
  }

  markMaytasLinkForDeletion(link) {
    if (!this.maytasLinksUpdated.length) this.resetUpdatedMaytasLinks();
    const linkToDelete = this.maytasLinksUpdated.find(l => l.employerId == link.employerId && l.vBranchId == link.vBranchId);

    if (linkToDelete.operation && linkToDelete.operation == 'delete') {
      linkToDelete.operation = 'none';
    } else {
      Object.assign(linkToDelete, { operation: 'delete' });
    }
  }

  removeAddedMaytasLink(link) {
    let ix = this.newMaytasLinks.findIndex(x =>
      x.employer.employerId == link.employer.employerId
      && x.branch.placementId == link.branch.placementId);

    if (ix != -1) {
      this.newMaytasLinks.splice(ix, 1);
    }

    ix = this.maytasLinksUpdated.findIndex(x =>
      (x.employer && x.employer.employerId == link.employer.employerId)
      && (x.branch && x.branch.placementId == link.branch.placementId));

    if (ix != -1) {
      this.maytasLinksUpdated.splice(ix, 1);
    }
  }

  addMaytasLink() {
    if (this.maytasLinkOptions) {
      this.openDialog();
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.height = '700px';
    dialogConfig.data = this.maytasLinkOptions;
    dialogConfig.data.placementType = this.placementType;
    dialogConfig.data.placementBasics = this.placementBasics;
    dialogConfig.data.associatedPlacements = this.associatedPlacements;

    const dialogRef = this.dialog.open(EmployerMaytasAddLinkComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      formData => {
        console.log(formData);
        if (formData) {
          this.newMaytasLinks = []; // TODO: replace newMaytasLinks entirely with maytasLinksUpdated new entries
          formData.forEach(element => {
            this.newMaytasLinks.push(element);
            Object.assign(element, { operation: 'create' });
            this.maytasLinksUpdated.push(element);
          });
          this.cdr.detectChanges();
        }
      });
  }

  resetUpdatedMaytasLinks() {
    this.maytasLinksUpdated = _.clone(this.maytasLinks);
    if (this.maytasLinksUpdated.length) this.deselectLinks();
  }

  deselectLinks() {
    if (!this.maytasLinksUpdated.length) this.resetUpdatedMaytasLinks();

    if (this.newMaytasLinks) {
      this.maytasLinksUpdated = this.maytasLinksUpdated.filter(l => {
        return !this.newMaytasLinks.includes(l);
      });
      this.newMaytasLinks = [];
    }
    _.each(this.maytasLinksUpdated, l => {
      l.operation = 'none';
    })
  }

  extractNewLinksForCurrent() {
    const linksToExtract = this.newMaytasLinks.filter(l =>
      (this.placementType == PlacementTypeVariant.Employer ? l.employer.employerId : l.branch.placementId) == this.placementBasics.placementId
    );

    linksToExtract.forEach(link => {
      let newLink = {
        employerId: link.employer.employerId,
        employerName: link.employer.employerName,
        vBranchId: link.branch.placementId,
        createdBy: this.userInfo.userName,
        createdById: this.userInfo.staffId,
        createdWhen: new Date()
      };

      this.maytasLinks.push(newLink);
    });
  }

  emitData() {
    console.log('emit data from maytas info');

    if (this.maytasLinksUpdated.length) {

      const maytasLinksToDelete = this.maytasLinksUpdated.filter(l => l.operation == 'delete');
      const maytasLinksToCreate = this.maytasLinksUpdated.filter(l => l.operation == 'create');

      if (maytasLinksToDelete.length > 0) {
        const returnData = { values: maytasLinksToDelete, operation: 'delete' };
        console.log('deleting: ', returnData);
        this.maytasLinkDelete.emit({ maytasLinks: returnData });
        this.extractNewLinksForCurrent();
        this.deselectLinks();
      }

      if (maytasLinksToCreate.length > 0) {
        const returnData = { values: maytasLinksToCreate, operation: 'create' };
        console.log('creating: ', returnData);
        this.maytasLinkDelete.emit({ maytasLinks: returnData });
        this.extractNewLinksForCurrent();
        this.deselectLinks();
      }

    }
    else {
      console.log('no changes to emit');
    }
  }
}
