import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrillServiceService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public getToken(staffId: number, email: string, name: string): Observable<any>{
    const url = `${environment.API_URL}GetFrillToken`;
    const options = this.getOptionsWithAccessToken();
    let postData = {'staffId': staffId, 'email' : email, 'name': name}
    return this.http.post(url, postData, options);
  }



}
