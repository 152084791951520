import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

export interface IFavourteLearners {
  id: number;
  name: string;
  pot: number;
  traineeId: string;
}

@Injectable()
export class FavouriteLearnerTableService {
  table: Dexie.Table<IFavourteLearners, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('favouriteLearner');
  }

  get(id) {
    return this.table.get(id);
  }

  getAll() {
    return this.table.toArray();
  }

  add(data: IFavourteLearners, id) {
    return this.table.add(data, id);
  }

  put(data, id) {
    return this.table.put(data, id);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }

  clear() {
    return this.table.clear();
  }
}
