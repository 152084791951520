
/**
 * Standard date format to be used across the application
 */
export const DATE_FORMAT = 'dd/MM/y';

export const DATE_FORMAT_MOMENT = "DD/MM/yyyy";
/**
 * Standard date and time format to be used across the application
 */
export const DATE_TIME_FORMAT = 'dd/MM/y HH:mm';

/**
 * Standard date and time format for moment to be used across the application
 */
export const DATE_TIME_FORMAT_MOMENT = "DD/MM/yyyy HH:mm";

/**
 * Standard no data found message to be used across the application
 */
export const NO_DATA = 'No records to display.';

/**
 * Standard data fetch error message to be used across the application
 */
export const ERROR_GETTING_DATA = 'Error getting data, please reload or try again in a few minutes. If this error persists, contact system support and let them know!';


/**
 * Standard error for persisting data
 */
export const ERROR_SAVING_DATA = 'Error saving data, please try again. If this error persists, contact system support and let them know!';
