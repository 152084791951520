<div class="attendance-approval">
  <div>
    <mat-form-field style="width: 100%" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input #filterValue matInput placeholder="Start typing to filter results" (keyup)="applyFilter(filterValue.value)"
        autocomplete="off" [(ngModel)]="this.filterString">
      <mat-slide-toggle [checked]="onlyMyLearnerStatus" (change)="toggleOnlyMyLearnerStatus($event)" matSuffix>Show Only
        My
        Learners</mat-slide-toggle>
    </mat-form-field>
  </div>
  <div class="reportTable" style="display: block; max-height: 550px; overflow:auto;">
    <table mat-table [dataSource]="this.tableDataSource" #tableSort="matSort" matSort
      matSortActive="{{this.tableSort.active}}" matSortDirection="{{this.tableSort.direction}}">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef sticky>
          <span *ngIf="column!=='select'" mat-sort-header
            [matTooltip]="column==='approvedHours'?'Hours already approved on this date for the learner':''">
            {{this.sharedFunctions.unCamelCase(column)}}
          </span>

          <span *ngIf="column==='select'">
            <mat-checkbox [disabled]="this.tableDataSource.filteredData.length===0 || showSpinner"
              (change)="masterToggle($event)" [checked]="masterCheckbox">
            </mat-checkbox>
            {{this.tableDataSource.filteredData.length===0?"Select All On Page":isAllSelected() ? 'Deselect All On Page'
            :
            'Select All On Page'}}
          </span>
        </th>
        <td mat-cell *matCellDef="let cell" style="text-align: center;">
          <span *ngIf="column=='info'" matTooltip="{{cell[column]}}">
            <mat-icon>info</mat-icon>
          </span>
          <span *ngIf="column=='name'">
            <!--<a class="export-all" style="text-decoration: underline;"
              (click)="loadLearner(cell['traineeId'], cell['pot'])"> {{cell[column]}} </a>-->
            <a routerLink="/learner/dashboard/{{cell['traineeId']}}/{{cell['pot']}}">{{this.stringHelper.formatForEmpty(cell[column])}}
            </a>
          </span>
          <span
            *ngIf="column!='timeIn' && column!='timeOut' &&column!='name' &&column!='info' &&column!='select' &&column !='approvedHours' &&column!='edit'">
            {{sharedFunctions.isDateFromColumnName(cell[column],column) ? (cell[column] | date: this.dateFormat)
            :cell[column]}}
          </span>
          <span *ngIf="column=='approvedHours'">
            <!--<a class="export-all" style="text-decoration: underline;"
              (click)="openAttendance(cell['traineeId'], cell['pot'], cell['date'])"> {{cell[column]}} </a>-->
            <a routerLink="/learner/attendance/{{cell['traineeId']}}/{{cell['pot']}}"
              [queryParams]="{date:moment(cell['date']).startOf('isoWeek').format('YYYY-MM-DD')}">{{cell[column]}}
            </a>
          </span>
          <span *ngIf="column=='timeIn' || column=='timeOut'"
            class="{{timeStyle(cell[column], cell['centreStartTime'], cell['centreEndTime'])}}">
            {{asTime(cell[column])}}
          </span>
          <span *ngIf="column=='select'">
            <mat-checkbox [disabled]="showSpinner" (click)="$event.stopPropagation()"
              (change)="selectedItem($event,cell)" [checked]="this.selection.isSelected(cell)">
            </mat-checkbox>
          </span>
          <span *ngIf="column=='edit'">
            <mat-icon style="width:auto;height:auto; font-size: 20px;cursor: pointer;"
              (click)="editTime(cell)">edit</mat-icon>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="displayedColumns.length" style="text-align: center;"
          [attr.colspan]="this.displayedColumns.length">
          {{
          showSpinner ? 'Loading...':
          errorText ? errorText :'No Results'}}
        </td>
      </tr>
    </table>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
  <mat-paginator #tablePaginator [length]="totalData" [pageSizeOptions]="pageOptions" showFirstLastButtons="true">
  </mat-paginator>
  <button class="mat-raised-button mat-primary" mat-button (click)="approve()"
    [disabled]="this.fullSelection.length===0 || showSpinner">Approve
    {{this.fullSelection.length>0?this.fullSelection.length+'
    Rows':''}}</button>
  <button class="mat-raised-button mat-warn" style="margin-left: 10px;" mat-button (click)="reject()"
    [disabled]="this.fullSelection.length===0|| showSpinner">Reject
    {{this.fullSelection.length>0?this.fullSelection.length+'
    Rows':''}}</button>
</div>