<div style="width: 450px">

  <h2 class="title">
    <mat-icon *ngIf="this.type === 'warn'" color="warn" aria-hidden="false" aria-label="Warning icon" class="warning-icon">
      warning
    </mat-icon>
    {{ this.title }}
  </h2>

  <p class="message" [innerHTML]="this.message"></p>

  <div class="button-container">
    <button class="mat-raised-button" (click)="onCancelClick()">
      {{ this.cancelButtonText }}
    </button>
    <button class="mat-raised-button {{ this.type === 'warn' ? 'mat-warn' : 'mat-primary' }}" (click)="onConfirmClick()">
      {{ this.confirmButtonText }}
    </button>
  </div>
</div>
