import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class LearnerSearchResolverService implements Resolve<any> {


  constructor() { }

  resolve(route: ActivatedRouteSnapshot): any {
    return route.params['assessorName'];
  }
}
