<h1 mat-dialog-title>Add Note</h1>
<div mat-dialog-content>

    <form [formGroup]="commentsForm" novalidate>
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Leave a comment</mat-label>
            <textarea matInput formControlName="comment" rows="10"></textarea>
        </mat-form-field>
    </form>

</div>

<div mat-dialog-actions>
    <button mat-stroked-button color="primary"(click)="onNoClick()">Cancel</button>
    <button  mat-flat-button color="primary"(click)="onOK()" cdkFocusInitial >Save</button>
</div>