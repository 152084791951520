<cdk-virtual-scroll-viewport class="search-virtual-scroll" itemSize="{{ this.cardHeight }}"
  minBufferPx="{{ this.cardHeight * this.rowsPerPage * 2 }}" maxBufferPx="{{ this.cardHeight * this.rowsPerPage * 4 }}"
  (scroll)="onScroll()">
  <div class="search-list-container">
    <div *ngIf="this.totalCount !== 0; else message">
      <div *ngIf="!displayError && this.type===this.types.learner">
        <div *cdkVirtualFor="let row of this.rows; templateCacheSize: 20;" class="search-row"
          [style.width.px]="this.rowWidth">
          <app-learner-card *ngFor="let learner of this.row.row; trackBy: rowTrackBy"
            [learner]="learner"></app-learner-card>
        </div>
      </div>
      <div *ngIf="!displayError && this.type===this.types.employer">
        <div *cdkVirtualFor="let row of this.rows; templateCacheSize: 20;" class="search-row"
          [style.width.px]="this.rowWidth">
          <app-employer-card *ngFor="let employer of this.row.row; trackBy: rowTrackBy"
            [employer]="employer"></app-employer-card>
        </div>
      </div>
    </div>

    <ng-template #message>
      <div class="message-container">
        <h2 [style.padding-right.px]="this.sideNavOpen ? this.cardWidth : 0">{{ this.displayError ? this.dataError :
          this.totalCount === 0 ? this.noData : '' }}</h2>
      </div>
    </ng-template>
  </div>
</cdk-virtual-scroll-viewport>