<mat-card class="learners-card">

    <h2 class="dashboard-titles" style="padding-left:0px;">Assessors</h2>

    <div *ngFor="let assessor of obs | async" class="assessor-list">
        <div class="assessor-item">
            <a class="assessor-link" (click)="this.handleAssessorClick(assessor)">
              {{assessor.assessorName | titlecase}}
            </a>
            <div>
                {{assessor.sectorName}}
            </div>
        </div>
    </div>
    <mat-paginator [pageSize]="6" [pageSizeOptions]="[6, 15, 50]" showFirstLastButtons></mat-paginator>
</mat-card>
