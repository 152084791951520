import { Component } from '@angular/core';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { ReportVariant } from 'src/app/shared/enums/report-variant';
import { ISummaryGroupBy } from 'src/app/shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-suspensions-report',
  templateUrl: './suspensions-report.component.html'
})
export class SuspensionsReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.Suspensions;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'scheme', 'sector', 'centre', 'assessor', 'suspendedDate', 'weeksSuspended', 'suspensionReason', 'lastContact', 'returnDate', 'progressPercentage']
    }
  ];
  dateFilters = ['startDate', 'expectedCompletionDate', 'returnDate', 'lastContact', 'suspendedDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];
  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch (column) {
      case 'returnDate':
        if (row['overExpectedReturnDate'] === 'Yes') {
          style = { 'background-color': '#ffd1d1' };
        }
        break;
      case 'lastContact':
        if (row['hadContact'] === 'Yes') {
          style = { 'background-color': '#ffd1d1' };
        }
        break;
      case 'progressPercentage':
        if (row['fwStatus'] === 3) {
          style = { 'background-color': '#ffd1d1' };
        } else if (row['fwStatus'] === 2) {
          style = { 'background-color': '#ffd5ba' };
        } else {
          style = { 'background-color': '#c2f0c2' };
        }
        break;
    }
    return style;
  }
}
