import { Component, Input } from '@angular/core';
import { SearchLearner } from '../../../../shared/interfaces/search-learner';
import { DATE_FORMAT } from 'src/app/app.constants';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
    selector: 'app-learner-card',
    templateUrl: './learner-card.component.html',
    styleUrls: ['./learner-card.component.scss']
})

export class LearnerCardComponent {

    constructor(
        private datePipe: DatePipe,
        private router: Router,
        private route: ActivatedRoute,
        private sharedService: SharedService
    ) { }

    public dateFormat = DATE_FORMAT;

    @Input() learner: SearchLearner;

    public potStatusColour(): { color: string, backgroundColor: string, border: string } {

        switch (this.learner.potStatus) {
            // Active
            case 'Active':
                return { color: '#108c27', backgroundColor: '#ddffe4', border: '1px #108c27 solid' };
            // Paused
            case 'Suspended':
            case 'Limbo':
            case 'Pending Start':
            case 'Unconfirmed':
                return { color: '#ff8c00', backgroundColor: '#fff7e6', border: '1px #ff8c00 solid' };
            //  Posative Leaver
            case 'Achiever':
            case 'Provisional':
            case 'Positive':
            case 'Completion':
            case 'Partial-Comp':
                return { color: '#2d31cb', backgroundColor: '#d6d8ff', border: '1px #2d31cb solid' };
            // Negative Leaver
            case 'Early Leaver':
            case 'Negative':
            case 'Non-Comp':
                return { color: '#ff0000', backgroundColor: '#ffd6d6', border: '1px #ff0000 solid' };
            //Excluded / Others
            default:
                return { color: '#4e4e4e', backgroundColor: '#c5c5c5', border: '1px #4e4e4e solid' };
        }
    }

    public cardTooltipText(): string {
        const dob = this.datePipe.transform(this.learner.dateOfBirth, this.dateFormat);
        let text = this.learner.dateOfBirth !== null ? `DoB: ${dob}` : '';
        text += this.learner.uniqueLearnerNumber !== null ? `\nULN: ${this.learner.uniqueLearnerNumber}` : '';
        text += this.learner.subcontractor !== null ? `\nSubcontractor: ${this.learner.subcontractor}` : '';
        text += this.learner.programmeType !== null ? `\nProgramme Type: ${this.learner.programmeType}` : '';
        text += this.learner.centre !== null ? `\nCentre: ${this.learner.centre}` : '';

        return text;
    }

    public routeToLearner() {
        this.router.navigate(
            [`/learner/dashboard/${this.learner.traineeId}/${this.learner.pot}`],
            this.sharedService.getBackRouteState()
        );
    }

}
