import { IqaRecordFormService } from './learner-iqa/iqa-record-form/iqa-record-form.service';
import { LearnerDetailsTripleEntryComponent } from './learner-details/learner-details-triple-entry/learner-details-triple-entry.component';
import { LearnerDetailsSendMessageEntryComponent } from './learner-details/learner-details-send-message-entry/learner-details-send-message-entry.component';
import { MaterialsModule } from '../core/materials/materials.module';
import { LearnerTemplateResolverService } from './learner-template/learner-template-resolver.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LearnerDetailsComponent } from './learner-details/learner-details.component';
import { LearnerSummaryBarComponent } from './learner-template/learner-summary-bar/learner-summary-bar.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LearnerDetailsEntryComponent } from './learner-details/learner-details-entry/learner-details-entry.component';
import { LearnerDetailsAddressEntryComponent } from './learner-details/learner-details-address-entry/learner-details-address-entry.component';
import { LearnerDetailsDoubleEntryComponent } from './learner-details/learner-details-double-entry/learner-details-double-entry.component';
import { MainLearnerDetailsComponent } from './learner-details/main-learner-details/main-learner-details.component';
import { CurrentProgrammeComponent } from './learner-details/current-programme/current-programme.component';
import { CurrentEmploymentComponent } from './learner-details/current-employment/current-employment.component';
import { JobsGrowthWalesPlus } from './learner-details/jobs-growth-wales-plus/jobs-growth-wales-plus.component';
import { LearnerDashboardComponent } from './learner-dashboard/learner-dashboard.component';
import { LearnerRoutingModule } from './learner-routing.module';
import { LearnerProgressComponent } from './learner-progress/learner-progress.component';
import { LearnerAttendanceComponent } from './learner-attendance/attendance.component';
import { ProgressSummaryComponent } from './learner-dashboard/progress-summary/progress-summary.component';
import { ActivitiesComponent } from './learner-progress/activities/activities.component';
import { AwardsComponent } from './learner-progress/awards/awards.component';
import { ObjectivesComponent } from './learner-progress/objectives/objectives.component';
import { LearnerEventsComponent } from './learner-events/learner-events.component';
import { NgChartsModule } from 'ng2-charts';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { IqaRecordFormComponent } from './learner-iqa/iqa-record-form/iqa-record-form.component';
import { ReviewRecordFormComponent } from './learner-reviews/review-record-form/review-record-form.component';
import { LearnerEventFormComponent } from './learner-events/learner-event-form/learner-event-form.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PreviousLearningRecordComponent } from './learner-details/previous-learning-record/previous-learning-record.component'
import { ReportingModule } from '../reporting/reporting.module'
import { NgSelectModule } from '@ng-select/ng-select';
import { SubmitForIqaComponent } from './learner-iqa/submit-for-iqa/submit-for-iqa.component';
import { LearnerSearchResolverService } from '../learner-search/learner-search-resolver.service';
import { YesNoPipe } from '../core/pipes/yes-no.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LearnerIqaComponent } from './learner-iqa/learner-iqa.component';
import { LearnerWestComponent } from './learner-west/learner-west.component';
import { LearnerWestDetailsComponent } from './learner-west/learner-west-details/learner-west-details/learner-west-details.component';
import { LearnerReviewsComponent } from './learner-reviews/learner-reviews.component';
import { LearnerAttachmentsComponent } from './learner-attachments/learner-attachments.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { LearnerFileUploadComponent } from './learner-attachments/learner-file-upload/learner-file-upload.component';
import { LearnerKeyInfoComponent } from './learner-template/learner-key-info/learner-key-info.component';
import { LearnerPeopleComponent } from './learner-people/learner-people.component';
import { LearnerGlanceDetailsComponent } from './learner-details/learner-glance-details/learner-glance-details.component';
import { LearnerUniqueIdentificationComponent } from './learner-details/learner-unique-identification/learner-unique-identification.component';
import { LearnerCollapsibleSectionComponent } from './learner-details/learner-collapsible-section/learner-collapsible-section.component';
import { AssessorHistoryComponent } from './learner-people/assessor-history/assessor-history.component';
import { LearnerTemplateComponent } from './learner-template/learner-template.component';
import { MilestonesComponent } from './learner-dashboard/milestones/milestones.component';
import { LearnerDynamicDirective } from './learner-dynamic.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReportingModuleV2 } from '../reportingV2/reporting.module';
import { LearnerPageService } from './learner-page.service';
import { NgxGaugeModule } from 'ngx-gauge';
import { LearnerSignUpComponent } from './learner-sign-up/learner-sign-up.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        LearnerDetailsComponent,
        LearnerSummaryBarComponent,
        LearnerDetailsEntryComponent,
        LearnerDetailsAddressEntryComponent,
        LearnerDetailsDoubleEntryComponent,
        LearnerDetailsTripleEntryComponent,
        LearnerDetailsSendMessageEntryComponent,
        LearnerPeopleComponent,
        LearnerGlanceDetailsComponent,
        LearnerCollapsibleSectionComponent,
        MainLearnerDetailsComponent,
        CurrentProgrammeComponent,
        CurrentEmploymentComponent,
        JobsGrowthWalesPlus,
        LearnerDashboardComponent,
        LearnerProgressComponent,
        LearnerAttendanceComponent,
        ProgressSummaryComponent,
        ActivitiesComponent,
        AwardsComponent,
        ObjectivesComponent,
        LearnerEventsComponent,
        IqaRecordFormComponent,
        ReviewRecordFormComponent,
        LearnerEventFormComponent,
        PreviousLearningRecordComponent,
        SubmitForIqaComponent,
        YesNoPipe,
        LearnerIqaComponent,
        LearnerWestComponent,
        LearnerReviewsComponent,
        LearnerAttachmentsComponent,
        LearnerFileUploadComponent,
        LearnerKeyInfoComponent,
        LearnerWestDetailsComponent,
        LearnerUniqueIdentificationComponent,
        AssessorHistoryComponent,
        LearnerTemplateComponent,
        MilestonesComponent,
        LearnerDynamicDirective,
        LearnerSignUpComponent,
    ],
    imports: [
        CommonModule,
        MaterialsModule,
        RouterModule,
        FlexLayoutModule,
        LearnerRoutingModule,
        NgChartsModule,
        NgxGraphModule,
        NgxDaterangepickerMd.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReportingModule,
        ReportingModuleV2,
        NgSelectModule,
        MatAutocompleteModule,
        HighchartsChartModule,
        MatSlideToggleModule,
        NgxGaugeModule,
        SharedModule,
    ],
    providers: [
        IqaRecordFormService,
        LearnerSearchResolverService,
        LearnerTemplateResolverService,
        LearnerPageService,
    ]
})

export class LearnerModule { }
