<div class="home-bd-container" >
  
  <div class="widget-container" fxLayout="row wrap" fxLayoutGap="25px grid">
    <ng-template selectedWidgetHost></ng-template>
  </div>

  <div id="outer-widget-selector-div">
    <h4>Add Your Own Widget</h4>
    <span id="view-button" *ngIf="!showHideWidgetOptions" (click)="showWidgetOptions()">Click to view options</span>
    <span id="view-button" *ngIf="showHideWidgetOptions" (click)="showWidgetOptions()">Click to hide options</span>

    <div id="widget-selector-div" *ngIf="showHideWidgetOptions" style="display:flex;">

        <!-- groupBy="gender" -->
        <ng-select 
          class="widget-selector"
          [items]="allWidgets" 
          [multiple]="true" 
          bindLabel="widgetName" 
          [selectableGroup]="true"
          [selectableGroupAsModel]="true" 
          [closeOnSelect]="false" 
          bindValue="widgetId" 
          [(ngModel)]="widgetsSelected">
          <!-- <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="widgetId">
              <input id="item-{{index}}" type="checkbox" (click)="test(item.widgetId)" [ngModel]="item$.selected" [ngModelOptions]="{ standalone : true }"/> {{item.widgetName | uppercase}}
          </ng-template> -->
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.widgetName}}
          </ng-template>
        </ng-select>

        <div class="widget-buttons">
          <div class="default-widget-selection-button favourites-button">
            <button mat-flat-button color="secondary" (click)="loadDefaultWidgets()">Defaults</button>
          </div>
          
          <div class="save-widget-selection-button favourites-button">
            <button mat-flat-button color="primary" (click)="saveWidgetChanges()">Save</button>
          </div>
        </div>
          
    </div>
  </div>
</div>

<!-- 
  <div fxLayout="row wrap" fxLayoutGap="25px grid" >

    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <div class="widget-container-bottom-margin">
          <app-your-reports style="width:100%" [favourites]='myFavouriteReports'></app-your-reports>
      </div>
      <div>
          <app-your-employers #yourEmployersWidget></app-your-employers>
      </div>
    </div>

    <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
      <div>
          <app-your-events [staffId]="staffId" [currentDashboard]="currentDashboard"></app-your-events>
      </div>
    </div>

  </div>

</div> -->
