
<div class="mat-card-container">
  <mat-card class="dashboard-card">
    <div style="display: flex; justify-content: flex-end; gap: 8px; position: absolute; right: 0; padding-right: 25px;">
      <button type="button" class="icon-buttons" (click)="toggleEdit()" *ngIf="editPermissionLevel < 2" [attr.aria-label]="editMode ? 'Save' : 'Edit' ">
        <mat-icon class="glance-icon" >{{editOrSave}}</mat-icon>
      </button>
      <button type="button" class="icon-buttons" (click)="cancelEdit()" *ngIf="editMode" aria-label="Cancel">
        <mat-icon class="glance-icon">highlight_off</mat-icon>
      </button>
    </div>

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="previousLearner.icon" [alt]="previousLearner.alt">Past ACT Learner</h3>
      <mat-slide-toggle [checked]="previousLearner.savedState" *ngIf="editMode && this.canEditSignup" (change)="previousLearner.change($event)" ></mat-slide-toggle>
    </div>

        <!-- This was editable in the main learner details but the api never looked for this value and it is just set to whatever Past act learner is
        Seems to be only one collum in the db, acthisorical, and they both just get values from that but only past act learner updates it  -->
        <!-- Removed for now as I dont think it is a thing -->
    <!-- <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="previousWork.icon" [alt]="previousWork.alt" />Past ACT Work</h3> -->
      <!-- <mat-slide-toggle [checked]="previousWork.savedState" *ngIf="editMode" (change)="previousWork.change($event)"></mat-slide-toggle> -->
    <!-- </div> -->

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="previousWest.icon" [alt]="previousWest.alt" />Past WEST Test</h3>
      <mat-slide-toggle [checked]="previousWest.savedState" *ngIf="editMode && this.canEditSignup" (change)="previousWest.change($event)" ></mat-slide-toggle>
    </div>

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="otherTraining.icon" [alt]="otherTraining.alt" />Other Training</h3>
      <mat-slide-toggle [checked]="otherTraining.savedState" *ngIf="editMode && this.canEditSignup" (change)="otherTraining.change($event)"></mat-slide-toggle>
    </div>

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="additionalSupport.icon" [alt]="additionalSupport.alt" />Additional Support Requirements</h3>
    </div>

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="englishFirstLanguage.icon" [alt]="englishFirstLanguage.alt" />English first language</h3>
      <mat-slide-toggle [checked]="englishFirstLanguage.savedState" *ngIf="editMode && this.canEditSignup" (change)="englishFirstLanguage.change($event)" color="#483B8D"></mat-slide-toggle>
    </div>

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="welshSpeaking.icon" [alt]="welshSpeaking.alt" />Welsh first language</h3>
      <!-- Taking this out as in the backend its looking at first langue and shouldnt be edited in this way as it can be lots of other langues -->
      <!-- <mat-slide-toggle [checked]="welshSpeaking.savedState" *ngIf="editMode && this.canEditSignup" (change)="welshSpeaking.change($event)"></mat-slide-toggle> -->
    </div>

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="westInWelsh.icon" [alt]="westInWelsh.alt" />WEST in Welsh</h3>
      <mat-slide-toggle [checked]="westInWelsh.savedState" *ngIf="editMode && this.canEditSignup" (change)="westInWelsh.change($event)"></mat-slide-toggle>
    </div>

    <div class="card-detail-row" fxLayout="row" fxLayoutAlign="default center">
      <h3><img [src]="laptopLoaned.icon" [alt]="laptopLoaned.alt" />Laptop Loaned</h3>
      <mat-slide-toggle [checked]="laptopLoaned.savedState" *ngIf="editMode" (change)="laptopLoaned.change($event)"></mat-slide-toggle>
    </div>

  </mat-card>
</div>
