import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as _ from 'underscore';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss']
})
export class ReportCardComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  constructor(private changeDetectorRef:ChangeDetectorRef) { }

  dataSource: any = [];
  updateFlag = false;
  highcharts = Highcharts;
  chartOptions = {
      chart: {
         height: 500
      },
     title : {
        text: 'Overall Progress'
     },
     tooltip: {
      formatter: function() {
          return '<b>' + this.point.name + '</b>' + ' has completed ' + this.y.toFixed(0) + '%. The target is ' + this.x.toFixed(0) + '%'
      }
  }, plotOptions:{
   series:{
       turboThreshold:5000//larger threshold or set to 0 to disable
   }
},
     xAxis : {
      min: 0,
      max: 100,
      title: {text:'Target Progress'}
   },
   yAxis : {
      min: 0,
      max: 100,
      title: {text:'Actual Progress'}
   },
     series : [
       {
        type: 'scatter',
        zoomType:'xy',
        name: 'Learner Progress',
        data: this.dataSource
     },{
      type: 'line',
      name: 'On Track',
      data: [[0, 0], [100, 100]],
      marker: {
         enabled: false
      },
      states: {
         hover: {
            lineWidth: 0
         }
      },
      enableMouseTracking: false
      }]
  };

  ngOnInit(): void {
  }

  setData(data){
    for (let obj in data) {
      let subset = _.pick(data[obj], ['frameworkOverallActual','frameworkOverallTarget','fullName']);
      subset['y'] = (subset['frameworkOverallActual'] * 100)
      subset['x'] = (subset['frameworkOverallTarget'] * 100)
      subset['name'] = subset['fullName']
      this.chartOptions.series[0].data.push(subset)
    };
    this.updateFlag = true;
    this.changeDetectorRef.detectChanges();
   }



}
