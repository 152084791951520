import { MaterialsModule } from './../core/materials/materials.module';
import { LogOutComponent } from './log-out.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    LogOutComponent
  ],
  imports: [
    CommonModule,
    MaterialsModule
  ]
})
export class LogOutModule { }
