import { P } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { NotificationsService } from '../notifications.service';

export interface Section {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  folders: Section[] = [
    {
      name: 'Private applications report shared with you',
      updated: new Date('1/1/16'),
    },
    {
      name: 'Review completed',
      updated: new Date('1/17/16'),
    },
    {
      name: 'New learner: Tom Edwards',
      updated: new Date('1/28/16'),
    }
  ];
  notifications;
  countUnread;

  constructor( private cdr: ChangeDetectorRef, private notificationsService: NotificationsService, private userService: UserService) {
    this.notificationsService.getNotifications().subscribe(response => {
      this.notifications = response.notifications;
      this.countUnread = response.countUnread;
      this.cdr.detectChanges();
      console.log(this.countUnread)
      if(this.countUnread > 0){
        this.notificationsService.markNotificationsAsRead().subscribe(response => {
            this.userService.resetUserNotifications();
        })
      }
      console.log('notifications', this.notifications)
    })
  }

  ngOnInit(): void {
  }

}
