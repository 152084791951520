import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { InfoDialog } from "src/app/shared/interfaces/info-dialog";
import { InfoDialogComponent } from "./info-dialog.component";

export class DialogIcons {
  static readonly SUCCESS = 'check_circle';
  static readonly ERROR = 'error';
  static readonly INFO = 'info';
  static readonly WARNING = 'warning';
}

@Injectable({
  providedIn: 'root'
})
export class InfoDialogHelper {
  public savingDialogConfig(title: string, subTitle = ''): MatDialogConfig<InfoDialog> {
    const config: MatDialogConfig<InfoDialog> = {
      minWidth: '25%',
      maxHeight: '85%',
      disableClose: true,
      data: {
        title: title,
        subTitle: subTitle,
        showLoading: true,
        showError: false,
        showInfo: false,
        showWarning: false,
        showButtons: false
      }
    };
    return config;
  }
  public savedDialogConfig(title: string, subTitle = ''): MatDialogConfig<InfoDialog> {
    const config: MatDialogConfig<InfoDialog> = {
      minWidth: '25%',
      maxHeight: '85%',
      disableClose: true,
      data: {
        icon: DialogIcons.SUCCESS,
        title: title,
        subTitle: subTitle,
        showLoading: false,
        showError: false,
        showInfo: true,
        showWarning: false,
        showButtons: true
      }
    };
    return config;
  }
  public errorDialogConfig(title: string, subTitle = ''): MatDialogConfig<InfoDialog> {
    const config: MatDialogConfig<InfoDialog> = {
      minWidth: '25%',
      maxHeight: '85%',
      disableClose: true,
      data: {
        icon: DialogIcons.ERROR,
        title: title,
        subTitle: subTitle,
        showLoading: false,
        showError: true,
        showInfo: false,
        showWarning: false,
        showButtons: true,
      }
    };
    return config;
  }
  public confirmDialogConfig(title: string, subTitle = ''): MatDialogConfig<InfoDialog> {
    const config: MatDialogConfig<InfoDialog> = {
      minWidth: '25%',
      maxHeight: '85%',
      disableClose: true,
      data: {
        icon: DialogIcons.WARNING,
        title: title,
        subTitle: subTitle,
        showLoading: false,
        showError: false,
        showInfo: false,
        showWarning: true,
        showButtons: true
      }
    };
    return config;
  }
  public informationDialogConfig(title: string, subTitle = ''): MatDialogConfig<InfoDialog> {
    const config: MatDialogConfig<InfoDialog> = {
      minWidth: '25%',
      maxHeight: '85%',
      disableClose: true,
      data: {
        icon: DialogIcons.INFO,
        title: title,
        subTitle: subTitle,
        showLoading: false,
        showError: false,
        showInfo: false,
        showWarning: true,
        showButtons: true
      }
    };
    return config;
  }
  public successDialog<T>(savingDialog: MatDialogRef<InfoDialogComponent>,
    savingDialogSuccess: MatDialogConfig<InfoDialog>,
    dialog: MatDialog, dialogRef: MatDialogRef<T>): void {
    savingDialog.close();
    dialog.open(InfoDialogComponent, savingDialogSuccess);
    dialogRef.close();
  }
  public errorDialog(savingDialog: MatDialogRef<InfoDialogComponent>,
    savingDialogError: MatDialogConfig<InfoDialog>,
    dialog: MatDialog): void {
    savingDialog.close();
    dialog.open(InfoDialogComponent, savingDialogError);
  }
  public infoDialog(infoDialog: MatDialogConfig<InfoDialog>, dialog: MatDialog): void {
    dialog.open(InfoDialogComponent, infoDialog);
  }
}
