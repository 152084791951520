import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'learner-details-address-entry',
  templateUrl: './learner-details-address-entry.component.html',
  styleUrls: ['./learner-details-address-entry.component.css']
})
export class LearnerDetailsAddressEntryComponent implements OnInit {
  @Input() type: string;
  @Input() addressLineOne: string;
  @Input() addressLineTwo: string;
  @Input() addressLineThree: string;
  @Input() addressLineFour: string;
  @Input() phone: string;
  @Input() mobile: string;
  @Input() email1: string;
  @Input() email2: string;
  @Input() emergencyContact: string;
  @Input() RelationshipToLearner: string;
  @Input() EmergenceyContactNumber: string;

  constructor() { }

  ngOnInit(): void {

    // Removes any extra spaces or commas from the start or end of the string
    const regex = /^[\s,]+|[\s,]+$/g;
    this.addressLineOne = this.addressLineOne ? this.addressLineOne.replace(regex, '') : null;
    this.addressLineTwo = this.addressLineTwo ? this.addressLineTwo.replace(regex, '') : null;
    this.addressLineThree = this.addressLineThree ? this.addressLineThree.replace(regex, '')  : null;
    this.addressLineFour = this.addressLineFour ? this.addressLineFour.replace(regex, '')  : null;
  }

}
