import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { LearnerDynamicDirective } from '../learner-dynamic.directive';
import { LearnerPageComponent } from '../learner-page.component';
import { LearnerPageService } from '../learner-page.service';
import { LearnerService } from '../learnerService.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-learner-template',
  templateUrl: './learner-template.component.html',
  styleUrls: ['./learner-template.component.scss']
})
export class LearnerTemplateComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private learnerPageService: LearnerPageService,
    private router: Router,
    private learnerService: LearnerService,
    private location: Location
  ) {
    const currentNav = this.router.getCurrentNavigation();
    this.backUrl = currentNav?.extras.state?.back.url || undefined;
    this.backParams = currentNav?.extras.state?.back.params || undefined;
  }


  tabName: string;
  traineeId: string;
  pot: number;
  staffId: number;

  templateInfoLoaded = false;
  isFavorite = false;
  favouriteLearnerId: number;
  waitingForFavoriteToggle = false;
  templateInfo: any;
  showAttendance = false;

  learnerPages: object;

  backUrl: string;
  backParams: any;

  @ViewChild(LearnerDynamicDirective, { static: true }) learnerHost!: LearnerDynamicDirective;

  ngOnInit(): void {
    this.tabName = this.route.snapshot.params.tabName;
    this.traineeId = this.route.snapshot.params.traineeId;
    this.pot = this.route.snapshot.params.potId;
    this.templateInfo = this.route.snapshot.data.learner.templateInfo;
    this.staffId = this.route.snapshot.data.learner.staffId;
    this.showAttendance = ['Traineeship', 'JGW+'].includes(this.templateInfo.programme);
    this.learnerPages = this.learnerPageService.getLearnerPages();

    this.checkFavorite();

    this.templateInfoLoaded = true;

    this.loadPage(this.tabName, true);

    this.location.subscribe(event => {
      const tabName = event.url.split('/')[2];
      if (tabName !== this.tabName) {
        this.tabName = tabName;
        this.loadPage(this.tabName, true);
        this.cdr.detectChanges();
      }
    });
  }

  changePot() {
    this.templateInfoLoaded = false;
    const subscription = this.learnerService.getLearnerTemplateInfo(this.traineeId, this.pot, this.staffId).subscribe({
      next: (response) => {
        this.templateInfo = response;
        this.showAttendance = ['Traineeship', 'JGW+'].includes(this.templateInfo.programme);
      },
      error: (errorResponse) => {
        this.router.navigate(['/404'], { skipLocationChange: true })
          .then(() => console.log('Error Message:', errorResponse.error));
        subscription.unsubscribe();
      },
      complete: () => {
        this.loadPage(this.tabName);
        this.templateInfoLoaded = true;
        subscription.unsubscribe();
        this.cdr.detectChanges();
      }
    });
  }

  loadPage(pageName: string, skipHistory = false) {
    if (this.learnerPages[pageName] === undefined) {
      this.router.navigate(['/404'], { skipLocationChange: true })
        .then(() => console.log('Error Message: Tab not recognised'));
      return;
    }

    this.tabName = pageName;
    if (!skipHistory) {
      window.history.pushState({}, "", `learner/${this.learnerPages[pageName].tabName}/${this.traineeId}/${this.pot}`);
    }

    console.log(this.learnerHost);
    const viewContainerRef = this.learnerHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<LearnerPageComponent>(this.learnerPages[pageName].component);
    componentRef.instance.traineeId = this.traineeId;
    componentRef.instance.pot = this.pot;
    componentRef.instance.staffId = this.staffId;
    componentRef.instance.getData();
  }

  async checkFavorite() {
    this.userService.getFavouriteLearnersFromLocalDb().then(result => {
      const favouriteLearnerDetails = result.filter(element => element.traineeId === this.traineeId);
      this.isFavorite = favouriteLearnerDetails.length > 0;
      if (this.isFavorite) {
        this.favouriteLearnerId = favouriteLearnerDetails[0].id;
      }
    });
  }

  toggleFavorite(event: boolean) {
    this.waitingForFavoriteToggle = true;
    if (event == true) {
      this.addLearnerToFavorite();
    } else if (event === false) {
      this.removeLearnerFromFavorite();
    }
  }

  addLearnerToFavorite() {
    const subscription = this.userService.addFavouriteLearner(this.staffId, this.traineeId).subscribe({
      next: (result: number) => {
        this.isFavorite = true;
        this.favouriteLearnerId = result;
        this.snackbar.open('Learner added to bookmarked learners', 'Close', {
          duration: 3000
        });
      },
      error: err => {
        this.snackbar.open('Error adding learner to bookmarked learners', 'Close', {
          duration: 3000
        });
      },
      complete: () => {
        this.toggleCleanUp(subscription);
      }
    });
  }

  removeLearnerFromFavorite() {
    const subscription = this.userService.removeFavouriteLearner(this.staffId, this.favouriteLearnerId).subscribe({
      next: () => {
        this.isFavorite = false;
        this.snackbar.open('Learner removed from bookmarked learners', 'Close', {
          duration: 3000
        });
      },
      error: (err) => {
        this.snackbar.open('Error removing learner from bookmarked learners', 'Close', {
          duration: 3000
        });
      },
      complete: () => {
        this.toggleCleanUp(subscription);
      }
    });
  }

  toggleCleanUp(sub: Subscription) {
    sub.unsubscribe();
    this.waitingForFavoriteToggle = false;
    this.cdr.detectChanges();
  }

}
