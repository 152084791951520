import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsV2Service } from '../reports.service';

@Component({
  selector: 'app-show-saved-report-filters-dialog',
  templateUrl: './show-saved-report-filters-dialog.component.html',
  styleUrls: ['./show-saved-report-filters-dialog.component.scss']
})
export class ShowSavedReportFiltersDialogComponentV2 implements OnInit {
  staff;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private reportsService: ReportsV2Service,
    private dialogRef: MatDialogRef<ShowSavedReportFiltersDialogComponentV2>,
  ) { }

  ngOnInit(): void {
    this.staff = this.reportsService.getStaff();
  }

  close(): void {
    this.dialogRef.close();
  }

  loadStoredFilter(stored) {
    var data = { 'type': "stored", 'filter': stored };
    this.dialogRef.close(data);
  }
}