import { IReportDetail } from './../shared/interfaces/report-detail';
import { SharedFunctionsService } from '../core/services/shared-functions.service';
import { Router } from '@angular/router';
import { UserTableService } from './../core/database/user-table.service';
import { UserService } from 'src/app/core/services/user.service';
import { ReportListService } from './../report-list/report-list.service';
import { ReportVariant } from './../shared/enums/report-variant';
import { FavouriteTableService } from './../core/database/favourite-table.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as _ from 'underscore';
import { GoogleAnalyticsService } from '../core/services/google-analytics.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DATE_FORMAT } from 'src/app/app.constants';
import { ReportListWithFilters } from '../shared/interfaces/report-list-with-filters';

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  @ViewChild('txtVal') txtVal;
  constructor(
    private reportListService: ReportListService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private favouriteTableService: FavouriteTableService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private userTableService: UserTableService,
    private router: Router,
    private sharedFunctions: SharedFunctionsService,
    private snackBar: MatSnackBar
  ) { }

  reportsList: IReportDetail[] = [];
  reportsList_AF = [];
  reportsList_GM = [];
  reportsList_NQ = [];
  reportsList_RZ = [];
  favourites: { reports: IReportDetail[], filters: IReportDetail[], all: IReportDetail[] } = { reports: [], filters: [], all: [] };
  filterApplied = false;
  txtFilter: string;
  staffId;

  dateFormat = DATE_FORMAT;

  ngOnInit(): void {
    this.getCurrentUser();
    //console.log("getting report list")


  }

  getCurrentUser() {
    this.userTableService.get(1)
      .then(staffResponse => {
        if (!staffResponse || staffResponse == undefined || staffResponse == null) {
          this.router.navigate(['']);
        } else {
          this.staffId = staffResponse.staffId
          this.populateReportsList();
          //console.log('learner-search getCurrentUser', result.staffId + ': ' + result.userName);
          this.userService.getNotifications(staffResponse.staffId).subscribe(
            notificationsResponse => {
              staffResponse.countUnreadNotifications = notificationsResponse['countUnreadNotifications'];
              this.userService.updateUserNotifications(staffResponse);
              this.cdr.detectChanges();
            })
        }
      });
  }

  populateReportsList() {
    this.reportListService.getReportList(this.staffId)
      .subscribe((report: ReportListWithFilters) => {
        report.reports.forEach(element => {
          this.reportsList.push({
            type: 'report',
            id: element.reportId,
            title: element.title,
            link: `/reports/${element.titleForLink.replace("+", "")}`,
            tags: element.tags,
            filter: "",//element.filter is not included in the reports but needs to be on the interface!
            name: "",//element.name is not included in the reports but needs to be on the interface!
            reportId: element.reportId
          });
        });
        const favourites = report['selectedReportIds'];
        const favouritesWithFilters = report['selectedReportFilters'];
        this.favouriteTableService.clear();


        favourites.forEach(favouriteReport => {
          const reportDetails = report.reports.find(rpt => rpt.reportId == favouriteReport.reportId);
          if (reportDetails) {
            this.favouriteTableService.add({
              type: 'report',
              id: favouriteReport.id,
              title: this.sharedFunctions.unCamelCase(reportDetails['title']),
              link: `/reports/${reportDetails.titleForLink.replace("+", "")}`,
              tags: reportDetails.tags,
              filter: '',
              name: '',
              reportId: favouriteReport.reportId,
            }, `report:${favouriteReport.id}`);
          }
        });

        favouritesWithFilters.forEach(reportFilter => {
          const reportDetails = report.reports.find(rpt => rpt.reportId == reportFilter.reportId);
          this.favouriteTableService.add({
            type: 'filter',
            id: reportFilter.filterId,
            title: this.sharedFunctions.unCamelCase(ReportVariant[reportFilter.reportId]),
            link: `/reports/${reportDetails.titleForLink.replace("+", "")}/${reportFilter.filterId}`,
            tags: reportDetails.tags,
            filter: reportFilter.filter,
            name: reportFilter.name,
            reportId: reportFilter.reportId,
            createdDate: reportFilter.createdDate,
          }, `filter:${reportFilter.filterId}`)
        });

        this.reportsList_AF = _.sortBy(this.reportsList.filter(rpt =>
          rpt.title.toUpperCase().startsWith("A") ||
          rpt.title.toUpperCase().startsWith("B") ||
          rpt.title.toUpperCase().startsWith("C") ||
          rpt.title.toUpperCase().startsWith("D") ||
          rpt.title.toUpperCase().startsWith("E") ||
          rpt.title.toUpperCase().startsWith("F")
        ), 'title');
        this.reportsList_GM = _.sortBy(this.reportsList.filter(rpt =>
          rpt.title.toUpperCase().startsWith("G") ||
          rpt.title.toUpperCase().startsWith("H") ||
          rpt.title.toUpperCase().startsWith("I") ||
          rpt.title.toUpperCase().startsWith("J") ||
          rpt.title.toUpperCase().startsWith("K") ||
          rpt.title.toUpperCase().startsWith("L") ||
          rpt.title.toUpperCase().startsWith("M")
        ), 'title');
        this.reportsList_NQ = _.sortBy(this.reportsList.filter(rpt =>
          rpt.title.toUpperCase().startsWith("N") ||
          rpt.title.toUpperCase().startsWith("O") ||
          rpt.title.toUpperCase().startsWith("P") ||
          rpt.title.toUpperCase().startsWith("Q")
        ), 'title');
        this.reportsList_RZ = _.sortBy(this.reportsList.filter(rpt =>
          rpt.title.toUpperCase().startsWith("R") ||
          rpt.title.toUpperCase().startsWith("S") ||
          rpt.title.toUpperCase().startsWith("T") ||
          rpt.title.toUpperCase().startsWith("U") ||
          rpt.title.toUpperCase().startsWith("V") ||
          rpt.title.toUpperCase().startsWith("W") ||
          rpt.title.toUpperCase().startsWith("X") ||
          rpt.title.toUpperCase().startsWith("Y") ||
          rpt.title.toUpperCase().startsWith("Z")
        ), 'title');
        this.getFavouritesFromLocalDb(); // TODO: this could be sped up \ tidied. by not going back to the local db as we have them here already.

        this.cdr.detectChanges();
      });
  }

  getFavouritesFromLocalDb() {
    this.favouriteTableService
      .getAll()
      .then(response => {
        this.favourites.all = [...response];
        response.forEach(fav => {
          if (fav.type == 'report') {
            this.favourites.reports.push(fav);
          } else if (fav.type == 'filter') {
            this.favourites.filters.push(fav);
          }
        });
        this.cdr.detectChanges();
      })
  }

  applyFilter(val: string) {
    this
      .googleAnalyticsService
      .eventEmitter("Searching reports", val, "search", "click", this.staffId);


    this.filterApplied = true;
    this.txtFilter = val;
  }

  filteredReports() {
    const reportsFilteredOnTitle = this.reportsList.filter(r => r.title.toUpperCase().includes(this.txtFilter.toUpperCase()));
    const reportsFilteredOnTag = this.reportsList.filter(r => r.tags.toUpperCase().includes(this.txtFilter.toUpperCase()));
    const favouriteFilters = this.favourites.all.filter(r => r.name != null && (r.name.toUpperCase().includes(this.txtFilter.toUpperCase()) || r.title.toUpperCase().includes(this.txtFilter.toUpperCase()) || r.tags.toUpperCase().includes(this.txtFilter.toUpperCase())));
    const filteredReports = reportsFilteredOnTitle.concat(reportsFilteredOnTag).concat(favouriteFilters);
    return filteredReports;
  }

  clear() {
    this.filterApplied = false;
    // console.log(localStorage.getItem('favourite'));
    this.txtVal.nativeElement.value = "";
  }

  /*
    NOT IN USE!
  getAllReports() {
    for (var reportVariant in ReportVariant) {
      let reportValue = parseInt(reportVariant, 10);
      if (reportValue > 0) {
        //console.log(`enum member: ${reportValue} - `, ReportVariant[reportValue]);
      }
    }
  }*/

  removeFavourite(event) {
    const data = { "type": event.type, "Id": event.id, "staffId": this.staffId };
    this.userService.removeFavourite(data).subscribe(response => {
      if (response) {
        this.favourites = { all: [], reports: [], filters: [] };
        this.reportsList = [];
        this.populateReportsList();
        this.snackBar.open("Favourite report removed successfully.",
          'OK', { duration: 2000 });
      }
    });
  }
}
