import { ChangeDetectorRef, Component, HostBinding, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { UserService } from 'src/app/core/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-favourite-learners',
  templateUrl: './favourite-learners.component.html',
  styleUrls: ['./favourite-learners.component.scss'],
})
export class FavouriteLearnersComponent {

  @HostBinding('class') class = 'active-widget';
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  dataSource: any;
  rawData: any;
  staffId: number;
  waiting = true;
  displayedColumns = ['name', 'actions'];

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private sharedFunctions: SharedFunctionsService,
    private snackbar: MatSnackBar,
  ) { }

  initialApiCall(staffId: number) {
    this.staffId = staffId;
    this.userService.getFavouriteLearnersFromLocalDb().then(
      result => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.waiting = false;
      });
  }

  removeFavourite(id) {
    if (!this.waiting) {
      this.waiting = true;
      const subscription = this.userService.removeFavouriteLearner(this.staffId, id).subscribe({
        next: () => {
          this.dataSource.data = this.dataSource.data.filter(row => row['id'] !== id);
          this.snackbar.open('Learner removed from bookmarked learners', 'Close', { duration: 3000 });
        },
        error: error => {
          this.snackbar.open('Error removing learner from bookmarked learners', 'Close', { duration: 3000 });
          console.error(error);
        },
        complete: () => {
          subscription.unsubscribe();
          this.waiting = false;
        }
      });
    }
  }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }


}
