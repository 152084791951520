
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ReportListService } from './report-list.service';

@Injectable()
export class ReportsResolversService implements Resolve<any> {
  constructor(private reportListService: ReportListService) { }
  resolve(route: ActivatedRouteSnapshot) {
    console.log('return fav filter from db', route.params['reportId']);
    return this.reportListService.getReportFilter(route.params['reportFilterId']);
  }
}
