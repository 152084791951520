<mat-card class="dfp-card">
  <div class="dfp-container">
    <div style="margin-top: 15px;">
      <h2 class="heading" style="margin-right: 5px">LLWR Funding Predictions</h2>
    </div>
    <h3 class="subheading">These figures are not fact and are predicted on current data - actual LLWR payment may differ</h3>
    <h3 class="subheading subheading-flex">
      <span *ngIf="this.subcontractor" >{{this.subcontractor}}</span>
      <span *ngIf="this.programmeType" class="subheading-flex">
        <img src="../../../assets/arrows/right-arrow.svg" class="breadcrumb-chevron"/>
        <span> {{this.programmeType}}</span>
      </span>
      <span *ngIf="this.scheme" class="subheading-flex">
        <img src="../../../assets/arrows/right-arrow.svg" class="breadcrumb-chevron"/>
        <span> {{this.scheme}}</span>
      </span>
    </h3>
    <div *ngIf="this.dataLoaded && !this.dataError; else loadingOrError">
      <table class="widget-your-employers-table"
        mat-table
        matSort
        [hidden]="!dataSource || !groupedBySubcontractor"
        [dataSource]="dataSource"
      >
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{this.headerToolTip(column)}}"
          matTooltipPosition="above">
            {{unCamelCase(column)}}
          </th>
          <div *ngIf="column === 'subcontractor' || column === 'programmeType' || column === 'scheme'; else default">
              <td mat-cell *matCellDef="let element"
                  (click)="this.changeState(this.direction.down, element[column])"
                  class="drill-down-cell"
              >
                {{ element[column] ?? 'Unknown'}}
              </td>
          </div>

          <ng-template #default>
            <ng-container *ngIf="column !== 'traineeId'; else traineeId">
              <td mat-cell *matCellDef="let element"
              [style]="element[column] < 0 ? 'color: #d84d4d' : ''"
              >
                {{ isDate(element[column], column) ? (element[column] | date: this.dateFormat) :
                column === 'previousTotalPayment' || column === 'predictedTotalPayment' || column === 'predictedNextMonthlyPayment' ?
                (element[column] | currency: '£' : 'symbol-narrow') :
                element[column] ?? '-'}}
              </td>
            </ng-container>
          </ng-template>

          <ng-template #traineeId>
            <td mat-cell *matCellDef="let element">
              <a class="trainee-link" href="/learner/attendance/{{element['traineeId']}}/{{element['pot']}}">
                {{element[column]}}
              </a>
            </td>
          </ng-template>

          <td mat-footer-cell *matFooterCellDef>
            {{ column === 'previousTotalPayment' || column === 'predictedTotalPayment' || column === 'predictedNextMonthlyPayment' ?
              ( getSumForColumn(column) | currency: '£' : 'symbol-narrow' ) : getSumForColumn(column) ?? '-'}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <ng-template #loadingOrError>
      <mat-progress-bar *ngIf="!this.dataError; else error" mode="indeterminate"></mat-progress-bar>
    </ng-template>

    <ng-template #error>
      <div style="padding: 10px; text-align: center;">
        <p>There was an error loading the data. Please refresh and try again or contact support if this issue continues.</p>
      </div>
    </ng-template>

    <div style="display: flex; align-items: center;">
      <mat-icon *ngIf="this.currentState !== this.state.Subcontractor" (click)="this.changeState(this.direction.up)" style="height: auto; cursor: pointer;">keyboard_backspace</mat-icon>
      <div style="flex-grow: 1;"></div>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>

  </div>
</mat-card>
