<div style="position: relative; min-height: 240px;">
  <h2 class="dashboard-titles">Attendance Import</h2>

    <div *ngIf="this.filesToUpload === null; else spinner" class="file-drop-container" appDnd (fileDropped)="fileHandler($event)">
      <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileHandler($event.target.files)" />
      <div class="file-drop-information">
        <img style="float: left;" src="../../../../assets/images/icon-documents.png">
        <div>
          <h4>Drag file here to upload</h4>
          <label class="link" for="fileDropRef">or click to browse your files</label>
        </div>
      </div>
    </div>
    <ng-template #spinner>
      <mat-spinner style="left: calc(50% - 50px);"></mat-spinner>
    </ng-template>

    <mat-dialog-actions style="position: absolute; right: 0; bottom: 0;">
        <button mat-button (click)="closeForm()" mat-flat-button color="primary">Close</button>
    </mat-dialog-actions>
</div>
