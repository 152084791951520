<div class="absence-analysis-details">
  <div style="  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;">

    <mat-form-field style="width: 49%;" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input #txtVal matInput placeholder="Start typing to filter results" (keyup)="applyTextFilter(txtVal.value)"
        autocomplete="off" [value]="this.rawTextFilterString">
    </mat-form-field>

    <mat-form-field style=" width: 49%;" appearance="outline">
      <input style="cursor: pointer;" matInput type="text" #dateRange ngxDaterangepickerMd
        placeholder="Select Date Range" [(ngModel)]="dateRangeSelected" (datesUpdated)="dateRangeChange($event)"
        [ranges]="ranges" [showClearButton]="true" [readonly]="true"
        [locale]="{applyLabel: 'Apply',clearLabel: 'Clear',format: 'DD/MM/YYYY'}" startKey="startDate" endKey="endDate"
        autocomplete="off" startKey="startDate" [alwaysShowCalendars]=true endKey="endDate" [showDropdowns]="true">
    </mat-form-field>
  </div>
  <div style="  padding: 0;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;">
    <ng-select style="width:100%;" [multiple]="false" appearance="outline" (change)="schemeChange(selectedScheme)"
      class="filter-select" #select [items]="schemeOptions" bindLabel="scheme" placeholder="Filter by Scheme"
      [searchable]="false" [(ngModel)]="selectedScheme" (clear)="schemeSelectCleared()">
    </ng-select>
  </div>
  <h3 class="subheading subheading-flex">
    <!--<span *ngIf="level===0">Subcontractors</span>-->
    <a *ngIf="level>0" [routerLink]="[]" (click)="resetDrillDown()">Subcontractor: {{subContractorName}}</a>
    <span *ngIf="level>=1" class="subheading-flex">
      <img src="../../../assets/arrows/right-arrow.svg" class="breadcrumb-chevron" />
      <span *ngIf="level===1">Centres</span>
      <a *ngIf="level>1" [routerLink]="[]" (click)="goToCentre()"> Centre: {{centreName}}</a>
    </span>
    <span *ngIf="level===2" class="subheading-flex">
      <img src="../../../assets/arrows/right-arrow.svg" class="breadcrumb-chevron" />
      <span> Learners</span>
    </span>
  </h3>
  <div class="analysisTable" style="display: block; max-height: 550px; overflow: auto;">
    <table mat-table [dataSource]="this.tableDataSource" #tableSort="matSort" matSort>
      <ng-container [matColumnDef]="column" *ngFor="let column of tableDisplayColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky>
          {{this.sharedFunctions.unCamelCase(column)}} </th>
        <td mat-cell *matCellDef="let cell"
          [ngStyle]="{'background': column == 'learnPercentage' ? indicatorColour(cell[column]) : '#fff', 'text-align': column!=='subcontractor'&& column!=='centre' && column!=='fullName'? 'center':'left'}">
          <a *ngIf="column==='subcontractor'" [routerLink]="[]"
            (click)="drillDown(cell['subcontractorId'],cell[column])">{{cell[column]}}</a>
          <a *ngIf="column==='centre'" [routerLink]="[]"
            (click)="drillDown(cell['centreId'],cell[column])">{{cell[column]}}</a>
          <span *ngIf="column.includes('Percentage')">{{cell[column]}}%</span>
          <span *ngIf="column==='fullName'">
            <a routerLink="/learner/attendance/{{cell['traineeId']}}/{{cell['pot']}}"
              target="_blank">{{cell[column]}}</a>
          </span>
          <span *ngIf="column==='weekStartDate'">
            {{cell[column] | date: this.dateFormat}}
          </span>
          <span
            *ngIf="column!=='subcontractor'&& column!=='centre'&& column!=='fullName' && column!=='weekStartDate' && !(column.includes('Percentage'))">{{cell[column]}}</span>

        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{getOverallAverage(column)}}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="column" *ngFor="let column of footerDisplayColumns">
        <td mat-footer-cell *matFooterCellDef>
          {{getSubContractorAverage(column)}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableDisplayColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: tableDisplayColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="tableDisplayColumns.length" style="text-align: center;"
          [attr.colspan]="this.tableDisplayColumns.length">
          {{
          showSpinner ? 'Loading...':
          noDateSelected? 'Please Select a date range.':'No Results'}}
        </td>
      </tr>
      <tr [hidden]="showSpinner || level !== 1" mat-footer-row *matFooterRowDef="footerDisplayColumns"
        class="footer-row">
      </tr>
      <tr [hidden]="showSpinner || level === 2" mat-footer-row *matFooterRowDef="tableDisplayColumns"
        class="footer-row">
      </tr>
    </table>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
  <mat-paginator #tablePaginator [length]="totalData" [pageSizeOptions]="pageOptions" showFirstLastButtons="true">
  </mat-paginator>
  <button mat-stroked-button class="save-export-buttons" [matMenuTriggerFor]="saveMenu">
    Export As... <mat-icon class="export-arrow">expand_more</mat-icon>
  </button>
  <mat-menu #saveMenu="matMenu" yPosition="below">
    <button mat-menu-item (click)="export('csv')">.csv</button>
    <button mat-menu-item (click)="export('xlsx')">.xlsx</button>
    <button mat-menu-item (click)="export('pdf')">.pdf</button>
  </mat-menu>
</div>
