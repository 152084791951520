import { IPotProgress } from 'src/app/shared/interfaces/potprogress';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LearnerService } from '../../learnerService.service';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { ProgressTableService } from 'src/app/core/database/progress-table-service';
import * as Highcharts from 'highcharts';

export var data: IPotProgress[] = [];

@Component({
  selector: 'progress-summary',
  templateUrl: './progress-summary.component.html',
  styleUrls: ['./progress-summary.component.css']
})
export class ProgressSummaryComponent implements OnInit {

  @Input() traineeId: string;
  @Input() pot: number;
  @Input() potProgress: IPotProgress[];
  @Input() startDate: string;
  @Input() showViewReportLink: boolean;
  @Input() showXAxisLabel: boolean = true;
  @Input() showYAxisLabel: boolean = true;

  data: IPotProgress[];
  heightVar: number;
  responsive = true;
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  xAxisLabel = 'Date';
  yAxisLabel = 'Progress';
  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']
  };

  highcharts = Highcharts;
  chartOptions: {};

  constructor(
    private dashboardService: LearnerService,
    private progressTableService: ProgressTableService,
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    let categories: string[] = [];
    let values: number[] = [];
    let target = this.potProgress[0].target;
    let currentProgress = this.potProgress[this.potProgress.length - 1].value;

    categories.push('');
    values.push(0);

    this.potProgress.forEach(element => {
      categories.push(element.name);
      values.push(element.value);
    })

    this.chartOptions = {
      chart: {
        type: "spline" //areaspline
      },
      title: {
        text: ""
      },
      subtitle: {
        text: ""
      },
      xAxis: {
        categories: categories,
        gridLineWidth: 1
      },
      yAxis: {
        title: {
          text: "Progress"
        },
        max: currentProgress > 75 || target >= 80 || currentProgress > target ? 100 : target + (target / 4),
        plotLines: [{
          color: '#FF0000',
          width: 2,
          dashStyle: 'LongDash',
          value: this.potProgress[0].target,
          label: {
            useHTML: true,
            align: 'left',
            formatter: function () {
                return '<span style="color: #828282;">Target: ' + target + '</span>';
            }
        }
        }]
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          return '<span style="color: #262633"><b>' + this.x + '</b></span><br>'
          + '<span>Progress: ' + this.y + '</span><br>'
          + '<span>' + Math.round(this.y /  target * 100) + '% of Target</span>';
        }
      },
      series: [{
        showInLegend: false,
        name: 'Progress',
        data: values
      }],
      credits: {
        enabled: false
      },
    };

  }

}
