<mat-toolbar>
    <mat-toolbar-row>
        <button mat-button color="accent" routerLink="">Back</button>
    </mat-toolbar-row>
    
    <mat-toolbar-row style="margin-left: 20px">
        <div [formGroup]="staffSelectForm" style="display: contents;" >
            <ng-select #select placeholder="Staff Selector" [items]="uniqueStaff" appendTo="body"
                bindLabel="name" bindValue="staffId"
                (change)="loadStaffByMember()" style="width: 300px; z-index: 100;" formControlName="staffSelect" [loading]="!uniqueStaff">
            </ng-select>

            <ng-select #select placeholder="Select random by department" [items]="uniqueStaffSimple" appendTo="body"
            (change)="loadStaffSimple()" style="width: 300px; z-index: 100;" formControlName="staffSelectSimple" bindLabel="department" [loading]="!uniqueStaffSimple">
            </ng-select>

            <button mat-flat-button class="button-staff-impersonate" [disabled]="!staffSelected" style="color:#7F7F7F; background-color: #333;" (click)="impersonateUser()">Impersonate User</button>
        </div>
    </mat-toolbar-row>

</mat-toolbar>

<div style="margin-left: 20px">
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="User Information">
            <form [formGroup]="staffInfoForm" *ngIf="staffSelected" style="margin-left: 1em;">
                <mat-form-field *ngFor="let ctrl of controlsList | keyvalue" style="margin-right: 1em;">
                    {{unCamelCase(ctrl.key)}}
                    <input matInput formControlName="{{ctrl.key}}">
                </mat-form-field>

            </form>

            <button *ngIf="staffSelected" mat-button color="primary" class="edit-button" (click)="saveForm()">Save</button>
        </mat-tab>

        <mat-tab label="User Permissions">

            <div class="permissions" *ngIf="traineePermissions && !permissionsEdit">
                <h2>Trainee Permissions</h2>
                 {{traineePermissions.hasNoAccess}}
                <div *ngIf="traineePermissions?.length == 0">
                    This staff member can see all trainees.
                </div>

                <div *ngIf="traineePermissions?.length == 1 && traineePermissions[0].hasNoAccess">
                    This staff member does not have access to any trainees yet.
                </div>

                <div *ngIf="traineePermissions?.length != 0 && !(traineePermissions?.length == 1 && traineePermissions[0].hasNoAccess) ">

                    
                   <div> This staff member can only see trainees where:</div>
                    <div *ngFor="let traineePermission of traineePermissions; index as i" fxLayout="row"
                        class="permission-condition">
            
                         <button mat-button color="primary" class="edit-button" (click)="togglePermissionsEdit()">Edit</button>
            
                        <div *ngIf="i != 0" fxFlex="10" class="or">
                            OR
                        </div>
                        <div *ngIf="i == 0" fxFlex="10">
            
                        </div>
            
                        <div *ngIf="traineePermission.contracts_in" fxFlex>                
                            <div *ngIf="traineePermission.contracts_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'contracts_in'"  class="and">and </span>
                                <span class="property-header">Contracts in:</span>
                                <div *ngFor="let contract of traineePermission.contracts_in; index as j">
                                    {{contract.contract }}<span [hidden]="j == traineePermission.contracts_in?.length - 1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.contracts_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'contracts_in'"  class="and">and </span>
                                <span class="property-header">Contract is:</span>
                                <div *ngFor="let contract of traineePermission.contracts_in">
                                    {{contract.contract }}
                                </div>
                            </div>
                        </div>
            
                        <div *ngIf="traineePermission.subcontractors_in" fxFlex>               
                            <div *ngIf="traineePermission.subcontractors_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'subcontractors_in'"  class="and">and </span>
                                <span class="property-header">Providers in:</span>
                                <div *ngFor="let subcontractor of traineePermission.subcontractors_in; index as j">
                                    {{subcontractor.subcontractor }}<span
                                        [hidden]="j == traineePermission.subcontractors_in?.length - 1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.subcontractors_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'subcontractors_in'"  class="and">and </span>
                                <span class="property-header">Provider is:</span>
                                <div *ngFor="let subcontractor of traineePermission.subcontractors_in">
                                    {{subcontractor.subcontractor }}
                                </div>
                            </div>
                        </div>
            
                        <div *ngIf="traineePermission.centres_in" fxFlex>                
                            <div *ngIf="traineePermission.centres_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'centres_in'"  class="and">and </span>
                                <span class="property-header">Centres in:</span>
                                <div *ngFor="let centre of traineePermission.centres_in; index as j">
                                    {{centre.centre }}<span [hidden]="j == traineePermission.centres_in?.length -1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.centres_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'centres_in'"  class="and">and </span>
                                <span class="property-header">Centre is:</span>
                                <div *ngFor="let centre of traineePermission.centres_in">
                                    {{centre.centre }}
                                </div>
                            </div>
                        </div>
            
                        <div *ngIf="traineePermission.programme_types_in" fxFlex>                
                            <div *ngIf="traineePermission.programme_types_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'programme_types_in'"  class="and">and </span>
                                <span class="property-header">Programme types in:</span>
                                <div *ngFor="let programmeType of traineePermission.programme_types_in; index as j">
                                    {{programmeType.programmetype }}<span
                                        [hidden]="j == traineePermission.programme_types_in?.length - 1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.programme_types_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'programme_types_in'"  class="and">and </span>
                                <span class="property-header">Programme type is:</span>
                                <div *ngFor="let programmeType of traineePermission.programme_types_in">
                                    {{programmeType.programmetype }}
                                </div>
                            </div>
                        </div>
            
                        <div *ngIf="traineePermission.schemes_in" fxFlex>                
                            <div *ngIf="traineePermission.schemes_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'schemes_in'"  class="and">and </span>
                                <span class="property-header">Schemes in:</span>
                                <div *ngFor="let scheme of traineePermission.schemes_in; index as j">
                                    {{scheme.scheme }}<span [hidden]="j == traineePermission.schemes_in?.length -1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.schemes_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'schemes_in'"  class="and">and </span>
                                <span class="property-header">Scheme is:</span>
                                <div *ngFor="let scheme of traineePermission.schemes_in">
                                    {{scheme.scheme }}
                                </div>
                            </div>
                        </div>
                     
                        <div *ngIf="traineePermission.sectors_in" fxFlex>                
                            <div *ngIf="traineePermission.sectors_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'sectors_in'"  class="and">and </span>
                                <span class="property-header"> Sectors in: </span>
                                <div *ngFor="let sector of traineePermission.sectors_in; index as j">
                                    {{sector.sector }}<span [hidden]="j == traineePermission.sectors_in?.length - 1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.sectors_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'sectors_in'"  class="and">and </span>
                                <span class="property-header">Sector is:</span>
                                <div *ngFor="let sector of traineePermission.sectors_in">
                                    {{sector.sector }}
                                </div>
                            </div>
                        </div>
                        
                        <div *ngIf="traineePermission.assessors_in" fxFlex>             
                            <div *ngIf="traineePermission.assessors_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'assessors_in'"  class="and">and </span>
                                <span class="property-header">Assessors in:</span>
                                <div *ngFor="let assessor of traineePermission.assessors_in; index as j">
                                    {{assessor.firstname }} {{assessor.lastname }}<span [hidden]="j == traineePermission.assessors_in?.length -1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.assessors_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'assessors_in'"  class="and">and </span>
                                <span class="property-header">Assessor is:</span>
                                <div *ngFor="let assessor of traineePermission.assessors_in">
                                    {{assessor.firstname }} {{assessor.lastname }}
                                </div>
                            </div>
                        </div>
            
                        
                        <div *ngIf="traineePermission.trainees_in" fxFlex>
                            
                            <div *ngIf="traineePermission.trainees_in?.length != 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'trainees_in'"  class="and">and </span>
                                <span class="property-header">Trainees in:</span>
                                <div *ngFor="let trainee of traineePermission.trainees_in; index as j">
                                    {{trainee.Name }}<span [hidden]="j == traineePermission.trainees_in?.length -1">,</span>
                                </div>
                            </div>
                            <div *ngIf="traineePermission.trainees_in?.length == 1">
                                <span *ngIf="traineePermission.nameFirstCondition != 'trainees_in'"  class="and">and </span>
                                <span class="property-header">Trainee is:</span>
                                <div *ngFor="let trainee of traineePermission.trainees_in">
                                    {{trainee.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>

             <div *ngIf="permissionsEdit" >
                <table mat-table matSort [dataSource]="dataSource.data">
                    <!-- <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns"> -->
                        <!-- Id column -->
                        <ng-container matColumnDef="iD">
                            <th mat-header-cell *matHeaderCellDef>iD</th>
                            <td mat-cell *matCellDef="let element">{{element.iD}}</td>
                        </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="contractid">
                        <th mat-header-cell *matHeaderCellDef>ContractiD</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <ng-select #select placeholder="Contracts" [items]="contractsList"
                            appendTo="body" [multiple]="true" (clear)="clearSelection(i, 'contractid')"
                            bindLabel="contract" bindValue="" [(ngModel)]="selectedContracts[i]" (change)="onSelect($event, i, 'contractid')">
                                <ng-option ng-model=emp[column]  *ngFor="let item of contractsList" [value]="item">
                                    {{item.contract}}
                                </ng-option>
                            </ng-select>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="subcontractorid">
                        <th mat-header-cell *matHeaderCellDef>SubcontractoriD</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <ng-select #select placeholder="Subcontractors" [items]="subcontractorsList"
                            appendTo="body" [multiple]="true" (clear)="clearSelection(i, 'subcontractorid')"
                            bindLabel="subcontractor" bindValue="" [(ngModel)]="selectedSubcontractors[i]" (change)="onSelect($event, i, 'subcontractorid')">
                                <ng-option ng-model=emp[column]  *ngFor="let item of subcontractorsList" [value]="item">
                                    {{item.subcontractor}}
                                </ng-option>
                            </ng-select>
                        </td>
                    </ng-container>

                    <!-- Programme Column -->
                    <ng-container matColumnDef="programmetypeid">
                        <th mat-header-cell *matHeaderCellDef>ProgrammeTypeiD</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <ng-select #select placeholder="Programme Types" [items]="programmeTypesList"
                            appendTo="body" [multiple]="true" (clear)="clearSelection(i, 'programmetypeid')"
                            bindLabel="programmeType" bindValue="" [(ngModel)]="selectedProgrammes[i]" (change)="onSelect($event, i, 'programmetypeid')">
                                <ng-option ng-model=emp[column]  *ngFor="let item of programmeTypesList" [value]="item">
                                    {{item.programmeType}}
                                </ng-option>
                            </ng-select>
                        </td>
                    </ng-container>

                    <!-- Sector Column -->
                    <ng-container matColumnDef="sectorid">
                        <th mat-header-cell *matHeaderCellDef>SectoriD</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <ng-select #select placeholder="Sectors" [items]="sectorsList" 
                            appendTo="body" [multiple]="true" (clear)="clearSelection(i, 'sectorid')"
                            bindLabel="sector" bindValue="" [(ngModel)]="selectedSectors[i]" (change)="onSelect($event, i, 'sectorid')">
                                <ng-option ng-model=emp[column]  *ngFor="let item of sectorsList" [value]="item">
                                    {{item.sector}}
                                </ng-option>
                            </ng-select>
                        </td>
                    </ng-container>

                    <!-- Centre Column -->
                    <ng-container matColumnDef="centreid">
                        <th mat-header-cell *matHeaderCellDef>CentreiD</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <ng-select #select placeholder="Centres" [items]="centresList" 
                            appendTo="body" [multiple]="true" (clear)="clearSelection(i, 'centreid')"
                            bindLabel="centre" bindValue="" [(ngModel)]="selectedCentres[i]" (change)="onSelect($event, i, 'centreid')">
                                <ng-option ng-model=emp[column]  *ngFor="let item of centresList" [value]="item">
                                    {{item.centre}}
                                </ng-option>
                            </ng-select>
                        </td>
                    </ng-container>

                    <!-- Scheme Column -->
                    <ng-container matColumnDef="schemeid">
                        <th mat-header-cell *matHeaderCellDef>SchemeiD</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <ng-select #select placeholder="Schemes" [items]="schemesList" 
                            appendTo="body" [multiple]="true" (clear)="clearSelection(i, 'schemeid')"
                            bindLabel="scheme" bindValue="" [(ngModel)]="selectedSchemes[i]" (change)="onSelect($event, i, 'schemeid')">
                                <ng-option ng-model=emp[column]  *ngFor="let item of schemesList" [value]="item">
                                    {{item.scheme}}
                                </ng-option>
                            </ng-select>
                        </td>
                    </ng-container>

                     <!-- Assessor Column -->
                    <ng-container matColumnDef="assessorid">
                        <th mat-header-cell *matHeaderCellDef>AssessoriD</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <ng-select #select placeholder="Assessors" [items]="assessorList" 
                            appendTo="body" [multiple]="true" (clear)="clearSelection(i, 'assessorid')"
                            bindLabel="assessor" bindValue="" [(ngModel)]="selectedAssessors[i]" (change)="onSelect($event, i, 'assessorid')">
                                <ng-option ng-model=emp[column]  *ngFor="let item of assessorList" [value]="item">
                                    {{item.assessor}}
                                </ng-option>
                            </ng-select>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="columnDelete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon class="icon--click-hover" (click)="delete(element)">delete</mat-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                  <button mat-button color="primary" class="edit-button" (click)="addRow()">Add</button>
                  <button mat-button color="primary" class="edit-button" (click)="togglePermissionsEdit()">Save</button>
             </div>

        </mat-tab>

        <mat-tab label="OneFile API Testing">
            <h2>OneFile API Testing</h2>
            <button mat-button color="primary" class="edit-button" (click)="newTraineesToOneFileTest()">New Trainees Test</button>
        </mat-tab>

        <mat-tab label="Password Generator">
            <h2>Password Generator</h2>
            <button mat-button color="primary" class="edit-button" (click)="getWordsForPassword()">Generate Password</button>
            {{generatedPass}}
            <button *ngIf="generatedPass" mat-button color="primary" (click)="copy()">Copy</button>
        </mat-tab>

    </mat-tab-group>
</div>