<h1 mat-dialog-title *ngIf="!isEdit">Create event</h1>
<h1 mat-dialog-title *ngIf="isEdit">Edit event
  <mat-icon style="float: right; margin-bottom: 10px;" class="icon--click-hover" (click)="deleteEvent()" *ngIf="canDelete">delete</mat-icon>
</h1>

<span *ngIf="data.isDashboard">({{data.currentDashboard}} dashboard)<br><br></span>
<div mat-dialog-content>
  <!-- <p>Event subject</p> -->


  <form [formGroup]="eventForm" novalidate *ngIf="isLoaded">


    <mat-dialog-content class="new-record-form">

      <mat-divider class="iqaformDivider"></mat-divider>
      <!--Using ng-option and for loop-->
      <!-- https://github.com/ng-select/ng-select#getting-started -->
      <div *ngIf="data.isEmployerLevel">
        <div class="employer-checkbox">
          <mat-checkbox style="margin-right: 10px;" formControlName="IsEmployerLevelEvent" [disabled]="isEdit">
            {{data.placementName}}
            Employer
            event</mat-checkbox>
        </div>

        <div *ngIf="!this.eventForm.get('IsEmployerLevelEvent').value">

          <ng-select class="event-type-select" #select placeholder="Branch" [items]="data.associatedPlacements"
            formControlName="forBranch" bindLabel="placementName" [disabled]="isEdit">
          </ng-select>
        </div>
      </div>
      <ng-select (change)="eventSelected()" class="event-type-select" #select placeholder="Event type"
        [items]="eventTypeSelection" formControlName="eventType" bindLabel="employereventtype">
      </ng-select>

      <div *ngIf="isDisplayAppraisalOptions && isEdit" style="margin: 20px 0 20px 0px;">
        Attachments
      </div>

      <div *ngIf="!isDisplayNewAppointmentOptions">
        <mat-label class="date-picker">Date Planned</mat-label> <br>
        <mat-form-field *ngIf="!isHealthAndSafety" appearance="outline">
          <input matInput readonly id="event_date" [matDatepicker]="pickerEvent" formControlName="eventDate"
            style="cursor: default;">
          <mat-datepicker-toggle matSuffix [for]="pickerEvent"></mat-datepicker-toggle>
          <mat-datepicker #pickerEvent></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="isHealthAndSafety" appearance="outline">
          <input matInput readonly id="event_date" [matDatepicker]="pickerEvent" formControlName="plannedEventDate"
            style="cursor: default;">
          <mat-datepicker-toggle matSuffix [for]="pickerEvent"></mat-datepicker-toggle>
          <mat-datepicker #pickerEvent></mat-datepicker>
        </mat-form-field>
        <br>

        <mat-label *ngIf="isHealthAndSafety" class="date-picker">Date Actual
        </mat-label> <br>
        <mat-form-field *ngIf="isHealthAndSafety" appearance="outline">
          <input matInput readonly id="event_date" [matDatepicker]="pickerEvent" formControlName="actualEventDate"
            style="cursor: default;">
          <mat-datepicker-toggle matSuffix [for]="pickerEvent"></mat-datepicker-toggle>
          <mat-datepicker #pickerEvent></mat-datepicker>
        </mat-form-field>

        <ng-select class="staff-member-select" #select [items]="staff" bindLabel="name" placeholder="By"
          formControlName="by"></ng-select>

        <div *ngIf="!isHealthAndSafety" class="message-label">
          <mat-label>Notes</mat-label>
        </div>
        <ckeditor *ngIf="!isHealthAndSafety" class="multi-line white" id="message" [editor]="Editor" [config]="editorConfig"
          formControlName="message" data=""></ckeditor>
      </div>

      <div *ngIf="isDisplayNewAppointmentOptions">
        <div>
          <mat-checkbox style="margin-right: 10px;" formControlName="IamTheOrganiser">I am the event organiser
          </mat-checkbox>
        </div>
        <div *ngIf="!eventForm.get('IamTheOrganiser').value">
          <ng-select class="staff-member-select" #select [items]="staff" bindLabel="name" placeholder="Organiser"
            formControlName="by"></ng-select>
        </div>
        <ng-select class="staff-select" #select [items]="staff" [multiple]="true" bindLabel="name" placeholder="Staff"
          formControlName="selectedStaff">
        </ng-select>
        <div class="wrap" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
          fxLayoutAlign="start end" fxLayoutAlign.md="start start" fxLayoutAlign.sm="start start"
          fxLayoutAlign.xs="start start">
          <div flex="30">
            <mat-label class="date-picker">Date Planned</mat-label> <br>
            <mat-form-field appearance="outline">
              <input matInput readonly id="event_date" [matDatepicker]="pickerEvent" formControlName="eventDate"
                style="cursor: default;">
              <mat-datepicker-toggle matSuffix [for]="pickerEvent"></mat-datepicker-toggle>
              <mat-datepicker #pickerEvent></mat-datepicker>
            </mat-form-field> <br>
          </div>
          <div flex="30"></div>
          <div flex="30">
            <ng-select class="start-select" #select placeholder="Start time" [items]="startTimeSelection"
              formControlName="startTime">
            </ng-select>
          </div>
          <div flex="30"></div>
          <div flex="30">
            <ng-select class="end-select" #select placeholder="End time" [items]="endTimeSelection"
              formControlName="endTime">
            </ng-select>
          </div>
        </div>

        <mat-label class=reviewFormTitle>Subject</mat-label>
        <mat-form-field class="iqa-new-record" appearance="outline">
          <input cdkFocusInitial matInput formControlName="subject">
        </mat-form-field>

        <div class="message-label">
          <mat-label>Message</mat-label>
        </div>
        <ckeditor class="multi-line white" id="message" [editor]="Editor" [config]="editorConfig"
          formControlName="message" data=""></ckeditor>

        <div class="location">
          <mat-label class=reviewFormTitle>Location</mat-label>
          <mat-form-field class="iqa-new-record" appearance="outline">
            <input matInput formControlName="location">
          </mat-form-field>
        </div>
      </div>



    </mat-dialog-content>
  </form>

</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onOK()" cdkFocusInitial [disabled]="!eventForm.valid" *ngIf="isLoaded">Ok</button>
</div>
