 <!-- Style set to null to override max-height set in home.component.scss file and messes up the layout -->
<mat-card style="max-height: none;">
  <h2 class="dashboard-titles">IALPs Due</h2>
  <mat-spinner *ngIf="loadingData" style="margin: 20px auto 20px auto; display: block;"></mat-spinner>
  <div style="margin-top: 20px">
    <div class="info-content-container">
      <div class="info-content-sides">
        <div style="display: flex; flex-direction: column; gap: 5px">
          <mat-slide-toggle [checked]="onlyMyLearnerStatus" (change)="toggleOnlyMyLearnerStatus($event)">Only My Learners</mat-slide-toggle>
          <mat-slide-toggle [checked]="hidePrivateLearnerStatus" (change)="toggleHidePrivateLearnerStatus($event)">Hide Private Learners</mat-slide-toggle>
        </div>
      </div>
      <div style="font-size: 3em; font-weight: 500; flex-grow: 1;">
        {{dataSource?.data?.length}}
      </div>
      <div class="info-content-sides">&nbsp;</div>
    </div>
    <div *ngIf="!loadingData" style="font-size: 0.9em; cursor: pointer; color: #39F; margin: 5px 0 5px 0" (click)="this.toggleTable()">
      {{ this.showTable ? 'Hide' : 'Show quick info' }}
    </div>
  </div>
  <div class="reviews-due-table-container {{this.showTable ? 'open-review-table' : ''}}">
    <table mat-table class="reviews-due-table"
      [dataSource]="dataSource"
      #sort="matSort"
      matSort
      matSortActive="days"
      matSortDirection="desc"
    >

      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky>
          {{ column === 'fullName' ? 'Name' : this.unCamelCase(column) }}
        </th>
          <td mat-cell *matCellDef="let cell" [ngStyle]="this.tableCellStyle(column , cell[column], cell)">
            <span *ngIf="column !== 'fullName'">
              {{ this.isDate(cell[column], column) ? (cell[column] | date: this.dateFormat) ?? '-' : cell[column] }}
            </span>
            <span *ngIf="column === 'fullName'">
                <a routerLink="/learner/reviews/{{cell['traineeId']}}/{{cell['pot']}}">
                  {{ cell[column] }}
                </a>
            </span>
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9" >No Reviews due</td>
      </tr>
    </table>
    <mat-paginator #paginator [pageSize]="6" [hidePageSize]="true"></mat-paginator>
  </div>
  <div style="margin: 15px 0 5px 0;">
    <a routerLink="/reports/onefile-ialps">Full Report</a>
  </div>
</mat-card>
