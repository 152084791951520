<h1 mat-dialog-title>Add Employer</h1>
<div mat-dialog-content>
    <form [formGroup]="employerForm" novalidate >
        <div><mat-label>Name</mat-label></div>
        <mat-form-field appearance="outline" style="width: 100%">
            <input cdkFocusInitial matInput formControlName="name" />
        </mat-form-field>

    </form>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button color="primary"(click)="onNoClick()">Cancel</button>
    <button mat-button (click)="onOK()" cdkFocusInitial [disabled]="!employerForm.valid">Save</button>
</div>
