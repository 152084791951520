import { LearnerPayExportComponent } from './learner-pay-export/learner-pay-export.component';
import { LearnerPayImportComponent } from './learner-pay-import/learner-pay-import.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-learner-pay',
  templateUrl: './learner-pay.component.html',
  styleUrls: ['./learner-pay.component.scss']
})
export class LearnerPayComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @Input() staffId: number;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  learnerPayExport() {
    const data = { staffId: this.staffId };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.height = '93%';
    const dialogRef = this.dialog.open(LearnerPayExportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        console.log(formData);
      }
    })
  }

  learnerPayImport() {
    const data = { staffId: this.staffId };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '390px';
    dialogConfig.minHeight = '300px';
    const dialogRef = this.dialog.open(LearnerPayImportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        console.log(formData);
      }
    })
  }

}
