import { group } from '@angular/animations';
import { DecimalPipe } from '@angular/common';
import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-attendance-summary-report',
  templateUrl: './attendance-summary-report.component.html'
})
export class AttendanceSummaryReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
    private decimalPipe: DecimalPipe,
  ) { }

  reportVariant = ReportVariant.AttendanceSummary;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'scheme', 'sector', 'assessor', 'weekStartDate', 'totalHours']
    }
  ];
  dateFilters = ['weekStartDate'];
  otherColumns = [
    'centre', 'attendedHours', 'unauthorisedAbsenceHours', 'classBasedHours',
    'learningHours', 'workBasedHours', 'holidayHours', 'homeStudyHours',
    'unauthorisedAbsencePercentage'
  ]
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'centre', pieChartShareValue: 'attendedHours'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'centre', pieChartShareValue: 'attendedHours'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'centre', pieChartShareValue: 'attendedHours'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'centre', pieChartShareValue: 'attendedHours'
    },
    {
      value: 'scheme', title: 'Scheme', columns: ['scheme', ...this.otherColumns],
      pieChartDrillDown: 'centre', pieChartShareValue: 'attendedHours'
    },
  ];
}
