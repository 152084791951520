import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-certificates-to-be-claimed-report',
  templateUrl: './certificates-to-be-claimed-report.component.html',
  styleUrls: ['./certificates-to-be-claimed-report.component.scss']
})
export class CertificatesToBeClaimedReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.CertificatesToBeClaimed;
  defaultDisplayColumns = [
    {
      type: 'overview',
      columns: ['fullName', 'scheme', 'sector', 'assessor', 'potStatus', 'title', 'iqa', 'dateSignedOff']
    },
    {
      type: 'units',
      columns: ['fullName', 'title', 'level', 'regNumber', 'regDate', 'dateSignedOff', 'assessor', 'iqa',
        'unit1', 'unit2', 'unit3', 'unit4', 'unit5', 'unit6', 'unit7', 'unit8', 'unit9', 'unit10',
        'unit11', 'unit12', 'unit13', 'unit14', 'unit15', 'unit16', 'unit17', 'unit18', 'unit19', 'unit20',
        'unit21', 'unit22', 'unit23', 'unit24', 'unit25', 'unit26', 'unit27', 'unit28']
    },
    {
      type: 'admin',
      columns: ['awardingBody', 'laimRef', 'title', 'level', 'regNumber', 'regDate', 'fullName', 'dob',
        'uniqueLearnerNumber', 'sector', 'scheme', 'assessor', 'potStatus', 'iqa', 'partial', 'dateSignedOff',
        'unit1', 'unit2', 'unit3', 'unit4', 'unit5', 'unit6', 'unit7', 'unit8', 'unit9', 'unit10', 'unit11',
        'unit12', 'unit13', 'unit14', 'unit15', 'unit16', 'unit17', 'unit18', 'unit19', 'unit20',
        'unit21', 'unit22', 'unit23', 'unit24', 'unit25', 'unit26', 'unit27', 'unit28']
    }
  ];
  dateFilters = ['dateSignedOff', 'regDate', 'startDate', 'expectedEndDate', 'terminationDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];
}
