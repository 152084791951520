<mat-card style="height: fit-content;">
  <h2 class="dashboard-titles">Bookmarked Learners</h2>
  <div style="margin-top: 20px">
    <table mat-table class="fav-learner-table"
      [dataSource]="dataSource"
      #sort="matSort"
      matSort
      matSortActive="name"
      matSortDirection="desc"
    >

      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sticky>
          {{ this.unCamelCase(column) }}
        </th>
          <td mat-cell *matCellDef="let cell">
            <span *ngIf="column !== 'name'">
              <span class="action-button remove {{ this.waiting ? 'disabled' : '' }}" (click)="removeFavourite(cell['id'])">Remove</span>
            </span>
            <span *ngIf="column === 'name'">
                <a routerLink="/learner/dashboard/{{cell['traineeId']}}/{{cell['pot']}}">
                  {{ cell[column] }}
                </a>
            </span>
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9" >You have not bookmarked any learners yet!</td>
      </tr>
    </table>
    <mat-progress-bar *ngIf="this.waiting" mode="indeterminate"></mat-progress-bar>
    <mat-paginator #paginator [pageSize]="6" [hidePageSize]="true"></mat-paginator>
  </div>
</mat-card>
