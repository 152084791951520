import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AuthService } from "../core/services/auth.service";
import { Router } from "@angular/router";
import { AuthErrors } from "../shared/enums/auth-errors";


@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html'
})

export class RedirectComponent implements OnInit {

    redirect;
    count: number = 0;
    showMessage: boolean = false;
    constructor(private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,) {


    }

    ngOnInit(): void {
        console.dir("LOADED REDIRECT!");
        this.redirect = setInterval(() => { this.countUp() }, 1000);
    }

    ngOnDestroy() {
        clearInterval(this.redirect);
    }

    countUp() {
        this.count++;
        if (this.count == 60) {
            this.showMessage = true;
            this.changeDetectorRef.detectChanges();
        }
        if (this.count == 120) {
            this.router.navigate(['/auth-error'], { queryParams: { error: AuthErrors.FailedRedirect } });
        }
    }
}