
import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { IReportFilterUnsaved } from '../../shared/interfaces/report-filter-unsaved';


export interface ReportFilterUnsavedWithId extends IReportFilterUnsaved {
  id: number;
}


@Injectable()
export class RecentReportFiltersUnsavedTableService {
  table: Dexie.Table<ReportFilterUnsavedWithId, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('recentReportFiltersUnsaved');
  }

 
  getAll() {
    return this.table.toArray();
  }

  get(id) {
    return this.table.get(id);
  }

  async where(index, data) {
    const result = await this.table.where(index).equals(data).toArray();
    return result;
  }

  add(data) {
    return this.table.add(data);
  }

  put(data) {
    return this.table.put(data);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  delete(id) {
    return this.table.delete(id);
  }

  clear(){
    return this.table.clear();
  }

  count(){
    return this.table.count();
  }

}
