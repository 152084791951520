<div>
  <div *ngIf="this.templateInfoLoaded" style="position: fixed; top:0; width: 100%; z-index: 10;">
    <div class="learner-Info">
      <app-learner-key-info [programme]="this.templateInfo.programme"
        [level]="this.templateInfo.level?.toString().replace('-', 'E')" [learnerName]="this.templateInfo.name"
        [sector]="this.templateInfo.sector" [scheme]="this.templateInfo.scheme"
        [welsh]="this.templateInfo.welshSpeaking" [potStatus]="this.templateInfo.potStatus"
        [isFavorite]="this.isFavorite" [waitingForFavoriteToggle]="this.waitingForFavoriteToggle"
        (toggleFavoriteEvent)="this.toggleFavorite($event)" [backUrl]="this.backUrl" [backParams]="this.backParams">
      </app-learner-key-info>
    </div>

    <!-- Text align last isnt 100% supported but its an easy solution so that the options are centered -->
    <div id="pot-select-container">
      <ng-select class="pot-dropdown" [(ngModel)]="this.pot" [items]="this.templateInfo.pots"
        placeholder="POT: {{ this.pot }}" (change)="this.changePot()" id="pot-select">
      </ng-select>
    </div>

    <learner-summary-bar [showAttendance]="this.showAttendance" (loadPageEvent)="this.loadPage($event)"
      [currentTab]="this.tabName">
    </learner-summary-bar>
  </div>

  <div class="content">
    <ng-template learnerHost></ng-template>
  </div>

</div>