<div>

  <div class="top-section">
    <div>
      <h2 *ngIf="!data.title" mat-dialog-title class="IQAHeading">
        Add new IQA Record
      </h2>

      <div class=".IQAHeading" *ngIf="data.title" style="display: flex; flex-direction: row; align-items: center;">
        <h2 mat-dialog-title class="IQAHeading" style="padding: 0; margin: 0;">
          IQA Record
        </h2>
        <span *ngIf="this.beenClaimed && !this.certReceived" class="claimed-stamp" style="cursor: default;"
          matTooltip="Claimed on {{this.claimDate | date}} by {{this.claimBy}}" matTooltipPosition="above">
          Claim Requested
        </span>
        <span *ngIf="this.certReceived" class="cert-received-stamp" matTooltip="{{this.certReceived | date}}"
          matTooltipPosition="above" style="cursor: default;">
          Certificate Received
        </span>
      </div>
    </div>
    <mat-icon *ngIf="data.title && data.canDelete" (click)="this.delete()" class="icon delete">
      delete_forever
    </mat-icon>
  </div>

  <mat-dialog-content class="new-record-form">
    <form [formGroup]="iqaForm" novalidate (ngSubmit)="close()">
      <mat-label class="IQAFormTitle" style="margin-top: 20px">
        Qualification
        <span *ngIf="!this.data.iqaRecordId" style="color:red"> *</span>
      </mat-label>
      <div *ngIf="this.data.iqaRecordId" style="line-height: 40px; padding-left: 0.5em;">
        {{this.data.title}}
      </div>
      <ng-select *ngIf="!this.data.iqaRecordId" appearance="outline" #select class="select-qual-dropdown"
        [items]="data.objectives" (change)="changeQualification()" formControlName="title" bindLabel="title"
        bindValue="title" name="title">
      </ng-select>

      <!-- Should these be inputs? they are auto set and I dont think they should ever be changed
        26/5/2023 Have changed them now to read only and will wait to see if there is any negative feedback before fully removing.
      -->
      <div *ngIf="iqaForm.get('title').value" style="display: flex; gap: 30px; margin-bottom: 1.25em;">
        <div class="read-only">
          <div class="IQAFormTitle">
            Learning Aim Reference:
          </div>
          <div>
            {{this.iqaForm.get('laimRef').value}}
          </div>
        </div>
        <!-- <mat-form-field class="iqa-new-record" appearance="outline">
          <input *ngIf="data.laimRef" value="{{data.laimRef}}" matInput formControlName="laimRef">
          <input *ngIf="!data.laimRef" matInput formControlName="laimRef">
        </mat-form-field> <br> -->

        <div class="read-only">
          <div class="IQAFormTitle">
            Awarding Body:
          </div>
          <div>
            {{this.iqaForm.get('awardingBody').value}}
          </div>
        </div>
        <!-- <mat-form-field class="iqa-new-record" appearance="outline">
          <input *ngIf="data.awardingBody" value="{{data.awardingBody}}" matInput
              formControlName="awardingBody">
          <input *ngIf="!data.awardingBody" matInput formControlName="awardingBody">
        </mat-form-field> -->
      </div>

      <!-- come back to - multi select? -->
      <div formArrayName="selectedUnitsArray" style="margin-bottom: 30px;">
        <mat-label class="IQAFormTitle">
          Units
        </mat-label>
        <div *ngIf="displayQualificationNotSelectedWarning" class="warn">Please select a qualification first.
        </div>
        <div fxLayout="row" class=iconsuffix style="align-items: center; margin-bottom: 0.25em; gap: 15px;"
          *ngFor="let ctrl of iqaForm.get('selectedUnitsArray')['controls']; let i = index;" [formGroupName]="i">
          <ng-select fxFlex="90" appearance="outline" class="dropdown-select units" #select [items]="units"
            (open)="checkQualSelected()" (change)="countCriteria(ctrl.value)" [compareWith]="compareFn"
            formControlName="selectedUnit" bindLabel="title" notFoundText="No results found"
            [searchFn]="unitCustomSearch" [style.padding-bottom.px]="0">
            <ng-template ng-label-tmp let-item="item">
              {{item.unit}} - {{item.title}}
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              {{item.unit}} - {{item.title}}
            </ng-template>
          </ng-select>

          <button *ngIf="i > 0" fxFlex="5" type="button" mat-icon-button (click)='cancelUnit(i)'>
            <mat-icon style="height: auto; width: auto; line-height: 30px; padding-top: 6px">cancel</mat-icon>
          </button>
        </div>

        <div style="display:flex; gap: 15px; align-items: baseline; margin-top: 1.25em;">
          <button class="mat-raised-button mat-basic" type="button" mat-button (click)='newUnit()'>
            Add Unit
          </button>
          <p *ngIf="iqaCreditsCount" class="unitTips" style="margin: 0"
            [style.color]="iqaCreditsCount >= iqaCreditsMin ? 'darkolivegreen' : 'darkred'">
            {{iqaCreditsCount}} of {{iqaCreditsMin}} credits selected
          </p>
        </div>

        <p *ngIf="iqaCreditsCountA != 0 && iqaCreditsMin_A != 0" class=unitTips
          [style.color]="iqaCreditsCountA >= iqaCreditsMin_A ? 'darkolivegreen' : 'darkred'">
          {{iqaCreditsCountA}}
          of {{iqaCreditsMin_A}} credits selected from group A</p>
        <p *ngIf="iqaCreditsCountA > iqaCreditsMax_A" class=unitTips>Too many credits selected from group A!</p>
        <p *ngIf="iqaCreditsCountB != 0 && iqaCreditsMin_B != 0" class=unitTips
          [style.color]="iqaCreditsCountB >= iqaCreditsMin_B ? 'darkolivegreen' : 'darkred'">
          {{iqaCreditsCountB}}
          of {{iqaCreditsMin_B}} credits selected from group B</p>
        <p *ngIf="iqaCreditsCountB > iqaCreditsMax_B" class=unitTips>Too many credits selected from group B!</p>
        <p *ngIf="iqaCreditsCountC != 0 && iqaCreditsMin_C != 0" class=unitTips
          [style.color]="iqaCreditsCountC >= iqaCreditsMin_C ? 'darkolivegreen' : 'darkred'">
          {{iqaCreditsCountC}}
          of {{iqaCreditsMin_C}} credits selected from group C</p>
        <p *ngIf="iqaCreditsCountC > iqaCreditsMax_C" class=unitTips>Too many credits selected from group C!</p>
        <p *ngIf="iqaCreditsCountD != 0 && iqaCreditsMin_D != 0" class=unitTips
          [style.color]="iqaCreditsCountD >= iqaCreditsMin_D ? 'darkolivegreen' : 'darkred'">
          {{iqaCreditsCountD}}
          of {{iqaCreditsMin_D}} credits selected from group D</p>
        <p *ngIf="iqaCreditsCountD > iqaCreditsMax_D" class=unitTips>Too many credits selected from group D!</p>
        <p *ngIf="iqaCreditsCountE != 0 && iqaCreditsMin_E != 0" class=unitTips
          [style.color]="iqaCreditsCountE >= iqaCreditsMin_E ? 'darkolivegreen' : 'darkred'">
          {{iqaCreditsCountE}}
          of {{iqaCreditsMin_E}} credits selected from group E</p>
        <p *ngIf="iqaCreditsCountE > iqaCreditsMax_E" class=unitTips>Too many credits selected from group E!</p>
        <p *ngIf="iqaCreditsCountF != 0 && iqaCreditsMin_F != 0" class=unitTips
          [style.color]="iqaCreditsCountF >= iqaCreditsMin_F ? 'darkolivegreen' : 'darkred'">
          {{iqaCreditsCountF}}
          of {{iqaCreditsMin_F}} credits selected from group F</p>
        <p *ngIf="iqaCreditsCountF > iqaCreditsMax_F" class=unitTips>Too many credits selected from group F!</p>
      </div>

      <mat-label class="IQAFormTitle">IQA Type</mat-label>
      <span style="color:red"> *</span>
      <ng-select appearance="outline" class="dropdown-select" #select [items]="formOptions.iqaFormOptionIQAType"
        formControlName="iqaTypeId" bindLabel="iqaType" bindValue="iqaTypeId">
      </ng-select>

      <mat-label class="IQAFormTitle">Sample Type</mat-label>
      <span style="color:red"> *</span>
      <ng-select appearance="outline" class="dropdown-select" #select [items]="formOptions.iqaFormOptionSampleType"
        formControlName="sampleTypeId" bindLabel="sampleType" bindValue="sampleTypeId">
      </ng-select>

      <mat-label class="IQAFormTitle">Outcome</mat-label>
      <ng-select appearance="outline" class="dropdown-select" #select [items]="formOptions.iqaFormOptionOutcome"
        formControlName="iqaOutcomeId" bindLabel="outcome" bindValue="outcomeId">
      </ng-select>

      <div class="IQAFormGrid">
        <mat-label class="IQAFormTitle">Date Planned<span style="color:red"> *</span></mat-label>
        <mat-label class="IQAFormTitle">Date Actual</mat-label>
        <mat-form-field appearance="outline">
          <input [readonly]="true" matInput id="date_planned" [matDatepicker]="pickerPlanned"
            formControlName="datePlanned" style="cursor: default;">
          <mat-datepicker-toggle matSuffix [for]="pickerPlanned"></mat-datepicker-toggle>
          <mat-datepicker #pickerPlanned></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input [readonly]="true" matInput id="date_actual" [matDatepicker]="pickerActual" formControlName="dateActual"
            style="cursor: default;">
          <mat-datepicker-toggle matSuffix [for]="pickerActual"></mat-datepicker-toggle>
          <mat-datepicker #pickerActual></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="title-row-above">
        <mat-label class="IQAFormTitle title-above">Assessor</mat-label>
        <mat-label class="IQAFormTitle title-above" style="margin-left: 30px;">Second Line Assessor</mat-label>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <ng-select fxFlex="48" appearance="outline" class="dropdown-select" #select
          [items]="formOptions.iqaFormOptionSLA" formControlName="assessorId" bindLabel="fullname"
          bindValue="staffId"></ng-select>
        <ng-select fxFlex="48" appearance="outline" class="dropdown-select" #select
          [items]="formOptions.iqaFormOptionSLA" formControlName="secondLineAssessorId" bindLabel="fullname"
          bindValue="staffId"></ng-select>
      </div>

      <div class="title-row-above">
        <mat-label class="IQAFormTitle title-above">IQA</mat-label>
        <mat-label class="IQAFormTitle title-above" style="margin-left: 30px;">Countersign IQA</mat-label>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <ng-select fxFlex="48" appearance="outline" class="dropdown-select" #select
          [items]="formOptions.iqaFormOptionStaff" formControlName="iqaStaffId" bindLabel="fullname"
          bindValue="staffId"></ng-select>
        <ng-select fxFlex="48" appearance="outline" class="dropdown-select" #select
          [items]="formOptions.iqaFormOptionStaff" formControlName="counterSignIqaStaffId" bindLabel="fullname"
          bindValue="staffId"></ng-select>
      </div>

      <mat-label class="IQAFormTitle">IQA Site</mat-label>
      <ng-select appearance="outline" class="dropdown-select" #select [items]="formOptions.iqaFormOptionSite"
        formControlName="iqaSiteId" bindLabel="site" bindValue="siteId">
      </ng-select>

      <mat-label class="IQAFormTitle">Comments</mat-label>
      <mat-form-field style="display: block" appearance="outline">
        <textarea matInput formControlName="comments" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="7">
        </textarea>
      </mat-form-field>

      <div *ngIf="this.iqaForm.get('iqaTypeId').value === 6 || this.iqaForm.get('iqaTypeId').value === 3">
        <mat-checkbox id="claim" color="primary" formControlName="claim">Claim Record</mat-checkbox>
        <p style="color:red">{{ this.claimError }}</p>
      </div>

    </form>
  </mat-dialog-content>


  <mat-dialog-actions style="margin-top: 10px; float: right;">
    <button class="mat-raised-button mat-primary submit-button" [disabled]="!iqaForm.valid" (click)="save()">Submit
    </button>
    <div class="close">
      <button mat-button (click)="close()">Close</button>
    </div>
  </mat-dialog-actions>
</div>