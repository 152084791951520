<div style="max-height: 80vh;">

  <div>
    <h1 mat-dialog-title>{{ this.title }}</h1>
    <mat-divider></mat-divider>
  </div>

  <div mat-dialog-content style="margin-top: 15px;">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef
          [ngStyle]="{'width':column=='date' ? '85px;' : column=='level' ? '40px' : ''}"
        >
          {{unCamelCase(column)}}
        </th>
        <td mat-cell *matCellDef="let emp">
          {{ isDate(emp[column], column) ? (emp[column] | date: this.dateFormat)
          : emp[column] == null ? '-' : emp[column].toUpperCase() }}
          <span *ngIf="column !== 'date'" [innerHTML]="compairLevel(emp.index, emp[column], column)"></span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" showFirstLastButtons aria-label="Select page"></mat-paginator>
  </div>

  <div mat-dialog-actions>
    <mat-divider style="width: 100%"></mat-divider>
    <div class="button-container">
      <button mat-flat-button color="primary" style="float: right;" (click)="close()">Close</button>
    </div>
  </div>
</div>
