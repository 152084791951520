<mat-card class="learners-card">
    <div class="employers-container">
        <button *ngIf="dataSource" mat-flat-button color="primary" class="learners-button" routerLink="/employer/search">Search all Employers</button>
        <h2 class="dashboard-titles" style="padding-left:0px;">Your Employers</h2>

        <mat-spinner *ngIf="!dataSource" style="font-size: 25px;"></mat-spinner>

        <mat-form-field [hidden]="!dataSource" class="widget-your-employers-search" appearance="outline">
            <mat-icon matPrefix >search</mat-icon>
            <input #txtVal matInput placeholder="Start typing to jump to an Employer" (keyup)="applyFilter(txtVal.value)" autocomplete="off">
        </mat-form-field>

        <table class="widget-your-employers-table" [hidden]="!dataSource" mat-table [dataSource]="dataSource" matSort>
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{unCamelCase(column)}} </th>
                <span *ngIf="column=='name'">
                    <td mat-cell *matCellDef="let element"> <a class="export-all" style="text-decoration: underline; font-size: 1em;" (click)="loadEmployer(element[column])"> {{element[column]}} </a> </td>
                </span>
                <span *ngIf="column != 'name'">
                    <td mat-cell *matCellDef="let element" > {{column.indexOf('lastContacted') != -1 ? (element[column] | date) : element[column] }} </td>
                </span>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator  [pageSize]="4"  [pageSizeOptions]="[4, 25, 50]" showFirstLastButtons></mat-paginator>
    </div>
</mat-card>
