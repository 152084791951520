<div *ngIf="data.staffData.name">Name: {{ data.staffData.name }}</div>
<div *ngIf="data.staffData.company">Company: {{ data.staffData.company }}</div>
<!-- <div *ngIf="data.staffData.floor">Floor: {{ data.staffData.floor }}</div> -->
<div *ngIf="data.staffData.jobRole">Job Role: {{ data.staffData.jobRole }}</div>
<div *ngIf="data.staffData.mobile">Mobile: {{ data.staffData.mobile }}</div>
<div *ngIf="data.staffData.extNo">Extension: {{ data.staffData.extNo }}</div>
<div *ngIf="data.staffData.carReg">Car Registration: {{ data.staffData.carReg }}</div>
<div *ngIf="data.staffData.currentLocation">Current Location: {{ data.staffData.currentLocation }}</div>
<div *ngIf="data.staffData.additionalRoles">Additional Roles: {{ data.staffData.additionalRoles }}</div>

<button *ngIf="canSignOthersIn" mat-button (click)="signInOrOut()">{{buttonText}}</button>


<div class="permissions">
    <h2>Trainee Permissions</h2>
    {{data.traineePermissions.hasNoAccess}}
    <div *ngIf="data.traineePermissions?.length == 0">
        This staff member can see all trainees.
    </div>
    <div *ngIf="data.traineePermissions?.length == 1 && data.traineePermissions[0].hasNoAccess">
        This staff member does not have access to any trainees yet.
    </div>
    <div *ngIf="data.traineePermissions?.length != 0 && !(data.traineePermissions?.length == 1 && data.traineePermissions[0].hasNoAccess) ">
       <div> This staff member can only see trainees where:</div>
        <div *ngFor="let traineePermission of data.traineePermissions; index as i" fxLayout="row"
            class="permission-condition">

            <!-- <button mat-button color="primary" class="edit-button" (click)="editPermission(traineePermission)">Edit</button> -->

            <div *ngIf="i != 0" fxFlex="10" class="or">
                OR
            </div>
            <div *ngIf="i == 0" fxFlex="10">

            </div>

            <div *ngIf="traineePermission.contracts_in" fxFlex>
                <div *ngIf="traineePermission.contracts_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'contracts_in'"  class="and">and </span>
                    <span class="property-header">Contracts in:</span>
                    <div *ngFor="let contract of traineePermission.contracts_in; index as j">
                        {{contract.contract }}<span [hidden]="j == traineePermission.contracts_in?.length - 1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.contracts_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'contracts_in'"  class="and">and </span>
                    <span class="property-header">Contract is:</span>
                    <div *ngFor="let contract of traineePermission.contracts_in">
                        {{contract.contract }}
                    </div>
                </div>
            </div>

            <div *ngIf="traineePermission.subcontractors_in" fxFlex>
                <div *ngIf="traineePermission.subcontractors_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'subcontractors_in'"  class="and">and </span>
                    <span class="property-header">Providers in:</span>
                    <div *ngFor="let subcontractor of traineePermission.subcontractors_in; index as j">
                        {{subcontractor.subcontractor }}<span
                            [hidden]="j == traineePermission.subcontractors_in?.length - 1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.subcontractors_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'subcontractors_in'"  class="and">and </span>
                    <span class="property-header">Provider is:</span>
                    <div *ngFor="let subcontractor of traineePermission.subcontractors_in">
                        {{subcontractor.subcontractor }}
                    </div>
                </div>
            </div>

            <div *ngIf="traineePermission.centres_in" fxFlex>
                <div *ngIf="traineePermission.centres_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'centres_in'"  class="and">and </span>
                    <span class="property-header">Centres in:</span>
                    <div *ngFor="let centre of traineePermission.centres_in; index as j">
                        {{centre.centre }}<span [hidden]="j == traineePermission.centres_in?.length -1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.centres_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'centres_in'"  class="and">and </span>
                    <span class="property-header">Centre is:</span>
                    <div *ngFor="let centre of traineePermission.centres_in">
                        {{centre.centre }}
                    </div>
                </div>
            </div>

            <div *ngIf="traineePermission.programme_types_in" fxFlex>
                <div *ngIf="traineePermission.programme_types_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'programme_types_in'"  class="and">and </span>
                    <span class="property-header">Programme types in:</span>
                    <div *ngFor="let programmeType of traineePermission.programme_types_in; index as j">
                        {{programmeType.programmetype }}<span
                            [hidden]="j == traineePermission.programme_types_in?.length - 1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.programme_types_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'programme_types_in'"  class="and">and </span>
                    <span class="property-header">Programme type is:</span>
                    <div *ngFor="let programmeType of traineePermission.programme_types_in">
                        {{programmeType.programmetype }}
                    </div>
                </div>
            </div>

            <div *ngIf="traineePermission.schemes_in" fxFlex>
                <div *ngIf="traineePermission.schemes_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'schemes_in'"  class="and">and </span>
                    <span class="property-header">Schemes in:</span>
                    <div *ngFor="let scheme of traineePermission.schemes_in; index as j">
                        {{scheme.scheme }}<span [hidden]="j == traineePermission.schemes_in?.length -1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.schemes_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'schemes_in'"  class="and">and </span>
                    <span class="property-header">Scheme is:</span>
                    <div *ngFor="let scheme of traineePermission.schemes_in">
                        {{scheme.scheme }}
                    </div>
                </div>
            </div>

            <div *ngIf="traineePermission.sectors_in" fxFlex>
                <div *ngIf="traineePermission.sectors_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'sectors_in'"  class="and">and </span>
                    <span class="property-header"> Sectors in: </span>
                    <div *ngFor="let sector of traineePermission.sectors_in; index as j">
                        {{sector.sector }}<span [hidden]="j == traineePermission.sectors_in?.length - 1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.sectors_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'sectors_in'"  class="and">and </span>
                    <span class="property-header">Sector is:</span>
                    <div *ngFor="let sector of traineePermission.sectors_in">
                        {{sector.sector }}
                    </div>
                </div>
            </div>

            <div *ngIf="traineePermission.assessors_in" fxFlex>
                <div *ngIf="traineePermission.assessors_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'assessors_in'"  class="and">and </span>
                    <span class="property-header">Assessors in:</span>
                    <div *ngFor="let assessor of traineePermission.assessors_in; index as j">
                        {{assessor.firstname }} {{assessor.lastname }}<span [hidden]="j == traineePermission.assessors_in?.length -1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.assessors_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'assessors_in'"  class="and">and </span>
                    <span class="property-header">Assessor is:</span>
                    <div *ngFor="let assessor of traineePermission.assessors_in">
                        {{assessor.firstname }} {{assessor.lastname }}
                    </div>
                </div>
            </div>


            <div *ngIf="traineePermission.trainees_in" fxFlex>

                <div *ngIf="traineePermission.trainees_in?.length != 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'trainees_in'"  class="and">and </span>
                    <span class="property-header">Trainees in:</span>
                    <div *ngFor="let trainee of traineePermission.trainees_in; index as j">
                        {{trainee.Name }}<span [hidden]="j == traineePermission.trainees_in?.length -1">,</span>
                    </div>
                </div>
                <div *ngIf="traineePermission.trainees_in?.length == 1">
                    <span *ngIf="traineePermission.nameFirstCondition != 'trainees_in'"  class="and">and </span>
                    <span class="property-header">Trainee is:</span>
                    <div *ngFor="let trainee of traineePermission.trainees_in">
                        {{trainee.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div >
        <button mat-flat-button color="primary" class="button-permissions-request" (click)="requestPermission(data.staffData.name)">Update Permission Request</button>
    </div>
</div>
