import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

export interface EmployerWithId {
  employerId: number;
  type: string;
}

@Injectable()
export class EmployerTableService {
  table: Dexie.Table<EmployerWithId, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('employers');
  }

  getAll() {
    return this.table.toArray();
  }

  get(id) {
    return this.table.get(id);
  }

  async where(index, data) {
    const result = await this.table.where(index).equals(data).toArray();
    return result;
  }

  add(data) {
    return this.table.add(data);
  }

  put(data) {
    return this.table.put(data);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  delete(id) {
    return this.table.delete(id);
  }

  clear(){
    return this.table.clear();
  }

  count(){
    return this.table.count();
  }

  last(){
    return this.table.orderBy('id').last();
  }
}
