import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { InfoDialogComponent } from "src/app/shared/components/info-dialog/info-dialog.component";
import { InfoDialogHelper } from "src/app/shared/components/info-dialog/info-dialog.helper";
import { NoteDialogComponent } from "src/app/shared/components/note-dialog/note-dialog.component";
import { DateHelper } from "src/app/shared/date-helper";
import { FromValidators } from "src/app/shared/form-validators";
import { InfoDialog } from "src/app/shared/interfaces/info-dialog";
import { AttendanceAuthorisedService, AbsenceApproved } from "../attendance-authorised.service";

@Component({
  selector: 'app-authorise-dialog',
  templateUrl: './authorise-dialog.component.html',
  styleUrls: ['./authorise-dialog.component.scss']
})
export class AuthoriseDialogComponent implements OnInit {

  name: string;
  count: number;
  dayLimit = 15;
  timeIn = '';
  timeOut = '';
  comment = '';
  timeIncrements: string[] = [];
  dateRange: { startDate: Date, endDate: Date } = { startDate: new Date(), endDate: new Date() };
  authoriseAbsence: FormGroup;//UntypedFormGroup;
  errorMessage = 'Please enter a valid date.';
  dateRangeFC: any;
  timeInFC: any;
  timeOutFC: any;
  savingDialogConfig: MatDialogConfig<InfoDialog>;
  savedDialogConfig: MatDialogConfig<InfoDialog>;
  errorDialogConfig: MatDialogConfig<InfoDialog>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      staffId: number,
      selection: any[]
    },
    private attendanceAuthorisedService: AttendanceAuthorisedService,
    private dialogRef: MatDialogRef<NoteDialogComponent>,
    private formValidators: FromValidators,
    private dialog: MatDialog,
    private infoDialogHelper: InfoDialogHelper,
    private dateHelper: DateHelper
  ) {
    this.authoriseAbsence = new FormGroup({
      dateRange: new FormControl('', { validators: [Validators.required, this.formValidators.exceedsMaxDays(this.dayLimit), this.formValidators.isOverWeekend()], updateOn: 'change' }),
      timeIn: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
      timeOut: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
      comment: new FormControl('', { validators: [Validators.required], updateOn: 'change' })
    });
    this.dateRangeFC = this.authoriseAbsence.controls.dateRange;
    this.timeInFC = this.authoriseAbsence.controls.timeIn;
    this.timeOutFC = this.authoriseAbsence.controls.timeOut;
    this.savingDialogConfig = infoDialogHelper.savingDialogConfig('Saving Authorised Absence', 'If there are multiple absences, it could take a while!');
    this.savedDialogConfig = infoDialogHelper.savedDialogConfig('Saved Successfully!', 'Authorised Absence has been saved successfully!');
    this.errorDialogConfig = infoDialogHelper.errorDialogConfig('Error Saving!', 'Something went wrong! Please try again later.');
  }

  ngOnInit(): void {
    if (this.data) {
      this.name = this.data.selection[0].name;
      this.count = this.data.selection.length;
    }
  }
  onClose(): void {
    this.dialogRef.close("cancel");
  }
  timeInStringOnChange(timeIn) {
    this.timeIn = timeIn.target.value;
    if (this.timeOut !== '') {
      this.timeInFC.setValidators([Validators.required, this.formValidators.timeIsAfterOtherInput(this.timeOut)]);
      this.timeOutFC.setValidators([Validators.required, this.formValidators.timeIsBeforeOtherInput(this.timeIn)]);
      this.timeInFC.updateValueAndValidity();
      this.timeOutFC.updateValueAndValidity();
    }
  }
  timeOutStringOnChange(timeOut) {
    this.timeOut = timeOut.target.value;
    if (this.timeIn !== '') {
      this.timeInFC.setValidators([Validators.required, this.formValidators.timeIsAfterOtherInput(this.timeOut)]);
      this.timeOutFC.setValidators([Validators.required, this.formValidators.timeIsBeforeOtherInput(this.timeIn)]);
      this.timeInFC.updateValueAndValidity();
      this.timeOutFC.updateValueAndValidity();
    }
  }
  onSave(form): void {
    if (this.authoriseAbsence.valid) {
      const savingDialog = this.dialog.open(InfoDialogComponent, this.savingDialogConfig);
      const payload: AbsenceApproved[] = [];
      const days = this.dateHelper.calculateDayDiff(new Date(form.value.dateRange.startDate), new Date(form.value.dateRange.endDate));

      if (days > 0) {
        for (let i = 0; i < days; i++) {
          const date = new Date(Date.UTC(form.value.dateRange.startDate.$y, form.value.dateRange.startDate.$M, form.value.dateRange.startDate.$D + i));
          if (this.dateHelper.isWeekDay(date)) {
            this.data.selection.forEach(
              value => {
                payload.push({
                  traineeId: value.traineeId,
                  pot: value.pot,
                  centreId: value.centreId,
                  assessorId: value.assessorId,
                  timeIn: `${form.value.timeIn}:00`,
                  timeOut: `${form.value.timeOut}:00`,
                  comment: form.value.comment,
                  date: date
                });
              });
          }
        }
      }
      else {
        const date = new Date(Date.UTC(form.value.dateRange.startDate.$y, form.value.dateRange.startDate.$M, form.value.dateRange.startDate.$D));
        if (this.dateHelper.isWeekDay(date)) {
          this.data.selection.forEach(
            value => {
              payload.push({
                traineeId: value.traineeId,
                pot: value.pot,
                centreId: value.centreId,
                assessorId: value.assessorId,
                timeIn: `${form.value.timeIn}:00`,
                timeOut: `${form.value.timeOut}:00`,
                comment: form.value.comment,
                date: date
              });
            });
        }
      }
      this.attendanceAuthorisedService.postAbsenceToAuthorise(this.data.staffId, payload)
        .subscribe({
          next: () => {
            this.infoDialogHelper.successDialog(savingDialog, this.savedDialogConfig, this.dialog, this.dialogRef);
          },
          error: () => {
            this.infoDialogHelper.errorDialog(savingDialog, this.errorDialogConfig, this.dialog);
          }
        });
    }
  }
}
