import { FileListComponent } from '../../core/file-list/file-list.component';
import { FileUploadComponent } from '../../core/file-upload/file-upload.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-employer-attachments',
  templateUrl: './employer-attachments.component.html',
  styleUrls: ['./employer-attachments.component.scss']
})
export class EmployerAttachmentsComponent {

  @Input() vEmployerId: number;

  selectedFiles: FileList;
  progressInfos = [];
  message = '';

  fileInfos: Observable<any>;

  constructor(private dialog: MatDialog) { }

  dndFiles(): void {
    this.openUploadAttachmentDialog();
  }

  viewAttachments(): void {
    this.openViewAttachmentsDialog();
  }

  openUploadAttachmentDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

      dialogConfig.data = {
        isEmployer: true,
        vEmployerId: this.vEmployerId
      }

    this.dialog.open(FileUploadComponent, dialogConfig);
    return false;
  }

  openViewAttachmentsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

      dialogConfig.data = {
        isEmployer: true,
        vEmployerId: this.vEmployerId
      }
      dialogConfig.height = '70%';

    this.dialog.open(FileListComponent, dialogConfig);
    return false;
  }

}
