import { ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';
import { DATE_FORMAT } from 'src/app/app.constants';

export enum State {
  Subcontractor = 4,
  ProgrammeType = 3,
  scheme = 2,
  Learner = 1,
}

export enum Direction {
  up = 1,
  down = -1,
}


@Component({
  selector: 'delivery-funding-predictions',
  templateUrl: './delivery-funding-predictions.component.html',
  styleUrls: ['./delivery-funding-predictions.component.scss']
})
export class DeliveryFundingPredictionsComponent implements OnInit {
  @HostBinding('class') class = 'active-widget';
  groupedBySubcontractor: any;
  groupedByProgrammeType: any;
  groupedByScheme: any;
  learnerFundingData: any;
  state = State;
  direction = Direction;
  currentState = State.Subcontractor;
  subcontractor: string = null;
  programmeType: string = null;
  scheme: string = null;
  staffId: number;

  dataLoaded: boolean = false;
  dataError: boolean = false;

  mainData: any;
  dataSource: any;
  displayedColumns: string[];
  mainFundingColumns = [
    'previousTotalPayment',
    'predictedTotalPayment',
    'predictedNextMonthlyPayment',
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('datePicker') datePicker;

  dateFormat = DATE_FORMAT;

  constructor (
    private cdr: ChangeDetectorRef,
    private sharedFunctions: SharedFunctionsService,
    private homeDashboardService: HomeDashboardService
  ) {}

  ngOnInit (): void {
  }

  initialApiCall () {
    this.homeDashboardService
      .GetDeliveryFundingPredictions(this.staffId)
      .subscribe({
        next: response => {
          this.mainData = response;
          this.groupedBySubcontractor = this.mainData['groupedBySubcontractor'];
          this.groupedByProgrammeType = this.mainData['groupedByProgrammeType'];
          this.groupedByScheme = this.mainData['groupedByScheme'];
          this.learnerFundingData = this.mainData['deliveryFundingPredictions'];
        },
        error: error => {
          console.log(error);
          console.log('Error loading Delivery Funding Predictions: ', error.error);
          this.dataError = true;
          this.dataLoaded = true;

          this.cdr.detectChanges();
        },
        complete: () => {
          this.dataLoaded = true;
          this.cdr.detectChanges();

          this.dataSource = new MatTableDataSource(this.groupedBySubcontractor);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log('Funding predictions: ', this.dataSource);
          this.displayedColumns = [
            'subcontractor',
            ...this.mainFundingColumns
          ];
        }
      });
  }



  newState(change) : number {
    return this.currentState + change < this.state.Learner || this.currentState + change > this.state.Subcontractor
    ? 0 : this.currentState + change;
  }

  changeState(direction: Direction, value: string = null) {
    //console.log('Direction: ', direction, ' Value: ', value);
    const newState = this.newState(direction);
    if(newState !== 0)
    {
      this.currentState = newState;

      switch(this.currentState) {
        case this.state.Subcontractor:
          if(direction === this.direction.up) {
            this.subcontractor = null;
          }
          this.dataSource = new MatTableDataSource(this.groupedBySubcontractor);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.displayedColumns = [
            'subcontractor',
            ...this.mainFundingColumns
          ];
          break;
        case this.state.ProgrammeType:
          if(direction === this.direction.down) {
            this.subcontractor = value;
          } else {
            this.programmeType = null;
          }
          const dataSourceProgramme = this.groupedByProgrammeType.filter(element =>
            element['subcontractor'] === this.subcontractor
          );
          console.log('Data source programme: ', dataSourceProgramme);
          this.dataSource = new MatTableDataSource(dataSourceProgramme);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.displayedColumns = [
            'programmeType',
            ...this.mainFundingColumns
          ];
          break;
          case this.state.scheme:
            if(direction === this.direction.down) {
              this.programmeType = value;
            } else {
              this.scheme = null;
            }
            const dataSourceScheme = this.groupedByScheme.filter(element =>
              element['subcontractor'] === this.subcontractor && element['programmeType'] === this.programmeType
            );
            console.log('Data source scheme: ', dataSourceScheme);
            this.dataSource = new MatTableDataSource(dataSourceScheme);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.displayedColumns = [
              'scheme',
              ...this.mainFundingColumns
            ];
            break;
        case this.state.Learner:
          if(direction === this.direction.down) {
            this.scheme = value;
          }
          const dataSourceLearner = this.learnerFundingData.filter(element =>
            element.scheme === this.scheme
            && element.programmeType === this.programmeType
            && element.subcontractor === this.subcontractor
          );
          this.dataSource = new MatTableDataSource(dataSourceLearner);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.displayedColumns = [
            'traineeId',
            'pot',
            ...this.mainFundingColumns,
            'startDate',
            'expectedEndDate',
            'termDate',
            'estCB',
            'estWB',
            'actCB',
            'actWB',
          ];
          break;
      }

      this.cdr.detectChanges();
    }

    console.log('Data source: ', this.dataSource);
  }

  // (datesUpdated)="dateRangeChange($event)"

  unCamelCase (str: string) {
    return (
      str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        // uppercase the first character
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
    );
  }

  isDate (value, column): boolean {
    return this.sharedFunctions.isDateFromColumnName(value, column);
  }

  getSumForColumn(column: string) : string {
    let sum = 0;
    if(column === 'previousTotalPayment' || column === 'predictedTotalPayment' || column === 'predictedNextMonthlyPayment') {
      this.dataSource.data.forEach(element => {
        sum += element[column];
      });
      return sum.toString();
    } else if (column === 'pot' || column === 'scheme' || column === 'subcontractor' || column === 'programmeType') {
      return 'Total'
    }

    return '';
  }

  headerToolTip(column: string) : string {
    let tooltip = '';
    switch(column) {
      case 'estCB':
        tooltip = 'Estimated Center Based Hours';
        break;
      case 'estWB':
        tooltip = 'Estimated Work Based Hours';
        break;
      case 'actCB':
        tooltip = 'Actual Center Based Hours';
        break;
      case 'actWB':
        tooltip = 'Actual Work Based Hours';
        break;
    }
    return tooltip;
  }

}
