import { Component, Input } from '@angular/core';
import { IWidgetComponent } from './contract-widget-interface';


@Component({
  template: `
    <div class="widget">
      <h4>{{data.headline}}</h4>
      {{data.body}}
    </div>
  `
})
export class ContractWidgetTemplateComponent implements IWidgetComponent {
  @Input() data: any;
}

