import Dexie from 'dexie';
import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import { IProgressSummary } from 'src/app/shared/interfaces/progress-summary';

export interface ProgressWithId extends IProgressSummary {
  id: number;
}

@Injectable()
export class ProgressTableService {
  table: Dexie.Table<ProgressWithId, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('progress');
  }

  get(id) {
    return this.table.get(id);
  }

  getAll(id) {
    return this.table.toArray(id);
  }

  add(data) {
    return this.table.add(data);
  }

  put(data) {
    return this.table.put(data);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }

  clear() {
    return this.table.clear();
  }
}
