import { Router } from '@angular/router';
import { IUserInfo } from '../../shared/interfaces/user-info';
import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

export interface UserInfoWithId extends IUserInfo {
  company: string;
  id: number;
}

@Injectable()
export class UserTableService {
  table: Dexie.Table<UserInfoWithId, number>;

  constructor(
    private localDb: LocalDb) {
    this.table = this.localDb.table('users');
  }

  get(id) {
    return this.table.get(id);
  }

  getAll() {
    return this.table.toArray();
  }

  add(data, id) {
    //console.log(`UserTable add to ${id}`, data);
    return this.table.add(data, id);
  }

  put(data, id) {
    return this.table.put(data, id);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }

  clear() {
    return this.table.clear();
  }

  async getUserInfo() {
    let user: IUserInfo;
    await this.table.get(1).then((data) => {
      user = data;
    });
    return user;
  }
}
