import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { UserTableService } from '../core/database/user-table.service';
import { PermissionService } from '../core/database/permission.service';

@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.scss']
})
export class LogOutComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private userTableService: UserTableService,
    private permissionService: PermissionService,

  ) { }

  ngOnInit() {
    console.log("Remove Token");
    localStorage.removeItem('access_token');
    console.log("Clear user table");
    this.userTableService.clear();
    console.log("Clear permission table");
    this.permissionService.clear();
    console.log("Set auth to false");
    this.authService.setUiAuthenticated(false);
  }

}
