import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { IDefaultDisplayColumns, ISummaryGroupBy } from "src/app/shared/interfaces/generic-interfaces";

@Component({
    selector: 'app-learner-progress-report',
    templateUrl: './learner-progress-report.component.html'
})
export class LearnerProgressReportComponent {
    constructor() {

    }

    name: string = 'Learner Progress Report';
    reportVariant: ReportVariant = ReportVariant.LearnerProgress;
    defaultDisplayColumns: IDefaultDisplayColumns[] = [
        {
            type: 'default',
            columns: ['fullName', 'programmeType', 'sector', 'assessor',
                'startDate', 'expectedCompletionDate', 'mainQualTarget',
                'mainQualActual', 'mainQualStatus', 'frameworkTarget',
                'frameworkActual', 'commTarget', 'commActual', 'commStatus',
                'aonTarget', 'aonActual', 'aonStatus', 'digLitTarget', 'digLitActual',
                'digLitStatus', 'techCertTarget', 'techCertActual', 'techCertStatus']
        }
    ];
    defaultColumnOrder = [
        {
            type: 'default',
            columns: ['fullName', 'programmeType', 'sector', 'assessor',
                'startDate', 'expectedCompletionDate', 'mainQualTarget',
                'mainQualActual', 'mainQualStatus', 'frameworkTarget',
                'frameworkActual', 'commTarget', 'commActual', 'commStatus',
                'aonTarget', 'aonActual', 'aonStatus', 'digLitTarget', 'digLitActual',
                'digLitStatus', 'techCertTarget', 'techCertActual', 'techCertStatus']
        }
    ];
    dateFilters: string[] = [
        'startDate', 'expectedCompletionDate', 'terminationDate'
    ];

    tableCellStyle = (column: string, value: any, row: any): {} => {
        let calc: number = 0;
        switch (column) {
            case 'mainQualActual':
                calc = row['mainQualTarget'] - value;
                if (row['mainQualTarget'] === 0 && value === null) {
                    return {};
                }
                else if (calc <= 5) {
                    return { 'background-color': '#adffcb' };
                }
                else if (calc <= 10) {
                    return { 'background-color': '#fcb549' };
                }
                else if (calc > 10) {
                    return { 'background-color': '#f5a4a4' }
                }
                break;
            case 'frameworkActual':
                calc = row['frameworkTarget'] - value;
                if (row['frameworkTarget'] === 0 && value === null) {
                    return {};
                }
                else if (calc <= 5) {
                    return { 'background-color': '#adffcb' };
                }
                else if (calc <= 10) {
                    return { 'background-color': '#fcb549' };
                }
                else if (calc > 10) {
                    return { 'background-color': '#f5a4a4' }
                }
                break;
            default:
                return {};
        }
    }

}