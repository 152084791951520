import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { map, catchError, Observable, of, from, switchMap } from 'rxjs';
import { LearnerService } from '../learnerService.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserTableService } from 'src/app/core/database/user-table.service';

@Injectable()
export class LearnerTemplateResolverService implements Resolve<any> {

  constructor(
    private learnerService: LearnerService,
    private router: Router,
    private userTableService: UserTableService,
  ) { }

  resolve (route: ActivatedRouteSnapshot): Observable<any> {

    const tabName = route.params['tabName'];
    const traineeId = route.params['traineeId'];
    const pot = route.params['potId'];

    return from(this.userTableService.get(1)).pipe(
      switchMap(user => {
        const staffId = user.staffId;

        return this.learnerService.getLearnerTemplateInfo(traineeId, pot, staffId).pipe(
          map((response : any) => {
            return {
              tabName: tabName,
              traineeId: traineeId,
              pot: pot,
              staffId: staffId,
              templateInfo: response,
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.router.navigate(['/404'], { skipLocationChange: true })
              .then(() => console.log('Error Message:', errorResponse.error));
            return of(null);
          })
        );
      })
    );
  }

}
