<div class="aao-container">

  <div class="table-container">
    <div fxLayout="row wrap" fxLayoutGap="40px grid" style="justify-content: center;">

      <div class="progress-grid-tile activities-tile">
        <div class="progressComponentDiv">
          <activities></activities>
        </div>
      </div>

      <div  class="progress-grid-tile awards-tile">
        <div class="progressComponentDiv">
          <awards></awards>
        </div>
      </div>

      <div class="progress-grid-tile objectives-tile">
        <div class="progressComponentDiv">
          <objectives></objectives>
        </div>
      </div>

    </div>
  </div>
</div>


