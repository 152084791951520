import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-finance-currancy-input',
  templateUrl: './finance-currancy-input.component.html',
  styleUrls: ['./finance-currancy-input.component.scss']
})
export class FinanceCurrancyInputComponent implements OnInit {

  form: UntypedFormGroup;
  @Input() isActive: boolean = false;
  @Input() controlName: string;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control as UntypedFormGroup;
  }

  setDynamicWidth(value) {
    let length = 50;
    if(Math.abs(value) < 10 || value === null || value === undefined) {
      length = 31;
    } else if(Math.abs(value) < 100) {
      length = 40;
    }

    if(value < 0) {
      length = length + 5;
    }

    return `${length}px`;
  }

}
