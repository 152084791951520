import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";

@Component({
  selector: "app-lor-activity-success-report",
  templateUrl: "./lor-activity-success-report.component.html"
})
export class LorActivityReportComponent {
  reportVariant = ReportVariant.LorActivitySuccess;
  reportName = "LOR Activity Success Report";
  highlighting = [
    {
      name: "Excellent",
      columns: ["prov", "act"],
      lowerThreshold: 90,
      hex: "#02c21c",
    },
    {
      name: "Good",
      columns: ["prov", "act"],
      lowerThreshold: 80,
      upperThreshold: 89,
      hex: "#9afc9f",
    },
    {
      name: "Poor",
      columns: ["prov", "act"],
      lowerThreshold: 75,
      upperThreshold: 79,
      hex: "#f7700f",
    },
    {
      name: "Very Poor",
      columns: ["prov", "act"],
      lowerThreshold: 0,
      upperThreshold: 74,
      hex: "#c92020",
    }
  ];
  groupingOptions = [
    {
      name: "Provider",
      icon: "assets/reports/summary-view.svg",
      highlighting: this.highlighting
    },
    {
      name: "Sector",
      icon: "assets/reports/graph-view.svg",
      highlighting: this.highlighting
    },
    {
      name: "Activity",
      icon: "assets/reports/table-view.svg",
      highlighting: this.highlighting
    },
    {
      name: "Assessor",
      icon: "assets/reports/graph-view.svg",
      highlighting: this.highlighting
    }
  ];
  programmeOptions = [
    { name: 'Apprenticeships', columns: ['scheme', 'closed', 'comp', 'cert', 'prov', 'act'], grouping: this.groupingOptions },
    { name: 'Traineeships', columns: ['scheme', 'closed', 'comp', 'cert', 'prov', 'act'], grouping: this.groupingOptions },
    { name: 'Work Ready', columns: ['scheme', 'closed', 'comp', 'cert', 'prov', 'act'], grouping: this.groupingOptions },
    { name: 'Other', columns: ['closed', 'comp', 'cert', 'prov', 'act'], grouping: this.groupingOptions },
    { name: 'JGW', columns: ['closed', 'comp', 'cert', 'prov', 'act'], grouping: this.groupingOptions }];
}
