import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchService, SearchTableService } from '../../search.service';
import { ChangeDetectorRef } from '@angular/core';
import { ERROR_GETTING_DATA } from 'src/app/app.constants';
import { UserTableService } from 'src/app/core/database/user-table.service';
import { ISearchLearnerData } from 'src/app/shared/interfaces/search-learner-data';
import { ISearchEmployerData } from 'src/app/shared/interfaces/search-employer-data';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ISearchData } from 'src/app/shared/interfaces/search-data';

@Component({
  selector: 'app-search-layout',
  templateUrl: './search-layout.component.html',
  styleUrls: ['../search.component.scss'],
})
export class SearchLayoutComponent implements OnInit {

  constructor(
    private searchService: SearchService,
    private searchTableService: SearchTableService,
    private cdf: ChangeDetectorRef,
    private userTableService: UserTableService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  @Output() submittedData = new EventEmitter<ISearchData>();
  @Output() sideNavChange = new EventEmitter<boolean>();

  @Input() type: string;
  @Input() categories: { label: string, value: string }[] = [];
  @Input() isLoadingSearch = false;
  @Input() filterOptions: { [key: string]: { name: string, id: number }[] } = {};
  @Input() searchForm;
  @Input() filterForm;
  @Input() totalCount: number = null;
  @Input() sideNavOpen = true;
  @Input() applyFiltersButton = false;
  public dataError = ERROR_GETTING_DATA;
  public filterError = false;
  public isLoadingFilterOptions = true;
  private staffId = 0;

  ngOnInit(): void {
    this.userTableService.get(1).then(result => {
      this.staffId = result.staffId;

      this.checkForStoredSearch();

      const subscription = this.searchService.filterOptions(this.type, this.staffId).subscribe({
        next: (results: any) => {
          this.filterOptions = results.data;
        },
        error: (err: any) => {
          console.log(err);
          this.isLoadingFilterOptions = false;
          this.filterError = true;
          subscription.unsubscribe();
          this.cdf.detectChanges();
        },
        complete: () => {
          this.isLoadingFilterOptions = false;
          subscription.unsubscribe();
          this.cdf.detectChanges();
        }
      });

    });

    this.filterForm.valueChanges.subscribe(() => {
      this.applyFiltersButton = true;
    });
  }

  private checkForStoredSearch() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe({
      next: (params: any) => {
        if (params.search) {
          // Get the stored search and apply it
          this.searchTableService.getStoredParams(this.type).then((result: any) => {
            console.log('search', result);
            switch (this.type) {
              case 'learner':
                const learnerData: ISearchLearnerData = JSON.parse(result);

                //set the params to the form
                this.searchForm.get('term').setValue(learnerData.term);
                this.searchForm.get('category').setValue(learnerData.category);
                this.filterForm.get('subcontractors').setValue(learnerData.filters.subcontractors);
                this.filterForm.get('programmeTypes').setValue(learnerData.filters.programmeTypes);
                this.filterForm.get('centres').setValue(learnerData.filters.centres);
                this.filterForm.get('assessors').setValue(learnerData.filters.assessors);
                this.filterForm.get('sectors').setValue(learnerData.filters.sectors);
                this.filterForm.get('schemes').setValue(learnerData.filters.schemes);
                this.filterForm.get('activeOnly').setValue(learnerData.filters.activeOnly);
                break;
              case 'employer':
                const employerData: ISearchEmployerData = JSON.parse(result);

                this.searchForm.get('term').setValue(employerData.term);
                this.searchForm.get('category').setValue(employerData.category);
                this.filterForm.get('accountManagers').setValue(employerData.filters.accountManagers);
                this.filterForm.get('accountTypes').setValue(employerData.filters.accountTypes);
                this.filterForm.get('activeOnly').setValue(employerData.filters.activeOnly);
                break;
            }

            this.cdf.detectChanges();
            this.onSubmit();
          });
        }
      },
      error: (err: any) => {
        console.log(err);
      },
    });
  }

  public onSubmit() {
    this.submittedData.emit(this.getSearchData());
    this.applyFiltersButton = false;
  }

  private getSearchData(): ISearchData {
    const search = this.searchForm.getRawValue();
    const filtersRaw = this.filterForm.getRawValue();

    const filters = {};
    for (const prop in this.filterOptions) {
      filters[prop] = filtersRaw[prop];
    }
    //This is standard for all searches
    filters['activeOnly'] = filtersRaw.activeOnly;
    return {
      type: this.type,
      term: search.term.trim(),
      category: search.category,
      staffId: this.staffId,
      filters: filters,
      paginationSkip: 0,
      paginationTake: 0,
    }
  }

  generateMoreTooltipText(items: any[]): string {
    return items.slice(4).map(item => item.name).join(', ');
  }

  ngSelectOpenOrClose(select: NgSelectComponent) {
    select.searchable = select.isOpen;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  clearFilters() {
    this.filterForm.reset();
    this.filterForm.get('activeOnly').setValue(true);
  }

  onSidenavOpenedChange(value) {
    this.sideNavChange.emit(value);
  }
}
