import { DatePipe } from '@angular/common';
import { SharedFunctionsService } from './../../core/services/shared-functions.service';
import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Chart from 'chart.js';
import * as Highcharts from 'highcharts';
import  More from 'highcharts/highcharts-more';
More(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
import { IGenericSummaryTableData } from 'src/app/shared/interfaces/generic-interfaces';
import { any } from 'underscore';
import { DATE_FORMAT } from 'src/app/app.constants';

// Initialize exporting module.
Exporting(Highcharts);

// with drilldown
export interface iPieChartEntryWithDrilldown {
  name: string;
  y: number;
  drilldown: string;
  id: string;
}

// only if bottom level of drilldown
export interface iPieChartDrilldownSeriesEntry {
  name: string;
  id: string;
  data: any[];
  type: string;
}


@Component({
  selector: 'app-report-summary-graph',
  templateUrl: './report-summary-graph.component.html',
  styleUrls: ['./report-summary-graph.component.scss']
})
export class ReportSummaryGraphComponent implements OnInit {

  dateFormat = DATE_FORMAT;

  highcharts = Highcharts;
  isShowPieChart: boolean = true;
  updateFlag: boolean = false;
  drillDownColumnTitle: string = "schemes"

  chartOptions = {
      chart : {
        // height: "100%",
         plotBorderWidth: null,
         plotShadow: false,
         events: {
          drilldown: function(e) {
            var segmentClicked = e.point.name
            console.log(segmentClicked);
          },
          drillup: function (e) {
          }
        }
      },
      title : {
         text: 'Grouped by: ',
         align: 'left'
      },
      subtitle: {
        text: '',
        align: 'left'
      },
      legend:     {
          align: 'right',
          verticalAlign: 'middle',
        },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<b>{point.name}</b>: ({point.y}) <b>{point.percentage:.1f}%</b> of total<br/>'
      },
      plotOptions : {
         pie: {
            allowPointSelect: true,
            cursor: 'pointer',

            dataLabels: {
               enabled: false
            },

            showInLegend: true
         }
      },
      series : [],
      drilldown: {series: [], drillUpButton : {relativeTo: 'spacingBox',
      position: {
          y: 10,
          x: -45
      }}}

   };

   isDrilleddownInPieChart: boolean = false;

  resetPieChart(){
    this.isShowPieChart = false;
    this.cdr.detectChanges();
    this.isShowPieChart = true;
    this.chartOptions.subtitle.text = ""
   }

   drilldownClicked(segmentClicked){
     this.chartOptions.subtitle.text = segmentClicked + " " + this.drillDownColumnTitle
     this.updateFlag = true;
     this.cdr.detectChanges()
     var snackBarMessage = "Showing " + this.drillDownColumnTitle + " for " + segmentClicked
     this.snackBar.open(snackBarMessage,
       'Close', { duration: 3000 });
     this.isDrilleddownInPieChart = true;
   }

   drillupClicked(){
     this.chartOptions.subtitle.text = ""
     this.updateFlag = true;
     this.cdr.detectChanges()
     this.isDrilleddownInPieChart = false;
   }

  constructor(
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    private sharedFunctions: SharedFunctionsService) { }

  ngOnInit(): void {
  }

  setPieChartSummaryData(dataSource2Data: any[], groupByColumn: string, summaryColumnTitles : string[] = [], summaryOn = ""){

    //if(this.isDrilleddownInPieChart){

      this.resetPieChart();
  //  }

    const groupByColumnUserFriendly = this.unCamelCase(groupByColumn)
    this.chartOptions.title.text = groupByColumnUserFriendly + " Share"

    if(summaryColumnTitles.length > 0 && summaryColumnTitles[2] !== "Count"){
      this.chartOptions.title.text = groupByColumnUserFriendly + " Share by " + summaryColumnTitles[2]
    }
    var indexSelected = 3
    if(summaryOn != ""){
      this.chartOptions.title.text = groupByColumnUserFriendly + " Share by " + summaryOn
      var indexSelected = summaryColumnTitles.indexOf(summaryOn) + 1
      console.log("index selected", indexSelected)
    }
    var propertyName = "column" + indexSelected

    if(summaryColumnTitles.length > 0 && summaryColumnTitles[1] != "Scheme"){
      this.drillDownColumnTitle = summaryColumnTitles[1].toLowerCase() + "s"
    }

    var dataSet = []
    const pieChartEntries = [...new Set(dataSource2Data.map(item => item.column1))];
    console.log("unique", pieChartEntries)
    let pieChartDrilldownSeries : iPieChartDrilldownSeriesEntry[] = []
    pieChartEntries.forEach(p => {
      var dilldownDataSet = []
      let sum: number = 0;
      dataSource2Data.forEach(a => {
        if(a.column1 == p) {
          var propertyValue = this.replaceAll(a[propertyName], ",", "")
          propertyValue = this.replaceAll(propertyValue, "%", "")
          sum += +propertyValue
          dilldownDataSet.push([a.column2, +propertyValue])
        }

      });

      const drilldownid =  p + "-" + this.drillDownColumnTitle
      const pieChartEntry : iPieChartEntryWithDrilldown = {
        name: p,
        y: sum,
        drilldown: drilldownid,
        id: p

      }
      dataSet.push(pieChartEntry)

      let entry : iPieChartDrilldownSeriesEntry = {
        type: 'pie',
        name: p + " " + this.drillDownColumnTitle,
        id: drilldownid,
        data: dilldownDataSet
      }
      pieChartDrilldownSeries.push(entry);
     // dataSet.push([p, sum])
    });
    console.log("data", dataSet)
    this.chartOptions.series =  [{
      type: 'pie',
      name: groupByColumnUserFriendly + ' share',
      data: dataSet

   }]

   var a = this;
   this.chartOptions.chart = {
       plotBorderWidth: null,
       plotShadow: false,
       events: {
        drilldown: function(e) {
          var segmentClicked = e.point.name
          console.log(segmentClicked);
          //this.chartOptions.title.text = segmentClicked
          a.drilldownClicked(segmentClicked);
        },
        drillup: function (e) {
          a.drillupClicked();
        }
      }
    },

   this.chartOptions.drilldown.series = pieChartDrilldownSeries
  //  this.chartOptions.drilldown.drillUpButton = {relativeTo: 'spacingBox',
  //  position: {
  //      y: 10,
  //      x: 0
  //  }}


   console.log("chart options set", this.chartOptions.series);
   this.updateFlag = true;
   this.cdr.detectChanges();
  }


  setPieChartSummaryDataForBasicReport(dataSource2Data: any[], groupByColumn: any){
    this.resetPieChart();
    const datepipe: DatePipe = new DatePipe('en-GB');

    const groupByColumnUserFriendly = this.unCamelCase(groupByColumn.value);
    const shareByColumnUserFriendly = this.unCamelCase(groupByColumn.pieChartShareValue);
    this.drillDownColumnTitle = this.unCamelCase(groupByColumn.pieChartDrillDown).toLowerCase() + "s";
    this.chartOptions.title.text = groupByColumnUserFriendly + " Share By " + shareByColumnUserFriendly;

    var dataSet = [];
    const pieChartEntries = [...new Set(dataSource2Data.map(item => item[groupByColumn.value]))];
    let pieChartDrilldownSeries : iPieChartDrilldownSeriesEntry[] = [];
    pieChartEntries.forEach(p => {
      var dilldownDataSet = []
      let sum: number = 0;
      dataSource2Data.forEach(a => {
        if(a[groupByColumn.value] === p){
          var propertyValue = this.replaceAll(a[groupByColumn.pieChartShareValue], ",", "")
          propertyValue = this.replaceAll(propertyValue, "%", "")
          sum += +propertyValue
          dilldownDataSet.push([
            this.sharedFunctions.isDateFromColumnName(a[groupByColumn.pieChartDrillDown], groupByColumn.pieChartDrillDown)
            ? datepipe.transform(a[groupByColumn.pieChartDrillDown], this.dateFormat) : a[groupByColumn.pieChartDrillDown],
            +propertyValue
          ])
        }
      });

      const drilldownid =  p + "-" + this.drillDownColumnTitle
      const pieChartEntry : iPieChartEntryWithDrilldown = {
        name: p,
        y: sum,
        drilldown: drilldownid,
        id: p

      }
      dataSet.push(pieChartEntry)

      let entry : iPieChartDrilldownSeriesEntry = {
        type: 'pie',
        name: p + " " + this.drillDownColumnTitle,
        id: drilldownid,
        data: dilldownDataSet
      }
      pieChartDrilldownSeries.push(entry);
    });
    this.chartOptions.series =  [{
      type: 'pie',
      name: groupByColumnUserFriendly + ' share by ' + shareByColumnUserFriendly,
      data: dataSet

   }]

   var a = this;
   this.chartOptions.chart = {
       plotBorderWidth: null,
       plotShadow: false,
       events: {
        drilldown: function(e) {
          var segmentClicked = e.point.name
          a.drilldownClicked(segmentClicked);
        },
        drillup: function (e) {
          a.drillupClicked();
        }
      }
    },

   this.chartOptions.drilldown.series = pieChartDrilldownSeries

   this.updateFlag = true;
   this.cdr.detectChanges();
  }

  replaceAll(str, find, replace) {
    return str.toString().replace(new RegExp(find, 'g'), replace);
  }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }

}
