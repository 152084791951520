import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserTableService } from '../core/database/user-table.service';
import { BdEventsComponent } from './bd-events/bd-events.component';
import { SitePermissions } from 'src/app/shared/enums/permissions';

@Component({
  selector: 'app-bd-homepage',
  templateUrl: './bd-homepage.component.html',
  styleUrls: ['./bd-homepage.component.scss']
})
export class BdHomepageComponent implements OnInit {

  @ViewChild(BdEventsComponent) bdEventsComponent!: BdEventsComponent;

  constructor(
    private userTableService: UserTableService,
    private router: Router
  ) { }

  eventPermissions: { canEditEvent: boolean, canDeleteEvent: boolean } =
    {
      canEditEvent: false,
      canDeleteEvent: false,
    };

  ngOnInit(): void {
    this.userTableService.get(1).then(user => {
      if (!user || user == undefined || user == null) {
        this.router.navigate(['/']);
        return;
      }

      if(!user.permission.includes(SitePermissions.BdHomepageView))
      {
        this.router.navigate(['/']);
        return;
      }

      this.eventPermissions.canEditEvent = user.permission.includes(SitePermissions.EmployerEventEdit);
      this.eventPermissions.canDeleteEvent = user.permission.includes(SitePermissions.EmployerEventDelete);

      this.bdEventsComponent.setUp(user.staffId, this.eventPermissions);
    });

  }

}
