import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { HighchartsChartModule } from 'highcharts-angular';
import { SidebarModule } from 'ng-sidebar';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatTableResponsiveModule } from '../core/directives/mat-table-responsive.module';
import { MaterialsModule } from '../core/materials/materials.module';
import { AwardsNoActivitiesReportComponentV2 } from './awards-no-activities-report/awards-no-activities-report.component';
import { ClassicReportComponent } from './classic-report/classic-report.component';
import { GuidedContactHoursReportComponentV2 } from './guided-contact-hours-report/guided-contact-hours-report.component';
import { IqaProfilesReportComponentV2 } from './iqa-profiles-report/iqa-profiles-report.component';
import { OccupancyReportComponentV2 } from './occupancy-report/occupancy-report.component';
import { ReportStoredFilterHelperServiceV2 } from './report-stored-filter-helper.service';
import { ReportSummaryGraphComponentV2 } from './report-summary-graph/report-summary-graph.component';
import { ReportsV2Service } from './reports.service';
import { SaveReportFiltersDialogComponentV2 } from './save-report-filters-dialog/save-report-filters-dialog.component';
import { ShareReportFiltersDialogComponentV2 } from './share-report-filters-dialog/share-report-filters-dialog.component';
import { SharedFiltersComponentV2 } from './shared-filters/shared-filters.component';
import { ShowSavedReportFiltersDialogComponentV2 } from './show-saved-report-filters-dialog/show-saved-report-filters-dialog.component';
import { ActivitiesReportComponentV2 } from './activities-report/activities-report.component';
import { DigitalApplicationsReportComponentV2 } from './digital-applications-report/digital-applications-report.component';
import { JGWLearnerAwaitingValidationComponentV2 } from './jgw-learner-awaiting-validation-report/jgw-learner-awaiting-validation-report.component';
import { JGWLearnerReferralsComponentV2 } from './jgw-learner-referrals-report/jgw-learner-referrals-report.component';
import { JGWLearnerNotificationsComponentV2 } from './jgw-learner-notifications-report/jgw-learner-notifications-report.component';
import { JGWLearnerAgreedStartComponentV2 } from './jgw-learner-agreed-start-report/jgw-learner-agreed-start-report.component';
import { TraineeVisionEventsReportComponentV2 } from './trainee-vision-events-report/trainee-vision-events-report.component';
import { ReviewAccuracyReportComponentV2 } from './review-accuracy-report/review-accuracy-report.component';
import { TraineeStatusChangeReportComponentV2 } from './trainee-status-change-report/trainee-status-change-report.component';
import { ReviewAnalysisReportComponentV2 } from './review-analysis-report/review-analysis-report.component';
import { ActivitiesNoAwardsReportComponentV2 } from './activities-no-awards-report/activities-no-awards-report.component';
import { AttendanceSummaryReportComponentV2 } from './attendance-summary-report/attendance-summary-report.component';
import { SignInHistoryComponentV2 } from './sign-in-history/sign-in-history.component';
import { OnefileReviewsReportComponentV2 } from './onefile-reviews-report/onefile-reviews-report.component';
import { TermsReportComponentV2 } from './terms-report/terms-report.component';
import { RegistrationSummaryReportComponentV2 } from './registration-summary-report/registration-summary-report.component';
import { OutstandingReturnedCertificatesReportComponentV2 } from './outstanding-returned-certificates-report/outstanding-returned-certificates-report.component';
import { TraineeEssentialSkillsReportComponentV2 } from './trainee-essential-skills-report/trainee-essential-skills-report.component';
import { AttendanceSignedOutReportComponentV2 } from './attendance-signed-out-report/attendance-signed-out-report.component';
import { ApprenticeshipProvisionalAchieversReportComponentV2 } from './apprenticeship-provisional-achievers-report/apprenticeship-provisional-achievers-report.component';
import { StartsReportComponentV2 } from './starts-report/starts-report.component';
import { CertificatesClaimedAndReceivedReportComponentV2 } from './certificates-claimed-and-received-report/certificates-claimed-and-received-report.component';
import { ReviewsRequiredReportComponentV2 } from './reviews-required-report/reviews-required-report.component';
import { ReviewListReportComponentV2 } from './review-list-report/review-list-report.component';
import { OnefileIalpsReportComponentV2 } from './onefile-ialps-report/onefile-ialps-report.component';
import { CertificatesToBeClaimedReportComponentV2 } from './certificates-to-be-claimed-report/certificates-to-be-claimed-report.component';
import { ProfiledAchieversReportComponentV2 } from './profiled-achievers-report/profiled-achievers-report.component';
import { AttendanceSignedInReportComponentV2 } from './attendance-signed-in-report/attendance-signed-in-report.component';
import { UnregisteredLearnersEightAndTwelveWeeksReportComponentV2 } from './unregistered-learners-eight-and-twelve-weeks-report/unregistered-learners-eight-and-twelve-weeks-report.component';
import { OutstandingRegistrationRequestsReportComponentV2 } from './outstanding-registration-requests-report/outstanding-registration-requests-report.component';
import { PlannedVisitsReportComponentV2 } from './planned-visits-report/planned-visits-report.component';
import { AwaitingIqaReportComponentV2 } from './awaiting-iqa-report/awaiting-iqa-report.component';
import { HealthAndSafetyEmployerReportComponentV2 } from './health-and-safety-employer-report/health-and-safety-employer-report.component';
import { SuspensionsReportComponentV2 } from './suspensions-report/suspensions-report.component';
import { MostRecentSignInReportComponentV2 } from './most-recent-sign-in-report/most-recent-sign-in-report.component';
import { ReviewAlnReportComponent } from './review-aln-report/review-aln-report.component';
import { WellbeingReportComponentV2 } from './wellbeing-report/wellbeing-report.component';
import { LearnerProgressReportComponent } from './learner-progress-report/learner-progress-report.component';
import { EsqOccupancyReportComponent } from './esq-occupancy-report/esq-occupancy-report.component';
import { EarlyLeaversReportComponent } from './early-leavers-report/early-leavers-report.component';
import { EightWeekTermReportComponent } from './eight-week-term-report/eight-week-term-report.component';
import { LorActivityReportComponent } from './lor-activity-success-report/lor-activity-success-report.component';
import { LorFrameworkReportComponent } from './lor-framework-success-report/lor-framework-success-report.component';
import { LorPredictedFrameworkReportComponent } from './lor-predicted-framework-success-report/lor-predicted-framework-success-report.component';
import { LorProgressionReportComponent } from './lor-progression-success-report/lor-progression-success-report.component';
import { LorReportComponent } from './lor-report/lor-report.component';
import { OnefileAssessmentReportComponent } from './onefile-assessment-report/onefile-assessment-report';
import { OnefileAssessmentPlanReportComponent } from './onefile-assessment-plan-report/onefile-assessment-plan-report';
import { OnefileAssessmentPendingReportComponent } from './onefile-assessment-pending-report/onefile-assessment-pending-report';
import { EsqMarkingAndIqaReportComponent } from './esq-marking-and-iqa-report/esq-marking-and-iqa-report.component';
import { WorkshopAvailableReportComponent } from './workshop-available-report/workshop-availablereport.component';
import { WorkshopBookingReportComponent } from './workshop-booking-report/workshop-booking-report.component';
import { DrillDownReportComponent } from './drill-down-report/drill-down-report.component';
import { TraineeObjectiveListReportComponent } from './trainee-objective-list-report/trainee-objective-list-report.component';
import { UnopenedActivitiesReportComponent } from './unopened-activities-report/unopened-activities-report.component';
import { RecentVisitsReportComponent } from './recent-visits-report/recent-visits-report.component';
import { CancelledAppointmentsReportComponent } from './cancelled-appointments-report/cancelled-appointments-report.component';
import { SuspensionSubmissionReportComponent } from './suspension-submission-report/suspension-submission-report.component';
import { RetrospectiveOccupancyComponent } from './retrospective-occupancy/retrospective-occupancy.component';

@NgModule({
  declarations: [
    OccupancyReportComponentV2,
    GuidedContactHoursReportComponentV2,
    ClassicReportComponent,
    ShareReportFiltersDialogComponentV2,
    SaveReportFiltersDialogComponentV2,
    ShowSavedReportFiltersDialogComponentV2,
    SharedFiltersComponentV2,
    ReportSummaryGraphComponentV2,
    AwardsNoActivitiesReportComponentV2,
    IqaProfilesReportComponentV2,
    ActivitiesReportComponentV2,
    DigitalApplicationsReportComponentV2,
    JGWLearnerAwaitingValidationComponentV2,
    JGWLearnerReferralsComponentV2,
    JGWLearnerNotificationsComponentV2,
    JGWLearnerAgreedStartComponentV2,
    TraineeVisionEventsReportComponentV2,
    ReviewAccuracyReportComponentV2,
    TraineeStatusChangeReportComponentV2,
    ReviewAnalysisReportComponentV2,
    ActivitiesNoAwardsReportComponentV2,
    AttendanceSummaryReportComponentV2,
    SignInHistoryComponentV2,
    OnefileReviewsReportComponentV2,
    TermsReportComponentV2,
    RegistrationSummaryReportComponentV2,
    OutstandingReturnedCertificatesReportComponentV2,
    TraineeEssentialSkillsReportComponentV2,
    AttendanceSignedOutReportComponentV2,
    ApprenticeshipProvisionalAchieversReportComponentV2,
    StartsReportComponentV2,
    CertificatesClaimedAndReceivedReportComponentV2,
    ReviewsRequiredReportComponentV2,
    ReviewListReportComponentV2,
    OnefileIalpsReportComponentV2,
    CertificatesToBeClaimedReportComponentV2,
    ProfiledAchieversReportComponentV2,
    AttendanceSignedInReportComponentV2,
    UnregisteredLearnersEightAndTwelveWeeksReportComponentV2,
    OutstandingRegistrationRequestsReportComponentV2,
    PlannedVisitsReportComponentV2,
    AwaitingIqaReportComponentV2,
    HealthAndSafetyEmployerReportComponentV2,
    SuspensionsReportComponentV2,
    MostRecentSignInReportComponentV2,
    ReviewAlnReportComponent,
    WellbeingReportComponentV2,
    LearnerProgressReportComponent,
    EsqOccupancyReportComponent,
    EarlyLeaversReportComponent,
    EightWeekTermReportComponent,
    LorActivityReportComponent,
    LorFrameworkReportComponent,
    LorPredictedFrameworkReportComponent,
    LorProgressionReportComponent,
    LorReportComponent,
    OnefileAssessmentReportComponent,
    OnefileAssessmentPlanReportComponent,
    OnefileAssessmentPendingReportComponent,
    EsqMarkingAndIqaReportComponent,
    WorkshopAvailableReportComponent,
    WorkshopBookingReportComponent,
    DrillDownReportComponent,
    TraineeObjectiveListReportComponent,
    UnopenedActivitiesReportComponent,
    RecentVisitsReportComponent,
    CancelledAppointmentsReportComponent,
    SuspensionSubmissionReportComponent,
    RetrospectiveOccupancyComponent
  ],
  imports: [
    MatProgressBarModule,
    MatTableResponsiveModule,
    CommonModule,
    MaterialsModule,
    NgxGraphModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd,
    SidebarModule.forRoot(),
    NgSelectModule,
    HighchartsChartModule,
    MatMenuModule,
    RouterModule
  ],
  exports: [
    OccupancyReportComponentV2,
    GuidedContactHoursReportComponentV2,
    ClassicReportComponent,
    AwardsNoActivitiesReportComponentV2,
    IqaProfilesReportComponentV2,
    ActivitiesReportComponentV2,
    DigitalApplicationsReportComponentV2,
    JGWLearnerAwaitingValidationComponentV2,
    JGWLearnerReferralsComponentV2,
    JGWLearnerNotificationsComponentV2,
    JGWLearnerAgreedStartComponentV2,
    TraineeVisionEventsReportComponentV2,
    ReviewAccuracyReportComponentV2,
    TraineeStatusChangeReportComponentV2,
    ReviewAnalysisReportComponentV2,
    ActivitiesNoAwardsReportComponentV2,
    AttendanceSummaryReportComponentV2,
    SignInHistoryComponentV2,
    OnefileReviewsReportComponentV2,
    TermsReportComponentV2,
    RegistrationSummaryReportComponentV2,
    OutstandingReturnedCertificatesReportComponentV2,
    TraineeEssentialSkillsReportComponentV2,
    AttendanceSignedOutReportComponentV2,
    ApprenticeshipProvisionalAchieversReportComponentV2,
    StartsReportComponentV2,
    CertificatesClaimedAndReceivedReportComponentV2,
    ReviewsRequiredReportComponentV2,
    ReviewListReportComponentV2,
    OnefileIalpsReportComponentV2,
    CertificatesToBeClaimedReportComponentV2,
    ProfiledAchieversReportComponentV2,
    AttendanceSignedInReportComponentV2,
    UnregisteredLearnersEightAndTwelveWeeksReportComponentV2,
    OutstandingRegistrationRequestsReportComponentV2,
    PlannedVisitsReportComponentV2,
    AwaitingIqaReportComponentV2,
    HealthAndSafetyEmployerReportComponentV2,
    SuspensionsReportComponentV2,
    MostRecentSignInReportComponentV2,
    ReviewAlnReportComponent,
    WellbeingReportComponentV2,
    LearnerProgressReportComponent,
    EsqOccupancyReportComponent,
    LorActivityReportComponent,
    LorFrameworkReportComponent,
    LorPredictedFrameworkReportComponent,
    LorProgressionReportComponent,
    LorReportComponent,
    OnefileAssessmentReportComponent,
    OnefileAssessmentPlanReportComponent,
    OnefileAssessmentPendingReportComponent,
    EsqMarkingAndIqaReportComponent,
    WorkshopAvailableReportComponent,
    WorkshopBookingReportComponent,
    DrillDownReportComponent,
    TraineeObjectiveListReportComponent,
    UnopenedActivitiesReportComponent,
    RecentVisitsReportComponent,
    CancelledAppointmentsReportComponent,
    SuspensionSubmissionReportComponent
  ],
  entryComponents: [SaveReportFiltersDialogComponentV2, ShareReportFiltersDialogComponentV2],
  providers: [ReportStoredFilterHelperServiceV2, ReportsV2Service, DecimalPipe]
})

export class ReportingModuleV2 { }
