import { NgModule } from "@angular/core";
import { MaterialsModule } from "../core/materials/materials.module";
import { RouterModule } from "@angular/router";
import { SharedModule } from '../shared/shared.module';
import { BdHomepageComponent } from './bd-homepage.component';
import { BdEventsComponent } from './bd-events/bd-events.component';

@NgModule({
  declarations: [
    BdHomepageComponent,
    BdEventsComponent
  ],
  imports: [
    MaterialsModule,
    RouterModule,
    SharedModule
  ],
  providers: [

  ]
})

export class BdHomepageModule { }
