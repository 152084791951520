<article class="mat-card-container">
  <mat-card class="dashboard-card">
    <div style="display: flex; justify-content: flex-end; gap: 8px; position: absolute; right: 0; padding-right: 25px;">
      <button type="button" class="icon-buttons" (click)="toggleEdit()" *ngIf="editPermissionLevel < 2" [attr.aria-label]="editMode ? 'Save' : 'Edit' ">
        <mat-icon class="uid-icon" >{{editOrSave}}</mat-icon>
      </button>
      <button type="button" class="icon-buttons" (click)="cancelEdit()" *ngIf="editMode" aria-label="Cancel">
        <mat-icon class="uid-icon">highlight_off</mat-icon>
      </button>
    </div>

    <section>
      <learner-details-entry [labelName]="'Trainee ID'" [labelValue]="learnerDetails.learnerBasics.traineeId">
      </learner-details-entry>
    </section>
    <section>
      <learner-details-entry [labelName]="'LN02'" [labelValue]="learnerDetails.learnerUniqueIdentification.lN02">
      </learner-details-entry>
    </section>
    <section>
      <learner-details-entry [labelName]="'ULI'" [labelValue]="learnerDetails.learnerUniqueIdentification.uli">
      </learner-details-entry>
    </section>
    <section>
      <learner-details-entry [labelName]="'ULN'" [labelValue]="learnerDetails.learnerUniqueIdentification.uln">
      </learner-details-entry>
    </section>
    <section>
      <learner-details-entry [labelName]="'NI'" [labelValue]="learnerDetails.learnerUniqueIdentification.ni">
      </learner-details-entry>
    </section>
    <section [formGroup]="uniqueIdForm">
      <div *ngIf="editPermissionLevel === 1">
        <div style="margin-bottom: 5px;">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Provisional West username: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <input matInput formControlName="provisionalWestUsername" >
          </mat-form-field>
        </div>
        <div>
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
            [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Provisional West password: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <input matInput formControlName="provisionalWestPassword" >
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry
          [labelName]="'Provisional West username'"
          [labelValue]="this.learnerDetails.learnerUniqueIdentification.provisionalWestUserName ? this.learnerDetails.learnerUniqueIdentification.provisionalWestUserName : '-' ">
        </learner-details-entry>
        <learner-details-entry
          [labelName]="'Provisional West password'"
          [labelValue]="this.learnerDetails.learnerUniqueIdentification.provisionalWestPassword ? this.learnerDetails.learnerUniqueIdentification.provisionalWestPassword : '-' ">
        </learner-details-entry>
      </div>
    </section>
  </mat-card>
</article>
