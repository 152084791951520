import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ERROR_GETTING_DATA, NO_DATA } from 'src/app/app.constants';
import { EventModalMode } from '../../enums/event-modal-mode';
import { EventDisplayType } from '../../enums/event-display-type';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss']
})
export class EventsPageComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() openEventForm = new EventEmitter<{ mode: EventModalMode, event: any | null }>();
  @Output() pageEvent = new EventEmitter<PageEvent>();
  @Input() events: any[];
  @Input() draftEvents: any[];
  @Input() eventPagination: { page: number, total: number };
  @Input() permissions: { canCreate: boolean } = { canCreate: false };
  @Input() loading = true;
  @Input() topGap = 0;
  @Input() eventDisplayType: EventDisplayType = EventDisplayType.learner;
  @Input() totalEvents = 0;
  @Input() eventsError = false;


  noData = NO_DATA;
  dataError = ERROR_GETTING_DATA;
  eventModalMode = EventModalMode;
  eventToHighlight = null;

  textExpand = false;

  toggleTextExpand() {
    this.textExpand = !this.textExpand;
  }

  handleOpenEvent(mode: EventModalMode, event: any = null) {
    this.openEventForm.emit({ mode, event });
  }

  handlePageEvent(event: PageEvent) {
    this.pageEvent.emit(event);
  }

}
