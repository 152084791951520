
<article *ngIf="show"
  class="main-article"
  fxLayout="row wrap"
  style="padding-left: 25px;"
  [formGroup]="currentEmploymentForm">

  <section class="section-container" fxLayout="row wrap" fxLayoutGap="100px">

    <section class="section-container" fxLayout="column" fxLayoutGap="15px" style="min-width: 285px">
      <h3 class="dashboard_headers">Sign up Details</h3>

      <!-- Job Title -->
      <section>
        <!-- <div *ngIf="editPermissionLevel === 1">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Job title: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <input matInput formControlName="jobtitle">
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1"> -->
          <!-- Not editable as its DtVPotsignupDetails -->
          <learner-details-entry
          [labelName]="'Job title'"
          [labelValue]="currentEmployment.jobTitle"
          [inline]="true">
          </learner-details-entry>
        <!-- </div> -->
      </section>

      <!-- Start Date -->
      <learner-details-entry
        [labelName]="'Start date'"
        [labelValue]="formattedStartDate"
        [inline]="true">
      </learner-details-entry>

      <!-- End Date -->
      <learner-details-entry
        [labelName]="'End date'"
        [labelValue]="formattedEndDate"
        [inline]="true">
      </learner-details-entry>

      <!-- Date Probation Completed -->
      <learner-details-entry *ngIf="currentEmployment.dateProbationCompleted"
        [labelName]="'Date probation completed'"
        [labelValue]="currentEmployment.dateProbationCompleted | date: this.dateFormat"
        [inline]="true">
      </learner-details-entry>

      <!-- Place of Work -->
      <learner-details-entry *ngIf="currentEmployment.placeOfWork"
        [labelName]="'Place of work'"
        [labelValue]="currentEmployment.placeOfWork"
        [inline]="true">
      </learner-details-entry>

      <!-- Long Contract -->
      <div class="bm-15">
        <label
          style="font-weight: 500; vertical-align: middle;"
          [style.color]="editMode && editPermissionLevel === 1 ? 'darkred' : '#000000'"
        >
          Long contract
        </label>
        <mat-checkbox *ngIf="editPermissionLevel === 1 && editMode; else contractImage"
          style="margin-left: 5px;"
          [style.color]="editMode ? 'darkred' : '#000000'"
          formControlName="longcontract" >
        </mat-checkbox>
        <ng-template #contractImage>
          <img style="margin-left: 5px; vertical-align: middle; width: 15px;"
            [src]="this.longContract ? '../../../assets/images/icon-yes.png' : '../../../assets/images/icon-no.png'"
            [alt]="this.longContract ? 'yes' : 'no'"/>
        </ng-template>
      </div>
    </section>

    <section class="section-container" fxLayout="column" fxLayoutGap="15px">

      <h3 class="dashboard_headers">Sign Up Manager</h3>

      <!-- Sign Up Manager Name -->
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
          Name:
        </mat-label>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <input matInput formControlName="manager" placeholder="-">
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry
          [labelName]="'Name'"
          [labelValue]="currentEmployment.manager.fullName"
          [inline]="true">
        </learner-details-entry>
      </div>

      <!-- Sign Up Manager Phone -->
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
          Phone:
        </mat-label>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <input matInput formControlName="managerphone" placeholder="-">
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry
          [labelName]="'Phone'"
          [labelValue]="currentEmployment.manager.phone"
          [inline]="true">
        </learner-details-entry>
      </div>

      <!-- Sign Up Manager Email -->
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
          Email:
        </mat-label>
        <mat-form-field [style.width.px]="280" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <a *ngIf="!editMode && currentEmployment.manager.email" href="javascript:void(0)" (click)="copyEmail(currentEmployment.manager.email)" >
            <input matInput formControlName="manageremail" placeholder="-">
          </a>
          <input *ngIf="editMode || !currentEmployment.manager.email" matInput formControlName="manageremail" placeholder="-">
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry
          [labelName]="'Email'"
          [labelValue]="currentEmployment.manager.email"
          [inline]="true"
          [copyToClipboard]="true">
        </learner-details-entry>
      </div>
    </section>

  </section>


  <section class="section-container" fxLayout="row wrap" fxLayoutGap="100px">

    <section class="section-container" fxLayout="column" fxLayoutGap="15px" style="min-width: 285px">
      <h3 class="dashboard_headers">Employer</h3>

      <!-- Employer -->
      <learner-details-entry
        [labelName]="'Name'"
        [labelValue]="currentEmployment.employer ? currentEmployment.employer : '-'"
        [inline]="true">
      </learner-details-entry>


      <!-- Address -->
      <learner-details-address-entry *ngIf="currentEmployment.address.addressLine1"
        [type]="'Address'"
        [addressLineOne]="currentEmployment.workLocation.addressLine1"
        [addressLineTwo]="currentEmployment.workLocation.addressLine2 ? currentEmployment.workLocation.addressLine2 : currentEmployment.workLocation.addressLine3"
        [addressLineThree]="currentEmployment.workLocation.town"
        [addressLineFour]="currentEmployment.workLocation.postCode">
      </learner-details-address-entry>


      <!-- Work location is set to the same column value as adress in the API -->

      <!-- <learner-details-address-entry *ngIf="currentEmployment.workLocation?.addressLine1 != null"
        [type]="'Work location'" [addressLineOne]="currentEmployment.workLocation.addressLine1"
        [addressLineTwo]="currentEmployment.workLocation.addressLine2"
        [addressLineThree]="currentEmployment.workLocation.postTown"
        [addressLineFour]="currentEmployment.workLocation.postCode">
      </learner-details-address-entry> -->

      <!-- <learner-details-entry *ngIf="currentEmployment.workLocation.addressLine1 == null" [labelName]="'Work location'"
        [labelValue]="'As above'"></learner-details-entry> -->

      <!-- Phone -->
      <learner-details-entry *ngIf="currentEmployment.phone"
        [labelName]="'Phone'"
        [labelValue]="currentEmployment.phone"
        [inline]="true">
      </learner-details-entry>

      <!-- Email -->
      <learner-details-entry *ngIf="currentEmployment.email"
        [labelName]="'Email'"
        [labelValue]="currentEmployment.email"
        [inline]="true"
        [copyToClipboard]="true">
      </learner-details-entry>

      <!-- Wifi Access -->
      <div class="bm-15">
        <label
          style="font-weight: 500; vertical-align: middle;"
          [style.color]="editMode && editPermissionLevel === 1 ? 'darkred' : '#000000'"
        >
          WiFi access at work
        </label>
        <mat-checkbox *ngIf="editPermissionLevel === 1 && editMode; else wifiImage"
          style="margin-left: 5px;"
          [style.color]="editMode ? 'darkred' : '#000000'"
          formControlName="wifiaccess" >
        </mat-checkbox>
        <ng-template #wifiImage>
          <img style="margin-left: 5px; vertical-align: middle; width: 15px;"
            [src]="this.wifiAccess ? '../../../assets/images/icon-yes.png' : '../../../assets/images/icon-no.png'"
            [alt]="this.wifiAccess ? 'yes' : 'no'"/>
        </ng-template>
      </div>

    </section>

    <section class="section-container" fxLayout="column" fxLayoutGap="15px">
      <h3 class="dashboard_headers">Current Line Manager</h3>

      <!-- Current Line Manager Name -->
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
          Name:
        </mat-label>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <input matInput formControlName="lineManager" placeholder="-">
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry
          [labelName]="'Name'"
          [labelValue]="contactInformation.lineManager2"
          [inline]="true">
        </learner-details-entry>
      </div>

      <!-- Current Line Manager Phone -->
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
          Phone:
        </mat-label>
        <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <input matInput formControlName="lineManagerMobile" placeholder="-">
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry
          [labelName]="'Phone'"
          [labelValue]="contactInformation.lineManagerMobile2"
          [inline]="true">
        </learner-details-entry>
      </div>

      <!-- Current Line Manager Email -->
      <div *ngIf="editPermissionLevel === 1">
        <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
          Email:
        </mat-label>
        <mat-form-field [style.width.px]="280" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
          <a *ngIf="!editMode && contactInformation.lineManagerEmail2" href="javascript:void(0)" (click)="copyEmail(contactInformation.lineManagerEmail2)" >
            <input matInput formControlName="lineManagerEmail" placeholder="-">
          </a>
          <input *ngIf="editMode || !contactInformation.lineManagerEmail2" matInput formControlName="lineManagerEmail" placeholder="-">
        </mat-form-field>
      </div>
      <div *ngIf="editPermissionLevel !== 1">
        <learner-details-entry
          [labelName]="'Email'"
          [labelValue]="contactInformation.lineManagerEmail2"
          [inline]="true"
          [copyToClipboard]="true">
        </learner-details-entry>
      </div>
    </section>

  </section>

</article>
