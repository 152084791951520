

<mat-card class="lar-card">
    <div class="lar-container">
        <h2 class="dashboard-titles" style="padding-left:0px;">Learning Aim Reference Editor</h2>
                
        <ng-select appearance="outline" style="margin-right: 20px;"
            class="ref-selector" 
            [items]="laimRefs"
            bindLabel="label" 
            bindValue="laimRef"
            placeholder="Learning Aims Reference" 
            [(ngModel)]="selectedLaimRef">
        </ng-select>
    </div>   

    <div>
        <button mat-button (click)="selectRef()" mat-flat-button [ngClass]="{'button-enabled': selectedLaimRef, 'button-disabled': !selectedLaimRef}" class="favourites-button">Edit</button>
        <button mat-button (click)="newRef()" mat-flat-button color="secondary" class="new-button">New</button>
    </div>

</mat-card>