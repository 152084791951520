<div class=formContainer>
  <h2 mat-dialog-title class="title">{{data.ReviewType}}</h2>
  <div style="flex-direction: row; display: flex; gap: 10px; align-items: center; margin-bottom: 10px;">
    <h2 class="date">{{ data.Date | date: this.dateFormat}}</h2>
    <h2 [ngClass]="data.OutcomeClass" class="status-text">{{data.Outcome}}</h2>
  </div>
  <mat-divider style="margin-bottom: 10px;"></mat-divider>
  <form [formGroup]='reviewForm' novalidate>

      <mat-dialog-content class="new-record-form">

          <!-- <mat-label class=reviewFormTitle>Outcome</mat-label>
          <mat-form-field class="iqa-new-record"  appearance="outline" >
              <input cdkFocusInitial [readonly]="true" *ngIf="data" matInput formControlName="outcome">
          </mat-form-field> -->

          <mat-label class=reviewFormTitle>Notes</mat-label>
          <mat-form-field class="iqa-new-record"  appearance="outline">
              <input [readonly]="true" *ngIf="data" matInput formControlName="notes">
          </mat-form-field>

          <mat-label *ngIf="data.FullDetails" class=reviewFormTitle>Full Details</mat-label>
          <mat-form-field *ngIf="data.FullDetails" class="iqa-new-record"  appearance="outline">
              <textarea matInput [readonly]="true"  formControlName="fullDetails" cdkAutosizeMinRows="10" style="min-height: 250px;">{{data.FullDetails}}</textarea>
          </mat-form-field>

      </mat-dialog-content>
  </form>
  <mat-dialog-actions class="close">
      <button mat-flat-button color="primary" (click)="close()">Close</button>  <br>
  </mat-dialog-actions>
</div>
