import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Console } from 'console';

@Component({
  selector: 'app-attendance-approval-edit',
  templateUrl: './attendance-approval-edit.component.html',
  styleUrls: ['./attendance-approval-edit.component.scss']
})
export class AttendanceApprovalEditComponent implements OnInit {
  timeInReason: boolean = false;
  timeOutReason: boolean = false;
  timeInChanged: boolean = false;
  timeOutChanged: boolean = false;
  undo: boolean;
  defaultCentreStartTime = '09:00';
  centreStartTime: string;
  defaultCentreEndTime = '17:00';
  centreEndTime: string;

  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
  private cdr: ChangeDetectorRef,
  private fb : UntypedFormBuilder,
  private snackBar: MatSnackBar,
  private dialogRef: MatDialogRef<AttendanceApprovalEditComponent>) { }
  attenanceEditForm: UntypedFormGroup;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.centreStartTime = this.data.centreStartTime === null ? this.defaultCentreStartTime : this.data.centreStartTime.substring(0,5);
    this.centreEndTime = this.data.centreEndTime === null ? this.defaultCentreEndTime : this.data.centreEndTime.substring(0,5);
    console.log(this.centreStartTime, ' - ', this.centreEndTime);
    // const date = '01/01/2010';
    // const dateTime = new Date(Date.parse(`${date} ${this.centerEndTime}`));
    // const dateTimeOneMinLess = new Date(dateTime.getTime()-60000);
    // const centerEndTimeOneMinLess = (dateTimeOneMinLess.getHours() + ':' + dateTimeOneMinLess.getMinutes()).substring(0,5);
    let formDat = this.data;
    this.attenanceEditForm = this.fb.group({
      'Time In': new UntypedFormControl(formDat.timeinnew ? formDat.timeinnew.substring(0,5) : formDat.timein.substring(0,5),
        [Validators.maxLength(5),
        this.minTime(this.centreStartTime),
        this.maxTime(this.centreEndTime)]),
      'Time In Comments':  new UntypedFormControl(formDat.timeincomments),
      'Time Out':  new UntypedFormControl(formDat.timeoutnew ? formDat.timeoutnew.substring(0,5) : formDat.timeout.substring(0,5),
        [
          Validators.maxLength(5),
          this.minTime(this.centreStartTime),
          this.maxTime(this.centreEndTime),
          this.afterLearnerSignOut(formDat.learnerSignOut.substring(0,5))
        ]),
      'Time Out Comments':  new UntypedFormControl(formDat.timeoutcomments),
      'signid' : new UntypedFormControl(formDat.signid)
    }, {validators: this.checkIfTimeInIsBeforeTimeOut});
    console.log(this.attenanceEditForm.value)
    this.cdr.detectChanges();
  }

  afterLearnerSignOut(time: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const forbidden = control.value > time;
      return forbidden ? {afterLearnerSignOut: time} : null;
    };
  }

  minTime(time: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const forbidden = control.value < time;
      return forbidden ? {minTime: {value: control.value}} : null;
    };
  }

  maxTime(time: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const forbidden = control.value > time;
      return forbidden ? {maxTime: {value: control.value}} : null;
    };
  }

  checkIfTimeInIsBeforeTimeOut(control : AbstractControl) : ValidationErrors | null {
    if(!control.get('Time In').value || !control.get('Time Out').value) {
      return null;
    }
    const forbidden = control.get('Time In').value > control.get('Time Out').value;
    return forbidden ? {'timeInAfterTimeOut':  true} : null;
  }

  timeInReasonChange() {
    this.timeInReason = true;
    this.cdr.detectChanges();
  }

  timeOutReasonChange() {
    this.timeOutReason = true;
    this.cdr.detectChanges();
  }

  timeInChange() {
    this.timeInChanged = true;
    this.cdr.detectChanges();
  }

  timeOutChange() {
    this.timeOutChanged = true;
    this.cdr.detectChanges();
  }

  confirm() {
    let errorMessage: string = null;

    if(this.attenanceEditForm.errors !== null) {
      errorMessage = 'Time In must be before Time Out';
    } else if(!this.attenanceEditForm.get('Time Out').valid){
      if(this.attenanceEditForm.get('Time Out').errors.afterLearnerSignOut) {
        console.log(this.attenanceEditForm.get('Time Out').errors);
        errorMessage = `Please enter a Time out that is not after: ${this.attenanceEditForm.get('Time Out').errors.afterLearnerSignOut} `;
      } else {
        errorMessage = `Please enter a Time out from ${this.centreStartTime}  - and before ${this.centreEndTime}`;
      }
    } else if(!this.attenanceEditForm.get('Time In').valid) {
      errorMessage = `Please enter a Time in from ${this.centreStartTime} - and before ${this.centreEndTime}`;
    } else if(this.timeInChanged && !this.timeInReason) {
      errorMessage = 'Please enter a reason for changing the time in';
    } else if(this.timeOutChanged && !this.timeOutReason) {
      errorMessage = 'Please enter a reason for changing the time out';
    }

    if(errorMessage) {
      this.snackBar.open(errorMessage, 'Close', {
        duration: 3000,
        panelClass: ['error-snackbar']
      });
    } else {
      this.dialogRef.close(this.attenanceEditForm);
    }


  }

  close(): void {
    if (this.attenanceEditForm.dirty) {
      let msg = "This form is about to lose all unsaved data!";
      this.undo = false;
      let ref = this.snackBar.open(msg, 'Click to return', {
        duration: 3000
      });
      setTimeout(() => {
        if (!this.undo) {
          this.dialogRef.close();
        }
      }, 3000);

      ref.onAction().subscribe(() => {
        this.undo = true;
      });
    }
    else {
      this.dialogRef.close();
    }
  }

}
