<article>
  <mat-sidenav-container>
    <mat-sidenav #filters [fixedInViewport]="true" [fixedTopGap]="120" mode="side" opened style="border-right: 0;">
      <div class="filter-container">
        <div style="display: flex; justify-content: space-between;">
          <h2 style="margin: 0; padding: 8px">Options</h2>
          <button *ngIf="filters.opened" mat-button class="filterButtons" style="margin-right: 5px"
            (click)="filters.toggle()">
            <img src="../../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Hide Filters">
          </button>
        </div>
        <div class="filter-options">

          <div style="border: #ffffff6d solid 1px; padding: 10px; border-radius: 6px;">
            <mat-slide-toggle style="color: white; float:left; font-size: 13px;" [checked]="this.textExpand"
              (change)="this.toggleTextExpand()">
              Expand text
            </mat-slide-toggle>
          </div>

          <div *ngIf="this.permissions.canCreate">
            <button style="width: 100%;" mat-flat-button color="primary" class="create-button"
              (click)="this.openEventModal(this.eventModalMode.create)">Create New Event</button>
          </div>

          <div>
            <h2 style="margin: 0; padding: 8px">Filters</h2>
            <div style="border-bottom: #7162C3 solid 1px;"></div>
          </div>

          <div>
            <mat-form-field id="date-range-field" class="option-select" style="width: 100%;" appearance="outline"
              [floatLabel]="'always'">
              <mat-label>Event Date</mat-label>
              <!-- this seems to be fireing dates updated twice at the start -->
              <input style="cursor: pointer; color: white;" matInput #dateRange ngxDaterangepickerMd type="text"
                placeholder="Select Date Range" [(ngModel)]="this.dateRangeSelected" (datesUpdated)="filterEvents()"
                [showClearButton]="true" [readonly]="true"
                [locale]="{applyLabel: 'Apply', clearLabel: 'Clear', format: 'DD/MM/YYYY'}" autocomplete="off"
                [alwaysShowCalendars]=true>
            </mat-form-field>
          </div>

          <form [formGroup]="eventsFormOptions" novalidate>
            <ng-select class="filter-select" #select placeholder="Event Type" formControlName="eventTypeSelect"
              (change)="filterEvents()" (clear)="eventTypeCleared()" [items]="uniqueEventTypes" appearance="outline"
              [closeOnSelect]="false" [clearOnBackspace]="false" [multiple]="true" [clearSearchOnAdd]="true">
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="item-box" *ngFor="let item of items">
                  <span class="ng-value-label">{{item}}</span>
                  <span class="item-clear" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
              </ng-template>
            </ng-select>

            <ng-select class="filter-select" #select placeholder="Created By" formControlName="eventStaffSelect"
              (change)="filterEvents()" (clear)="staffSelectCleared()" [items]="uniqueStaff" appearance="outline"
              [closeOnSelect]="false" [clearOnBackspace]="false" [multiple]="true" [clearSearchOnAdd]="true">
              <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="item-box" *ngFor="let item of items">
                  <span class="ng-value-label">{{item}}</span>
                  <span class="item-clear" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
              </ng-template>
            </ng-select>
          </form>

          <div style="text-align:right;">
            <button mat-flat-button color="basic" class="clear-filters-button" (click)="resetAllValues()">Clear
              All</button>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content style="min-height: calc(100vh - 120px); background-color: #fafafa;">
      <div class="events-container" style="height: 100%;">
        <div *ngIf="!filters.opened" style="background-color: #473B8C; position: fixed; left: 52px; top: 120px;">
          <button mat-button class="filterButtons" (click)="filters.toggle()">
            <img src="../../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Hide Filters">
          </button>
        </div>

        <div *ngIf="!this.loadedEvents">
          <p style="text-align: center; margin-bottom: 0;">Loading Events...</p>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <mat-accordion *ngIf="this.filteredDraftEvents?.length > 0 && this.loadedEvents">
          <mat-expansion-panel [expanded]="false" style="margin-bottom: 15px;">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Drafts
              </mat-panel-title>
              <mat-panel-description>
                <div>
                  {{this.filteredDraftEvents?.length}} draft event{{this.filteredDraftEvents?.length > 1 ? 's.' : '.'}}
                </div>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let event of this.filteredDraftEvents; let last = last"
              (click)="this.openEventModal(this.eventModalMode.view, event)">
              <div class="event-row" [ngClass]="{'highlight' : this.eventToHighlight === event.eventId }">
                <span *ngIf="event.isDraft == true" class="draft-event-label">Draft</span>
                <span class="draft-event" style="font-weight: 450;">
                  {{event.eventType ? event.eventType : '[no type]'}}
                </span>
                <div class="event-by body-text draft-event">
                  By {{event.staffName}} on {{event.eventDate | date: this.dateFormat }}
                  <span *ngIf="event.eventDate == null">[no date]</span>
                </div>
                <div class="event-comment body-text draft-event" [style]="textExpand ? 'white-space: pre-line' : ''">
                  {{event.comments ? textExpand ? event.comments : (event.comments | truncate:120) : '-' }}
                </div>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-card *ngIf="this.loadedEvents" style="text-align: left; padding: 12px;">
          <mat-card-content>
            <div *ngIf="this.filteredEvents?.length > 0; else noEvents">
              <div *ngFor="let event of this.filteredEvents; let last = last"
                (click)="this.openEventModal(this.eventModalMode.view, event)">
                <event-item [event]="event" [highlight]="this.eventToHighlight === event.eventId"
                  [textExpand]="this.textExpand"></event-item>
                <!-- <div class="event-row" [ngClass]="{'highlight' : this.eventToHighlight === event.eventId }">
                      <span style="font-weight: 450;">
                        {{event.eventType ? event.eventType : '[no type]'}} -
                        <span style="font-weight: 400;">
                          {{ event.eventDate | date: this.dateFormat }}
                          <span *ngIf="event.eventDate == null">[no date]</span>
                        </span>
                      </span>

                      <div class="event-by body-text">
                        By {{ event.staffName }}
                      </div>
                      <div class="event-comment body-text" [style]="textExpand ? 'white-space: pre-line' : ''">
                        {{ event.comments?.length > 0 ? textExpand ? event.comments : (event.comments | truncate:120) : '-'  }}
                      </div>
                    </div> -->
                <mat-divider *ngIf="!last"></mat-divider>
              </div>
            </div>

            <ng-template #noEvents>
              <div *ngIf="this.errorLoadingEvents === null; else eventsError"
                style="text-align: center; padding: 28px; font-size: 16px;">
                {{ this.noData }}
              </div>
            </ng-template>

            <ng-template #eventsError>
              <div style="text-align: center; padding: 28px; font-size: 16px;">
                {{ this.errorLoadingEvents }}
              </div>
            </ng-template>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

</article>