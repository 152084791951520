import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { UserTableService } from 'src/app/core/database/user-table.service';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { IDefaultColumns } from 'src/app/reporting/basic-report/basic-report.component';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthoriseDialogComponent } from './authorise-dialog/authorise-dialog.component';
import { IUserInfo } from 'src/app/shared/interfaces/user-info';
import { StringHelper } from 'src/app/shared/string-helper';
import { AttendanceApproval, AttendanceAuthorisedService } from './attendance-authorised.service';
import { AttendanceTableService } from 'src/app/core/database/attendance-table.service';

@Component({
  selector: 'app-attendance-authorised',
  templateUrl: './attendance-authorised.component.html',
  styleUrls: ['./attendance-authorised.component.scss']
})
export class AbsenceToAuthoriseDetailsComponent implements OnInit {

  @ViewChild('tablePaginator', { static: true }) tablePaginator: MatPaginator;
  @ViewChild('tableSort', { static: true }) tableSort: MatSort;

  displayedColumns: string[] = ['select', 'name', 'assessor', 'scheme', 'centre'];
  errorText = '';
  filterString = '';
  mainTableData: AttendanceApproval[] = [];
  masterCheckbox = false;
  matSortActive = 'name';
  matSortDirection = 'asc';
  onlyMyLearnerStatus = true;
  pageOptions: number[] = [this.sharedFunctions.calcOptimumRows(), 25, 50, 100];
  refreshTable: EventEmitter<boolean> = new EventEmitter<boolean>()
  selectedDefaultDisplayColumns: IDefaultColumns;
  selection = new SelectionModel<AttendanceApproval>(true, []);
  fullSelection = [];
  showSpinner = true;
  sortString = '';
  staffId: number;
  tableDataSource: MatTableDataSource<AttendanceApproval>;
  totalData: number;
  user: IUserInfo;
  widgetId: number;
  showSelectedLearners = false;

  constructor(
    private _snackBar: MatSnackBar,
    private attendanceAuthorisedService: AttendanceAuthorisedService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private userTableService: UserTableService,
    public sharedFunctions: SharedFunctionsService,
    public stringHelper: StringHelper,
    private attendanceTableService: AttendanceTableService
  ) {
    this.tableDataSource = new MatTableDataSource<AttendanceApproval>(this.mainTableData);
  }

  ngOnInit(): void {
    this.tableDataSource.paginator = this.tablePaginator;
    this.tableSort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
    this.sortString = `${this.displayedColumns[1]}::asc`;
    this.tableDataSource.sort = this.tableSort;
    this.attendanceTableService.getAuthorisedAbsence().then((data) => {
      if (data !== undefined && data !== null) {
        this.filterString = data.filterString;
        this.onlyMyLearnerStatus = data.onlyMyLearners;
      }
      this.userTableService.get(1).then((user) => {
        if (!user || user == undefined || user == null) {
          this.router.navigate(['home']);
        }
        else {
          this.user = user;
          this.setupTablePagination();
        }
      });
    });
  }

  setupTablePagination() {
    merge(this.tableSort.sortChange, this.tablePaginator.page, this.refreshTable).pipe(
      startWith({}),
      switchMap(() => {
        return this.getTableData()
          .pipe(
            catchError((val) => {
              this.showSpinner = false;
              this.errorText = val;
              this.cdr.detectChanges();
              return of(null);
            }));
      }),
      map((data: PaginationResults<AttendanceApproval>) => {
        if (data == null) return [];
        this.totalData = data.totalRecords;
        return data.data;
      })
    ).subscribe((data) => {
      this.mainTableData = data;
      this.tableDataSource = new MatTableDataSource(this.mainTableData);
      this.showSpinner = false;
      this.selection.clear();
      this.checkBoxVisualControl(data);
      this.cdr.detectChanges()
    });
  }

  checkBoxVisualControl(data: AttendanceApproval[]) {
    this.fullSelection.forEach(item => {
      const found = data.find(x => x.traineeId === item.traineeId);
      if (found) {
        this.selection.select(found);
      }
    });
    this.masterCheckbox = this.isAllSelected();
  }

  setupSort() {
    this.tableSort.sortChange.subscribe((event) => {
      this.tablePaginator.pageIndex = 0;
      if (event.direction !== '') {
        this.sortString = `${event.active}::${event.direction}`;
      }
    });
  }

  getTableData() {
    this.showSpinner = true;
    this.errorText = null;
    const staffId = this.user.staffId;
    return this.attendanceAuthorisedService.getDetails(
      staffId,
      this.tablePaginator.pageIndex + 1,
      this.tablePaginator.pageSize,
      this.filterString,
      this.onlyMyLearnerStatus,
      this.tableSort.active,
      this.tableSort.direction);
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      staffId: this.user.staffId,
      selection: this.fullSelection
    }
    dialogConfig.minWidth = '700px';
    dialogConfig.minHeight = '95%';
    const dialogRef = this.dialog.open(AuthoriseDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'cancel') return;
      this.selection.clear();
      this.fullSelection = [];
      this.cdr.detectChanges();
    });
  }

  loadLearner(traineeId, pot) {
    this._snackBar.open("Loading learner details", "Close", {
      duration: 4000,
    });
    const link = `/learner/dashboard/${traineeId}/${pot}`;
    window.open(link, '_blank').focus();
  }

  isPercentage(value, column) {
    let isNum = false;
    if (['frameworkOverallActual', 'frameworkOverallTarget', 'mqProgress', 'mqTarget', 'aonProgress', 'aonProgress', 'comProgress', 'dlProgress'].includes(column)) {
      isNum = typeof (value) === 'number';
    }
    return isNum;
  }

  formatForEmpty(value): string {
    let formattedValue = value;
    switch (value) {
      case null:
      case undefined:
      case 'null':
      case '':
      case ' ':
      case '  ':
        formattedValue = '-';
        break;
    }
    return formattedValue;
  }

  applyFilter(filterValue: string) {
    this.filterString = filterValue.toLowerCase();
    this.tablePaginator.pageIndex = 0;
    this.attendanceTableService.addAuthorisedAbsence(this.filterString, this.onlyMyLearnerStatus);
    this.refreshTable.emit(true);
  }

  toggleOnlyMyLearnerStatus(value: MatSlideToggleChange) {
    this.fullSelection = [];
    this.tablePaginator.pageIndex = 0;
    this.onlyMyLearnerStatus = value.checked;
    this.attendanceTableService.addAuthorisedAbsence(this.filterString, this.onlyMyLearnerStatus);
    this.refreshTable.emit(true);
  }

  toggleShowSelected(value: MatSlideToggleChange) {
    this.showSelectedLearners = value.checked;
    if (value.checked) {
      this.tableDataSource = new MatTableDataSource(this.fullSelection);
      this.totalData = this.fullSelection.length;
      this.selection.clear();
      this.checkBoxVisualControl(this.fullSelection);
    }
    else {
      this.tableDataSource = new MatTableDataSource(this.mainTableData);
      this.selection.clear();
      this.checkBoxVisualControl(this.mainTableData);
    }
    this.cdr.detectChanges();
  }

  masterToggle($event) {
    this.masterCheckbox = $event.checked;
    if (this.isAllSelected()) {
      this.tableDataSource.filteredData.forEach(row => {
        this.selectedItem({ checked: false }, row);
      });
    } else {
      this.tableDataSource.filteredData.forEach(row => {
        if (this.fullSelection.indexOf(row) === -1) {
          this.selectedItem({ checked: true }, row);
        }
      });
    }
  }

  //checks if everything is selected on a table page.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.filteredData.length;
    return numSelected === numRows;
  }

  selectedItem($event, cell) {
    const traineeId = cell.traineeId;
    const pot = cell.pot;
    if ($event.checked) {
      this.selection.select(cell);
      this.fullSelection.push(cell);
    }
    else {
      const valueToRemove = this.fullSelection.filter(item => (item.traineeId === traineeId) && (item.pot === pot));
      const index = this.fullSelection.indexOf(valueToRemove[0]);
      if (index !== -1) {
        this.fullSelection.splice(index, 1);
      }
      this.selection.deselect(cell);
    }
    this.masterCheckbox = this.isAllSelected();
    this.cdr.detectChanges();
  }

  canAuthorise() {
    return !(this.fullSelection.length >= 1);
  }
}
