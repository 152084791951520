<div *ngIf="dataLoaded" class="employer-dashboard-container">
  <mat-toolbar color="white">
    <mat-toolbar-row style="width:90%; padding-left: 5%;">
      <button class="backBtn" mat-icon-button type="button" (click)="navBack()">
        <mat-icon style="font-size:50px">chevron_left</mat-icon>
      </button>
      <span style="margin-left: 35px;">{{placementBasics?.name}}</span>
      <mat-icon style="margin-left:10px; cursor: pointer;width: auto; height: auto;"
        (click)="toggleEdit()">{{iconText}}</mat-icon>
    </mat-toolbar-row>

    <mat-toolbar-row style="width:90%; padding-left: 5%;">
      <span>{{associatedTitleString}}</span>
      <div class="no-branches" *ngIf="placementType == 1 && associatedPlacements?.length == 0">
        No branches
      </div>
      <div class="select-container" *ngIf="placementType == 1 && associatedPlacements?.length > 1">
        <ng-select (change)="loadAssociatedPlacement()" class="branch-select" #select [items]="associatedPlacements"
          bindLabel="placementName" [(ngModel)]="selectedBranch"
          placeholder="{{associatedPlacements?.length}} branches">
        </ng-select>
      </div>
      <button mat-flat-button class="employer-button" *ngIf="placementType == 2 || associatedPlacements?.length == 1"
        (click)="loadEmployerOrBranch(associatedPlacements[0])">
        {{associatedPlacements[0].placementName}}
      </button>

      <button mat-icon-button type="button" (click)="addBranch()" class="add-branch-button" *ngIf="placementType == 1">
        <mat-icon style="width: auto; height:auto;">add_circle</mat-icon>
      </button>


    </mat-toolbar-row>
  </mat-toolbar>

  <div style="width:90%; padding-left: 5%; padding-bottom: 25px;">
    <mat-tab-group [selectedIndex]="selectedTabIndex">

      <mat-tab label="Employer Profile">
        <mat-divider></mat-divider>



        <div class="employer-dashboard-profile-container">
          <div fxLayout="row wrap" fxLayoutGap="25px grid">
            <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">

              <div class="widget-container">
                <app-employer-basics #employerBasics [placementBasics]="placementBasics" [isEditForm]="isEditEmployer"
                  (empBasicsUpdate)="processFormData($event, 'details')">
                </app-employer-basics>
              </div>

              <div class="widget-container">
                <app-employer-contacts #employerContacts [isEditForm]="isEditEmployer" [contacts]="contacts"
                  (contactsUpdate)="processFormData($event, 'contacts')">
                </app-employer-contacts>
              </div>

              <div class="widget-container">
                <app-employer-learner-list #learnerList [learners]="learners"
                  (pageSizeUpdated)="pageSizeUpdated($event)">
                </app-employer-learner-list>
              </div>

            </div>

            <div fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">

              <div class="widget-container">
                <app-employer-h-and-s #employerHAndS [hsInfo]="hsInfo" [riskBandings]="riskBandings"
                  [currentRiskId]="currentRiskId" [isEditForm]="isEditEmployer"
                  (hsInfoUpdate)="processFormData($event, 'hsInfo')">
                </app-employer-h-and-s>
              </div>

              <div class="widget-container">
                <app-employer-attachments [vEmployerId]="placementBasics.placementId"></app-employer-attachments>
              </div>

              <div class="widget-container">
                <app-employer-maytas-info #employerMaytasLinks [showLinks]="hasMaytasLinks" [maytasLinks]="maytasLinks"
                  [maytasLinkOptions]="maytasLinkOptions" [isEditForm]="isEditEmployer" [placementType]="placementType"
                  [associatedPlacements]="associatedPlacements" [placementBasics]="placementBasics"
                  [userInfo]="userInfo" (maytasLinkDelete)="processFormData($event, 'maytasLinks')">
                </app-employer-maytas-info>
              </div>

            </div>
          </div>
        </div>

      </mat-tab>

      <mat-tab label="Events">
        <mat-divider></mat-divider>
        <app-employer-events #employerEvents [placementId]="placementId" [placementType]="placementType"
          [placementName]="placementBasics?.name" [associatedPlacements]="associatedPlacements">
        </app-employer-events>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>