import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from 'src/app/shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-onefile-assessment-plan-report',
  templateUrl: './onefile-assessment-plan-report.html'
})
export class OnefileAssessmentPlanReportComponent {

  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  name = 'Onefile Assessment Plan Report';
  reportVariant = ReportVariant.OneFileAssessmentPlan;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['sector', 'fullname', 'assessor', 'startDate', 'expCompletionDate', 'title', 'dateAssessment', 'dateLearnerSign', 'dateAssessorSign']
    }
  ]
  defaultColumnOrder = [
    {
      type: 'default',
      columns: ['fullname', 'sector', 'assessor', 'startDate', 'expCompletionDate', 'title', 'dateAssessment', 'dateLearnerSign', 'dateAssessorSign']
    }
  ];
  dateFilters = ['startDate', 'expCompletionDate', 'terminationDate'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];
}
