import { AdditionalExport } from "src/app/shared/enums/additional-export";
import { AttendanceAnalysisService } from "./attendance-analysis.service";
import { ClassicReportService } from "src/app/reportingV2/classic-report/classic-report.service";
import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DATE_FORMAT } from "src/app/app.constants";
import { IUserInfo } from "src/app/shared/interfaces/user-info";
import { map, merge, startWith, switchMap } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { SharedFunctionsService } from "src/app/core/services/shared-functions.service";
import { UserTableService } from "src/app/core/database/user-table.service";
import moment from 'moment';
import { AttendanceTableService } from "src/app/core/database/attendance-table.service";

@Component({
  selector: 'app-attendance-analysis',
  templateUrl: './attendance-analysis.component.html',
  styleUrls: ['./attendance-analysis.component.scss']
})
export class AttendanceAnalysisComponent implements OnInit {
  //View Child
  @ViewChild('tableSort', { static: true }) tableSort: MatSort;
  @ViewChild(MatPaginator) tablePaginator: MatPaginator;
  @ViewChild('datePicker') datePicker;

  //Emitters
  refreshTable: EventEmitter<boolean> = new EventEmitter();

  //Variables
  dateFormat = DATE_FORMAT;
  showSpinner = true;
  tableDisplayColumns: string[];
  footerDisplayColumns: string[] = ['centreAdditionalFooter', 'cbPercentageAdditionalFooter', 'wbPercentageAdditionalFooter', 'virtualPercentageAdditionalFooter', 'learnPercentageAdditionalFooter', 'aaPercentageAdditionalFooter', 'holPercentageAdditionalFooter', 'uaPercentageAdditionalFooter'];
  //Level0
  subContractorDisplayColumns = ['subcontractor', 'cbPercentage', 'wbPercentage', 'virtualPercentage', 'learnPercentage', 'aaPercentage', 'holPercentage', 'uaPercentage'];
  //Level1s
  centreDisplayColumns = ['centre', 'cbPercentage', 'wbPercentage', 'virtualPercentage', 'learnPercentage', 'aaPercentage', 'holPercentage', 'uaPercentage'];
  //Level2
  learnerDisplayColumns = ['fullName', 'weekStartDate', 'cbHours', 'wbHours', 'virtualHours', 'learnHours', 'aaHours', 'hHours', 'uaHours', 'totalHours', 'fundedHours', 'scheme'];
  filterString = '';
  drillDownString = '';
  dateString = '';
  rawTextFilterString = "";
  textFilterString = '';
  level = 0;
  tableData: any[];
  tableDataSource: MatTableDataSource<any>; //needs to be any as the data is dynamic :(
  user: IUserInfo;
  totalData: number;
  subContractorName: string;
  subContractorId: number;
  centreName: string;
  centreId: number;
  pageOptions: number[] = [this.sharedFunctions.calcOptimumRows(), 25, 50, 100];
  totals: any[] = []; //totals for the table
  ranges: any;
  dateRangeSelected: { startDate: moment.Moment, endDate: moment.Moment };
  noDateSelected = false;
  selectedScheme: string[] = [];
  schemeOptions = ['Advancement', 'Engagement']

  constructor(
    private attendanceAnalysisService: AttendanceAnalysisService,
    private classicReportService: ClassicReportService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private snackBar: MatSnackBar,
    private userTableService: UserTableService,
    public sharedFunctions: SharedFunctionsService,
    private attendanceTableService: AttendanceTableService
  ) {
    this.tableDataSource = new MatTableDataSource<any>(this.tableData);
    this.tableDataSource.paginator = this.tablePaginator;
    this.attendanceTableService.getAnalysis().then((data) => {
      if (data !== undefined && data !== null) {
        this.level = data.level;
        this.rawTextFilterString = data.filterString;
        this.dateString = data.dateString;
        this.selectedScheme = data.schemeString;
        this.drillDownString = data.drillDownString;
        this.subContractorName = data.subContractorName;
        this.centreName = data.centreName;
        this.filterBuilder();
      }
      this.userTableService.get(1).then((user) => {
        if (!user || user == undefined || user == null) {
          this.router.navigate(['home']);
        }
        else {
          this.user = user;
          this.setupTable();
        }
      });
    });
  }

  ngOnInit() {
    this.tableSort.sort(({ id: this.subContractorDisplayColumns[0], start: 'asc' }) as MatSortable);
    this.tableDataSource.sort = this.tableSort
    this.tableDisplayColumns = this.subContractorDisplayColumns;
    this.setRanges();
    this.dateRangeSelected = { startDate: moment(this.ranges['Last 14 Days'][0].format('YYYY/MM/DD')), endDate: moment(this.ranges['Last 14 Days'][1].format('YYYY/MM/DD')) };
    this.dateString = `startDate::${this.dateRangeSelected.startDate.format('DD-MM-YYYY')},endDate::${this.dateRangeSelected.endDate.format('DD-MM-YYYY')}`;
    this.filterBuilder();
  }

  setRanges() {
    const monthOfYear = new Date().getMonth();
    const firstContractDay = moment();
    const lastContractDay = moment();
    const previousfirstContractDay = moment();
    const previouslastContractDay = moment();
    if (monthOfYear < 4) {
      firstContractDay.set({ year: moment().year() - 1, month: 3, date: 1 }); // moment months start counting at 0!
      lastContractDay.set({ year: moment().year(), month: 2, date: 31 });
      previousfirstContractDay.set({ year: moment().year() - 2, month: 3, date: 1 });
      previouslastContractDay.set({ year: moment().year() - 1, month: 2, date: 31 });
    } else {
      firstContractDay.set({ year: moment().year(), month: 3, date: 1 });
      lastContractDay.set({ year: moment().year() + 1, month: 2, date: 31 });
      previousfirstContractDay.set({ year: moment().year() - 1, month: 3, date: 1 });
      previouslastContractDay.set({ year: moment().year(), month: 2, date: 31 });
    }

    this.ranges = {
      'Last 14 Days': [moment().subtract(14, 'days'), moment()],
      'Last 30 Days': [moment().subtract(30, 'days'), moment()],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'This Contract Year': [firstContractDay, lastContractDay],
      'Last Contract Year': [previousfirstContractDay, previouslastContractDay]
    };
  }

  setupTable() {
    merge(this.tableSort.sortChange, this.tablePaginator.page, this.refreshTable).pipe(
      startWith({}),
      switchMap(() => {
        this.showSpinner = true;
        switch (this.level) {
          case 0:
            this.tableDisplayColumns = this.subContractorDisplayColumns;
            return this.attendanceAnalysisService.getSubContractor(
              this.user.staffId,
              this.tablePaginator.pageIndex + 1,
              this.tablePaginator.pageSize,
              this.filterString,
              this.tableSort.active,
              this.tableSort.direction
            );
          case 1:
            this.tableDisplayColumns = this.centreDisplayColumns;
            return this.attendanceAnalysisService.getCentre(
              this.user.staffId,
              this.tablePaginator.pageIndex + 1,
              this.tablePaginator.pageSize,
              this.filterString,
              this.tableSort.active,
              this.tableSort.direction
            );
          case 2:
            this.tableDisplayColumns = this.learnerDisplayColumns;
            return this.attendanceAnalysisService.getLearners(
              this.user.staffId,
              this.tablePaginator.pageIndex + 1,
              this.tablePaginator.pageSize,
              this.filterString,
              this.tableSort.active,
              this.tableSort.direction
            );
        }
      }),
      map((data: PaginationResults<any>) => {
        if (data == null) return [];
        this.totalData = data.totalRecords;
        return data.data;
      })
    ).subscribe((data: any) => {
      if (data.data !== undefined) {
        //LEVEL 0 + LEVEL 1
        this.tableData = data.data;
      }
      else {
        //LEVEL 2 (JUST LEARNERS)
        this.tableData = data;
      }
      if (data.total !== undefined) {
        //LEVEL 0 + LEVEL 1
        this.totals = data.total;
      }
      else {
        //LEVEL 2 (JUST LEARNERS)
        this.totals = [];
      }
      this.tableDataSource = new MatTableDataSource<any>(this.tableData);
      this.showSpinner = false;
      this.cdr.detectChanges();
    });
  }

  dateRangeChange(event: any) {
    if (event.startDate !== null || event.endDate !== null) {
      const startDate: moment.Moment = event.startDate;
      const endDate: moment.Moment = event.endDate;
      this.noDateSelected = false;
      this.dateString = `startDate::${startDate.format('DD-MM-YYYY')},endDate::${endDate.format('DD-MM-YYYY')}`;
      this.filterBuilder();
      this.refreshTable.emit();
      this.cdr.detectChanges();
    }
    else {
      this.totals = [];
      this.tableData = [];
      this.tableDataSource = new MatTableDataSource<any>(this.tableData);
      this.noDateSelected = true;
      this.dateString = '';
      this.cdr.detectChanges();
    }
  }

  applyTextFilter(value) {
    this.rawTextFilterString = value;
    this.filterBuilder();
    this.refreshTable.emit();
    this.cdr.detectChanges();
  }

  clearTextFilter() {
    this.rawTextFilterString = "";
  }

  filterBuilder() {
    switch (this.level) {
      case 0:
      default:
        this.filterString = `${this.dateString}`;
        break;
      case 1:
      case 2:
        this.filterString = `${this.dateString},${this.drillDownString}`;
        break;
    }
    if (this.selectedScheme.length > 0) {
      console.dir(this.selectedScheme);
      this.filterString += `,scheme::${this.selectedScheme}`;
    }
    if (this.rawTextFilterString) {
      switch (this.level) {
        case 0:
          this.textFilterString = `subcontractor::${this.rawTextFilterString}`;
          break;
        case 1:
          this.textFilterString = `centre::${this.rawTextFilterString}`;
          break;
        case 2:
          this.textFilterString = `learner::${this.rawTextFilterString}`;
          break;
      }
      this.filterString += `,${this.textFilterString}`;
    }
    this.attendanceTableService.addAnalysis(this.level, this.rawTextFilterString, this.dateString, this.selectedScheme, this.drillDownString, this.subContractorName, this.centreName);
  }

  drillDown(id: number, name: string) {
    this.level++;
    this.totals = [];
    this.tableData = [];
    this.tableDataSource = new MatTableDataSource<any>(this.tableData);
    this.tablePaginator.pageIndex = 0;
    switch (this.level) {
      case 1:
        this.tableSort.sort(({ id: this.centreDisplayColumns[0], start: 'asc' }) as MatSortable);
        this.subContractorName = name;
        this.subContractorId = id;
        this.drillDownString += `subcontractorId::${id}`;
        break;
      case 2:
        this.tableSort.sort(({ id: this.learnerDisplayColumns[0], start: 'asc' }) as MatSortable);
        this.centreName = name;
        this.centreId = id;
        this.drillDownString += `,centreId::${id}`;
        break;
    }
    this.clearTextFilter();
    this.filterBuilder();
    this.refreshTable.emit(true);
    this.cdr.detectChanges();
  }

  goToCentre() {
    this.level--;
    this.totals = [];
    this.tableData = [];
    this.tableDataSource = new MatTableDataSource<any>(this.tableData);
    this.tablePaginator.pageIndex = 0;
    this.centreName = '';
    this.centreId = 0;
    this.drillDownString = this.drillDownString.split(',')[0];
    this.clearTextFilter();
    this.filterBuilder();
    this.refreshTable.emit(true);
    this.cdr.detectChanges();
  }

  resetDrillDown() {
    this.tableSort.sort(({ id: this.subContractorDisplayColumns[0], start: 'asc' }) as MatSortable);
    this.totals = [];
    this.tableData = [];
    this.tableDataSource = new MatTableDataSource<any>(this.tableData);
    this.level = 0;
    this.subContractorName = '';
    this.centreName = '';
    this.subContractorId = 0;
    this.centreId = 0;
    this.drillDownString = '';
    this.tablePaginator.pageIndex = 0;
    this.clearTextFilter();
    this.filterBuilder();
    this.refreshTable.emit(true);
    this.cdr.detectChanges();
  }

  indicatorColour(value: number): string {
    let colour = '#fff';
    if (value < 75) {
      colour = '#c92020';
    } else if (value >= 75 && value <= 79.9) {
      colour = '#f7700f';
    } else if (value >= 80 && value <= 89.9) {
      colour = '#9afc9f';
    } else if (value >= 90) {
      colour = '#02c21c';
    }
    return colour;
  }

  getOverallAverage(column: string) {
    if (this.totals.length > 0) {
      if (column === 'subcontractor' || column === 'centre') {
        return 'Overall Average';
      }
      else {
        return `${this.totals[0][column]}%`;
      }
    }
  }
  getSubContractorAverage(column: string) {
    const columnName = column.replace('AdditionalFooter', '');
    if (this.totals.length > 1) {
      if (columnName === 'subcontractor' || columnName === 'centre') {
        return 'Subcontractor Average';
      }
      else {
        return `${this.totals[1][columnName]}%`;
      }
    }
  }
  schemeChange(scheme: any) {
    this.selectedScheme = scheme;
    this.filterBuilder();
    this.refreshTable.emit();
    this.cdr.detectChanges();
  }

  schemeSelectCleared() {
    this.selectedScheme = [];
    this.filterBuilder();
    this.refreshTable.emit();
    this.cdr.detectChanges();

  }

  export(fileType) {
    let columns: string;
    let reportExportNumber: number;
    const sortString = `${this.tableSort.active}::${this.tableSort.direction}`;
    switch (this.level) {
      case 0:
        columns = this.subContractorDisplayColumns.join(";");
        reportExportNumber = AdditionalExport.AttendanceAnalysisSubContractor;
        break;
      case 1:
        columns = this.centreDisplayColumns.join(";");
        reportExportNumber = AdditionalExport.AttendanceAnalysisCentre;
        break;
      case 2:
        columns = this.learnerDisplayColumns.join(";");
        reportExportNumber = AdditionalExport.AttendanceAnalysisLearner;
        break;
    }
    this.classicReportService.postReportExport(reportExportNumber, this.filterString, sortString, columns, fileType).subscribe({
      next: (data: any) => {
        this.snackBar.open(data.body, 'Close');
      },
      error: (error: any) => {
        this.snackBar.open(error, "Close");
      }
    });
  }

}
