import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialsModule } from './../core/materials/materials.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EmployerDashboardRoutingModule } from './../employer-dashboard/employer-dashboard-routing.module';
import { EmployerSearchComponent } from './../employer-search/employer-search.component';
import { EmployerDashboardComponent } from './employer-dashboard.component';
import { EmployerLearnerListComponent } from './../employer/employer-learner-list/employer-learner-list.component'
import { EmployerHAndSComponent } from './../employer/employer-h-and-s/employer-h-and-s.component'
import { EmployerAttachmentsComponent } from './../employer/employer-attachments/employer-attachments.component'
import { EmployerMaytasInfoComponent } from './../employer/employer-maytas-info/employer-maytas-info.component'
import { EmployerMaytasAddLinkComponent } from './../employer/employer-maytas-info/employer-maytas-add-link/employer-maytas-add-link.component';
import { EmployerBasicsComponent } from './../employer/employer-basics/employer-basics.component'
import { EmployerContactsComponent } from './../employer/employer-contacts/employer-contacts.component'
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerEventsComponent } from '../employer/employer-events/employer-events.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatCardModule } from '@angular/material/card';
import { AddEmployerEventComponent } from '../employer/employer-events/add-employer-event/add-employer-event.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmployerEventsService } from '../employer/employer-events/employer-events.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TimeRangeHelperService } from './../shared/services/time-range-helper.service';
import { AddBranchDialogComponent } from './add-branch-dialog/add-branch-dialog.component'
import { AddEmployerDialogComponent } from '../employer-search/add-employer-dialog/add-employer-dialog.component';
@NgModule({
  declarations: [
    EmployerDashboardComponent,
    EmployerSearchComponent,
    EmployerContactsComponent,
    EmployerLearnerListComponent,
    EmployerHAndSComponent,
    EmployerAttachmentsComponent,
    EmployerMaytasInfoComponent,
    EmployerBasicsComponent,
    EmployerEventsComponent,
    AddEmployerEventComponent,
    EmployerMaytasAddLinkComponent,
    AddBranchDialogComponent,
    AddEmployerDialogComponent
  ],
  imports: [
    CommonModule,
    EmployerDashboardRoutingModule,
    FlexLayoutModule,
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CKEditorModule,
    MatCardModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgSelectModule,
    NgxDaterangepickerMd
  ],
  providers: [
    EmployerEventsService, TimeRangeHelperService
  ]
})
export class EmployerDashboardModule { }
