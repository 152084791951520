import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { InfoDialog } from "src/app/shared/interfaces/info-dialog";


@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InfoDialog,
    private dialogRef: MatDialogRef<InfoDialogComponent>,
  ) { }

  closeDialog(action: string): void {
    this.dialogRef.close(action);
  }
}
