import { ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';

@Component({
  selector: 'app-contract-management',
  templateUrl: './contract-management.component.html',
  styleUrls: ['./contract-management.component.scss']
})
export class ContractManagementComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @ViewChild('subcontractorSelect') subcontractorSelect;
  @ViewChild('contractSelect') contractSelect;
  pdfResult: any;
  company: string;
  loading: boolean = false;
  waitingForData: boolean = true;

  subcontractors: { subcontractorId: number, subcontractor: string }[] = [];
  contractYearIds: { contractYearId: number, contractYear: string }[] = [];

  constructor(
    private homeDashboardService: HomeDashboardService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  setData(data: any) {
    this.subcontractors = data.subContractor;
    this.contractYearIds = data.contractYears;
    this.waitingForData = false;
    this.cdr.detectChanges();
  }

  getRemittanceReport() {
    if (this.subcontractorSelect.value !== undefined && this.contractSelect.value !== undefined) {
      this.loading = true;
      this.cdr.detectChanges();
      let subcontractorId = this.subcontractorSelect.value;
      let contractYearId = this.contractSelect.value;
      this.homeDashboardService.GetRemittanceReport(subcontractorId, contractYearId).subscribe(data => {

        let response = this.base64ToArrayBuffer(data.bytes);
        let file = new Blob([response], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.loading = false;
        this.cdr.detectChanges();
      })
    }
  }


  base64ToArrayBuffer(base64: any): ArrayBuffer {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
