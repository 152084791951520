import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AttendancePageService } from "../attendance-page.service";
import { AttendanceDynamicDirective } from "../attendance-dynamic.directive";
import { AttendanceTabComponent } from "../attendance-tab.component";

@Component({
  selector: 'app-attendance-template',
  templateUrl: './attendance-template.component.html',
  styleUrls: ['./attendance-template.component.scss']
})
export class AttendanceTemplateComponent implements OnInit {
  @ViewChild(AttendanceDynamicDirective, { static: true }) attendanceHost!: AttendanceDynamicDirective;
  tabName: string;
  staffId: number;
  attendancePages: object;

  constructor(
    private route: ActivatedRoute,
    private attendancePageService: AttendancePageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.tabName = this.route.snapshot.params.tabName;
    this.staffId = this.route.snapshot.data.learner.staffId;
    this.attendancePages = this.attendancePageService.getAttendanceTabs();
    this.loadPage(this.tabName);
  }

  loadPage(tabName: string) {
    if (this.attendancePages[tabName] === undefined) {
      this.router.navigate(['/404'], { skipLocationChange: true }).then(() => console.log('Error Message: Tab not recognised'));
      return;
    }
    this.tabName = tabName;
    //Add it to the history so we can use the back button
    window.history.pushState({}, '', `/attendance/${this.tabName}`);
    const viewContainerRef = this.attendanceHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<AttendanceTabComponent>(this.attendancePages[tabName].component);
    componentRef.instance.staffId = this.staffId;
  }
}
