import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import * as _ from 'underscore';
import moment from 'moment';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-review-record-form',
  templateUrl: './review-record-form.component.html',
  styleUrls: ['./review-record-form.component.scss']
})


export class ReviewRecordFormComponent implements OnInit {

  reviewForm: UntypedFormGroup;
  dateFormat = DATE_FORMAT;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReviewRecordFormComponent>,
    //private userService: UserTableService,
    private fb: UntypedFormBuilder
    ) { this.buildForm();}

  ngOnInit(): void {
    const widthCalc = (window.innerWidth * 0.33) - 105
    this.dialogRef.updatePosition({right: `${widthCalc}px`});
    this.reviewForm.patchValue({
      outcome: this.data.Outcome,
      reviewDate: moment(this.data.Date, 'DD/MM/YYYY').toDate(),
      reviewType: this.data.ReviewType,
      notes: this.data.Notes,
      fullDetails: this.data.FullDetails
    })

  }

  buildForm() {
    this.reviewForm = this.fb.group({
    reviewType: '',
    reviewDate: '',
    outcome: '',
    notes: '',
    fullDetails: '',
    });
}

  // getStaffId() {
  //   this.userService.get(1).then(
  //     value => {
  //       this.staffId = value.staffId;
  //       return this.staffId
  //     })
  // };

  close() {
    this.dialogRef.close();
    //console.log(this.staffId);
  }

}

