import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmployerLearnerFilter } from '../shared/interfaces/employerLearnerFilter';
import { EmployerContact } from '../shared/interfaces/employer-contacts';
import { Sort } from '@angular/material/sort';
import { BdHomepageEventFilter } from '../shared/interfaces/employer-event-filter';
import { EmployerEvent } from '../shared/interfaces/employer-event';
import { ApiHelper } from '../shared/api-helper';
import { EventEmitter } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class BdHomepageService extends ApiHelper {


  getEvents(staffId: number, filters: BdHomepageEventFilter, pageNumber: number, pageSize: number): Observable<any> {

    const pagination = {
      skip: pageNumber * pageSize,
      take: pageSize
    }

    const params = new HttpParams()
      .set("filters", JSON.stringify(filters))
      .set("pagination", JSON.stringify(pagination))
      .set("staffId", staffId.toString());

    const url = `${environment.API_URL}v2/BdHomepage/Events`;
    return this.getWithAccessToken(url, params);
  }

  getEventFilterOptions() {
    return this.getWithAccessToken(`${environment.API_URL}v2/BdHomepage/Events/FilterOptions`);
  }

}
