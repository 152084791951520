import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LearnerPageService } from 'src/app/learner/learner-page.service';
import { AttendancePageService } from '../../attendance-page.service';


@Component({
  selector: 'attendance-tabs',
  templateUrl: './attendance-tabs.component.html',
  styleUrls: ['./attendance-tabs.component.scss']
})
export class AttendanceTabsComponent implements OnInit, OnChanges {

  @Input() currentTab: string;

  @Output() loadPageEvent = new EventEmitter<string>();

  attendanceTabs: { 'label': string, 'tabName': string, 'order': number }[];
  selectedTab: { 'label': string, 'tabName': string, 'order': number };

  constructor(
    private attendancePageService: AttendancePageService,
  ) { }

  ngOnInit() {
    const attendanceTabObj = this.attendancePageService.getAttendanceTabs();

    this.attendanceTabs = Object.keys(attendanceTabObj).map((key) => {
      return {
        'label': attendanceTabObj[key].label,
        'tabName': attendanceTabObj[key].tabName,
        'order': attendanceTabObj[key].order,
      };
    }).sort((a, b) => a.order - b.order);

    this.selectedTab = this.attendanceTabs.find(page => page.tabName === this.currentTab);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!simpleChanges.currentTab.firstChange && simpleChanges.currentTab.currentValue !== simpleChanges.currentTab.previousValue) {
      this.selectedTab = this.attendanceTabs.find(page => page.tabName === this.currentTab);
    }
  }

  loadPage(pageName: string) {
    this.loadPageEvent.emit(pageName);
  }

}

