import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataListComponent } from '../data-list/data-list.component';

@Component({
  selector: 'app-data-summary-card',
  templateUrl: './data-summary-card.component.html',
  styleUrls: ['./data-summary-card.component.scss']
})
export class DataSummaryCardComponent implements OnInit {

  constructor(private changeDetectorRef: ChangeDetectorRef, private dialog: MatDialog) { }
  caseloadSummary: any;
  size: number;
  onTrack: number;
  overEED: number;
  sizePercentage: string;
  onTrackPercentage: string;
  overEEDPercentage: string;
  title: string;

  ngOnInit(): void {
  }

  setData(data, title="Caseload Summary") {
    this.title = title;
    this.caseloadSummary = data;
    const caseloadTarget = data.caseloadTarget ?? 40;
    this.size = this.caseloadSummary['caseloadSize'];
    console.log("checking", this.size, caseloadTarget)
    this.sizePercentage = '(' + ((this.size / caseloadTarget) * 100).toFixed(2) + '%)'

    if(this.size == 0){
      this.sizePercentage = '-';
    }

 

    this.onTrack = this.caseloadSummary['caseloadOnTrack'];
    this.onTrackPercentage = '(' + ((this.onTrack / this.size) * 100).toFixed(2) + '%)' 

    if(this.size == 0){
      this.onTrack = 0;
      this.onTrackPercentage = '-';
    }

    this.overEED = this.caseloadSummary['caseloadOverEED'];
    this.overEEDPercentage = '(' + ((this.overEED / this.size) * 100).toFixed(2) + '%)'

    if(this.size == 0){
      this.overEED = 0;
      this.overEEDPercentage = '-';
    }

    this.changeDetectorRef.detectChanges();
  }

  openDialog(filter) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    if (filter == 'size') { dialogConfig.data = this.caseloadSummary.caseload };
    if (filter == 'onTrack') { dialogConfig.data = this.caseloadSummary.caseload.filter(a=> a.onTrack == 'Y') };
    if (filter == 'overEED') { dialogConfig.data = this.caseloadSummary.caseload.filter(a=> a.overEED == 'Y') };
    const dialogRef = this.dialog.open(DataListComponent, dialogConfig);
  }

}