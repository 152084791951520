import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { UserTableService } from '../core/database/user-table.service';
import { AuthErrors } from '../shared/enums/auth-errors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-force-log-out',
  template: '<span></span>',
})
export class ForceLogOutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.navigate([`auth-error`], { queryParams: { error: AuthErrors.ForceLogout } });
    //this.authService.logout();
  }

}