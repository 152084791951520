<div style="float:right">
  <mat-dialog-actions class="close">
    <button mat-button (click)="close()" style="margin-bottom:30px">Close</button> <br>
  </mat-dialog-actions>
</div>

<form [formGroup]="submitForIQAForm" novalidate (ngSubmit)="close()">

  <mat-dialog-content class="submit-for-iqa-form">
    <mat-divider class="iqaformDivider"></mat-divider>
    <h2 mat-dialog-title class="IQAtitle" style="margin-bottom: 2px;">Submit for IQA</h2>

    <mat-radio-group formControlName="portfolioType">
      <mat-radio-button class="example-margin" value="e-Portfolio">
        e-Portfolio
      </mat-radio-button>
      <mat-radio-button class="example-margin" value="Paper Portfolio">
        Paper Portfolio
      </mat-radio-button>
    </mat-radio-group>

    <ng-select appearance="outline" class="dropdown-select" #select placeholder="Select Qualification" dropdownPosition="bottom"
      formControlName="objective">
      <ng-option *ngFor="let obj of data.objectives" [value]="obj.laimRef">
        {{obj.title}}
      </ng-option>
    </ng-select>

    <ng-select appearance="outline" class="dropdown-select" #select placeholder="IQA Type" formControlName="iqaType" dropdownPosition="bottom">
      <ng-option *ngFor="let option of data.formOptions.iqaFormOptionIQAType" [value]="option.iqaTypeId">
        {{option.iqaType}}
      </ng-option>
    </ng-select>

    <mat-radio-group formControlName="priority">
      <mat-radio-button class="example-margin" value="Priority">
        Priority
      </mat-radio-button>
      <mat-radio-button class="example-margin" value="Non-Priority">
        Non-Priority
      </mat-radio-button>
    </mat-radio-group>

    <mat-dialog-actions>
      <button class="mat-raised-button mat-primary" [disabled]="!submitForIQAForm.valid"
        [mat-dialog-close]="submitForIQAForm">Submit</button>
    </mat-dialog-actions>

  </mat-dialog-content>
</form>

<!-- bindLabel="objective" bindValue="" -->
