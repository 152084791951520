import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
    selector: 'app-review-analysis-report',
    templateUrl: './review-analysis-report.component.html'
})
export class ReviewAnalysisReportComponentV2 {
    constructor(
        private sharedFunctions: SharedFunctionsService,
    ) { }

    reportVariant = ReportVariant.ReviewAnalysis;
    defaultDisplayColumns = [
        {
            type: 'default',
            columns: ['fullName', 'reviewDate', 'scheme', 'sector', 'centre', 'assessor', 'startDate', 'expectedCompletionDate', 'progress', 'potStatus']
        }
    ];
    dateFilters = ['reviewDate', 'startDate', 'expectedCompletionDate', 'terminationDate'];
    otherColumns = ['scheme', 'count'];
    groupByColumns: ISummaryGroupBy[] = [
        {
            value: 'assessorBreakdownWeekly', title: 'Assessor Weekly Breakdown', columns: ['assessor', 'contractYear', 'weekCommencing', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'total']
        },
        {
            value: 'assessorBreakdownMonthly', title: 'Assessor Monthly Breakdown', columns: ['assessor', 'contractYear', 'month', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'total']
        },
        {
            value: 'employerSignatureBySector', title: 'Employer Signature By Sector', columns: ['sector', 'digitalReviews', 'signatureMissingErrors', 'signingTimesErrors', 'totalErrors', 'employerSignaturePercentage', 'successfulPercentage']
        },
        {
            value: 'employerSignatureBySectorAndAssessor', title: 'Employer Signature By Sector and Assessor', columns: ['sector', 'assessor', 'digitalReviews', 'signatureMissingErrors', 'signingTimesErrors', 'totalErrors', 'employerSignaturePercentage', 'successfulPercentage']
        },
        {
            value: 'digitalReviewBySector', title: 'Digital Review By Sector', columns: ['digitalReviewBySector', 'count', 'signatureMissingError', 'signinTimeError', 'totalErrors', 'digitalEmployerSignaturePercent', 'successfulPercent'],
            pieChartDrillDown: 'totalErrors', pieChartShareValue: 'totalErrors'
        },
        {
            value: 'month', title: 'Reviews by week', columns: ['month', 'weekStarting', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'weekTotal'],
            pieChartDrillDown: 'weekStarting', pieChartShareValue: 'weekTotal'
        },
        {
            value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
            pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
        },
        {
            value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
            pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
        },
        {
            value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
            pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
        },
        {
            value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
            pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
        },
        {
            value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
            pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
        },
    ];
}

