<h1 mat-dialog-title>Load Saved Filter</h1>
<div mat-dialog-content style="min-height: 136px;">
  <div *ngIf="data.storedFilters.length > 0; else noSavedFilters">
    <div style="display: flex; flex-direction: column; gap: 5px;">
      <div class="stored-filter-row" *ngFor="let stored of data.storedFilters">
        <div style="flex: 1; margin-left: 10px"> {{stored.name}} </div>
        <button mat-flat-button color="primary" class="load-button" (click)="loadStoredFilter(stored)">Load
        </button>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions style="float: right; margin-top: 20px">
  <button class="close-button" mat-stroked-button (click)="close()">Close</button>
</div>

<ng-template #noSavedFilters>
  <div class="no-saved-filters">
    <p>No saved filters for this report.</p>
  </div>
</ng-template>