import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { select } from "underscore";

@Component({
  selector: 'app-cancelled-appointments-report',
  templateUrl: './cancelled-appointments-report.component.html',
})
export class CancelledAppointmentsReportComponent {

  reportName = "Cancelled Appointments Report";
  reportVariant = ReportVariant.CancelledAppointments;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: [
        'fullname',
        'sector',
        'programmeType',
        'potStatus',
        'mostRecentCancellationDate',
        'cancelled3MonthsPrior',
        'totalCancelled',
        'startDate',
        'expCompletionDate'
      ]
    }
  ];
  dateFilters = [
    'startDate',
    'expCompletionDate',
    'mostRecentCancellationDate'
  ];
  otherColumns = ['scheme', 'count'];

}
