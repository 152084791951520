import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'learner-details-triple-entry',
  templateUrl: './learner-details-triple-entry.component.html',
  styleUrls: ['./learner-details-triple-entry.component.css']
})
export class LearnerDetailsTripleEntryComponent implements OnInit {
  @Input() type;
  @Input() labelOneName;
  @Input() labelOneValue;
  @Input() labelTwoName;
  @Input() labelTwoValue;
  @Input() labelThreeName;
  @Input() labelThreeValue;
  constructor() { }

  ngOnInit(): void {
  }

}
