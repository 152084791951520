<article>
  <!-- Search bar -->
  <section
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); position: fixed; width: 100%; z-index: 10; padding-top: 2rem; background-color: #F9F6F2;">
    <div style="margin: 0 auto; width: 50rem;">
      <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">

        <div style="display: flex; flex-direction: row; align-items: center; gap: 4px">
          <ng-select class="category-select" style="width: 8rem" appearance="outline" placeholder="Category"
            [items]="this.categories" bindLabel="label" bindValue="value" formControlName="category"
            [clearOnBackspace]="false" [clearable]="false" dropdownPosition="below" [searchable]="false">
          </ng-select>


          <input id="term" class="learner-search" formControlName="term" placeholder="Search..." />

          <button mat-raised-button color="primary" type="submit"
            style="width: 7rem; height: 2.5rem; margin-bottom: 15px;">
            Search
          </button>
          <div *ngIf="this.totalCount !== null && !this.isLoadingSearch" style="
            position: absolute;
            right: 68px;
            top: 80px;
            text-align: right;
            font-size: 14px;">
            {{ this.totalCount }} results
          </div>
        </div>

      </form>
    </div>
    <mat-progress-bar *ngIf="this.isLoadingSearch" mode="indeterminate"
      [color]="this.isLoadingSearch ? 'primary' : 'accent'" style="position: fixed; top: 95px;"></mat-progress-bar>
  </section>

  <!-- Filters -->
  <mat-sidenav-container>
    <mat-sidenav #filters [fixedInViewport]="true" [fixedTopGap]="97" mode="side" opened style="border-right: 0;">

      <section class="sidebar-container">
        <div class="filter-header-container">
          <h2 style="margin-top: 4px;">Filters</h2>
          <div>
            <button class="filterButtons" (click)="this.clearFilters()" style="margin-right: 8px;">
              Clear
            </button>
            <button *ngIf="filters.opened" mat-button class="filterButtons"
              (click)="filters.toggle(); this.onSidenavOpenedChange(false);" aria-label="Hide Filters">
              <img src="../../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Hide Filters">
            </button>
          </div>
        </div>
        <div class="filter-container">
          <div class="filters">
            <form [formGroup]="filterForm" style="width: 100%;">

              <div class="active-toggle">
                <mat-slide-toggle formControlName="activeOnly">
                  Active Only
                </mat-slide-toggle>
              </div>

              <div *ngFor="let filter of filterOptions | keyvalue">
                <ng-select class="filter-select" multiple="true" appearance="outline" [items]="filter.value"
                  [loading]="this.isLoadingFilterOptions" bindLabel="name"
                  placeholder="{{ capitalizeFirstLetter(filter.key) }}" [clearOnBackspace]="false"
                  [closeOnSelect]="false" virtualScroll="true" formControlName="{{ filter.key }}"
                  (open)="ngSelectOpenOrClose(itemRef)" (close)="ngSelectOpenOrClose(itemRef)" [searchable]="false"
                  dropdownPosition="auto" [notFoundText]="this.filterError ? this.dataError : 'No items found'"
                  #itemRef>
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:4">
                      <span class="ng-value-label">{{item.name}} </span>
                      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 4">
                      <span class="ng-value-label"
                        matTooltip="{{ generateMoreTooltipText(this.filterForm.get(filter.key).value) }}">
                        {{items.length - 4}} more
                      </span>
                    </div>
                  </ng-template>
                </ng-select>
              </div>

            </form>

            <button mat-raised-button color="primary" (click)="onSubmit()" style="width:100%; margin-top: 0.5rem"
              [disabled]="!this.applyFiltersButton">
              Apply Filters
            </button>
          </div>

        </div>
      </section>

    </mat-sidenav>

    <!-- Learners -->
    <mat-sidenav-content style="margin-top: 97px; height: calc(100vh - 97px);">
      <div *ngIf="!filters.opened"
        style="background-color: #473B8C; position: fixed; left: 52px; top: calc(98px + 0.5rem); z-index: 200;">
        <button mat-button class="filterButtons" (click)="filters.toggle(); this.onSidenavOpenedChange(true);"
          aria-label="Show Filters">
          <img src="../../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Hide Filters">
        </button>
      </div>
      <section style="height: inherit;">
        <ng-content>

        </ng-content>
      </section>
    </mat-sidenav-content>

  </mat-sidenav-container>

</article>