import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportsV2Service } from '../reports.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-share-report-filters-dialog',
  templateUrl: './share-report-filters-dialog.component.html',
  styleUrls: ['./share-report-filters-dialog.component.scss']
})
export class ShareReportFiltersDialogComponentV2 implements OnInit {
  shareFiltersForm: UntypedFormGroup;
  staff: Observable<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShareReportFiltersDialogComponentV2>,
    private formBuilder: UntypedFormBuilder,
    private reportsService: ReportsV2Service,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.staff = this.reportsService.getStaff();
    this.buildForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOK(): void {
    if (!this.shareFiltersForm.value.name) {
      const snackBarMessage = "Please choose a name for this filter."
      this.snackBar.open(snackBarMessage,
        'Close', { duration: 5000 });
    } else if (this.shareFiltersForm.value.selectedStaff === null || this.shareFiltersForm.value.selectedStaff.length === 0) {
      const snackBarMessage = "Please choose at least one staff member to share to."
      this.snackBar.open(snackBarMessage,
        'Close', { duration: 5000 });
    } else {
      this.dialogRef.close(this.shareFiltersForm.value);
    }
  }

  buildForm() {
    this.shareFiltersForm = this.formBuilder.group({
      isSaveFilters: [true, Validators.required],
      name: null,
      selectedStaff: null
    });
  }
}
