import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import moment from 'moment';
import * as _ from 'underscore';

@Component({
  selector: 'app-employer-h-and-s',
  templateUrl: './employer-h-and-s.component.html',
  styleUrls: ['./employer-h-and-s.component.scss']
})
export class EmployerHAndSComponent implements OnInit {

  @Input() hsInfo: any;
  @Input() isEditForm: boolean;
  @Input() riskBandings: any[];
  @Input() currentRiskId: number;
  @Output() hsInfoUpdate = new EventEmitter();

  hsInfoForm: any;
  riskRatingForm: any;
  toggleEdit: boolean = false;
  //masterhsInfo: any;

  constructor(private fb: UntypedFormBuilder) {
    this.hsInfoForm = new UntypedFormGroup({}),
    this.riskRatingForm = new UntypedFormGroup({})
  }

  ngOnInit(): void {
    this.buildHsInfoForm();
    this.buildRiskRatingForm();
    //this.masterhsInfo = _.clone(this.hsInfo);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes)
    if (changes.isEditForm) {
      if (changes.isEditForm.currentValue) {
        //this.hsInfoForm.enable();
        this.riskRatingForm.enable();
      }
      else {
        //this.hsInfoForm.disable();
        this.riskRatingForm.disable();
      }
    }
    // if (changes.hsInfo) {
    //   if (changes.hsInfo.currentValue != this.masterhsInfo){
    //     this.buildHsInfoForm();
    //   }
    // }
  }

  buildHsInfoForm(){
    this.hsInfoForm = this.fb.group({
      'Monitor Last': this.hsInfo && this.hsInfo.monitorLast ? moment(this.hsInfo.monitorLast).format('DD-MM-YYYY') : '-',
      'Monitor Next': this.hsInfo && this.hsInfo.monitorNext ? moment(this.hsInfo.monitorNext).format('DD-MM-YYYY') : '-',
      'Appraisal Last': this.hsInfo && this.hsInfo.appraisalLast ? moment(this.hsInfo.appraisalLast).format('DD-MM-YYYY') : '-',
      'Appraisal Next': this.hsInfo && this.hsInfo.appraisalNext ? moment(this.hsInfo.appraisalNext).format('DD-MM-YYYY') : '-',
      'ELI Next': this.hsInfo && this.hsInfo.eliNext ? moment(this.hsInfo.eliNext).format('DD-MM-YYYY') : '-'
    });
    this.hsInfoForm.disable();
  }

  buildRiskRatingForm(){
    this.riskRatingForm = this.fb.group({
      'riskRating': this.riskBandings
    })
    this.riskRatingForm.get('riskRating').value = this.currentRiskId;
    this.riskRatingForm.disable();
  }

  emitData(){
    console.log('emit data from health and safety');
    if (this.riskRatingForm.dirty) {
      let values = {riskBandingId: this.riskRatingForm.value.riskRating };
      const returnData = { values: values, operation: 'update' };
      console.log('sending: ', returnData);
      this.hsInfoUpdate.emit({ 'hsInfo': returnData });
      this.riskRatingForm.markAsUntouched();
      this.riskRatingForm.pristine = true;
    }
    else {
      console.log('no changes to emit');
    }
  }
}
