import { IReportDetail } from '../../shared/interfaces/report-detail';
import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

@Injectable()
export class FavouriteTableService {
  table: Dexie.Table<IReportDetail, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('favourites');
  }

  get(id) {
    return this.table.get(id);
  }

  getAll() {
    return this.table.toArray();
  }

  add(data: IReportDetail, id) {
    return this.table.add(data, id);
  }

  put(data, id) {
    return this.table.put(data, id);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }

  clear() {
    return this.table.clear();
  }
}
