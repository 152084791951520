<h1 mat-dialog-title>Add Note</h1>
<mat-dialog-content>
  <form [formGroup]="commentsForm">
    <div style="display:flex;justify-content: center;align-items: center;flex-direction: column;">
      <mat-form-field style="min-width: 45vw;">
        <mat-label>Leave a comment</mat-label>
        <textarea matInput formControlName="notes" rows="4"></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" (click)="onCancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="onSave()" cdkFocusInitial>Save</button>
</mat-dialog-actions>