import { ISmtDashReports } from '../../shared/interfaces/smt-dash-reports';
import { LocalDb } from './database.service';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';

export interface SmtDashReportsWithId extends ISmtDashReports {
  id: number;
}

@Injectable()
export class SmtDashReportsService {
  table: Dexie.Table<SmtDashReportsWithId, number>;

  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('smtDashReports');
  }

  get(id) {
    return this.table.get(id);
  }

  getAll() {
    return this.table.toArray();
  }

  add(data, id) {
    return this.table.add(data, id);
  }

  put(data, id) {
    return this.table.put(data, id);
  }

  update(id, data) {
    return this.table.update(id, data);
  }

  remove(id) {
    return this.table.delete(id);
  }

  clear() {
    return this.table.clear();
  }
}
