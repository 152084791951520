import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { LearnerPageService } from '../../learner-page.service';


@Component({
  selector: 'learner-summary-bar',
  templateUrl: './learner-summary-bar.component.html',
  styleUrls: ['./learner-summary-bar.component.css']
})
export class LearnerSummaryBarComponent implements OnInit, OnChanges {

  @Input() showAttendance: boolean = false;
  @Input() currentTab: string;

  @Output() loadPageEvent = new EventEmitter<string>();

  learnerPages: {'label': string, 'tabName': string, 'order': number}[];
  selectedTab: {'label': string, 'tabName': string, 'order': number};

  constructor(
    private learnerPageService: LearnerPageService,
    ) {}

  ngOnInit() {
    const learnerPageObj = this.learnerPageService.getLearnerPages();
    if(!this.showAttendance) {
      delete learnerPageObj.attendance;
    }
    this.learnerPages = Object.keys(learnerPageObj).map((key) => {
      return {
        'label': learnerPageObj[key].label,
        'tabName': learnerPageObj[key].tabName,
        'order': learnerPageObj[key].order,
      };
    }).sort((a, b) => a.order - b.order);

    this.selectedTab = this.learnerPages.find(page => page.tabName === this.currentTab);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if(!simpleChanges.currentTab.firstChange && simpleChanges.currentTab.currentValue !== simpleChanges.currentTab.previousValue) {
        this.selectedTab = this.learnerPages.find(page => page.tabName === this.currentTab);
    }
  }

  loadPage(pageName: string) {
    this.loadPageEvent.emit(pageName);
  }

}

