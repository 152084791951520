import { ChangeDetectorRef, Component, OnInit, ViewChild, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AttendanceApprovalEditComponent } from './attendance-approval-edit/attendance-approval-edit.component';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'app-attendance-approval',
  templateUrl: './attendance-approval.component.html',
  styleUrls: ['./attendance-approval.component.scss']
})
export class AttendanceApprovalComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @Input() staffId: number;
  @Output() attendanceApproval = new EventEmitter();

  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();
  rawData = [];
  awaitingApproval = [];
  approved = [];
  defaultCentreStartTime = '09:00:00';
  defaultCentreEndTime = '17:00:00';
  maxDailyHours = 10;
  showApproved = false;
  showApprovedLearnerName: string;
  timeTable: string;
  showSpinner = true;
  onlyMyLearnerStatus = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  awaitingApprovalColumns = ['select', 'name', 'assessor', 'centre', 'type', 'date', 'timein', 'timeout', 'approvedHours', 'edit']
  approvedColumns = ['name', 'assessor', 'centre', 'type', 'date', 'timein', 'timeout']
  displayedColumns = [...this.awaitingApprovalColumns];

  dateFormat = DATE_FORMAT;

  constructor(
    private dialog: MatDialog,
    private sharedFunctions: SharedFunctionsService,
    private homeDashService: HomeDashboardService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void { }

  setData(data) {
    console.log('Raw Data: ', this.rawData);
    this.awaitingApproval = data.filter(element => element['approved'] === null);
    this.approved = data.filter(element => element['approved'] === 1);

    // populates how any hours for that day has been approved already for that learner
    this.awaitingApproval.forEach(element => {
      this.rawData.filter(rawData =>
        rawData['traineeid'] === element['traineeid'] && rawData['date'] === element['date'] && rawData['approved'] === 1
      ).forEach(approved => {
        const hours = this.approvedHoursForLearner(approved);
        element['approvedHours'] = element['approvedHours'] ? element['approvedHours'] + hours : hours;
      });
      element['approvedHours'] = element['approvedHours'] ? element['approvedHours'] : 0;
    });

    this.awaitingApproval.forEach(element => {
      if (element['approvedHours'] !== 0) {
        element['approvedHours'] = `${Math.floor(element['approvedHours'])}:${(element['approvedHours'] % 1 * 60).toLocaleString('en-GB', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })}`;
      }
      element['centreStartTime'] = element['centreStartTime'] ? element['centreStartTime'] : this.defaultCentreStartTime;
      element['centreEndTime'] = element['centreEndTime'] ? element['centreEndTime'] : this.defaultCentreEndTime;
      element['learnerSignOut'] = element['timeout'];
    });

    this.dataSource = new MatTableDataSource(this.awaitingApproval.filter(x => x.yourLearner == true));
    this.selection = new SelectionModel<typeof data>(true, []);

    // Resctricting the filter search so it only is based on visable columns values
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      const timeIn = data.timein.substring(0, 5);
      const timeOut = data.timeout.substring(0, 5);
      const date = this.datePipe.transform(data.date, this.dateFormat);
      const string = data.name.toLowerCase() + data.assessor.toLowerCase() + data.centre.toLowerCase() +
        data.type.toLowerCase() + timeIn + timeOut + date;
      return !filter || string.includes(filter);
    }
    this.showSpinner = false;
  }

  paginateAndSort() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    // When you filter it will clear the selection so that items you selected dont become hidden
    // There could be a better solution of displaying the selected items in the table still
    this.selection.clear();
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    console.dir(filterValue);
    //console.log(this.dataSource.filteredData);
  }

  isDate(value, column): boolean {
    return this.sharedFunctions.isDateFromColumnName(value, column);
  }

  asTime(value) {
    return value.substring(0, 5);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  edit(data) {
    if (!this.selection.isSelected(data)) {
      this.selection.toggle(data);
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(AttendanceApprovalEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        console.log('Update: ', formData);
        let values = formData.value;
        let updateIdx = this.dataSource.data.findIndex(obj => { return obj['signid'] == values.signid });
        const timein = values['Time In'] + ':00.000';
        const timeout = values['Time Out'] + ':00.000';
        this.dataSource.data[updateIdx]['timein'] = timein;
        this.dataSource.data[updateIdx]['timeout'] = timeout;
        this.dataSource.data[updateIdx]['commentsTimeInNew'] = values['Time In Comments'];
        this.dataSource.data[updateIdx]['commentsTimeOutNow'] = values['Time Out Comments'];
        console.log('Updated Data: ', this.dataSource.data[updateIdx]);
        this.cdr.detectChanges();
      }
    }

    )
  }

  // Decision 1 = approve and 0 = reject
  approveOrReject(decision: number) {
    this.showSpinner = true;
    this.cdr.detectChanges();
    if (decision === 1) {
      this.validateApproval();
    } else {
      this.postAttendanceApproval(0);
    }
  }

  validateApproval() {
    let autoSignout = false;
    let autoSignoutNames = [];
    let selectionPastFive = false;
    let pastEndNames = [];
    let selectionBeforeOpen = false;
    let beforeOpenNames = [];
    let selectionTimeOutBeforeTimeIn = false;
    let timeOutBeforeTimeInNames = [];
    let hoursForEachSelectedLearnerPerDay: { traineeid: string, name: string, date: string, hours: number }[] = [];
    let selectionOverDayHoursLimit = false;
    let overDayHoursLimitNamesAndHours: { name: string, hours: number }[] = [];
    let times;
    this.selection.selected.forEach(element => {
      const centerStartTime = element['centreStartTime'];
      const centerEndTime = element['centreEndTime'];
      if (element['timeout'] === '17:00:00') {
        autoSignoutNames.push(element['name']);
        autoSignout = true;
      } else if (this.asTime(element['timeout']) > this.asTime(centerEndTime)
        || this.asTime(element['timein']) > this.asTime(centerEndTime)) {
        pastEndNames.push(element['name']);
        selectionPastFive = true;
      } else if (this.asTime(element['timeout']) < this.asTime(centerStartTime)
        || this.asTime(element['timein']) < this.asTime(centerStartTime)) {
        beforeOpenNames.push(element['name']);
        selectionBeforeOpen = true;
      } else if (this.asTime(element['timeout']) < this.asTime(element['timein'])) {
        timeOutBeforeTimeInNames.push(element['name']);
        selectionTimeOutBeforeTimeIn = true;
      } else {
        // calculate hours for the day for each selected learner
        const hours = this.hoursBetweenStringTime(element['timein'], element['timeout']);

        const index = hoursForEachSelectedLearnerPerDay.findIndex(obj => obj.traineeid === element['traineeid'] && obj.date === element['date']);
        if (index !== -1) {
          hoursForEachSelectedLearnerPerDay[index].hours += hours < 0 ? 0 : hours;
        } else if (element.type !== null) { // null type is to guard against Unathorised absences - dont think we pull data with it though
          hoursForEachSelectedLearnerPerDay.push({
            traineeid: element['traineeid'],
            name: element['name'],
            date: element['date'],
            hours: hours < 0 ? 0 : hours
          });
        }
      }

    });

    // Add on any already approved hours for the day
    if (!this.showApproved) {
      hoursForEachSelectedLearnerPerDay.forEach(element => {
        const learnerSameDayAttendance = this.rawData.filter(data =>
          data['traineeid'] === element['traineeid'] && data['date'] === element['date'] && data['approved'] === 1
        );
        learnerSameDayAttendance.forEach(record => {
          const hours = this.approvedHoursForLearner(record);
          element.hours += hours < 0 ? 0 : hours;
        });
        if (element.hours > this.maxDailyHours) {
          selectionOverDayHoursLimit = true;
          overDayHoursLimitNamesAndHours.push({ name: element.name, hours: element.hours });
        }
      });
    }


    if (!autoSignout && !selectionPastFive && !selectionBeforeOpen && !selectionTimeOutBeforeTimeIn && !selectionOverDayHoursLimit) {
      this.postAttendanceApproval(1);
    } else {
      let msg = 'You cannot approve an attendance for the following learners and reasons:\n ';
      if (autoSignout) {
        msg += `Time out was automatically set:\
        ${autoSignoutNames.join(', ')}.\n`;
      }
      if (selectionPastFive) {
        msg += `Time in or out after centre closes:\
         ${pastEndNames.join(', ')}.\n`;
      }
      if (selectionBeforeOpen) {
        msg += `Time in is before centre opens:\
         ${beforeOpenNames.join(', ')}.\n`;
      }
      if (selectionTimeOutBeforeTimeIn) {
        msg += `Time out is before the time in:\
         ${timeOutBeforeTimeInNames.join(', ')}.\n`;
      }
      if (selectionOverDayHoursLimit) {
        msg += `Total hours for the day would be over ${this.maxDailyHours}:\
         ${overDayHoursLimitNamesAndHours
            .map(obj => `${obj.name} - total hours if approved: ${obj.hours.toFixed(2)}`)
            .join(', ')}.\n`;
      }

      msg += 'Please edit the attendance to correct issues.';

      this.snackBar.open(msg, 'Close', {
        duration: 10000,
        panelClass: ['snackbar-message']
      }).afterDismissed().subscribe(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      });
    }
  }

  postAttendanceApproval(decision: number) {
    this.homeDashService.approveAttendance(this.selection.selected, this.staffId, decision).subscribe(result => {
      let msg = '';
      let action = ''
      if (result === 'Success') {
        let text = decision == 1 ? 'approved' : 'rejected'
        msg = "Attendance " + text + " successfully";
        action = 'Success';
        this.attendanceApproval.emit({ 'learners': this.selection.selected });
      } else {
        let text = decision == 1 ? 'approve' : 'rejecte'
        msg = `Failed to ${text} attendance. Error: ${result}`;
        action = 'Error';
      }
      this.snackBar.open(msg, action, {
        duration: 3000
      }).afterDismissed().subscribe(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      });
    });
  }


  showApprovedTimesForDay(element: any) {
    this.selection.clear();
    this.displayedColumns = [...this.approvedColumns];
    const learnerSameDayAttendance = this.approved.filter(data => data['traineeid'] === element.traineeid && data['date'] === element.date);
    this.dataSource = new MatTableDataSource(learnerSameDayAttendance);
    this.showApproved = true;
    this.showApprovedLearnerName = element.name;
    this.timeTable = `/learner/attendance/${element.traineeid}/${element.pot}`;
    this.paginateAndSort();
    this.cdr.detectChanges();
  }

  resetToAwaitingApproval() {
    this.selection.clear();
    this.displayedColumns = [...this.awaitingApprovalColumns];
    this.dataSource = new MatTableDataSource(this.awaitingApproval);
    this.showApproved = false;
    this.paginateAndSort();
    this.cdr.detectChanges();
  }

  approvedHoursForLearner(learner): number {
    // this is to guard against Unathorised absences - dont think we pull data with it though
    if (learner.type === null) {
      return 0;
    }
    const timeIn = learner['timeinnew'] === null ? learner['timein'] : learner['timeinnew'];
    const timeOut = learner['timeoutnew'] === null ? learner['timeout'] : learner['timeoutnew'];
    return this.hoursBetweenStringTime(timeIn, timeOut);
  }

  hoursBetweenStringTime(startTime: string, endTime: string): number {
    startTime = startTime.substring(0, 5);
    endTime = endTime.substring(0, 5);
    const date = '01/01/2010';
    return (Date.parse(`${date} ${endTime}`) - Date.parse(`${date} ${startTime}`))
      / 3600000; //convert from ms to hours
  }

  toggleOnlyMyLearnerStatus(value: MatSlideToggleChange) {
    this.onlyMyLearnerStatus = value.checked;

    if (value.checked) {
      this.dataSource.data = this.awaitingApproval.filter(x => x.yourLearner == true);
    }
    else {
      this.dataSource.data = this.awaitingApproval;
    }
    this.cdr.detectChanges();
  }

}
