import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadService } from './../services/upload.service';
import { Component, ViewChild, ElementRef, Inject } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})

export class FileUploadComponent {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];

  selectedFiles: FileList;
  uploadDone: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   private uploadService: UploadService,
   private snackBar: MatSnackBar,
   private dialogRef: MatDialogRef<FileUploadComponent>
   ){}

 /**
   * on file drop handler
   */
  onFileDropped($event) {
    console.log('onFileDropped', $event)
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
  }

  uploadEmployerFiles(index: number) {
    if (index == this.files.length) {
      this.uploadDone = false;
      console.log('uploadEmployerFiles', this.files[index]);
      return;
    } else {
      this.uploadService.uploadUnitFileForEmployer(this.files[index], this.data.vEmployerId).subscribe (
        event => {
          this.files[index].progress = 100;
          this.uploadDone = true;
          this.snackBar.open("Attachment uploaded", "Success", {
            duration: 3000,
          });

        }
      )
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";

    if(this.data.isEmployer){
      this.uploadEmployerFiles(0);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    //console.log('formatBytes', bytes)
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }


  /*
  // TODO - DOWNLOAD
  // This is the bare bones code

 downloadUnit() {
  this.downloadingUnit = true;
  console.log('about to download', this.unitWithStudents)

  this.unitService.downloadUnitAsAssessor(this.unitWithStudents.unit.unitNo, "latest")
  .subscribe((res: any) => {
    // this.downloadFile(res);
     var file = new Blob([res], {type: 'application/pdf'});
     console.log('start download:',res);
     const link = document.createElement('a');
     link.href = URL.createObjectURL(file);
    link.download = this.unitWithStudents.unit.title + '.pdf';
    link.click();
    this.downloadingUnit = false;
   }, error => {
     this.downloadingUnit = false;
     console.log('download error:', JSON.stringify(error));
   }, () => {
     console.log('Completed file download.')
   });
}
*/

close() {
  this.dialogRef.close();
}

}
