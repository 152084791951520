import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from './../../core/services/user.service';
import { AssessorHistoryComponent } from './assessor-history/assessor-history.component';
import { LearnerService } from '../learnerService.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DATE_FORMAT, NO_DATA } from 'src/app/app.constants';

@Component({
  selector: 'learner-people',
  templateUrl: './learner-people.component.html',
  styleUrls: ['./learner-people.component.scss']
})
export class LearnerPeopleComponent implements OnInit {

  @Input() traineeId: string;
  @Input() pot: number;

  userName: string;
  userEmail: string;
  cardsData: any = null;

  peopleLoaded: boolean = false;

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;

  constructor(
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private learnerService: LearnerService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getMyInfo();
    const subscription = this.learnerService.GetLearnerPeopleCards(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.cardsData = response.data;
      },
      error: (error) => {
        this.peopleLoaded = true;
        subscription.unsubscribe();
        this.changeDetectorRef.detectChanges();
        console.log('Error loading people cards:', error.error);
        this.snackbar.open('Error loading people cards', 'Close', {
          duration: 3000,
        });
      },
      complete: () => {
        this.peopleLoaded = true;
        this.changeDetectorRef.detectChanges();
        subscription.unsubscribe();
      },
    });
  }


  //TODO: This could be passed from the template resolver
  getMyInfo() {
    this.userService.getMyInfo()
      .subscribe(response => {
        this.userName = `${response['forename']} ${response['surname']}`;
        this.userEmail = response['email'].toLowerCase();
      });
  }

  openAssessorHistoryDialog() {
    const dialogConfig: MatDialogConfig<any> = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '50%';
    dialogConfig.maxWidth = '80%';

    dialogConfig.data = {
      assessorHistory: this.cardsData['assessorHistory'],
    };
    this.dialog.open(AssessorHistoryComponent, dialogConfig);
  }

}
