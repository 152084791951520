import { Component, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { LearnerWestDetailsComponent } from './learner-west-details/learner-west-details/learner-west-details.component';
import { LearnerService } from '../learnerService.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LearnerPageComponent } from '../learner-page.component';
import { DATE_FORMAT, NO_DATA } from 'src/app/app.constants';

interface West {
  title: string;
  date: string;
  initial: string;
  current: string;
  target: string;
  westDetails: any[];
}

@Component({
  selector: 'learner-west',
  templateUrl: './learner-west.component.html',
  styleUrls: ['./learner-west.component.scss']
})
export class LearnerWestComponent implements LearnerPageComponent {

  westsDisplayedColumns: string[] = ['title', 'date', 'initial', 'current', 'target' ];
  westsDataSource = new MatTableDataSource<any>();

  pot: number;
  traineeId: string;
  staffId: number;

  loadedWestData: boolean = false;

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;

  constructor(
    private dialog: MatDialog,
    private learnerService: LearnerService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar
    ) { }

  getData() {
    const subscription = this.learnerService.GetLearnerWestDetails(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.westsDataSource.data = this.reconfiguredWestDetailsData(response.data);
      },
      error: (error) => {
        console.log('Error loading wests:', error.error);
        this.loadedWestData = true;
        this.snackbar.open('Error loading WEST details', 'Close', {
          duration: 3000,
        });
        this.cdr.detectChanges();
        subscription.unsubscribe();
      },
      complete: () => {
        this.loadedWestData = true;
        this.cdr.detectChanges();
        subscription.unsubscribe();
      }
    });
  }

  reconfiguredWestDetailsData(rawWestData): any {
    let westData = [];
    if(rawWestData['applicationOfNumbers']?.length > 0) {
      westData.push(
        this.westData(
          'Application of Numbers',
          rawWestData['applicationOfNumbers'],
          rawWestData.aonTarget
        )
      );
    } else if(rawWestData.aonTarget !== null) {
      let west: West = {
        title: 'Application of Numbers',
        target: rawWestData.aonTarget,
        initial: '-',
        current: '-',
        date: '-',
        westDetails: null
      }
      westData.push(west);
    }
    if(rawWestData['communications']?.length > 0) {
      westData.push(
        this.westData(
          'Communication',
          rawWestData['communications'],
          rawWestData.comTarget
        )
      );
    } else if(rawWestData.comTarget !== null) {
      let west: West = {
        title: 'Communication',
        target: rawWestData.comTarget,
        current: '-',
        initial: '-',
        date: '-',
        westDetails: null
      }
      westData.push(west);
    }
    if(rawWestData['digitalLiteracies']?.length > 0) {
      westData.push(
        this.westData(
          'Digital Literacy',
          rawWestData['digitalLiteracies'],
          rawWestData.dlTarget
        )
      );
    } else if(rawWestData.dlTarget !== null) {
      let west: West = {
        title: 'Digital Literacy',
        target: rawWestData.dlTarget,
        current: '-',
        initial: '-',
        date: '-',
        westDetails: null,
      }
      westData.push(west);
    }
    return westData;
  }

  westData(title: string, wests: any[], target: string): West {
    return {
      title: title,
      target: target ? target.toLowerCase() === 'n/a' ? null : target : '-',
      current: wests[0].level,
      initial: wests[wests.length-1].level,
      date: moment(wests[0].date).toString(),
      westDetails: wests
    }
  }

  openDialog(element) {
    if(element.westDetails) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.maxWidth = '1100px';
      dialogConfig.data = {
        westDetails: element.westDetails,
        title: element.title
      };
      dialogConfig.autoFocus = true;
      const dialogRef = this.dialog.open(LearnerWestDetailsComponent, dialogConfig);
    }
  }

  // export(data: FormData[], exportType: string) {
  //   this.sharedFunctions.export(data, this.westsDisplayedColumns, 'west', exportType);
  // }

}
