<mat-card class="learner-pay-card">
  <div style="float: left; width: 100%;">
    <h2 class="dashboard-titles" style="padding-left:0px; margin-bottom: 2rem;">Learner Pay Export & Import</h2>
  </div>

  <div style="float: left; margin-left: 1.5rem; width: 35%;">
    <button class="mat-raised-button mat-primary submit-button" mat-button (click)="learnerPayExport()">Export</button>
  </div>

  <div style="float: right; margin-right: 1.5rem; width: 35%;">
    <button class="mat-raised-button mat-primary submit-button" mat-button (click)="learnerPayImport()">Import</button>
  </div>

</mat-card>
