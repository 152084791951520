import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SearchType } from 'src/app/searchV2/search.service';
import { ISearchData } from 'src/app/shared/interfaces/search-data';
import { SearchTableService } from 'src/app/searchV2/search.service';
import { SharedService } from 'src/app/shared/services/shared.service';


@Component({
  selector: 'app-your-assessors',
  templateUrl: './your-assessors.component.html',
  styleUrls: ['./your-assessors.component.scss']
})
export class YourAssessorsComponent {
  @Input() staffId;
  @HostBinding('class') class = 'active-widget';
  constructor
  (
    private router: Router,
    private searchTableService: SearchTableService,
    private sharedService: SharedService
  ) { }

  dataSource: any;
  displayedColumns: string[] = [];
  obs: Observable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  setCols(data) {
    if (data && data.length > 0) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.displayedColumns = ['assessorName']
      this.obs = this.dataSource.connect();
      // let cols = Object.keys(data[0]);
      // for(let col in cols) {
      //   if (cols[col] != 'vemployerid')
      //   this.displayedColumns.push(cols[col]);
      //   };
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  loadEmployer(emp) {
    const empId = this.dataSource._data._value.filter(a => a.name == emp);
    this.router.navigate([`employer-old/dashboard/${empId[0].vemployerid}`]);

  }

  handleAssessorClick(assessor) {

    const filterOptions = {
        subcontractors: [],
        programmeTypes: [],
        centres: [],
        assessors: [{
          name: this.sharedService.uppercaseWords( assessor.assessorName.toLowerCase() ),
          id: assessor.assessorId
        }],
        sectors: [],
        schemes: []
    };

    const searchData: ISearchData =   {
      type: SearchType.learner,
      term: '',
      category: 'name',
      staffId: this.staffId,
      filters: filterOptions,
      paginationSkip: 0,
      paginationTake: 0,
    }

    //Set the assessor as if it was a previous search
    this.searchTableService.setStoredParams(SearchType.learner, searchData);

    //Reoute to learner search with queryParams for return to past search
    const navigationExtras: NavigationExtras = {
      queryParams: { 'search': true }
    };
    this.router.navigate(['learner/learner-search'], navigationExtras);
  }

}
