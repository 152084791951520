import { Component, Input } from '@angular/core';

export type Layout = 'list' | 'cards';

export interface Item {
  id: number;
}

@Component({
  selector: 'app-content-items',
  templateUrl: './content-items.component.html',
  styleUrls: ['./content-items.component.scss']
})
export class ContentItemsComponent {
  @Input()
  layout: Layout = 'list';

  items = Array(20).fill(null);
}
