import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StaffAdminService { 


  constructor(private http: HttpClient) { this.token = localStorage.getItem('access_token')}

  apiUrl: string;
  token: string;

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public GetStaffInfo(staffId): Observable<any> {
    const url = `${environment.API_URL}getStaffInfo/${staffId}`;
    const options = this.getOptionsWithAccessToken();

    console.log('Call staff from API');

    return this.http.get(url, options);
  }

  public GetPermissionsOptions(): Observable<any> {
    const url = `${environment.API_URL}GetStaffPermissionOptions`;
    const options = this.getOptionsWithAccessToken();

    console.log('Get options from API');

    return this.http.get(url, options);
  }

public GetRandomWords() : Observable<any> {
  const url = "https://random-word-api.herokuapp.com/word?number=150"
  return this.http.get(url);
}

  public postNewPermissions(data, staffId): Observable<any> {
    const url = `${environment.API_URL}PostNewPermissions`;
    const options = this.getOptionsWithAccessToken();

    var postData = {'staffId': staffId, 'permissions': data};

    console.log('Post permissions to API');

    return this.http.post(url, postData, options);
  }

  
  public PutNewDetails(data, staffid): Observable<any> {
    const url = `${environment.API_URL}UpdateStaff/${staffid}`;
    const options = this.getOptionsWithAccessToken();

    console.log('Post permissions to API', url);

    return this.http.put(url, data, options);
  }

  public DeletePermisions(id): Observable<any> {
    const url = `${environment.API_URL}DeleteStaffPermisions/${id}`;
    const options = this.getOptionsWithAccessToken();

    console.log('Delete permissions to API', url);

    return this.http.delete(url, options);
  }


  public newTraineesToOneFileTest(): Observable<any> {
    const url = `${environment.API_URL}importIALPUpdatesFromOnefile`;

    const params = new HttpParams();

    return this.http.post(url, {params:params});
  }
  
  

  
}
