<div class="learner-details">

  <div *ngIf="!this.profileLoaded">
    <p style="text-align: center; margin-bottom: 0;">Loading Profile...</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div *ngIf="this.profileLoaded" style="display: flex; flex-direction: row; gap: 25px">

    <div style="flex-direction: column; flex: 1">

      <learner-collapsible-section
        [name]="'Learner Details'"
        [editPermissionLevel] = "editPermissionLevel">
          <main-learner-details
            #contentComponent
            [learnerDetails]="learner"
            [editPermissionLevel] = "editPermissionLevel"
            (mainLearnerDetailsUpdate)="processFormData($event, 'mainLearnerDetails')">
          </main-learner-details>
      </learner-collapsible-section>

      <learner-collapsible-section
        [name]="'Current Programme'"
        [editPermissionLevel] = "editPermissionLevel">
        <current-programme
          #contentComponent
          [currentProgramme]="currentProgramme"
          [editPermissionLevel] = "editPermissionLevel"
          (currentProgrammeUpdate)="processFormData($event, 'currentProgrammeUpdate')">
        </current-programme>
      </learner-collapsible-section>

      <learner-collapsible-section
        [name]="'Current Employment'"
        [editPermissionLevel] = "editPermissionLevel">
        <current-employment
          #contentComponent
          [currentEmployment]="currentEmployment"
          [contactInformation]="contactInformation"
          [editPermissionLevel] = "editPermissionLevel"
          (currentEmploymentUpdate)="processFormData($event, 'currentEmploymentUpdate')">
        </current-employment>
      </learner-collapsible-section>

      <learner-collapsible-section
        *ngIf="currentProgramme.programme == 'JGW+'"
        [name]="'Jobs Growth Wales+'"
        [editPermissionLevel] = "1"><!-- temp - will need to change when I know who can edit what: editPermissionLevel = 1 means that this section is always editable-->
        <jobs-growth-wales-plus
          #contentComponent
          [sectorOptions] = "learner.sectorOptions"
          [centreOptions] = "learner.centreOptions"
          [learnerDetails]="learner.learnerBasics"
          [jgwInfo]="jgw"
          [editPermissionLevel] = "1"
          (jgwUpdate)="processFormData($event, 'jgwUpdate')">
        </jobs-growth-wales-plus>
      </learner-collapsible-section>

      <learner-collapsible-section
        [name]="'Previous Learning Record'"
        [editPermissionLevel] = "99">
        <app-previous-learning-record
          [plr]="plr"
          #contentComponent>
        </app-previous-learning-record>
      </learner-collapsible-section>

    </div>

    <div style="flex-direction: column; margin-top: 20px; width: 345px;">
      <learner-glance-details
        [learnerInfo] = "learner"
        [editPermissionLevel] = "editPermissionLevel"
        (glanceLearnerDetailsUpdate)="processFormData($event, 'glanceDetails')">
      </learner-glance-details>

      <!-- temp - editPermissionLevel = 1 means that this section is always editable - will need to change when I know who can edit what-->
      <learner-unique-identification
        [learnerDetails]="learner"
        [editPermissionLevel] = "1"
        (learnerUniqueIdUpdate)="processFormData($event, 'westLogin')">
      </learner-unique-identification>

      <div>
        <h2 style="
          color: #262633;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 21px;"
        >
          People
        </h2>
        <learner-people
          [traineeId]="traineeId"
          [pot]="pot">
        </learner-people>
      </div>

    </div>

  </div>
</div>
