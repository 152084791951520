import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaffSearchService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) { this.token = localStorage.getItem('access_token') }


  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public searchStaff(searchText: string, searchType: string, staffId: number): Observable<any> {
    if(!searchText || !searchType || !staffId) return null;
    const url = `${environment.API_URL}searchStaff?searchString=${searchText}&searchType=${searchType}&staffId=${staffId}`;
    const options = this.getOptionsWithAccessToken();

    console.log('Call staff search from API');

    return null;

    //return this.http.get(url, options);
  }

  public getStaff(staffId: string): Observable<any> {
    const url = `${environment.API_URL}getStaff?staffId=${staffId}`;
    const options = this.getOptionsWithAccessToken();

    console.log('Call getStaff from API');

    return null;

    //return this.http.get(url, options);
  }

  public getStaffPermissions(staffId: string): Observable<any> {
    const url = `${environment.API_URL}getStaffPermissions/${staffId}`;
    const options = this.getOptionsWithAccessToken();

    console.log('Call getStaff from API');
    return this.http.get(url, options);
  }

}
