import { Component, Input, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DownloadService } from '../../core/services/download.service';
import { IOfficePhone } from '../../shared/interfaces/office-phone';
import { IPhoneListRecord } from '../../shared/interfaces/phone-list-record';
import { StaffSearchService } from '../staff-search/staff-search.service';
import { PhoneBookService } from './phone-book.service';
import { PhoneRecordFormComponent } from '../phone-record-form/phone-record-form.component';

@Component({
  selector: 'phone-book',
  templateUrl: './phone-book.component.html',
  styleUrls: ['./phone-book.component.scss']
})

export class PhoneBookComponent implements OnInit {

  @Input() phoneList: IPhoneListRecord[];
  itemNum: number = 36;
  staffListLoaded: boolean = false;

  constructor(
    private phoneBookService: PhoneBookService,
    private downloadService: DownloadService,
    private staffSearchService: StaffSearchService,
    private dialog: MatDialog,
    private cdf: ChangeDetectorRef
  ) { }

  officePhoneList: IOfficePhone[];

  ngOnInit() {
    console.log('phoneList', this.phoneList)
    this.officePhoneList = [];
    this.phoneBookService.getOfficePhoneNumbers().subscribe(
      officePhoneRecord => {
        this.officePhoneList.push(officePhoneRecord);
        if (this.officePhoneList.length >= 10) {
        this.staffListLoaded = true;
        }
      }
    );

  }

  onSelect(staffPhoneRecord: any) {
    this.onOpenDialogue(staffPhoneRecord);
  }

  onOpenDialogue(staffPhoneRecord: IPhoneListRecord) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    console.log('about to open dialog')
    if (staffPhoneRecord != null) {
      var staffId = staffPhoneRecord.staffId.toString();
      this.staffSearchService.getStaffPermissions(staffId).subscribe(response => {
        console.log(response)
        dialogConfig.data = {
          staffData: staffPhoneRecord,
          traineePermissions: response,
        },
        dialogConfig.width = '1800px';
        const dialogRef = this.dialog.open(PhoneRecordFormComponent, dialogConfig);
      })
    }
  }

  onDownload() {
    this.downloadService.downloadFile(this.phoneList, 'phone-list');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    if ((document.body.clientHeight + window.scrollY + 300) >= document.body.scrollHeight) {
      this.itemNum += 36;
    }
  }
}
