import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";

@Component({
  selector: 'app-workshop-booking-report',
  templateUrl: './workshop-booking-report.component.html'
})
export class WorkshopBookingReportComponent {

  report = ReportVariant.WorkshopBookings;
  reportName = 'Workshop Booking Report';
  drillDownGroups = [
    {
      name: 'Sector',
      image: "assets/reports/table-view.svg",
      dateFilterTypes: ['workshopDate', 'startDate', 'expCompletionDate', 'terminationDate'],
      levels: [
        {
          level: 0,
          name: 'Sector',
          idColumn: 'sectorId',
          hiddenColumns: ['sectorId'],
          defaultColumns: [{ type: 'default', columns: ['sector', 'bookings', 'attendance', 'attendancePercentage'] }]
        },
        {
          level: 1,
          name: 'Trainee',
          idColumn: 'traineeId',
          hiddenColumns: ['traineeId'],
          defaultColumns: [{ type: 'default', columns: ['name', 'assessor', 'contract', 'scheme', 'status', 'bookings', 'attendance', 'attendancePercentage'] }]
        },
        {
          level: 2,
          name: 'Workshop',
          idColumn: '',
          hiddenColumns: [],
          defaultColumns: [{ type: 'default', columns: ['workshop', 'workshopDate', 'attendance'] }]
        }
      ]
    },
    {
      name: 'Workshop',
      image: "assets/reports/table-view.svg",
      dateFilterTypes: ['workshopDate', 'startDate', 'expCompletionDate', 'terminationDate'],
      levels: [{
        level: 0,
        name: 'Workshop',
        idColumn: 'calendarId',
        hiddenColumns: ['calendarId'],
        defaultColumns: [{ type: 'default', columns: ['workshop', 'workshopDate', 'bookings', 'attendance', 'attendancePercentage'] }]
      },
      {
        level: 1,
        name: 'Attendee',
        idColumn: '', //has to be empty so the last level loads!
        hiddenColumns: [],
        defaultColumns: [{ type: 'default', columns: ['name', 'assessor', 'scheme', 'sector', 'attendance'] }]
      }]
    }
  ];
}
