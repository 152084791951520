<mat-card class="favourites-card">
        <button *ngIf="favourites" mat-flat-button color="primary" class="favourites-button" routerLink="/reports/index">View All Reports</button>
        <h2 class="dashboard-titles" style="padding-left:0px;">Your Reports</h2>
        
    <div>
        <mat-spinner *ngIf="!favourites" style="font-size: 25px;"></mat-spinner>
        <div *ngFor="let favourite of favourites"> 
            <a class="export-all" routerLink={{favourite.link}}> {{favourite.title}} <span *ngIf="favourite.name">: {{favourite.name}}
            </span></a>
        </div>
    </div>
</mat-card>