import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unCamelCase'
})
export class UnCamelCasePipe implements PipeTransform {
    transform(value: string): string {
      return value.split(/(?<=[a-z])(?=[A-Z])/).join(' ');
    }
}
