<mat-card class="learners-card">
  <div class="learners-container">
    <h2 class="dashboard-titles" style="padding-left:0px;">Attendance Analysis</h2>
    <div fxLayout="row">
      <mat-form-field style="width: 50%;" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input #txtVal matInput placeholder="Start typing to filter results" (keyup)="applyFilter(txtVal.value)"
          autocomplete="off">
      </mat-form-field>

      <input id="date-select" type="text" ngxDaterangepickerMd [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}"
        [ranges]="ranges" [showClearButton]="true" name="daterange" [(ngModel)]="dateRangeSelected"
        (change)="dateRangeChange($event)" placeholder="Select date range" startKey="start" endKey="end"
        autocomplete="off" alwaysShowCalendars="true" #datePicker>

      <ng-select [multiple]="false" appearance="outline" style="margin-left: 20px; display: inline-block; width: 300px;"
        (change)="schemeChange(selectedScheme)" class="filter-select" #select [items]="schemeOptions" bindLabel="scheme"
        placeholder="Filter by Scheme" [searchable]="false" [(ngModel)]="selectedScheme"
        (clear)="schemeSelectCleared()">
      </ng-select>
    </div>
    <div style="display: flex; align-items: center;">
      <mat-icon matTooltip="Back" *ngIf="drilled" (click)="setCols(masterData)"
        style="height: auto;">keyboard_backspace</mat-icon>
      <div *ngIf="dataType === 2">
        <label style="margin-right: 2px; margin-left: 20px; padding-right:10px;">Subcontractor Average</label>
        <input type="checkbox" name="overallAvg" [ngModel]="this.SubcontractorAvg"
          (ngModelChange)="this.SubcontractorAvg = $event">
      </div>
    </div>
    <!-- <mat-icon *ngIf="dataFiltered" (click)="reset()" style="position: relative; left: 1em">refresh</mat-icon> -->

    <mat-spinner *ngIf="!groupedBySubcontractor || showSpinner" style="margin:0 auto;">
    </mat-spinner>

    <table class="widget-your-employers-table" [hidden]="!dataSource || !groupedBySubcontractor || showSpinner"
      mat-table [dataSource]="dataSource" matSort>
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{unCamelCase(column)}} </th>
        <div *ngIf="column != 'fullName'">
          <td mat-cell *matCellDef="let element" (click)="drilldown(column, element[column])"
            [ngStyle]="{'background': column == 'learnPercentage' ? indicatorColour(element[column]) : '#fff'}">
            {{ isDate(element[column], column) ? (element[column] | date: this.dateFormat) :
            element[column] }} </td>
        </div>
        <div *ngIf="column == 'fullName'">
          <td mat-cell *matCellDef="let element">
            <a (click)="showSpinner = true"
              routerLink="/learner/attendance/{{element['traineeId']}}/{{element['pot']}}">{{element[column]}}</a>
          </td>
        </div>

        <td mat-footer-cell *matFooterCellDef>{{ getAverageForColumn(column)}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns;" [hidden]="hideFooter()" class="footer-row"></tr>
    </table>
    <div style="display: flex; align-items: center;">
      <button [disabled]="this.showSpinner" mat-stroked-button class="save-export-buttons"
        [matMenuTriggerFor]="saveMenu">
        Export As... <mat-icon class="export-arrow">expand_more</mat-icon>
      </button>
      <mat-menu #saveMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="export('csv')">.csv</button>
        <button mat-menu-item (click)="export('xlsx')">.xlsx</button>
      </mat-menu>
      <div style="flex-grow: 1;"></div>
      <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 15, 50]" showFirstLastButtons></mat-paginator>
    </div>
  </div>


</mat-card>
