import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UploadService } from "../../../../core/services/upload.service";
import { SharedFunctionsService } from "../../../../core/services/shared-functions.service";
import { UntypedFormGroup } from "@angular/forms";
import { Component, OnInit, ViewChild, ElementRef, Inject } from "@angular/core";
import moment from 'moment';

@Component({
  selector: "app-attencance-import",
  templateUrl: "./learner-pay-import.component.html",
  styleUrls: ["./learner-pay-import.component.scss"],
})
export class LearnerPayImportComponent implements OnInit {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  form: UntypedFormGroup;
  files: any[] = [];
  sucsessMessages: string[] = [];
  errorMessages: string[] = [];

  filesToUpload: any = null;

  staffId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LearnerPayImportComponent>,
    private sharedFunctions: SharedFunctionsService,
    private uploadService: UploadService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}


  fileHandler(files) {
    this.filesToUpload = files;
    this.files = [];
    this.sucsessMessages = [];
    this.errorMessages = [];
    Object.keys(this.filesToUpload).forEach(key => {
      this.uploadFile(this.filesToUpload[key]);
    });
  }

  uploadFile(file:any) {
    this.uploadService.importLearnerPayFile(file, this.data.staffId)
      .subscribe((imported) => {
        this.files.push(file);
        if (imported.length) {
          this.sucsessMessages.push(file.name);
        } else {
          this.errorMessages.push(file.name);
        }
        if(this.files.length == this.filesToUpload.length) {
          this.finishedUploadingAll();
        }
      });
  }


  finishedUploadingAll() {
    let message = this.sucsessMessages.length === this.filesToUpload.length ? 'All files uploaded successfully'
      : this.errorMessages.join(', ') + ' failed to upload. Please make sure these are .csv files and then try again';

      this.snackBar.open(message, 'Close', {
      duration: 5000,
    });

    this.filesToUpload = null;
  }

  closeForm() {
    this.dialogRef.close();
  }
}
