import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';

@Component({
  selector: 'app-esq-marking-and-iqa-report',
  templateUrl: './esq-marking-and-iqa-report.html'
})
export class EsqMarkingAndIqaReportComponent {

  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  name = 'ESQ Marking and Iqa Report';
  reportVariant = ReportVariant.EsqMarkingAndIqa;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullname', 'programmeSector', 'qualification', 'level', 'deliveryTutor', 'taskTopic', 'dateRecievedForMarking', 'markingTutor',
        'dateMarkingReturned', 'markingOutcome', 'dateAllocatedForIqa', 'iqaName', 'iqaOutcome', 'iqaDate', 'confirmatoryTestDate',
        'confirmatoryTestResult', 'qualificationClaimDate'
      ]
    },
    {
      type: 'other',
      columns: ['traineeId', 'pot', 'subcontractor', 'contract', 'assessor', 'scheme', 'sector', 'centre', 'potStatus', 'programmeType',
        'objectiveStatus', 'startDate', 'expCompletionDate']
    }
  ];
  defaultColumnOrder = [
    {
      type: 'default',
      columns: ['fullname', 'programmeSector', 'qualification', 'level', 'deliveryTutor', 'taskTopic', 'dateRecievedForMarking', 'markingTutor',
        'dateMarkingReturned', 'markingOutcome', 'dateAllocatedForIqa', 'iqaName', 'iqaOutcome', 'iqaDate', 'confirmatoryTestDate',
        'confirmatoryTestResult', 'qualificationClaimDate']
    },
    {
      type: 'other',
      columns: ['traineeId', 'pot', 'subcontractor', 'contract', 'assessor', 'scheme', 'sector', 'centre', 'potStatus', 'programmeType',
        'objectiveStatus', 'startDate', 'expCompletionDate']
    },
  ];
  dateFilters = ['startDate', 'expCompletionDate'];
}
