import { ApiHelper } from "src/app/shared/api-helper";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";

export interface AbsenceApproved {
  traineeId: string;
  pot: number;
  centreId: number;
  assessorId: string;
  timeIn: string;
  timeOut: string;
  comment: string;
  date: Date;
}

export class AttendanceApproval {
  public traineeId: string;
  public approved?: number;
  public pot: number;
  public assessorId: string;
  public signId: number;
  public name: string;
  public centre: string;
  public assessor: string;
  public comments: string;
  public commentsTimeInNew: string;
  public commentsTimeOutNow: string;
  public date?: Date;
  public timeIn?: string;
  public timeOut?: string;
  public timeInNew?: string;
  public timeOutNew?: string;
  public type: string;
  public subcontractor: string;
  public programmeType: string;
  public contract: string;
  public scheme: string;
  public sector: string;
  public subcontractorId?: number;
  public programmeTypeId?: number;
  public contractId?: number;
  public schemeId?: number;
  public sectorId?: number;
  public centreId?: number;
  public centreStartTime?: string;
  public centreEndTime?: string;
  public yourLearner: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AttendanceAuthorisedService extends ApiHelper {

  getDetails(staffId: number, pageNumber: number, pageSize: number, name: string = null, onlyMyLearners: boolean, sortColumn: string = null, sortColumnDirection: string = null) {
    let url = `${environment.API_URL}V2/Attendance/AbsenceToAuthorise/Details?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}`;
    url += `&filterString=onlyMyLearners::${onlyMyLearners}`
    if (name) {
      url += `,name::${name}`;
    }
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    return this.getWithAccessToken(url);
  }

  postAbsenceToAuthorise(staffId: number, payload: AbsenceApproved[]) {
    return this.postWithAccessToken(`${environment.API_URL}V2/Attendance/AbsenceToAuthorise/Authorise/${staffId}`, payload);
  }
}
