<app-search-layout [type]="this.type" [categories]="this.categories" [isLoadingSearch]="this.isLoadingSearch"
  [filterOptions]="this.filterOptions" [searchForm]="this.searchForm" [filterForm]="this.filterForm"
  [totalCount]="this.totalCount" [sideNavOpen]="this.sideNavOpen" [applyFiltersButton]="this.applyFiltersButton"
  (submittedData)="this.onSubmit($event)" (sideNavChange)="this.onSidenavOpenedChange($event)">

  <app-search-virtual-scroll #virtualScroll [type]="this.type" [filterOptions]="this.filterOptions"
    [searchForm]="this.searchForm" [filterForm]="this.filterForm" [sideNavOpen]="this.sideNavOpen"
    (isLoadingSearchChange)="this.loadingSearchChange($event)"
    (applyFiltersButtonChange)="this.applyFiltersButtonChange($event)"
    (totalCountChange)="this.totalCountChange($event)"
    (gettingMoreResultsChange)="this.gettingMoreResultsChange($event)">

  </app-search-virtual-scroll>

</app-search-layout>