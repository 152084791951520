<form novalidate autocomplete="off" (ngSubmit)="staffSearch()" [formGroup]="searchForm" class="search-container">

  <h1 class="staff-search-title">
    Staff Directory
  </h1>
  <h2 class="staff-search-subtitle">Who can we help you find today?</h2>
  <div class="controls-container" style="padding-left: 0px;">

    <a *ngIf="resetOption" href="#" class="export-all" (click)="resetForm()">Clear</a> <br>

    <!-- <div style="width: 100%; display: flex;"> -->
    <div style="width: 100%; display: block;">

      <mat-form-field appearance="fill"
        class="no-underline search-type-local" [ngStyle]="filterStyle">
        <mat-icon matSuffix id="filter_icon" style="padding-right: 10px; display: contents;">{{icon}}</mat-icon>
        <input style="width:90%" placeholder="{{refineByKeywordText}}" (focus)="onFocus($event)"
          (focusout)="lostFocus($event)" matInput formControlName="searchFilter" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addFilter($event)">
      </mat-form-field>

      <mat-chip-list #chipList aria-label="Filter selection" class=chiplist>
        <mat-chip matChipRemove style="margin-left: 15px; border-radius: 5px; background-color: white; height: 40px" *ngFor="let filter of filters"
          class="staff-search-secondary" [selectable]="selectable" [removable]="removable"
          (removed)="removeFilter(filter)">
          {{filter.value}}
          <mat-icon matChipRemove *ngIf="removable" class="filterChipRemove">close</mat-icon>
        </mat-chip>
      </mat-chip-list>

      <mat-spinner *ngIf="showSpinner" diameter = "40"></mat-spinner>

    </div>

    <div>
      <mat-label class="no-results-message" *ngIf="noResultsMessage != ''">
        {{noResultsMessage}}
      </mat-label>
    </div>

  </div>
</form>

<!-- insert table here -->
<div class="table-container">

  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z0" [hidden]="!dataLoaded">


    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
        Name
      </th>
      <td mat-cell *matCellDef="let element">
        <a class="export-all icon--click-hover" style="text-decoration: underline;" (click)="onSelect(element)">
          {{element.name}}
        </a>
      </td>
    </ng-container>

    <!-- Job role Column -->
    <ng-container matColumnDef="jobRole">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by job role">
        Job Title
      </th>
      <td mat-cell *matCellDef="let element"> {{element.jobRole}} </td>
    </ng-container>

    <!-- company Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by company">
        Company
      </th>
      <td mat-cell *matCellDef="let element"> {{element.company}} </td>
    </ng-container>

    <!-- mobile Column -->
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by mobile">
        Mobile
      </th>
      <td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
    </ng-container>

    <!-- currentLocation Column -->
    <ng-container matColumnDef="currentLocation">
      <th mat-header-cell *matHeaderCellDef>
        Current location
      </th>
      <td mat-cell *matCellDef="let element"> {{element.currentLocation}} </td>
    </ng-container>

     <!-- status Column -->
     <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        Status
      </th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator  (page)="pageEvent = pageChange($event)" [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons aria-label="Select page of employees"></mat-paginator>
</div>

<!-- <phone-book [phoneList]="searchResultsFiltered" class="phone-book-container"></phone-book> -->
