import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { isDate } from 'moment';
import { Sidebar } from 'ng-sidebar';
import { catchError, map, merge, of, pairwise, startWith, switchMap } from 'rxjs';
import { IUserInfo } from 'src/app/shared/interfaces/user-info';
import { FavouriteTableService } from '../../core/database/favourite-table.service';
import { UserTableService } from '../../core/database/user-table.service';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { UserService } from '../../core/services/user.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { IGenericSummaryTableData, ISummaryGroupBy, ITableFilter } from '../../shared/interfaces/generic-interfaces';
import { ReportStoredFilterHelperServiceV2 } from '../report-stored-filter-helper.service';
import { SaveReportFiltersDialogComponentV2 } from '../save-report-filters-dialog/save-report-filters-dialog.component';
import { ShareReportFiltersDialogComponentV2 } from '../share-report-filters-dialog/share-report-filters-dialog.component';
import { ShowSavedReportFiltersDialogComponentV2 } from '../show-saved-report-filters-dialog/show-saved-report-filters-dialog.component';
import { ClassicReportService } from './classic-report.service';
import { DATE_FORMAT_MOMENT, DATE_TIME_FORMAT_MOMENT } from 'src/app/app.constants';
import { StringHelper } from 'src/app/shared/string-helper';

export interface IReportResponse {
  reportData: any[];
  filterOptions: { [key: string]: any[] };
  currentPage: number;
  totalPages: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageSize: number;
}

export interface IAdditionalFilter {
  Type: string,
  Options: string[]
}

export interface IDefaultColumns {
  type: string,
  columns: string[];
}

export enum ReportType {
  table = "table",
  summary = "summary",
  chart = "chart"
}

@Component({
  selector: 'classic-report',
  templateUrl: './classic-report.component.html',
  styleUrls: ['./classic-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassicReportComponent implements OnInit, AfterViewInit {

  @ViewChild('dateRange') dateRangePicker;
  @ViewChild('summaryPaginator', { static: true }) summaryPaginator: MatPaginator;
  @ViewChild('summarySort', { static: true }) summarySort: MatSort;
  @ViewChild('tablePaginator', { static: true }) tablePaginator: MatPaginator;
  @ViewChild('tableSort', { static: true }) tableSort: MatSort;
  @ViewChild("ngSidebar") ngSidebar: Sidebar;
  @ViewChild("reportSummaryChart") reportSummaryChartChild;
  @ViewChild("sharedFilters") sharedFiltersChild;
  @ViewChildren('showColumnSelector') showColumnSelector: QueryList<any>;

  @Input() activeLearnerToggle = true;
  @Input() dateFilterTypes: string[];
  @Input() defaultColumnOrder: IDefaultColumns[] = new Array<IDefaultColumns>();
  @Input() defaultDisplayColumns: IDefaultColumns[];
  @Input() displayName = '';
  @Input() groupByColumns: ISummaryGroupBy[] = [];
  @Input() hasClassicFilters = true;
  @Input() hiddenColumns: string[] = [];
  @Input() report: ReportVariant;
  @Input() reportName: string;
  @Input() tableCellStyle: (column: string, value: any, row: any) => object = () => { return {} };
  @Input() matSortActive = '';
  @Input() matSortDirection = '';
  @Input() reportRefreshed = false;

  additionalFilterGroup = new UntypedFormGroup({});
  additionalFilters: IAdditionalFilter[] = [];
  allColumns: string[] = [];
  allFilterOptionsRaw: { [key: string]: any[] } = {};
  assessorOptions: any[];
  centreOptions: any[];
  chartDataSource: MatTableDataSource<any>;
  columnFormControl: UntypedFormControl;
  contractOptions: any[];
  dateFilterTypeSelected: string;
  dateTypeChange = new FormControl();
  displayedColumns: string[];
  displayShareExport = true;
  errorOptionsShow = false;
  errorOptionsText = "There was an error processing the filter. Please try again.";
  errorClassicShow = false;
  errorClassicText = "There was an error processing the filter. Please try again.";
  errorCustomShow = false;
  errorCustomText = "There was an error processing the filter. Please try again.";
  errorText = null;
  errorTextChart = null;
  errorTextSummary = null;
  filter: ITableFilter[] = []
  filterString = null;
  groupByColumn: ISummaryGroupBy = { value: "", columns: [], pieChartDrillDown: "", pieChartShareValue: "", title: "" };
  hideColumns: string[] = [...this.hiddenColumns, 'contractId', 'programmeTypeId', 'schemeId', 'sectorId', 'assessorId', 'subcontractorId', 'centreId', 'potStatusId'];
  isFavouriteReport: { status: boolean, data: { Id: number; key: string } } = { status: false, data: { Id: null, key: null } };
  lastColumnSelection: string[];
  loadingFilters = true;
  mainTableData: any[] = [];
  multipleDefaultDisplayColumns = false;
  pageOptions: any;
  programmeTypeOptions: any[];
  ranges: any = {}
  reportSavedFilter;
  reportSelected = ReportType.table;
  ReportType = ReportType;
  savedFilterToApply;
  savedReportFilterName;
  schemeOptions: any[];
  sectorOptions: any[];
  selectAllColumns = false;
  selectedColumnOrder: IDefaultColumns;
  selectedDateRange: { startDate: moment.Moment, endDate: moment.Moment } = null;
  selectedDefaultDisplayColumns: IDefaultColumns;
  setFilterEmitter = new EventEmitter<any>();
  setGroupByColumnEmitter = new EventEmitter<any>();
  setReportTypeEmitter = new EventEmitter<any>();
  showClassicFilters = true;
  showErrorTextChart = false;
  showOptions = true;
  showReportFilters = true;
  showSpinner = false;
  showSpinnerChart = false;
  showSpinnerSummary = false;
  sidebarMode: string;
  sidebarOpened = true;
  sortString: string = null;
  storedFilters = [];
  subcontractorOptions: any[];
  summaryColumns: string[] = [];
  summaryDataSource: MatTableDataSource<IGenericSummaryTableData>;
  tableDataSource: MatTableDataSource<any>;
  totalData: number;
  userInfo: IUserInfo;
  waitingForToggle = false;

  showRefreshDetails = false;
  showRefreshDetailsError = false;
  refreshFrequency = 0;
  lastRefreshed: string = null;

  dateFormat = DATE_FORMAT_MOMENT;
  dateTimeFormat = DATE_TIME_FORMAT_MOMENT;

  groupByColumnOptions: ISummaryGroupBy[] = [];

  constructor(
    private classicReportService: ClassicReportService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private favouriteTableService: FavouriteTableService,
    private reportStoredFilterHelperService: ReportStoredFilterHelperServiceV2,
    private route: ActivatedRoute,
    private router: Router,
    private sharedFunctions: SharedFunctionsService,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private userTableService: UserTableService,
    private stringHelper: StringHelper
  ) {
    this.pageOptions = [this.sharedFunctions.calcOptimumRows(), 25, 50, 100];
    this.summaryDataSource = new MatTableDataSource<IGenericSummaryTableData>(this.mainTableData);
    this.tableDataSource = new MatTableDataSource<any>(this.mainTableData);
  }
  //#region Lifecycle Hooks
  ngOnInit(): void {
    if (this.activeLearnerToggle) {
      this.filterString = 'activeLearners::true';
      this.filter.push({ column: 'activeLearners', value: 'true' });
    }
    this.sidebarMode = window.innerWidth <= 1200 ? 'over' : 'push';
    if (this.defaultColumnOrder.length === 0) {
      this.defaultColumnOrder = JSON.parse(JSON.stringify(this.defaultDisplayColumns));
    }
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns[0];
    this.selectedColumnOrder = this.defaultColumnOrder[0];
    if (this.defaultDisplayColumns.length > 1) {
      this.multipleDefaultDisplayColumns = true;
    }
    this.displayedColumns = this.orderColumns([...this.selectedDefaultDisplayColumns.columns]);
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.columnFormControl = new UntypedFormControl(this.displayedColumns);
    this.dateFilterTypeSelected = this.dateFilterTypes[0];
    this.showSpinner = true;
    this.tableDataSource.paginator = this.tablePaginator;
    this.sortString = `${this.displayedColumns[0]}::asc`;
    this.tableDataSource.sort = this.tableSort;
    this.sidebarOpened = true;
    this.sidebarMode = 'push';
    this.getAllSavedFiltersFromLocalDb();
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.setupAllFilterOptions();
    this.setupTablePagination();
    this.setupDateTypeChange();
    this.setupSort();
    this.setupDatePicker();
    if (this.reportRefreshed) {
      this.getLastRefreshed();
    }
  }
  //#endregion

  //#region UrlSlugs
  getFilterFromUrl() {
    this.route.data.forEach(
      result => {
        this.reportSavedFilter = result.reportFilter;
        if (this.reportSavedFilter) {
          this.savedFilterToApply = this.reportSavedFilter.filters;
          this.savedReportFilterName = this.reportSavedFilter.name;
          this.loadSavedFilters();
          this.snackBar.open("Filter applied: " + this.reportSavedFilter.name,
            'Close', { duration: 5000 });
        }
      }
    );
  }
  //#endregion
  //#region Setups
  getLastRefreshed() {
    this.classicReportService.getReportRefreshDetails(this.report).subscribe({
      next: (data: any) => {
        this.lastRefreshed = moment(data.lastRefreshed).format("DD/MM/yyyy HH:mm");
        this.refreshFrequency = data.refreshFrequency;
        this.showRefreshDetails = true;
        this.showRefreshDetailsError = false;
      },
      error: (error) => {
        this.showRefreshDetails = false;
        this.showRefreshDetailsError = true;
      }
    });
  }

  setupDatePicker() {
    const dayOfYear = moment.utc().dayOfYear();
    const firstContractDay = moment();
    const lastContractDay = moment();
    const previousfirstContractDay = moment()
    const previouslastContractDay = moment()
    if (dayOfYear < 213) {
      firstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 }) // moment months start counting at 0!
      lastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 2, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year() - 1, 'month': 6, 'date': 31 })
    } else {
      firstContractDay.set({ 'year': moment().year(), 'month': 7, 'date': 1 })
      lastContractDay.set({ 'year': moment().year() + 1, 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
    }

    this.ranges = {
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'This Contract Year': [firstContractDay, lastContractDay],
      'Last Contract Year': [previousfirstContractDay, previouslastContractDay]
    }
  }

  setupAllFilterOptions() {
    this.loadingFilters = true;
    this.userTableService.get(1)
      .then(result => {
        if (!result || result == undefined || result == null) {
          this.router.navigate(['']);
        } else {
          this.userInfo = result;
          this.getFilterData().subscribe({
            next: (data) => {
              this.allFilterOptionsRaw = data;
              this.assessorOptions = this.allFilterOptionsRaw.assessorFilters;
              this.centreOptions = this.allFilterOptionsRaw.centreFilters;
              this.contractOptions = this.allFilterOptionsRaw.contractFilters;
              this.subcontractorOptions = this.allFilterOptionsRaw.subcontractorFilters;
              this.programmeTypeOptions = this.allFilterOptionsRaw.programmeFilters;
              this.schemeOptions = this.allFilterOptionsRaw.schemeFilters;
              this.sectorOptions = this.allFilterOptionsRaw.sectorFilters;
              this.allFilterOptionsRaw['genericFilters']?.forEach(element => {
                this.additionalFilters.push({ Type: element.type, Options: [...element.options] });
                this.additionalFilterGroup.addControl(element.type, new UntypedFormControl(null));
              });
              this.loadingFilters = false;
              this.cdr.detectChanges();
              this.getFilterFromUrl();
            },
            error: (error) => {
              this.loadingFilters = false;
              this.errorOptionsShow = true;
              this.errorClassicShow = true;
              this.errorCustomShow = true;
              this.cdr.detectChanges();
            }
          });
        }
      });
  }

  setupTablePagination() {
    this.userTableService.get(1)
      .then(result => {
        if (!result || result == undefined || result == null) {
          this.router.navigate(['']);
        } else {
          this.userInfo = result;
          merge(this.tableSort.sortChange,
            this.summarySort.sortChange,
            this.tablePaginator.page,
            this.summaryPaginator.page,
            this.setFilterEmitter,
            this.setReportTypeEmitter,
            this.setGroupByColumnEmitter).pipe(
              startWith({}),
              switchMap((event) => {
                this.showErrorTextChart = false;
                if (typeof event === 'string') {
                  this.filterString = event;
                }
                if (this.reportSelected === 'table') {
                  return this.getTableData(
                    this.tablePaginator.pageIndex + 1,
                    this.tablePaginator.pageSize,
                    this.filterString,
                    this.tableSort.active,
                    this.tableSort.direction
                  )
                    .pipe(
                      catchError((val) => {
                        this.showSpinner = false;
                        this.errorText = val;
                        this.cdr.detectChanges();
                        return of(null);
                      }));
                }
                if (this.reportSelected === 'summary') {
                  console.dir(this.groupByColumn);
                  return this.getSummaryData(
                    this.summaryPaginator.pageIndex + 1,
                    this.summaryPaginator.pageSize,
                    this.filterString,
                    this.summarySort.active,
                    this.summarySort.direction,
                    this.groupByColumn.value
                  )
                    .pipe(
                      catchError((val) => {
                        this.showSpinnerSummary = false;
                        this.errorTextSummary = val;
                        this.cdr.detectChanges();
                        return of(null);
                      }));
                }
                if (this.reportSelected === 'chart') {
                  return this.getChartData(
                    this.filterString,
                    this.groupByColumn.value
                  )
                    .pipe(
                      catchError((val) => {
                        this.showSpinnerChart = false;
                        this.errorTextChart = val;
                        this.showErrorTextChart = true;
                        this.cdr.detectChanges();
                        return of(null);
                      })
                    );
                }
              }),
              map((data) => {
                if (this.reportSelected === 'chart') {
                  return data;
                }
                else {
                  if (data == null) return [];
                  this.totalData = data.totalRecords;
                  return data.data;
                }
              }),
            ).subscribe((data) => {
              this.mainTableData = data;
              if (this.reportSelected === 'table') {
                this.tableDataSource = new MatTableDataSource(this.mainTableData);
                this.showSpinner = false;
              }
              if (this.reportSelected === 'summary') {
                this.summaryDataSource = new MatTableDataSource(this.mainTableData);
                this.showSpinnerSummary = false;
              }
              if (this.reportSelected === 'chart') {
                this.chartDataSource = new MatTableDataSource(this.mainTableData);
                this.reportSummaryChartChild.setPieChartSummaryDataForBasicReport(
                  this.chartDataSource.data,
                  this.groupByColumn
                );
                this.showSpinnerChart = false;
              }
              if (data.length > 0) {
                this.allColumns = Object.keys(this.mainTableData[0]).sort().filter(column => this.hideColumns.indexOf(column) === -1);
                this.allColumns.unshift('Select All');
              }
              if (this.savedFilterToApply) {
                this.loadSavedFilters();
              }
              this.cdr.detectChanges()
            });
        }
      });
  }

  setupDateTypeChange() {
    this.dateTypeChange.valueChanges.pipe(
      startWith(this.dateTypeChange.value),
      pairwise()
    ).subscribe(
      ([old, value]) => {
        this.resetDatePicker();
        this.setFilter({ type: old, value: '' });
      }
    );
  }

  setupSort() {
    this.tableSort.sort(({ id: this.displayedColumns[0], start: 'asc' }) as MatSortable);
    this.tableSort.sortChange.subscribe(
      (event) => {
        this.tablePaginator.pageIndex = 0;
        if (event.direction !== '') {
          this.sortString = `${event.active}::${event.direction}`;
        }
        else {
          this.sortString = null;
        }
      }
    );
    if (this.groupByColumns.length > 0) {
      this.summarySort.sort(({ id: 'count', start: 'desc' }) as MatSortable);
      this.summarySort.sortChange.subscribe(
        (event) => {
          this.summaryPaginator.pageIndex = 0;
          if (event.direction !== '') {
            this.sortString = `${event.active}::${event.direction}`;
          }
          else {
            this.sortString = null;
          }
        }
      );
    }
  }
  //#endregion

  //#region Get Data
  getTableData(pageNumber: number, pageSize: number, filterString: string, sortColumn: string, sortDirection: string) {
    this.showSpinner = true;
    this.errorText = null;
    const staffId = this.userInfo.staffId;
    return this.classicReportService.getReportData(this.report, staffId, pageNumber, pageSize, filterString, sortColumn, sortDirection);
  }

  getChartData(filterString: string, groupByColumn: string) {
    this.showSpinnerChart = true;
    this.errorText = null;
    this.showErrorTextChart = false;
    const staffId = this.userInfo.staffId;
    return this.classicReportService.getReportChartData(this.report, staffId, filterString, groupByColumn);
  }

  getFilterData() {
    const staffId = this.userInfo.staffId;
    return this.classicReportService.getReportFilter(this.report, staffId);
  }

  getSummaryData(pageNumber: number, pageSize: number, filterString: string, sortColumn: string, sortDirection: string, groupByColumn: string) {
    this.showSpinnerSummary = true;
    this.errorTextSummary = null;
    const staffId = this.userInfo.staffId;
    return this.classicReportService.getReportSummary(this.report, staffId, pageNumber, pageSize, filterString, sortColumn, sortDirection, groupByColumn);
  }
  //#endregion

  //#region Filters
  getAllSavedFiltersFromLocalDb() {
    this.favouriteTableService
      .getAll()
      .then(response => {
        response.forEach(item => {
          if (item.type === 'filter' && item.reportId === this.report) {
            this.storedFilters.push(item);
          } else if (item.type === 'report' && item.reportId === this.report) {
            this.isFavouriteReport.status = true;
            this.isFavouriteReport.data.Id = item.id;
            this.isFavouriteReport.data.key = `report:${item.id}`;
          }
        });
      });
  }

  toggleOnlyActiveLearners(onlyActiveLearners: boolean) {
    this.setFilter({ type: 'activeLearners', value: onlyActiveLearners });
  }

  showHideFilters(filterType: Event) {
    const clickedChevron = filterType.target as Element;
    switch (clickedChevron.id) {
      case "Options-Filters":
        this.showOptions = !this.showOptions;
        break;
      case "Classic-Filters":
        this.showClassicFilters = !this.showClassicFilters;
        break;
      case "Report-Filters":
        this.showReportFilters = !this.showReportFilters;
        break;
      default:
        console.error("Unknown chevron clicked");
        break;
    }
    this.ngSidebar.triggerRerender();
  }

  dateRangeChange(event: any) {
    const dateRange = {
      type: this.dateFilterTypeSelected,
      value: ''
    }
    if (event.startDate !== null && event.endDate !== null) {
      dateRange.value = event.startDate.format("YYYY,MM,DD") + '-' + event.endDate.format("YYYY,MM,DD")
    }
    this.setFilter(dateRange);
    this.cdr.detectChanges();
  }

  setFilter(newFilter) {
    let filterString = ''
    if (
      (typeof newFilter.value === 'object' && newFilter.value.indexOf(0) === -1 && newFilter.value.length !== 0) ||
      (typeof newFilter.value === 'string' && newFilter.value !== '') ||
      (typeof newFilter.value === 'boolean' && newFilter.value !== null)
    ) {
      // remove current filter if it exists.
      const filterToRemove = this.filter.findIndex((obj) => obj.column === newFilter.type);
      if (filterToRemove !== -1) {
        this.filter.splice(filterToRemove, 1);
      }
      const filterToAdd: ITableFilter = { column: newFilter.type, value: newFilter.value };
      this.filter.push(filterToAdd);
    }
    else {
      const filterToRemove = this.filter.findIndex((obj) => obj.column === newFilter.type);
      if (filterToRemove !== -1) {
        this.filter.splice(filterToRemove, 1);
      }
    }
    this.filter.forEach(element => {
      filterString += `${element.column}::${element.value};`;
    });
    //remove last & from the string.
    filterString = filterString.slice(0, -1);
    filterString = filterString.replace(/&/g, '%26'); // replace & with %26. This will be decoded on the server.
    this.setFilterEmitter.emit(filterString);
  }

  resetAllFilters() {
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns[0];
    this.selectedColumnOrder = this.defaultColumnOrder[0];
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.columnFormControl.setValue([...this.selectedDefaultDisplayColumns.columns]);
    this.resetSelectAll();

    this.dateRangePicker.nativeElement.value = '';

    this.dateFilterTypeSelected = "startDate";
  }

  selectColumns(selection: any[]) {
    if (selection.indexOf('Select All') !== -1 && !this.selectAllColumns) {
      this.displayedColumns = this.orderColumns(this.allColumns);
      this.allColumns[this.allColumns.indexOf('Select All')] = 'Undo Select All';
      this.columnFormControl.setValue(this.displayedColumns);
      this.selectAllColumns = true;
    } else if (selection.indexOf('Undo Select All') !== -1 && this.selectAllColumns) {
      this.displayedColumns = [...this.lastColumnSelection];
      this.columnFormControl.setValue(this.displayedColumns);
      this.resetSelectAll();
    } else {
      this.displayedColumns = this.orderColumns(selection);
      this.lastColumnSelection = this.displayedColumns;
      this.resetSelectAll();
    }
  }

  setDefaultColumn($event) {
    console.dir($event);
    console.dir(this.defaultDisplayColumns);
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns.find(x => x.type === $event.value);
    console.dir(this.selectedDefaultDisplayColumns);
    this.selectedColumnOrder = this.defaultColumnOrder.find(x => x.type === $event.value);
    console.dir(this.selectedColumnOrder);
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    console.dir(this.lastColumnSelection);
    this.displayedColumns = this.orderColumns(this.lastColumnSelection);
    this.columnFormControl.setValue(this.displayedColumns);
    this.resetSelectAll();
  }

  orderColumns(newSelection: string[]) {
    let columnsToDisplay = [...this.selectedColumnOrder.columns];
    const selection = [...newSelection];

    columnsToDisplay = columnsToDisplay.filter(element => selection.indexOf(element) !== -1);

    const restOfSelection = selection.filter(item => this.selectedColumnOrder.columns.indexOf(item) === -1);
    restOfSelection.forEach(element => {
      if (element !== 'Select All') {
        columnsToDisplay.push(element);
      }
    });

    return columnsToDisplay;
  }

  resetDatePicker() {
    this.selectedDateRange = null;
  }

  resetSelectAll() {
    if (this.selectAllColumns) {
      this.allColumns[this.allColumns.indexOf('Undo Select All')] = 'Select All';
      this.selectAllColumns = false;
    }
  }

  setGroupByColumn(selection: ISummaryGroupBy) {
    this.groupByColumn = selection;
    this.summaryColumns = [...this.groupByColumn.columns];
    this.summaryDataSource.data = [];
    this.setGroupByColumnEmitter.emit(true);
  }

  loadSavedFilters() {
    const filters = this.savedFilterToApply.split(";");
    this.sharedFiltersChild.loadSavedFilters(this.allFilterOptionsRaw, filters);
    this.loadDateFilters(filters);
    this.loadAdditionalFilters(filters);
    this.loadTypeAndColumnFilters(filters);
    this.savedFilterToApply = null;
    //this.cdr.detectChanges();
  }

  loadDateFilters(filters) {
    const dateToLoad = this.reportStoredFilterHelperService.getDateToLoad(filters, this.ranges);
    // If loading old saved filters this could be undefined if not in the hardcoded array - For newer ones this value will always be set.
    this.dateFilterTypeSelected = dateToLoad.dateTypeFilter ?? this.dateFilterTypeSelected;
    if (dateToLoad.startDate) {
      this.selectedDateRange = { "startDate": dateToLoad.startDate, "endDate": dateToLoad.endDate };
    }
    this.dateRangeChange(this.selectedDateRange);
  }

  loadAdditionalFilters(filters) {
    filters.forEach(filter => {
      const filterSplit = filter.split("::");
      const filterType = filterSplit[0];
      const filterMatch = this.additionalFilterGroup.get(filterType)
      if (filterMatch) {
        filterMatch.setValue(filterSplit[1].split(","));
        this.setFilter({ type: filterType, value: filterSplit[1].split(",") })
      }
    });
  }

  // This should be compatible with old report type value using isShowSummary
  // Could write this out a little cleaner
  loadTypeAndColumnFilters(filters) {
    const typeAndColumns = this.reportStoredFilterHelperService.loadBasicTypeAndColumnFilters(filters);
    const reportSelectedTemp = this.ReportType[typeAndColumns.reportType];
    if (reportSelectedTemp === this.ReportType.summary || reportSelectedTemp === this.ReportType.chart) {
      this.reportSelected = reportSelectedTemp;
      const selectedGroupBy = this.groupByColumns.find(x => x.value === typeAndColumns.groupByColumn);
      this.setGroupByColumn(selectedGroupBy);
    }
    else if (reportSelectedTemp === this.ReportType.table) {
      this.reportSelected = this.ReportType.table;
      this.selectedDefaultDisplayColumns = this.defaultDisplayColumns.find(x => x.type === typeAndColumns.defaultDisplayColumnsType);
      this.selectedColumnOrder = this.defaultColumnOrder.find(x => x.type === typeAndColumns.defaultDisplayColumnsType);
      this.columnFormControl.setValue(typeAndColumns.selectedColumns);
      this.selectColumns(typeAndColumns.selectedColumns);
    }
    else {
      if (typeAndColumns.isShowSummery) {
        this.reportSelected = this.ReportType.summary;
        const selectedGroupBy = this.groupByColumns.find(x => x.value === typeAndColumns.groupByColumn);
        this.setGroupByColumn(selectedGroupBy);
      } else {
        this.reportSelected = this.ReportType.table;
        this.columnFormControl.setValue(typeAndColumns.selectedColumns);
        this.selectColumns(typeAndColumns.selectedColumns);
      }
    }
  }

  getFullFilterAsString(): string {
    // Check dateFilterTypeSelected is always set
    const reportTypeAndSelectColumns = this.reportStoredFilterHelperService.getBasicReportTypeAndColumns(this.reportSelected, this.columnFormControl, this.groupByColumn, this.selectedDefaultDisplayColumns.type);
    const dateFilter = this.reportStoredFilterHelperService.getBasicDateFilterAsString(this.selectedDateRange.startDate, this.selectedDateRange.endDate, this.dateFilterTypeSelected);
    const sharedFilters = this.sharedFiltersChild.getFiltersAsString();
    const additionalFilters = this.reportStoredFilterHelperService.getAdditionalFilters(this.additionalFilters, this.additionalFilterGroup);
    const fullFilter = reportTypeAndSelectColumns + dateFilter + sharedFilters + additionalFilters;
    return fullFilter;
  }

  checkSaveFiltersAndStore() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '300px';
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(SaveReportFiltersDialogComponentV2, dialogConfig);
    dialogRef.afterClosed().subscribe(formData => {
      this.storeFavourite(formData);
    });
  }
  //#endregion

  //#region Page functions
  changeReportType($event) {
    this.reportSelected = $event.value;
    this.mainTableData = null;
    this.totalData = 0;
    this.tableDataSource = new MatTableDataSource<any>(this.mainTableData);
    this.summaryDataSource = new MatTableDataSource<any>(this.mainTableData);
    this.chartDataSource = new MatTableDataSource<any>(this.mainTableData);
    this.showSpinnerChart = false;
    switch ($event.value) {
      case ReportType.table:
        //remove GroupBy
        this.displayShareExport = true;
        this.tablePaginator.firstPage();
        this.tableDataSource.sort = this.tableSort;
        this.showSpinner = true;
        break;
      case ReportType.summary:
        this.groupByColumn = this.groupByColumns[0];
        this.summaryColumns = [...this.groupByColumn.columns];
        this.displayShareExport = true;
        this.summaryPaginator.firstPage();
        this.summaryDataSource.sort = this.summarySort;
        this.showSpinnerSummary = true;
        this.groupByColumnOptions = [...this.groupByColumns];
        break;
      case ReportType.chart: {
        const filteredList = this.groupByColumns.filter(x => Object.prototype.hasOwnProperty.call(x, 'pieChartDrillDown'));
        this.groupByColumn = filteredList[0];
        this.displayShareExport = false;
        this.showSpinnerChart = true;
        this.groupByColumnOptions = [...filteredList];
        break;
      }
    }
    this.cdr.detectChanges();
    this.setReportTypeEmitter.emit(true);
  }



  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }

  columnFormatter(column, value): string {
    if (column === 'level')
      console.dir(value);
    if (this.sharedFunctions.isDateFromColumnName(value, column)) {
      return moment(value).format(this.dateFormat);
    }
    if (this.sharedFunctions.isDateTimeFromColumnName(value, column)) {
      return moment(value).format(this.dateTimeFormat);
    }
    if (typeof (value) === 'number')
      return value.toString();
    return this.stringHelper.formatForEmpty(value);
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  clearSidebar() {
    this.sharedFiltersChild.reset();
    this.filter = [];
    this.resetDatePicker();
    this.resetAllFilters();
    this.tableDataSource.data = this.mainTableData;
    if (this.summaryDataSource.data.length > 0) {
      this.setGroupByColumn(this.groupByColumns[0]);
    }
    this.additionalFilterGroup.reset();
  }

  export(exportType: string) {
    this.classicReportService.postReportExport(this.report, this.filterString, this.sortString, this.displayedColumns.join(";"), exportType).subscribe({
      next: (data: any) => {
        this.snackBar.open(data.body, 'Close');
      },
      error: (error: any) => {
        console.dir(error);
        this.snackBar.open(error, "Close");
      }
    });
  }

  back() {
    this.router.navigate(['/reports/index'])
  }

  shareReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '500px';
    const dialogRef = this.dialog.open(ShareReportFiltersDialogComponentV2, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      const fullFilter = this.getFullFilterAsString();
      const reportNameForUrl = this.route.snapshot.url[0].path;
      const data = { "filter": fullFilter, "reportId": this.report, "staffId": this.userInfo.staffId, "name": formData.name, "staffToShareThisWith": formData.selectedStaff, "isSaveFilters": formData.isSaveFilters, "reportNameForUrl": reportNameForUrl }
      this.userService.shareFavouriteReportWithFilter(data).subscribe(filterId => {
        const snackBarMessage = "Filter shared: " + formData.name

        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
        let reportTitle = this.unCamelCase(ReportVariant[this.report]);
        if (data.isSaveFilters) {
          this.favouriteTableService
            .add({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: formData.name, filterId: filterId, reportId: this.report }, filterId)
            .then(id => {
              reportTitle = this.unCamelCase(ReportVariant[this.report]);
              this.cdr.detectChanges();
            })
        }
      });
    });
  }

  formatForEmpty(value): string {
    let formattedValue = value;
    switch (value) {
      case null:
      case undefined:
      case 'null':
      case '':
      case ' ':
      case '  ':
        formattedValue = '-';
        break;
    }
    return formattedValue;
  }

  storeFavourite(rawData) {
    const reportTitle = this.unCamelCase(ReportVariant[this.report]);
    if (rawData) {
      if (rawData.isSaveFilters) {
        const fullFilter = this.getFullFilterAsString();
        const saveData = { "filter": fullFilter, "reportId": this.report, "staffId": this.userInfo.staffId, "name": rawData.name }
        this.userService.postFavouriteReportWithFilter(saveData).subscribe(filterId => {
          this.favouriteTableService.add({
            id: filterId,
            title: reportTitle,
            link: '',
            tags: '',
            filter: fullFilter,
            name: rawData.name,
            filterId: filterId,
            reportId: this.report
          }, filterId)
            .then(id => {
              this.storedFilters.push({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: rawData.name, filterId: filterId, reportId: this.report });
              this.snackBar.open('Saved: ' + rawData.name, 'Close', { duration: 2000 });
              this.cdr.detectChanges();
            })
        })
      }
      else {
        const data = { "reportId": this.report, "staffId": this.userInfo.staffId };
        this.userService.addReportToFavourites(data).subscribe(id => {
          if (id) {
            this.favouriteTableService.add({
              type: 'report',
              id: id,
              title: reportTitle,
              link: '',
              tags: '',
              filter: '',
              name: '',
              reportId: this.report
            }, `report:${id}`)
              .then(localId => {
                this.waitingForToggle = false;
                this.isFavouriteReport.status = true;
                this.isFavouriteReport.data.Id = id;
                this.isFavouriteReport.data.key = `report:${id}`;
                this.snackBar.open(`${reportTitle} added to favourites`, 'Close', { duration: 2000 });
                this.cdr.detectChanges();
              })
          }
        })
      }
    }
  }

  viewSavedFilters() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '300px';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      storedFilters: this.storedFilters
    };

    const dialogRef = this.dialog.open(ShowSavedReportFiltersDialogComponentV2, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        this.showSpinner = true;
        this.clearSidebar();
        this.savedFilterToApply = formData.filter.filter;
        this.savedReportFilterName = formData.filter.name;
        this.loadSavedFilters();
        this.showSpinner = false;
        this.cdr.detectChanges();

        const snackBarMessage = "Filter loaded: " + formData.filter.name
        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
      }
    });
  }

  toggleReportFavorite() {
    this.waitingForToggle = true;
    this.isFavouriteReport.status = !this.isFavouriteReport.status;
    if (this.isFavouriteReport.status) {
      this.storeFavourite({ isSaveFilters: false });
    }
    else {
      const data = { "type": 'report', "Id": this.isFavouriteReport.data.Id, "staffId": this.userInfo.staffId };
      this.userService.removeFavourite(data).subscribe(response => {
        if (response) {
          this.favouriteTableService.remove(this.isFavouriteReport.data.key);
          this.waitingForToggle = false;
          this.snackBar.open("Removed as a Favourite",
            'OK', { duration: 2000 });
        }
      });
    }
  }
  //#endregion
}
