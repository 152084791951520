import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { AttendanceApprovalService, TimeEdit } from "../attendance-approval.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { InfoDialog } from "src/app/shared/interfaces/info-dialog";
import { InfoDialogHelper } from "src/app/shared/components/info-dialog/info-dialog.helper";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FromValidators } from "src/app/shared/form-validators";
import { InfoDialogComponent } from "src/app/shared/components/info-dialog/info-dialog.component";

@Component({
  selector: 'app-time-edit-dialog',
  templateUrl: './time-edit-dialog.component.html',
  styleUrls: ['./time-edit-dialog.component.scss']
})
export class TimeEditDialogComponent {
  savingDialogConfig: MatDialogConfig<InfoDialog>;
  savedDialogConfig: MatDialogConfig<InfoDialog>;
  errorDialogConfig: MatDialogConfig<InfoDialog>;
  timeEditForm: FormGroup;
  timeInFC: any;
  timeOutFC: any;
  timeInCommentFC: any;
  timeOutCommentFC: any;
  timeIn: string;
  timeOut: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      timeData: TimeEdit,
      centreStartTime: string,
      centreEndTime: string,
      name: string,
      date: string
    },
    private infoDialogHelper: InfoDialogHelper,
    private dialog: MatDialog,
    private formValidators: FromValidators,
    private dialogRef: MatDialogRef<TimeEditDialogComponent>,
    private attendanceApprovalService: AttendanceApprovalService,
    private cdr: ChangeDetectorRef
  ) {
    this.timeIn = data.timeData.timeIn;
    this.timeOut = data.timeData.timeOut;
    this.timeEditForm = new FormGroup({
      timeIn: new FormControl(data.timeData.timeIn.substring(0, 5), { validators: [Validators.required], updateOn: 'change' }),
      timeOut: new FormControl(data.timeData.timeOut.substring(0, 5), { validators: [Validators.required], updateOn: 'change' }),
      timeInComment: new FormControl(data.timeData.commentsTimeIn),
      timeOutComment: new FormControl(data.timeData.commentsTimeOut),
    });
    this.timeInFC = this.timeEditForm.controls.timeIn;
    this.timeOutFC = this.timeEditForm.controls.timeOut;
    this.timeInCommentFC = this.timeEditForm.controls.timeInComment;
    this.timeOutCommentFC = this.timeEditForm.controls.timeOutComment;
    this.savingDialogConfig = infoDialogHelper.savingDialogConfig('Saving Time Edit', '');
    this.savedDialogConfig = infoDialogHelper.savedDialogConfig('Saved Successfully!', 'Time modification has been saved successfully!');
    this.errorDialogConfig = infoDialogHelper.errorDialogConfig('Error Saving!', 'Something went wrong! Please try again later.');
  }
  onClose() {
    this.dialogRef.close("cancel");
  }
  timeInStringOnChange(timeIn) {
    this.timeIn = timeIn.target.value;
    if (this.timeOut !== '') {
      this.timeInFC.setValidators([Validators.required, this.formValidators.timeIsAfterOtherInput(this.timeOut)]);
      this.timeOutFC.setValidators([Validators.required, this.formValidators.timeIsBeforeOtherInput(this.timeIn)]);
      this.timeInFC.updateValueAndValidity();
      this.timeOutFC.updateValueAndValidity();

      if (this.timeIn !== this.data.timeData.timeIn.substring(0, 5)) {
        this.timeInCommentFC.setValidators([Validators.required]);
      }
      if (this.timeIn === this.data.timeData.timeIn.substring(0, 5)) {
        this.timeInCommentFC.setValidators(null);
      }
      this.timeInCommentFC.updateValueAndValidity();
      this.timeEditForm.markAllAsTouched();
    }
  }
  timeOutStringOnChange(timeOut) {
    /*this.edited = true;
    this.timeOut = timeOut.target.value;
    if (this.timeOut !== this.data.timeData.timeOut.substring(0, 5)) {
      this.timeOutCommentFC.setValidators([Validators.required]);
    }
    if (this.timeOut === this.data.timeData.timeOut.substring(0, 5)) {
      this.timeOutCommentFC.setValidators(null);
    }
    this.timeOutCommentFC.updateValueAndValidity();
    this.timeEditForm.markAllAsTouched();*/
    this.timeOut = timeOut.target.value;
    if (this.timeOut !== '') {
      this.timeInFC.setValidators([Validators.required, this.formValidators.timeIsAfterOtherInput(this.timeOut)]);
      this.timeOutFC.setValidators([Validators.required, this.formValidators.timeIsBeforeOtherInput(this.timeIn)]);
      this.timeInFC.updateValueAndValidity();
      this.timeOutFC.updateValueAndValidity();

      if (this.timeIn !== this.data.timeData.timeIn.substring(0, 5)) {
        this.timeOutCommentFC.setValidators([Validators.required]);
      }
      if (this.timeIn === this.data.timeData.timeIn.substring(0, 5)) {
        this.timeOutCommentFC.setValidators(null);
      }
      this.timeOutCommentFC.updateValueAndValidity();
      this.timeEditForm.markAllAsTouched();
    }
  }
  onSave() {
    const payload = {
      signId: this.data.timeData.signId,
      timeIn: this.timeEditForm.get('timeIn').value + ":00",
      timeOut: this.timeEditForm.get('timeOut').value + ":00",
      commentsTimeIn: this.timeEditForm.get('timeInComment').value,
      commentsTimeOut: this.timeEditForm.get('timeOutComment').value
    }
    const savingDialog = this.dialog.open(InfoDialogComponent, this.savingDialogConfig);
    this.attendanceApprovalService.editTimeDetails(payload).subscribe({
      next: () => {
        savingDialog.close();
        this.dialog.open(InfoDialogComponent, this.savedDialogConfig);
        this.dialogRef.close("saved");
      },
      error: () => {
        savingDialog.close();
        this.dialog.open(InfoDialogComponent, this.errorDialogConfig);
      }
    });
  }
}
