import { UserTableService } from './../../core/database/user-table.service';
import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IqaRecordFormService } from './iqa-record-form/iqa-record-form.service';
import { IqaRecordFormComponent } from './iqa-record-form/iqa-record-form.component';
import { SubmitForIqaComponent } from './submit-for-iqa/submit-for-iqa.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { LearnerService } from '../learnerService.service';
import { LearnerPageComponent } from '../learner-page.component';
import { DATE_FORMAT, NO_DATA } from 'src/app/app.constants';
import { SitePermissions } from 'src/app/shared/enums/permissions';

@Component({
  selector: 'learner-iqa',
  templateUrl: './learner-iqa.component.html',
  styleUrls: ['./learner-iqa.component.scss']
})
export class LearnerIqaComponent implements LearnerPageComponent {
  iqaRecords: any;
  iqaRequests: any;
  iqaFormOptions: any;

  requestsSuccess: boolean;
  IqaRecordsSuccess: boolean;

  permissions: {
    canCreateRecord: boolean,
    canUpdateRecord: boolean,
    canDeleteRecord: boolean,
    canDeleteRequest: boolean,
  } = { canCreateRecord: false, canUpdateRecord: false, canDeleteRecord: false, canDeleteRequest: false };

  objectives: any[] = [];

  traineeId: string;
  pot: any;

  staffId: number;
  staffName: string;

  displayedColumns: string[] = ['title', 'datePlanned', 'iqaType', 'status'];
  requestsDisplayedColumns = ['title', 'type', 'priority', 'requestedBy', 'requestDate', 'actions'];
  dataSource = new MatTableDataSource([]);
  requestsDataSource = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageEvent: PageEvent;

  iqaLoaded = false;

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;

  constructor(
    private userTableService: UserTableService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private iqaRecordService: IqaRecordFormService,
    private cdf: ChangeDetectorRef,
    private sharedFunctions: SharedFunctionsService,
    private learnerService: LearnerService,
  ) {
    this.userTableService.get(1)
      .then(user => {
        if (!user) {
          this.router.navigate(['']);
        }
        this.staffName = user.userName;
        this.permissions = {
          canCreateRecord: user.permission.includes(SitePermissions.IqaCreateRecord),
          canUpdateRecord: user.permission.includes(SitePermissions.IqaUpdateRecord),
          canDeleteRecord: user.permission.includes(SitePermissions.IqaDeleteRecord),
          canDeleteRequest: user.permission.includes(SitePermissions.IqaDeleteRequest),
        };
      });
  }

  public getData(): void {
    this.getIqaData();
  }

  getIqaData() {
    const subscription = this.learnerService.GetLearnerIqa(this.traineeId, this.pot, this.staffId).subscribe({
      next: (response) => {
        this.objectives = response.objectives.data;
        this.iqaFormOptions = response.iqaFormOptions.data;
        this.IqaRecordsSuccess = response.iqaData.records.success;
        if (this.IqaRecordsSuccess) {
          if (response.iqaData.records.data.length > 0) {
            this.iqaRecords = this.sharedFunctions.genericOrderedDate(response.iqaData.records.data, 'datePlanned', true, false);
            const iqaRecordsFormated = this.formatIqaRecords([...this.iqaRecords]);
            this.dataSource.data = iqaRecordsFormated;
          }
        } else {
          console.log('Error loading iqa records:', response.iqaData.records.error);
        }
        this.requestsSuccess = response.iqaData.requests.success;
        if (this.requestsSuccess) {
          this.iqaRequests = response.iqaData.requests.data;
          if (!this.permissions.canDeleteRequest && !this.permissions.canCreateRecord) {
            this.requestsDisplayedColumns.pop();
          }
          this.requestsDataSource.data = this.iqaRequests;
        } else {
          console.log('Error loading iqa requests:', response.iqaData.requests.error);
        }
      },
      error: (error) => {
        console.log('Error loading iqa data:', error.error);
        this.snackBar.open('Error loading iqa data', 'Close', {
          duration: 3000,
        });
        this.iqaLoaded = true;
        this.cdf.detectChanges();
        subscription.unsubscribe();
      },
      complete: () => {
        this.iqaLoaded = true;
        this.cdf.detectChanges();
        subscription.unsubscribe();
      },
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  formatIqaRecords(iqaRecords: any): any {
    const iqaRecordsFormated = iqaRecords.map(item => {
      const container = {};
      container['iqaRecordId'] = item.iqaRecordId;
      container['title'] = item.title;
      container['iqaType'] = item.iqaType;
      container['datePlanned'] = item.datePlanned;
      container['dateSignedOff'] = item.dateSignedOff;
      container['staffNameSignedOff'] = item.staffNameSignedOff;
      container['certReceived'] = item.certReceived;

      switch (item.iqaOutcomeShort) {
        case 'Failed':
        case 'M-F':
        case 'I-F':
          container['status'] = { status: 'Failed', class: 'outcome-stamp-fail' };
          break;
        case 'Pass':
        case 'M-P':
        case 'I-P':
          container['status'] = { status: 'Passed', class: 'outcome-stamp-pass' };
          break;
        case 'Passed CTA':
          container['status'] = { status: 'Passed CTA', class: 'outcome-stamp-provisional' };
          break;
        case 'Referred':
          container['status'] = { status: 'Referred', class: 'outcome-stamp-referred' };
          break;
        case 'Skipped':
          container['status'] = { status: 'Skipped', class: 'outcome-stamp-skipped' };
          break;
        case 'Waiting':
          container['status'] = { status: 'Waiting', class: 'outcome-stamp-waiting' };
          break;
        case null:
          container['status'] = { status: 'Draft', class: 'outcome-stamp-draft' };
          break;
        default:
          container['status'] = { status: 'Unknown', class: 'outcome-stamp-unknown' };
          break;
      }

      return container;
    });

    return iqaRecordsFormated;
  }

  openDialog(selectedRecord?) {
    let record = {};
    if (selectedRecord) {
      record = this.iqaRecords.find(rec => rec.iqaRecordId == selectedRecord.iqaRecordId);
    }
    this.openRecordDialog(record);
  }

  submitForIQAClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      objectives: this.objectives,
      formOptions: this.iqaFormOptions
    }
    const dialogRef = this.dialog.open(SubmitForIqaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      formData => {
        if (formData) {
          const dataToSend = {
            'TraineeId': this.traineeId,
            'Pot': this.pot,
            'LaimRef': formData.value.objective,
            'Iqatypeid': formData.value.iqaType,
            'Staffid': this.staffId,
            'Iqasubmissionfoldertypeid': formData.value.portfolioType == "e-Portfolio" ? 2 : 1,
            'Iqasubmissionpriorityid': formData.value.priority == "Non-Priority" ? 0 : 1
          }
          this.iqaRecordService.submitForIQA(dataToSend).subscribe({
            next: (result) => {
              this.iqaRequests.push(
                {
                  'title': this.objectives.find(o => o.laimRef === result.laimRef).title,
                  'laimRef': result.laimRef,
                  'awardingBody': this.objectives.find(o => o.laimRef === result.laimRef).awardingBody,
                  'type': this.iqaFormOptions.iqaFormOptionIQAType.find(t => t.iqaTypeId === result.iqatypeid).iqaType,
                  'typeId': result.iqaTypeId,
                  'priority': result.iqasubmissionpriorityid === 0 ? 'N' : 'Y',
                  'requestedBy': this.staffName,
                  'requestDate': result.datesubmitted,
                  'submissionId': result.submissionid,
                });
              this.requestsDataSource.data = this.iqaRequests;
            },
            error: (error) => {
              console.log('Error submitting for iqa:', error.error);
              this.snackBar.open('Error submitting for iqa, please reload and try again. If this error persists, please contact system support.', 'Close', {});
            },
            complete: () => {
              this.snackBar.open("Submitted for IQA", "Success", {
                duration: 3000,
              });
            }
          });
        }
      });

    return false;

  }

  // export(data: FormData[], exportType: string) {
  //   this.sharedFunctions.export(data, this.displayedColumns, 'iqa records', exportType);
  // }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }

  openRecordDialog(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      objectives: this.objectives,
      traineeId: this.traineeId,
      pot: this.pot,
      formOptions: this.iqaFormOptions,
      dateSignedOff: null,
      certReceived: null,
      allRecords: this.iqaRecords,
      canCreate: this.permissions.canCreateRecord,
      canDelete: this.permissions.canDeleteRecord,
      canUpdate: this.permissions.canUpdateRecord,
      ...data,
    };
    const dialogRef = this.dialog.open(IqaRecordFormComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .subscribe(
        formData => {
          if (formData) {
            if (formData.delete === true) {
              // delete record
              this.iqaRecords = this.iqaRecords.filter(r => r.iqaRecordId !== formData.recordId);
              this.dataSource.data = this.dataSource.data.filter(r => r.iqaRecordId !== formData.recordId);
            }
            if (formData.add === true) {
              this.iqaRecords.push(formData.iqaRecord);
              this.iqaRecords = this.sharedFunctions.genericOrderedDate(this.iqaRecords, 'datePlanned', true);
              this.dataSource.data = this.formatIqaRecords([...this.iqaRecords]);
            }
            if (formData.update === true) {
              const ix = this.iqaRecords.findIndex(i => i['iqaRecordId'] == formData.iqaRecordId);
              if (ix != -1) {
                this.iqaRecords[ix] = formData.iqaRecord;
                const iqaRecordsFormated = this.formatIqaRecords(this.iqaRecords);
                this.dataSource.data = iqaRecordsFormated;
              }
            }
            this.cdf.detectChanges();
          }
        }
      );
  }

  createRecordFromRequest(request: any) {
    this.openRecordDialog({
      laimRef: request.laimRef,
      awardingBody: request.awardingBody,
      title: request.title,
      iqaTypeId: request.typeId,
      laimSpecification: this.objectives.find(o => o.laimRef === request.laimRef).laimSpecification,
    });
  }

  removeRequest(request: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: `This action will delete the *${request.type}* IQA request for **${request.title}** by ${request.requestedBy}.`,
      title: 'Are you sure?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      type: 'warn',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.iqaRecordService.removeSubmitForIQA(request.submissionId).subscribe({
          next: () => {
            this.iqaRequests = this.iqaRequests.filter(r => r.submissionId !== request.submissionId);
            this.requestsDataSource.data = this.iqaRequests;
          },
          error: (error) => {
            if (error === 'You do not have permission to complete the requested action.') {
              this.snackBar.open(error, 'Close', {
                duration: 3000,
              });
              console.log(error);
            } else {
              console.log('Error removing iqa request:', error.error);
              this.snackBar.open('Error removing iqa request, please reload and try again. If this error persists, please contact support.', 'Close');
            }
          },
          complete: () => {
            this.snackBar.open('IQA request deleted', 'Close', {
              duration: 3000,
            });
          }
        });
      }
    });
  }


}
