<h3>Modify Learner Time: </h3>
<h3>{{data.name}} on {{data.date}}</h3>
<mat-dialog-content>
  <form [formGroup]="timeEditForm" class="time-edit-dialog">
    <mat-form-field appearance="outline">
      <mat-label>Time In</mat-label>
      <input matInput type="time" formControlName="timeIn" (change)="timeInStringOnChange($event)">
      <mat-error *ngIf="timeInFC.errors?.timeIsBefore">
        The time entered is before the opening time of the centre ({{data.centreStartTime.substring(0,5)}}).
      </mat-error>
      <mat-error *ngIf="timeInFC.errors?.timeIsAfterOtherInput">
        The time entered is after the time out.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Time In Comments</mat-label>
      <input matInput formControlName="timeInComment">
      <mat-error *ngIf="timeInCommentFC.errors?.required">
        Time in comments are required.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Time Out</mat-label>
      <input matInput type="time" formControlName="timeOut" (change)="timeOutStringOnChange($event)">
      <mat-error *ngIf="timeOutFC.errors?.timeIsAfter">
        The time entered is after the closing time of the centre ({{data.centreEndTime.substring(0,5)}}).
      </mat-error>
      <mat-error *ngIf="timeOutFC.errors?.timeIsBeforeOtherInput">
        The time entered is before the time in.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Comments Time Out</mat-label>
      <input matInput formControlName="timeOutComment">
      <mat-error *ngIf="timeOutCommentFC.errors?.required">
        Time out comments are required.
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-raised-button mat-dialog-close (click)="onClose()">Cancel</button>
  <button mat-button mat-raised-button color="primary" type="submit"
    [disabled]="timeEditForm.invalid || timeEditForm.pristine" (click)="onSave()">Authorise</button>
</mat-dialog-actions>