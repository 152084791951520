import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StaffDashboardComponent } from './staff-dashboard.component';
import { AuthGuard } from 'src/app/core/services/auth-guard.service';

const routes: Routes = [

  { path: 'staff-dashboard', component: StaffDashboardComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class StaffDashboardRoutingModule { }
