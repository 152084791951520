import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LorReportService {
  apiUrl: string;
  token: string;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    const options = { headers: headers };
    return options;
  }

  getReportFilter(reportId: number, staffId: number) {
    const report = ReportVariant[reportId];
    const url = `${environment.API_URL}V2/ReportingFilter/${report}/${staffId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe();
  }

  getTermContractYearFilter(reportId: number, programme: string) {
    const report = ReportVariant[reportId];
    const url = `${environment.API_URL}V2/ReportingFilter/${report}/termcontractyear/${programme}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe();
  }

  getReportData(groupBy: string, reportId: number, staffId: number, pageNumber: number, pageSize: number, filter: string = null, sortColumn: string = null, sortColumnDirection: string = null) {
    const report = ReportVariant[reportId];
    let url = `${environment.API_URL}V2/ReportingData/${report}?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}&groupByString=${groupBy}`;
    if (filter !== null) {
      url += `&filterString=${filter}`;
    }
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options).pipe();
  }

  postReportExport(reportId: number, filterString: string, sortString: string, groupByString: string, displayedColumns: string, reportFormat: string) {
    const url = `${environment.API_URL}V2/Reporting/QueueExport`;
    const options = this.getOptionsWithAccessToken();
    options['observe'] = 'response';
    const body = {
      reportId: reportId,
      filterString: filterString,
      sortString: sortString,
      reportFormat: reportFormat,
      displayedColumns: displayedColumns,
      groupByString: groupByString
    };
    return this.http.post(url, body, options);
  }
}
