export class UserInfo {
  staffId: number;
  userName: string;
  jobRole: string;
  email: string;
  department: string;
  canImpersonate: boolean;
  impersonatingRole: string;
  isImpersonating: boolean;
  visionAdmin: boolean;
  countUnreadNotifications?: number;
  mode: string;
  permission: number[];
}
