import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-event-form',
  templateUrl: './add-event-form.component.html',
  styleUrls: ['./add-event-form.component.scss']
})
export class AddEventFormComponent implements OnInit {

  employerId: number;
  learnerId: string;
  pot: number;
  searchForm: UntypedFormGroup;
  autoCompleteOptions;
  options: any;
  learners: any[] = [];
  employers: any[] = [];
  selectedLearner: any;
  addOnBlur = false;
  rawAutoCompleteOptions;
  selectedEmployer: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddEventFormComponent>
    ) { }


    displayFn(item: any): string {
      return item && item.fullName ? item.fullName : '';
    }

    displayFnEmployer(item: any): string {
      return item && item.name ? item.name : '';
    }

  ngOnInit(): void {
    this.pot = 1; // TODO: pot selector?
    console.log('event for employer');
    this.employers = this.data.employers;
    this.rawAutoCompleteOptions = this.employers;
    this.autoCompleteOptions = this.employers;

    this.buildForm();

    this.options = this.searchForm.get('searchText').valueChanges
    .pipe(
      startWith(''),
      map(value => this.filterDataEmployer(value).slice(0, 10))
    );
  }

  private filterDataEmployer(value: string): string[] {
    if (value && value.length > 1 && this.autoCompleteOptions) {
      const filterValue = value.toLowerCase();
      return this.autoCompleteOptions.filter(

        option => {
          if(option && option.name){
           return option.name.toLowerCase().includes(filterValue)
          }
        }

        ).slice(0, 10);
    }
    else {
      return this.autoCompleteOptions.slice(0, 10);
    }
  }


  filterOnString(delegate: string, filterString: string): boolean {
    let isMatch = false;

    if (delegate != null && delegate.toLowerCase() == filterString.toLowerCase()) { return true };

    let delegateParts = delegate?.split(' ');
    let filterParts = filterString.split(' ');

    if (delegateParts) {
      delegateParts.forEach(
        delegatePart => {
          if (!isMatch) {
            if (delegatePart.substr(0, filterString.length).toLowerCase() == filterString.toLowerCase()) {
              isMatch = true;
            } else {
              filterParts.forEach(
                filterPart => {
                  if (delegatePart.substr(0, filterPart.length).toLowerCase() == filterPart.toLowerCase()) {
                    isMatch = true;
                  }
                });
            }
          }
        });
    }

    return isMatch;
  }

  buildForm() {
    this.searchForm = this.fb.group({
      searchText: null
    })
  }

  selectOption(learner){
    console.log("learner selected", learner, this.searchForm.value.searchText)
      this.selectedEmployer = this.searchForm.value.searchText;
      this.selectEmployer();
  }

  selectLearnerEvent() {
    console.log('selected learner', this.selectedLearner);
    this.navToEvents('learner');
    this.dialogRef.close();
  }

  selectEmployer() {
    console.log('selected employer', this.selectedEmployer);
    this.navToEvents('employer');
    this.dialogRef.close();
  }

  navToEvents(eventsType: string) {
    if(eventsType == 'employer'){
      if(this.selectedEmployer["source"] == "DT_V_VEMPLOYERS"){
        this.router.navigate([`employer-old/dashboard/${this.selectedEmployer.id}/loadEvents`]);
      }
      else{
        this.router.navigate([`employer-old/dashboard/branch/${this.selectedEmployer.id}/loadEvents`]);
      }
    }
    else {
      this.router.navigate([`learner/events/${this.selectedLearner.traineeId}/${this.pot}/loadEvents`]);
    }
  }

  cancelOperation() {
    this.dialogRef.close();
  }

}
