import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EmployerDashboardService } from 'src/app/employer-dashboard/employer-dashboard.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-employer-basics',
  templateUrl: './employer-basics.component.html',
  styleUrls: ['./employer-basics.component.scss']
})
export class EmployerBasicsComponent implements OnInit {

  @Input() placementBasics: any;
  @Input() isEditForm: boolean;
  @Input() doSaveData: boolean;
  businessDevelopmentStaff;
  holderWhenUpdatingAccountManager;

  @Output() empBasicsUpdate = new EventEmitter();

  placementAddress: string = '';
  basicsForm: any;

  constructor(private fb: UntypedFormBuilder, private employerDashboardService: EmployerDashboardService) { this.basicsForm = new UntypedFormGroup({}) }

  ngOnInit(): void {
    this.buildForm();

    
  }

  getBusinessDevStaffAndSetDefault(){



    this.employerDashboardService.getBusinessDevelopmentStaff().subscribe(response => {

      this.basicsForm = this.fb.group({
        'Address 1': this.tidyString(this.placementBasics?.address1),
        'Address 2': this.tidyString(this.placementBasics?.address2),
        'Address 3': this.tidyString(this.placementBasics?.address3),
        'Phone': this.tidyString(this.placementBasics?.phone),
        'Postcode': this.tidyString(this.placementBasics?.postCode),
        'Post Town': this.tidyString(this.placementBasics?.postTown),
        'AccountManager': this.placementBasics.accountManagerName // todo: select from list. 
      });
      var b = this.basicsForm.get("AccountManager")
      console.log("here", b)

      this.businessDevelopmentStaff = response;
      var initial =  this.businessDevelopmentStaff.filter(({ name }) => name == this.placementBasics.accountManagerName);
      if(initial.length > 0){
        //var a = this.basicsForm.get("AccountManager")
        console.log("to here")
        //console.log("just not here?", b)
        this.basicsForm.get("AccountManager").patchValue(initial[0])
      } 
  
     })
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.placementBasics) {
      if (changes.placementBasics.previousValue != this.placementBasics) {
        this.placementBasics = changes.placementBasics.currentValue;
        this.buildAddress();
      }
    }
  }

  buildAddress() {
    this.placementAddress = '';
    for (let element in this.placementBasics) {
      if ((element.includes('address') || element.includes('post')) && this.placementBasics[element]) {
        this.placementAddress += ' '  + this.placementBasics[element] + ',';
      }
    }
    this.placementAddress = this.tidyString(this.placementAddress);
  }

  tidyString(value: string): string{
    if(value) value =  value.replace('  ', ' ').trim();
    return value;
  }

  buildForm() {
   

    this.getBusinessDevStaffAndSetDefault();

  }

  basicsUpdate(evt) {
    let key = this.camelize(evt.key);
    this.placementBasics[key] = evt.value.value;
    this.buildAddress();
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  updateAccountManagerAfterEdit(){
    this.placementBasics.accountManagerName = this.holderWhenUpdatingAccountManager.name;
  }

  emitData() {
    //console.log('emit data from employer-basics');

    if (this.basicsForm.dirty) {
      const returnData = { values: this.basicsForm.value, operation: 'update' };
      this.holderWhenUpdatingAccountManager = returnData.values.AccountManager
      returnData.values.BusinessManagementEmail = returnData.values.AccountManager.email
      this.empBasicsUpdate.emit({ 'placementBasics': returnData });
      this.basicsForm.markAsUntouched();
      this.basicsForm.pristine = true;
    }
    else {
      console.log('no changes to emit');
    }

  }

}


