import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../core/services/auth-guard.service";
import { AttendanceTemplateComponent } from "./attendance-template/attendance-template.component";
import { AttendanceTemplateResolverService } from "./attendance-template/attendance-template-resolver.service";

const routes: Routes = [
  { path: ':tabName', component: AttendanceTemplateComponent, resolve: { learner: AttendanceTemplateResolverService }, canActivate: [AuthGuard] }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AttendanceRoutingModule { }
