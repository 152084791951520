import { ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild } from "@angular/core";
import { HomeDashboardService } from "../../home-dashboards/home-dashboard.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { error } from "protractor";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StringHelper } from "src/app/shared/string-helper";
import { filter } from "underscore";
import moment from 'moment';

@Component({
  selector: 'app-vast-data-export',
  templateUrl: './vast-data-export.component.html'
})
export class VastDataExportComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @ViewChild('reportSelect') reportSelect;
  @ViewChild('ageCategorySelect') ageCategorySelect;
  @ViewChild('contractSelect') contractSelect;
  @ViewChild('contractYearSelect') contractYearSelect;
  @ViewChild('dateFilterSelect') dateFilterSelect;
  @ViewChild('dateRange') dateRangePicker;

  form: FormGroup;
  ranges: any = {};

  constructor(
    private homeDashboardService: HomeDashboardService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private stringHelper: StringHelper

  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      reportSelect: [this.reportSelect, [Validators.required]],
      ageCategorySelect: [this.ageCategorySelect],
      contractSelect: [this.contractSelect],
      contractYearSelect: [this.contractYearSelect],
      dateFilterSelect: [this.dateFilterSelect, [Validators.required]],
      dateRange: [this.dateRangePicker, [Validators.required]]
    });
    this.populateReportSelect();
    this.setupDatePicker();
  }

  populateReportSelect() {
    this.homeDashboardService.getVastDataExport().subscribe(
      result => {
        console.dir(result);
        this.reportSelect = result.reports;
        this.ageCategorySelect = result.ageCategories;
        this.contractSelect = result.contracts;
        this.contractYearSelect = result.contractYears;
        this.dateFilterSelect = result.dateFilters;
        this.cdr.detectChanges();
      }
    )
  }

  setupDatePicker() {
    const dayOfYear = moment.utc().dayOfYear();
    const firstContractDay = moment();
    const lastContractDay = moment();
    const previousfirstContractDay = moment()
    const previouslastContractDay = moment()
    if (dayOfYear < 213) {
      firstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 }) // moment months start counting at 0!
      lastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 2, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year() - 1, 'month': 6, 'date': 31 })
    } else {
      firstContractDay.set({ 'year': moment().year(), 'month': 7, 'date': 1 })
      lastContractDay.set({ 'year': moment().year() + 1, 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
    }

    this.ranges = {
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'This Contract Year': [firstContractDay, lastContractDay],
      'Last Contract Year': [previousfirstContractDay, previouslastContractDay]
    }
  }

  submit(exportType: string) {
    let filterString = `${this.form.value.dateFilterSelect}::${this.form.value.dateRange.startDate.format('DD-MM-YYYY')},${this.form.value.dateRange.endDate.format('DD-MM-YYYY')};`;
    const reportId = this.form.value.reportSelect;
    const ageCategoryValue = this.form.value.ageCategorySelect;
    const contractValue = this.form.value.contractSelect;
    const contractYearValue = this.form.value.contractYearSelect;
    if (this.stringHelper.notNullOrEmpty(this.form.value.ageCategorySelect)) {
      filterString += `ageCategory::${ageCategoryValue};`;
    }
    if (this.stringHelper.notNullOrEmpty(this.form.value.contractSelect)) {
      filterString += `contractId::${contractValue};`;
    }
    if (this.stringHelper.notNullOrEmpty(this.form.value.contractYearSelect)) {
      filterString += `startContractYear::${contractYearValue};`;
    }
    filterString = filterString.slice(0, -1);
    this.homeDashboardService.postVastDataExport(reportId, exportType, filterString).subscribe({
      next: (data: any) => {
        this.snackBar.open(data, 'Close');
      },
      error: (err: any) => {
        this.snackBar.open(err, "Close");
      }
    })
  }

  clear() {
    this.form.reset();
    this.form.markAsPristine();
  }
}
