<div>
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <div style="width: 100%" [style.marginBottom.px]="this.mode === this.eventMode.view ? '0' : '20'">
      <div style="display: flex; flex-direction: row; gap: 10px">
        <h1 mat-dialog-title style="margin-bottom: 0; flex: 1">{{ this.eventHeader }}

          <span *ngIf="this.eventDate !== null"> -
            <span style="font-weight: 400;">
              {{ this.eventDate | date: this.dateFormat }}
            </span>
          </span>

        </h1>
        <form *ngIf="this.mode !== this.eventMode.view" [formGroup]="eventsForm">
          <mat-checkbox formControlName="isDraft" [labelPosition]="'before'">Draft</mat-checkbox>
        </form>
      </div>
      <h3 mat-dialog-title style="font-size: 14px; margin-bottom: 0;" *ngIf="this.eventSubHeader">
        {{ this.eventSubHeader}}
      </h3>
    </div>
    <div *ngIf="this.mode === this.eventMode.view" style="display: flex; flex-direction: row; gap: 10px">
      <mat-icon *ngIf="this.deletePermissionCheck()" (click)="this.delete()" class="icon delete">
        delete_forever
      </mat-icon>
      <mat-icon *ngIf="this.editPermissionCheck()" (click)="this.changeEventMode(this.eventMode.edit)"
        class="icon edit">
        edit
      </mat-icon>
    </div>
  </div>

  <div mat-dialog-content style="padding: 0 60px">
    <form [formGroup]="eventsForm" novalidate (ngSubmit)="close()" class="event-form">
      <mat-form-field *ngIf="this.mode !== this.eventMode.view" style="width: 100%; margin-bottom: 20px;">
        <mat-label class="EventsFormTitle">Event Type</mat-label>
        <mat-select required focused="false"
          formControlName="eventType"
          (selectionChange)="this.auditEventCheck($event.value)"
          panelClass="matSelectPanelBelow"
          [disableOptionCentering]="true"
        >
        <div style="padding: 10px 0">
          <mat-option *ngIf="this.data.allEventTypes === null; else showEvents" disabled style="color: #ff5722;">
            Error loading event types. Please reload page, if this persists contact support.
          </mat-option>

          <!-- List all filtered events -->
          <ng-template #showEvents>
            <div style="display: flex;">
              <input #searchInput type="text" class="event-search" [(ngModel)]="this.typeSearchFilter" [ngModelOptions]="{standalone: true}" placeholder="Search..."/>
            </div>
            <mat-divider style="margin-bottom: 8px;"></mat-divider>

            <div *ngIf="this.filteredEvents?.length > 0; else noEvents" style="overflow: auto; max-height: 185px">
              <mat-option *ngFor="let event of this.filteredEvents" [value]="event">
                {{ event.eventType }}
              </mat-option>
            </div>

            <ng-template #noEvents>
              <mat-option disabled>No events found.</mat-option>
            </ng-template>

          </ng-template>
        </div>
        </mat-select>
        <mat-error *ngIf="this.eventsForm.controls.eventType.errors">
          A type is required
        </mat-error>
      </mat-form-field>


      <mat-form-field *ngIf="this.mode !== this.eventMode.view; else commentText" class="iqa-new-record"
        appearance="outline">
        <mat-label class="EventsFormTitle">Comments</mat-label>
        <textarea matInput formControlName="comments" cdkAutosizeMinRows="10" style="min-height: 150px;"></textarea>
      </mat-form-field>

      <ng-template #commentText>
        <mat-divider style="margin: 8px 0"></mat-divider>
        <div style="width: calc(100% - 40px); padding: 12px 20px; min-height: 150px; display: flex; align-items: center;">
          <p style="margin: 0; white-space: pre-line">
            {{ eventsForm.controls.comments.value }}
          </p>
        </div>
      </ng-template>

      <div *ngIf="this.mode !== this.eventMode.view" style="display: flex; flex-direction: row; justify-content: space-between;">
        <mat-form-field appearance="outline">
          <mat-label class="EventsFormTitle">Event Date</mat-label>
          <input matInput readonly id="event_date" style="cursor: default;"
            [matDatepicker]="pickerEvent"
            formControlName="eventDate"
            required
          >
          <mat-datepicker-toggle matSuffix [for]="pickerEvent"></mat-datepicker-toggle>
          <mat-datepicker #pickerEvent></mat-datepicker>
          <mat-error *ngIf="this.eventsForm.controls.eventDate.errors">A date is required</mat-error>
        </mat-form-field>

        <div *ngIf="this.auditEvent">
          <mat-form-field appearance="outline">
            <mat-label class="EventsFormTitle">Signed Off Date</mat-label>
            <input matInput readonly id="signedOffDate" style="cursor: default;"
              [matDatepicker]="signedOffDate"
              formControlName="signedOffDate"
            >
            <mat-datepicker-toggle matSuffix [for]="signedOffDate"></mat-datepicker-toggle>
            <mat-datepicker #signedOffDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>

  </div>

  <div mat-dialog-actions>
    <mat-divider style="margin-bottom: 5px; width: 100%"></mat-divider>
    <div style="width: 100%; display: flex; flex-direction: row; gap: 5px;">
      <p *ngIf="this.auditEvent && this.mode === this.eventMode.view" style="font-size: 14px; margin: 0;">
        {{this.signedOffDate !== null ? 'Signed off on ' + (this.signedOffDate | date: this.dateFormat)
        : 'Not yet signed off' }}
      </p>

      <div style="flex:1"> </div>

      <button mat-stroked-button (click)="close()">
        {{ this.mode === this.eventMode.view ? 'Close' : 'Cancel'}}
      </button>

      <button *ngIf="this.mode !== this.eventMode.view"
        mat-flat-button color="primary"
        [mat-dialog-close]="eventsForm"
        [disabled]="!this.canSave()"
      >
        {{ this.mode === this.eventMode.edit ? 'Update' : 'Create event' }}
      </button>
    </div>
  </div>
</div>
