import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public getNotifications(): Observable<any> {
    const url = `${environment.API_URL}GetNotifications`; 
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public markNotificationsAsRead(): Observable<any> {
    const url = `${environment.API_URL}MarkNotificationsAsRead`; 
    const options = this.getOptionsWithAccessToken();
    return this.http.put(url, null, options);
  }
}
