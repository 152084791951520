
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { LearnerService } from '../learnerService.service';
import { UserTableService } from 'src/app/core/database/user-table.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LearnerPageComponent } from '../learner-page.component';

@Component({
  selector: 'learner-details',
  templateUrl: './learner-details.component.html',
  styleUrls: ['./learner-details.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class LearnerDetailsComponent implements LearnerPageComponent {
  learner: any; // changed for any for now to fix bug
  currentProgramme: any;
  currentEmployment: any;
  plr: any;
  isLarge = true;
	destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  jgw: any;
  today: Date = new Date();
  aprilFirst: Date = new Date("2022-04-01")
  contactInformation: any;

  editPermissionLevel = 2;
  staffId: number;

  learnerDetails: any;
  profileLoaded = false;

  traineeId: string;
  pot: number;

  constructor(
    private userTableService: UserTableService,
    private changeDetectorRef: ChangeDetectorRef,
    private learnerService: LearnerService,
    private snackBar: MatSnackBar
    ) {}

  getData() {
    this.profileLoaded = false;
    this.changeDetectorRef.detectChanges();
    const subscription = this.learnerService.getLearnerDetails(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.learnerDetails = response;
        this.learner = this.learnerDetails.learner;
        this.currentProgramme = this.learnerDetails.currentProgramme;
        this.currentEmployment = this.learnerDetails.currentEmployment;
        this.contactInformation = this.learnerDetails.learner.learnerContactInformation;
        this.plr = this.learnerDetails.learner.previousLearningRecords;
        this.jgw = this.learnerDetails.learner.jgwSignupInfo;
      },
      error: (error) => {
        console.log('Error loading details:', error);
        this.snackBar.open('Error - Could not load learner details.', "Close", {
          duration: 3000,
        });
        this.setEditPermissions();
        this.profileLoaded = true;
        this.changeDetectorRef.detectChanges();
        subscription.unsubscribe();
      },
      complete: () => {
        this.setEditPermissions();
        this.profileLoaded = true;
        this.changeDetectorRef.detectChanges();
        subscription.unsubscribe();
      },
    });
  }


  setEditPermissions() {
    this.userTableService.get(1).then(response => {
      const department = response.department.toLowerCase();
      this.staffId = response.staffId;
      //a number of systems development team are currently marked as operations so adding that in for now
      // not sure what other departments will need edit permissions
      if (department === 'admin' ||
        department === 'systems development' ||
        department === 'operations') {
          this.editPermissionLevel = 1;
      }
      //console.log('user department / Permission level:', department, this.editPermissionLevel);
      this.changeDetectorRef.detectChanges();
    });
  }

  processFormData(event, component) {
    //console.log('event', event)
    switch (component) {
      case 'mainLearnerDetails':
        this.learnerService.updateLearnerSignupDetails({
        traineeId: event.mainLearnerDetails.traineeId,
        pot: event.mainLearnerDetails.pot,
        previousActLearner: event.mainLearnerDetails.values.previousActLearner,
        //values['previousActWork'] = event.mainLearnerDetails.values.previousActWork,
        previousWestTest: event.mainLearnerDetails.values.previousWestTest,
        otherTraining: event.mainLearnerDetails.values.otherTraining,
        emergencyContact: event.mainLearnerDetails.values.emergencyContact,
        relationshipToLearner: event.mainLearnerDetails.values.relationshipToLearner,
        englishFirstLanguage: event.mainLearnerDetails.values.englishFirstLanguage,
        welshSpeaking: event.mainLearnerDetails.values.welshSpeaking,
        westInWelsh: event.mainLearnerDetails.values.westInWelsh,
        preferredLanguageOfLearning: event.mainLearnerDetails.values.preferredLanguageOfLearning,
        westUsername: event.mainLearnerDetails.values.provisionalWestUsername,
        provisionalWestPassword: event.mainLearnerDetails.values.provisionalWestPassword,
        email1: event.mainLearnerDetails.values.email1,
        email2: event.mainLearnerDetails.values.email2,
        phone: event.mainLearnerDetails.values.phone,
        mobile1: event.mainLearnerDetails.values.mobile1,
        mobile2: event.mainLearnerDetails.values.mobile2,
        employerPhone: event.mainLearnerDetails.values.employerPhone,
        additionalInfo: event.mainLearnerDetails.values.additionalInfo,
        laptopLoaned: event.mainLearnerDetails.values.laptopLoaned,
        Staffid: this.staffId.toString(),
        }).subscribe(
          {
            next: (response) => {
              const message = response === 1 ? 'Learner details updated!'
              : `Error - Can not update learner details`;
            this.snackBar.open(message, "Close", {
              duration: 7000,
            });
            },
            error: (error) => {
              console.log('Error updating main learner details:', error);
              this.snackBar.open('Error - Could not update learner details. Please contanct support if this persists', "Close", {
                duration: 3000,
              });
            },
          });
        break;
      case 'westLogin':
        this.learnerService.updateWestLogin({
          traineeId: this.learner.learnerBasics.traineeId,
          pot: this.learner.learnerUniqueIdentification.pot,
          username: event.values.provisionalWestUsername,
          password: event.values.provisionalWestPassword,
        }).subscribe({
          next: () => {
            this.snackBar.open('West login details updated!', "Close", {
              duration: 3000,
            });
          },
          error: (error) => {
            console.log('Error updating west login details:', error);
            this.snackBar.open('Error - Could not update west login details. Please contanct support if this persists', "Close", {
              duration: 3000,
            });
          },
        });
        break;
      case 'glanceDetails':
        this.learnerService.updateGlanceDetails({
          traineeId: this.learner.learnerBasics.traineeId,
          pot: this.learner.learnerUniqueIdentification.pot,
          previousActLearner: event.previousActLearner,
          previousWestTest: event.previousWestTest,
          otherTraining: event.otherTraining,
          englishFirstLanguage: event.englishFirstLanguage,
          westInWelsh: event.westInWelsh,
          laptopLoaned: event.laptopLoaned,
        }).subscribe({
          next: () => {
            this.snackBar.open('Details updated!', "Close", {
              duration: 3000,
            });
          },
          error: (error) => {
            console.log('Error updating glance details:', error);
            this.snackBar.open('Error - Could not update details. Please contanct support if this persists', "Close", {
              duration: 3000,
            });
          },
        });
        break;
      case 'currentProgrammeUpdate':
        this.learnerService.updateLearnerProgrammeSignupDetails({
          traineeId: this.learner.learnerBasics.traineeId,
          pot: this.learner.learnerUniqueIdentification.pot,
          agreedStartDate: event.currentProgrammeUpdate.values.agreedStartDate,
          businessDevelopmentAdvisor: event.currentProgrammeUpdate.values.businessDevelopmentAdvisor,
          businessDevelopmentSource: event.currentProgrammeUpdate.values.businessDevelopmentSource,
          empInc: event.currentProgrammeUpdate.values.empInc,
          empIncDis: event.currentProgrammeUpdate.values.empIncDis,
          nla: event.currentProgrammeUpdate.values.nla,
          Staffid: this.staffId.toString(),
        }).subscribe(a=> {
          //console.log(a);
          const message = a === 0 ? 'Program details updated!'
            : a === -1 ? 'Error - Can not update Program details: Not on Maytas'
              : `Error - Can not update Program details`;
          this.snackBar.open(message, "Close", {
            duration: 7000,
          });
        })
        break;

      case 'currentEmploymentUpdate':
        this.learnerService.updateLearnerEmployerSignupDetails({
          traineeId: this.learner.learnerBasics.traineeId,
          pot: this.learner.learnerUniqueIdentification.pot,
          manager: event.currentEmploymentDetails.values.manager,
          managerphone: event.currentEmploymentDetails.values.managerphone,
          manageremail: event.currentEmploymentDetails.values.manageremail,
          longContract: event.currentEmploymentDetails.values.longcontract,
          wifiAccess: event.currentEmploymentDetails.values.wifiaccess,
          jobTitle: event.currentEmploymentDetails.values.jobtitle,
          lineManager: event.currentEmploymentDetails.values.lineManager,
          lineManagerMobile: event.currentEmploymentDetails.values.lineManagerMobile,
          lineManagerEmail: event.currentEmploymentDetails.values.lineManagerEmail,
          Staffid: this.staffId.toString(),
        }).subscribe(a=> {console.log(a)})
        break;

        case 'jgwUpdate':
          this.learnerService.updateJGWDetails({
            traineeId: this.learner.learnerBasics.traineeId,
            pot: this.learner.learnerUniqueIdentification.pot,
            psmDate: event.jgwDetails.values.PSMDate,
            psmTime: event.jgwDetails.values.PSMTime && event.jgwDetails.values.PSMTime.length == 5 ? event.jgwDetails.values.PSMTime + ':00' : event.jgwDetails.values.PSMTime,
            expStartDate: event.jgwDetails.values.ExpectedStartDate,
            agreedStartDate: event.jgwDetails.values.AgreedStartDate,
            discussion: event.jgwDetails.values.Discussion,
            ttMon: event.jgwDetails.values.TTMonday ? 1 : 0,
            ttMonHours: parseFloat(event.jgwDetails.values.TTMondayHours),
            ttTue: event.jgwDetails.values.TTTuesday ? 1 : 0,
            ttTueHours: parseFloat(event.jgwDetails.values.TTTuesdayHours),
            ttWed: event.jgwDetails.values.TTWednesday ? 1 : 0,
            ttWedHours: parseFloat(event.jgwDetails.values.TTWednesdayHours),
            ttThu: event.jgwDetails.values.TTThursday ? 1 : 0,
            ttThuHours: parseFloat(event.jgwDetails.values.TTThursdayHours),
            ttFri: event.jgwDetails.values.TTFriday ? 1 : 0,
            ttFriHours: parseFloat(event.jgwDetails.values.TTFridayHours),
            psmLocation: event.jgwDetails.values.PSMLocation,
            centre: event.jgwDetails.values.Centre,
            Updateby:  this.staffId,
          }).subscribe(a=> {console.log(a)})
          break;
      }
  }
}
