import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';

@Component({
  selector: 'app-retrospective-occupancy',
  templateUrl: './retrospective-occupancy.component.html',
})
export class RetrospectiveOccupancyComponent {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.RetrospectiveOccupancy;
  reportName = 'Retrospective Occupancy';
  matSortActive = 'fullname';
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: [
        'fullname',
        'scheme',
        'sector',
        'centre',
        'startDate',
        'expCompletionDate',
        'terminationDate',
        'assessor',
        'potStatus',
        'months',
        'progress'
      ]
    }
  ];
  dateFilters = ['activeDuring'];
  tableCellStyle = (column: string, value: any, row: any): object => {
    switch (column) {
      case 'progress':
        switch (row["fwStatus"]) {
          case 3:
            return { 'background-color': '#f5a4a4' };
          case 2:
            return { 'background-color': '#fcb549' };
          default:
            return { 'background-color': '#adffcb' };
        }
      default:
        return {};
    }
  }
}

