import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-previous-learning-record',
  templateUrl: './previous-learning-record.component.html',
  styleUrls: ['./previous-learning-record.component.scss']
})
export class PreviousLearningRecordComponent implements OnInit {

  @Input() plr: any;
  show: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    ) { }

  ngOnInit(): void {
  }

  display(value) {
    this.show = value;
    this.changeDetectorRef.detectChanges();
  }

}
