<form id="attenanceEditForm" [formGroup]="attenanceEditForm">

    <div class="form-container">

        <mat-form-field id="timein">
            <mat-label class="{{ attenanceEditForm.get('Time In').valid ? '' : 'red-text' }}">
              Time In
            </mat-label>
            <input matInput formControlName="Time In"
              class="attendance-form-input {{ attenanceEditForm.get('Time In').valid ? '' : 'red-text' }}"
              type="time"
              (change)="timeInChange()"
            >
        </mat-form-field>

        <mat-form-field id="timeincomments">
            <mat-label class="{{ timeInChanged && !timeInReason ? 'red-text' : '' }}">
              Time In Update Reason
            </mat-label>
            <input matInput formControlName="Time In Comments"
              class="attendance-form-input"
              (change)="timeInReasonChange()"
            >
        </mat-form-field>

        <mat-form-field id="timeout">
            <mat-label class="{{ attenanceEditForm.get('Time Out').valid ? '' : 'red-text' }}">
              Time Out
            </mat-label>
            <input matInput formControlName="Time Out"
              class="attendance-form-input {{ attenanceEditForm.get('Time Out').valid ? '' : 'red-text' }}"
              type="time"
              (change)="timeOutChange()"
            >
          </mat-form-field>

        <mat-form-field id="timeoutcomments">
            <mat-label class="{{ timeOutChanged && !timeOutReason ? 'red-text' : '' }}">
              Time Out Update Reason
            </mat-label>
            <input matInput formControlName="Time Out Comments"
              class="attendance-form-input"
              (change)="timeOutReasonChange()">
        </mat-form-field>

        <mat-dialog-actions style="margin-top: 10px;" class="button-container">
            <button class="mat-raised-button mat-primary submit-button" (click)="confirm()">
              Confirm
            </button>
            <div class="close">
                <button mat-button (click)="close()">Close</button>
            </div>
        </mat-dialog-actions>


    </div>

</form>
