import { Component, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { LearnerService } from '../learnerService.service';
import { NO_DATA, ERROR_GETTING_DATA, DATE_TIME_FORMAT, ERROR_SAVING_DATA, DATE_FORMAT } from 'src/app/app.constants';
import { SharedService } from '../../shared/services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-learner-sign-up',
  templateUrl: './learner-sign-up.component.html',
  styleUrls: ['./learner-sign-up.component.scss']
})
export class LearnerSignUpComponent {

  constructor(
    private fb: FormBuilder,
    private learnerService : LearnerService,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) { }

  traineeId: string;
  pot: number;
  staffId: number;

  noData = NO_DATA;
  dataError = ERROR_GETTING_DATA;
  dateFormat = DATE_FORMAT;
  dateTimeFormat = DATE_TIME_FORMAT;
  savingError = ERROR_SAVING_DATA;
  saving = false;

  loadingData = false;

  westsInWelshOptions: {value: string, label: string}[];
  levelOptions: {value: string, label: string}[];
  BdSourceOptions: {value: string, label: string}[];
  bidOptions: string[];
  incentiveOptions: {value: string, label: string, group: string}[];
  languageOptions: {value: string, label: string}[];

  loadingOptions = true;
  filterError = false;

  learnerSignUpForm = this.fb.group({
    Bdi: [''],
    BdSource: [''],
    AgreedStartDate: new FormControl(null),
    JobTile: [''],
    Level: [''],
    ManagerName: [''],
    ManagerEmail: [''],
    ManagerPhone: [''],
    ProbationCompleteDate: new FormControl(null),
    PlaceOfWork: [''],
    AdditionalInfo: [''],
    PreviousActLearner: [false],
    PreviousWest: [false],
    ExtendedTimeOff: [false],
    AnyOtherTraining: [false],
    LongTermContract: [false],
    WelshWests: new FormControl('NONE'),
    FirstLanguage: [''],
    PreferredLearningLanguage: [''],
    WiFiAtWork: [false],
    EnrolmentLocation: [''],
    ProvisionalWestUsername: [''],
    ProvisionalWestPassword: [''],
    Incentive: [''],
    DisabledIncentive: [false],
    LearningAgreement: [false],
  });

  signUpData: any;
  permissions: number[];
  canEdit = false;

  public getData(): void {
    this.getSignUpData();
    this.getSignUpOptions();
  }

  getSignUpData() {
    this.loadingData = true;
    const subscription = this.learnerService.getSignUpDetailsTab(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.signUpData = response.data.signUp;
        this.permissions = response.data.permissions;
        if(this.permissions.includes(54)) {
          this.canEdit = true;
        }
      },
      error: (error) => {
        console.log('Error loading sign up data:', error.error);
        this.loadingData = false;
        subscription.unsubscribe();
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadingData = false;
        this.setDefaultValues();
        subscription.unsubscribe();
        this.cdr.detectChanges();
      }
    })
  }

  getSignUpOptions() {
    const subscription = this.learnerService.getSignUpTabOptions().subscribe({
      next: (response) => {
        this.setOptions(response.data);
      },
      error: (error) => {
        console.log('Error loading sign up options:', error.error);
        this.loadingOptions = false;
        this.filterError = true;
        subscription.unsubscribe();
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadingOptions = false;
        subscription.unsubscribe();
        this.cdr.detectChanges();
      }
    });
  }

  setOptions(data: any) {
    this.bidOptions = data.bdi;
    this.incentiveOptions = data.incentives.map((incentive) => {
      const textSplits = incentive.appvalueidText.split('(');
      const label = textSplits[0];
      const group = textSplits[1].replace(/\(|\)/g, "");
      return { value: incentive.appvalueid, label: label, group:group }
    });
    this.languageOptions = data.languages;
    this.westsInWelshOptions = data.westsInWelsh.map(w => {
      const label = this.sharedService.uppercaseFirstLetter(w.toLowerCase());
       return { value: w, label: label };
    });

    this.BdSourceOptions = data.bdSource;

    this.levelOptions = data.levels.map(l => {
      return { value: l, label: l.split(' ')[1]};
    });

  }


  setDefaultValues() {
    if(!this.loadingData && this.signUpData !== null) {
      this.learnerSignUpForm.setValue({
        Bdi: this.sharedService.uppercaseWords(this.signUpData.bdi ?? ''),
        BdSource: this.signUpData.bdSource ?? '',
        AgreedStartDate: this.signUpData.agreedStartDate !== null ? new Date(this.signUpData.agreedStartDate) : null,
        JobTile: this.signUpData.jobTitle ?? '',
        Level: this.signUpData.schemeShort ?? '',
        ManagerName: this.signUpData.managerName ?? '',
        ManagerEmail: this.signUpData.managerEmail ?? '',
        ManagerPhone: this.signUpData.managerPhone ?? '',
        ProbationCompleteDate: this.signUpData.probationCompleteDate !== null ? new Date(this.signUpData.probationCompleteDate) : null,
        PlaceOfWork: this.signUpData.placeOfWork ?? '',
        AdditionalInfo: this.signUpData.additionalInfo ?? '',
        PreviousActLearner: this.signUpData.previousActLearner === '-1',
        PreviousWest: this.signUpData.previousWest === '-1',
        ExtendedTimeOff: this.signUpData.extendedWorkPattern === '-1',
        AnyOtherTraining: this.signUpData.previousTraining === '-1',
        LongTermContract: this.signUpData.longContract === '-1',
        WelshWests: this.signUpData.westQualInWelsh ?? 'NONE',
        FirstLanguage: this.sharedService.uppercaseWords(this.signUpData.firstLanguage ?? ''),
        PreferredLearningLanguage: this.sharedService.uppercaseWords(this.signUpData.preferredLanguage  ?? ''),
        WiFiAtWork: this.signUpData.internetAvailableAtWork === '-1',
        EnrolmentLocation: this.signUpData.enrolmentLocation ?? '',
        ProvisionalWestUsername: this.signUpData.westuserName ?? '',
        ProvisionalWestPassword: this.signUpData.westInitialPassword ?? '',
        Incentive: this.signUpData.incentive ?? '',
        DisabledIncentive: this.signUpData.disabledIncentive === 7,
        LearningAgreement: this.signUpData.noLearningAgreement === 0 || this.signUpData.noLearningAgreement === null,
      });
    }
  }

  onSubmit() {
    if(this.saving) {
      return;
    }

    if(!this.canEdit) {
      this.snackBar.open('You do not have permission to edit this data', 'close');
      return;
    }

    this.saving = true;
    const formValue = this.learnerSignUpForm.value;

    const data = {
      signUpId: this.signUpData.signUpId,
      bdi: this.setEmptyToNull(formValue.Bdi),
      bdSource: this.setEmptyToNull(formValue.BdSource),
      agreedStartDate: formValue.AgreedStartDate !== null ? this.setEmptyToNull(formValue.AgreedStartDate.toJSON()) : null,
      jobTitle: this.setEmptyToNull(formValue.JobTile),
      schemeShort: this.setEmptyToNull(formValue.Level),
      managerName: this.setEmptyToNull(formValue.ManagerName),
      managerEmail: this.setEmptyToNull(formValue.ManagerEmail),
      managerPhone: this.setEmptyToNull(formValue.ManagerPhone),
      probationCompleteDate: formValue.ProbationCompleteDate !== null ? this.setEmptyToNull(formValue.ProbationCompleteDate.toJSON()): null,
      placeOfWork: this.setEmptyToNull(formValue.PlaceOfWork),
      additionalInfo: this.setEmptyToNull(formValue.AdditionalInfo),
      previousActLearner: formValue.PreviousActLearner ? '-1' : '0',
      previousWest: formValue.PreviousWest ? '-1' : '0',
      extendedWorkPattern: formValue.ExtendedTimeOff ? '-1' : '0',
      previousTraining: formValue.AnyOtherTraining ? '-1' : '0',
      longContract: formValue.LongTermContract ? '-1' : '0',
      westQualInWelsh: formValue.WelshWests === 'NONE' ? null : formValue.WelshWests,
      firstLanguage: this.setEmptyToNull(formValue.FirstLanguage),
      preferredLanguage: this.setEmptyToNull(formValue.PreferredLearningLanguage),
      internetAvailableAtWork: formValue.WiFiAtWork ? '-1' : '0',
      enrolmentLocation: this.setEmptyToNull(formValue.EnrolmentLocation),
      westuserName: this.setEmptyToNull(formValue.ProvisionalWestUsername),
      westInitialPassword: this.setEmptyToNull(formValue.ProvisionalWestPassword),
      incentive: typeof formValue.Incentive === 'string' ? null : formValue.Incentive,
      disabledIncentive: formValue.DisabledIncentive ? 7 : null,
      noLearningAgreement: formValue.LearningAgreement ? 0 : -1,
    };

    const subscription = this.learnerService.updateSignUpDetailsTab(data).subscribe({
      next: (response) => {
        this.signUpData.updateTime = response;
        this.snackBar.open('Sign up details updated successfully', '', { duration: 2000 });
      },
      error: (error) => {
        console.log('Error updating sign up data:', error);
        const errorMessage = error === 'You do not have permission to complete the requested action.' ? error : this.savingError;
        this.saving = false;
        this.snackBar.open(errorMessage, 'close');
        this.cdr.detectChanges();
        subscription.unsubscribe();
      },
      complete: () => {
        this.learnerSignUpForm.markAsPristine()
        this.saving = false;
        this.cdr.detectChanges();
        subscription.unsubscribe();
      }
    });
  }


  private setEmptyToNull(value: string) {
    return  value.trim() === '' ? null : value;
  }

  public resetForm() {
    this.learnerSignUpForm.reset();
    this.setDefaultValues();
  }

  public clear(formControlName: string) {
    this.learnerSignUpForm.get(formControlName).reset();
    this.learnerSignUpForm.get(formControlName).markAsDirty();
  }

  public getLevelView() :string {
    return this.levelOptions?.filter(l => l.value === this.learnerSignUpForm.value.Level)[0]?.label;
  }

  public getIncentiveView() :string {
    return this.incentiveOptions?.filter(i => i.value === this.learnerSignUpForm.value.Incentive)[0]?.label;
  }

}
