import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-your-reports',
  templateUrl: './your-reports.component.html',
  styleUrls: ['./your-reports.component.scss']
})
export class YourReportsComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @Input() favourites: any;

  constructor() { }

  ngOnInit(): void {
  }

}
