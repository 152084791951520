import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";

@Component({
  selector: 'app-workshop-available-report',
  templateUrl: './workshop-available-report.component.html'
})
export class WorkshopAvailableReportComponent {

  reportVariant = ReportVariant.AvailableWorkshops;
  reportTitle = 'Available Workshops';
  matSortActive = 'startTime';
  matSortDirection = 'desc';
  activeLearnerToggle = false;
  hasClassicFilters = false;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['workshopName', 'workshopType', 'level', 'startTime', 'endTime', 'location', 'capacity', 'spacesRemaining']
    }
  ];
  dateFilters = ['startTime', 'endTime'];
}
