<mat-card class="contract-management-card">
  <div class="contract-management-container">
    <h2 class="dashboard-titles" style="padding-left:0px;">Contract Management Reports</h2>

    <div *ngIf="pdfResult" style="display: flex;align-items: center;justify-content: center;">
      <iframe width="500" height="300" src="pdfResult" type="application/pdf"></iframe>
    </div>

    <mat-spinner *ngIf="this.waitingForData"
      style="position: absolute; left: calc(50% - 50px); top: calc(50% - 50px);"></mat-spinner>

    <div
      style="border: 1px solid #d0d0d0; border-radius: 4px; margin: 20px; padding: 10px; display: flex; flex-direction: column; gap: 10px">
      <div>
        <mat-label style="font-weight: 500;">Provider</mat-label>
        <mat-select #subcontractorSelect>
          <mat-option *ngFor="let sub of subcontractors"
            [value]="sub.subcontractorId">{{sub.subcontractor}}</mat-option>
        </mat-select>
      </div>

      <div>
        <mat-label style="font-weight: 500;">Contract Year</mat-label>
        <mat-select #contractSelect>
          <mat-option *ngFor="let year of contractYearIds"
            [value]="year.contractYearId">{{year.contractYear}}</mat-option>
        </mat-select>
      </div>
    </div>

    <button mat-flat-button color="primary" class="favourites-button" (click)="getRemittanceReport()"
      [disabled]="!subcontractorSelect?.value || !contractSelect?.value || loading">
      <span style="display: flex; gap: 5px;">
        Run Remittance Report
        <mat-spinner *ngIf="this.loading" [diameter]="20" style="align-self: center;">
        </mat-spinner>
      </span>
    </button>

  </div>
</mat-card>