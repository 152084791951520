<label class="body-text-title" style="font-weight: 550;" for="type">{{type}}:</label>
<div *ngIf="labelOneValue != null">
  <label class="body-text" *ngIf="labelOneName != null && labelOneName != undefined"  for="name">{{labelOneName}}: </label>
  <span class="body-text" style="padding-top: 3px;" *ngIf="labelOneValue != null && labelOneValue != undefined">{{labelOneValue}}</span>
  <span class="body-text" style="padding-top: 3px;" *ngIf="labelOneValue == null && labelOneValue == undefined">-</span>

</div>

<div *ngIf="labelTwoValue != null">
  <label class="body-text" *ngIf="labelTwoName != null && labelTwoName != undefined"  for="name">{{labelTwoName}}: </label>
  <span class="body-text" style="padding-top: 3px;" *ngIf="labelTwoValue != null && labelTwoValue != undefined">{{labelTwoValue}}</span>
  <span class="body-text" style="padding-top: 3px;" *ngIf="labelTwoValue == null && labelTwoValue == undefined">-</span>
</div>

<div *ngIf="labelThreeValue != null">
  <label class="body-text" *ngIf="labelThreeName != null && labelThreeName != undefined"  for="name">{{labelThreeName}}: </label>
  <span class="body-text" style="padding-top: 3px;" *ngIf="labelThreeValue != null && labelThreeValue != undefined">{{labelThreeValue}}</span>
  <span class="body-text" style="padding-top: 3px;" *ngIf="labelThreeValue == null && labelThreeValue == undefined">-</span>
</div>
