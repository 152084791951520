import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit, Input } from '@angular/core';
import { SendMessageFormComponent } from './../../../core/common-forms/send-message-form/send-message-form.component';

@Component({
  selector: 'learner-details-send-message-entry',
  templateUrl: './learner-details-send-message-entry.component.html',
  styleUrls: ['./learner-details-send-message-entry.component.css']
})
export class LearnerDetailsSendMessageEntryComponent implements OnInit {
  @Input() type;
  @Input() recipientName;
  @Input() recipientEmail;
  @Input() senderName;
  @Input() senderEmail;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openSendMessageDialog() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      recipientName: this.recipientName,
      recipientEmail: this.recipientEmail,
      senderName: this.senderName,
      senderEmail: this.senderEmail
    }

    this.dialog.open(SendMessageFormComponent, dialogConfig);
    return false;
  }
}
