import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StringHelper } from "./string-helper";

@Injectable({
  providedIn: 'root'
})
export class ApiHelper {
  constructor(private http: HttpClient, public stringHelper: StringHelper) {

  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    const options = { headers: headers };
    return options;
  }

  getWithAccessToken(url: string, params?: HttpParams) {
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, { ...options, params });
  }

  getFileWithAccessToken(url: string) {
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, { responseType: 'blob', headers: options.headers });
  }

  putWithAccessToken(url: string, payload: any) {
    const options = this.getOptionsWithAccessToken();
    return this.http.put(url, payload, options);
  }

  postWithAccessToken(url: string, payload: any) {
    const options = this.getOptionsWithAccessToken();
    return this.http.post(url, payload, options);
  }

  deleteWithAccessToken(url: string, params?: HttpParams) {
    const options = this.getOptionsWithAccessToken();
    return this.http.delete(url, { ...options, params });
  }

  postFileWithAccessToken(url: string, payload: any) {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token);
    const options = { headers: headers };
    return this.http.post(url, payload, { ...options });
  }
}
