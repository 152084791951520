<div class="west-container">

  <div class="west-table-container">
    <table *ngIf="westsDataSource" mat-table [dataSource]="westsDataSource">

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element">
                <a class="{{ element.westDetails !== null ? 'view-drill-down icon--click-hover' : ''}}" (click)="openDialog(element)">
                    {{element.title}}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Last Update </th>
            <td mat-cell *matCellDef="let element"> {{ element.date !== '-' ? (element.date | date: this.dateFormat) : '-'}} </td>
        </ng-container>


        <ng-container matColumnDef="initial">
          <th mat-header-cell *matHeaderCellDef> Initial </th>
          <td mat-cell *matCellDef="let element"> {{ element.initial.toUpperCase() }}</td>
        </ng-container>

        <ng-container matColumnDef="current">
            <th mat-header-cell *matHeaderCellDef> Current </th>
            <td mat-cell *matCellDef="let element"> {{element.current.toUpperCase() }} </td>
        </ng-container>

        <ng-container matColumnDef="target">
            <th mat-header-cell *matHeaderCellDef> Target </th>
            <td mat-cell *matCellDef="let element"> {{element.target}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="westsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: westsDisplayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <ng-container *ngIf="this.loadedWestData">
            <td class="mat-cell" colspan="5" style="text-align: center;">
              {{ this.noData }}
            </td>
          </ng-container>
        </tr>

    </table>
    <mat-progress-bar *ngIf="!this.loadedWestData" mode="indeterminate"></mat-progress-bar>
  </div>

  <!-- Removing for now as I dont think it is useful, leaving it here for now to see if people complain. 08/08/23 -->
  <!-- <div class="button-container"> -->
    <!-- Currently this is not functinal at all - Is it even needed? -->
    <!-- <button mat-flat-button color="primary" class="create-button" (click)="share()">
        <mat-icon>share</mat-icon>Share
    </button> -->
    <!-- <ng-select class="export-dropdown" [(ngModel)]="exportAs" [items]="['csv','xls','xml']" placeholder="Export as"></ng-select> -->

    <!-- <button mat-flat-button [matMenuTriggerFor]="saveMenu" class="reportOptionButton export-as-button">
        Export as... <span class="export-as-button-arrow"><img src="assets/arrows/down-arrow.svg" width="15px"
                alt="export as" class="filter-blue"></span>
    </button>
    <mat-menu #saveMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="export(westsDataSource.data, 'csv')">.csv</button>
        <button mat-menu-item (click)="export(westsDataSource.data, 'xlsx')">.xlsx</button>
    </mat-menu>
  </div> -->

</div>
