<mat-card style="height: fit-content;">
  <h2 class="dashboard-titles">Data Extract</h2>
  <form [formGroup]="form">
    <mat-form-field class="group-select" appearance="outline" style="width: 100%;">
      <mat-label>Report</mat-label>
      <mat-select #reportSelected formControlName="reportSelect">
        <mat-option *ngFor="let select of reportSelect" [value]="select.value">{{select.text}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div style="display:flex; justify-content: space-between;">
      <mat-form-field class="group-select" appearance="outline" style="width: 49%;">
        <mat-label>Date Filter</mat-label>
        <mat-select #dateFilterSelected formControlName="dateFilterSelect">
          <mat-option *ngFor="let select of dateFilterSelect" [value]="select.value">{{select.text}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="group-select" appearance="outline" style="width: 49%;">
        <mat-label>Date Range</mat-label>
        <input formControlName="dateRange" matInput type="text" #dateRange ngxDaterangepickerMd
          placeholder="Select Date Range" [showClearButton]="true" [readonly]="false"
          [locale]="{applyLabel: 'Apply',clearLabel: 'Clear',format: 'DD/MM/YYYY'}" startKey="startDate"
          endKey="endDate" autocomplete="off" [alwaysShowCalendars]="true" [showDropdowns]="true" opens="up"
          drops="right" [ranges]="ranges">
      </mat-form-field>
    </div>
    <mat-form-field class="group-select" appearance="outline" style="width: 100%;">
      <mat-label>Age Category</mat-label>
      <mat-select #ageCategorySelected formControlName="ageCategorySelect">
        <mat-option *ngFor="let select of ageCategorySelect" [value]="select.value">{{select.text}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="group-select" appearance="outline" style="width: 100%;">
      <mat-label>Contract</mat-label>
      <mat-select #contractSelected formControlName="contractSelect">
        <mat-option *ngFor="let select of contractSelect" [value]="select.value">{{select.text}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="group-select" appearance="outline" style="width: 100%;">
      <mat-label>Contract Year</mat-label>
      <mat-select #contractYearSelected formControlName="contractYearSelect">
        <mat-option *ngFor="let select of contractYearSelect" [value]="select.value">{{select.text}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button [disabled]="!form.valid" mat-stroked-button class="save-export-buttons" [matMenuTriggerFor]="saveMenu">
        Export As... <mat-icon class="export-arrow">expand_more</mat-icon>
      </button>
      <mat-menu #saveMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="submit('csv')">.csv</button>
        <button mat-menu-item (click)="submit('xlsx')">.xlsx</button>
      </mat-menu>
      <button style="margin-left: 5%;" mat-stroked-button color="warn" (click)="clear()" [disabled]="form.pristine">
        Clear
      </button>
    </div>
  </form>
</mat-card>