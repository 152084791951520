<div>

  <div>
    <h1 mat-dialog-title>
      Select Learners for Event
    </h1>
  </div>

  <mat-dialog-content style="margin: 40px 0;">
    <form [formGroup]="eventForm">

    <div class="controls">
      <mat-slide-toggle [checked]="onlyMyLearners" (change)="toggleOnlyMyLearners($event)">
        Only Mine
      </mat-slide-toggle>
      <mat-slide-toggle [checked]="onlyActiveLearners" (change)="toggleOnlyActiveLearners($event)">
        Only Active
      </mat-slide-toggle>
      <mat-slide-toggle [checked]="onlyFavoriteLearners" (change)="toggleOnlyFavoriteLearners($event)">
        Only Bookmarked
      </mat-slide-toggle>
    </div>

      <mat-divider style="margin:20px auto; width: 60%"></mat-divider>

      <mat-form-field class="learnerField">
        <mat-label>Select Learner/s</mat-label>
        <mat-select formControlName="learners" [required]="true" [disableOptionCentering]="true" panelClass="matSelectPanelBelow" (openedChange)="this.dropdownOpened()" multiple>
          <mat-select-trigger class="selected-learner-container">
            <div *ngFor="let learner of eventForm.get('learners').value; let i = index" class="selected-learner">
              {{ this.notNullOrEmpty(learner.fullName) ? learner.fullName : '-' }} <span style="font-size: 12px; font-style: italic;">(POT:{{learner.pot}} - {{learner.potStatus}})</span>
            </div>
          </mat-select-trigger>
          <div style="padding: 10px 0">
            <!-- Search -->
            <div style="display: flex;">
              <input #searchInput type="text" class="learner-search" [(ngModel)]="this.searchFilter" [ngModelOptions]="{standalone: true}" placeholder="Search..."/>
            </div>
            <mat-divider style="margin-bottom: 8px;"></mat-divider>

            <!-- learners -->
            <div *ngIf="!this.noOptions; else noLearners">
              <cdk-virtual-scroll-viewport itemSize="32" minBufferPx="400" maxBufferPx="600" class="virtual-scroll" #viewport>
                <!-- Selected Options -->
                <div *ngIf="eventForm.get('learners').value.length > 0">
                  <mat-option *ngFor="let learner of eventForm.get('learners').value" [value]="learner" style="height: 2em;">
                    {{ this.notNullOrEmpty(learner.fullName) ? learner.fullName : '-' }} <span style="font-size: 12px; font-style: italic;">(POT:{{learner.pot}} - {{learner.potStatus}})</span>
                  </mat-option>
                  <mat-divider style="margin-bottom: 8px;"></mat-divider>
                </div>

                <!-- All other options hidden if selected-->
                <mat-option *cdkVirtualFor="let learner of this.filteredLearners; templateCacheSize: 0" [value]="learner" [style.display]="this.showOption(learner)" style="height: 2em;">
                  <div *ngIf="learner; else loading;">
                    {{ this.notNullOrEmpty(learner.fullName) ? learner.fullName : '-' }} <span *ngIf="!this.noOptions" style="font-size: 12px; font-style: italic;">(POT:{{learner.pot}} - {{learner.potStatus}})</span>
                  </div>
                  <ng-template #loading>
                    Loading...
                  </ng-template>
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </div>

            <!-- No learners found -->
            <ng-template #noLearners>
              <div *ngIf="eventForm.get('learners').value.length > 0">
                <mat-option *ngFor="let learner of eventForm.get('learners').value" [value]="learner" style="height: 2em;">
                  {{ this.notNullOrEmpty(learner.fullName) ? learner.fullName : '-' }} <span style="font-size: 12px; font-style: italic;">(POT:{{learner.pot}} - {{learner.potStatus}})</span>
                </mat-option>
                <mat-divider style="margin-bottom: 8px;"></mat-divider>
              </div>
              <div class="options-text no-results">
                No learners found
                <!-- This is because there has to be at least one option for the dropdown to show -->
                <mat-option style="display: none;"></mat-option>
              </div>
            </ng-template>

            <mat-divider style="margin-top: 8px;"></mat-divider>
          </div>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: end">
    <button mat-flat-button id="close-button" (click)="this.dialogRef.close()">
      Cancel
    </button>
    <button mat-flat-button color="primary" (click)="this.submitForm()" [disabled]="this.eventForm.invalid">
      Next
    </button>
  </mat-dialog-actions>

</div>
