import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewOnlyFieldComponent } from './components/view-only-field/view-only-field.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MaterialsModule } from '../core/materials/materials.module';
import { UnCamelCasePipe } from '../core/pipes/un-camel-case';
import { EventItemComponent } from './components/event-item/event-item.component';
import { TruncatePipe } from '../core/pipes/truncate.pipe';
import { EventsPageComponent } from './components/events-page/events-page.component';
import { NgSelectOverrideComponent } from './components/ng-select-override/ng-select-override.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ViewOnlyFieldComponent,
    NavbarComponent,
    EventItemComponent,
    EventsPageComponent,
    NgSelectOverrideComponent,
    UnCamelCasePipe,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    NgSelectModule,
    FormsModule,
  ],
  exports: [
    ViewOnlyFieldComponent,
    EventItemComponent,
    NavbarComponent,
    EventsPageComponent,
    NgSelectOverrideComponent,
    UnCamelCasePipe,
    TruncatePipe,
  ]
})
export class SharedModule { }
