import { Component, Input } from '@angular/core';
import { DATE_FORMAT } from 'src/app/app.constants';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { StringHelper } from 'src/app/shared/string-helper';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employer-card',
  templateUrl: './employer-card.component.html',
  styleUrls: ['./employer-card.component.scss']
})

export class EmployerCardComponent {

  constructor(
    private router: Router,
    private sharedService: SharedService,
    public stringHelper: StringHelper,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.registerIcon();
   }

  public dateFormat = DATE_FORMAT;

  @Input() employer: {
    branchId: number,
    employerId: number,
    employerName: string,
    subcontractorId: number,
    subcontractor: string,
    branchName: string,
    address1: string,
    address2: string,
    address3: string,
    postTown: string,
    postcode: string,
    address: string,
    accountManagerName: string,
    accountManagerId: number,
    active: number,
    typeId: number,
    type: string,
  };

  public activeColour(): { color: string, backgroundColor: string, border: string } {
    switch (this.employer.active) {
      case 1:
        return { color: '#108c27', backgroundColor: '#ddffe4', border: '1px #108c27 solid' };
      case 0:
        return { color: '#ff0000', backgroundColor: '#ffd6d6', border: '1px #ff0000 solid' };
      default:
        return { color: '#4e4e4e', backgroundColor: '#c5c5c5', border: '1px #4e4e4e solid' };
    }
  }

  public cardTooltipText(): string {
    let text = "";
    let address = this.employer.address1 !== null ? `${this.employer.address1}` : '';
    address += this.employer.address2 !== null ? `, ${this.employer.address2}` : '';
    address += this.employer.address3 !== null ? `, ${this.employer.address3}` : '';
    address += this.employer.postTown !== null ? `, ${this.employer.postTown}` : '';
    address += this.employer.postcode !== null ? `, ${this.employer.postcode}` : '';
    text += address !== '' ? `Address: ${address}` : '';
    return text;
  }

  public routeToEmployer() {
    this.router.navigate(
      [`/employer/overview/${this.employer.branchId}`],
      this.sharedService.getBackRouteState()
    );
  }

  registerIcon() {
    this.matIconRegistry.addSvgIcon(
      'employer',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.websiteURL}/assets/employer/office.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'accountManager',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.websiteURL}/assets/employer/person.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'status',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.websiteURL}/assets/employer/pulse.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      'type',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.websiteURL}/assets/employer/briefcase.svg`)
    );
  }

}
