import { Injectable } from '@angular/core';
import moment from 'moment';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }

  downloadFile(data: any[], filename = 'data', fileType = 'csv', apendDate = true, includeHeader = true): string {
    const replacer = (key, value) => {

      if (value == null) {
        return ''
      }
      else {
        let stringValue: string = String(value)
        var re = new RegExp("T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]");
        if (re.exec(stringValue) != null) {
          var tempDate = moment(value).format('DD/MM/YYYY');
          return tempDate;
        }
        else return value;
      }

    }// specify how you want to handle null values here


    const header = Object.keys(data[0]);

    switch (fileType) {

      case 'csv':
        let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        if (includeHeader) csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');

        var a = document.createElement('a');
        var blob = new Blob([csvArray], { type: 'text/csv' }),
          url = window.URL.createObjectURL(blob);

        a.href = url;
        var today = moment().format('YYYY-MM-DD')
        filename = apendDate ? `${filename}-${today}.csv` : `${filename}.csv`;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        break;

      case 'txt':
        // For text export we need to remove redundant columns for each row, resulting in a jagged array where each row can consist of any number of items
        let arrayOfObjects = data.reduce(function (result, item) {
          let o = Object.keys(item)
            .filter((k) => item[k] != '')
            .reduce((a, k) => ({ ...a, [k]: item[k] }), {});
          if (o != '') {
            result.push(o);
          }
          return result;
        }, []);

        // For each row, convert object (which can each exist of any number of values) to a single comma-separated string
        let txt = arrayOfObjects.map(rowObj => {
          let row = '';
          for (const [key, value] of Object.entries(rowObj)) {
            row += value + ',';
          }
          return row.slice(0, -1);
        });

        if (includeHeader) csv.unshift(header.join(','));
        let txtArray = txt.join('\r\n');

        var a = document.createElement('a');
        var blob = new Blob([txtArray], { type: 'text/text' }),
          url = window.URL.createObjectURL(blob);

        a.href = url;
        var today = moment().format('YYYY-MM-DD')
        filename = apendDate ? `${filename}-${today}.txt` : `${filename}.txt`;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        break;

      case 'xlsx':
        const fixedDateData = data.map(row => {
          for (var fieldName in row) {
            row[fieldName] = replacer(fieldName, row[fieldName])
          }
          return row;
        });

        const excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(fixedDateData);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataToExport: Blob = new Blob([excelBuffer], { type: excelType });
        const fileName = apendDate ? filename + '-' + moment().format('YYYY-MM-DD') : filename;
        filename += '.xlsx';
        fileSaver.saveAs(dataToExport, fileName);
        break;
    }

    return filename;
  }
}
