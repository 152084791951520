import { Component, Input } from '@angular/core';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/app/app.constants';
import { EventDisplayType } from '../../enums/event-display-type';

@Component({
  selector: 'event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss']
})
export class EventItemComponent {

  @Input() event: any;
  @Input() highlight: false;
  @Input() textExpand: false;
  @Input() eventDisplayType: EventDisplayType = EventDisplayType.learner;


  eventDisplayTypeEnum = EventDisplayType;

  dateFormat = DATE_FORMAT;
  dateTimeFormat = DATE_TIME_FORMAT;

}
