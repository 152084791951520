<div class="iqa-container">

  <div class="table-container">

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">
          Title
        </th>
        <td mat-cell *matCellDef="let element">
          <a class="export-all icon--click-hover" style="text-align: left; width: 100%; line-height: 26px;" (click)="openDialog(element)">
            {{element.title}}
          </a>
        </td>
      </ng-container>

      <!-- Date planned Column -->
      <ng-container matColumnDef="datePlanned">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date planned">
          Date Planned
        </th>
        <td mat-cell *matCellDef="let element"> {{element.datePlanned | date: this.dateFormat}} </td>
      </ng-container>

      <!-- IQA type Column -->
      <ng-container matColumnDef="iqaType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by iqa type">
          IQA Type
        </th>
        <td mat-cell *matCellDef="let element"> {{element.iqaType}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
            Status
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <span [ngClass]="element.status.class" class="status-text"> {{element.status.status}} </span>
          <span *ngIf="element.dateSignedOff !== null && element.certReceived === null" class="claimed-stamp"
            matTooltip="Claimed on {{element.dateSignedOff | date}} by {{element.staffNameSignedOff}}"
            matTooltipPosition="above" style="cursor: default;"
          >
            Claim Requested
          </span>
          <span *ngIf="element.certReceived !== null" class="cert-received-stamp"
            matTooltip="{{element.certReceived | date}}"
            matTooltipPosition="above" style="cursor: default;"
          >
            Certificate Received
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="this.iqaLoaded" class="mat-cell" colspan="4" style="text-align: center;">
          {{
              !this.IqaRecordsSuccess
            ? 'There was an issue getting IQA records. Please reload or contant support if it contunues.'
            : this.noData
          }}
        </td>
      </tr>
    </table>
    <mat-progress-bar mode="indeterminate" *ngIf="!this.iqaLoaded"></mat-progress-bar>
      <!-- <mat-paginator  (page)="pageEvent = pageChange($event)" [pageSizeOptions]="[25, 5, 10, 100]" aria-label="Select page of IQA records"></mat-paginator> -->

      <div class="button-container">
        <button *ngIf="this.permissions?.canCreateRecord" mat-flat-button color="primary" class="create-button" (click)="openDialog()">Add New Record</button>
        <!-- I dont think this is useful - Disabling it for now and see if someone complains. RB 03/08/2023 -->
        <!-- <button mat-flat-button [matMenuTriggerFor]="saveMenu" class="reportOptionButton export-as-button">
          Export as... <span class="export-as-button-arrow"><img src="assets/arrows/down-arrow.svg" width="15px" alt="export as" class="filter-blue"></span>
        </button> -->
        <!-- <mat-menu #saveMenu="matMenu" yPosition="below">
          <button mat-menu-item (click) = "export(dataSource.data, 'csv')">.csv</button>
          <button mat-menu-item (click) = "export(dataSource.data, 'xlsx')">.xlsx</button>
        </mat-menu> -->
      </div>
  </div>




  <!-- Requested IQA -->
  <div class="table-container">
    <h3 class="header">Requests</h3>
    <table mat-table [dataSource]="requestsDataSource">
      <ng-container [matColumnDef]="column" *ngFor="let column of requestsDisplayedColumns">
        <th mat-header-cell *matHeaderCellDef>
          {{ this.unCamelCase(column) }}
        </th>
        <td mat-cell *matCellDef="let cell">
          <div *ngIf="column === 'actions'; else mainCell" style="display: flex; gap: 8px; justify-content: center;">
            <span *ngIf="this.permissions?.canCreateRecord" class="action-button create" (click)="createRecordFromRequest(cell)">Create</span>
            <span  class="action-button delete" (click)="removeRequest(cell)">Delete</span>
          </div>
          <ng-template #mainCell>
            <td mat-cell *matCellDef="let cell"></td>
            {{
              column === 'priority' ?  cell[column] === 'Y' ? 'Yes' : 'No'
              : column === 'requestDate' ? (cell[column] | date: this.dateFormat) : cell[column]
            }}
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="requestsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: requestsDisplayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="this.iqaLoaded" class="mat-cell" colspan="6" style="text-align: center;">
          {{
              !this.requestsSuccess
            ? 'There was an issue getting requests. Please reload or contant support if it contunues.'
            : this.noData
          }}
        </td>
      </tr>
    </table>
    <mat-progress-bar mode="indeterminate" *ngIf="!this.iqaLoaded"></mat-progress-bar>
    <div class="button-container">
      <button mat-flat-button color="secondary" class="submit-iqa-button" (click)="submitForIQAClick()">
        Submit for IQA
      </button>
    </div>
  </div>


</div>
