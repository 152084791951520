<h3 mat-dialog-title *ngIf="count==1">Authorise Absence for: {{name}}</h3>
<h3 mat-dialog-title *ngIf="count>1">Authorise Absence For Multiple Learners</h3>
<mat-dialog-content>
  <form [formGroup]="authoriseAbsence" class="authorise-absence-dialog">
    <mat-form-field appearance="outline">
      <mat-label>Date Range</mat-label>
      <input formControlName="dateRange" matInput type="text" #dateRange ngxDaterangepickerMd
        placeholder="Select Date Range" [showClearButton]="true" [readonly]="true"
        [locale]="{applyLabel: 'Apply',clearLabel: 'Clear',format: 'DD/MM/YYYY'}" startKey="startDate" endKey="endDate"
        autocomplete="off" startKey="startDate" [alwaysShowCalendars]=true endKey="endDate" [showDropdowns]="true"
        opens="right" drops="down">
      <mat-hint *ngIf="dateRangeFC.warnings?.overWeekend">
        Date range contains dates that fall on a weekend. These dates will be
        skipped.
      </mat-hint>
      <mat-error *ngIf="dateRangeFC.errors?.onlyWeekend">
        Date range contains dates that only fall on a weekend. Please select a valid date range.
      </mat-error>
      <mat-error *ngIf="dateRangeFC.errors?.exceedsMaxDays">
        The date range selected exceeds the limit of {{dayLimit}} days.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Start Time</mat-label>
      <input matInput type="time" formControlName="timeIn" (change)="timeInStringOnChange($event)">
      <mat-error *ngIf="timeInFC.errors?.timeIsAfterOtherInput">
        The time entered is after the time out.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>End Time</mat-label>
      <input matInput type="time" formControlName="timeOut" (change)="timeOutStringOnChange($event)">
      <mat-error *ngIf="timeOutFC.errors?.timeIsBeforeOtherInput">
        The time entered is before the time in.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Comment</mat-label>
      <textarea formControlName="comment" matInput rows="10"></textarea>
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-raised-button mat-dialog-close (click)="onClose()">Cancel</button>
  <button mat-button mat-raised-button color="primary" type="submit" [disabled]="!authoriseAbsence.valid"
    (click)="onSave(authoriseAbsence)">Authorise</button>
</mat-dialog-actions>
