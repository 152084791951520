import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { UserTableService } from '../core/database/user-table.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
    selector: 'app-force-log-in',
    template: '<span></span>',
})
export class LoginComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private msalService: MsalService,
        private router: Router
    ) { }

    ngOnInit() {
        //this.msalService.loginRedirect();
        var nav = this.router.getCurrentNavigation();
        console.dir(nav);
        this.authService.login();
    }

}