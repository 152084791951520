<basic-report
[report]="this.reportVariant"
[reportName]="'GCH Discrepancy Report'"
[defaultDisplayColumns]="this.defaultDisplayColumns"
[dateFilterTypes]="this.dateFilters"
[groupByColumns]="this.groupByColumns"
[getSummaryData]="this.getSummaryData"
[activeLearnerToggle]="false"
>
</basic-report>
