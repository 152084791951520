import { Injectable } from "@angular/core";
import { ApiHelper } from "src/app/shared/api-helper";
import { environment } from "src/environments/environment";

export interface AttendanceApprovalPayload {
  signId: number;
  timeIn: string;
  commentsTimeIn: string;
  timeOut: string;
  commentsTimeOut: string;
  staffId: number;
}
export interface TimeEdit {
  signId: number;
  timeIn: string;
  timeOut: string;
  commentsTimeIn: string;
  commentsTimeOut: string;
}
@Injectable({
  providedIn: 'root'
})
export class AttendanceApprovalService extends ApiHelper {
  getDetails(staffId: number, pageNumber: number, pageSize: number, name: string = null, onlyMyLearners: boolean, sortColumn: string = null, sortColumnDirection: string = null) {
    let url = `${environment.API_URL}V2/Attendance/AbsenceToApprove/Details?staffId=${staffId}&pageNumber=${pageNumber}&perPage=${pageSize}`;
    url += `&filterString=onlyMyLearners::${onlyMyLearners}`
    if (name) {
      url += `,name::${name}`;
    }
    if ((sortColumnDirection !== "")) {
      url += `&sortString=${sortColumn}::${sortColumnDirection}`;
    }
    return this.getWithAccessToken(url);
  }
  approveDetails(attendanceApproval: AttendanceApprovalPayload[]) {
    return this.postWithAccessToken(`${environment.API_URL}V2/Attendance/AbsenceToApprove/Approve`, attendanceApproval);
  }
  rejectDetails(attendanceRejection: AttendanceApprovalPayload[]) {
    return this.postWithAccessToken(`${environment.API_URL}V2/Attendance/AbsenceToApprove/Reject`, attendanceRejection);
  }
  editTimeDetails(modification: TimeEdit) {
    return this.postWithAccessToken(`${environment.API_URL}V2/Attendance/AbsenceToApprove/UpdateTime`, modification);
  }
}
