<article>
  <mat-sidenav-container>

    <!-- Oprions & Filters -->
    <mat-sidenav #sideNav [fixedInViewport]="true" [fixedTopGap]="this.topGap" mode="side" opened style="border-right: 0;">
      <article class="side-nav-container">
        <button *ngIf="sideNav.opened" mat-button class="side-nav-buttons" style="position: absolute; right: 5px; top: 5px;" (click)="sideNav.toggle()">
          <img src="../../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Hide side panel">
        </button>
        <div class="side-nav-options">
          <header style="margin-bottom: 20px;">
            <h2 style="margin: 0; padding: 8px">Options</h2>
            <div style="border-bottom: #7162C3 solid 1px;"></div>
          </header>
          <div class="side-nav-content">
            <div style="border: #ffffff6d solid 1px; padding: 10px; border-radius: 6px;">
              <mat-slide-toggle style="color: white; float:left; font-size: 13px;" [checked]="this.textExpand" (change)="this.toggleTextExpand()">
                Expand text
              </mat-slide-toggle>
            </div>

            <div *ngIf="this.permissions.canCreate">
              <button style="width: 100%;" mat-flat-button color="primary" class="create-button" (click)="this.handleOpenEvent(this.eventModalMode.create)">Create New Event</button>
            </div>
          </div>
        </div>
        <div>
          <header>
            <h2 style="margin: 0; padding: 8px;">Filters</h2>
            <div style="border-bottom: #7162C3 solid 1px;"></div>
          </header>
          <div style="padding: 25px 0">
            <ng-content select="[filters]"></ng-content>
          </div>
        </div>

      </article>
    </mat-sidenav>


    <!-- Events options and filters -->
    <mat-sidenav-content [style.height]="'calc(100vh - ' + this.topGap + 'px)'" style="overflow: auto !important;">
      <button *ngIf="!sideNav.opened" mat-button class="side-nav-buttons" style="position: fixed; left: 52px; top: 152px;" (click)="sideNav.toggle()">
        <img src="../../../assets/images/icon-filter.png" style="vertical-align: bottom;" alt="Show side panel">
      </button>

      <div>
        <section class="events-content-container">
          <!-- Drafts -->
          <mat-accordion *ngIf="this.draftEvents?.length > 0 && !this.loading">
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Drafts
                </mat-panel-title>
                <mat-panel-description>
                  <div>
                    {{this.draftEvents?.length}} draft event{{this.draftEvents?.length > 1 ? 's.' : '.'}}
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </mat-accordion>

          <!-- Events -->
          <mat-card style="text-align: left; padding: 12px;">
            <mat-card-content>

              <div *ngIf="this.loading && this.events === undefined; else showEvents">
                <div style="text-align: center; font-size: 1rem; padding: 30px 0 5px 0">
                  Loading Events...
                </div>
              </div>

              <ng-template #showEvents>
                <div *ngIf="this.events?.length > 0 && !this.eventsError; else noEvents">
                  <div *ngFor="let event of this.events;" (click)="this.handleOpenEvent(this.eventModalMode.view, event)">
                    <event-item [event]="event" [highlight]="this.eventToHighlight === event.eventId" [textExpand]="this.textExpand" [eventDisplayType]="this.eventDisplayType"></event-item>
                    <mat-divider></mat-divider>
                  </div>
                </div>
              </ng-template>

              <ng-template #noEvents>
                <div *ngIf="!this.eventsError; else errorGettingEvents" style="text-align: center; padding: 28px; font-size: 16px;">
                    {{ this.noData }}
                </div>
              </ng-template>

              <ng-template #errorGettingEvents>
                <div style="text-align: center; padding: 28px; font-size: 16px;">
                  {{ this.dataError }}
                </div>
              </ng-template>

              <!-- Pagination -->
              <div style="min-height: 4px; background-color: white;">
                <mat-progress-bar *ngIf="this.loading" mode="indeterminate"></mat-progress-bar>
              </div>
              <mat-paginator
                #paginator
                [pageSize]="7"
                [length]="this.totalEvents"
                showFirstLastButtons
                (page)="handlePageEvent($event)"
                aria-label="Select page"
                [disabled]="this.loading"
              >
              </mat-paginator>
            </mat-card-content>
          </mat-card>
        </section>
      </div>

    </mat-sidenav-content>

  </mat-sidenav-container>
</article>
