import { UserService } from 'src/app/core/services/user.service';
import { LearnerSearchTableService } from './../core/database/learner-search-table.service';
import { IUserInfo } from 'src/app/shared/interfaces/user-info';
import { UserTableService } from './../core/database/user-table.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ENTER } from '@angular/cdk/keycodes';
import { LearnerSearchService } from './learner-search.service';
import { SearchHistoryTableService } from '../core/database/search-history.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms'
import { Component, OnInit, ChangeDetectorRef, HostListener, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { LearnerTableService } from '../core/database/learner-table.service';
import { ILearner } from '../shared/interfaces/learner';
import { MatSelectChange } from '@angular/material/select';
import { ISearchFilter } from '../shared/interfaces/search-filter';
import { IGenericTextValuePair } from '../shared/interfaces/generic-text-value-pair';
import * as _ from 'underscore';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SharedFunctionsService } from '../core/services/shared-functions.service';
import { MilestoneTableService } from '../core/database/milestone-table-service';
import { ProgressTableService } from '../core/database/progress-table-service';
import { DATE_FORMAT } from 'src/app/app.constants';

export interface User {
  term: string;
}

@Component({
  selector: 'learner-search',
  templateUrl: './learner-search.component.html',
  //templateUrl: './learner-search-trial.component.html',
  styleUrls: ['./learner-search.component.scss']
})

export class LearnerSearchComponent implements OnInit {
  loadingLearner: boolean = false;
  selectedLearner: any;
  selectedTraineeId: any;

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.learnerSearchTableService.clear();
    this.learnerTableService.clear();
    this.milestonesTableService.clear();
    this.progressTableService.clear();
  }

  sideBarOpen;

  description: string;
  searchResultsRaw: any[] = [];
  searchResultsFiltered: any[] = [];
  searchForm: UntypedFormGroup;
  searchTypes: any[];
  selectedSearchType: IGenericTextValuePair;
  refineByKeywordText: string;
  icon: string;
  width: any;
  checkWidth: any;
  userInfo: IUserInfo;
  countUnread: number;

  formFieldStyle: string = 'none';

  // Filters
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  readonly separatorKeysCodes: number[] = [ENTER];
  filters: ISearchFilter[] = [];

  noResultsMessage: string;
  showSpinner: boolean;

  options: User[] = [];
  filteredOptions: Observable<User[]>;

  selectedLearnerName: string;
  selectedGender: string;
  selectedSector: string;
  selectedDepartment: string;
  selectedAssessor: string;
  selectedUniqueIdentifier: string;
  selectedPostCode: string;
  selectedPostTown: string;
  selectedEmployerBranchPlacement: string;
  selectedScheme: string;

  learnerNameOptions: any[];
  genderOptions: any[];
  sectorOptions: any[];
  departmentOptions: any[];
  assessorOptions: any[];
  postCodeOptions: any[];
  postTownOptions: any[];
  employerBranchPlacementOptions: any[];
  schemeOptions: any[];

  startDate: Date;
  endDate: Date;

  isActiveLearnersOnly: boolean = true;

  isRedoSearch: boolean = false;

  useRandP: boolean = false;

  dateFormat = DATE_FORMAT;

  @ViewChild("startDateRange") dateRangePicker;

  constructor(
    private fb: UntypedFormBuilder,
    private learnerSearchService: LearnerSearchService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private learnerTableService: LearnerTableService,
    private userTableService: UserTableService,
    private userService: UserService,
    private learnerSearchTableService: LearnerSearchTableService,
    private searchHistoryTableService: SearchHistoryTableService,
    private milestonesTableService: MilestoneTableService,
    private progressTableService: ProgressTableService,
    private sharedFunctions: SharedFunctionsService
  ) { }



  ngOnInit() {
    this.getCurrentUser();

    this.resetFilterOptions();

    this.populateSearchTypeDropDownList();
    this.buildForm();
    this.selectedSearchType = { value: 'LearnerName', text: 'Learner Name' };
    this.refineByKeywordText = "Try NI, LN02 or TraineeID";
    this.icon = "filter_list";
    this.width = { 'width': "50%" };

    this.onChanges();

    this.getSearchResultsFromLocalDb();

    this.filteredOptions = this.searchForm.get('searchText').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filter(name) : this.options.slice())
      );
  }

  resetFilterOptions() {
    this.learnerNameOptions = [];
    this.genderOptions = [];
    this.sectorOptions = [];
    this.departmentOptions = [];
    this.assessorOptions = [];
    this.postCodeOptions = [];
    this.postTownOptions = [];
    this.employerBranchPlacementOptions = [];
    this.schemeOptions = [];
  }

  getSearchHistoryList() {
    this.searchHistoryTableService.getAll().then(
      result => {
        //console.log(result, 'history');
        let searches = result;
        this.options = [];
        for (let x in searches) {
          this.options.push({term: result[x]});
        };
      }
    )
    //this.cdr.detectChanges();
  }


  displayFn(user: User): string {
    //console.log('triggered', user)
    return user && user.term ? user.term : '';
  }

  filter(name: string): User[] {
    //console.log('triggered', name)
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.term.toLowerCase().includes(filterValue));
  }

  getRouteDataAndApplySearch() {
    this.route.data.forEach(
      data => {
        //console.log('assessorName', data);
        if (data.assessorName) {
          //console.log('assessorname provided')
          this.loadAssessorNameAndSearch(data.assessorName)
        }

      })
  }

  onChanges(): void {

    this.getSearchHistoryList();

    this.searchForm.get('searchText').valueChanges.subscribe(val => {
      //console.log('search text', val);
      this.noResultsMessage = '';
    });
  }

  loadAssessorNameAndSearch(assessorName) {
    this.selectedSearchType = { value: "AssessorName", text: "Assessor Name" }

    this.searchForm.get('searchText').setValue(assessorName);
    this.selectedSearchType.value == "AssessorName"
    this.searchForm.get('searchType').setValue("AssessorName")

    var newFilterEntry = { type: "externalSearch", value: assessorName }
    this.filters.push(newFilterEntry);

    this.executeSearch();

  }

  getCurrentUser() {
    this.userTableService.get(1)
      .then(staffResponse => {
        if (!staffResponse || staffResponse == undefined || staffResponse == null) {
          this.router.navigate(['']);
        } else {
          this.userInfo = staffResponse;
          this.userService.getNotifications(staffResponse.staffId).subscribe(
            notificationsResponse => {
              staffResponse.countUnreadNotifications = notificationsResponse['countUnreadNotifications'];
              this.userService.updateUserNotifications(staffResponse);
              this.cdr.detectChanges();
            });
        }
        this.getRouteDataAndApplySearch();
      });
  }

  buildForm() {
    this.searchForm = this.fb.group({
      searchText: null,
      searchDateOfBirth: null,
      searchFilter: null,
      searchType: null,
      activeLearnersOnly: this.isActiveLearnersOnly,
      sectorOptions: this.sectorOptions,
      departmentOptions: this.departmentOptions,
      assessorOptions: this.assessorOptions,
      uniqueIdentifierFilter: null,
      genderOptions: this.genderOptions,
      learnerNameOptions: this.learnerNameOptions,
      postCodeOptions: this.postCodeOptions,
      postTownOptions: this.postTownOptions,
      employerBranchPlacementOptions: this.employerBranchPlacementOptions,
      schemeOptions: this.schemeOptions
    });
  }

  get filterStyle() {
    return this.width;
  }

  get filterStyleTickbox() {
    return this.checkWidth;
  }

  // enterUniqueIdentifier(event: any, value: string, field: string) {
  //   console.log(event);

  //   if(event.key == 'Enter') {
  //     this.setLocalFilter(value, field);
  //   }
  // }


  setUniqueIdentifierFilter(event: any) {
    if(event.key == 'Enter') {
      this.setLocalFilter(this.selectedUniqueIdentifier, 'uniqueIdentifier');
    }
  }

  setLocalFilter(value: string, field: string) {
    if (value) {
      this.clearSelected(field);
      let newFilterEntry = {
        type: 'localFilter',
        value: field != 'uniqueIdentifier' ? value[field] : value,
        field: field
      };

      this.filters.push(newFilterEntry);

      this.searchLocal();
    } else {
      this.clearSelected(field);
    }
  }

  clearSelected(field: string) {
    if(field) {
      let filterToRemove = this.filters.filter(f => f.field == field)[0];
      if(filterToRemove) {
        this.removeFilter(filterToRemove);
      }
    }
  }

  clearAllLocalFilters() {
    this.filters = this.filters.filter(f => f.type != 'localFilter');

    this.searchForm.controls['uniqueIdentifierFilter'].setValue(null);
    this.searchForm.controls['sectorOptions'].setValue(null);
    this.searchForm.controls['departmentOptions'].setValue(null);
    this.searchForm.controls['assessorOptions'].setValue(null);
    this.searchForm.controls['genderOptions'].setValue(null);
    this.searchForm.controls['learnerNameOptions'].setValue(null);
    this.searchForm.controls['postCodeOptions'].setValue(null);
    this.searchForm.controls['postTownOptions'].setValue(null);
    this.searchForm.controls['employerBranchPlacementOptions'].setValue(null);
    this.searchForm.controls['schemeOptions'].setValue(null);

    // this will reset the selected value on each learners to true
    this.searchLocal();
  }

  setLocalFilterInFormControls() {
    const filters = this.filters.filter(f => f.type == 'localFilter');

    this.searchForm.controls['uniqueIdentifierFilter'].setValue(_.findWhere(filters, {field: 'uniqueIdentifier'})?.value);
    this.searchForm.controls['sectorOptions'].setValue(_.findWhere(filters, {field: 'sector'})?.value);
    this.searchForm.controls['departmentOptions'].setValue(_.findWhere(filters, {field: 'department'})?.value);
    this.searchForm.controls['assessorOptions'].setValue(_.findWhere(filters, {field: 'assessor'})?.value);
    this.searchForm.controls['genderOptions'].setValue(_.findWhere(filters, {field: 'gender'})?.value);
    this.searchForm.controls['learnerNameOptions'].setValue(_.findWhere(filters, {field: 'fullName'})?.value);
    this.searchForm.controls['postCodeOptions'].setValue(_.findWhere(filters, {field: 'postCode'})?.value);
    this.searchForm.controls['postTownOptions'].setValue(_.findWhere(filters, {field: 'postTown'})?.value);
    this.searchForm.controls['employerBranchPlacementOptions'].setValue(_.findWhere(filters, {field: 'employerBranchPlacement'})?.value);
    this.searchForm.controls['schemeOptions'].setValue(_.findWhere(filters, {field: 'scheme'})?.value);
  }

  resetForm() {
    this.sideBarOpen = false;
    this.showSpinner = false;
    this.searchResultsRaw = [];
    this.searchResultsFiltered = [];
    this.filters = [];
    this.searchForm.reset();
    this.selectedSearchType = { value: 'LearnerName', text: 'Learner Name' };
    this.buildForm();
    this.noResultsMessage = '';
    this.learnerSearchTableService.clear();
    this.learnerTableService.clear();
    this.milestonesTableService.clear();
    this.progressTableService.clear();
    this.getSearchHistoryList();
    this.resetFilterOptions();
    return false;
  }

  onFocus(event) {
    //this.refineByKeywordText = "Why not try location, assessor or employer?"
    this.checkWidth = { 'width': '15%' };
    this.width = { 'width': "60%" };
    this.icon = 'search';
  }

  lostFocus(event) {
    //this.refineByKeywordText = "Refine by keyword";
    this.checkWidth = { 'width': '15%' };
    this.width = { 'width': "50%" };
    this.icon = 'filter_list'
  }

  populateSearchResultsFiltered() {
    if (this.searchResultsRaw != undefined && this.searchResultsRaw != null) {
      this.searchResultsFiltered = this.searchResultsRaw[0].learnerSearch.filter(
        element => {
          return element.selected == true;
        }
      )
      //console.log('searchResultsFiltered', this.searchResultsFiltered)
    }
    this.showSpinner = false;
  }

  saveSearchResultsToLocalDb() {
    this.learnerSearchTableService.clear();
    this.learnerSearchTableService.add(this.searchResultsRaw, 0);
    this.learnerSearchTableService.add(this.searchResultsFiltered, 1);
    this.learnerSearchTableService.add(this.filters, 2);
  }

  getSearchResultsFromLocalDb() {
    this.learnerSearchTableService.getAll().then(
      searchResults => {
        //console.log('from localDb', searchResults);
        if (searchResults.length) {
          this.searchResultsRaw = searchResults[0];
          this.searchResultsFiltered = searchResults[1];
          this.filters = searchResults[2];
          if(this.learnerNameOptions.length == 0) {
            this.organiseFilterOptions(this.searchResultsRaw[0].learnerSearch);
          }
          this.restoreSelectedLocalFilters();
        }
        this.cdr.detectChanges();
      }
    )
  }

  restoreSelectedLocalFilters() {
    for(let i = 0; i < this.filters.length; i++) {
      if(this.filters[i].type === 'localFilter') {
        switch(this.filters[i].field) {
          case 'fullName':
            this.selectedLearnerName = this.filters[i].value;
            break;
          case 'assessor':
           this.selectedAssessor = this.filters[i].value;
            break;
          case 'postTown':
            this.selectedPostTown = this.filters[i].value;
            break;
          case 'postCode':
            this.selectedPostCode = this.filters[i].value;
            break;
          case 'gender':
           this.selectedGender = this.filters[i].value;
            break;
          case 'employerBranchPlacement':
            this.selectedEmployerBranchPlacement = this.filters[i].value;
            break;
          case 'sector':
            this.selectedSector = this.filters[i].value;
            break;
          case 'department':
            this.selectedDepartment = this.filters[i].value;
            break;
          case 'scheme':
            this.selectedScheme = this.filters[i].value;
            break;
          case 'uniqueIdentifier':
            this.selectedUniqueIdentifier = this.filters[i].value;
            break;
        }
      }
    }
  }

  addFilter(event: MatChipInputEvent = null, clickEvent: boolean = false): void {

    let value: any;

    if (this.searchForm.value.searchType == 'DOB') {
      if(clickEvent){
        let d = this.searchForm.controls['searchDateOfBirth'].value;
        value = moment(d).format('DD/MM/YYYY');
      } else {
        value = event.value;
      }
    } else {
      value = clickEvent ? this.searchForm.controls['searchText'].value : event.value;
    }

    if(value == 'Invalid date') return;

    // Add filter
    if ((value || '').trim()) {

      if (this.searchForm.value.searchType != 'DOB') {
        // Deal with legit (i.e. first) comma
        const commaPos = value.indexOf(',');

        if (commaPos != -1) {
          const leftPart = value.substring(0, commaPos).trim();
          const rightPart = value.substring(commaPos + 1, value.length).trim();
          value = `${rightPart} ${leftPart}`;
        }
        // deal with any superfluous commas
        value = value.replace(',', ' ').trim();

        //deal with wildcards
        value = value.replaceAll('%', '');

      }

      const formValues = this.searchForm.value;
      formValues.searchText = value;

      const newFilterEntry = {
        type: this.isLocalSearch() && !clickEvent ? 'localFilter' : 'externalSearch',
        value: value
      };
      this.filters.push(newFilterEntry);

      this.executeSearch();
    }

    // Reset input value
    if (this.isLocalSearch()) {
      this.searchForm.controls.searchFilter.reset();
    }
  }

  selectAllSearchResults(searchResult: any, select: boolean) {
    searchResult.forEach(result => {
      result.learnerSearch.forEach(learner => {
        learner.selected = select;
      })
    });
  }

  removeFilter(filter: ISearchFilter): void {
    const index = this.filters.indexOf(filter);

    if (index >= 0) {
      this.filters.splice(index, 1);
      if (this.filters.length == 1) {
        this.filters[0].type = 'externalSearch';
        this.searchForm.value.searchFilter = this.filters[0].value;
        this.searchForm.value.searchText = this.filters[0].value;
      }
      this.searchLocal();
    }

    if (!this.hasExternalSearchFilter()) {
      this.resetForm();
    }
  }

  filtersOrdered(excludeExternal: boolean = true) {
    //console.log('filtersOrdered', this.filters.length);
    let sorted = this.filters.sort(function (a, b) {
      var nameA = a.type.toUpperCase();
      var nameB = b.type.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });

    if (excludeExternal) {
      sorted = sorted.filter(
        element => {
          return element.type != 'externalSearch';
        }
      )
    }

    return sorted;
  }

  searchTypeClass(filter: ISearchFilter) {
    let searchTypeClass: string;
    if (filter.type == 'externalSearch') {
      searchTypeClass = 'primary';
    } else {
      searchTypeClass = 'secondary';
    }
    //console.log('searchTypeClass', searchTypeClass);
    return searchTypeClass;
  }

  hasExternalSearchFilter() {
    return this.filters.filter(item => item.type == 'externalSearch').length > 0;
  }

  hasLocalSearchFilter() {
    return this.filters.filter(item => item.type == 'localFilter').length > 0;
  }

  isLocalSearch() {
    const hasResults = this.searchResultsRaw != undefined && this.searchResultsRaw.length > 0;
    this.sideBarOpen = hasResults;
    return hasResults;
  }

  filtersOfType(type: string) {
    // type='externalSearch': search database via API
    // type='localFilter': filter search results returned from API
    let filters: ISearchFilter[];
    filters = this.filters.filter(
      element => {
        return element.type == type;
      }
    );
    return filters;
  }

  externalFilterValue() {
    const filter = this.filtersOfType('externalSearch');
    return filter[0].value;
  }

  executeSearch() {
    if(!this.isRedoSearch){
      this.noResultsMessage = '';
      this.showSpinner = true;
      if (this.isLocalSearch()) {
        this.searchLocal();
      } else {
        this.searchExternal();
      }
    }
  }

  onSelectedSearchType(event: MatSelectChange): void {

    this.selectedSearchType = {
      value: event.value,
      text: event.source.triggerValue
    };

  }

  searchExternal() {

    if (!this.isRedoSearch) {
      if (!this.userInfo || this.userInfo == undefined || this.userInfo == null) {
        this.router.navigate(['']);
      }

      this.searchResultsRaw = null;

      let searchText: string;
      let searchType = this.searchForm.get('searchType').value;
      this.isActiveLearnersOnly = this.searchForm.get('activeLearnersOnly').value;
      //this.useRandP = this.searchForm.get('useRandP').value;

      if (this.hasExternalSearchFilter()) {
        let externalFilter = this.filters.filter(item => item.type == 'externalSearch')
        searchText = externalFilter[0].value
        let x = this.searchHistoryTableService.add(searchText).then(
          result => {
            this.getSearchHistoryList();
          }
        )
      }

      if (this.selectedSearchType.value == 'DOB') {
        const searchValues = this.searchForm.value;
        searchText = searchValues.searchText;
      }

      this.learnerSearchService.searchLearner(searchText, searchType, this.isActiveLearnersOnly, this.userInfo.staffId, this.useRandP)
        .subscribe(response => {
          if (response.length > 0) {
            this.sideBarOpen = true;
            this.organiseSearchResult(response);
          } else {
            this.sideBarOpen = false;
            this.noResultsMessage = `${this.selectedSearchType.text} '${searchText}' does not exist, or you do not have access.`;
            this.showSpinner = false;
            this.searchResultsRaw = [];
            this.searchResultsFiltered = [];
            this.filters = [];
          }
          this.cdr.detectChanges();
        });
    }
  }

  searchLocal() {
    const filters = this.filtersOfType('localFilter');

    this.resetFilterOptions();

    if (this.searchResultsRaw) {
      for (let i = 0; i < this.searchResultsRaw.length; i++) {
        this.searchResultsRaw[i].learnerSearch.forEach(result => {
          if(filters.length > 0) {
            let perLearnerPass = 0;

            filters.forEach(filter => {
              let filterValue = filter['value'];
              let delegate: string | string[];
              let partialMatch = false;

              if (perLearnerPass == 0 || perLearnerPass > 0 && result.selected) {

                switch(filter['field']){
                  case 'fullName':
                    delegate = `${result.learnerBasics.firstName} ${result.learnerBasics.lastName}`;
                    break;
                  case 'assessor':
                    delegate = `${result.assessor.firstName} ${result.assessor.lastName}`;
                    break;
                  case 'postTown':
                    delegate = [result.learnerBasics.postTown, result.employerBranch.postTown, result.employer.postTown];
                    break;
                  case 'postCode':
                    delegate = [result.learnerBasics.postCode, result.potDetails.placementLocation];
                    break;
                  case 'gender':
                    filterValue = filterValue === null ? null : this.sharedFunctions.convertGenderString(filterValue);
                    delegate = result.learnerBasics.genderId;
                    break;
                  case 'employerBranchPlacement':
                    delegate = [result.employerBranch.branchName, result.employer.employerName, result.potDetails.placementName];
                    break;
                  case 'sector':
                    delegate = result.sector.sector;
                    break;
                  case 'department':
                    delegate = result.sector.department;
                    break;
                  case 'scheme':
                    delegate = result.scheme.scheme;
                    break;
                  case 'uniqueIdentifier':
                    delegate = [result.learnerBasics.uln, result.learnerBasics.uli, result.learnerBasics.niNumber, result.learnerBasics.ln02, result.learnerBasics.traineeId];
                    break;
                  default:
                    console.log('Unknown filter field');
                    break;
                }

                result.selected = this.filterOnString(delegate, filterValue, partialMatch);
                if(result.selected && perLearnerPass === filters.length-1) {
                  this.addFilterOptions(result);
                }
              } else {
                result.selected = false;
              }
              perLearnerPass++;
            });
          } else {
            result.selected = true;
            this.addFilterOptions(result);
          }
        });

      }
    }
    this.makeFilterOptionsUniqueAndOrdered();
    this.populateSearchResultsFiltered();
    this.saveSearchResultsToLocalDb();
  }

  addFilterOptions(learner: any) {
    if(learner.learnerBasics.fullName) this.learnerNameOptions.push({ fullName: learner.learnerBasics.fullName });
    if(learner.assessor.assessor) this.assessorOptions.push({ assessor: learner.assessor.assessor });
    if(learner.learnerBasics.postCode) this.postCodeOptions.push({ postCode: learner.learnerBasics.postCode.toUpperCase() });
    if(learner.learnerBasics.postTown) this.postTownOptions.push({ postTown: learner.learnerBasics.postTown.toLowerCase() });
    if(learner.employer.postTown) this.postTownOptions.push({ postTown: learner.employer.postTown.toLowerCase() });
    if(learner.employerBranch.postTown) this.postTownOptions.push({ postTown: learner.employerBranch.postTown.toLowerCase() });
    if(learner.sector.sector) this.sectorOptions.push({ sector: learner.sector.sector });
    if(learner.employer.employerName) this.employerBranchPlacementOptions.push({ employerBranchPlacement: learner.employer.employerName.toLowerCase() });
    if(learner.employerBranch.branchName) this.employerBranchPlacementOptions.push({ employerBranchPlacement: learner.employerBranch.branchName.toLowerCase() });
    if(learner.potDetails.placementName) this.employerBranchPlacementOptions.push({ employerBranchPlacement: learner.potDetails.placementName.toLowerCase() });
    if(learner.sector.department) this.departmentOptions.push({ department: learner.sector.department });
    if(learner.scheme.scheme) this.schemeOptions.push({ scheme: learner.scheme.scheme });
    if(learner.learnerBasics.genderId) this.genderOptions.push({gender: learner.learnerBasics.genderId === null ? null : this.sharedFunctions.convertGenderString(learner.learnerBasics.genderId)});
  }

  makeFilterOptionsUniqueAndOrdered() {
    // Creates a unique array for each filter using Map due to the data being objects
    this.learnerNameOptions = [...new Map(this.learnerNameOptions.map(item => [item['fullName'], item])).values()];
    this.assessorOptions = [...new Map(this.assessorOptions.map(item => [item['assessor'], item])).values()];
    this.postCodeOptions = [...new Map(this.postCodeOptions.map(item => [item['postCode'], item])).values()];
    this.postTownOptions = [...new Map(this.postTownOptions.map(item => [item['postTown'], item])).values()];
    this.sectorOptions = [... new Map(this.sectorOptions.map(item => [item['sector'] ,item])).values()];
    this.employerBranchPlacementOptions = [... new Map(this.employerBranchPlacementOptions.map(item => [item['employerBranchPlacement'] ,item])).values()];
    this.departmentOptions = [... new Map(this.departmentOptions.map(item => [item['department'] ,item])).values()];
    this.schemeOptions = [... new Map(this.schemeOptions.map(item => [item['scheme'] ,item])).values()];
    this.genderOptions = [... new Map(this.genderOptions.map(item => [item['gender'] ,item])).values()];

    this.assessorOptions = this.sharedFunctions.genericOrdered(this.assessorOptions, 'assessor');
    this.postCodeOptions = this.sharedFunctions.genericOrdered(this.postCodeOptions, 'postCode');
    this.postTownOptions = this.sharedFunctions.genericOrdered(this.postTownOptions, 'postTown');
    this.sectorOptions = this.sharedFunctions.genericOrdered(this.sectorOptions, 'sector');
    this.departmentOptions = this.sharedFunctions.genericOrdered(this.departmentOptions, 'department');
    this.employerBranchPlacementOptions = this.sharedFunctions.genericOrdered(this.employerBranchPlacementOptions, 'employerBranchPlacement');
    this.schemeOptions = this.sharedFunctions.genericOrdered(this.schemeOptions, 'scheme');
  }

  private organiseSearchResult(searchResult: any[]) {
    this.selectAllSearchResults(searchResult, true);
    this.populateToolTips(searchResult);
    this.searchResultsRaw = searchResult;
    if (this.hasLocalSearchFilter()) {
      this.searchLocal();
    } else {
      this.organiseFilterOptions(searchResult[0].learnerSearch);
    }
    this.populateSearchResultsFiltered();
    this.saveSearchResultsToLocalDb();
  }

  private populateToolTips(searchResult: any[]) {
    searchResult[0].learnerSearch.forEach( r => {
      let toolTipArray: string[] = [];
      if(r.learnerBasics.dob) toolTipArray.push(`DOB: ${moment(r.learnerBasics.dob).format('DD-MM-YYYY')}`);
      if(r.learnerBasics.uli) toolTipArray.push(`ULI: ${r.learnerBasics.uli}`);
      if(r.learnerBasics.programme) toolTipArray.push(`Programme: ${r.learnerBasics.programme}`);
      if(r.learnerBasics.centre) toolTipArray.push(`Centre: ${r.learnerBasics.centre}`);
      if(r.sector.department) toolTipArray.push(`Dept: ${r.sector.department}`);
      r.toolTipText = this.tooltipText(toolTipArray);
    })
  }

  tooltipText( textArray: string[]) {
    let text = '';
    textArray.forEach(txt => {
      if(txt) text += txt + ' / '
    })
    text = text.substring(0,text.length-3);
    return text;
  }

  private organiseFilterOptions(searchResult: any[]) {
    this.resetFilterOptions();
    searchResult.forEach(learner => {
      if(learner.selected)
      this.addFilterOptions(learner);
    });
    this.makeFilterOptionsUniqueAndOrdered();

    this.buildForm();
  }

  filterOnDate(delegate: string, filterString: string): boolean {
    let isMatch = false;

    if (delegate && moment(delegate).isValid && !isMatch) {
      const dob = moment(delegate).startOf('day');
      const dobFilt = moment(filterString);

      if (dob.isSame(dobFilt, 'year') && dob.isSame(dobFilt, 'month') && dob.isSame(dobFilt, 'day')) {
        isMatch = true;
      }
    };

    return isMatch;
  }

  filterOnString(delegate:string | string[], filter: string, partialMatch: boolean): boolean {
    let isMatch = false;

    if (!delegate || !filter) { return isMatch };
    filter = filter.toLowerCase();

    if(typeof delegate === 'string') {
      delegate = delegate.toLowerCase();
      if(!partialMatch) {
        isMatch = delegate === filter;
      } else {
        isMatch = delegate.indexOf(filter) > -1; // Returns true if filter string is found within delegate string.
      }
    } else {
      delegate.every(valueString => {
        if (valueString) {
          const delegateString = valueString.toLowerCase();
          if (!partialMatch && delegateString === filter) {
            isMatch = true;
            return false; // Exits out of the every methord
          } else if (partialMatch && delegateString.indexOf(filter) > -1) { // Returns true if filter string is found within delegate string.
            isMatch = true;
            return false; // Exits out of the every methord
          } else {
            return true; // Keeps running the every methord
          }
        }
      });
    }

    return isMatch;
  }

  filterOnFirstAndLastNames(firstName: string, lastName: string, filterString: string): boolean {
    let isMatch = false;

    if (`${firstName} ${lastName}` == filterString) { return true; }

    let nameParts = filterString.split(' ');

    if (firstName && firstName.substring(0, nameParts[0].length).toLowerCase() == nameParts[0].toLowerCase()) {
      isMatch = true;
    }

    if (nameParts.length == 1) {
      if (lastName && lastName.substring(0, nameParts[0].length).toLowerCase() == nameParts[0].toLowerCase()) {
        isMatch = true;
      }
    } else {
      if (lastName && lastName.substring(0, nameParts[1].length).toLowerCase() != nameParts[1].toLowerCase()) {
        isMatch = false;
      }
    }

    return isMatch;
  }

  onSelect(learner: any) {
    this.selectedLearner = learner;
    this.selectedTraineeId = learner.learnerBasics.traineeId;
    this.learnerTableService.count().then(
      result => {
        if (result >= 1) {
          this.learnerTableService.clear()
        }
        this.saveLocalLearnerAndNavigateToDashboard(learner);
      }
    )
  }

  saveLocalLearnerAndNavigateToDashboard(learner: any) {

    this.loadingLearner = true;
    this.cdr.detectChanges();

    let learnerToSave: ILearner = {
      traineeId: learner.learnerBasics.traineeId,
      pot: learner.potDetails.pot,
      pots: learner.learnerBasics.pots,
      firstName: learner.learnerBasics.firstName,
      lastName: learner.learnerBasics.lastName,
      fullName: learner.learnerBasics.fullName,
      genderId: learner.learnerBasics.genderId,
      dob: learner.learnerBasics.dob,
      email: learner.learnerBasics.email,
      postCode: learner.learnerBasics.postCode,
      postTown: learner.learnerBasics.postTown,
      oneFileId: learner.learnerBasics.oneFileId,
      programme: learner.learnerBasics.programme,
      welshSpeaking: learner.learnerBasics.welshSpeaking
    };

    this.milestonesTableService.clear();
    this.progressTableService.clear();

    this.learnerTableService.put(learnerToSave).then((id) => {
      //console.log(`${learnerToSave.traineeId} added to localDb. Id = ${id}`);

      this.router.navigateByUrl(`/learner/dashboard/${learner.learnerBasics.traineeId}/${learner.potDetails.pot}`, { state: { originURL: this.router.url } });
    })
  }

  populateSearchTypeDropDownList() {
    this.searchTypes = [
      { value: 'LearnerName', title: 'Learner Name' },
      { value: 'AssessorName', title: 'Assessor Name' },
      { value: 'EmployerName', title: 'Employer Name' },
      { value: 'NI', title: 'NI Number' },
      { value: 'DOB', title: 'Date of Birth' },
      { value: 'TraineeId', title: 'TraineeID' },
      { value: 'LN02', title: 'LN02' },
      { value: 'LearnerFirstName', title: 'Learner (First Name Only)' },
      { value: 'LearnerSurname', title: 'Learner (Surname Only)' }
    ];
  }

  activeOnlySearch() {
    this.showSpinner = true;
    this.isActiveLearnersOnly = !this.isActiveLearnersOnly;
    this.searchForm.get('activeLearnersOnly').setValue(this.isActiveLearnersOnly);
    this.learnerSearchTableService.clear();
    this.learnerTableService.clear();
    this.milestonesTableService.clear();
    this.progressTableService.clear();
    this.redoExternalSearch();
    this.showSpinner = false;
  }

  redoExternalSearch(): void {
    if (this.hasExternalSearchFilter()) {
      this.isRedoSearch = true;
      const externalFilter = this.filters.filter(a => a.type == "externalSearch");
      const searchText = externalFilter[0].value;
      this.showSpinner = true;
      this.learnerSearchService.searchLearner(searchText, this.selectedSearchType.text.replace(' ', ''), this.isActiveLearnersOnly, this.userInfo.staffId)
        .subscribe(response => {
          if (response.length > 0) {
            this.sideBarOpen = true;
            this.organiseSearchResult(response);
            this.searchLocal();
            this.setLocalFilterInFormControls();
            this.isRedoSearch = false;
          } else {
            this.sideBarOpen = false;
            this.noResultsMessage = `${this.selectedSearchType.text} '${searchText}' does not exist, or you do not have access.`;
            this.showSpinner = false;
            this.searchResultsRaw = [];
            this.searchResultsFiltered = [];
            this.filters = [];
          }
          this.cdr.detectChanges();
        });
    }
  }
}
