import Dexie from "dexie";
import { LocalDb } from "./database.service";
import { Injectable } from "@angular/core";

@Injectable()
export class PermissionService {
    table: Dexie.Table<number[], string>;
    constructor(private localDb: LocalDb) {
        this.table = this.localDb.table('permission');
    }

    get(id) {
        return this.table.get(id);
    }

    getAll() {
        return this.table.toArray();
    }

    add(data, id) {
        return this.table.add(data, id);
    }

    put(data, id) {
        return this.table.put(data, id);
    }

    update(id, data) {
        return this.table.update(id, data);
    }

    remove(id) {
        return this.table.delete(id);
    }

    clear() {
        return this.table.clear();
    }
}