import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogModel {

  title: string,
  message: string,
  confirmButtonText: string,
  cancelButtonText: string,
  type: string,
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {

  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  type: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogModel,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  )
  {
    this.title = data.title;
    this.confirmButtonText = data.confirmButtonText;
    this.cancelButtonText = data.cancelButtonText;
    this.type = data.type ?? 'confirm';
  }

  ngOnInit(): void {
    this.message = this.addMarkdownFormatting(this.data.message);
  }

  addMarkdownFormatting(message: string): string {
    let formattedMessage = message;
    formattedMessage = formattedMessage.replace(/(\*\*|__)(.*?)(\*\*|__)/g, '<strong>$2</strong>');
    formattedMessage = formattedMessage.replace(/(\*|_)(.*?)(\*|_)/g, '<em>$2</em>');

    return formattedMessage;
  }


  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

}
