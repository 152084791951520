<div style="display: flex">
  <h2 class="progressTitle" style="flex: 1">Maytas</h2>
  <mat-icon class="icon--click-hover" *ngIf="isEditForm" (click)="addMaytasLink()" style="flex: 1; float: right; margin: auto;">
    add_circle</mat-icon>
</div>
<div class="maytas-list" *ngIf="showLinks">
  <ul *ngFor="let link of newMaytasLinks">
    <li style="color: green;">
      Employer: <b>{{link?.employer.employerName | titlecase}}</b>
    </li>
    <li style="color: green;">
      Branch: {{link?.branch.placementName | titlecase}}
      <mat-icon class="icon--click-hover" *ngIf="isEditForm" (click)="removeAddedMaytasLink(link)">delete_forever</mat-icon>
      <hr>
    </li>
  </ul>
  <ul *ngFor="let link of maytasLinks" [ngClass]="{'mark-delete !important': link.operation=='delete'}"
    style="margin-bottom: 1em;">
    <li><b>{{link?.employerName | titlecase}}</b>
      <mat-icon class="icon--click-hover" *ngIf="isEditForm" (click)="markMaytasLinkForDeletion(link)">delete_forever</mat-icon>
    </li>
    <li *ngIf="link?.createdBy">Created By: {{link?.createdBy}} </li>
    <li *ngIf="link?.createdWhen">Created: {{link?.createdWhen | date}} </li>
  </ul>
</div>
<div class="maytas-list" *ngIf="!showLinks">None</div>
