import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';


@Component({
  selector: 'app-guided-contact-hours-report',
  templateUrl: './guided-contact-hours-report.component.html',
})
export class GuidedContactHoursReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.GuidedContactHours;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'sector', 'lastReview', 'nextReview', 'daysToReview', 'attendanceCenterHours', 'attendanceWorkHours', 'historicCenterHours', 'historicWorkHours', 'activityCenterHours', 'activityWorkHours', 'centerHoursRemaining', 'workHoursRemaining']
    }
  ];
  dateFilters = ['startDate', 'expectedCompletionDate', 'lastReview', 'nextReview', 'firstAttended', 'lastAttended'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];
}

