import { Component, Input } from '@angular/core';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.css']
})
export class MilestonesComponent {

  @Input() milestones: any[];
  @Input() traineeId: string;
  @Input() pot: number;
  @Input() showMilestones: boolean;

  dateFormat = DATE_FORMAT;

  constructor() { }

}
