import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LearnerSearchService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  public searchLearner(searchText: string, searchType: string, activeLearnersOnly: boolean, staffId: number, useRolesAndPermissions: boolean = true): Observable<any> {
    if(!searchText || !searchType || !staffId) return null;
    const url = `${environment.API_URL}SearchLearner?searchString=${searchText}&searchType=${searchType}&activeLearnersOnly=${activeLearnersOnly}&staffId=${staffId}&useRolesAndPermissions=${useRolesAndPermissions}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

  public getLearner(traineeId: string): Observable<any> {
    const url = `${environment.API_URL}GetLearner?traineeId=${traineeId}`;
    const options = this.getOptionsWithAccessToken();
    return this.http.get(url, options);
  }

}


