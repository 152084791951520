import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractWidgetService {

  apiUrl: string;
  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);

    let options = { headers: headers };
    return options;
  }
  
  public getStaffContractWidgets(staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetStaffContractWidgets/${staffId}`;
    const options = this.getOptionsWithAccessToken();

    return this.http.get(url, options);
  }

  public getWidgetTemplate(staffId: number): Observable<any> {
    const url = `${environment.API_URL}GetWidgetTemplate/${staffId}`;
    const options = this.getOptionsWithAccessToken();

    return this.http.get(url, options);
  }

  public saveContractWidgetChanges(staffId: number, selectedWidgetIds: number[]): Observable<any> {
    console.log('widgets to update', selectedWidgetIds);
    
    const url = `${environment.API_URL}UpdateContractWidgets`;
    const options = this.getOptionsWithAccessToken();    
    let data = { StaffId: staffId, SelectedWidgetIds: selectedWidgetIds}
    return this.http.post<any>(url, data, options);
  }

  public removeStaffWidgetSelections(staffId: number): Observable<any> {
    const url = `${environment.API_URL}RemoveStaffWidgetSelections/${staffId}`;
    const options = this.getOptionsWithAccessToken();

    return this.http.get(url, options);
  }
}
