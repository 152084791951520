<h1 mat-dialog-title>Event info</h1>
<mat-dialog-content>
    <div>
        <label for="location">Organiser: </label>
        <span> {{data.event.staff}}</span>
    </div>
    <div>
        <label for="location">Subject: </label>
        <span> {{data.event.subject}}</span>
    </div>
    <div>
        <label for="location">Location: </label>
        <span> {{data.event.location}}</span>
    </div>
    <div>
        <label for="location">Start time: </label>
        <span>   {{data.event.eventDate | date: this.dateFormat}} {{data.event.startTime}}</span>
    </div>
    <div>
        <label for="location">End time: </label>
        <span>   {{data.event.eventDate | date: this.dateFormat}} {{data.event.endTime}}</span>
    </div>
    <div [innerHTML]="data.event.message"></div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" (click)="onNoClick()">Cancel</button>
    <button mat-button (click)="goToEdit()" cdkFocusInitial>Edit event</button>
</div>
