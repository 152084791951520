
<article class="main-article" *ngIf="show" fxLayout="row wrap" fxLayoutGap="100px"
  [formGroup]="learnerDetailsForm">

  <section class="section-container" fxLayout="row wrap" fxLayoutGap="100px">

      <!-- had to add a class to get the bottom margin to work as [style.margin-bottom.px]="15" was not working-->
    <div class="bm-15" fxLayout="column" fxLayoutGap="15px" >

      <!-- First Name -->
      <section>
        <div *ngIf="editPermissionLevel === 1">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;">First Name: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="none">
            <input matInput formControlName="firstName">
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <learner-details-entry
            [labelName]="'First Name'"
            [labelValue]="this.learnerDetails.learnerBasics.firstName"
            [inline]="true">
          </learner-details-entry>
        </div>
      </section>

      <!-- Last Name -->
      <section>
        <div *ngIf="editPermissionLevel === 1">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;">Last Name: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="none">
            <input matInput formControlName="lastName">
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <learner-details-entry
            [labelName]="'Last Name'"
            [labelValue]="this.learnerDetails.learnerBasics.lastName"
            [inline]="true">
          </learner-details-entry>
        </div>
      </section>

      <!-- Date of Birth -->
      <section>
        <ng-template #showAge>
          (age {{age}})
          <img *ngIf="birthday" src="../../../assets/images/icon-cake.png" alt="Birthday cake!" style="width: 1em; vertical-align: bottom;"/>
        </ng-template>
        <div *ngIf="editPermissionLevel === 1">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;">Born: </mat-label>
          <mat-form-field  [style.width.px]="95" class="mat-form-field-no-padding" appearance="none">
            <input matInput formControlName="dob">
          </mat-form-field>
          <ng-container *ngTemplateOutlet="showAge"></ng-container>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <learner-details-entry
            [labelName]="'Born'"
            [labelValue]="this.formattedDOB"
            [inline]="true">
          </learner-details-entry>
          <ng-container *ngTemplateOutlet="showAge"></ng-container>
        </div>
      </section>

      <!-- Phone -->
      <section *ngIf="learnerDetails.learnerContactInformation.phone || editMode && editPermissionLevel === 1">
        <div *ngIf="editPermissionLevel === 1">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Phone: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <input matInput formControlName="phone" >
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <learner-details-entry
            [labelName]="'Phone'"
            [labelValue]="this.learnerDetails.learnerContactInformation.phone"
            [inline]="true">
          </learner-details-entry>
        </div>
      </section>

      <!-- Mobile -->
      <section fxLayout="column"  fxLayoutGap="24px" *ngIf="learnerDetails.learnerContactInformation.mobile1 || learnerDetails.learnerContactInformation.mobile2">
        <div *ngIf="editPermissionLevel === 1">
          <div>
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
            [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Mobile: </mat-label>
            <mat-form-field *ngIf="learnerDetails.learnerContactInformation.mobile1" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
              <input matInput formControlName="mobile1" >
            </mat-form-field>
            <mat-form-field *ngIf="useOnlyMobile2" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
              <input matInput formControlName="mobile2" >
            </mat-form-field>
          </div>
          <mat-form-field *ngIf="learnerDetails.learnerContactInformation.mobile2 && !useOnlyMobile2" style="padding-left: 57px;" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <input matInput formControlName="mobile2" >
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <div>
            <learner-details-entry *ngIf="learnerDetails.learnerContactInformation.mobile1"
            [labelName]="'Mobile'"
            [labelValue]="this.learnerDetails.learnerContactInformation.mobile1"
            [inline]="true">
          </learner-details-entry>
          <learner-details-entry *ngIf="useOnlyMobile2"
            [labelName]="'Mobile'"
            [labelValue]="this.learnerDetails.learnerContactInformation.mobile2"
            [inline]="true">
          </learner-details-entry>
          </div>
          <learner-details-entry *ngIf="learnerDetails.learnerContactInformation.mobile2 && !useOnlyMobile2"
            [labelValue]="this.learnerDetails.learnerContactInformation.mobile2"
            [inline]="true"
            style="padding-left: 57px;">
          </learner-details-entry>
        </div>
      </section>

      <!-- Email -->
      <section fxLayout="column"  fxLayoutGap="24px" *ngIf="learnerDetails.learnerContactInformation.email1 || learnerDetails.learnerContactInformation.email2">
        <div *ngIf="editPermissionLevel === 1">
          <div>
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
              [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
              Email:
            </mat-label>
            <mat-form-field [style.width.px]="280" *ngIf="learnerDetails.learnerContactInformation.email1" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
              <a *ngIf="!editMode" href="javascript:void(0)" (click)="copyEmail(learnerDetails.learnerContactInformation.email1)" >
                <input #email1 matInput formControlName="email1" >
              </a>
              <input *ngIf="editMode" #email1 matInput formControlName="email1" >
            </mat-form-field>
            <mat-form-field [style.width.px]="280" *ngIf="useOnlyEmail2" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
              <a *ngIf="!editMode" href="javascript:void(0)" (click)="copyEmail(learnerDetails.learnerContactInformation.email2)" >
                <input #email2 matInput formControlName="email2" >
              </a>
              <input *ngIf="editMode" #email2 matInput formControlName="email2" >
            </mat-form-field>
          </div>
          <mat-form-field [style.width.px]="280" *ngIf="learnerDetails.learnerContactInformation.email2 && !useOnlyEmail2" style="padding-left:48px;" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <a *ngIf="!editMode" href="javascript:void(0)" (click)="copyEmail(learnerDetails.learnerContactInformation.email2)" >
              <input #email2 matInput formControlName="email2" >
            </a>
            <input *ngIf="editMode" #email2 matInput formControlName="email2" >
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <div>
            <learner-details-entry *ngIf="learnerDetails.learnerContactInformation.email1"
            [labelName]="'Email'"
            [labelValue]="this.learnerDetails.learnerContactInformation.email1"
            [inline]="true"
            [copyToClipboard]="true">
          </learner-details-entry>
          <learner-details-entry *ngIf="useOnlyEmail2"
            [labelName]="'Email'"
            [labelValue]="this.learnerDetails.learnerContactInformation.email2"
            [inline]="true"
            [copyToClipboard]="true">
          </learner-details-entry>
          </div>
          <learner-details-entry *ngIf="learnerDetails.learnerContactInformation.email2 && !useOnlyEmail2"
            [labelValue]="this.learnerDetails.learnerContactInformation.email2"
            [inline]="true"
            [copyToClipboard]="true"
            style="padding-left: 48px;">
          </learner-details-entry>
        </div>
      </section>

      <section>
        <div *ngIf="editPermissionLevel === 1">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Preferred learning language: </mat-label>
          <mat-form-field [style.width.px]="100" class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <mat-select formControlName="preferredLanguageOfLearning" >
              <mat-option value="English">English</mat-option>
              <mat-option value="Welsh">Welsh</mat-option>
              <mat-option value="Bi-lingual">Bi-lingual</mat-option>
              <mat-option value="">N/A</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <learner-details-entry
            [labelName]="'Preferred learning language'"
            [labelValue]="this.learnerDetails.learnerLanguagePreferences.preferredLanguageOfLearning ? this.learnerDetails.learnerLanguagePreferences.preferredLanguageOfLearning : 'N/A'"
            [inline]="true">
          </learner-details-entry>
        </div>
      </section>
    </div>

    <div fxLayout="column" fxLayoutGap="15px">

      <!-- Gender -->
      <!-- Removed editing for now, should be a drop down box -->
      <section>
        <div *ngIf="editPermissionLevel === 0">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
          [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Gender: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="none">
            <input matInput formControlName="gender" >
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 0">
          <learner-details-entry
            [labelName]="'Gender'"
            [labelValue]="this.formattedGender"
            [inline]="true">
          </learner-details-entry>
        </div>
      </section>

      <section>
        <learner-details-entry
        [labelName]="'Ethnicity'"
        [labelValue]="this.learnerDetails.learnerBasics.ethnicity"
        [inline]="true">
        </learner-details-entry>
      </section>

      <!-- Adress -->
      <learner-details-address-entry [type]="'Address'"
        [addressLineOne]="learnerDetails.learnerContactInformation.address.addressLine1"
        [addressLineTwo]="learnerDetails.learnerContactInformation.address.addressLine2"
        [addressLineThree]="learnerDetails.learnerContactInformation.address.addressLine3"
        [addressLineFour]="learnerDetails.learnerContactInformation.address.postCode">
      </learner-details-address-entry>

      <!-- Highest Quilification -->
      <learner-details-entry *ngIf="learnerDetails.learnerBasics.highestQualificationLevel != null"
        [labelName]="'Highest Qualification Level'"
        [labelValue]="learnerDetails.learnerBasics.highestQualificationLevel"
        [inline]="true">
      </learner-details-entry>


      <!-- Need to check if this is any good at all as info is in other places and I am not sure this even gets any data -->
      <section *ngIf="learnerDetails.learnerContactInformation.employerPhone || editMode && editPermissionLevel === 1">
        <div *ngIf="editPermissionLevel === 1">
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
            [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Employer Phone: </mat-label>
          <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
            <input matInput formControlName="employerPhone" >
          </mat-form-field>
        </div>
        <div *ngIf="editPermissionLevel !== 1">
          <learner-details-entry
            [labelName]="'Employer Phone'"
            [labelValue]="this.learnerDetails.learnerContactInformation.employerPhone"
            [inline]="true">
          </learner-details-entry>
        </div>
      </section>


      <!-- Leaving this out for now due to incorrect formatting of data and questions about if this is needed.
      Check buildForm in the .ts file. -->

      <!-- <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
        [ngStyle]="{'color': !editPermission2 ? 'darkred' : 'black' }">Emergency Contact: </mat-label>
      <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
        <input matInput formControlName="emergencyContact" [readonly]="editPermission2">
      </mat-form-field>

      <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
        [ngStyle]="{'color': !editPermission2 ? 'darkred' : 'black' }">Relationship to Learner: </mat-label>
      <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
        <input matInput formControlName="relationshipToLearner" [readonly]="editPermission2">
      </mat-form-field>

      <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
        [ngStyle]="{'color': !editPermission2 ? 'darkred' : 'black' }">Emergency Contact Number: </mat-label>
      <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}}">
        <input matInput formControlName="emergencyContactNumber" [readonly]="editPermission2">
      </mat-form-field> -->

    </div>
  </section>

  <!-- Additional Support Requirements -->
  <section class="section-container" fxLayout="column">
    <h3 class=dashboard_headers>Additional Support Requirements</h3>
    <div fxLayout="row wrap" fxLayoutGap="80px" flex-shrink="50px" id="additional-support" [style.margin-bottom.px]="15">
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.extendedTimeOffWork !== null &&
      learnerDetails.additionalSupportRequirements.extendedTimeOffWork !== '' &&
      learnerDetails.additionalSupportRequirements.extendedTimeOffWork !== '0'"
        [labelName]="'Extended time off work'"
        [labelValue]="learnerDetails.additionalSupportRequirements.extendedTimeOffWork">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.loneParent != null"
        [labelName]="'Lone parent'"
        [labelValue]="learnerDetails.additionalSupportRequirements.loneParent">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.disability != null"
        [labelName]="'Disability'"
        [labelValue]="learnerDetails.additionalSupportRequirements.disability">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.inCare != null"
        [labelName]="'In care'"
        [labelValue]="learnerDetails.additionalSupportRequirements.inCare">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.careLeaver != null"
        [labelName]="'Care leaver'"
        [labelValue]="learnerDetails.additionalSupportRequirements.careLeaver">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.yosSupport != null"
        [labelName]="'YOS support'"
        [labelValue]="learnerDetails.additionalSupportRequirements.yosSupport">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.exOffender != null"
        [labelName]="'Ex offender'"
        [labelValue]="learnerDetails.additionalSupportRequirements.exOffender">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.exOffenderRiskAssessment != null"
        [labelName]="'Ex-offender risk assessment'"
        [labelValue]="learnerDetails.additionalSupportRequirements.exOffenderRiskAssessment">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.accessRequirements != null"
        [labelName]="'Access requirements'"
        [labelValue]="learnerDetails.additionalSupportRequirements.accessRequirements">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.accessRequirementDetails != null"
        [labelName]="'Access requirement details'"
        [labelValue]="learnerDetails.additionalSupportRequirements.accessRequirementDetails">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.travelType != null"
        [labelName]="'Travel type'"
        [labelValue]="learnerDetails.additionalSupportRequirements.travelType">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.travelDetails != null"
        [labelName]="'Travel details'"
        [labelValue]="learnerDetails.additionalSupportRequirements.travelDetails">
      </learner-details-entry>
      <learner-details-entry *ngIf="learnerDetails.additionalSupportRequirements.additionalLearningNeeds != null"
        [labelName]="'Additional learning needs'"
        [labelValue]="learnerDetails.additionalSupportRequirements.additionalLearningNeeds">
      </learner-details-entry>
    </div>
    <div *ngIf="learnerDetails.learnerBasics.additionalInfo && editPermissionLevel === 1|| editMode && editPermissionLevel === 1">
      <mat-label style="font-weight: 500;"
        [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">Additional Information: </mat-label>
      <mat-form-field appearance="{{formFieldStyle}}" style="display: block;">
        <textarea matInput formControlName="additionalInfo"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="7">
        </textarea>
      </mat-form-field>
    </div>
    <div *ngIf="learnerDetails.learnerBasics.additionalInfo && editPermissionLevel !== 1">
      <learner-details-entry
        [labelName]="'Additional Information'"
        [labelValue]="learnerDetails.learnerBasics.additionalInfo">
      </learner-details-entry>
    </div>
  </section>


</article>
