import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-save-report-filters-dialog',
  templateUrl: './save-report-filters-dialog.component.html',
  styleUrls: ['./save-report-filters-dialog.component.scss']
})
export class SaveReportFiltersDialogComponentV2 implements OnInit {
  isSaveFiltersForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SaveReportFiltersDialogComponentV2>,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOK(): void {
    if (!this.isSaveFiltersForm.valid) {
      let snackBarMessage = "";
      if (this.isSaveFiltersForm.controls.name.errors.required) {
        snackBarMessage = "Please choose a name for this filter."
      } else if (this.isSaveFiltersForm.controls.name.errors.maxlength) {
        snackBarMessage = "The name you have chosen is too long."
      }
      this.snackBar.open(snackBarMessage,
        'Close', { duration: 5000 });
    } else {
      this.dialogRef.close(this.isSaveFiltersForm.value);
    }
  }

  buildForm() {
    this.isSaveFiltersForm = this.formBuilder.group({
      isSaveFilters: true,
      name: [null, [Validators.required, Validators.maxLength(70)]]
    });
  }
}