<div id="collapsible-wrapper" [style.padding-bottom]="show ? '50px' : '0px'">

  <div>
  <div class="top-container">

    <h2 class="collapsible-header">{{ name }}</h2>
    <div style="display: flex; flex-direction: row; gap: 15px;">
      <button class="collapsible-buttons" type="button" (click)="cancelEdit()" *ngIf="editMode" aria-label="Cancel">
        <mat-icon class="collapsible-icon">highlight_off</mat-icon>
      </button>
      <button class="collapsible-buttons" type="button" (click)="toggleEdit()" *ngIf="show && editPermissionLevel < 2" [attr.aria-label]="editMode ? 'Save' : 'Edit' ">
        <mat-icon class="collapsible-icon">{{editIconStyle}}</mat-icon>
      </button>
      <button class="collapsible-buttons" type="button" (click)="showHide()" *ngIf="!editMode" aria-label="Expand">
        <mat-icon class="collapsible-icon">{{iconType}}</mat-icon>
      </button>
    </div>
  </div>

  <ng-content></ng-content>

</div>

