import { Component, ViewChild, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { NO_DATA, ERROR_GETTING_DATA } from 'src/app/app.constants';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SearchService, SearchTableService, SearchType } from '../../search.service';
import { ISearchData } from 'src/app/shared/interfaces/search-data';
import { Router, NavigationExtras } from '@angular/router';


@Component({
  selector: 'app-search-virtual-scroll',
  templateUrl: './search-virtual-scroll.component.html',
  styleUrls: ['../search.component.scss'],
})
export class SearchVirtualScrollComponent {

  constructor(
    private searchService: SearchService,
    private searchTableService: SearchTableService,
    private cdf: ChangeDetectorRef,
    private router: Router
  ) {
    this.onResize();
  }

  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    let newColumSize = this.sideNavOpen ? 0 : 1;
    const screenWidth = window.innerWidth;

    if (screenWidth < 1130) {
      newColumSize += 1;
    } else if (screenWidth < 1450) {
      newColumSize += 2;
    } else if (screenWidth < 1780) {
      newColumSize += 3
    } else {
      newColumSize += 4;
    }

    if (this.columns !== newColumSize) {
      this.columns = newColumSize;
      this.setColumnBasedValues();
      if (this.withPlaceholders.length > 0) {
        this.rowChunking();
        this.checkIfMoreRowsNeeded();
        this.cdf.detectChanges();
      }
    }
  }

  @Output() isLoadingSearchChange = new EventEmitter<boolean>();
  @Output() applyFiltersButtonChange = new EventEmitter<boolean>();
  @Output() totalCountChange = new EventEmitter<number>();
  @Output() gettingMoreResultsChange = new EventEmitter<boolean>();

  @Input() categories: [{ label: string, value: string }];
  @Input() filterOptions: [{ [key: string]: { name: string, id: number }[] }];
  @Input() searchForm;
  @Input() filterForm;
  @Input() type: string;
  @Input() sideNavOpen = true;

  public noData = NO_DATA;
  public dataError = ERROR_GETTING_DATA;
  public columns = 2;
  public cardWidth = 322;
  public cardHeight = 222;
  public rowsPerPage = 4;
  public rowWidth = this.cardWidth * this.columns;
  public rows: { row: any[], id: number }[];
  public totalCount: number = null;

  public displayError = false;
  public types = SearchType;

  private submittedData: ISearchData;
  private withPlaceholders: any[] = [];
  private allResults: any[] = [];
  private maxRows = 0;
  private startNumber = (this.columns * (this.rowsPerPage * 3));
  private currentNumberOfRows = 0;
  private currentNumberOfResults = 0;
  private neededRows = 0;
  private canRequestMoreResults = true;
  private take = 0;

  public onSubmit(data) {
    this.submittedData = data;
    this.submittedData.paginationTake = this.startNumber;

    this.displayError = false;
    this.applyFiltersButtonChange.emit(false);
    this.isLoadingSearchChange.emit(true);
    this.canRequestMoreResults = false;
    this.viewport.scrollToIndex(0);
    this.rows = [];

    const subscription = this.searchService.search(this.submittedData).subscribe({
      next: (response: any) => {
        this.allResults = response.data.result;
        this.totalCount = response.data.totalCount;
        this.totalCountChange.emit(this.totalCount);
      },
      error: (err: any) => {
        console.log(err);
        this.isLoadingSearchChange.emit(false);
        this.displayError = true;

        // Saving it here would allow the user to refresh the page and keep the search
        this.searchTableService.setStoredParams(this.type, this.submittedData);
        const navigationExtras: NavigationExtras = {
          queryParams: { 'search': true },
          replaceUrl: true,
        };
        this.router.navigate([], navigationExtras);
        subscription.unsubscribe();
        this.endOfRequest();
      },
      complete: () => {
        this.currentNumberOfResults = this.allResults.length;
        const placeHolders = new Array(this.totalCount - this.currentNumberOfResults).fill({} as any);
        this.withPlaceholders = [...this.allResults, ...placeHolders];
        this.setColumnBasedValues();

        this.rowChunking();
        this.isLoadingSearchChange.emit(false);

        this.searchTableService.setStoredParams(this.type, this.submittedData);
        const navigationExtras: NavigationExtras = {
          queryParams: { 'search': true },
          replaceUrl: true,
        };
        this.router.navigate([], navigationExtras);
        subscription.unsubscribe();
        this.endOfRequest();
      }
    });
  }


  private getMoreResults() {
    if (!this.canRequestMoreResults || this.displayError) {
      return;
    }
    this.gettingMoreResultsChange.emit(true);
    this.canRequestMoreResults = false;

    this.submittedData.paginationSkip = this.currentNumberOfResults;
    this.submittedData.paginationTake = this.take;

    const subscription = this.searchService.search(this.submittedData).subscribe({
      next: (response: any) => {
        this.allResults = [...this.allResults, ...response.data.result];
        this.totalCount = response.data.totalCount;
      },
      error: (err: any) => {
        console.log(err);
        subscription.unsubscribe();
        // If there is an error, we need to reset the viewport
        this.viewport.setTotalContentSize(0);
        this.gettingMoreResultsChange.emit(false);
        this.displayError = true;
        this.endOfRequest();

      },
      complete: () => {
        this.currentNumberOfResults = this.allResults.length;
        const placeHolders = new Array(this.totalCount - this.currentNumberOfResults).fill({} as any);
        this.withPlaceholders = [...this.allResults, ...placeHolders];

        this.setColumnBasedValues();
        this.rowChunking();

        subscription.unsubscribe();
        this.isLoadingSearchChange.emit(false);
        this.endOfRequest();
      }
    });
  }

  private endOfRequest() {
    this.cdf.detectChanges();

    // Debounce the call to prevent multiple calls in quick succession
    // Also check if more rows are needed after the getting more and debounce
    setTimeout(() => {
      this.canRequestMoreResults = true;
      if (this.neededRows >= this.currentNumberOfRows) {
        this.checkIfMoreRowsNeeded();
      }
    }, 150);
  }

  private rowChunking(): void {
    const tempArray: { row: any[], id: number }[] = [];
    for (let i = 0, j = this.withPlaceholders.length; i < j; i += this.columns) {
      const row = this.withPlaceholders.slice(i, i + this.columns);
      const id = i;
      tempArray.push({ row: row, id: id });
    }
    this.rows = tempArray;
  }

  onScroll() {
    this.checkIfMoreRowsNeeded();
  }

  checkIfMoreRowsNeeded() {
    // How many rows do we currently need to fill the viewport plus buffer
    const scrollOffset = this.viewport.measureScrollOffset('top') + this.viewport.measureViewportSize('vertical');
    this.neededRows = Math.ceil(scrollOffset / this.cardHeight) + (this.rowsPerPage * 4);
    const rowTrigger = Math.min(this.maxRows, (this.currentNumberOfRows + this.rowsPerPage * 2));

    if (this.neededRows >= rowTrigger && this.currentNumberOfResults < this.totalCount) {
      const rowDifference = this.neededRows - this.currentNumberOfRows;
      this.take = (rowDifference + this.rowsPerPage) * this.columns;

      this.getMoreResults();

    }
  }

  //dynamicly change number of columns
  onSidenavOpenedChange(open: boolean) {
    if (open) {
      this.sideNavOpen = true;
      this.columns--;
    } else {
      this.sideNavOpen = false;
      this.columns++;
    }
    this.setColumnBasedValues();
    this.rowChunking();
    this.checkIfMoreRowsNeeded();

    this.cdf.detectChanges();
  }

  setColumnBasedValues() {
    this.maxRows = Math.ceil(this.totalCount / this.columns);
    this.startNumber = (this.columns * (this.rowsPerPage * 3));
    this.rowWidth = this.cardWidth * this.columns;
    this.currentNumberOfRows = Math.ceil(this.currentNumberOfResults / this.columns);
  }

  rowTrackBy(index: number, item: { row: any[], id: number }) {
    return item.id;
  }

}
