export enum SitePermissions {
  All = null,
  Admin = 1,
  AdminUser = 2,
  AdminPermission = 3,
  AdminPermissionArea = 4,
  AdminPermissionProfile = 53,
  SignUpEdit = 54,
  IqaCreateRecord = 55,
  IqaDeleteRecord = 56,
  IqaUpdateRecord = 57,
  IqaDeleteRequest = 58,
  EventAudit = 59,
  EventCreate = 60,
  EventEdit = 61,
  EventDelete = 62,
  AttendanceEditFinancePaid = 63,
  ContactCreate = 64,
  ContactEdit = 65,
  ContactDelete = 66,
  EmployerEventCreate = 67,
  EmployerEventEdit = 68,
  EmployerEventDelete = 69,
  EmployerHealthAndSafetyEdit = 70,
  BdHomepageView = 71
}
