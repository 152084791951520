<h1 mat-dialog-title>Save Filters</h1>
<div mat-dialog-content>
    <form [formGroup]="isSaveFiltersForm" novalidate>
        <div *ngIf="isSaveFiltersForm.get('isSaveFilters').value">
            <mat-form-field  appearance="outline" style="width: 100%">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions style="justify-content: end;">
    <button mat-stroked-button color="primary"(click)="onNoClick()">Cancel</button>
    <button  mat-flat-button color="primary"(click)="onOK()" cdkFocusInitial >Save</button>
</div>
