import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/services/auth-guard.service';
import { NotificationsComponent } from './notifications/notifications.component';



const routes: Routes = [

  { path: '', component: NotificationsComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class NotificationsRoutingModule { }
