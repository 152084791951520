import { Component } from "@angular/core";
import { ReportVariant } from "src/app/shared/enums/report-variant";

@Component({
  selector: 'app-suspension-submission-report',
  templateUrl: './suspension-submission-report.component.html',
})
export class SuspensionSubmissionReportComponent {

  reportName = "Suspension Submission Report";
  reportVariant = ReportVariant.SuspensionSubmission;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: [
        'potStatus',
        'monthYear',
        'fullname',
        'schemeShort',
        'sector',
        'assessor',
        'startDate',
        'expCompletionDate',
        'eventDate',
        'type',
        'comments',
        'staff',
        'employerName',
        'bdi',
        'ialpTimely',
        'ialpDone',
        'ialpCompliant',
        'lastReview',
        'ialpDate',
        'firstContact',
        'appWage',
        'suspensionEvidence',
        'progress',
        'laptop'
      ]
    }
  ];
  dateFilters = [
    'startDate',
    'expCompletionDate',
    'objectiveStatusDate',
    'eventDate'
  ];
  otherColumns = ['scheme', 'count'];

}
