<div class="file-drop-container">
  <img src="assets/dnd/download-file.png" width="72px" alt="">
  <h3>Select attachment for download</h3>
  <mat-spinner *ngIf="this.showSpinner"></mat-spinner>
  <ul *ngFor="let file of fileList">
    <li *ngIf="data.isEmployer">
      <a mat-button (click)="downloadFileForEmployer(file)">{{file}}</a>
    </li>
  </ul>
</div>


<div style="float:right">
  <button mat-button (click)="close()" class="export-all" [style.width]="'auto'">Close</button>  <br>
</div>
