<mat-card class="authorised-absence-card">
  <h2 class="dashboard-titles" style="padding-left:0px;">Authorised Absence</h2>

  <div class="wrapper">

    <div>
      <mat-form-field style="width: 100%" appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <input #txtVal matInput placeholder="Start typing to jump to a learner" (keyup)="applyFilter(txtVal.value)"
          autocomplete="off">
        <mat-slide-toggle [checked]="onlyMyLearnerStatus" (change)="toggleOnlyMyLearnerStatus($event)" matSuffix>Only My
          Learners</mat-slide-toggle>
      </mat-form-field>
    </div>

    <div> <!--  style="max-height: 52%; overflow-y: auto;"> -->
      <table mat-table [dataSource]="dataSource" matSort class="attendance-approval-table">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style="padding: 10px !important;">
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Assessor Column -->
        <ng-container matColumnDef="assessor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tutor</th>
          <td mat-cell *matCellDef="let element"> {{element.assessor}} </td>
        </ng-container>

        <!-- Centre Column -->
        <ng-container matColumnDef="centre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Centre</th>
          <td mat-cell *matCellDef="let element"> {{element.centre}} </td>
        </ng-container>

        <!-- Centre Column -->
        <ng-container matColumnDef="scheme">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Scheme</th>
          <td mat-cell *matCellDef="let element"> {{element.scheme}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No Results Found</td>
        </tr>

      </table>
      <mat-progress-bar *ngIf="showSpinner" mode="indeterminate"></mat-progress-bar>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons></mat-paginator>
    </div>


    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: baseline;">

      <div style="margin-left: 50px">
        <mat-form-field appearance="outline" [floatLabel]="'always'" style="width: 100px; margin-right: 10px">
          <mat-label>Start Time</mat-label>
          <input [(ngModel)]="startTime" matInput class="attendance-form-input" type="time" list="timeIncrements">
        </mat-form-field>

        <mat-form-field appearance="outline" [floatLabel]="'always'" style="width: 100px; margin-right: 30px">
          <mat-label>End time</mat-label>
          <input [(ngModel)]="endTime" matInput class="attendance-form-input" type="time" list="timeIncrements">
        </mat-form-field>

        <datalist id="timeIncrements">
          <option *ngFor="let i of timeIncrements" [value]="i">{{i}}</option>
        </datalist>

        <mat-form-field appearance="outline" [floatLabel]="'always'">
          <mat-label>Date Range</mat-label>
          <input type="text" style="text-align: center; cursor: pointer;" ngxDaterangepickerMd #datePicker matInput
            name="daterange" placeholder="Select date range" autocomplete="off" alwaysShowCalendars="true"
            [readonly]="true" [locale]="{applyLabel: 'ok', format: 'DD-MM-YYYY'}" [ranges]="ranges"
            [showClearButton]="true" [(ngModel)]="this.dateRange" opens="right" drops="up">
        </mat-form-field>
      </div>



      <mat-form-field appearance="outline" [floatLabel]="'always'" style="width: 30%">
        <mat-label>Comments</mat-label>
        <input matInput placeholder="Add any comments here." [(ngModel)]="comments" />
      </mat-form-field>


      <button class="mat-raised-button mat-primary submit-button" mat-button (click)="approveSelected()"
        [disabled]="!canAuthorise()">
        Authorise
      </button>

    </div>
  </div>
</mat-card>