import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DATE_FORMAT } from 'src/app/app.constants';

@Component({
  selector: 'current-programme',
  templateUrl: './current-programme.component.html',
  styleUrls: ['./current-programme.component.css']
})
export class CurrentProgrammeComponent implements OnInit {

  @Output() currentProgrammeUpdate = new EventEmitter();
  @Input() currentProgramme: any;
  @Input() editPermissionLevel: number;

  show: boolean = false;

  editMode: boolean = false;
  formFieldStyle: string = 'none';
  currentProgrammeForm: UntypedFormGroup;
  nla: boolean;
  formattedAgreedStartDate: string;
  formattedEmpInc: string;
  formattedEmpIncDis: string;

  dateFormat = DATE_FORMAT;

  constructor(
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {  }

  ngOnInit(): void {
    this.formattedAgreedStartDate = this.currentProgramme.agreedStartDate ? this.datePipe.transform(this.currentProgramme.agreedStartDate, this.dateFormat) : '-';
    this.buildForm();
    this.formatEmpInc();
    this.formatEmpIncDis();
  }

  formatEmpInc() {
    switch(this.currentProgramme.empInc) {
      case '1':
        this.formattedEmpInc = '£3000: Aged 16-24, 30+ hours p/w';
        break;
      case '2':
        this.formattedEmpInc = '£1500: Aged 16-24, 16-29 hours p/w';
        break;
      case '3':
        this.formattedEmpInc = '£2000: Aged 25+, 30+ hours p/w';
        break;
      case '4':
        this.formattedEmpInc = '£1000: Aged 25+, 16-29 hours p/w';
        break;
      default:
        this.formattedEmpInc = 'N/A';
        break;
    }
  }

  formatEmpIncDis() {
    switch(this.currentProgramme.empIncDis) {
      case '5':
        this.formattedEmpIncDis = '£2600: Aged 16+, 30+ hours p/w';
        break;
      case '6':
        this.formattedEmpIncDis = '£1300: Aged 16+, 16-29 hours p/w';
        break;
      case '7':
        this.formattedEmpIncDis = '£1500: Identified as disabled during recruitment process';
        break;
      default:
        this.formattedEmpIncDis = 'N/A';
        break;
    }
  }

  display(value) {
    this.show = value;
    this.changeDetectorRef.detectChanges();
  }

  buildForm() {
    this.nla = this.currentProgramme.nla === '-1' ? true : false;
    this.currentProgrammeForm = this.fb.group({
      'businessDevelopmentAdvisor': new UntypedFormControl({value: this.currentProgramme.businessDevelopmentAdvisor, disabled: true}),
      'businessDevelopmentSource':  new UntypedFormControl({value: this.currentProgramme.businessDevelopmentSource, disabled: true}),
      'agreedStartDate': new UntypedFormControl({value: this.currentProgramme.agreedStartDate, disabled: true}),
      'empInc': new UntypedFormControl({value: this.currentProgramme.empInc, disabled: true}),
      'empIncDis': new UntypedFormControl({value: this.currentProgramme.empIncDis, disabled: true}),
      'nla': new UntypedFormControl({value: this.nla, disabled: true}),
  });
  this.changeDetectorRef.detectChanges();
}


saveData() {
  if (this.editMode && this.currentProgrammeForm.dirty) {
    let returnData = { values: this.currentProgrammeForm.value, operation: 'update' };
    // Stops you from sending dates thare not valied to the api and replaces it with the original date
    if(this.currentProgrammeForm.get('agreedStartDate').hasError('matDatepickerParse')) {
      returnData.values.agreedStartDate = this.currentProgramme.agreedStartDate;
    }
    this.currentProgrammeUpdate.emit({ 'currentProgrammeUpdate': returnData });
    this.currentProgrammeForm.markAsUntouched();
    this.currentProgrammeForm.markAsPristine();
  }
}

changeEditMode(editMode) {
    this.editMode = editMode;
    this.formFieldStyle = this.editMode ? 'standard' : 'none';

    if( this.editMode ) {
      this.currentProgrammeForm.enable()
    } else {
      this.currentProgrammeForm.disable()
    };

    this.changeDetectorRef.detectChanges();
  }


  cancelEdit() {
    this.editMode = false;
    this.formFieldStyle = 'none';
    this.buildForm();
  }

}
