
<article class="main-article" *ngIf="show" fxLayout="row wrap" fxLayoutGap="100px" style="padding-left: 25px;"
[formGroup]="jgwForm" class="jgw-form">

  <section class="section-container" fxLayout="row wrap" fxLayoutGap="100px" >

    <div fxLayout="column">
      <section class="section-container" fxLayout="column" fxLayoutGap="15px">

        <!-- Agreed Start Date -->
        <div>
          <div *ngIf="editPermissionLevel === 1">
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
              [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
              Agreed Start Date:
            </mat-label>
            <br>
            <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
              <input matInput readonly
                class="date-input"
                [matDatepicker]="pickerAgreedStartDate"
                (click)="pickerAgreedStartDate.open()"
                formControlName="AgreedStartDate">
              <mat-datepicker #pickerAgreedStartDate></mat-datepicker>
              <mat-error *ngIf="jgwForm.get('AgreedStartDate').hasError('matDatepickerParse')">
                  <span>Please enter a valid date</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="editPermissionLevel !== 1">
            <learner-details-entry
            [labelName]="'Agreed Start Date'"
            [labelValue]="jgwInfo.agreedStartDate">
            </learner-details-entry>
          </div>
        </div>

        <!-- Sector -->
        <div>
          <learner-details-entry
            [labelName]="'Sector'"
            [labelValue]="sectorName">
          </learner-details-entry>
        </div>

        <!-- Centre -->
        <div>
          <div *ngIf="editMode && editPermissionLevel === 1; else centre">
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }" >
              Centre:
            </mat-label>
            <br>
            <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}" >
              <mat-select formControlName="Centre">
                <mat-option *ngFor="let centre of centreOptions" [value]="centre.centre">
                  {{centre.centre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-template #centre>
            <div style="height: 40px;">
              <learner-details-entry
                [labelName]="'Centre'"
                [labelValue]="centreName">
              </learner-details-entry>
            </div>
          </ng-template>
        </div>

        <div>
          <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
            Discussion:
          </mat-label>
          <br>
          <textarea *ngIf="editMode && editPermissionLevel === 1; else discussion"
            style="width: 300px"
            readonly
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="7"
            id="discussion" (click)="openDiscussion()"
          >{{discussionHtmlToText(this.jgwInfo.discussion)}}</textarea>
          <ng-template #discussion>
            <div class="discussion-text">{{discussionHtmlToText(this.jgwInfo.discussion)}}</div>
          </ng-template>
        </div>

      </section>

      <section class="section-container" fxLayout="column" fxLayoutGap="15px">

        <h3 class="dashboard_headers">Pre Start Details</h3>

        <!-- Meeting Date -->
        <div>
          <div *ngIf="editPermissionLevel === 1">
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
              [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
              Meeting Date:
            </mat-label>
            <br>
            <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
              <input matInput readonly
                class="date-input"
                [matDatepicker]="pickerPSMDate"
                (click)="pickerPSMDate.open()"
                formControlName="PSMDate"
              >
              <mat-datepicker #pickerPSMDate></mat-datepicker>
              <mat-error *ngIf="jgwForm.get('PSMDate').hasError('matDatepickerParse')">
                  <span>Please enter a valid date</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="editPermissionLevel !== 1">
            <learner-details-entry
            [labelName]="'Meeting Date'"
            [labelValue]="jgwInfo.psmDate">
            </learner-details-entry>
          </div>
        </div>

        <!-- Meeting Time -->
        <div>
          <div *ngIf="editPermissionLevel === 1">
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
              [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
              Meeting Time:
            </mat-label>
            <br>
            <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
                <input type="time" min="09:00" max="18:00" matInput formControlName="PSMTime">
            </mat-form-field>
          </div>
          <div *ngIf="editPermissionLevel !== 1">
            <learner-details-entry
            [labelName]="'Meeting Time'"
            [labelValue]="jgwInfo.psmTime">
            </learner-details-entry>
          </div>
        </div>

        <!-- Meeting location -->
        <div>
          <div *ngIf="editMode && editPermissionLevel === 1; else meetingLocation">
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;" [ngStyle]="{'color': editMode ? 'darkred' : 'black' }" >
              Meeting location:
            </mat-label>
            <br>
            <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}" >
              <mat-select formControlName="PSMLocation">
                <mat-option *ngFor="let centre of centreOptions" [value]="centre.centreId">
                  {{centre.centre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-template #meetingLocation>
            <div style="height: 40px;">
              <learner-details-entry
                [labelName]="'Meeting location'"
                [labelValue]="psmLocationName">
              </learner-details-entry>
            </div>
          </ng-template>
        </div>


        <!-- Expected Start Date -->
        <div>
          <div *ngIf="editPermissionLevel === 1">
            <mat-label style="font-weight: 500; padding-bottom: 0.5em;"
              [ngStyle]="{'color': editMode ? 'darkred' : 'black' }">
              Expected Start Date:
            </mat-label>
            <br>
            <mat-form-field class="mat-form-field-no-padding" appearance="{{formFieldStyle}}">
              <input matInput readonly
                class="date-input"
                [matDatepicker]="pickerExpStartDate"
                (click)="pickerExpStartDate.open()"
                formControlName="ExpectedStartDate">
              <mat-datepicker #pickerExpStartDate></mat-datepicker>
              <mat-error *ngIf="jgwForm.get('ExpectedStartDate').hasError('matDatepickerParse')">
                  <span>Please enter a valid date</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="editPermissionLevel !== 1">
            <learner-details-entry
            [labelName]="'Expected Start Date'"
            [labelValue]="jgwInfo.expStartDate">
            </learner-details-entry>
          </div>
        </div>

        <!-- Notification sent -->
        <learner-details-entry
        [labelName]="'Notification Sent'"
        [labelValue]="this.notificationSent ? this.notificationSent : '-' ">
        </learner-details-entry>
      </section>
    </div>

    <section class="section-container">

      <h3 class="dashboard_headers">Timetable</h3>

      <table style="margin-top:15px; border-collapse: collapse;">
        <tr>
          <th>Days</th>
          <th class="text-center">Attending</th>
          <th class="text-center">Hours</th>
        </tr>

        <tr *ngFor="let day of daysOfWeek">
          <td
            [style.color]="editMode && editPermissionLevel === 1 ? 'darkred' : '#000000'"
            [style.width.px]="95"
          >
            {{day}}
          </td>
          <td class="text-center">
            <div>
              <mat-checkbox *ngIf="editPermissionLevel === 1 && editMode"
                [style.color]="editMode ? 'darkred' : '#000000'"
                formControlName="TT{{day}}"
                (change)="dayToggled($event)"
                id="{{day}}">
              </mat-checkbox>
              <div *ngIf="editPermissionLevel !== 1 || !editMode">
                <img style="vertical-align: middle; width: 15px;"
                  [src]="this.jgwForm.get('TT'+day).value === 1 ? '../../../assets/images/icon-yes.png' : '../../../assets/images/icon-no.png'"
                  [alt]="this.jgwForm.get('TT'+day).value === 1 ? 'yes' : 'no'"/>
              </div>
            </div>
          </td>
          <td class="text-center" [style.width.px]="95">
            <mat-form-field class="text-center"
              id="hour-input"
              [style.width.px]="30"
              appearance="{{formFieldStyle}}">
              <input matInput
                     formControlName="TT{{day}}Hours"
                     (change)="updateTotalHours()" >
            </mat-form-field>
          </td>
        </tr>
      </table>

      <span [ngClass]="getColours()"> Total Hours: {{totalHours}}</span>

    </section>

  </section>

</article>
