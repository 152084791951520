<label class="body-text-title" style="font-weight: 550;" for="type">{{type}}:</label>
<div *ngIf="labelOneValue != null" >
  <label *ngIf="labelOneName != null  && labelOneName != undefined" for="name" class="body-text">{{labelOneName}}: </label>
  <span *ngIf="labelOneValue != null && labelOneValue != undefined" class="body-text">{{labelOneValue}}</span>
  <span *ngIf="labelOneValue == null || labelOneValue == undefined" class="body-text">-</span>
</div>

<div *ngIf="labelTwoValue != null">
  <label *ngIf="labelTwoName != null  && labelTwoName != undefined" for="name" class="body-text">{{labelTwoName}}: </label>
  <span *ngIf="labelTwoValue != null && labelTwoValue != undefined" class="body-text">{{labelTwoValue}}</span>
  <span *ngIf="labelTwoValue == null || labelTwoValue == undefined" class="body-text">-</span>
</div>
