import { Component, EventEmitter, Input, Output, SimpleChange, OnChanges, SimpleChanges } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { error } from 'protractor';
import { ERROR_GETTING_DATA } from 'src/app/app.constants';
import { any, get, select } from 'underscore';

@Component({
  selector: 'ng-select-override',
  templateUrl: './ng-select-override.component.html',
  styleUrls: ['./ng-select-override.component.scss']
})
export class NgSelectOverrideComponent implements OnChanges {

  @Input() items: any[] = [];
  @Input() placeholder: string;
  @Input() multipleSelect = false;
  @Input() displayNumber = 3;
  @Input() setModel: any[];
  @Input() isLoading = false;
  @Input() error = false;

  @Output() onChange = new EventEmitter();

  _model: any;

  get model(): any {
    return this._model;
  }

  set model(value: any) {
    this._model = value;
    this.onChange.emit(value);
  }

  dataError = ERROR_GETTING_DATA;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.setModel) {
      this._model = changes.setModel.currentValue;
    }
  }

  ngSelectOpenOrClose(select: NgSelectComponent) {
    select.searchable = select.isOpen;
  }

  generateMoreTooltipText(): string {
    return this.model.slice(this.displayNumber).map(item => item.name).join(', ');
  }

}
