<div mat-dialog-content>
  <div class="select-container">
    <div class="top-text">Employer to create event for:</div>
    <form novalidate autocomplete="off" [formGroup]="searchForm" style="margin-left: 125px;">
      <mat-form-field style="width:calc(80% - 140px); min-width: 250px;" appearance="fill" class="search-type-external">
        <input matInput style="min-width: 50%;" placeholder="Select employer" value="" formControlName="searchText"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='selectOption($event)'
          [displayWith]="displayFnEmployer">
          <mat-option *ngFor="let option of options | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" class="button" (click)="cancelOperation()">Cancel</button>
</div>
