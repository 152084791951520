import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MaterialsModule } from '../core/materials/materials.module';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationsReportComponent } from './applications-report/applications-report.component';
import { SidebarModule } from 'ng-sidebar';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedFiltersComponent } from './shared-filters/shared-filters.component';
import { ReportStoredFilterHelperService } from './report-stored-filter-helper.service';
import { ShareReportFiltersDialogComponent } from './share-report-filters-dialog/share-report-filters-dialog.component';
import { ReportsService } from './reports.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { RecentFilterDialogComponent } from './recent-filter-dialog/recent-filter-dialog.component';
import { ShowSavedReportFiltersDialogComponent } from './show-saved-report-filters-dialog/show-saved-report-filters-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { GCHDiscrepancyReportComponent } from './gch-discrepancies-report/gch-discrepancies-report.component';
import { AppRoutingModule } from '../app.routing';
import { RouterModule } from '@angular/router';
import { BasicReportComponent } from './basic-report/basic-report.component';
import { MatTableResponsiveModule } from '../core/directives/mat-table-responsive.module';
import { SaveReportFiltersDialogComponent } from './save-report-filters-dialog/save-report-filters-dialog.component';
import { ReportSummaryGraphComponent } from './report-summary-graph/report-summary-graph.component';

@NgModule({
  declarations: [
    SaveReportFiltersDialogComponent,
    ApplicationsReportComponent,
    SharedFiltersComponent,
    ShareReportFiltersDialogComponent,
    RecentFilterDialogComponent,
    ShowSavedReportFiltersDialogComponent,
    GCHDiscrepancyReportComponent,
    BasicReportComponent,
    ReportSummaryGraphComponent

  ],
  imports: [
    MatTableResponsiveModule,
    CommonModule,
    MaterialsModule,
    NgxGraphModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd,
    SidebarModule.forRoot(),
    NgSelectModule,
    HighchartsChartModule,
    MatMenuModule,
    RouterModule
  ],
  exports: [
    SaveReportFiltersDialogComponent,
    ApplicationsReportComponent,
    SharedFiltersComponent,
    ShareReportFiltersDialogComponent,
    GCHDiscrepancyReportComponent,
    ReportSummaryGraphComponent
  ],
  entryComponents: [ShareReportFiltersDialogComponent],
  providers: [ReportStoredFilterHelperService, ReportsService, DecimalPipe]
})
export class ReportingModule { }
