<div class="absence-to-authorise-details">
  <div>
    <mat-form-field style="width: 100%" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input #filterValue matInput placeholder="Start typing to filter results" (keyup)="applyFilter(filterValue.value)"
        autocomplete="off" [(ngModel)]="this.filterString">
      <mat-slide-toggle [checked]="onlyMyLearnerStatus" (change)="toggleOnlyMyLearnerStatus($event)" matSuffix>Show Only
        My
        Learners</mat-slide-toggle>
    </mat-form-field>
  </div>
  <div class="reportTable" style="display: block; max-height: 550px; overflow: auto;">
    <table mat-table [dataSource]="this.tableDataSource" #tableSort="matSort" matSort
      matSortActive="{{this.matSortActive}}" matSortDirection="{{this.matSortDirection}}">
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
        <th mat-header-cell *matHeaderCellDef sticky>
          <span *ngIf="column!=='select'" mat-sort-header>
            {{this.sharedFunctions.unCamelCase(column)}}
          </span>
          <span *ngIf="column==='select'">
            <mat-checkbox [disabled]="this.tableDataSource.filteredData.length===0" (change)="masterToggle($event)"
              [checked]="masterCheckbox">
            </mat-checkbox>
            {{this.tableDataSource.filteredData.length===0?"Select All On Page":isAllSelected() ? 'Deselect All On Page'
            :
            'Select All On Page'}}
          </span>
        </th>

        <td mat-cell *matCellDef="let cell" style="text-align: center;">
          <span *ngIf="column=='info'" matTooltip="{{cell[column]}}">
            <mat-icon>info</mat-icon>
          </span>
          <span *ngIf="column=='name'">
            <!--<a class="export-all" style="text-decoration: underline;"
              (click)="loadLearner(cell['traineeId'], cell['pot'])"> {{cell[column]}} </a>-->
            <a routerLink="/learner/dashboard/{{cell['traineeId']}}/{{cell['pot']}}">{{formatForEmpty(cell[column])}}
            </a>
          </span>
          <span *ngIf="column!='name' && column!='info' && column!='select' && column!=' '">
            {{this.sharedFunctions.isDateFromColumnName(cell[column], column)?(cell[column] | date:
            'dd/MM/y'):isPercentage(cell[column], column) ? (cell[column] * 100).toFixed(0) + '%':
            stringHelper.formatForEmpty(cell[column]) }}
          </span>

          <span *ngIf="column=='select'">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="selectedItem($event,cell)"
              [checked]="this.selection.isSelected(cell)">
            </mat-checkbox>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="displayedColumns.length" style="text-align: center;"
          [attr.colspan]="this.displayedColumns.length">
          {{
          showSpinner ? 'Loading...':
          errorText ? errorText :'No Results'}}
        </td>
      </tr>
    </table>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
  <mat-paginator #tablePaginator [length]="totalData" [pageSizeOptions]="pageOptions" showFirstLastButtons="true">
  </mat-paginator>
  <div class="absence-to-authorise-details-button">
    <button [disabled]="canAuthorise()" mat-button mat-raised-button color="primary"
      (click)="openDialog()">{{fullSelection.length>0?'Authorise ' + fullSelection.length +' Learners'
      :'Authorise'}}</button>
  </div>
</div>