import { ConditionalExpr, ParseSourceSpan } from '@angular/compiler';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { type } from 'os';
import * as _ from 'underscore';

@Component({
  selector: 'app-shared-filters',
  templateUrl: './shared-filters.component.html',
  styleUrls: ['./shared-filters.component.scss']
})
export class SharedFiltersComponent implements OnInit {
  @Output() setFilterEmitter = new EventEmitter<any>();
  @Output() toggleOnlyActiveLearners = new EventEmitter<any>();
  @Input() assessorOptions;
  @Input() centreOptions: any[];
  @Input() contractOptions: any[];
  @Input() subcontractorOptions: any[];
  @Input() programmeTypeOptions: any[];
  @Input() schemeOptions: any[];
  @Input() sectorOptions: any[];
  @Input() hasActiveLearnerFilter: boolean;
  @Input() multiSelectIsEnabled: boolean = true;

  onlyActiveLearners: boolean = true;
  selectedAssessor;
  selectedCentre;
  selectedSector;
  selectedScheme;
  selectedContract;
  selectedSubcontractor;
  selectedProgrammeType;


  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

  }



  reset() {
    this.onlyActiveLearners = true;
    this.selectedCentre = null;
    this.selectedAssessor = null;
    this.selectedSector = null;
    this.selectedScheme = null;
    this.selectedContract = null;
    this.selectedSubcontractor = null;
    this.selectedProgrammeType = null;
  }


  toggleOnlyActiveSearch(){
    this.toggleOnlyActiveLearners.emit(this.onlyActiveLearners);
  }

  setFilter(value, type) {

    // console.log('set flter', value, type)

    if (type.includes('type')) {type.replace('type', 'Type'), console.log('type updated', type)};
    if (type.includes('id')){type.replace('id', 'Id'), console.log('type updated', type)};

    let filterNum;
    if(value){ filterNum = value.length; }
    let typeWithoutId = type.slice(0,-2);

    if (type == 'assessorId') {
      if(!this.multiSelectIsEnabled){
        if(this.selectedAssessor && this.selectedAssessor.assessorId) {
          value = this.selectedAssessor.assessorId;
        }
        else {
          this.assessorSelectCleared();
          value = -1
        }
      }
      else if (this.selectedAssessor.length != 0) {
        value = this.selectedAssessor.map(s => s.assessorId);
      }
      else {
        this.assessorSelectCleared();
        value = [0]
      }
    }
    else if (type == 'centreId') {
      if(!this.multiSelectIsEnabled){
        if(this.selectedCentre && this.selectedCentre.centreId) {
          value = this.selectedCentre.centreId;
        }
        else {
          this.centreSelectCleared();
          value = -1
        }
      }
      else if (this.selectedCentre.length != 0) {
        value = this.selectedCentre.map(s => s.centreId);
      }
      else {
        this.centreSelectCleared();
        value = [0]
      }
    }
    else if (type == 'sectorId') {
      if(!this.multiSelectIsEnabled){
        if(this.selectedSector && this.selectedSector.sectorId) {
          value = this.selectedSector.sectorId;
        }
        else {
          this.sectorSelectCleared();
          value = -1
        }
      }
      else if (this.selectedSector.length != 0) {
        value = this.selectedSector.map(s => s.sectorId);
      }
      else {
        this.sectorSelectCleared();
        value = [0]
      }
    }
    else if (type == 'schemeId') {
      if(!this.multiSelectIsEnabled){
        if(this.selectedScheme && this.selectedScheme.schemeId) {
          value = this.selectedScheme.schemeId
        }
        else {
          this.schemeSelectCleared();
          value = -1
        }
      }
      else if (this.selectedScheme.length != 0) {
        value = this.selectedScheme.map(s => s.schemeId);
      }
      else {
        this.schemeSelectCleared();
        value = [0]
      }
    }
    else if (type == 'programmeTypeId') {
      if(!this.multiSelectIsEnabled){
        if(this.selectedProgrammeType && this.selectedProgrammeType.programmeTypeId) {
          value = this.selectedProgrammeType.programmeTypeId
        }
        else {
          this.programmeTypeSelectCleared();
          value = -1
        }
      }
      else if (this.selectedProgrammeType.length != 0) {
        value = this.selectedProgrammeType.map(s => s.programmeTypeId);
      }
      else {
        this.programmeTypeSelectCleared();
        value = [0]
      }
    }
    else if (type == 'subcontractorId') {
      if(!this.multiSelectIsEnabled){
        if(this.selectedSubcontractor && this.selectedSubcontractor.subcontractorId) {
          value = this.selectedSubcontractor.subcontractorId;
        }
        else {
          this.subcontractorSelectCleared();
          value = -1
        }
      }
      else if (this.selectedSubcontractor.length != 0) {
        value  = this.selectedSubcontractor.map(s => s.subcontractorId)
      }
      else {
        this.subcontractorSelectCleared();
        value = [0]
      }
    }
    else if (type == 'contractId') {
      if(!this.multiSelectIsEnabled){
        if(this.selectedContract && this.selectedContract.contractId) {
          value = this.selectedContract.contractId;
        }
        else {
          this.contractSelectCleared();
          value = -1
        }
      }
      else if (this.selectedContract.length != 0) {
        value = this.selectedContract.map(s => s.contractId);
      }
      else {
        this.contractSelectCleared();
        value = [0]
      }
    }

    var output = { 'type': type, 'value': value }
    // console.log('about to emit', output);
    this.setFilterEmitter.emit(output);
    // todo. subcontractor filter using contains.
  }

  remove(chip) {
    let filterIdx = 0;
    switch (chip.type) {
      case 'contract':
        filterIdx = this.selectedContract.findIndex(x => x.contract === chip.selection);
        this.selectedContract = [...this.selectedContract.slice(0, filterIdx), ...this.selectedContract.slice(filterIdx + 1)];
        break;
      case 'subcontractor':
        filterIdx = this.selectedSubcontractor.findIndex(x => x.subcontractor === chip.selection);
        this.selectedSubcontractor = [...this.selectedSubcontractor.slice(0, filterIdx), ...this.selectedSubcontractor.slice(filterIdx + 1)];
        break;
      case 'programmeType':
        filterIdx = this.selectedProgrammeType.findIndex(x => x.programmeType === chip.selection);
        this.selectedProgrammeType = [...this.selectedProgrammeType.slice(0, filterIdx), ...this.selectedProgrammeType.slice(filterIdx + 1)];
        break;
      case 'scheme':
        filterIdx = this.selectedScheme.findIndex(x => x.scheme === chip.selection);
        this.selectedScheme = [...this.selectedScheme.slice(0, filterIdx), ...this.selectedScheme.slice(filterIdx + 1)];
        break;
      case 'sector':
        filterIdx = this.selectedSector.findIndex(x => x.sector === chip.selection);
        this.selectedSector = [...this.selectedSector.slice(0, filterIdx), ...this.selectedSector.slice(filterIdx + 1)];
        break;
      case 'centre':
        filterIdx = this.selectedCentre.findIndex(x => x.centre === chip.selection);
        this.selectedCentre = [...this.selectedCentre.slice(0, filterIdx), ...this.selectedCentre.slice(filterIdx + 1)];
        break;
      case 'assessor':
        filterIdx = this.selectedAssessor.findIndex(x => x.assessor === chip.selection);
        this.selectedAssessor = [...this.selectedAssessor.slice(0, filterIdx), ...this.selectedAssessor.slice(filterIdx + 1)];
        break;
      default:
        // console.log('Not found');
        break;
      };
  }


  assessorSelectCleared() {
    this.selectedAssessor = [];
  }

  centreSelectCleared() {
    this.selectedCentre = [];
  }

  sectorSelectCleared() {
    this.selectedSector = [];
  }

  schemeSelectCleared() {
    this.selectedScheme = [];
  }

  programmeTypeSelectCleared() {
    this.selectedProgrammeType = [];
  }

  subcontractorSelectCleared() {
    this.selectedSubcontractor = [];
  }

  contractSelectCleared() {
    // this.selectedContract = [{ "contract": "< All contracts >", "contractId": 0 }];
    this.selectedContract = [];
  }

  getIsActiveLearnersOnly() {
    return this.onlyActiveLearners;
  }

  loadSavedFilters(optionSet: { [key: string]: any[] }, filters: string[]) { // todo think about load method. this seems to do it. No it didnt but I fixed it now.
    const optionsSet = {...optionSet};
    filters.forEach(filter => {
      var filterSplit = filter.split("::");
      if (filterSplit[1] !== "0") {

        if (filterSplit[0] === "selectedAssessor") {
          this.selectedAssessor = this.getSavedSelection('assessorId', filterSplit[1], optionsSet.assessorFilters, false);
          this.setFilterEmitter.emit( {'type': "assessorId", 'value': this.selectedAssessor.map(s => s.assessorId)} );
        }
        else if (filterSplit[0] === "selectedCentre") {
          this.selectedCentre = this.getSavedSelection('centreId', filterSplit[1], optionsSet.centreFilters);
          this.setFilterEmitter.emit( {'type': "centreId", 'value': this.selectedCentre.map(s => s.centreId)} );
        }
        else if (filterSplit[0] === "selectedSector") {
          this.selectedSector = this.getSavedSelection('sectorId', filterSplit[1], optionsSet.sectorFilters);
          this.setFilterEmitter.emit( {'type': "sectorId", 'value': this.selectedSector.map(s => s.sectorId)} );
        }
        else if (filterSplit[0] === "selectedScheme") {
          this.selectedScheme = this.getSavedSelection('schemeId', filterSplit[1], optionsSet.schemeFilters);
          this.setFilterEmitter.emit( {'type': "schemeId", 'value': this.selectedScheme.map(s => s.schemeId)} );
        }
        else if (filterSplit[0] == "selectedContract") {
          this.selectedContract = this.getSavedSelection('contractId', filterSplit[1], optionsSet.contractFilters);
          this.setFilterEmitter.emit( {'type': "contractId", 'value': this.selectedContract.map(s => s.contractId)} );
        }
        else if (filterSplit[0] == "selectedSubcontractor") {
          this.selectedSubcontractor = this.getSavedSelection('subcontractorId', filterSplit[1], optionsSet.subcontractorFilters);
          this.setFilterEmitter.emit( {'type': "subcontractorId", 'value': this.selectedSubcontractor.map(s => s.subcontractorId)});
        }
        else if (filterSplit[0] == "selectedProgrammeType") {
          this.selectedProgrammeType = this.getSavedSelection('programmeTypeId', filterSplit[1], optionsSet.programmeFilters);
          this.setFilterEmitter.emit({'type': "programmeTypeId", 'value': this.selectedProgrammeType.map(s => s.programmeTypeId)});
        }
        else if (filterSplit[0] == "isOnlyActiveLearners") {
          this.onlyActiveLearners = filterSplit[1] === 'false' ? false : true;
          if(!this.onlyActiveLearners) {
            this.toggleOnlyActiveLearners.emit(this.onlyActiveLearners);
          }
        }
      }
    });
  }

  getSavedSelection(type: string, filterString: string, optionSet: any[], isNum = true) {
    const stringsIds = filterString.split(',');
    const numberIds = isNum ? stringsIds.map(string => parseInt(string)) : null;
    return optionSet.filter( item  => isNum ? numberIds.indexOf(item[type]) !== -1
                                            : stringsIds.indexOf(item[type]) !== -1);
  }



  getFiltersAsString(): string {
    let sharedFilterString = "";
    if(!this.onlyActiveLearners){
      sharedFilterString += "isOnlyActiveLearners::" + this.onlyActiveLearners + ";"
    }
    if (this.selectedAssessor) {
      sharedFilterString += "selectedAssessor::" + this.selectedAssessor.map(s => s.assessorId) + ";"
    }
    if (this.selectedCentre) {
      sharedFilterString += "selectedCentre::" + this.selectedCentre.map(s => s.centreId) + ";"
    }
    if (this.selectedSector) {
      sharedFilterString += "selectedSector::" + this.selectedSector.map(s => s.sectorId) + ";"
    }
    if (this.selectedScheme) {
      sharedFilterString += "selectedScheme::" + this.selectedScheme.map(s => s.schemeId) + ";"
    }
    if (this.selectedContract) {
      sharedFilterString += "selectedContract::" + this.selectedContract.map(s => s.contractId) + ";"
    }
    if (this.selectedSubcontractor) {
      sharedFilterString += "selectedSubcontractor::" + this.selectedSubcontractor.map(s => s.subcontractorId) + ";"
    }
    if (this.selectedProgrammeType) {
      sharedFilterString += "selectedProgrammeType::" + this.selectedProgrammeType.map(s => s.programmeTypeId) + ";"
    }
    return sharedFilterString;
  }

}
