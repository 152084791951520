<article>
  <ng-template #spinner>
    <div *ngIf="!this.peopleLoaded; else noPeople">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <!-- No data retrived from api -->
  <ng-template #noPeople>
    <section>
      <mat-card class="dashboard-card" style="display: flex; align-items: center;">
        <p style="text-align: center; flex-grow: 1;">
          {{ this.noData }}
        </p>
      </mat-card>
    </section>
  </ng-template>

  <div *ngIf="this.peopleLoaded && this.cardsData !== null; else spinner">
    <!--
      Check to see if we get any cards to display.
      RouteManager cards are hidden if you are act JGW, so even if we have some data we still may display noPeople
    -->
    <div *ngIf="this.cardsData.assessor
      || this.cardsData.iqa
      || (this.cardsData.routeManager && this.cardsData.routeManager !== ' ' && this.cardsData.subcontractorId === 1 && this.cardsData.programmeType !== 'JGW+')
      || this.cardsData.manager
      || this.cardsData.businessDevelopmentAdvisor; else noPeople"
    >

      <section class="mat-card-container" *ngIf="this.cardsData.assessor">
        <mat-card class="dashboard-card">
          <div style="display: flex; flex-direction: row; justify-content: space-between; line-height: 13px;">
            <h3 class="people-card-title" style="margin: 0;">Assessor</h3>
            <p style="font-size: 12px; cursor: pointer; color:#0000ee; margin: 0;" (click)="openAssessorHistoryDialog()">History</p>
          </div>
          <h2 class="people-card-name">
            {{this.cardsData.assessor}}
          </h2>
          <h3 class="people-card-allocation" *ngIf="this.cardsData.assessorAllocation">
            <img src="../../../assets/images/icon-assessor.png" class="copy-icon people-card-email">Allocation: {{this.cardsData.assessorAllocation | date : this.dateFormat}}
          </h3>
          <learner-details-send-message-entry
            [recipientName]="this.cardsData.assessor" [recipientEmail]="this.cardsData.assessorEmail"
            [senderName]="userName" [senderEmail]="userEmail">
          </learner-details-send-message-entry>
        </mat-card>
      </section>

      <section class="mat-card-container" *ngIf="this.cardsData.iqa">
        <mat-card class="dashboard-card">
          <h3 class="people-card-title">Latest IQA</h3>
          <h2 class="people-card-name">
            {{this.cardsData.iqa}}
          </h2>
          <!-- Currently have no extra info for IQA-->
          <!-- <h3 class="people-card-allocation">
            <img src="../../../assets/images/icon-assessor.png" class="copy-icon people-card-email">
          </h3> -->
          <learner-details-send-message-entry
            [recipientName]="this.cardsData.iqa" [recipientEmail]="this.cardsData.iqaEmail"
            [senderName]="userName" [senderEmail]="userEmail">
          </learner-details-send-message-entry>
        </mat-card>
      </section>


      <section class="mat-card-container" *ngIf="this.cardsData.routeManager
                                            && this.cardsData.routeManager !== ' '
                                            && this.cardsData.subcontractorId === 1
                                            && this.cardsData.programmeType !== 'JGW+'">
        <mat-card class="dashboard-card">
          <h3 class="people-card-title">Route Manager</h3>
          <h2 class="people-card-name">
            {{this.cardsData.routeManager}}
          </h2>
          <!-- Currently have no extra info for route manager -->
          <!-- <h3 class="people-card-allocation">
            <img src="../../../assets/images/icon-assessor.png" class="copy-icon people-card-email">
          </h3> -->
          <learner-details-send-message-entry
            [recipientName]="this.cardsData.routeManager" [recipientEmail]="this.cardsData.routeManagerEmail"
            [senderName]="userName" [senderEmail]="userEmail">
          </learner-details-send-message-entry>
        </mat-card>
      </section>

      <section class="mat-card-container" *ngIf="this.cardsData.manager">
        <mat-card class="dashboard-card large-card">
          <h3 class="people-card-title">Employer</h3>
          <h2 class="people-card-name">
            {{this.cardsData.manager}}<br>
            {{this.cardsData.managerCompany}}
          </h2>
          <h3 class="people-card-allocation" *ngIf="this.cardsData.managerPhone">
            <img src="../../../assets/images/icon-assessor.png" class="copy-icon people-card-email">{{this.cardsData.managerPhone}}
          </h3>
          <learner-details-send-message-entry
            [recipientName]="this.cardsData.manager" [recipientEmail]="this.cardsData.managerEmail"
            [senderName]="userName" [senderEmail]="userEmail">
          </learner-details-send-message-entry>
        </mat-card>
      </section>

      <section class="mat-card-container" *ngIf="this.cardsData.businessDevelopmentAdvisor">
        <mat-card class="dashboard-card large-card">
          <h3 class="people-card-title">Business Development Advisor</h3>
          <h2 class="people-card-name">
            {{this.cardsData.businessDevelopmentAdvisor}}<br>
            {{this.cardsData.businessDevelopmentSource}}
          </h2>
          <h3 class="people-card-allocation">
            <img src="../../../assets/images/icon-assessor.png" class="copy-icon people-card-email">Start Date: {{this.cardsData.startDate | date : this.dateFormat}}
          </h3>
          <learner-details-send-message-entry
            [recipientName]="this.cardsData.businessDevelopmentAdvisor" [recipientEmail]="this.cardsData.businessDevelopmentAdvisorEmail"
            [senderName]="userName" [senderEmail]="userEmail">
          </learner-details-send-message-entry>
        </mat-card>
      </section>
    </div>
  </div>
</article>
