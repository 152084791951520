<div class="container">
    <h2 class="progressTitle">Learners</h2>
    <div *ngIf="!hasLearners">
        No learner data is currently available.
    </div>
    <div *ngIf="hasLearners">
        <mat-form-field class="search-box" appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <input #txtVal matInput placeholder="Start typing to jump to a learner" (keyup)="applyFilter(txtVal.value)"
                autocomplete="off">
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{unCamelCase(column)}} </th>


                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="column=='fullName'"><a
                            routerLink="/learner/dashboard/{{element['traineeId']}}/{{element['pot']}}">
                            {{element[column]}} </a>
                        </span>
                        <span *ngIf="column=='startdate'">
                            {{element[column] | date: this.dateFormat}}
                            </span>
                        <span *ngIf="column!='fullName' && column!='startdate'">{{element[column] }}
                        </span>
                    </td>

            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator (page)="pageUpdated($event)" [pageSize]="6" [pageSizeOptions]="[6, 15, 50]" showFirstLastButtons>
        </mat-paginator>

    </div>
</div>
