import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Drilldown from 'highcharts/modules/drilldown';
import { SharedFunctionsService } from './../../core/services/shared-functions.service';
import { DATE_FORMAT } from 'src/app/app.constants';

More(Highcharts);
Drilldown(Highcharts);
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
// Initialize exporting module.
Exporting(Highcharts);

// with drilldown
export interface iPieChartEntryWithDrilldown {
  name: string;
  y: number;
  drilldown: string;
  id: string;
}

// only if bottom level of drilldown
export interface iPieChartDrilldownSeriesEntry {
  name: string;
  id: string;
  data: any[];
  type: string;
}


@Component({
  selector: 'app-report-summary-graph',
  templateUrl: './report-summary-graph.component.html',
  styleUrls: ['./report-summary-graph.component.scss']
})
export class ReportSummaryGraphComponentV2 implements OnInit {
  chartOptions = {
    chart: {
      plotBorderWidth: null,
      plotShadow: false,
      events: {
        drilldown: function (e) {
        },
        drillup: function (e) {
        }
      }
    },
    title: {
      text: 'Grouped by: ',
      align: 'left'
    },
    subtitle: {
      text: '',
      align: 'left'
    },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<b>{point.name}</b>: ({point.y}) <b>{point.percentage:.1f}%</b> of total<br/>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [],
    drilldown: {
      series: [], drillUpButton: {
        relativeTo: 'spacingBox',
        position: {
          y: 10,
          x: -45
        }
      }
    }
  };
  drillDownColumnTitle: string = "schemes";
  highcharts = Highcharts;
  isDrilledDownInPieChart: boolean = false;
  isShowPieChart: boolean = true;
  updateFlag: boolean = false;

  dateFormat = DATE_FORMAT;

  constructor(
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private sharedFunctions: SharedFunctionsService) { }

  ngOnInit(): void {
  }
  //#region pie chart setup
  setPieChartSummaryDataForBasicReport(dataSource2Data: any[], groupByColumn: any) {
    this.resetPieChart();
    const datepipe: DatePipe = new DatePipe('en-GB');
    const groupByColumnUserFriendly = this.sharedFunctions.unCamelCase(groupByColumn.value);
    const shareByColumnUserFriendly = this.sharedFunctions.unCamelCase(groupByColumn.pieChartShareValue);
    this.drillDownColumnTitle = this.sharedFunctions.unCamelCase(groupByColumn.pieChartDrillDown).toLowerCase() + "s";
    this.chartOptions.title.text = groupByColumnUserFriendly + " Share By " + shareByColumnUserFriendly;
    var dataSet = [];
    const pieChartEntries = [...new Set(dataSource2Data.map(item => item[groupByColumn.value]))];
    let pieChartDrilldownSeries: iPieChartDrilldownSeriesEntry[] = [];
    pieChartEntries.forEach(p => {
      var drilldownDataSet = [];
      let sum: number = 0;
      dataSource2Data.forEach(a => {
        if (a[groupByColumn.value] === p) {
          var propertyValue = this.replaceAll(a[groupByColumn.pieChartShareValue], ",", "");
          propertyValue = this.replaceAll(propertyValue, "%", "");
          sum += +propertyValue;
          drilldownDataSet.push([
            this.sharedFunctions.isDateFromColumnName(a[groupByColumn.pieChartDrillDown], groupByColumn.pieChartDrillDown)
              ? datepipe.transform(a[groupByColumn.pieChartDrillDown], this.dateFormat) : a[groupByColumn.pieChartDrillDown],
            +propertyValue
          ]);
        }
      });

      const drilldownId = p + "-" + this.drillDownColumnTitle;
      const pieChartEntry: iPieChartEntryWithDrilldown = {
        name: p,
        y: sum,
        drilldown: drilldownId,
        id: p
      };
      dataSet.push(pieChartEntry);

      let entry: iPieChartDrilldownSeriesEntry = {
        type: 'pie',
        name: p + " " + this.drillDownColumnTitle,
        id: drilldownId,
        data: drilldownDataSet
      }
      pieChartDrilldownSeries.push(entry);
    });

    this.chartOptions.series = [{
      type: 'pie',
      name: groupByColumnUserFriendly + ' share by ' + shareByColumnUserFriendly,
      data: dataSet
    }];

    var a = this;
    this.chartOptions.chart = {
      plotBorderWidth: null,
      plotShadow: false,
      events: {
        drilldown: function (e) {
          var segmentClicked = e.point.name
          a.drillDownClicked(segmentClicked);
        },
        drillup: function (e) {
          a.drillUpClicked();
        }
      }
    };
    this.chartOptions.drilldown.series = pieChartDrilldownSeries;
    this.updateFlag = true;
    this.cdr.detectChanges();
  }
  //#endregion

  //#region helper functions
  replaceAll(str, find, replace) {
    return str.toString().replace(new RegExp(find, 'g'), replace);
  }
  //#endregion

  //#region pie chart functions
  resetPieChart() {
    this.isShowPieChart = false;
    this.cdr.detectChanges();
    this.isShowPieChart = true;
    this.chartOptions.subtitle.text = ""
  }

  drillDownClicked(segmentClicked) {
    this.chartOptions.subtitle.text = segmentClicked + " " + this.drillDownColumnTitle
    this.updateFlag = true;
    this.cdr.detectChanges()
    var snackBarMessage = "Showing " + this.drillDownColumnTitle + " for " + segmentClicked
    this.snackBar.open(snackBarMessage,
      'Close', { duration: 3000 });
    this.isDrilledDownInPieChart = true;
  }

  drillUpClicked() {
    this.chartOptions.subtitle.text = ""
    this.updateFlag = true;
    this.cdr.detectChanges()
    this.isDrilledDownInPieChart = false;
  }
  //#endregion
}
