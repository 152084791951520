<h1 mat-dialog-title>Share Report</h1>
<div mat-dialog-content>
    <form [formGroup]="shareFiltersForm" novalidate>

        <div class="filter-name">
            <mat-label class=reviewFormTitle>Filter Name</mat-label><br />
            <mat-form-field appearance="outline">
                <input matInput formControlName="name">
            </mat-form-field>
        </div>
        <ng-select class="staff-select" #select [items]="staff | async" [multiple]="true" bindLabel="name"
            placeholder="Staff" formControlName="selectedStaff"></ng-select>


        <div>
            <mat-label>Would you also like to save these filters to your favourites?</mat-label>
        </div>
        <mat-checkbox style="margin-right: 10px;" formControlName="isSaveFilters">Save filters
        </mat-checkbox>


    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" (click)="onNoClick()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onOK()" cdkFocusInitial>Save</button>
</div>