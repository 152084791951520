import { Component, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedFunctionsService } from 'src/app/core/services/shared-functions.service';
import { LearnerAttendanceDetailsComponent } from './learner-attendance-details/learner-attendance-details.component';
import { LearnerPageComponent } from '../learner-page.component';
import { LearnerService } from '../learnerService.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DATE_FORMAT, NO_DATA } from 'src/app/app.constants';
import { UserTableService } from 'src/app/core/database/user-table.service';
import { SitePermissions } from 'src/app/shared/enums/permissions';

export interface IAttendance {
  name: string,
  value: number
}

export interface IGuageColor {
  name: string,
  value: string
}

@Component({
  selector: 'attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})


export class LearnerAttendanceComponent implements LearnerPageComponent {

  traineeId: string;
  pot: number;
  staffId: number;

  displayedColumns: string[] = ['week', 'timeAttended', 'timeDocked', 'percentage'];
  displayedColumnsHolidays: string[] = ['holidaysAccrued', 'holidaysTaken', 'holidaysBooked', 'holidaysRemaining'];

  latestWeeksGuages: {value: number, lable: string, weekId: number}[];

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  dataSourceHolidays: MatTableDataSource<any> = new MatTableDataSource([]);

  loadingWeekDetail: number = null;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  thresholdConfig = {
    '0': {color: '#a10514'},
    '50': {color: '#de9e36'},
    '75': {color: '#2ca607'}
  };

  loadedAttendance = false;

  attendanceWeekSummary: any;
  holidays: any;
  permanentNote: { note: string };
  permissions : { canEditFinancePaid : boolean } = { canEditFinancePaid : false };

  dataLoiadingFailedMessage : string = null;

  loadingReport = false;

  dateFormat = DATE_FORMAT;
  noData = NO_DATA;

  constructor (
     private route: ActivatedRoute,
      private cdr: ChangeDetectorRef,
      private dialog: MatDialog,
      public sharedFunctionsSercice: SharedFunctionsService,
      private learnerService: LearnerService,
      private snackBar: MatSnackBar,
      private userTableService: UserTableService,
      private router: Router
     ) {
  }

  getData(): void {
    this.getLearnerAttendance();
    this.userTableService.get(1)
    .then(user => {
      if (!user) {
          this.router.navigate(['']);
      }
        this.permissions = {
          canEditFinancePaid: user.permission.includes(SitePermissions.AttendanceEditFinancePaid)
      };
    });
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private checkForParamsDate() {

    // If route has a date then we need to open up the dialog modal with the correct week
    this.route.queryParams.subscribe(params => {
      if(params['date'] !== undefined) {

        const requestedWeek = this.dataSource.data.find(element => {
          const diffranceInDays = (new Date(params['date']).getTime()- new Date(element['week']).getTime()) / 86400000;
          return diffranceInDays > -1 && diffranceInDays < 7;
        });

        if(requestedWeek === undefined) {
          console.log('No week found for date:', params['date']);
          return;
        }
        this.getAttendanceDetails(requestedWeek);
      }
  });
  }

  getLearnerAttendance(){
    this.loadedAttendance = false;
    this.dataLoiadingFailedMessage = null;
    const subscription = this.learnerService.getLearnerAttendance(this.traineeId, this.pot).subscribe({
      next: (response) => {
        this.attendanceWeekSummary = response.attendanceWeeks;
        this.holidays = response.holidays;
        this.permanentNote = response.permanentNote;

        this.setGuages();
        this.dataSource.data = this.attendanceWeekSummary;
        this.dataSourceHolidays.data = this.holidays === null ? null : [this.holidays];
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.log('Error loading attendance:', error.error);
        this.dataLoiadingFailedMessage = 'Error loading attendance data. Please reload and try again. If this error persists, please contact systems support.';
        this.loadedAttendance = true;
        subscription.unsubscribe();
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadedAttendance = true;
        this.checkForParamsDate();
        subscription.unsubscribe();
        this.cdr.detectChanges();
      }
    });
  }

  setGuages() {
    this.latestWeeksGuages = [
      { value: this.attendanceWeekSummary[0]?.percentage * 100 ?? 0, lable: this.attendanceWeekSummary[0]?.week , weekId: this.attendanceWeekSummary[0]?.attendanceWeekId ?? null },
      { value: this.attendanceWeekSummary[1]?.percentage * 100 ?? 0, lable: this.attendanceWeekSummary[1]?.week , weekId: this.attendanceWeekSummary[1]?.attendanceWeekId ?? null },
      { value: this.attendanceWeekSummary[2]?.percentage * 100 ?? 0, lable: this.attendanceWeekSummary[2]?.week , weekId: this.attendanceWeekSummary[2]?.attendanceWeekId ?? null }
    ];
  }

  getAttendanceDetails(element) {
    if(this.loadingWeekDetail !== null) {
      return;
    }
    this.loadingWeekDetail = element.attendanceWeekId;
    const dialogData = [];

    this.learnerService.getLearnerAttendanceWeekDetails(element.attendanceWeekId).subscribe({
      next: (response) => {
        dialogData[0] = response;
        dialogData[0].permanentNote = this.permanentNote ? this.permanentNote ?? '' : null;
        dialogData[0].attendance = element.percentage;
        dialogData[0].allowance = element.payAllowance;
        dialogData[0].payTravel = element.payTravel;
        dialogData[0].mealDays = element.mealDays;
        dialogData[0].mealValue = element.mealValue;
        dialogData[0].canEditFinancePaid = this.permissions.canEditFinancePaid;
      },
      error: (error) => {
        console.log('Error loading attendance week details:', error.error);
        this.loadingWeekDetail = null;
        this.cdr.detectChanges();
      },
      complete: () => {
        console.log('Dialog data:', dialogData);
        this.loadingWeekDetail = null;
        this.cdr.detectChanges();
        this.openDialog(dialogData, element);
      }
    });
  }

  openDialog(data, element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "60vw";
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(LearnerAttendanceDetailsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData?.timesheetData !== undefined) {
        const data = formData['timesheetData'];
        element['timeAttendedHours'] = data['timeAttended'].hours;
        element['timeAttendedMinutes'] = data['timeAttended'].minutes;
        element['timeDockedHours'] = data['timeDocked'].hours;
        element['timeDockedMinutes'] = data['timeDocked'].minutes;
        element['percentage'] = data['attendance'];
        element['mealDays'] = data['mealDays'];

        //Set guages to match the new data
        const matchingGuage = this.latestWeeksGuages.find(guage => guage.weekId === element.attendanceWeekId) ?? null;
        if(matchingGuage) {
          matchingGuage.value = element.percentage * 100;
        }

      }

      if(formData?.financeData !== undefined) {
        this.permanentNote = formData.financeData.permanentNote;
        element.payTravel = formData.financeData.payTravel;
      }

      this.cdr.detectChanges();

    });
  }

  attendanceColor(attendance) {
    if(attendance > 0.75) {
      return this.thresholdConfig['75'].color;
    }
    else if(attendance > 0.5) {
      return this.thresholdConfig['50'].color;
    }
    else {
      return this.thresholdConfig['0'].color;
    }
  }

  openReport(name: string) {
    this.loadingReport = true;
    this.learnerService.getAttendanceReport(this.traineeId, this.pot, name).subscribe({
      next: (response) => {
        console.log('Report response:', response);
        const file = new Blob([this.base64ToArrayBuffer(response.bytes)], { type: 'application/pdf' });
        window.open(URL.createObjectURL(file));
      },
      error: (error) => {
        this.loadingReport = false;
        this.snackBar.open('Error loading attendance report. Please try again, if this error persists please contact systems support.', 'OK');
        console.log('Error loading attendance report:', error.error);
        this.cdr.detectChanges();
      },
      complete: () => {
        this.loadingReport = false;
        this.cdr.detectChanges();
      }
    });
  }

  base64ToArrayBuffer(base64: any): ArrayBuffer {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
