import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialsModule } from '../core/materials/materials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HomeAssessorComponent } from './home-dashboards/home-assessor/home-assessor.component';
import { HomeBusinessDevelopmentComponent } from './home-dashboards/home-business-development/home-business-development.component';
import { HomeMarketingComponent } from './home-dashboards/home-marketing/home-marketing.component';
import { HomeManagersComponent } from './home-dashboards/home-managers/home-managers.component';
import { HomeSmtComponent } from './home-dashboards/home-smt/home-smt.component';
import { HomeSystemsComponent } from './home-dashboards/home-systems/home-systems.component';
import { HomeGenericComponent } from './home-dashboards/home-generic/home-generic.component';
import { HomeAdminComponent } from './home-dashboards/home-admin/home-admin.component';
import { YourEventsComponent } from './home-dashboards-shared/your-events/your-events.component';
import { YourReportsComponent } from './home-dashboards-shared/your-reports/your-reports.component';
import { YourAssessorsComponent } from './home-dashboards-shared/your-assessors/your-assessors.component';
import { YourLearnersComponent } from './home-dashboards-shared/your-learners/your-learners.component';
import { DataCardComponent } from './home-dashboards-shared/data-card/data-card.component';
import { DataListComponent } from './home-dashboards-shared/data-list/data-list.component';
import { YourEmployersComponent } from './home-dashboards-shared/your-employers/your-employers.component';
import { HomeHealthAndSafetyComponent } from './home-dashboards/home-health-and-safety/home-health-and-safety.component';
import { ExpirationNotificationsComponent } from './home-dashboards/home-health-and-safety/expiration-notifications/expiration-notifications.component';
import { ReportCardComponent } from './home-dashboards-shared/report-card-caseload-progress/report-card.component';
import { DataSummaryCardComponent } from './home-dashboards-shared/data-summary-card/data-summary-card.component';
import { ReportCardReviewComplianceComponent } from './home-dashboards-shared/report-card-review-compliance/report-card-review-compliance.component';
import { AddEventFormComponent } from './home-dashboards-shared/your-events/add-event-form/add-event-form.component';
import { CreateMassLearnerEventModal } from './home-dashboards-shared/your-events/create-mass-learner-event-modal/create-mass-learner-event-modal.component';
import { YourEventInfoDialogComponent } from './home-dashboards-shared/your-events/your-event-info-dialog/your-event-info-dialog.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DataListDialogComponent } from './home-dashboards-shared/data-list/data-list-dialog/data-list-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContractWidgetTemplateComponent } from './contract-widgets/contract-widget-template-component';
import { WidgetDirective } from '../core/directives/widget.directive';
import { ContractWidgetService } from './contract-widgets/contract-widget.service';
import { AttendanceApprovalComponent } from './home-dashboards-shared/attendance-approval/attendance-approval.component';
import { AuthorisedAbsenceComponent } from './home-dashboards-shared/authorised-absence/authorised-absence.component';
import { ContractManagementComponent } from './home-dashboards-shared/contract-management/contract-management.component';
import { DateCalculatorComponent } from './home-dashboards-shared/date-calculator/date-calculator.component';
import { LearningAimEditorComponent } from './home-dashboards-shared/learning-aim-editor/learning-aim-editor.component';
import { LearningAimEditorFormComponent } from './home-dashboards-shared/learning-aim-editor/learning-aim-editor-form/learning-aim-editor-form.component';
import { LearningAimUnitsFormComponent } from './home-dashboards-shared/learning-aim-editor/learning-aim-units-form/learning-aim-units-form.component';
import { LearnerPayComponent } from './home-dashboards-shared/learner-pay/learner-pay.component';
import { AttendanceApprovalEditComponent } from './home-dashboards-shared/attendance-approval/attendance-approval-edit/attendance-approval-edit.component';
import { AttendanceAnalysisComponent } from './home-dashboards-shared/attendance-analysis/attendance-analysis.component';
import { DeliveryFundingPredictionsComponent } from './home-dashboards-shared/delivery-funding-predictions/delivery-funding-predictions.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LearnerPayImportComponent } from './home-dashboards-shared/learner-pay/learner-pay-import/learner-pay-import.component';
import { LearnerPayExportComponent } from './home-dashboards-shared/learner-pay/learner-pay-export/learner-pay-export.component';
import { ReviewsDueComponent } from './home-dashboards-shared/reviews-due/reviews-due.component';
import { IalpsDueComponent } from './home-dashboards-shared/ialps-due/ialps-due.component';
import { FavouriteLearnersComponent } from './home-dashboards-shared/favourite-learners/favourite-learners.component';
import { data } from '../learner/learner-dashboard/progress-summary/progress-summary.component';
import { VastDataExportComponent } from './home-dashboards-shared/vast-data-export/vast-data-expoer.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    HomeComponent,
    HomeGenericComponent,
    HomeAssessorComponent,
    HomeBusinessDevelopmentComponent,
    HomeMarketingComponent,
    HomeManagersComponent,
    HomeSmtComponent,
    HomeSystemsComponent,
    HomeAdminComponent,
    YourEventsComponent,
    YourReportsComponent,
    YourAssessorsComponent,
    YourLearnersComponent,
    DataCardComponent,
    DataListComponent,
    YourEmployersComponent,
    HomeHealthAndSafetyComponent,
    ExpirationNotificationsComponent,
    ReportCardComponent,
    DataSummaryCardComponent,
    ReportCardReviewComplianceComponent,
    AddEventFormComponent,
    CreateMassLearnerEventModal,
    YourEventInfoDialogComponent,
    DataListDialogComponent,
    ContractWidgetTemplateComponent,
    WidgetDirective,
    AttendanceApprovalComponent,
    AuthorisedAbsenceComponent,
    ContractManagementComponent,
    LearningAimEditorComponent,
    DateCalculatorComponent,
    LearningAimEditorFormComponent,
    LearningAimUnitsFormComponent,
    LearnerPayComponent,
    AttendanceApprovalEditComponent,
    AttendanceAnalysisComponent,
    DeliveryFundingPredictionsComponent,
    LearnerPayImportComponent,
    LearnerPayExportComponent,
    ReviewsDueComponent,
    IalpsDueComponent,
    FavouriteLearnersComponent,
    VastDataExportComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FlexLayoutModule,
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    MatAutocompleteModule,
    NgSelectModule,
    NgxDaterangepickerMd,
    ScrollingModule,
  ],
  providers: [
    ContractWidgetService
  ]
})
export class HomeModule { }
