import { Injectable } from "@angular/core";
import Dexie from "dexie";
import { LocalDb } from "./database.service";

@Injectable()
export class AttendanceTableService {
  table: Dexie.Table<any, number>;
  constructor(private localDb: LocalDb) {
    this.table = this.localDb.table('attendance');
  }
  getAuthorisedAbsence() {
    return this.table.get(1);
  }
  addAuthorisedAbsence(filterString: string, onlyMyLearners: boolean) {
    this.table.get(1).then((data) => {
      if (data === undefined) {
        return this.table.add({ filterString: filterString, onlyMyLearners: onlyMyLearners }, 1);
      }
      else {
        return this.table.update(1, { filterString: filterString, onlyMyLearners: onlyMyLearners });
      }
    })
  }
  getApproval() {
    return this.table.get(2);
  }
  addApproval(filterString: string, onlyMyLearners: boolean) {
    this.table.get(2).then((data) => {
      if (data === undefined) {
        return this.table.add({ filterString: filterString, onlyMyLearners: onlyMyLearners }, 2);
      }
      else {
        return this.table.update(2, { filterString: filterString, onlyMyLearners: onlyMyLearners });
      }
    });
  }
  getAnalysis() {
    return this.table.get(3);
  }
  addAnalysis(level: number, filterString: string, dateString: string, schemeString: string[], drillDownString: string, subContractorName: string, centreName: string) {
    this.table.get(3).then((data) => {
      if (data === undefined) {
        return this.table.add({
          level: level,
          filterString: filterString,
          dateString: dateString,
          schemeString: schemeString,
          drillDownString: drillDownString,
          subContractorName: subContractorName,
          centreName: centreName
        }, 3);
      }
      else {
        return this.table.update(3, {
          level: level,
          filterString: filterString,
          dateString: dateString,
          schemeString: schemeString,
          drillDownString: drillDownString,
          subContractorName: subContractorName,
          centreName: centreName
        });
      }
    });
  }
}
