import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';

@Component({
  selector: 'app-eight-week-term-report',
  templateUrl: './eight-week-term-report.html'
})
export class EightWeekTermReportComponent {

  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  name = 'Eight Week Term Report';
  reportVariant = ReportVariant.EightWeekTerms;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: [
        'traineeId',
        'outcome',
        'month',
        'fullName',
        'schemeShort',
        'sector',
        'assessor',
        'startDate',
        'expCompletionDate',
        'eventDate',
        'type',
        'comments',
        'staff',
        'employerName',
        'bdi',
        'timely',
        'ialp',
        'compliant',
        'category',
        'firstContact',
        'appWage'
      ]
    }
  ];
  defaultColumnOrder = [
    {
      type: 'default',
      columns: [
        'traineeId',
        'outcome',
        'month',
        'fullName',
        'schemeShort',
        'sector',
        'assessor',
        'startDate',
        'expCompletionDate',
        'eventDate',
        'type',
        'comments',
        'staff',
        'employerName',
        'bdi',
        'timely',
        'ialp',
        'compliant',
        'category',
        'firstContact',
        'appWage'
      ]
    }
  ];
  dateFilters = ['eventDate'];
}
