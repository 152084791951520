import { ChangeDetectorRef, Component, OnInit, Output, ViewChild, EventEmitter, Input, HostBinding } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-authorised-absence',
  templateUrl: './authorised-absence.component.html',
  styleUrls: ['./authorised-absence.component.scss']
})
export class AuthorisedAbsenceComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @Input() staffId: number;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('datePicker') datePicker;
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  @Output() attendanceUpdate = new EventEmitter();

  comments: string;
  selection = new SelectionModel<any>(true, []);
  dataSource = new MatTableDataSource();

  startTime: string;
  endTime: string;
  startDate: any;
  endDate: any;
  dateRange: { startDate: Date, endDate: Date } = { startDate: new Date(), endDate: new Date() };
  ranges: any;
  timeIncrements: string[] = [];

  displayedColumns: string[] = ['select', 'name', 'assessor', 'scheme', 'centre'];
  showSpinner = true;
  onlyMyLearnerStatus = true;

  rawData = [];

  constructor(
    private homeDashService: HomeDashboardService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.setRanges();
    this.setTimeIncrements();
    this.showSpinner = false;
    this.cdr.detectChanges();
  }

  setTimeIncrements() {
    var now = new Date();
    this.timeIncrements.push('08:00:00');

    for (var i = 1; i < 61; i++) {
      // add another hour every 6th iteration
      now.setHours(8 + i / 6);
      // add 10 minutes every iteration, starting back at 0 on the 6th
      now.setMinutes((i % 6) * 10);
      let hr = this.pad(now.getHours(), 2) + ':' + this.pad(now.getMinutes(), 2) + ':00';
      this.timeIncrements.push(hr);
    }
  }

  pad(val, max) {
    var str = val.toString();
    return str.length < max ? this.pad("0" + str, max) : str;
  }

  setRanges() {
    let monthOfYear = new Date().getMonth();
    let firstContractDay = moment();
    let lastContractDay = moment();
    let previousfirstContractDay = moment();
    let previouslastContractDay = moment();
    if (monthOfYear < 4) {
      firstContractDay.set({ year: moment().year() - 1, month: 3, date: 1 }); // moment months start counting at 0!
      lastContractDay.set({ year: moment().year(), month: 2, date: 31 });
      previousfirstContractDay.set({ year: moment().year() - 2, month: 3, date: 1 });
      previouslastContractDay.set({ year: moment().year() - 1, month: 2, date: 31 });
    } else {
      firstContractDay.set({ year: moment().year(), month: 3, date: 1 });
      lastContractDay.set({ year: moment().year() + 1, month: 2, date: 31 });
      previousfirstContractDay.set({ year: moment().year() - 1, month: 3, date: 1 });
      previouslastContractDay.set({ year: moment().year(), month: 2, date: 31 });
    }

    this.ranges = {
      'Last 14 Days': [moment().subtract(14, 'days'), moment()],
      'Last 30 Days': [moment().subtract(30, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'This Contract Year': [firstContractDay, lastContractDay],
      'Last Contract Year': [previousfirstContractDay, previouslastContractDay]
    };
  }

  setData(data) {
    this.rawData = data;
    this.dataSource = new MatTableDataSource(data.filter(x => x.yourLearner == true));
    this.selection = new SelectionModel<typeof data>(true, []);
    // Resctricting the filter search so it only is based on visable columns values
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      const string = data.name.toLowerCase() + data.assessor.toLowerCase() + data.scheme.toLowerCase() + data.centre.toLowerCase();
      return !filter || string.includes(filter);
    }
    console.dir("Authorised-absence:" + this.rawData);
  }

  paginateAndSort() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    // When you filter it will clear the selection so that items you selected dont become hidden
    // There could be a better solution of displaying the selected items in the table still
    this.selection.clear();
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  calculateDayDiff() {
    return Math.floor((Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate()) - Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  isWeekDay(date: Date) {
    date = new Date(date);
    date = new Date(date);
    return date.getDay() != 6 && date.getDay() != 0;
  }

  canAuthorise(): boolean {
    return (
      this.startTime != undefined
      && this.endTime != undefined
      && this.selection.selected.length > 0
      && this.dateRange.startDate !== null
      && this.dateRange.endDate !== null
      && !this.showSpinner
    );
    return (
      this.startTime != undefined
      && this.endTime != undefined
      && this.selection.selected.length > 0
      && this.dateRange.startDate !== null
      && this.dateRange.endDate !== null
      && !this.showSpinner
    );
  }





  approveSelected() {
    console.log(this.dateRange);

    this.showSpinner = true;
    this.startDate = new Date(this.dateRange.startDate);
    this.startDate.setHours(8, 0, 0, 0);
    this.endDate = new Date(this.dateRange.endDate);
    this.endDate.setHours(17, 0, 0, 0);

    console.log(this.startDate, this.endDate);

    const isTimesSet: boolean = this.startTime != undefined && this.endTime != undefined;
    const isDatesSet: boolean = this.startDate != undefined && this.endDate != undefined;
    const isSelectionsMade: boolean = this.selection.selected.length > 0;

    if (!isSelectionsMade || !isTimesSet || !isDatesSet) {
      return false
    }

    //Add seconds to time value. I think this is only needed so you
    //Add seconds to time value. I think this is only needed so you
    const timeIn = this.startTime + ':01';
    const timeOut = this.endTime + ':01';

    for (let x in this.selection.selected) {
      this.selection.selected[x]['timein'] = timeIn;
      this.selection.selected[x]['timeout'] = timeOut;
      this.selection.selected[x]['comments'] = this.comments;
      this.selection.selected[x]['date'] = this.startDate;
      this.selection.selected[x]['date'] = this.startDate;
    };

    const selection = this.selection.selected;
    const days = this.calculateDayDiff();

    //set for multuple dates
    if (days > 1) {
      for (let i in this.selection.selected) {
        for (let d = 1; d < days; d++) {
          let nextDay = new Date(this.startDate.getTime());
          nextDay.setDate(nextDay.getDate() + d);
          //Skip weekends
          if (this.isWeekDay(nextDay)) {
            const newSelection = ({ ...this.selection.selected[i] });
            newSelection['date'] = nextDay;
            selection.push(newSelection);
          }
        }
      }
    }




    this.homeDashService.createAuthorisedAbsences(selection, this.staffId).subscribe(result => {
      let msg = '';
      let action = '';
      if (result == 'Success') {
        msg = "Absence authorised successfully";
        action = "Success";
        this.attendanceUpdate.emit({ 'learners': selection });
      } else {
        msg = "Error: " + result;
        action = "Error";
      }

      this.snackBar.open(msg, action, {
        duration: 3000
      }).afterDismissed().subscribe(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      });
    });

  }



  toggleOnlyMyLearnerStatus(value: MatSlideToggleChange) {
    this.onlyMyLearnerStatus = value.checked;

    if (value.checked) {
      this.dataSource.data = this.rawData.filter(x => x.yourLearner == true);
    }
    else {
      this.dataSource.data = this.rawData;
    }
    this.cdr.detectChanges();
  }
}
