import { SharedFunctionsService } from './../../../core/services/shared-functions.service';
import { UserTableService } from 'src/app/core/database/user-table.service';
import { CreateMassLearnerEventModal } from './create-mass-learner-event-modal/create-mass-learner-event-modal.component';
import { AddEventFormComponent } from './add-event-form/add-event-form.component';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ChangeDetectorRef } from '@angular/core';
import { YourEventInfoDialogComponent } from './your-event-info-dialog/your-event-info-dialog.component';
import { Router } from '@angular/router';
import { LearnerEventFormComponent } from 'src/app/learner/learner-events/learner-event-form/learner-event-form.component';
import { EventModalMode } from 'src/app/shared/enums/event-modal-mode';
import { LearnerService } from 'src/app/learner/learnerService.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HomeDashboardService } from '../../home-dashboards/home-dashboard.service';
import { DATE_FORMAT } from 'src/app/app.constants';
import { SitePermissions } from 'src/app/shared/enums/permissions';

@Component({
  selector: 'app-your-events',
  templateUrl: './your-events.component.html',
  styleUrls: ['./your-events.component.scss']
})
export class YourEventsComponent implements OnInit {

  @HostBinding('class') class = 'active-widget';
  @Input() staffId: number;

  eventsMissed: any[] = [];
  eventsToday: any[] = [];
  eventsUpcoming: any[] = [];

  placementType: string;
  employerId: number;
  learnerId: string;
  pot: number;

  learners: any[] =[];
  allEventTypes: any[] = [];
  permissions: {
    canAudit: boolean,
    canCreate: boolean,
    canDelete: boolean,
    canEdit: boolean
  } = { canAudit: false, canCreate: false, canDelete: false, canEdit: false };
  employers: any[] = [];
  learnersForEvent: {traineeId: string, pot:number}[] = [];

  isShowMissedEvents = true;
  isShowTodaysEvents = true;
  isShowUpcomingEvents = true;
  currentDashboard: string;

  haveLearnerData = false;
  dateFormat = DATE_FORMAT;

  constructor(
    private homeDashboardService: HomeDashboardService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private userTableService: UserTableService,
    private router: Router,
    private sharedFunctions: SharedFunctionsService,
    private learnerService: LearnerService,
    private snackBar: MatSnackBar,
  ) { }


  ngOnInit(): void {
    this.checkStaffIdAndGetEvents();
    this.getEventModalData();
  }

  hideMissedEvents(){
    this.isShowMissedEvents = false;
  }

  openDialogForEventInfo(event){
    const dialogConfig = new MatDialogConfig();
     dialogConfig.minWidth = '50%'

    dialogConfig.data = {
      event: event
    }

    const dialogRef = this.dialog.open(YourEventInfoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(isGoToEdit => {
      if(isGoToEdit){
        if(!event.vbranchid){
          this.router.navigate([`employer-old/dashboard/${event.vemployerid}/loadEvent/${event.eventId}`]);
        }
        else{
          this.router.navigate([`employer-old/dashboard/branch/${event.vbranchid}/loadEvent/${event.eventId}`]);
        }
      }
    });
  }

  checkStaffIdAndGetEvents() {
    this.userTableService.get(1).then(
      user => {
        this.staffId = user.staffId;
        this.permissions = {
          canEdit: user.permission.includes(SitePermissions.EventEdit),
          canDelete: user.permission.includes(SitePermissions.EventDelete),
          canCreate: user.permission.includes(SitePermissions.EventCreate),
          canAudit: user.permission.includes(SitePermissions.EventAudit),
        };
        this.changeDetectorRef.detectChanges();
        this.getEvents();
      })
  }

  getEvents() {
    this.homeDashboardService.getEvents(this.staffId, this.currentDashboard).subscribe(
      response => {
        console.log('staff events', response);
        const eventsMissed = response.eventsMissed;
        const eventsToday = response.eventsToday;
        const eventsUpcoming = response.eventsUpcoming;

        this.eventsMissed = this.sharedFunctions.genericOrdered(eventsMissed, 'eventDate');
        this.eventsToday = this.sharedFunctions.genericOrdered(eventsToday, 'eventDate');
        this.eventsUpcoming = this.sharedFunctions.genericOrdered(eventsUpcoming, 'eventDate');
        this.changeDetectorRef.detectChanges();

        this.isShowMissedEvents = this.eventsMissed.length > 0;
        this.isShowTodaysEvents = this.eventsToday.length > 0;
        this.isShowUpcomingEvents = this.eventsUpcoming.length > 0;

        this.changeDetectorRef.detectChanges();
      }
    );
  }

  addNewEvent() {
      this.getEmployers();
  }

  createNewLearnerEvent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '650px';
    dialogConfig.data = {
      learners: this.learners,
    };

    const learnerDialogRef = this.dialog.open(CreateMassLearnerEventModal, dialogConfig);

    learnerDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.learnersForEvent = result;
        this.openEventDialog();
      }
    });
  }

  openEventDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '650px';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      allEventTypes: this.allEventTypes,
      permissions: this.permissions,
      mode: EventModalMode.create,
    };

    const eventDialogRef = this.dialog.open(LearnerEventFormComponent, dialogConfig);
    eventDialogRef.afterClosed().subscribe(formData => {
      if(formData) {
        this.postEvent(formData);
      }
    });
  }

  postEvent(formData) {
    const eventData = {
      postEventLearners: this.learnersForEvent,
      eventTypeId: formData.value.eventType.eventTypeId,
      eventDate: formData.value.eventDate,
      signedOffDate: formData.value.signedOffDate ?? null,
      Comments: formData.value.comments,
      isDraft: formData.value.isDraft ?? false
    };

    const subscription = this.learnerService.PostMassLearnerEvent(eventData).subscribe({
      next: () => {
        this.snackBar.open('Event successfully created', 'Close', {
          duration: 3000,
        });
      },
      error: error => {
        this.snackBar.open('Error: Event could not be created, please try again. If this problem persists please contact systems support.', 'Close', {
        });
        console.log('error posting event', error.error);
      },
      complete: () => {
        subscription.unsubscribe();
        this.getEvents();
      }
    });
  }



  openAddEventDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '70%';
    dialogConfig.height = "600px"
    dialogConfig.data = {
      learners: this.learners,
      employers: this.employers
    };

    const dialogRef = this.dialog.open(AddEventFormComponent, dialogConfig);
  }

  getEventModalData() {
    this.homeDashboardService.getEventModalData(this.staffId).subscribe({
      next: response => {
        this.learners = response.learners;
        this.allEventTypes = response.eventTypes;
      },
      error: error => {
        this.haveLearnerData = true;
        console.log('error getting learners: ', error.error);
      },
      complete: () => {
        this.haveLearnerData = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getEmployers() {
    console.log('about to get employers');
    this.homeDashboardService.getEmployers().subscribe(
      response => {
        if (response) {
          console.log('employers response', response);
          response.forEach(element => {
            this.employers.push(element);
          });

          this.openAddEventDialog();
        }
      });
  }
}
