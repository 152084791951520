
<div class="info-timeline">
  <h3 class="title" style="margin-left: 15px;">Milestones</h3>
  <mat-list *ngIf="showMilestones">
    <mat-list-item *ngFor="let milestone of milestones " style="margin-left: 0px;">
      <img src="../../../assets/images/line-green.png" *ngIf="milestone.state == 'COMPLETE'">
      <img src="../../../assets/images/line-yellow.png" *ngIf="milestone.state == 'PENDING'">
      <img src="../../../assets/images/line-red.png" *ngIf="milestone.state == 'OVERDUE'">
      <img src="../../../assets/images/line-grey.png" *ngIf="milestone.state == 'NOTFOUND'">
     <div class="milestone-content" >
        <div mat-line class="milestone-tile">
          <span>{{milestone.title | titlecase}}</span>
        </div>
        <div mat-line *ngIf="milestone.state=='PENDING'" class="milestone-display">Due: {{milestone.dueDate | date: dateFormat}}
        </div>
        <div mat-line *ngIf="milestone.state=='COMPLETE'" class="milestone-display">{{milestone.title == 'Planned review' ? 'Planned:' : 'Completed:'}} {{milestone.completedDate | date: dateFormat}}
        </div>
        <div mat-line *ngIf="milestone.state=='OVERDUE'" class="milestone-display">Due: {{milestone.dueDate | date: dateFormat}}
        </div>
        <div mat-line *ngIf="milestone.state=='NOTFOUND'" class="milestone-display">Not found
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</div>
