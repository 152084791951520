import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private serverUrl = environment.API_URL;

  apiUrl: string;
  token: string;

  constructor(private httpClient: HttpClient) {
    this.token = localStorage.getItem('access_token');
  }

  getOptionsWithAccessToken() {
    const token = localStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', 'Bearer ' + token);
    let options = { headers: headers };
    return options;
  }

  importLearnerPayFile(file: any, staffId: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('staffId', staffId);

    const headers = new HttpHeaders()
      .set('Authorization', "todotoken");

    let options = { headers: headers };
    const url = `${this.serverUrl}ImportLearnerPayFile`;

    return this.httpClient.post(url, formData, options);
  }

  importLearningAimUnitFile(file: any, laimRef: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('laimRef', laimRef);

    const headers = new HttpHeaders()
      .set('Authorization', "todotoken");

    let options = { headers: headers };
    const url = `${this.serverUrl}ImportLearningAimUnitFile`;

    return this.httpClient.post(url, formData, options);
  }

  uploadUnitFileForEmployer(file: any, vEmployerId: string) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('vEmployerId', vEmployerId);

    const headers = new HttpHeaders()
      .set('Authorization', "todotoken")

    let options = { headers: headers };
    console.log('posting')
    return this.httpClient.post(`${this.serverUrl}UploadUnitFileForEmployer`, formData, options);
  }

  getUploadedFilesListForEmployer(data: any){
    const url = `${this.serverUrl}GetUploadedFilesListForEmployer/${data.vEmployerId}`;
    const options = this.getOptionsWithAccessToken();
    return this.httpClient.get(url, options);
  }

  downloadUnitFileForEmployer(vEmployerId: string, fileName: string){
    const url = `${this.serverUrl}DownloadUnitFileForEmployer/${vEmployerId}/${fileName}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
    return this.httpClient.get(url, { responseType: 'blob', headers: headers });
  }

}
