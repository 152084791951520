import { Component } from '@angular/core';
import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';

@Component({
  selector: 'app-onefile-ialps-report',
  templateUrl: './onefile-ialps-report.component.html'
})
export class OnefileIalpsReportComponentV2 {
  constructor(
    private sharedFunctions: SharedFunctionsService,
  ) { }

  reportVariant = ReportVariant.OneFileIalps;
  defaultDisplayColumns = [
    {
      type: 'default',
      columns: ['fullName', 'subcontractor', 'scheme', 'sector', 'assessor', 'digitalIALPCompleted', 'digitalIALPCompliant', 'reason']
    }
  ];
  dateFilters = ['startDate', 'expectedEndDate', 'ialpVersionCompDate', 'twentyEightDays'];
  otherColumns = ['scheme', 'count'];
  groupByColumns: ISummaryGroupBy[] = [
    {
      value: 'scheme', title: 'Percent Compliant', columns: ['scheme', 'compliant', 'notCompliant', 'total', 'percentCompliant'],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'compliant'
    },
    {
      value: 'programmeType', title: 'Programme Type', columns: ['programmeType', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'subcontractor', title: 'Subcontractor', columns: ['subcontractor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'sector', title: 'Sector', columns: ['sector', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'assessor', title: 'Assessor', columns: ['assessor', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
    {
      value: 'centre', title: 'Centre', columns: ['centre', ...this.otherColumns],
      pieChartDrillDown: 'scheme', pieChartShareValue: 'count'
    },
  ];

  tableCellStyle = (column: string, value: any, row: any): any => {
    let style = {};
    switch (column) {
      case 'fifteenDays':
        if (row['programmeTypeId'] === 16 && row['over15Or28Days'] === 'Yes') {
          style = { 'background-color': '#ffd1d1' };
        }
        break;
      case 'twentyEightDays':
        if (row['programmeTypeId'] !== 16 && row['over15Or28Days'] === 'Yes') {
          style = { 'background-color': '#ffd1d1' };
        }
        break;
      case 'days':
        if (row['over15Or28Days'] === 'Yes') {
          style = { 'background-color': '#ffd1d1' };
        }
        break;
    }
    return style;
  }

}

