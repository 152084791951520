import { SharedFunctionsService } from '../../core/services/shared-functions.service';
import { UserService } from '../../core/services/user.service';
import { ReportVariant } from '../../shared/enums/report-variant';
import { FavouriteTableService } from '../../core/database/favourite-table.service';
import { UserTableService } from '../../core/database/user-table.service';
import { IUserInfo } from 'src/app/shared/interfaces/user-info';
import { BasicReportService } from './basic-report.service';
import { IGenericSummaryTableData, ITableFilter, ISummaryGroupBy } from '../../shared/interfaces/generic-interfaces';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, QueryList, ViewChild, ViewChildren, ChangeDetectorRef, AfterViewInit, Input, Type, ɵresetCompiledComponents, ChangeDetectionStrategy } from '@angular/core';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportStoredFilterHelperService } from '../report-stored-filter-helper.service';
import { SaveReportFiltersDialogComponent } from '../save-report-filters-dialog/save-report-filters-dialog.component';
import { ShareReportFiltersDialogComponent } from '../share-report-filters-dialog/share-report-filters-dialog.component';
import { Sidebar } from 'ng-sidebar';
import { ShowSavedReportFiltersDialogComponent } from '../show-saved-report-filters-dialog/show-saved-report-filters-dialog.component';
import { object } from 'underscore';
import { DATE_FORMAT } from 'src/app/app.constants';

export interface IReportResponse {
  reportData: any[];
  filterOptions: { [key: string]: any[] };
  currentPage: number;
  totalPages: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageSize: number;
}

export interface IAdditionalFilter {
  Type: string,
  Options: string[]
}

export interface IDefaultColumns {
  type: string,
  columns: string[];
}

export enum ReportType {
  table = "table",
  summary = "summary",
  chart = "chart"
}

@Component({
  selector: 'basic-report',
  templateUrl: './basic-report.component.html',
  styleUrls: ['./basic-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicReportComponent implements OnInit, AfterViewInit {
  @ViewChild('summarySort', { static: true }) summarySort: MatSort;
  @ViewChild('summaryPaginator', { static: true }) summaryPaginator: MatPaginator;

  @ViewChild('tableSort', { static: true }) tableSort: MatSort;
  @ViewChild('tablePaginator', { static: true }) tablePaginator: MatPaginator;

  @ViewChildren('filterSelector') filterSelector: QueryList<any>;
  @ViewChildren('showColumnSelector') showColumnSelector: QueryList<any>;
  @ViewChild("sharedFilters") sharedFiltersChild;
  @ViewChild("reportSummaryChart") reportSummaryChartChild;
  @ViewChild('dateRange') dateRangePicker;
  @ViewChild("ngSidebar") ngSidebar: Sidebar;

  @Input() reportName: string;
  @Input() defaultDisplayColumns: IDefaultColumns[];
  @Input() dateFilterTypes: string[];
  @Input() report: ReportVariant;
  @Input() matSortActive = '';
  @Input() matSortDirection = '';
  @Input() groupByColumns: ISummaryGroupBy[];
  @Input() getSummaryData: (tempTableData: any[], groupByColumn: ISummaryGroupBy) => any;
  @Input() activeLearnerToggle = true;
  @Input() tableCellStyle: (column: string, value: any, row: any) => object = () => { return {} };
  @Input() displayName = '';
  @Input() hasClassicFilters = true;
  @Input() hiddenColumns: string[] = [];
  @Input() defaultColumnOrder: IDefaultColumns[] = new Array<IDefaultColumns>();

  selectedDefaultDisplayColumns: IDefaultColumns;
  selectedColumnOrder: IDefaultColumns;
  multipleDefaultDisplayColumns = false;

  selectedDateRange: { startDate: moment.Moment, endDate: moment.Moment } = null;
  showClassicFilters = true;
  showReportFilters = true;
  showOptions = true;
  userInfo: IUserInfo;

  tableDataSource: MatTableDataSource<any>;
  summaryDataSource: MatTableDataSource<IGenericSummaryTableData>;
  tableDataFiltered: any[] = [];


  mainTableData: any[] = [];
  tableDataActiveLearnersOnly: any[] = [];
  tableDataAllLearners: any[] = [];

  allColumns: string[] = [];
  displayedColumns: string[];
  lastColumnSelection: string[];
  columnFormControl: UntypedFormControl;
  selectAllColumns = false;
  summaryColumns: string[] = [];

  //Hide the id columns from the table
  hideColumns: string[] = ['contractId', 'programmeTypeId', 'schemeId', 'sectorId', 'assessorId', 'subcontractorId', 'centreId', 'potStatusId'];

  ReportType = ReportType;
  reportSelected = ReportType.table;

  dateFilterTypeSelected: string;
  ranges: any = {}

  assessorOptions: any[];
  centreOptions: any[];
  contractOptions: any[];
  subcontractorOptions: any[];
  programmeTypeOptions: any[];
  schemeOptions: any[];
  sectorOptions: any[];

  savedReportFilterName;
  savedFilterToApply;
  storedFilters = [];
  reportSavedFilter;
  additionalFilters: IAdditionalFilter[] = [];
  filter: ITableFilter[] = []
  allFilterOptionsRaw: { [key: string]: any[] } = {};
  groupByColumn: ISummaryGroupBy;

  additionalFilterGroup = new UntypedFormGroup({
  });

  pageOptions: any;

  sidebarMode: string;
  sidebarOpened = false;

  isFavouriteReport: { status: boolean, data: { Id: number; key: string } } = { status: false, data: { Id: null, key: null } };
  waitingForToggle = false;

  showSpinner = false;
  errorText: string = null;

  dateFormat = DATE_FORMAT;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private reportStoredFilterHelperService: ReportStoredFilterHelperService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private basicReportService: BasicReportService,
    private userTableService: UserTableService,
    private favouriteTableService: FavouriteTableService,
    private cdr: ChangeDetectorRef,
    private sharedFunctions: SharedFunctionsService,
  ) {
    this.tableDataSource = new MatTableDataSource<any>(this.mainTableData);
    this.summaryDataSource = new MatTableDataSource<IGenericSummaryTableData>();
    this.pageOptions = [this.sharedFunctions.calcOptimumRows(), 25, 50, 100];
    moment.locale('en-GB');
  }

  ngOnInit(): void {
    // If there is no defautl column order set, set it to the default display columns
    console.log("Column Order: ", this.defaultColumnOrder);
    if (this.defaultColumnOrder.length === 0) {
      this.defaultColumnOrder = JSON.parse(JSON.stringify(this.defaultDisplayColumns));
    }
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns[0];
    this.selectedColumnOrder = this.defaultColumnOrder[0];
    if (this.defaultDisplayColumns.length > 1) {
      this.multipleDefaultDisplayColumns = true;
    }
    this.displayedColumns = this.orderColumns([...this.selectedDefaultDisplayColumns.columns]);
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.columnFormControl = new UntypedFormControl(this.displayedColumns);
    this.dateFilterTypeSelected = this.dateFilterTypes[0];
    this.groupByColumn = this.groupByColumns[0];
    this.summaryColumns = [...this.groupByColumn.columns];
    this.showSpinner = true;
    this.tableDataSource.paginator = this.tablePaginator;
    this.tableDataSource.sort = this.tableSort;
    this.summaryDataSource.paginator = this.summaryPaginator;
    this.summaryDataSource.sort = this.summarySort;
    this.closeSideBar();
    this.sidebarMode = 'push';
    this.getCurrentUser();
    this.getAllSavedFiltersFromLocalDb();
    this.setUpDatePicker();
    this.checkForSavedFilters();
  }

  checkForSavedFilters() {
    this.route.data.forEach(
      result => {
        this.reportSavedFilter = result.reportFilter;
        if (this.reportSavedFilter) {
          this.savedFilterToApply = this.reportSavedFilter.filters;
          this.savedReportFilterName = this.reportSavedFilter.name;

          this.snackBar.open("Filter applied: " + this.reportSavedFilter.name,
            'Close', { duration: 5000 });
        }
      });
  }

  ngAfterViewInit() {
    this.sidebarMode = window.innerWidth <= 1200 ? 'over' : 'push';
  }

  getCurrentUser() {
    this.userTableService.get(1)
      .then(result => {
        if (!result || result == undefined || result == null) {
          this.router.navigate(['']);
        } else {
          // console.log(`${this.reportName}-report getCurrentUser`, result.staffId + ': ' + result.userName);
          this.userInfo = result;
          this.getReportData();
        }
      });
  }

  getAllSavedFiltersFromLocalDb() {
    this.favouriteTableService
      .getAll()
      .then(response => {
        response.forEach(item => {
          if (item.type === 'filter' && item.reportId === this.report) {
            this.storedFilters.push(item);
          } else if (item.type === 'report' && item.reportId === this.report) {
            this.isFavouriteReport.status = true;
            this.isFavouriteReport.data.Id = item.id;
            this.isFavouriteReport.data.key = `report:${item.id}`;
          }
        });
      });
  }

  changeReportType($event) {
    this.reportSelected = $event.value;

    if (this.reportSelected !== ReportType.table) {
      this.createSummaryReport();
    }
  }

  setDefaultColumn($event) {
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns.find(x => x.type === $event.value);
    this.selectedColumnOrder = this.defaultColumnOrder.find(x => x.type === $event.value);
    this.displayedColumns = [...this.selectedDefaultDisplayColumns.columns];
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.columnFormControl.setValue(this.displayedColumns);
    this.resetSelectAll();
  }

  toggleOnlyActiveLearners(onlyActiveLearners: boolean) {
    this.mainTableData = onlyActiveLearners ? [...this.tableDataActiveLearnersOnly] : [...this.tableDataAllLearners];
    this.tableDataFiltered = this.mainTableData;
    this.updateResultsFollowingSwitchingIsOnlyActiveLearners();
  }

  updateResultsFollowingSwitchingIsOnlyActiveLearners() {
    this.filterData();
    this.populateSideBar();
    this.cdr.detectChanges();
  }

  filterData() {
    // Reset to unfiltered data
    this.tableDataFiltered = this.mainTableData;

    // Filter by classic and report specific filters
    this.filter.forEach(element => {
      this.tableDataFiltered = this.tableDataFiltered.filter(a =>
        element.value.indexOf(a[element.column]) !== -1
      )
    });

    // Filter by date and set filtered data to table data source
    this.tableDataSource.data = this.filterByDate();

    // This sets the data for summary and chart if it is selected
    if (this.reportSelected !== ReportType.table) {
      this.createSummaryReport();
    }
  }

  getReportData() {
    this.errorText = null;
    this.basicReportService.getBasicReport(this.reportName.replace(/ /g, ''), this.userInfo.staffId).subscribe(
      result => {
        console.log(result);
        this.setDatatFollowingRequest(result);
      },
      error => {
        console.log(error);
        this.showSpinner = false;
        this.userService.connectionIssueNotification(); //Adds refresh button to bottom of screen
        this.snackBar.open(`${error} Please reload and try again`, 'Close', {
          duration: 5000,
        });
        this.errorText = 'Sorry, there was an error loading the report. Please try again.';
        this.cdr.detectChanges();
      }
    )
  }

  setDatatFollowingRequest(result: IReportResponse) {
    this.tableDataAllLearners = [...result.reportData];
    this.tableDataActiveLearnersOnly = [...result.reportData.filter(a => a['terminationDate'] === null)];
    this.allFilterOptionsRaw = result.filterOptions;
    if (this.activeLearnerToggle) {
      this.mainTableData = this.tableDataActiveLearnersOnly;
    } else {
      this.mainTableData = this.tableDataAllLearners;
    }
    this.tableDataFiltered = this.mainTableData;
    if (this.mainTableData.length !== 0) {
      this.allColumns = Object.keys(this.mainTableData[0]).sort();
      this.hideColumns = [...this.hideColumns, ...this.hiddenColumns];
      this.allColumns = this.allColumns.filter(column => this.hideColumns.indexOf(column) === -1);
      this.allColumns.unshift('Select All');

      this.tableDataSource.data = this.mainTableData;
      this.tableDataSource.filterPredicate[0] = this.sharedFunctions.customFilterPredicate;

      this.populateSideBar();
      if (window.innerWidth > 800) {
        this.openSideBar();
      } else {
        this.closeSideBar();
      }

      if (this.savedFilterToApply) {
        this.loadSavedFilters();
      } else {
        this.createSummaryReport()
      }
    }
    this.showSpinner = false;
    this.cdr.detectChanges();
  }

  setUpDatePicker() {
    const dayOfYear = moment.utc().dayOfYear();
    const firstContractDay = moment();
    const lastContractDay = moment();
    const previousfirstContractDay = moment()
    const previouslastContractDay = moment()
    if (dayOfYear < 213) {
      firstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 }) // moment months start counting at 0!
      lastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 2, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year() - 1, 'month': 6, 'date': 31 })
    } else {
      firstContractDay.set({ 'year': moment().year(), 'month': 7, 'date': 1 })
      lastContractDay.set({ 'year': moment().year() + 1, 'month': 6, 'date': 31 })
      previousfirstContractDay.set({ 'year': moment().year() - 1, 'month': 7, 'date': 1 })
      previouslastContractDay.set({ 'year': moment().year(), 'month': 6, 'date': 31 })
    }

    this.ranges = {
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'This Contract Year': [firstContractDay, lastContractDay],
      'Last Contract Year': [previousfirstContractDay, previouslastContractDay]
    }
  }

  toggleSidebar() {
    this.sidebarOpened ? this.closeSideBar() : this.openSideBar();
  }

  openSideBar() {
    this.sidebarOpened = true;
  }

  closeSideBar() {
    this.sidebarOpened = false;
  }

  createSummaryReport() {
    const tempTableData = [...this.tableDataSource.data];

    this.summaryDataSource.data = this.getSummaryData(tempTableData, this.groupByColumn);

    if (this.reportSelected === this.ReportType.chart) {
      this.reportSummaryChartChild.setPieChartSummaryDataForBasicReport(
        this.summaryDataSource.data,
        this.groupByColumn
      );
    }
  }

  setFilter(newFilter: { type: string, value: any[] }) {
    if (newFilter.value) {
      // remove current filter if it exists.
      this.filter = this.filter.filter(f => f.column !== newFilter.type);

      // if the filter is not empty, add it to the filter array.
      // Shared filters sends a 0 value if the filter is empty - probably should change this to be consistant.
      if (newFilter.value.indexOf(0) === -1 && newFilter.value.length !== 0) {
        const filterToAdd: ITableFilter = { column: newFilter.type, value: newFilter.value };
        this.filter.push(filterToAdd);
      }

      this.filterData();
    }
  }

  setAllFilterOptions() {
    this.assessorOptions = this.allFilterOptionsRaw.assessorFilters;
    this.centreOptions = this.allFilterOptionsRaw.centreFilters;
    this.contractOptions = this.allFilterOptionsRaw.contractFilters;
    this.subcontractorOptions = this.allFilterOptionsRaw.subcontractorFilters;
    this.programmeTypeOptions = this.allFilterOptionsRaw.programmeFilters;
    this.schemeOptions = this.allFilterOptionsRaw.schemeFilters;
    this.sectorOptions = this.allFilterOptionsRaw.sectorFilters;

    // Only want to set the form controls once.
    if (this.additionalFilters.length === 0) {
      this.allFilterOptionsRaw['genericFilters']?.forEach(element => {
        this.additionalFilters.push({ Type: element.type, Options: [...element.options] });
        this.additionalFilterGroup.addControl(element.type, new UntypedFormControl(null)); // may be cleaner with form builder.
      });
    } else {
      this.additionalFilters = [];
      this.allFilterOptionsRaw['genericFilters']?.forEach(element => {
        this.additionalFilters.push({ Type: element.type, Options: [...element.options] });
      });
    }
  }

  resetAllFilters() {
    this.selectedDefaultDisplayColumns = this.defaultDisplayColumns[0];
    this.selectedColumnOrder = this.defaultColumnOrder[0];
    this.displayedColumns = [...this.selectedDefaultDisplayColumns.columns];
    this.lastColumnSelection = [...this.selectedDefaultDisplayColumns.columns];
    this.columnFormControl.setValue([...this.selectedDefaultDisplayColumns.columns]);
    this.resetSelectAll();

    this.dateRangePicker.nativeElement.value = '';

    this.dateFilterTypeSelected = "startDate";
  }

  reset() {
    this.sharedFiltersChild.reset();
    this.filter = [];
    this.filterSelector.forEach(element => { element.value = -1 });

    this.resetDatePicker();
    this.resetAllFilters();

    this.tableDataSource.data = this.mainTableData;
    this.tableDataFiltered = this.mainTableData;
    if (this.summaryDataSource.data.length > 0) {
      this.setGroupByColumn(this.groupByColumns[0]);
    }
    this.additionalFilterGroup.reset();
  }

  export(data: any[], exportType: string) {
    this.sharedFunctions.export(data, this.displayedColumns, this.report, exportType);
  }

  back() {
    this.router.navigate(['/reports/index'])
  }

  populateSideBar() {
    this.setAllFilterOptions();

    if (this.assessorOptions) {
      this.assessorOptions = this.filterAndSortOptions('assessor', this.assessorOptions);
    }
    if (this.centreOptions) {
      this.centreOptions = this.filterAndSortOptions('centre', this.centreOptions);
    }
    if (this.contractOptions) {
      this.contractOptions = this.filterAndSortOptions('contract', this.contractOptions);
    }
    if (this.subcontractorOptions) {
      this.subcontractorOptions = this.filterAndSortOptions('subcontractor', this.subcontractorOptions);
    }
    if (this.programmeTypeOptions) {
      this.programmeTypeOptions = this.filterAndSortOptions('programmeType', this.programmeTypeOptions);
    }
    if (this.schemeOptions) {
      this.schemeOptions = this.filterAndSortOptions('scheme', this.schemeOptions);
    }
    if (this.sectorOptions) {
      this.sectorOptions = this.filterAndSortOptions('sector', this.sectorOptions);
    }

    if (this.additionalFilters.length > 0) {
      this.additionalFilters.forEach(element => {
        element.Options = this.filterAndSortOptions(element.Type, element.Options, true);
      });
    }

  }

  // Only shows options that are in the current table data and sorts albanetically.
  filterAndSortOptions(name: string, array: any[], additionalFilter = false): any[] {
    let options = [...array];

    if (additionalFilter) {
      options = options.filter(option =>
        this.mainTableData.find(tableRow => tableRow[name] === option) !== undefined ? true : false
      );
      options.sort((a, b) => a.localeCompare(b));
    } else {
      options = options.filter(option =>
        this.mainTableData.find(tableRow => tableRow[name] === option[name]) !== undefined ? true : false
      );
      options.sort((a, b) => a[name].localeCompare(b[name]));
    }

    return options;
  }

  selectColumns(selection: any[]) {
    if (selection.indexOf('Select All') !== -1 && !this.selectAllColumns) {
      this.displayedColumns = this.orderColumns(this.allColumns);
      this.allColumns[this.allColumns.indexOf('Select All')] = 'Undo Select All';
      this.columnFormControl.setValue(this.displayedColumns);
      this.selectAllColumns = true;
    } else if (selection.indexOf('Undo Select All') !== -1 && this.selectAllColumns) {
      this.displayedColumns = [...this.lastColumnSelection];
      this.columnFormControl.setValue(this.displayedColumns);
      this.resetSelectAll();
    } else {
      this.displayedColumns = this.orderColumns(selection);
      this.lastColumnSelection = this.displayedColumns;
      this.resetSelectAll();
    }
  }

  orderColumns(newSelection: string[]) {
    let columnsToDisplay = [...this.selectedColumnOrder.columns];
    const selection = [...newSelection];

    columnsToDisplay = columnsToDisplay.filter(element => selection.indexOf(element) !== -1);

    const restOfSelection = selection.filter(item => this.selectedColumnOrder.columns.indexOf(item) === -1);
    restOfSelection.forEach(element => {
      if (element !== 'Select All') {
        columnsToDisplay.push(element);
      }
    });

    return columnsToDisplay;
  }

  resetSelectAll() {
    if (this.selectAllColumns) {
      this.allColumns[this.allColumns.indexOf('Undo Select All')] = 'Select All';
      this.selectAllColumns = false;
    }
  }

  setGroupByColumn(selection: ISummaryGroupBy) {
    this.groupByColumn = selection;
    this.summaryColumns = [...this.groupByColumn.columns];
    this.createSummaryReport();
  }

  filterByDate(): any[] {
    const tableData = this.filter.length > 0 ? [...this.tableDataFiltered] : [...this.mainTableData];
    let dataFilteredByDate: any[];
    if (this.selectedDateRange?.startDate && this.selectedDateRange?.endDate) {
      dataFilteredByDate = tableData.filter(
        m => moment(m[this.dateFilterTypeSelected]).toDate() >= this.selectedDateRange.startDate.toDate()
          && moment(m[this.dateFilterTypeSelected]).toDate() <= this.selectedDateRange.endDate.toDate()
      );
    } else {
      dataFilteredByDate = tableData;
    }

    return dataFilteredByDate;
  }


  dateRangeChange(event: any) {
    this.tableDataSource.data = this.filterByDate();

    if (this.reportSelected !== ReportType.table) {
      // This sets the data for summary and chart
      this.createSummaryReport();
    }
  }

  resetDatePicker() {
    this.selectedDateRange = null;
  }


  loadSavedFilters() {
    const filters = this.savedFilterToApply.split(";");
    this.sharedFiltersChild.loadSavedFilters(this.allFilterOptionsRaw, filters);
    this.loadDateFilters(filters);
    this.loadAdditionalFilters(filters);
    this.loadTypeAndColumnFilters(filters);
    this.savedFilterToApply = null;
    //this.cdr.detectChanges();
  }

  loadDateFilters(filters) {
    const dateToLoad = this.reportStoredFilterHelperService.getDateToLoad(filters, this.ranges);
    // If loading old saved filters this could be undefined if not in the hardcoded array - For newer ones this value will always be set.
    this.dateFilterTypeSelected = dateToLoad.dateTypeFilter ?? this.dateFilterTypeSelected;
    if (dateToLoad.startDate) {
      this.selectedDateRange = { "startDate": dateToLoad.startDate, "endDate": dateToLoad.endDate };
      this.tableDataSource.data = this.filterByDate();
    }
  }

  loadAdditionalFilters(filters) {
    filters.forEach(filter => {
      const filterSplit = filter.split("::");

      const filterType = filterSplit[0];
      const filterMatch = this.additionalFilterGroup.get(filterType)
      if (filterMatch) {
        filterMatch.setValue(filterSplit[1].split(","));
        this.setFilter({ type: filterType, value: filterSplit[1].split(",") })
      }
    });
  }

  // This should be compatible with old report type value using isShowSummary
  // Could write this out a little cleaner
  loadTypeAndColumnFilters(filters) {
    const typeAndColumns = this.reportStoredFilterHelperService.loadBasicTypeAndColumnFilters(filters);
    const reportSelectedTemp = this.ReportType[typeAndColumns.reportType];
    if (reportSelectedTemp === this.ReportType.summary || reportSelectedTemp === this.ReportType.chart) {
      this.reportSelected = reportSelectedTemp;
      const selectedGroupBy = this.groupByColumns.find(x => x.value === typeAndColumns.groupByColumn);
      this.setGroupByColumn(selectedGroupBy);
    } else if (reportSelectedTemp === this.ReportType.table) {
      this.reportSelected = this.ReportType.table;
      this.selectedDefaultDisplayColumns = this.defaultDisplayColumns.find(x => x.type === typeAndColumns.defaultDisplayColumnsType);
      this.selectedColumnOrder = this.defaultColumnOrder.find(x => x.type === typeAndColumns.defaultDisplayColumnsType);
      this.columnFormControl.setValue(typeAndColumns.selectedColumns);
      this.selectColumns(typeAndColumns.selectedColumns);
    } else {
      if (typeAndColumns.isShowSummery) {
        this.reportSelected = this.ReportType.summary;
        const selectedGroupBy = this.groupByColumns.find(x => x.value === typeAndColumns.groupByColumn);
        this.setGroupByColumn(selectedGroupBy);
      } else {
        this.reportSelected = this.ReportType.table;
        this.columnFormControl.setValue(typeAndColumns.selectedColumns);
        console.log('Select Columns: ', typeAndColumns.selectedColumns);
        this.selectColumns(typeAndColumns.selectedColumns);
      }
    }
  }

  shareReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '500px';
    const dialogRef = this.dialog.open(ShareReportFiltersDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      const fullFilter = this.getFullFilterAsString();
      const reportNameForUrl = this.route.snapshot.url[0].path;
      const data = { "filter": fullFilter, "reportId": this.report, "staffId": this.userInfo.staffId, "name": formData.name, "staffToShareThisWith": formData.selectedStaff, "isSaveFilters": formData.isSaveFilters, "reportNameForUrl": reportNameForUrl }
      this.userService.shareFavouriteReportWithFilter(data).subscribe(filterId => {
        const snackBarMessage = "Filter shared: " + formData.name

        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
        let reportTitle = this.unCamelCase(ReportVariant[this.report]);
        if (data.isSaveFilters) {
          this.favouriteTableService
            .add({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: formData.name, filterId: filterId, reportId: this.report }, filterId)
            .then(id => {
              reportTitle = this.unCamelCase(ReportVariant[this.report]);
              this.cdr.detectChanges();
            })
        }
      });
    });
  }

  getFullFilterAsString(): string {
    // Check dateFilterTypeSelected is always set
    const reportTypeAndSelectColumns = this.reportStoredFilterHelperService.getBasicReportTypeAndColumns(this.reportSelected, this.columnFormControl, this.groupByColumn.value, this.selectedDefaultDisplayColumns.type);
    const dateFilter = this.reportStoredFilterHelperService.getBasicDateFilterAsString(this.selectedDateRange.startDate, this.selectedDateRange.endDate, this.dateFilterTypeSelected);
    const sharedFilters = this.sharedFiltersChild.getFiltersAsString();
    const additionalFilters = this.reportStoredFilterHelperService.getAdditionalFilters(this.additionalFilters, this.additionalFilterGroup);
    const fullFilter = reportTypeAndSelectColumns + dateFilter + sharedFilters + additionalFilters;
    return fullFilter;
  }

  checkSaveFiltersAndStore() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '300px';
    dialogConfig.autoFocus = false;


    const dialogRef = this.dialog.open(SaveReportFiltersDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      this.storeFavourite(formData);
    });
  }

  storeFavourite(rawData) {
    const reportTitle = this.unCamelCase(ReportVariant[this.report]);
    if (rawData) {
      if (rawData.isSaveFilters) {
        const fullFilter = this.getFullFilterAsString();
        const saveData = { "filter": fullFilter, "reportId": this.report, "staffId": this.userInfo.staffId, "name": rawData.name }
        this.userService.postFavouriteReportWithFilter(saveData).subscribe(filterId => {
          this.favouriteTableService.add({
            id: filterId,
            title: reportTitle,
            link: '',
            tags: '',
            filter: fullFilter,
            name: rawData.name,
            filterId: filterId,
            reportId: this.report
          }, filterId)
            .then(id => {
              this.storedFilters.push({ id: filterId, title: reportTitle, link: '', tags: '', filter: fullFilter, name: rawData.name, filterId: filterId, reportId: this.report });
              this.snackBar.open('Saved: ' + rawData.name, 'Close', { duration: 2000 });
              this.cdr.detectChanges();
            })
        })
      }
      else {
        const data = { "reportId": this.report, "staffId": this.userInfo.staffId };
        this.userService.addReportToFavourites(data).subscribe(id => {
          if (id) {
            this.favouriteTableService.add({
              type: 'report',
              id: id,
              title: reportTitle,
              link: '',
              tags: '',
              filter: '',
              name: '',
              reportId: this.report
            }, `report:${id}`)
              .then(localId => {
                this.waitingForToggle = false;
                this.isFavouriteReport.status = true;
                this.isFavouriteReport.data.Id = id;
                this.isFavouriteReport.data.key = `report:${id}`;
                this.snackBar.open(`${reportTitle} added to favourites`, 'Close', { duration: 2000 });
                this.cdr.detectChanges();
              })
          }
        })
      }
    }
  }

  saveReportFilters() {
    this.checkSaveFiltersAndStore(); // return something
  }

  unCamelCase(str: string) {
    return this.sharedFunctions.unCamelCase(str);
  }

  isDate(value, column): boolean {
    return this.sharedFunctions.isDateFromColumnName(value, column);
  }

  showHideFilters(filterType: number) {
    if (filterType === 1) {
      this.showClassicFilters = !this.showClassicFilters;
    }
    else if (filterType === 2) {
      this.showReportFilters = !this.showReportFilters;
    }
    else if (filterType === 3) {
      this.showOptions = !this.showOptions;
    }
    this.ngSidebar.triggerRerender();
  }

  viewSavedFilters() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minHeight = '300px';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      storedFilters: this.storedFilters
    };

    const dialogRef = this.dialog.open(ShowSavedReportFiltersDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(formData => {
      if (formData) {
        this.showSpinner = true;
        this.reset();
        this.savedFilterToApply = formData.filter.filter;
        this.savedReportFilterName = formData.filter.name;
        this.loadSavedFilters();
        this.showSpinner = false;
        this.cdr.detectChanges();

        const snackBarMessage = "Filter loaded: " + formData.filter.name
        this.snackBar.open(snackBarMessage,
          'Close', { duration: 5000 });
      }
    });
  }

  toggleReportFavorite() {
    this.waitingForToggle = true;
    this.isFavouriteReport.status = !this.isFavouriteReport.status;
    if (this.isFavouriteReport.status) {
      this.storeFavourite({ isSaveFilters: false });
    }
    else {
      const data = { "type": 'report', "Id": this.isFavouriteReport.data.Id, "staffId": this.userInfo.staffId };
      this.userService.removeFavourite(data).subscribe(response => {
        if (response) {
          this.favouriteTableService.remove(this.isFavouriteReport.data.key);
          this.waitingForToggle = false;
          this.snackBar.open("Removed as a Favourite",
            'OK', { duration: 2000 });
        }
      });
    }
  }

  formatForEmpty(value): string {
    let formattedValue = value;
    switch (value) {
      case null:
      case undefined:
      case 'null':
      case '':
      case ' ':
      case '  ':
        formattedValue = '-';
        break;
    }
    return formattedValue;
  }

}

