<div>
  <mat-dialog-content>

    <h2>Assessor History</h2>

    <table style="padding: 15px;"  mat-table [dataSource]="this.assessorHistory">

      <ng-container matColumnDef="assessor">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{element.assessor}}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>From</th>
        <td mat-cell *matCellDef="let element">{{element.startDate | date: this.dateFormat}}</td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>To</th>
        <td mat-cell *matCellDef="let element">{{element.endDate === null ? '-' : (element.endDate | date: this.dateFormat)}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-dialog-actions style="float:right; margin-bottom: 5px;">
        <button mat-flat-button color="primary" (click)="this.dialogRef.close()">
          Close
        </button>
    </mat-dialog-actions>

  </mat-dialog-content>
</div>
