// Note: It is essential this this list is kept exactly in sync with the
// ReportVariant enum in the API project and also with the records in the UI.REPORT

export enum ReportVariant {
  None = 0,
  Activities = 1,
  // Applications = 2, //replaced with DigitalApplications
  AttendanceSignedIn = 3,
  AttendanceSummary = 4,
  AwaitingIqa = 5,
  Occupancy = 6,
  OneFileIalps = 7,
  ReviewList = 8,
  ReviewsRequired = 9,
  Terms = 10,
  ProfiledAchievers = 11,
  TraineeEssentialSkills = 12,
  AwardsNoActivities = 13,
  ActivitiesNoAwards = 14,
  IqaProfiles = 15,
  OneFileReviews = 17,
  JGWLearnerReferrals = 18,
  JGWLearnerNotificationsSent = 19,
  JGWLearnerAgreedStarts = 20,
  JGWLearnerAwaitingValidations = 21,
  AttendanceSignedOut = 22,
  SignInHistory = 23,
  GuidedContactHours = 24,
  LorActivitySuccess = 25,
  LorFrameworkSuccess = 26,
  LorProgressionSuccess = 27,
  LorPredictedFrameworkSuccess = 28,
  Starts = 29,
  OutstandingRegistrationRequests = 30,
  PlannedVisits = 31,
  UnregisteredLearners8And12Weeks = 32,
  TraineeVisionEvents = 33,
  ReviewAccuracy = 34,
  RegistrationSummary = 35,
  ApprenticeshipProvisionalAchievers = 36,
  CertificatesToBeClaimed = 37,
  CertificatesClaimedAndReceived = 38,
  OutstandingReturnedCertificates = 39,
  DigitalApplications = 40,
  TraineeStatusChange = 41,
  ReviewAnalysis = 42,
  Suspensions = 43,
  HealthAndSafetyEmployer = 44,
  MostRecentSignIn = 45,
  ReviewAln = 46,
  Wellbeing = 48,
  LearnerProgress = 49,
  EsqOccupancy = 50,
  EarlyLeavers = 51,
  EightWeekTerms = 52,
  OneFileAssessment = 53,
  OneFileAssessmentPending = 54,
  OneFileAssessmentPlan = 55,
  EsqMarkingAndIqa = 56,
  AvailableWorkshops = 57,
  WorkshopBookings = 58,
  TraineeObjectiveList = 59,
  UnopenedActivities = 60,
  RecentVisits = 61,
  CancelledAppointments = 62,
  SuspensionSubmission = 63,
  RetrospectiveOccupancy = 64,
}
